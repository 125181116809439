import React, { useEffect } from 'react';
import SigninTemplateStyle from './SigninTemplateStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import logo from '../../../assets/images/logo.svg';
import SigninForm from '../../molecules/SigninForm/SigninForm';
import Alert from '../../molecules/Alerts/Alerts';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { alertActions } from '../../../redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';

const SigninTemplate = () => {
  const state = useSelector( (state:RootStore) => state);
  const dispatch: TypedDispatch = useDispatch();
  const auth = Cookies.get("userToken");
  const navigate = useNavigate()

  useEffect(() => {
    if(auth !== undefined){
      navigate('/dashboard')
    }
    return () => {
      dispatch(alertActions.clear());
    }
  }, [])

  return (
    <>
      <SigninTemplateStyle>
       <div className={`container-full center signin`}>
          <div className={`signin__box`}>
            <div className={`container-full center signin__box__logo`}>
              <Image image={logo} alt="logo" />
            </div>
            <div className={`container-full signin__box__modal`}>
              {/* <div className={`container-full signin__box__modal__menu`}>
                <div className='signin__box__modal__menu__item active'>
                  <Text value="Admin" />
                </div>
                <div className='signin__box__modal__menu__item'>
                  <Text value="Merchant" />
                </div>
              </div> */}
              <div className={`container-full signin__box__modal__content`}>
                <div className={`container-full center signin__box__modal__content__desc`}>
                  <HeaderText value="Log In" />
                </div>
                {
                  state.alertReducer.type === "alert-danger" ? 
                  <div className={`container-full signin__box__modal__content__alert`}>
                    <Alert text={state.alertReducer.message} />
                  </div> : ''
                }
                <div className={`container-full signin__box__modal__content__form`}>
                  <SigninForm />
                </div>
              </div>
            </div>
            <div className={`container-full signin__box__link`}>
              <Link to="/forgot-password">
                <Text value='Forgot Password?' />
              </Link>
            </div>
          </div>
       </div>
      </SigninTemplateStyle>
    </>
  );
};

export default SigninTemplate;
