import React, { useState, useEffect } from 'react';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import InitiatePaymentStepOneStyle from './InitiatePaymentStepOneStyle';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import caret from '../../../assets/images/caret-down-select.svg';
import dayjs from 'dayjs';
import { addDays, format } from 'date-fns';
import { DateRange, DayPicker } from 'react-day-picker';
import { SubmitActionsUpload } from '../../../redux/actions/SubmitAction';
import { stepOne } from '../../../redux/actions/PaymentStepAction';
import template from '../../../assets/document/payment-template.xlsx';

type InitiatePaymentStepOneProps = {
  handleClose: Function,
  changeStep: Function,
  toggleUploadProgress: Function
}

const InitiatePaymentStepOne = ({ handleClose, changeStep, toggleUploadProgress }: InitiatePaymentStepOneProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  console.log(state);
  let timeOutId:any = null;
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [fields, setFields] = useState({
    paymentList: '',
    paymentName: '',
    paymentDescription: '',
    dateRange: ''
  })
  const [toggleCreate, setToggleCreate] = useState(false);
  const [uploadedFile, setUploadedFile] = useState("");
  const [file, setFile] = useState("");
  const pastMonth = new Date();

  useEffect(() => {
    if (state.submitReducer.results) {
      setUploadedFile(state.submitReducer.results.data.fileUri)
    }
  }, [state.submitReducer])

  useEffect(() => {
    if (Object.keys(state.paymentStepReducer.results.stepOne).length > 0) {
      setFields({
        paymentList: '',
        paymentName: state.paymentStepReducer.results.stepOne.paymentName,
        paymentDescription: state.paymentStepReducer.results.stepOne.paymentDescription,
        dateRange: state.paymentStepReducer.results.stepOne.dateRange
      })
      setUploadedFile(state.paymentStepReducer.results.stepOne.file);

    }
  }, [])

  const inputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues[name] = value;
    await setFields(fieldsValues);
  };

  // const toggleCreateList = () => {
  //   if (toggleCreate) {
  //     setToggleCreate(false);
  //     return;
  //   }
  //   setToggleCreate(true);
  //   inputChange("paymentList", "");
  // }

  const onClickHandler = (value: boolean) => {
    if(selectedMenu !== false){
      setSelectedMenu(false);
      return;
    }
    setSelectedMenu(value);
  }

  const onBlurHandler = () => {
      timeOutId = setTimeout(() => {
        setSelectedMenu(false);
      });
  }

  const onFocusHandler = () =>{
      clearTimeout(timeOutId);
  }

  const defaultSelected: DateRange = {
    from: pastMonth,
    to: addDays(pastMonth, 4)
  };
  const [range, setRange] = useState<DateRange | any>(defaultSelected);

  let footer = <p>Please pick the first day.</p>;
  if (range !== undefined && range.from) {
    if (!range.to) {
      footer = <p style={{ fontFamily: "Mulish" }}>{format(range.from, 'PPP')}</p>;
    } else if (range.to) {
      footer = (
        <p style={{ fontFamily: "Mulish" }}>
          {format(range.from, 'PPP')}–{format(range.to, 'PPP')}
        </p>
      );
    }
  }

  const fileUpload = async (file: any) => {
    toggleUploadProgress(true);
    setFile(file.name);
    let value = {
      file,
      fileType: 'PAYMENT'
    }
    await dispatch(SubmitActionsUpload('/files/upload/', value));
    toggleUploadProgress(false);
  }

  const onFormSubmit = () => {
    return async (evt: React.FormEvent) => {
      evt.preventDefault();
      let data = {
        file: uploadedFile,
        paymentName: fields.paymentName,
        paymentDescription: fields.paymentDescription,
        dateRange: range
      };
      await dispatch(stepOne(data));
      changeStep(2);
    }
  }

  return (
    <InitiatePaymentStepOneStyle>
      <form onSubmit={onFormSubmit()}>
        <div className={`container-full form`}>
          <div className={`container-full form__desc`}>
            <HeaderText value='Step 1:' className='step' />
            <HeaderText value='Payment Details' className='details' />
          </div>
          <div className={`container-full form__content`}>
            <div className={`container-full form__content__row`}>
              <div className={`div-sm-12 form__content__row__item`}>
                <HeaderText value='Upload Your Payment List' />
                <div className={`container-full center form__content__row__item__upload`}>
                  <Text value='Select file for upload' />
                  <Input type="file" className="input"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(event) => fileUpload(event.target.files[0])}
                  />
                </div>
                <div className={`container-full form__content__row__item__assistive-text`}>
                  <Text value={file} />
                  <div className='form__content__row__item__assistive-text__right'>
                    <Text value='Not sure about the format?' />

                    <a href={template} download target="_blank"> Download the Template </a>
                  </div>
                </div>
              </div>
            </div>
            <div className={`container-full form__content__row`}>
              {/* <div className={`div-sm-12 form__content__row__item`}>
                <HeaderText value='Payment List' />
                <div className='input'>
                  <select
                    name="paymentList"
                    onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                    value={fields.paymentList}
                    disabled={toggleCreate}
                  >
                    <option value="" hidden> Select List </option>
                    <option value="jdjf"> sam's list </option>

                  </select>
                  <Image image={caret} />
                </div>
                <div className={`container-full form__content__row__item__assistive-text`}>
                  <Text value={
                    toggleCreate ? 'remove new' : 'create new'
                  } onClick={() => toggleCreateList()} />
                </div>
              </div> */}
            </div>
            <div className={`container-full form__content__row`}>
              <div className={`div-sm-12 form__content__row__item`}>
                <HeaderText value='Payment Name' />
                <Input type='text' className="input" placeholder="Enter Payment Name"
                  name="paymentName"
                  onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                  value={fields.paymentName}
                  required
                />
              </div>
            </div>
            <div className={`container-full form__content__row`}>
              <div className={`div-sm-12 form__content__row__item`}>
                <HeaderText value='Payment Description' />
                <Input type='text' className="input" placeholder="Enter Payment Description"
                  name="paymentDescription"
                  onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                  value={fields.paymentDescription}
                  required
                />
              </div>
            </div>
            <div className={`container-full form__content__row`}>
              <div className={`div-sm-12 form__content__row__item`} onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
                <HeaderText value='Payment Date Range' />
                <button type="button" className={`button --elipses`} onClick={() => onClickHandler(true)} aria-haspopup="true" aria-expanded={selectedMenu}>
                  <Input type="text" className={`input --date`}
                    placeholder="Select Date Range"
                    value={(range) ? `${dayjs(range.from).format("YYYY-MM-DD")} to ${dayjs(range.to).format("YYYY-MM-DD")}` : ''}
                    name="dateRange"
                    onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                    readOnly
                  />
                </button>
                {
                  selectedMenu !== false && (
                    <DayPicker
                      mode="range"
                      defaultMonth={pastMonth}
                      selected={range}
                      footer={footer}
                      modifiersStyles={{
                        selected: {
                          background: 'blue',
                          color: 'white',
                          fontWeight: 'bold',
                        },
                      }}
                      onSelect={setRange}
                      style={{ position: "absolute", left: "0", marginTop: "10px", background: "white", boxSizing: "border-box", padding: "35px", boxShadow: "0px 15px 44px rgba(10, 18, 73, 0.15)" }}
                      styles={{
                        day: { boxSizing: "border-box", padding: '10px', borderRadius: "8px", fontFamily: "Mulish" },
                        caption: { fontFamily: "Mulish" },
                      }}
                    />
                  )
                }
              </div>
            </div>
          </div>
          <div className={`container-full center form__action`}>
            <Button type="button" value="Cancel" className={`button --cancel`} onClick={() => handleClose()} />
            <Button type="submit" value="Continue" className={`button --continue ${uploadedFile === "" ? 'disabled' : ''
              }`} isLoading={state.submitReducer.fetching} />
          </div>
        </div>
      </form>
    </InitiatePaymentStepOneStyle>
  );
};

export default InitiatePaymentStepOne;
