import styled from "styled-components";
import Colors from '../../../helpers/Colors';

const RolesAndPermissionsStyle = styled.div`
  width: 100%;

  .roles{
    align-items: flex-start;
  }
  .roles__roles-box{
    -ms-flex: 0 0 25%;
    -webkit-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 11px 9px rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    padding: 12px 12px 32px 12px;
  }
  .roles__roles-box__group{
    p{
      color: rgba(37, 39, 51, 0.3);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
    }
  }
  .--user-created-roles{
    margin-top: 20px;
  }
  .roles__roles-box__group__list{
    margin-top: 10px;
  }
  .roles__roles-box__group__list__item{
    box-sizing: border-box;
    cursor: pointer;
    height: 36px;
    padding: 10px 15px;
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }
  }
  .--active-role{
    background: #F9F9FB;
    border-radius: 8px; 
    h2{
      font-weight: 700;
    }
  }
  .roles__permissions{
    -ms-flex: 0 0 72.7%;
    -webkit-flex: 0 0 72.7%;
    flex: 0 0 72.7%;
    max-width: 72.7%;
    background: #FFFFFF;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
  }
  .roles__permissions__empty{
    margin: 203px 0 203px 0;
  }
  .roles__permissions__empty__box{
    margin-bottom: 0;
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      width: 100%;
    }
  }
  .roles__permissions__desc{
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    box-sizing: border-box;
    height: 54px;
    padding: 0 12px 0 12px;
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      margin-top: 15.5px;
      text-align: center;
      width: 100%;
    }
  }
  .roles__permissions__info{
    margin-top: 10px;
  }
  .roles__permissions__info__text{
    align-items: flex-start;
    display: flex;
    img{
      margin: 0;
    }
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 0.875em;
      line-height: 18px;
      margin-left: 7px;
      span{
        font-family: 'Mulish';
        font-weight: 600;
      }
    }
    .--warning{
      color: #BD0F10;
    }
  }
  .roles__permissions__info__switch{
    display: flex;
    margin-top: 1px;
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      margin-left: 6.5px;
    }
  }
  .roles__permissions__info__switch__checkbox{
    position: relative;
    .input{
      height: 18px;
      position: absolute;
      opacity: 0;
      width: 30px;
      &:checked + .roles__permissions__info__switch__checkbox__box{
        background: #1B9830;
        .roles__permissions__info__switch__checkbox__box__ball{
          background: white;
          margin-left: 10px;
        }
      }
    }
    .disabled{
      &:checked + .roles__permissions__info__switch__checkbox__box{
        background: #1B9830;
        opacity: 0.3;
      }
    }
  }
  .roles__permissions__info__switch__checkbox__box{
    background: #E3E5ED;
    border-radius: 70px;
    box-sizing: border-box;
    height: 18px;
    padding: 2px;
    transition: all 0.2s linear;
    width: 28px;
  }
  .roles__permissions__info__switch__checkbox__box__ball{
    background: #D1D5E0;
    border-radius: 50%;
    height: 14px;
    transition: margin 0.5s linear;
    width: 14px;
  }
  .roles__permissions__box{
    background: #FCFCFD;
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 29px;
    padding: 12px 20px 12px 18px;
  }
  .roles__permissions__box__left{
    -ms-flex: 0 0 35%;
    -webkit-flex: 0 0 35%;
    flex: 0 0 35%;
    max-width: 35%;
  }
  .roles__permissions__box__left__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 1.125em;
      letter-spacing: 0.01em;
      line-height: 23px;
      margin-bottom: 4.3px;
    }
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }
  }
  .roles__permissions__box__left__info{
    margin-top: 36px;
  }
  .roles__permissions__box__left__info__desc{
    margin-bottom: 10px;
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
    }
    p{
      color: rgba(37, 39, 51, 0.5);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      margin-top: 7px;
    }
  }
  .roles__permissions__box__left__info__list{
    box-sizing: border-box;
    justify-content: normal;
    padding-left: 9px;
  }
  .roles__permissions__box__left__info__list__item{
    justify-content: normal;
    margin-bottom: 2px;
    position: relative;
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
      margin-left: 12px;
      width: 95%;
      span{
        font-family: 'Mulish';
        font-weight: 400;
      }
    }
  }
  .roles__permissions__box__left__info__list__item__bullet{
    background: #252733;
    border-radius: 50%;
    height: 5px;
    left: 0;
    margin-top: 6px;
    position: absolute;
    top: 0;
    width: 5px;
  }
  .roles__permissions__box__right{
    -ms-flex: 0 0 62%;
    -webkit-flex: 0 0 62%;
    flex: 0 0 62%;
    max-width: 62%;
    background: white;
    border-radius: 7px;
    box-sizing: border-box;
    padding: 5px;
  }
  .roles__permissions__box__right__group{
    margin-bottom: 9.5px;
  }
  .roles__permissions__box__right__group__desc{
    background: #F5F9FE;
    box-sizing: border-box;
    margin-bottom: 9.5px;
    padding: 3.5px 20px 3.5px 20px;
    p{
      color: rgba(37, 39, 51, 0.4);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 13px;
      text-transform: uppercase;
    }
  }
  .roles__permissions__box__right__group__item{
    box-sizing: border-box;
    justify-content: normal;
    margin-bottom: 19px;
    padding: 0 20px 0 20px;
    &:last-child{
      margin-bottom: 9.5px;
    }
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      width: 90%;
    }
  }
  .roles__permissions__box__right__group__item__checkbox{
    position: relative;
    .input{
      left: 0;
      margin-top: -13px;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 14.5px;
      z-index: 500;
      &:checked + {
        .roles__permissions__box__right__group__item__checkbox__box{
          background: #3751FF;
          img{
            transform: scale(1);
          }
        }
      }
    }
    .disabled{
      &:checked + {
        .roles__permissions__box__right__group__item__checkbox__box{
          background: #8790CC;
        }
      }
    }
  }
  .roles__permissions__box__right__group__item__checkbox__box{
    background: white;
    border: 0.52531px solid #B5BABD;
    border-radius: 3px;
    height: 14.5px;
    position: relative;
    transition: background 0.5s linear;
    width: 14.5px;
    img{
      left: 0;
      margin-left: 3.5px;
      margin-top: 4.5px;
      position: absolute;
      transform: scale(0);
      transition: all 0.5s linear;
      top: 0;
    }
  }
  .roles__permissions__action{
    justify-content: flex-end;
    margin-top: 30px;
    .button{
      background: #3751FF;
      border-radius: 8px;
      color: white;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      height: 34px;
      padding-left: 8px;
      width: 123px;
    }
  }
`;

export default RolesAndPermissionsStyle;