import styled from "styled-components";
import Colors from "../../../helpers/Colors";
// import Colors from '../../../helpers/Colors';

const OngoingPaymentsListSummaryStyle = styled.div`
  width: 100%;
  .data{
    flex-wrap: nowrap;
    justify-content: normal;
    overflow: auto;
  }
  .data__item{
    -ms-flex: 0 0 191px;
    -webkit-flex: 0 0 191px;
    background: #FDFDFD;
    border: 1px solid #F5F5F5;
    border-radius: 8px;
    box-sizing: border-box;
    flex: 0 0 191px;
    margin-right: 8px;
    max-width: 191px;
    padding: 8px;
  }
  .data__item__desc{
    p{
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 2px;
      width: 100%;
    }
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .data__item__box{
    background: #F2F3F6;
    box-sizing: border-box;
    height: 40px;
    margin-top: 12px;
    padding: 12px 10px;
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      font-feature-settings: 'salt' on;
    }
  }
  .data__item__record{
    box-sizing: border-box;
    padding: 15px 10px;
  }
  .data__item__record__row{
    margin-bottom: 23px;
    &:last-child{
      margin-bottom: 0;
    }
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      font-feature-settings: 'salt' on;
    }
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      font-feature-settings: 'salt' on;
      width: 100%;
    }
  }
  .--salary{
    margin-bottom: 16px;
  }
  .data__item__record__row__salary{
    display: flex;
    p{
      margin-top: 2px;
    }
  }
  .data__item__record__row__salary__label{
    background: #F0F0F0;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 3px;
    margin-left: 7px;
    padding: 2px 6px;
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      font-feature-settings: 'salt' on;
    }
  }
  .pagination{
    border-top: 1px solid #EEEEEE;
    box-sizing: border-box;
    margin-top: 30px;
    padding: 20px 40px 20px 20px;
  }
  .pagination__box{
    display: flex;
    margin-bottom: 0;
    margin-top: 10px;
    .button{
      border-radius: 5px;
      background: #3751FF;
      box-shadow: 0px 4px 12px rgba(13, 43, 242, 0.1);
      box-sizing: border-box;
      color: white;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      height: 32px;
      line-height: 16px;
      width: 140px;
      &:nth-of-type(1){
        margin-right: 8px;
      }
    }
  }
  .pagination__action{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    .button{
      border-radius: 5px;
      box-sizing: border-box;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      height: 32px;
      line-height: 16px;
      position: relative;
      img{
        position: absolute;
        left: 0;
        margin-left: 15px;
        margin-top: 8px;
        top: 0;
      }
    }
    .--submit{
      background: #3751FF;
      box-shadow: 0px 4px 12px rgba(13, 43, 242, 0.1);
      padding: 8px 15px 8px 34px;
      width: 117px;
      p{
        color: #FFFFFF;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        text-align: left;
      }
    }
    .--cancel{
      background: #F5F5F5;
      box-shadow: 0px 4px 12px rgba(37, 39, 51, 0.08);
      color: #252733;
      margin-right: 8px;
      padding: 8px 15px;
      width: 71px;
    }
   
  }
  .pagination__action__save{
    display: flex;
    margin-right: 38px;
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.2px;
      margin-top: 10px;
    }
  }
  .pagination__action__save__checkbox{
    input[type='checkbox'] {
      -moz-appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;
      background: #FFFFFF;
      border-radius: 3px;
      height: 12.67px;
      margin-right: 6.17px;
      margin-top: 13.67px;
      outline: 0.52531px solid #B5BABD !important;
      position: relative;
      transition: all 0.2s linear;
      width: 12.67px;
      & + img {
        margin-left: -16px;
        margin-top: 17px;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: all 0.2s linear;
        transform: scale(0);
        transform-origin: bottom left;
      }
      &:checked {
        background: #3751FF;
        outline: 2px solid #3751FF !important;
      }
      &:checked + img {
        transform: scale(1);
      }
    }
  }
  
`;

export default OngoingPaymentsListSummaryStyle;