import React, { useEffect, useState } from 'react';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import { NavLink, useLocation } from 'react-router-dom';
import DashboardMenuStyle from './DashboardMenuStyle';
import logo from '../../../assets/images/logo.svg';
import dashboardIcon from '../../../assets/images/dashboard-icon.svg';
import merchantsIcon from '../../../assets/images/merchants-icon.svg';
import payrollIcon from '../../../assets/images/payroll-icon.svg';
import reportsIcon from '../../../assets/images/reports-icon.svg';
import settingsIcon from '../../../assets/images/settings-icon.svg';
import helpIcon from '../../../assets/images/help-icon.svg';
import caret from '../../../assets/images/caret-down.svg';
import circle from '../../../assets/images/circle.svg';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../config/ConfigStore';
import photo from '../../../assets/images/photo.svg';
import Img from '../../atoms/Image/Img';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';
import cogoToast from "cogo-toast";
import globalManual from '../../../assets/document/Global User ETAS 2.0 Manual.pdf';
import merchantManual from '../../../assets/document/Merchant User Manual ETAS 2.0.pdf';

const DashboardMenu = () => {
  const state = useSelector((state: RootStore) => state);
  const location = useLocation();
  const checkIfPayrollIsActive = location.pathname.includes('payroll-config');
  const checkIfSettingsIsActive = location.pathname.includes('settings');
  const checkIfGlobalParameterssIsActive = location.pathname.includes('global-parameters');
  const checkIfMerchantIsActive = location.pathname.includes('merchants');
  const checkIfPaymentsIsActive = location.pathname.includes('payments');
  const checkIfReportsIsActive = location.pathname.includes('reports');
  const [url, setFileUrl] = useState<string>();

  useEffect(() => {
    if(state.authReducer.results.profilePhotoUri){
        openFile(state.authReducer.results.profilePhotoUri);
    }
  }, [state.dashboardReducer])

  const toggleAccordion = () => {
    return (evt: any) => {
      console.log(evt.target.parentElement.nextSibling);
      if (evt.target.parentElement.nextSibling.style.maxHeight) {
        evt.target.parentElement.nextSibling.style.maxHeight = null;
        evt.target.lastChild.style.transform = "rotate(180deg)"
      }
      else {
        evt.target.parentElement.nextSibling.style.maxHeight = "10rem";
        evt.target.lastChild.style.transform = "rotate(360deg)"
      }
    }
  }

  const openFile = async (fileUri: string) => {
    const apiDefinition = () => {
      return axios.get(`${fileUri.substring(4)}`,
        {
          responseType: "blob",
          headers: {
            "Authorization": `Bearer ${Cookies.get("userToken")}`,
          }
        }
      );
    };
    try {
      const { data } = await apiDefinition();
      const url = URL.createObjectURL(new Blob([data]));
      setFileUrl(url);
    } catch (error) {
      cogoToast.error("Error downloading file", { position: "top-right" })
    }
  }

  return (
    <DashboardMenuStyle>
      <div className={`container-full dashboard-menu`}>
        <div className={`container-full center dashboard-menu__logo`}>
          <Image image={logo} alt="logo" />
        </div>
        <div className={`container-full dashboard-menu__menus`}>
          <NavLink
            to="/dashboard"
            className={({ isActive }) =>
              isActive ? "active" : undefined
            }
          >
            <div className={`container-full dashboard-menu__menus__item`}>
              <Image image={dashboardIcon} alt="icon" />
              <Text value="Dashboard" />
            </div>
          </NavLink>
          {
            state.authReducer.results.user_level === 2 ?
              <NavLink
                to="/merchants"
                className={checkIfMerchantIsActive ? 'active' : undefined}
              >
                <div className={`container-full dashboard-menu__menus__item`}>
                  <Image image={merchantsIcon} alt="icon" />
                  <Text value="Merchants" />
                </div>
              </NavLink> : null
          }
          {
            state.authReducer.results.user_level === 1 &&
            <NavLink
              to="/payments/ongoing-payments"
              className={checkIfPaymentsIsActive ? 'active' : undefined}
            >
              <div className={`container-full dashboard-menu__menus__item`}>
                <Image image={payrollIcon} alt="icon" />
                <Text value="Payments" />
              </div>
            </NavLink>
          }

          {
            state.authReducer.results.user_level === 2 ?
              <NavLink
                to="/payroll-config/issuers"
                className={checkIfPayrollIsActive ? 'active' : undefined}
              >
                <div className={`container-full dashboard-menu__menus__item`}>
                  <Image image={payrollIcon} alt="icon" />
                  <Text value="Payroll Config" />
                </div>
              </NavLink> : null
          }

          <NavLink
            to={state.authReducer.results.user_level === 2 ? "/reports/audit/global-users" : "reports/audit"}
            className={checkIfReportsIsActive ? 'active' : undefined}
          >
            <div className={`container-full dashboard-menu__menus__item --parent`} onClick={toggleAccordion()}>
              <Image image={reportsIcon} alt="icon" />
              <Text value="Reports" />
              <Image image={caret} alt="caret" className={`caret`} />
            </div>
          </NavLink>
          <div className={`container-full dashboard-menu__menus__item__sub-menu`}>
            <NavLink
              to={state.authReducer.results.user_level === 2 ? "/reports/audit/global-users" : "reports/audit"}
              className={({ isActive }) =>
                isActive ? "active" : undefined
              }
            >
              <div className={`container-full dashboard-menu__menus__item__sub-menu__item`}>
                <Text value="Audit Log" />
                <Image image={circle} alt="circle" />
              </div>
            </NavLink>
            <NavLink
              to="/reports/payment-report"
              className={({ isActive }) =>
                isActive ? "active" : undefined
              }
            >
              <div className={`container-full dashboard-menu__menus__item__sub-menu__item`}>
                <Text value="Payment Report" />
                <Image image={circle} alt="circle" />
              </div>
            </NavLink>
            <NavLink
              to="/reports/partner-report"
              className={({ isActive }) =>
                isActive ? "active" : undefined
              }
            >
              <div className={`container-full dashboard-menu__menus__item__sub-menu__item`}>
                <Text value="Partner Report" />
                <Image image={circle} alt="circle" />
              </div>
            </NavLink>
            {
              state.authReducer.results.user_level === 2 &&
              <NavLink
                to="/reports/fee-report"
                className={({ isActive }) =>
                  isActive ? "active" : undefined
                }
              >
                <div className={`container-full dashboard-menu__menus__item__sub-menu__item`}>
                  <Text value="Fee Report" />
                  <Image image={circle} alt="circle" />
                </div>
              </NavLink>
            }
          </div>
          <NavLink
            to={
              (state.authReducer.results.user_level === 1) ? `/settings/business-profile` : '/settings/profile'
            }
            className={checkIfSettingsIsActive ? "active" : undefined}
          >
            <div className={`container-full dashboard-menu__menus__item`}>
              <Image image={settingsIcon} alt="icon" />
              <Text value="Account Settings" />
            </div>
          </NavLink>
          {
            state.authReducer.results.user_level === 2 ?
              <NavLink
                to="/global-parameters"
                className={checkIfGlobalParameterssIsActive ? "active" : undefined}
              >
                <div className={`container-full dashboard-menu__menus__item`}>
                  <Image image={settingsIcon} alt="icon" />
                  <Text value="Global Parameters" />
                </div>
              </NavLink> : null
          }

          <a href={state.authReducer.results.user_level === 2 ? globalManual : merchantManual} target="_blank">
            <div className={`container-full dashboard-menu__menus__item`}>
              <Image image={helpIcon} alt="icon" />
              <Text value="Help" />
            </div>
          </a>
        </div>
        <div className={`container-full dashboard-menu__user`}>
          <div className={`dashboard-menu__user__img`}>
            {
              (state.authReducer.results.profilePhotoUri && url) ?
                <Img image={url} className={`dp`} /> : <Img image={photo} />
            }
          </div>
          <div className={`dashboard-menu__user__text`}>
            <HeaderText value={state.authReducer.results.first_name} />
            <Text value={state.authReducer.results.last_name} />
          </div>
        </div>

      </div>
    </DashboardMenuStyle>
  );
};

export default DashboardMenu;
