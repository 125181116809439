import React, { useState, useEffect } from 'react';
import SetFeesModalStyle from './SetFeesModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import Input from '../../atoms/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { SubmitActionsPOST } from '../../../redux/actions/SubmitAction';
import { alertActions } from '../../../redux/actions/AlertActions';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';
import closeIcon from '../../../assets/images/close-icon-round.svg';
import dropdown from '../../../assets/images/dropdown.svg';
import caret from '../../../assets/images/caret-down-select.svg';
import Img from '../../atoms/Image/Img';
import nike from '../../../assets/images/nike.svg';
import SetFeesForm from '../../molecules/SetFeesForm/SetFeesForm';
import FeeSummaryForm from '../../molecules/FeeSummaryForm/FeeSummaryForm';

type SetFeesModalProps = {
  handleClose: Function,
  reloadPage: Function
}

const SetFeesModal = ({ handleClose, reloadPage }: SetFeesModalProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  const [step, setStep] = useState(1);

  const setStepNumber = () => {
    let nextStep:number = step;
    setStep(++nextStep);
  }

  const goToStep = (index: number) => {
    if(step > index){
      setStep(index)
    }
  }

  return (
    <SetFeesModalStyle className={`container-full`}>
      <div className={`container-full center modal-container`}>
        <div className={`modal-container__box`}>
          <div className={`container-full modal-container__box__header`}>
            <HeaderText value='Set Fees' />
            <Image image={close} onClick={() => handleClose()} />
          </div>
          <div className={`container-full modal-container__box__desc`}>
            <Text value='You must set fees for all statutory bodies to complete fee setup. Start below' />
          </div>
          <div className={`container-full modal-container__box__nav`}>
            <div className={`modal-container__box__nav__item --nhis 
                ${
                  step > 1 ? 'completed' : ''
                }
                ${
                  step === 1 ? 'active-nav' : ''
                }
              `}
              onClick={() => goToStep(1)}
            >
              <div className={`modal-container__box__nav__item__circle`}>
                <Image image={nike} alt="nike" className='img' />
              </div>
              <Text value='NHIS' />
            </div>
            <div className={`modal-container__box__nav__item --nhf 
                ${
                  step > 2 ? 'completed' : ''
                }
                ${
                  step === 2 ? 'active-nav' : ''
                }
              `}
              onClick={() => goToStep(2)}
            >
              <div className={`modal-container__box__nav__item__circle`}></div>
              <Text value='NHF' />
            </div>
            <div className={`modal-container__box__nav__item --pensions
                ${
                  step > 3 ? 'completed' : ''
                }
                ${
                  step === 3 ? 'active-nav' : ''
                }
              `}
              onClick={() => goToStep(3)}
            >
              <div className={`modal-container__box__nav__item__circle`}></div>
              <Text value='Pensions' />
            </div>
            <div className={`modal-container__box__nav__item --paye
                ${
                  step > 4 ? 'completed' : ''
                }
                ${
                  step === 4 ? 'active-nav' : ''
                }
              `}
              onClick={() => goToStep(4)}
            >
              <div className={`modal-container__box__nav__item__circle`}></div>
              <Text value='PAYE' />
            </div>
            <div className={`modal-container__box__nav__item --salary
                ${
                  step > 5 ? 'completed' : ''
                }
                ${
                  step === 5 ? 'active-nav' : ''
                }
              `}
              onClick={() => goToStep(5)}
            >
              <div className={`modal-container__box__nav__item__circle`}></div>
              <Text value='Salary' />
            </div>
            <div className={`modal-container__box__nav__item --deductions
                ${
                  step > 6 ? 'completed' : ''
                }
                ${
                  step === 6 ? 'active-nav' : ''
                }
              `}
              onClick={() => goToStep(6)}
            >
              <div className={`modal-container__box__nav__item__circle`}></div>
              <Text value='Deductions' />
            </div>
            <div className={`modal-container__box__nav__item --summary
                ${
                  step === 7 ? 'active-nav' : ''
                }
              `}
            >
              <div className={`modal-container__box__nav__item__circle`}></div>
              <Text value='Summary' />
            </div>
          </div>
          {
            step === 1 ? <SetFeesForm handleClose={handleClose} feeType="nhis" setStep={setStepNumber} /> : null
          }
          {
            step === 2 ? <SetFeesForm handleClose={handleClose} feeType="nhf" setStep={setStepNumber} /> : null
          }
          {
            step === 3 ? <SetFeesForm handleClose={handleClose} feeType="pension" setStep={setStepNumber} /> : null
          }
          {
            step === 4 ? <SetFeesForm handleClose={handleClose} feeType="paye" setStep={setStepNumber} /> : null
          }
          {
            step === 5 ? <SetFeesForm handleClose={handleClose} feeType="salary" setStep={setStepNumber} /> : null
          }
          {
            step === 6 ? <SetFeesForm handleClose={handleClose} feeType="deductions" setStep={setStepNumber} /> : null
          }
          {
            step === 7 ? <FeeSummaryForm handleClose={handleClose} reloadPage={reloadPage}/> : null
          }
        </div>
      </div>
    </SetFeesModalStyle>
  );
}

export default SetFeesModal;