import React, { useState, useEffect } from 'react';
import FileProcessingModalStyle from './FileProcessingModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import loading from '../../../assets/images/loading.gif';


type FileProcessingModalProps = {
  header: string
  data: any
}

const FileProcessingModal = ({ data, header }: FileProcessingModalProps) => {

  return (
    <FileProcessingModalStyle className={`container-full`}>
      <div className={`container-full center modal-container`}>
        <div className={`modal-container__box`}>
          <div className={`container-full center modal-container__box__header`}>
            <HeaderText value={header} />
          </div>
          <div className={`container-full center modal-container__box__loading`}>
            <Image image={loading} />
          </div>
          <div className={`container-full center modal-container__box__text`}>
            <Text value={`${data.processedCount}/${data.beneficiaryCount}`} />
          </div>
        </div>
      </div>
    </FileProcessingModalStyle>
  );
}

export default FileProcessingModal;