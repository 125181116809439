import styled from "styled-components";
import calendar from '../../../assets/images/calendar.svg';

const InitiatePaymentStepTwoStyle = styled.div`
  position: relative;
  width: 100%;

  form{
    width: 100%;
  }
  .form__desc{
    margin-bottom: 20px;
    .step{
      color: rgba(37, 39, 51, 0.5);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      width: 100%;
    }
    .details{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      width: 100%;
    }
  }
  .form__add-deduction{
    background: #FCFDFE;
    border: 1px solid #F0F1F7;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px 10px 18px 10px;
    form{
      width: 100%;
    }
    .additional-text{
      color: red;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin-top:5px;
    }
  }
  .form__add-deduction__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: -0.01em;
      line-height: 18px;
    }
  }
  .form__add-deduction__content{
    margin-top: 12px;
  }
  .form__add-deduction__content__row{
    margin-bottom: 12px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .form__add-deduction__content__row__item{
    margin-bottom: 0;
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 8px;
    }
    .input{
      background: rgba(55, 81, 255, 0.025);
      border: none;
      border-radius: 6px;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      height: 40px;
      padding-left: 15px;
      position: relative;
      width: 100%;
      &::placeholder{
        color: rgba(37, 39, 51, 0.3);
      }
    }
    select{
      appearance: none;
      background: transparent;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      margin-top: 10px;
      width: 100%;
    }
    select:required:invalid {
        color: rgba(37, 39, 51, 0.3);
    }
    img{
        margin-right: 8px;
        margin-top: 11px;
        position: absolute;
        right: 0;
        top: 0;
    }
  }
  .form__add-deduction__action{
    justify-content: flex-end;
    margin-top: 10px;
    .button{
      background: transparent;
      border-radius: 3px;
      box-sizing: border-box;
      color: #3751FF;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      height: 36px;
      letter-spacing: 0.2px;
      padding: 8px 12px 8px 34px;
      position: relative;
      width: 147px;
      img{
        left: 0;
        margin-left: 12px;
        margin-top: 12px;
        position: absolute;
        top: 0;
      }
    }
  }
  .form__deductions{
    background: #FCFDFE;
    border: 1px solid #F0F1F7;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding: 11px 10px 10px 10px;
  }
  .form__deductions__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: -0.01em;
      line-height: 18px;
    }
    img{
      cursor: pointer;
    }
  }
  .form__deductions__content{
    margin-top: 12px;
  }
  .form__deductions__content__row{
    margin-bottom: 12px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .form__deductions__content__row__item{
    margin-bottom: 0;
    h2{
      color: rgba(37, 39, 51, 0.23);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 8px;
    }
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .form__action{
    margin-top: 78px;
  }
  .form__action__right{
    .button{
      border-radius: 8px;
      font-family: 'Mulish';
      font-size: 16px;
      height: 50px;
      margin-bottom: 0;
  }
  .--cancel{
      background: #F5F5F5;
      color: #252733;
      font-weight: 600;
      margin-right: 20px;
      width: 102px;
  }
  .--continue{
      background: #3751FF;
      box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
      color: white;
      font-weight: 700;
      width: 118px;
  }
  }
  .form__action__left{
    .button{
      background: transparent;
      border-radius: 8px;
      color: #252733;
      font-family: 'Mulish';
      font-size: 16px;
      font-weight: 600;
      height: 50px;
      margin-bottom: 0;
      width: 102px;
    }
  }
  
  
`;

export default InitiatePaymentStepTwoStyle;