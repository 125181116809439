import styled from "styled-components";

const ChangePasswordModalStyle = styled.div`
    animation: show-bg 0.5s linear forwards;
    box-sizing:border-box;
    display:flex;
    height:100%;
    left:0;
    position:absolute;
    position:fixed;
    top: 0;
    width:100%;
    z-index:5000;
    @keyframes show-bg{
        0%{
            background:rgba(0, 0, 0, 0)
        }
        100%{
            background:rgba(37, 39, 51, 0.7);
        }
    }
    .modal-container{
        box-sizing:border-box;
        height:100vh;
    }
    .modal-container__box{
        -ms-flex: 0 0 505px;
        -webkit-flex: 0 0 505px;
        flex: 0 0 505px;
        max-width: 505px;
        background:#FFFFFF;
        border-radius:10px;
        box-sizing:border-box;
        padding:20px 20px 30px 20px;
    }
    .modal-container__box__header{
        box-sizing: border-box;
        padding: 0 10px 0 10px;
        h2{
           color: #252733;
           font-family: 'Mulish';
           font-style: normal;
           font-weight: 700;
           font-size: 1.375em;
           line-height: 30px;
        }
        img{
            cursor: pointer;
        }
    }
    .modal-container__box__content__desc{
        box-sizing: border-box;
        margin-top: 12px;
        padding: 0 10px 0 10px;
        p{
            color: rgba(37, 39, 51, 0.7);
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 21px;
        }
    }
    .modal-container__box__content__desc__form{
        background: #FAFAFA;
        border-radius: 10px;
        box-sizing: border-box;
        margin-top: 22px;
        padding: 25px;

    }
    .modal-container__box__content__desc__form__item{
        margin-bottom: 25px;
        &:nth-of-type(3){
            margin-bottom: 0;
        }
        .label{
            color: #9FA2B4;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 14px;
            margin-bottom: 5px;
            text-transform: uppercase;
        }
        .input{
            background: #FCFDFE;
            border: 1px solid #F0F1F7;
            border-radius: 8px;
            box-sizing: border-box;
            color: #4B506D;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 300;
            font-size: 1em;
            height: 50px;
            padding-left: 15px;
            transition: all 0.5s linear;
            width: 100%;
            &::placeholder{
              color: rgba(75, 80, 109, 0.4);
            }
            &:focus{
              border: 1px solid rgba(55, 81, 255, 0.5);
            }
            &:focus ~ .validation{
              display: block;
            }
        }
    }
    .password{
        position:relative;
        .eyes{
            cursor:pointer;
            margin-right:16.67px;
            margin-top:18.33px;
            position:absolute;
            right:0;
            top:0;
        }
    } 
    .validation{
        background: #FFFFFF;
        border-radius: 8px;
        box-shadow: 0px 5px 15px rgba(8, 9, 10, 0.1);
        box-sizing: border-box;
        display: none;
        margin-top: 50px;
        padding: 6px 20px 6px 20px;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2000;
    }
    .validation__item{
        justify-content: normal;
        margin-bottom: 7px;
        &:last-child{
          margin-bottom: 0;
        }
        img{
          margin-right: 6.25px;
          display: none;
        }
        p{
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #9FA2B4;
        }
      }
      .--active{
        img{
          display: flex;
        }
        p{
          color: #1B9830;
        }
    }
    .modal-container__box__action{
        justify-content: flex-end;
        margin-top: 27px;
        .button{
            border-radius: 8px;
            font-family: 'Mulish';
            font-weight: 500;
            font-size: 16px;
            height: 40px;
            letter-spacing: 0.2px;
            margin-bottom: 0;
        }
        .--cancel{
            background: #F5F5F5;
            color: #252733;
            margin-right: 20px;
            width: 102px;
        }
        .--change{
            background: linear-gradient(0deg, #ADB3DC, #ADB3DC), #3751FF;
            box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
            color: white;
            pointer-events:  none;
            width: 187px;
        }
        .--active-btn{
            background: #3751FF;
            pointer-events: unset;
        }
    }
    


    
`
export default ChangePasswordModalStyle;