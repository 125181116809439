import React, { useState, useEffect } from 'react';
import SignatoryGroupDetailsStyle from './EntitiesDetailsStyle';
import Text from '../../atoms/Text/Text';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import HeaderText from '../../atoms/HeaderText/Text';
import closeIcon from '../../../assets/images/close-icon-round.svg';
import dropdown from '../../../assets/images/dropdown.svg';
import icon from '../../../assets/images/add-icon-blue.svg';
import { alertActions } from '../../../redux/actions/AlertActions';
import {appInstance as axios} from '../../../axios';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import Cookies from 'js-cookie';
import { SubmitActionsPUT } from '../../../redux/actions/SubmitAction';

type SignatoryGroupDetailsProps = {
  data: any
  reloadPage: Function,
  setData: Function
}

const SignatoryGroupDetails = ({ data, reloadPage, setData }: SignatoryGroupDetailsProps) => {
  let timeOutId: any = null;
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [entitiesData, setEntitiesData]: any = useState({});
  const [signatoryList, setSignatoryList] = useState([]);
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector( (state:RootStore) => state);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    fetchSignatories();
    console.log(state);
  }, [])

  useEffect(() => {
    setEntitiesData(data);
  }, [data])

  useEffect(() => {
    if(state.submitReducer.results && submitted){
     reloadPage();
    }
   }, [state.submitReducer])

  const fetchSignatories = async () => {
    try{
      const res = await axios.get('/signatory?status=1&size=0&page=1', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setSignatoryList(res.data.data.content);
    }catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const onClickHandler = (value: boolean) => {
    if (selectedMenu !== false) {
      setSelectedMenu(false);
      return;
    }
    setSelectedMenu(value);
  }

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
      setSelectedMenu(false);
    });
  }

  const onFocusHandler = () => {
    clearTimeout(timeOutId);
  }

  const setDescription = async (value: string) =>  {
    const fieldsValues: any = Object.assign({}, entitiesData);
    fieldsValues["description"] = value;
    await setEntitiesData(fieldsValues);
  }

  const statusChange = () => {
    let signatory;
    if(entitiesData.status){
      signatory = {
        ...entitiesData,
        status: 0
      }
    }
    else{
      signatory = {
        ...entitiesData,
        status: 1
      }
    }
    setEntitiesData(signatory); 
  }

  const toggleSignatories = (item: Record<any, any>) => {
    if(entitiesData.groups && entitiesData.groups.some((role: Record<any, any>) => role.id === item.id)){
      const newArr = entitiesData.groups.filter( (record: Record<any,any>) => record.id !== item.id)
      console.log(newArr);
      let signatory = {
        ...entitiesData, 
        groups: newArr
      }
      return setEntitiesData(signatory);
    }
    if(!entitiesData.groups){
      const signatory = {
        ...entitiesData,
        groups: [item]
      };
      return setEntitiesData(signatory);
    }
    if(entitiesData.groups && entitiesData.groups.length < 1){
      const signatory = {
        ...entitiesData,
        groups: [...entitiesData.groups, item]
      };
      setEntitiesData(signatory);
    }
    if(entitiesData.groups && entitiesData.name === "Payments"){
      const signatory = {
        ...entitiesData,
        groups: [...entitiesData.groups, item]
      };
      setEntitiesData(signatory);
    }
  }

  const removeSignatory = (item: Record<any, any>) => {
    const newArr = entitiesData.groups.filter( (record: Record<any,any>) => record.id !== item.id)
    console.log(newArr);
    let signatory = {
      ...entitiesData, 
      groups: newArr
    }
    return setEntitiesData(signatory);
  }

  const saveGroup = () => {
    return (e:  React.FormEvent) => {
      e.preventDefault()
      let groups:any = [];
      entitiesData.groups.map((signatory: Record<any, any>) => {
        groups.push(signatory.id);
      })
      const values = {
        "status": entitiesData.status,
        "groups": groups
      }
      setSubmitted(true);
      dispatch(SubmitActionsPUT(`/entities/${entitiesData.id}`, values));
    }
    
  }

  return (
    <SignatoryGroupDetailsStyle>
      <div className={`container-full signatory`}>
        <div className={`container-full signatory__desc`}>
          <HeaderText value={
            Object.keys(data).length > 0 ? data.name : 'Entities'
          } />
        </div>
        {
          Object.keys(data).length > 0 ?
            <form onSubmit={saveGroup()}>
              <div className={`container-full signatory__content`}>
                <div className={`div-sm-12 signatory__content__group --description`}>
                  <HeaderText value='Description' />
                    <Text value={entitiesData.description} />
                    {/* <Input type="text" className="input" name="description" required value={entitiesData.description} onChange={(e) => setDescription(e.target.value)} /> */}
                </div>
                <div className={`div-sm-12 signatory__content__group --status`}>
                  <div className='signatory__content__group__status'>
                    <HeaderText value='Status' />
                    {
                      (entitiesData.status === 1) ? 
                      <div className='label --enabled'>
                        <Text value='Enabled' />
                      </div>: null
                    }
                    {
                      (entitiesData.status === 0) ? 
                      <div className='label --disabled'>
                        <Text value='Disabled' />
                      </div>: null
                    }
                    {
                      (entitiesData.status > 1) ? 
                      <div className='label --pending'>
                        <Text value='Pending' />
                      </div>: null
                    }
                  </div>
                  <div className={`signatory__content__group__switch ${
                    entitiesData.defaultRole ? 'disabled' : ''
                  }`}>
                    <input type="checkbox" className={`input
                     ${
                      entitiesData.defaultRole ? 'disabled' : ''
                     }
                    `} checked={entitiesData.status} onChange={() => statusChange()} />
                    <div className={`signatory__content__group__switch__box`}>
                      <div className={`signatory__content__group__switch__box__ball`}></div>
                    </div>
                  </div>
                </div>
                <div className={`div-sm-12 signatory__content__group --users`}>
                  <HeaderText value='Signatory Groups' />
                  <Text value='Manage signatories mapped to this entity' />
                  <div className={`container-full signatory__content__group__users`}>
                    {
                      (entitiesData.groups && entitiesData.groups.length > 0 && entitiesData.groups.map((signatory: Record<any, any>, key: string) => {
                        return(
                          <div className={`container-full signatory__content__group__users__item`} key={key}>
                            <div className={`signatory__content__group__users__item__number`}>
                              <Text value={key += 1} />
                            </div>
                            <div className={`signatory__content__group__users__item__name`}>
                              <Text value={`${signatory.name}`} />
                            </div>
                            <div className={`signatory__content__group__users__item__close`} onClick={() => removeSignatory(signatory)}>
                              <Image image={closeIcon} />
                            </div>
                          </div>
                        )
                      }))
                    }
                    <div className={`container-full signatory__content__group__users__item --add-user`} onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
                      <button type="button" className={`button --elipses`} onClick={() => onClickHandler(true)} aria-haspopup="true" aria-expanded={selectedMenu}>
                        <Text value='Select Signatory Group' />
                        <Image image={dropdown} />
                      </button>
                      {
                        selectedMenu !== false && (
                          <div className={`container-full more-info`}>
                            {
                              signatoryList.length > 0 ? 
                              signatoryList.map((signatory: Record<any, any>) => {
                                return(
                                  <div className={`container-full more-info__item
                                  ${
                                    (entitiesData.groups && entitiesData.groups.some((entity: Record<any, any>) => entity.id === signatory.id)) ? 'active' : ''
                                  }
                                  `} key={signatory.id}>
                                    <button type="button" onClick={() => toggleSignatories(signatory)}>
                                      <Text value={signatory.name} />
                                    </button>
                                  </div>
                                )
                              }) : ''
                            }
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
              {
                (entitiesData.status <= 1) ? 
                <div className={`container-full signatory__action`}>
                  <Button type="button" value="Cancel" className={`button --cancel`} onClick={() => setData({})}/>
                  <Button type="submit" value="Save Entity" className={`button --change ${
                   entitiesData.groups && entitiesData.groups.length > 0 ? '--active-btn' : ''
                  }${
                    (state.authReducer.results.roles.includes('Authorizer') && state.authReducer.results.roles.length === 1) 
                    || (state.authReducer.results.roles.includes('Audit') && state.authReducer.results.roles.length === 1)  ? 'disabled' : ''
                  }`} isLoading={state.submitReducer.fetching}/>
                </div> : null
              }
            </form> : 
            <div className={`container-full center signatory__empty`}>
              <HeaderText value='Nothing to Show' className='header'/> 
              <Text value='There is nothing selected. Select an item from the list to see more details' className='text'/>
            </div>
        }
      </div>
    </SignatoryGroupDetailsStyle>
  );
};

export default SignatoryGroupDetails;
