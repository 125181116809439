import styled from "styled-components";
import calendar from '../../../assets/images/calendar.svg';

const InitiatePaymentStepThreeStyle = styled.div`
  position: relative;
  width: 100%;

  form{
    width: 100%;
  }
  .form__desc{
    margin-bottom: 20px;
    .step{
      color: rgba(37, 39, 51, 0.5);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      width: 100%;
    }
    .details{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      width: 100%;
    }
  }
  .form__content{
    background: #FCFDFE;
    border: 1px solid #F0F1F7;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
  }
  .form__content__row{
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .form__content__row__item{
    margin-bottom: 0;
    .input{
      background: rgba(55, 81, 255, 0.025);
      border: none;
      border-radius: 6px;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      height: 40px;
      margin-bottom: 12px;
      padding-left: 15px;
      position: relative;
      width: 100%;
      &::placeholder{
        color: rgba(37, 39, 51, 0.3);
      }
    }
    select{
      appearance: none;
      background: transparent;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      margin-top: 10px;
      width: 100%;
    }
    select:required:invalid {
      color: rgba(37, 39, 51, 0.3);
    }
    img{
        margin-right: 8px;
        margin-top: 11px;
        position: absolute;
        right: 0;
        top: 0;
    }
    p{
      color: rgba(37, 39, 51, 0.3);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .form__invoice{
    margin-top: 20px;
  }
  .form__invoice__row__item{
    margin-bottom: 0;
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 8px;
    }
  }
  .form__invoice__row__item__file-select{
    background: #F7F9FE;
    border: 1.5px dashed rgba(55, 81, 255, 0.5);
    border-radius: 8px;
    height: 70px;
    p{
      color: rgba(37, 39, 51, 0.3);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
    }
    .input{
      appearance: none;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  .form__invoice__row__item__assistive-text{
    margin-top: 8px;
    p{
      color: #3751FF;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
    }
  }
  .form__action{
    margin-top: 144px;
  }
  .form__action__right{
    .button{
      border-radius: 8px;
      font-family: 'Mulish';
      font-size: 16px;
      height: 50px;
      margin-bottom: 0;
  }
  .--cancel{
      background: #F5F5F5;
      color: #252733;
      font-weight: 600;
      margin-right: 20px;
      width: 102px;
  }
  .--continue{
      background: #3751FF;
      box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
      color: white;
      font-weight: 700;
      width: 176px;
  }
  }
  .form__action__left{
    .button{
      background: transparent;
      border-radius: 8px;
      color: #252733;
      font-family: 'Mulish';
      font-size: 16px;
      font-weight: 600;
      height: 50px;
      margin-bottom: 0;
      width: 102px;
    }
  }
  
  
`;

export default InitiatePaymentStepThreeStyle;