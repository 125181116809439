import styled from "styled-components";
import Colors from '../../../helpers/Colors';

const PerformingMerchantsStyle = styled.div`
  background: white;
  box-sizing: border-box;
  padding-left: 24px;
  width: 100%;

  .performing-merchants{
   
  }
  .performing-merchants__menu__item{
    margin-bottom: 0;
    .button{
      background: linear-gradient(0deg, #EBEEFF, #EBEEFF), #FFFFFF;
      border-radius: 8px;
      box-sizing: border-box;
      color: #3751FF;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      height: 65px;
      line-height: 16px;
      margin-bottom: 0;
      width: 100%;
    }
  }
  .performing-merchants__table{
    margin-top: 32px;
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }
  }
  .performing-merchants__table__list{
    box-sizing: border-box;
    margin-top: 24px;
    padding: 0 10px 0 15px;
  }
  .performing-merchants__table__list__row{
    box-sizing: border-box;
    margin-bottom: 3.5px;
    padding-bottom: 15px;
    &:last-child{
      &:after{
        display: none;
      }
    }
    &:after{
      background: #F4F5F6;
      content: '';
      display: block;
      height: 1px;
      margin-left: 5px;
      margin-top: 15px;
      width: 90%;
    }
  }
  .performing-merchants__table__list__row__item{
    display: flex;
    margin-bottom: 0;
    .value{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      font-feature-settings: 'salt' on;
    }
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      font-feature-settings: 'salt' on;
    }
  }
  .performing-merchants__table__list__row__item__dot{
    background: #1B9830;
    border-radius: 50%;
    height: 8px;
    margin-right: 9px;
    margin-top: 5px;
    width: 8px;
  }
`;

export default PerformingMerchantsStyle;