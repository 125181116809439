import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Signin from './components/pages/Signin/Signin';
import ForgotPassword from './components/pages/ForgotPassword/ForgotPassword';
import MainLayout from './components/pages/MainLayout/MainLayout';
import PrivateRoute from './config/PrivateRoute';
import ResetPassword from './components/pages/ResetPassword/ResetPassword';
import 'react-day-picker/dist/style.css';

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Signin />
        }
      />
      <Route
        path="/forgot-password"
        element={
          <ForgotPassword />
        }
      />
       <Route
        path="/reset"
        element={
          <ResetPassword />
        }
      />
      <Route
        path="*"
        element={
          <PrivateRoute>
            <MainLayout />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default App;
