import styled from "styled-components";
import Colors from '../../../helpers/Colors';

const FeeSummaryFormStyle = styled.div`
  width: 100%;

  form{
    width: 100%;
  }
  .form__content{
    border-bottom: 1px solid #F0F0F0;
    border-top: 1px solid #F0F0F0;
    box-sizing: border-box;
    margin-top: 12px;
    padding: 27px 15px 34.27px 15px;
  }
  .form__content__desc{
    margin-bottom: 13px;
    h2{
      color: rgba(37, 39, 51, 0.3);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .form__content__group__item{
    margin-bottom: 20.27px;
    &:last-child{
      margin-bottom: 0;
    }
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 10.27px;
    }
    p{
      color: rgba(37, 39, 51, 0.7);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .form__details{
    box-sizing: border-box;
    padding: 27px 15px 0 15px;
  }
  .form__details__desc{
    h2{
      color: rgba(37, 39, 51, 0.5);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .form__details__nav{
    justify-content: normal;
    margin-top: 20px;
  }
  .form__details__nav__item{
    box-sizing: border-box;
    cursor: pointer;
    padding: 7px 0;
    h2{
      color: #B6B6BB;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.01em;
    }
  }
  .active-nav{
    border-bottom: 2px solid #200E32;
    padding-bottom: 8px;
    padding-top: 6px;
    h2{
      color: #200E32;
      font-weight: 600;
    }
  }
  .--nhis{
    padding-left: 25px;
    padding-right: 25px;
  }
  .--nhf{
    padding-left: 36.33px;
    padding-right: 36.33px;
  }
  .--pensions{
    padding-left: 16px;
    padding-right: 16px;
  }
  .--paye{
    padding-left: 35px;
    padding-right: 35px;
  }
  .--salary{
    padding-left: 25px;
    padding-right: 25px;
  }
  .--deductions{
    padding-left: 14.83px;
    padding-right: 14.83px;
  }
  .form__details__content{
    background: #F9FAFB;
    border: 1px solid #F0F1F7;
    border-radius: 5px;
    box-sizing: border-box;
    margin-top: 20px;
    padding: 11px 10px 15px 10px;
  }
  .form__details__content__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .form__details__content__group{
    margin-top: 18px;
  }
  .form__details__content__group__item{
    margin-bottom: 12px;
    .div-md-6{
      margin-bottom: 0;
    }
    p{
      color: rgba(37, 39, 51, 0.7);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
    }
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
    .label{
      background: #E7E7E7;
      box-sizing: border-box;
      border-radius: 5px;
      display: inline-block;
      mix-blend-mode: multiply;
      padding: 2px 6px;
      p{
        color: #6A6A73;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 16px;
        font-feature-settings: 'salt' on;
      }
    }
  }
  .form__details__content__bands{
    background: #F9FAFB;
    border-radius: 5px;
    box-sizing: border-box;
    margin-top: 8px;
    padding: 10px;
  }
  .form__details__content__bands__item{
      background: #F9FAFB;
      border: 1px solid #F0F1F7;
      border-radius: 5px;
      box-sizing: border-box;
      margin-bottom: 10px;
      mix-blend-mode: multiply;
      padding: 20px 20px 25px 20px;
      &:last-child{
          margin-bottom: 0;
      }
  }
  .form__details__content__bands__item__desc{
      h2{
          color: #130F26;
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
      }
  }
  .form__details__content__bands__item__group{
      margin-top: 19px;
  }
  .form__details__content__bands__item__group__row{
      margin-bottom: 14px;
      &:last-child{
          margin-bottom: 0;
      }
  }
  .form__details__content__bands__item__group__row__item{
      margin-bottom: 0;
      p{
          color: rgba(37, 39, 51, 0.7);
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 20px;
      }
      h2{
          color: #252733;
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
      }
  }
  .form__details__content__bands__item__group__row__item__label{
      background: #E7E7E7;
      border-radius: 5px;
      box-sizing: border-box;
      display: inline-block;
      mix-blend-mode: multiply;
      padding: 2px 6px;
      p{
          color: #6A6A73;
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          font-feature-settings: 'salt' on;
      }
  }
  .form__action{
    justify-content: flex-end;
    margin-top: 83px;
    .button{
        border-radius: 8px;
        font-family: 'Mulish';
        font-weight: 500;
        font-size: 16px;
        height: 40px;
        margin-bottom: 0;
    }
    .--cancel{
        background: #F5F5F5;
        color: #252733;
        margin-right: 20px;
        width: 102px;
    }
    .--change{
        background: #3751FF;
        box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
        color: white;
        width: 117px;
    }
  }
`;

export default FeeSummaryFormStyle;