import React, { useState, useEffect } from 'react';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import AddSourceAccountUsersStyle from './AddSourceAccountUsersFormStyle';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { signIn } from '../../../redux/actions/AuthAction';
import { useNavigate } from "react-router-dom";
import caret from '../../../assets/images/caret-down-select.svg';
import addIcon from '../../../assets/images/add-blue.svg';
import eyesClose from '../../../assets/images/eyes-pwd-close.svg';
import eyes from '../../../assets/images/eyes-pwd.svg';
import closeIcon from '../../../assets/images/close-icon-round.svg';
import dropdown from '../../../assets/images/dropdown.svg';
import { alertActions } from '../../../redux/actions/AlertActions';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';
import { SubmitActionsPOST } from '../../../redux/actions/SubmitAction';
import { stepClear } from '../../../redux/actions/AddSourcerAccountStep';

type AddSourceAccountUsersProps = {
  handleClose: Function,
  reloadPage: Function,
  setStep: Function,
}


const AddSourceAccountUsers = ({ handleClose, reloadPage, setStep }: AddSourceAccountUsersProps) => {
  let timeOutId: any = null;
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [users, setUsers]: any = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  // const navigate = useNavigate();

  useEffect(() => {
    if (state.submitReducer.results && submitted) {
      dispatch(stepClear())
      handleClose();
      reloadPage();
    }
  }, [state.submitReducer])

  useEffect(() => {
    fetchUsers();
  }, [])

  const fetchUsers = async () => {
    try {
      const res = await axios.get(`/users/roleType?${window.location.href.split("?")[1]}&type=2&size=0&page=1`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setUsersList(res.data.data.content);
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const onClickHandler = (value: boolean) => {
    if (selectedMenu !== false) {
      setSelectedMenu(false);
      return;
    }
    setSelectedMenu(value);
  }

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
      setSelectedMenu(false);
    });
  }

  const onFocusHandler = () => {
    clearTimeout(timeOutId);
  }

  const toggleState = () => {
    return (evt: any) => {
      if (evt.target.parentElement.firstChild.type === 'password') {
        evt.target.src = eyes;
        return (evt.target.parentElement.firstChild.type = 'text');
      } else {
        evt.target.src = eyesClose;
        return (evt.target.parentElement.firstChild.type = 'password');
      }
    };
  }

  const toggleUsers = (item: Record<any, any>) => {
    if (users.some((role: Record<any, any>) => role.id === item.id)) {
      const newArr = users.filter((record: Record<any, any>) => record.id !== item.id)
      console.log(newArr);
      return setUsers(newArr);
    }
    const newArr: any = [...users, item]
    setUsers(newArr);
  }

  const removeUser = (item: Record<any, any>) => {
    const newArr = users.filter((record: Record<any, any>) => record.id !== item.id)
    return setUsers(newArr);
  }

  const onFormSubmit = () => {
    return async (evt: React.FormEvent) => {
      evt.preventDefault();
      setSubmitted(true);
      const authorizers: any = [];
      await users.map((user: Record<any, any>) => {
        authorizers.push(user.id);
      })
      const value = {
        "feeCategoryId": state.addSourceAccountStepReducer.results.stepOne.feeCategory.split("+")[0],
        "accountNumber": state.addSourceAccountStepReducer.results.stepOne.accountNumber,
        "issuerCode": state.addSourceAccountStepReducer.results.stepOne.bank.split("+")[0],
        "defProcessorId": state.addSourceAccountStepReducer.results.stepOne.processor,
        "merchantId": window.location.href.split("?")[1].split("=")[1],
        "processorParams": {
          "terminalId": state.addSourceAccountStepReducer.results.stepOne.terminalID,
          "encryptionKey": state.addSourceAccountStepReducer.results.stepOne.encryptionKey
        },
        "authorizers": authorizers
      }
      await dispatch(SubmitActionsPOST("/account", value))
    }
  }

  return (
    <AddSourceAccountUsersStyle>
      <div className={`container-full form`}>
        <form onSubmit={onFormSubmit()}>
          <div className={`container-full form__content`}>
            <div className={`container-full form__content__desc`}>
              <Text value='Source Account Summary' />
            </div>
            <div className={`container-full form__content__group`}>
              <div className={`container-full form__content__group__item`}>
                <div className={`div-md-6`}>
                  <p> Bank Name </p>
                  <p> {state.addSourceAccountStepReducer.results.stepOne.bank.split("+")[1]} </p>
                </div>
                <div className={`div-md-6`}>
                  <p> Account Number </p>
                  <p> {state.addSourceAccountStepReducer.results.stepOne.accountNumber} </p>
                </div>
              </div>
              <div className={`container-full form__content__group__item`}>
                <div className={`div-md-6`}>
                  <p> Fee Category </p>
                  <p> {state.addSourceAccountStepReducer.results.stepOne.feeCategory.split("+")[1]} </p>
                </div>
                <div className={`div-md-6`}>
                  <p> Processor </p>
                  <p> {state.addSourceAccountStepReducer.results.stepOne.processor} </p>
                </div>
              </div>
              <div className={`container-full form__content__group__item`}>
                <div className={`div-md-6`}>
                  <p> Terminal ID </p>
                  <p> {state.addSourceAccountStepReducer.results.stepOne.terminalID} </p>
                </div>
                <div className={`div-md-6`}>
                  <p> Encryption Key </p>
                  <div className='password'>
                    <Input type='password' className="input" value={state.addSourceAccountStepReducer.results.stepOne.encryptionKey} onChange={() => { return false }}
                      onPaste={(event: React.FormEvent) => { event.preventDefault() }}
                      onCopy={(event: React.FormEvent) => { event.preventDefault() }}
                      onCut={(event: React.FormEvent) => { event.preventDefault() }}
                    />
                    <img
                      src={eyes}
                      onClick={toggleState()}
                    />
                  </div>
                </div>
              </div>
              <div className={`container-full form__content__group__item`}>
                <div className='div-sm-12'>
                  <p> Validated Account Name </p>
                  <p className='--account-name'> {state.addSourceAccountStepReducer.results.stepOne.accountName} </p>
                </div>
              </div>
            </div>
          </div>
          <div className={`container-full form__processor`}>
            <div className={`container-full form__processor__desc`}>
              <HeaderText value='Add Final Authorizers' />
            </div>
            <div className={`container-full form__processor__group`}>
              <div className={`container-full form__processor__group__users`}>
                <div className={`container-full form__processor__group__users__item --add-user`} onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
                  <button type="button" className={`button --elipses`} onClick={() => onClickHandler(true)} aria-haspopup="true" aria-expanded={selectedMenu}>
                    <Text value='Select a User' />
                    <Image image={dropdown} />
                  </button>
                  {
                    selectedMenu !== false && (
                      <div className={`container-full more-info`}>
                        {
                          usersList.length > 0 ?
                            usersList.map((user: Record<any, any>) => {
                              return (
                                <div className={`container-full more-info__item
                                  ${(users.some((userData: Record<any, any>) => userData.id === user.id)) ? 'active' : ''
                                  }
                                  `} key={user.id}>
                                  <button type="button" onClick={() => toggleUsers(user)}>
                                    <Text value={`${user.firstName} ${user.lastName}`} />
                                  </button>
                                </div>
                              )
                            }) : ''
                        }
                      </div>
                    )
                  }
                </div>
                {
                  (users && users.length > 0 && users.map((user: Record<any, any>, key: string) => {
                    return (
                      <div className={`container-full form__processor__group__users__item`} key={key}>
                        <div className={`form__processor__group__users__item__number`}>
                          <Text value={key += 1} />
                        </div>
                        <div className={`form__processor__group__users__item__name`}>
                          <Text value={`${user.firstName} ${user.lastName}`} />
                        </div>
                        <div className={`form__processor__group__users__item__close`} onClick={() => removeUser(user)}>
                          <Image image={closeIcon} />
                        </div>
                      </div>
                    )
                  }))
                }

              </div>
            </div>
          </div>
          <div className={`container-full form__action`}>
            <div className={`form__action__left`}>
              <Button type="button" value="Back" className={`button --cancel`} onClick={() => setStep(1)} />
            </div>
            <div className={`form__action__right`}>
              <Button type="button" value="Cancel" className={`button --cancel`} onClick={() => handleClose()} />
              <Button type="submit" value="Submit" className={`button --change ${users.length > 0 ? '--active-btn' : ''
                }`} isLoading={state.submitReducer.fetching} />
            </div>
          </div>
        </form>
      </div>
    </AddSourceAccountUsersStyle>
  );
};

export default AddSourceAccountUsers;
