import styled from "styled-components";
import Colors from '../../../helpers/Colors';

const ResetPasswordFormStyle = styled.div`
  width: 100%;

  form{
    width: 100%;
  }
  .form__item{
    margin-bottom: 29px;
    .label{
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 8px;
      text-transform: uppercase;
    }
    .input{
      background: #FCFDFE;
      border: 1px solid #F0F1F7;
      border-radius: 8px;
      box-sizing: border-box;
      color: #4B506D;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 1em;
      height: 50px;
      padding-left: 15px;
      transition: all 0.5s linear;
      width: 100%;
      &::placeholder{
        color: rgba(75, 80, 109, 0.4);
      }
      &:focus{
        border: 1px solid rgba(55, 81, 255, 0.5);
      }
      &:focus ~ .validation{
        display: block;
      }
    }
  }
  .password{
    position:relative;
    .eyes{
        cursor:pointer;
        margin-right:16.67px;
        margin-top:18.33px;
        position:absolute;
        right:0;
        top:0;
    }
  } 
  .form__action{
    .button{
      background: linear-gradient(0deg, #ADB3DC, #ADB3DC), #3751FF;
      border-radius: 8px;
      box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
      color: white;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      height: 50px;
      margin-bottom: 0;
      pointer-events: none;
      width: 100%;
    }
    .--active-btn{
      background: #3751FF;
      pointer-events: unset;
    }
  }
  .validation{
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 5px 15px rgba(8, 9, 10, 0.1);
    box-sizing: border-box;
    display: none;
    margin-top: 50px;
    padding: 6px 20px 6px 20px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2000;
  }
  .validation__item{
    justify-content: normal;
    margin-bottom: 7px;
    &:last-child{
      margin-bottom: 0;
    }
    img{
      margin-right: 6.25px;
      display: none;
    }
    p{
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #9FA2B4;
    }
  }
  .--active{
    img{
      display: flex;
    }
    p{
      color: #1B9830;
    }
  }
  
  
`;

export default ResetPasswordFormStyle;