/* eslint-disable no-shadow */
import React, {memo} from 'react';
import {Navigate} from 'react-router-dom';
import Cookies from 'js-cookie';

type LayoutProps = {
    children: JSX.Element;
};

function PrivateRoute({ children }: LayoutProps) {
    const auth = Cookies.get("userToken");
    return auth !== undefined ? children : <Navigate to="/" />;
}
export default memo(PrivateRoute);
