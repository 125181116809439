import React, { useState, useEffect } from 'react';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import OngoingPaymentsListTableStyle from './OngoingPaymentsListTableStyle';
import nike from '../../../assets/images/nike.svg';
import elipses from '../../../assets/images/elipses.svg';
import user from '../../../assets/images/user-male.svg';
import Pagination from '../Pagination/Pagination';
import UpdateIssuerModal from '../UpdateIssuerModal/UpdateIssuerModal';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { exportData } from '../../../redux/actions/ExportAction';
import { useNavigate } from "react-router-dom";
import download from '../../../assets/images/download.svg';
import upload from '../../../assets/images/upload.svg';
import Input from '../../atoms/Input/Input';
import { SubmitActionsPOST, SubmitActionsUpload } from '../../../redux/actions/SubmitAction';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import Button from '../../atoms/Button/Button';
import { alertActions } from '../../../redux/actions/AlertActions';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';


type UserTableProps = {
  data: any,
  toFilter: boolean
  statusData: any,
  pagination: number,
  setPagination: Function,
  changePage: Function,
  page: number,
  reloadPage: Function
}

const OngoingPaymentsListTable = ({ data, toFilter, pagination, setPagination, changePage, page, reloadPage, statusData }: UserTableProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state: any = useSelector((state: RootStore) => state);
  const navigate = useNavigate();
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileUploadSuccessful, setFileUploadSuccessful] = useState(false);
  const [submitRecords, setSubmitRecords] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);

  console.log(state);

  useEffect(() => {
    if (state.submitReducer.results && fileUploaded) {
      setFetchLoading(true);
      let payload = {
        fileId: state.submitReducer.results.data.fileUri.split("/v1/files/")[1],
        reUpload: true,
        paymentReference: data.data.content[0].paymentReference,
        signatoryGrpId: data.data.signatoryGroupId,
        sourceAccountId: data.data.sourceAccountId,
        paymentName: data.data.paymentName
      }
      dispatch(SubmitActionsPOST("/payment/initiate", payload));
      setFileUploadSuccessful(true)
      setFileUploaded(false);
      setFetchLoading(false);
    }
  }, [state.submitReducer])

  useEffect(() => {
    if (state.submitReducer.results !== null && fileUploadSuccessful) {
      reloadPage();
    }
  }, [state.submitReducer])

  useEffect(() => {
    if (state.submitReducer.results !== null && submitRecords) {
      navigate(`/payments/ongoing-payment/list/summary?paymentReference=${data.data.content[0].paymentReference}`);
    }
  }, [submitRecords])

  const handleAllCheck = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      await dispatch(exportData(state.dashboardReducer.results.data.content))
      console.log(state.exportReducer);
    }
    else {
      await dispatch(exportData([]));
      console.log(state.exportReducer);
    }
  };

  const handleCheckItem = (item: Record<any, any>) => {
    if (state.exportReducer.results.data.includes(item)) {
      const newArr = state.exportReducer.results.data.filter((record: Record<any, any>) => record.id !== item.id)
      console.log(newArr);
      return dispatch(exportData(newArr));
    }
    const update = [...state.exportReducer.results.data, item]
    console.log(update);
    return dispatch(exportData(update));
  };

  const fileUpload = async (file: any) => {
    setFetchLoading(true);
    let value = {
      file,
      fileType: 'PAYMENT'
    }
    setFileUploaded(true);
    setFetchLoading(false);
    await dispatch(SubmitActionsUpload('/files/upload/', value));
  }

  const renderFetchLoading = () => {
    if (fetchLoading) {
      return (
        <FetchLoading />
      )
    }
  }

  const proceedToSummary = async () => {
    await dispatch(SubmitActionsPOST(`/payment/${data.data.content[0].paymentReference}/aggregate`, {
      "paymentReference": data.data.content[0].paymentReference
    }))
    setSubmitRecords(true)
  }

  return (
    <>
      {renderFetchLoading()}
      <OngoingPaymentsListTableStyle>
        <div className={`container-full table`}>
          <table>
            <thead>
              <tr className={`table-header`}>
                <th>
                  <input type="checkbox" onChange={handleAllCheck} />
                  <Image image={nike} />
                </th>
                <th>
                  <Text value="Row No." className={`table-title`} />
                </th>
                <th>
                  <Text value="Staff" className={`table-title`} />
                </th>
                <th>
                  <Text value="Net Salary" className={`table-title`} />
                </th>
                <th>
                  <Text value="Bank" className={`table-title`} />
                </th>
                <th>
                  <Text value="Tax ID" className={`table-title`} />
                </th>
                <th>
                  <Text value="Tax Deduction" className={`table-title`} />
                </th>
                <th>
                  <Text value="PFA Details" className={`table-title`} />
                </th>
                <th>
                  <Text value="Employee Contribution" className={`table-title`} />
                </th>
                <th>
                  <Text value="Employer Contribution" className={`table-title`} />
                </th>
                <th>
                  <Text value="NHF Details" className={`table-title`} />
                </th>
                <th>
                  <Text value="Amount" className={`table-title`} />
                </th>
                <th>
                  <Text value="NHIS Details" className={`table-title`} />
                </th>
                <th>
                  <Text value="Amount" className={`table-title`} />
                </th>
                <th>
                  <Text value="Other Deductions" className={`table-title`} />
                </th>
              </tr>
            </thead>
            <tbody className={`table-body`}>
              {/* {
                toFilter ?

              } */}
              {
                (data.data.content !== undefined && data.data.content.length > 0) ?
                  data.data.content.map((payment: Record<any, any>, key: number) => {
                    if (!toFilter) {
                      return (
                        <tr className={`table-row ${(!payment.valid) ? '--error-row' : '--active-row'
                          }`} key={key} title={payment.parseError}>
                          <td>
                            <input
                              type="checkbox"
                              checked={state.exportReducer.results.data.includes(
                                payment
                              )}
                              onChange={() => handleCheckItem(payment)}
                            />
                            <Image image={nike} />
                          </td>
                          <td>
                            <Text value={payment.rowNumber} className='label' />
                          </td>
                          <td>
                            <Text value={`${payment.firstName} ${payment.lastName}`} className='label' />
                            <Text value={`Staff | ${payment.staffId}`} className='sublabel' />
                          </td>
                          <td>
                            <Text value={`₦${payment.netSalary}`} className='label' />
                          </td>
                          <td>
                            <Text value={payment.accountNo} className='label' />
                            <Text value={payment.bankName} className='sublabel' />
                          </td>
                          <td>
                            <Text value={payment.payeTaxId} className='label' />
                            <Text value={payment.payePartnerCode} className='sublabel' />
                          </td>
                          <td>
                            <Text value={`₦${payment.payeAmount}`} className='label' />
                          </td>
                          <td>
                            <Text value={payment.pensionId} className='label' />
                            <Text value={payment.pfaPartnerCode} className='sublabel' />
                          </td>
                          <td>
                            <Text value={`₦${payment.pensionEmpyeeAmount}`} className='label' />
                          </td>
                          <td>
                            <Text value={`₦${payment.pensionEmpyerAmount}`} className='label' />
                          </td>
                          <td>
                            <Text value={payment.nhfId} className='label' />
                            <Text value={payment.nhfPartnerCode} className='sublabel' />
                          </td>
                          <td>
                            <Text value={`₦${payment.nhfAmount}`} className='label' />
                          </td>
                          <td>
                            <Text value={payment.nhisId} className='label' />
                            <Text value={payment.nhisPartnerCode} className='sublabel' />
                          </td>
                          <td>
                            <Text value={`₦${payment.nhisAmount}`} className='label' />
                          </td>
                          <td>
                            <Text value={`₦${payment.ded1Amount}, ₦${payment.ded2Amount}, ₦${payment.ded3Amount}, ₦${payment.ded4Amount}, ₦${payment.ded5Amount}`} className='label' />

                          </td>
                        </tr>
                      )
                    }
                    else{
                      if(payment.parseError !== ""){
                        return (
                          <tr className={`table-row ${(!payment.valid) ? '--error-row' : '--active-row'
                            }`} key={key} title={payment.parseError}>
                            <td>
                              <input
                                type="checkbox"
                                checked={state.exportReducer.results.data.includes(
                                  payment
                                )}
                                onChange={() => handleCheckItem(payment)}
                              />
                              <Image image={nike} />
                            </td>
                            <td>
                              <Text value={payment.rowNumber} className='label' />
                            </td>
                            <td>
                              <Text value={`${payment.firstName} ${payment.lastName}`} className='label' />
                              <Text value={`Staff | ${payment.staffId}`} className='sublabel' />
                            </td>
                            <td>
                              <Text value={`₦${payment.netSalary}`} className='label' />
                            </td>
                            <td>
                              <Text value={payment.accountNo} className='label' />
                              <Text value={payment.bankName} className='sublabel' />
                            </td>
                            <td>
                              <Text value={payment.payeTaxId} className='label' />
                              <Text value={payment.payePartnerCode} className='sublabel' />
                            </td>
                            <td>
                              <Text value={`₦${payment.payeAmount}`} className='label' />
                            </td>
                            <td>
                              <Text value={payment.pensionId} className='label' />
                              <Text value={payment.pfaPartnerCode} className='sublabel' />
                            </td>
                            <td>
                              <Text value={`₦${payment.pensionEmpyeeAmount}`} className='label' />
                            </td>
                            <td>
                              <Text value={payment.nhfId} className='label' />
                              <Text value={payment.nhfPartnerCode} className='sublabel' />
                            </td>
                            <td>
                              <Text value={`₦${payment.nhfAmount}`} className='label' />
                            </td>
                            <td>
                              <Text value={payment.nhisId} className='label' />
                              <Text value={payment.nhisPartnerCode} className='sublabel' />
                            </td>
                            <td>
                              <Text value={`₦${payment.nhisAmount}`} className='label' />
                            </td>
                            <td>
                              <Text value={`₦${payment.ded1Amount}, ₦${payment.ded2Amount}, ₦${payment.ded3Amount}, ₦${payment.ded4Amount}, ₦${payment.ded5Amount}`} className='label' />
    
                            </td>
                          </tr>
                        )
                      }
                    }

                  }) : null
              }
            </tbody>
          </table>
        </div>
        <div className={`container-full pagination`}>
          <div className={`div-md-6 pagination__box`}>
            <Pagination pagination={pagination} changePage={changePage} setPagination={setPagination} page={page} />
          </div>
          <div className={`div-md-6 pagination__action`}>
            {/* <button className='button --download'> Download and correct <Image image={download} /></button> */}
            {
              data.data.totalBadRecords && data.data.totalBadRecords > 0 ?
                <button className='button --upload'> Upload a different file <Image image={upload} />
                  <Input type="file" className="input"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(event) => fileUpload(event.target.files[0])}
                  />
                </button> :
                <Button className={`button --proceed ${statusData && statusData.processedCount >= statusData.totalRecords && statusData.status !== 11 ? '' : 'disabled'
                  }`} value="Proceed to Summary" isLoading={state.submitReducer.fetching} onClick={() => proceedToSummary()} />
            }
          </div>
        </div>
      </OngoingPaymentsListTableStyle>
    </>
  );
};

export default OngoingPaymentsListTable;
