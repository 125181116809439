import styled from "styled-components";

const CreateSignatoryModalStyle = styled.div`
    animation: show-bg 0.5s linear forwards;
    box-sizing:border-box;
    display:flex;
    height:100%;
    left:0;
    position:absolute;
    position:fixed;
    top: 0;
    width:100%;
    z-index:5000;
    @keyframes show-bg{
        0%{
            background:rgba(0, 0, 0, 0)
        }
        100%{
            background:rgba(37, 39, 51, 0.7);
        }
    }
    .modal-container{
        box-sizing:border-box;
        height:100vh;
    }
    .modal-container__box{
        -ms-flex: 0 0 552px;
        -webkit-flex: 0 0 552px;
        flex: 0 0 552px;
        max-width: 552px;
        background: #FAFAFA;
        border-radius:10px;
        box-sizing:border-box;
        padding: 15px;
    }
    .modal-container__box__header{
        box-sizing: border-box;
        padding-left: 10px;
        h2{
           color: #252733;
           font-family: 'Mulish';
           font-style: normal;
           font-weight: 700;
           font-size: 1.375em;
           line-height: 30px;
        }
        img{
            cursor: pointer;
        }
    }
    .modal-container__box__content{
        margin-top: 10px;
    }
    .modal-container__box__content__item{
        margin-bottom: 8px;
        h2{
            color: rgba(37, 39, 51, 0.5);
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 8px;
        }
        .input{
            background: rgba(55, 81, 255, 0.025);
            border: none;
            border-radius: 6px;
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            height: 38px;
            padding: 10px;
            width: 100%;
            &::placeholder{
                // color: #252733;
            }
        }
        &:nth-of-type(2){
            .input{
                height: 65px;
            }
        }
    }
    .--signatories{
        h2{
            margin-bottom: 3px;
        }
        p{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            margin-bottom: 8px;
        }
    }
    .modal-container__box__content__item__users__item{
        background: rgba(55, 81, 255, 0.025);
        border-radius: 6px;
        box-sizing: border-box;
        justify-content: normal;
        margin-bottom: 8px;
        padding: 9px 7px 6px 7px;
    }
    .modal-container__box__content__item__users__item__name{
        margin-left: 7px;
        width: 80%;
        p{
          color: #252733;
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
        }
    }
    .modal-container__box__content__item__users__item__close{
        cursor: pointer;
        margin-left: auto;
    }
    .--add-user{
        cursor: pointer;
        justify-content: space-between;
        margin-bottom: 0;
        margin-top: 10px;
        position: relative;
        p{
            color: rgba(37, 39, 51, 0.5);
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
        }
    }
    .more-info{
        background: white;
        border-radius: 8px;
        box-sizing: border-box;
        box-shadow: 0px 5px 15px rgba(8, 9, 10, 0.1);
        left: 0;
        max-height: 136px;
        margin-top: 50px;
        overflow: auto;
        padding: 5px;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 4000;
        .active{
            background: #F5F6FF;
            background-blend-mode: multiply;
            border-radius: 5px;
        }
    }
    .more-info__item{
        box-sizing: border-box;
        cursor: pointer;
        margin-bottom: 2px;
        padding: 7px 8px 7px 15px;
        transition: all 0.5s linear;
        button{
          all:unset;
          cursor:pointer;
          width:100%;
        }
        &:hover{
          background: #F5F6FF;
          background-blend-mode: multiply;
          border-radius: 5px;
        }
        p{
          color: #252733;
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 300;
          font-size: 13px;
          line-height: 16px;
        }
    }
    .--elipses{
        all: unset;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
        width: 100%;
    }
    .modal-container__box__action{
        justify-content: flex-end;
        margin-top: 42px;
        .button{
            border-radius: 8px;
            font-family: 'Mulish';
            font-weight: 500;
            font-size: 13px;
            height: 32px;
            margin-bottom: 0;
        }
        .--cancel{
            background: #F5F5F5;
            color: #252733;
            letter-spacing: 0.2px;
            margin-right: 20px;
            width: 73px;
        }
        .--change{
            background: linear-gradient(0deg, #ADB3DC, #ADB3DC), #3751FF;
            box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
            color: white;
            pointer-events:  none;
            width: 101px;
        }
        .--active-btn{
            background: #3751FF;
            pointer-events: unset;
        }
    }
`
export default CreateSignatoryModalStyle;