import React, {useState, useEffect} from 'react';
import MerchantStepFourStyle from './MerchantStepFourStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import { Link, useNavigate } from 'react-router-dom';
import MerchantProgressBar from '../MerchantProgressBar/MerchantProgressBar';
import Input from '../../atoms/Input/Input';
import caret from '../../../assets/images/caret-left-new.svg';
import Button from '../../atoms/Button/Button';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {stepFour, stepClear} from '../../../redux/actions/StepAction';
import {DashboardActions} from '../../../redux/actions/DashboardAction';
import {SubmitActionsPOST} from '../../../redux/actions/SubmitAction';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';


const MerchantStepFour = () => {
    const dispatch: TypedDispatch = useDispatch();
    const state = useSelector( (state:RootStore) => state);
    const [fetchLoading, setFetchLoading]: any = useState(true);
    const [reloadPage, setReloadPage] = useState(false)
    const navigate = useNavigate()
    const [fields, setFields] = useState({
        name: '',
        email: '',
        number: '',
        role: '',
        username: ''
    });
    const [fieldErrors, setFieldErrors] = useState({
        number: '',
    })

    useEffect(() => {
        if(state.dashboardReducer.results && !fetchLoading && Object.keys(state.stepReducer.results.stepFour).length === 0){
            setFields({
                name: '',
                email: '',
                number: '',
                role: state.dashboardReducer.results.data.content[0].id,
                username: ''
            })
        }
    }, [state.dashboardReducer, fetchLoading])

    useEffect(() => {
        if(Object.keys(state.stepReducer.results.stepFour).length > 0){
            let fieldValue = {
                name: state.stepReducer.results.stepFour.name,
                email: state.stepReducer.results.stepFour.email,
                number: state.stepReducer.results.stepFour.number,
                role: state.stepReducer.results.stepFour.role,
                username: state.stepReducer.results.stepFour.username
            }
            setFields(fieldValue);
        }
        if(Object.keys(state.stepReducer.results.stepThree).length < 1){
            navigate('/merchants/create/step-three');
        }
    }, [])

    useEffect(() => {
        if(state.submitReducer.results && reloadPage){
            dispatch(stepClear());
            navigate('/merchants');
        }
    }, [state.submitReducer])

    useEffect(() => {
        fetchAllRoles();
    }, [])
    
    const fetchAllRoles = async () => {
        setFetchLoading(true);
        await dispatch(DashboardActions(`/roles?defaultRole=1&roleLevel=1&roleType=2`));
        console.log(state);
        setFetchLoading(false);
    }

    useEffect(() => {
        if(Object.keys(state.stepReducer.results.stepFour).length > 0){
            let stepValue = state.stepReducer.results
            let data = {
                "address": stepValue.stepOne.address,
                "adminUser": {
                  "email": stepValue.stepThree.email,
                  "firstName": stepValue.stepThree.name.split(" ")[0],
                  "lastName": stepValue.stepThree.name.split(" ")[1],
                  "phoneNumber": stepValue.stepThree.number,
                  "userRoles": [
                    stepValue.stepThree.role
                  ],
                  "username": stepValue.stepThree.username
                },
                "authoriserUser": {
                    "email": stepValue.stepFour.email,
                    "firstName": stepValue.stepFour.name.split(" ")[0],
                    "lastName": stepValue.stepFour.name.split(" ")[1],
                    "phoneNumber": stepValue.stepFour.number,
                    "userRoles": [
                      stepValue.stepFour.role
                    ],
                    "username": stepValue.stepFour.username
                },
                "businessEmail": stepValue.stepOne.email,
                "businessName": stepValue.stepOne.name,
                "businessPhoneNumber": stepValue.stepOne.number,
                "businessWebsite": "",
                "description": stepValue.stepOne.description,
                "kyc": stepValue.stepTwo,
                "rcNumber": stepValue.stepOne.rcNumber,
                "processSalary": stepValue.stepOne.processSalary
            }
            dispatch(SubmitActionsPOST('/merchant', data));
            setReloadPage(true)
        }
    }, [state.stepReducer])

    const renderFetchLoading = () => {
        if(fetchLoading){
          return(
            <FetchLoading />
          )
        }
      }

    const inputChange =  async (name: string, value: string) => {
        const fieldsValues: any = Object.assign({}, fields);
        fieldsValues[name] = value;
        await setFields(fieldsValues);
        if(name === "number"){
            onInputValidate(value, name);
        }
        console.log(fields)
    };

    const onInputValidate = async (value: string, name: string) => {
        if(/^234[0-9]{10}$/.test(value)){
          const fieldErrorValues: any = Object.assign({}, fieldErrors);
          fieldErrorValues[name] = false;
          await setFieldErrors(fieldErrorValues);
          return true
        }
        else{
          const fieldErrorValues: any = Object.assign({}, fieldErrors);
          fieldErrorValues[name] = 'Invalid phone number format';
          await setFieldErrors(fieldErrorValues);
          return false
        }
    }

    const onFormSubmit = () => {
        return async (evt: React.FormEvent) => { 
          evt.preventDefault();
          if(validate()){
            return;
          }
          await dispatch(stepFour(fields));
          console.log(state);
        }
    }

    const validate = () => {         
        const person:any = fieldErrors;
        if (Object.keys(fieldErrors).filter((k) => person[k]).length){
            return true;
        }
        return false;
    } 

    return (
        <>
            {renderFetchLoading()}
            <MerchantStepFourStyle className={`container-full`}>
            <form onSubmit={onFormSubmit()}>
                <div className={`container-full merchants__body`}>
                    <MerchantProgressBar />
                    <div className={`container-full merchants__body__form`}>
                        <div className={`div-sm-12 merchants__body__form__desc`}>
                            <Text value='Step 4:' className='step'/>
                            <HeaderText value='Authorizer' className='header'/>
                            <Text value='Enter company details to create merchant' className='desc' />
                        </div>
                        <div className={`container-full merchants__body__form__form`}>
                            <div className={`container-full merchants__body__form__form__row`}>
                                <div className='div-md-6 merchants__body__form__form__row__item'>
                                    <Text value='Full Name' />
                                    <Input type="text" className='input' placeholder='Enter legal full name'  required value={fields.name} name="name" onChange={(evt) => inputChange(evt.target.name, evt.target.value) }/>
                                </div>
                                <div className='div-md-6 merchants__body__form__form__row__item'>
                                    <Text value='Email' />
                                    <Input type="email" className='input' placeholder='Enter email address' required value={fields.email} name="email" onChange={(evt) => inputChange(evt.target.name, evt.target.value) }/>
                                </div>
                            </div>
                            <div className={`container-full merchants__body__form__form__row`}>
                                <div className='div-md-6 merchants__body__form__form__row__item'>
                                    <Text value='Phone Number' />
                                    <Input type="text" className='input' placeholder='Enter phone number' required value={fields.number} name="number" onChange={(evt) => inputChange(evt.target.name, evt.target.value) } />
                                    <span> {fieldErrors.number} </span>
                                </div>
                                <div className='div-md-6 merchants__body__form__form__row__item'>
                                    <Text value='Role' />
                                    <div className='input'>
                                        <select required value={fields.role} name="role" onChange={(evt) => inputChange(evt.target.name, evt.target.value) }>
                                            {
                                                (state.dashboardReducer.results && state.dashboardReducer.results.data.content.map((role: Record<any, any>) => {
                                                    return(
                                                        <option value={role.id} key={role.id}> {role.name} </option>
                                                    )
                                                }))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className={`container-full merchants__body__form__form__row`}>
                                <div className='div-md-6 merchants__body__form__form__row__item'>
                                    <Text value='Username' />
                                    <Input type="text" className='input' placeholder='Enter username' required value={fields.username} name="username" onChange={(evt) => inputChange(evt.target.name, evt.target.value) } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`container-full merchants__action`}>
                        <Link to="/merchants/create/step-three">
                            <button className='button --back'>
                                <Image image={caret} />
                                Back
                            </button> 
                        </Link>
                        <Button value="Finish Merchant Setup" className='button --save' isLoading={state.submitReducer.fetching} />
                </div>
            </form>
            </MerchantStepFourStyle>
        </>
    );
}

export default MerchantStepFour;