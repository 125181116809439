import styled from "styled-components";
import Colors from '../../../helpers/Colors';

const AddSourceAccountFormStyle = styled.div`
  width: 100%;

  form{
    width: 100%;
  }
  .form__content{
    border-bottom: 1px solid #F0F0F0;
    box-sizing: border-box;
    padding: 0 15px;
    padding-bottom: 27px;
  }
  .form__content__desc{
    p{
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .form__content__group{
    margin-top: 15px;
  }
  .form__content__group__item{
    margin-bottom: 15px;
    &:last-child{
      margin-bottom: 0;
    }
    .div-md-6{
      margin-bottom: 0;
    }
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 9px;
      span{
          color: #F12B2C;
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          margin-left: 5px;
      }
    }
    .input{
        background: rgba(55, 81, 255, 0.025);
        border: none;
        border-radius: 6px;
        color: #252733;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        height: 40px;
        padding-left: 15px;
        position: relative;
        width: 100%;
        &::placeholder{
            color: rgba(37, 39, 51, 0.3);
        }
    }
    select{
      appearance: none;
      background: transparent;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      margin-top: 10px;
      width: 100%;
    }
    select:required:invalid {
        color: rgba(37, 39, 51, 0.3);
    }
    img{
        margin-right: 8px;
        margin-top: 11px;
        position: absolute;
        right: 0;
        top: 0;
    }
  }
  .form__processor{
    box-sizing: border-box;
    padding: 27px 15px 0 15px;
  }
  .form__processor__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .form__processor__group{
    margin-top: 13px;
  }
  .form__processor__group__item{
    margin-bottom: 13px;
    position: relative;
    &:last-child{
      margin-bottom: 0;
    }
    .div-md-6{
      margin-bottom: 0;
    }
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 9px;
      span{
          color: #F12B2C;
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          margin-left: 5px;
      }
    }
    .input{
        background: rgba(55, 81, 255, 0.025);
        border: none;
        border-radius: 6px;
        color: #252733;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        height: 40px;
        padding-left: 15px;
        position: relative;
        width: 100%;
        &::placeholder{
            color: rgba(37, 39, 51, 0.3);
        }
    }
    select{
      appearance: none;
      background: transparent;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      margin-top: 10px;
      width: 100%;
    }
    select:required:invalid {
        color: rgba(37, 39, 51, 0.3);
    }
    img{
        margin-right: 8px;
        margin-top: 11px;
        position: absolute;
        right: 0;
        top: 0;
    }
    .password{
      position:relative;
      img{
          cursor:pointer;
          margin-right:10.52px;
          margin-top:13.36px;
          position:absolute;
          right:0;
          top:0;
      }
    } 
  }
  .form__content__group__item__label{
    background: #F0F0F0;
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    height: 24px;
    margin-top: 7px;
    padding: 4px 6px;
    width: 126px;
    p{
        color: #252733;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        font-feature-settings: 'salt' on;
    }
  }
  .info{
    color: rgba(37, 39, 51, 0.5);
    font-family: 'Mulish';
    font-feature-settings: 'salt' on;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-top: 8px;
    text-transform: uppercase;
  }
  .error{
    color: #BD0F10;
    font-family: 'Mulish';
    font-feature-settings: 'salt' on;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
  }
  .form__action{
    justify-content: flex-end;
    margin-top: 83px;
    .button{
        border-radius: 8px;
        font-family: 'Mulish';
        font-weight: 500;
        font-size: 16px;
        height: 40px;
        margin-bottom: 0;
    }
    .--cancel{
        background: #F5F5F5;
        color: #252733;
        margin-right: 20px;
        width: 102px;
    }
    .--change{
      background: linear-gradient(0deg, #ADB3DC, #ADB3DC), #3751FF;
      box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
      color: white;
      pointer-events:  none;
      width: 118px;
    }
    .--active-btn{
      background: #3751FF;
      pointer-events: unset;
    }
  }
`;

export default AddSourceAccountFormStyle;