import React, {useState, useEffect} from 'react';
import CreateSignatoryModalStyle from './CreateSignatoryModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import Input from '../../atoms/Input/Input';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {SubmitActionsPOST} from '../../../redux/actions/SubmitAction';
import { alertActions } from '../../../redux/actions/AlertActions';
import {appInstance as axios} from '../../../axios';
import Cookies from 'js-cookie';
import closeIcon from '../../../assets/images/close-icon-round.svg';
import dropdown from '../../../assets/images/dropdown.svg';


type CreateSignatoryModalProps = {
  handleClose: Function,
  showSuccessModal: Function,
}

const CreateSignatoryModal = ({handleClose, showSuccessModal}: CreateSignatoryModalProps) => {
    let timeOutId:any = null;
    const [selectedMenu, setSelectedMenu] = useState(false);
    const dispatch: TypedDispatch = useDispatch();
    const state = useSelector( (state:RootStore) => state);
    const [fields, setFields] = useState({
      name: '',
      description: ''
    })
    const [users, setUsers] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
     if(state.submitReducer.results && submitted){
      handleClose();
      showSuccessModal()
     }
    }, [state.submitReducer])

    useEffect(() => {
      fetchUsers();
      console.log(state);
    }, [])

    const fetchUsers = async () => {
      try{
        const res = await axios.get('/users/roleType?status=1&type=2&size=0&page=1', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get("userToken")}`
          }
        });
        setUsersList(res.data.data.content);
      }catch (error: any) {
        if (error.message === "Network Error") {
          dispatch(alertActions.error('Network Error, try again!!!'));
        } else {
          dispatch(alertActions.error(error.response.data.error));
        }
      }
    }

    const onClickHandler = (value: boolean) => {
      if(selectedMenu !== false){
        setSelectedMenu(false);
        return;
      }
      setSelectedMenu(value);
    }

    const onBlurHandler = () => {
      timeOutId = setTimeout(() => {
        setSelectedMenu(false);
      });
    }

    const onFocusHandler = () =>{
      clearTimeout(timeOutId);
    }

    const inputChange =  async (name: string, value: string) => {
      const fieldsValues: any = Object.assign({}, fields);
      fieldsValues[name] = value;
      await setFields(fieldsValues);
    };

    const onFormSubmit = () => {
      return(e: React.FormEvent) => {
        e.preventDefault();
        setSubmitted(true);
        let members:any = [];
        users.map((user: Record<any, any>) => {
          members.push(user.id);
        })
        let values = {
          "description": fields.description,
          "members": members,
          "name": fields.name
        }
        dispatch(SubmitActionsPOST('/signatory', values))
      }
    }

    const toggleUsers = (item: Record<any, any>) => {
      if(users.some((role: Record<any, any>) => role.id === item.id)){
        const newArr = users.filter( (record: Record<any,any>) => record.id !== item.id)
        console.log(newArr);
        return setUsers(newArr);
      }
      const newArr:any = [...users, item]
      setUsers(newArr);
    }

    const removeUser = (item: Record<any, any>) => {
      const newArr = users.filter( (record: Record<any,any>) => record.id !== item.id)
      return setUsers(newArr);
    }
  
    return (
        <CreateSignatoryModalStyle className={`container-full`}>
            <div className={`container-full center modal-container`}>
                <div className={`modal-container__box`}>
                    <div className={`container-full modal-container__box__header`}>
                     <HeaderText value='Create Signatory Group' />
                     <Image image={close} onClick={() => handleClose()}/>
                    </div>
                    <form onSubmit={onFormSubmit()}>
                    <div className={`container-full modal-container__box__content`}>
                      <div className={`div-sm-12 modal-container__box__content__item`}>
                        <HeaderText value='Name' />
                        <Input type="text" className="input" placeholder='Add signatory name' required name="name" value={fields.name} onChange={(e) => inputChange(e.target.name, e.target.value)}/>
                      </div>
                      <div className={`div-sm-12 modal-container__box__content__item`}>
                        <HeaderText value='Description' />
                        <textarea className='input' placeholder='Add a description for this signatory group.' required name="description" value={fields.description} onChange={(e) => inputChange(e.target.name, e.target.value)}></textarea>
                      </div>
                      <div className={`div-sm-12 modal-container__box__content__item --signatories`}>
                        <HeaderText value='Signatories' />
                        <Text value='You can add or remove users to this group.' />
                        <div className={`container-full modal-container__box__content__item__users`}>
                          {
                            users.length > 0 && users.map((user: Record<any, any>) => {
                              return(
                                <div className={`container-full modal-container__box__content__item__users__item`}>
                                  <div className={`modal-container__box__content__item__users__item__name`}>
                                    <Text value={user.username} />
                                  </div>
                                  <div className={`modal-container__box__content__item__users__item__close`} onClick={() => removeUser(user)}>
                                    <Image image={closeIcon} />
                                  </div>
                                </div>
                              )
                            })
                          }
                          <div className={`container-full modal-container__box__content__item__users__item --add-user`} onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
                            <button type="button" className={`button --elipses`} onClick={() => onClickHandler(true)} aria-haspopup="true" aria-expanded={selectedMenu}>
                              <Text value='Select a User' />
                              <Image image={dropdown} />
                            </button>
                            {
                              selectedMenu !== false && (
                                <div className={`container-full more-info`}>
                                  {
                                    usersList.length > 0 ? 
                                    usersList.map((user: Record<any, any>) => {
                                      return(
                                        <div className={`container-full more-info__item
                                        ${
                                          (users.some((userData: Record<any, any>) => userData.id === user.id)) ? 'active' : ''
                                        }
                                        `} key={user.id}>
                                          <button type="button" onClick={() => toggleUsers(user)}>
                                            <Text value={user.username} />
                                          </button>
                                        </div>
                                      )
                                    }) : ''
                                  }
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`container-full center modal-container__box__action`}>
                        <Button type="button" value="Cancel" className={`button --cancel`} onClick={() => handleClose()}/>
                        <Button type="submit" value="Save Group" className={`button --change ${
                          users.length > 0 ? '--active-btn' : ''
                        }`} isLoading={state.submitReducer.fetching}/>
                    </div>
                    </form>
                </div>  
            </div>
        </CreateSignatoryModalStyle>
    );
}

export default CreateSignatoryModal;