import React, { useState, useEffect } from 'react';
import ApprovalModalUpdateStyle from './ApprovalModalUpdateStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import Input from '../../atoms/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { SubmitActionsPOST } from '../../../redux/actions/SubmitAction';
import { alertActions } from '../../../redux/actions/AlertActions';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';
import cancel from '../../../assets/images/cancel-icon.svg';
import accept from '../../../assets/images/accept-icon.svg';
import dayjs from 'dayjs';

type ApprovalModalUpdateProps = {
  data: any,
  handleClose: Function,
}

const ApprovalModalUpdate = ({ handleClose, data }: ApprovalModalUpdateProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  const [comment, setComment] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [record, setRecord]: any = useState();

  useEffect(() => {
    if (state.submitReducer.results && submitted) {
      handleClose();
    }
  }, [state.submitReducer])

  useEffect(() => {
    let record = JSON.parse(data.recordAfter);
    setRecord(record);
    console.log(record);
  }, [])

  const inputChange = (value: string) => {
    setComment(value)
  }

  const rejectRequest = () => {
    setSubmitted(true);
    let values = {
      "comment": comment,
      "approved": 2,
      notificationId: data.id
    }
    dispatch(SubmitActionsPOST(`audit/approval/entity/${data.approvalStateId}`, values))
  }

  const acceptRequest = () => {
    setSubmitted(true);
    let values = {
      "comment": comment,
      "approved": 1,
      notificationId: data.id
    }
    dispatch(SubmitActionsPOST(`audit/approval/entity/${data.approvalStateId}`, values))
  }

  return (
    <ApprovalModalUpdateStyle className={`container-full`}>
      <div className={`container-full center modal-container`}>
        <div className={`modal-container__box`}>
          <div className={`container-full modal-container__box__header`}>
            <HeaderText value='Entity Approval' />
            <Image image={close} onClick={() => handleClose()} />
          </div>
          <div className={`container-full modal-container__box__content`}>
            <div className={`container-full modal-container__box__content__desc`}>
              <div className={`div-md-8 modal-container__box__content__desc__action`}>
                <HeaderText value={data.entityName.substring(1)} />
                <p> Action: <span>{data.action.toLowerCase()}</span></p>
              </div>
              <div className={`div-md-4 modal-container__box__content__desc__details`}>
                <div className={`container-full modal-container__box__content__desc__details__row`}>
                  <div className={`div-md-4 modal-container__box__content__desc__details__row__item`}>
                    <HeaderText value='Requester' className='label' />
                  </div>
                  <div className={`div-md-8 modal-container__box__content__desc__details__row__item`}>
                    <Text value={data.requester} className='value' />
                  </div>
                </div>
                <div className={`container-full modal-container__box__content__desc__details__row`}>
                  <div className={`div-md-4 modal-container__box__content__desc__details__row__item`}>
                    <HeaderText value='Date' className='label' />
                  </div>
                  <div className={`div-md-8 modal-container__box__content__desc__details__row__item`}>
                    <p className='value'> {dayjs(data.createdDate).format("DD/MM/YYYY")} <span>{dayjs(data.createdDate).format("HH:MM A")} </span></p>
                  </div>
                </div>
              </div>
            </div>
            <div className={`container-full modal-container__box__content__table`}>
              <div className={`modal-container__box__content__table__properties`}>
                <div className={`div-sm-12 modal-container__box__content__table__properties__header`}>
                  <Text value='Property' />
                </div>
                <div className={`container-full modal-container__box__content__table__properties__content`}>
                  {
                    record && record.changes.length > 0 && record.changes.map((record: Record<any, any>, key: number) => {
                      if (record.property !== "profilePhotoUri") {
                        return (
                          <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`} key={key}>
                            <Text value={record.property} />
                          </div>
                        )
                      }
                    })
                  }
                  {
                    record && record.permissions && (
                      <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                        <Text value={`permissions`} />
                      </div>
                    )
                  }
                  {
                    record && record.userRolesNames && JSON.parse(record.userRolesNames).length > 0 && (
                      <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                        <Text value={`user roles`} />
                      </div>
                    )
                  }
                  {
                    record && record.signatoryGroupsUserNames && JSON.parse(record.signatoryGroupsUserNames).length > 0 && (
                      <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                        <Text value={`signatory groups`} />
                      </div>
                    )
                  }
                  {
                    record && record.membersNames && JSON.parse(record.membersNames).length > 0 && (
                      <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                        <Text value={`signatory groups members`} />
                      </div>
                    )
                  }
                  {
                    record && record.authorizerNames && JSON.parse(record.authorizerNames).length > 0 && (
                      <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                        <Text value={`authorizer names`} />
                      </div>
                    )
                  }
                  {
                    record && record.terminalId && (
                      <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                        <Text value={`terminal ID`} />
                      </div>
                    )
                  }
                  {
                    record && record.encryptionKey && (
                      <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                        <Text value={`encryption key`} />
                      </div>
                    )
                  }
                </div>
              </div>
              <div className={`modal-container__box__content__table__values`}>
                <div className={`div-sm-12 modal-container__box__content__table__values__header`}>
                  <Text value='Old Values' />
                </div>
                <div className={`container-full modal-container__box__content__table__values__content`}>
                  {
                    record && record.changes.length > 0 && record.changes.map((record: Record<any, any>, key: number) => {
                      if (record.property !== "profilePhotoUri") {
                        if (record.property !== "status" && record.property !== "issuerType" && record.property !== "reviewer" && record.property !== "permissions") {
                          return (
                            <div className={`div-sm-12 modal-container__box__content__table__values__content__row`} key={key}>
                              <Text value={record.left} />
                            </div>
                          )
                        }
                        else if (record.property === "status") {
                          return (
                            <div className={`div-sm-12 modal-container__box__content__table__values__content__row`} key={key}>
                              {
                                record.left === 1 ?
                                  <div className={`modal-container__box__content__table__values__content__row__label --enabled`}>
                                    <Text value='Enabled' />
                                  </div> : null
                              }
                              {
                                record.left === 2 || record.left === 3 || record.left === 4 ?
                                  <div className={`modal-container__box__content__table__values__content__row__label --pending`}>
                                    <Text value='Pending' />
                                  </div> : null
                              }
                              {
                                record.left === 0 ?
                                  <div className={`modal-container__box__content__table__values__content__row__label --disabled`}>
                                    <Text value='Disabled' />
                                  </div> : null
                              }
                            </div>
                          )
                        }
                        else if (record.property === "issuerType") {
                          return (
                            <div className={`div-sm-12 modal-container__box__content__table__values__content__row`} key={key}>
                              {
                                record.left === 1 ? <Text value='Commercial' /> : null
                              }
                              {
                                record.left === 2 ? <Text value='Microfinance' /> : null
                              }
                              {
                                record.left === 3 ? <Text value='Wallet' /> : null
                              }
                            </div>
                          )
                        }
                        else if (record.property === "reviewer") {
                          return (
                            <div className={`container-full modal-container__box__content__table__values__content__row --reviewers`}>
                              <div className={`modal-container__box__content__table__values__content__row__label`}>
                                <Text value={record.left} />
                              </div>
                            </div>
                          )
                        }
                      }
                    })
                  }
                  {
                    record && record.oldPermissionsNames && JSON.parse(record.oldPermissionsNames).length > 0 && (
                      <div className={`container-full modal-container__box__content__table__values__content__row --reviewers`}>
                        {
                          JSON.parse(record.oldPermissionsNames).map((permission: string, key: number) => {
                            return (
                              <div className={`modal-container__box__content__table__values__content__row__label`} key={key}>
                                <Text value={permission} />
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  }
                  {
                    record && record.oldUserRolesNames && JSON.parse(record.oldUserRolesNames).length > 0 && (
                      <div className={`container-full modal-container__box__content__table__values__content__row --reviewers`}>
                        {
                          JSON.parse(record.oldUserRolesNames).map((role: string, key: number) => {
                            return (
                              <div className={`modal-container__box__content__table__values__content__row__label`} key={key}>
                                <Text value={role} />
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  }
                  {
                    record && record.oldSignatoryGroupsUserNames && JSON.parse(record.oldSignatoryGroupsUserNames).length > 0 && (
                      <div className={`container-full modal-container__box__content__table__values__content__row --reviewers`}>
                        {
                          JSON.parse(record.oldSignatoryGroupsUserNames).map((signatoryGroup: string, key: number) => {
                            return (
                              <div className={`modal-container__box__content__table__values__content__row__label`} key={key}>
                                <Text value={signatoryGroup} />
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  }
                  {
                    record && record.oldMembersNames && JSON.parse(record.oldMembersNames).length > 0 && (
                      <div className={`container-full modal-container__box__content__table__values__content__row --reviewers`}>
                        {
                          JSON.parse(record.oldMembersNames).map((memberName: string, key: number) => {
                            return (
                              <div className={`modal-container__box__content__table__values__content__row__label`} key={key}>
                                <Text value={memberName} />
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  }
                  {
                    record && record.oldAuthorizerNames && JSON.parse(record.oldAuthorizerNames).length > 0 && (
                      <div className={`container-full modal-container__box__content__table__values__content__row --reviewers`}>
                        {
                          JSON.parse(record.oldAuthorizerNames).map((authorizerName: string, key: number) => {
                            return (
                              <div className={`modal-container__box__content__table__values__content__row__label`} key={key}>
                                <Text value={authorizerName} />
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  }
                  {
                    record && record.oldTerminalId && (
                      <div className={`container-full modal-container__box__content__table__values__content__row`} style={{background: "green"}}>
                        <Text value={JSON.parse(record.oldTerminalId)} />
                      </div>
                    )
                  }
                  {
                    record && record.oldEncryptionKey && (
                      <div className={`container-full modal-container__box__content__table__values__content__row`}>
                        <Text value={JSON.parse(record.oldEncryptionKey)} />
                      </div>
                    )
                  }

                </div>
              </div>
              <div className={`modal-container__box__content__table__values --new-values`}>
                <div className={`div-sm-12 modal-container__box__content__table__values__header`}>
                  <Text value='New Values' />
                </div>
                <div className={`container-full modal-container__box__content__table__values__content`}>
                  {
                    record && record.changes.length > 0 && record.changes.map((record: Record<any, any>, key: number) => {
                      if (record.property !== "profilePhotoUri") {
                        if (record.property !== "status" && record.property !== "issuerType" && record.property !== "reviewer" && record.property !== "permissions") {
                          return (
                            <div className={`div-sm-12 modal-container__box__content__table__values__content__row`} key={key}>
                              <Text value={record.right} />
                            </div>
                          )
                        }
                        else if (record.property === "status") {
                          return (
                            <div className={`div-sm-12 modal-container__box__content__table__values__content__row`} key={key}>
                              {
                                record.right === 1 ?
                                  <div className={`modal-container__box__content__table__values__content__row__label --enabled`}>
                                    <Text value='Enabled' />
                                  </div> : null
                              }
                              {
                                record.right === 2 || record.right === 3 || record.right === 4 ?
                                  <div className={`modal-container__box__content__table__values__content__row__label --pending`}>
                                    <Text value='Pending' />
                                  </div> : null
                              }
                              {
                                record.right === 0 ?
                                  <div className={`modal-container__box__content__table__values__content__row__label --disabled`}>
                                    <Text value='Disabled' />
                                  </div> : null
                              }
                            </div>
                          )
                        }
                        else if (record.property === "issuerType") {
                          return (
                            <div className={`div-sm-12 modal-container__box__content__table__values__content__row`} key={key}>
                              {
                                record.right === 1 ? <Text value='Commercial' /> : null
                              }
                              {
                                record.right === 2 ? <Text value='Microfinance' /> : null
                              }
                              {
                                record.right === 3 ? <Text value='Wallet' /> : null
                              }
                            </div>
                          )
                        }
                        else if (record.property === "reviewer") {
                          return (
                            <div className={`container-full modal-container__box__content__table__values__content__row --reviewers`}>
                              <div className={`modal-container__box__content__table__values__content__row__label`}>
                                <Text value={record.right} />
                              </div>
                            </div>
                          )
                        }
                      }
                    })
                  }
                  {
                    record && record.permissionsName && JSON.parse(record.permissionsName).length > 0 && (
                      <div className={`container-full modal-container__box__content__table__values__content__row --reviewers`}>
                        {
                          JSON.parse(record.permissionsName).map((permission: string, key: number) => {
                            return (
                              <div className={`modal-container__box__content__table__values__content__row__label`} key={key}>
                                <Text value={permission} />
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  }
                  {record && record.userRolesNames && JSON.parse(record.userRolesNames).length > 0 && (
                    <div className={`container-full modal-container__box__content__table__values__content__row --reviewers`}>
                      {
                        JSON.parse(record.userRolesNames).map((role: string, key: number) => {
                          return (
                            <div className={`modal-container__box__content__table__values__content__row__label`} key={key}>
                              <Text value={role} />
                            </div>
                          )
                        })
                      }
                    </div>
                  )
                  }
                  {
                    record && record.signatoryGroupsUserNames && JSON.parse(record.signatoryGroupsUserNames).length > 0 && (
                      <div className={`container-full modal-container__box__content__table__values__content__row --reviewers`}>
                        {
                          JSON.parse(record.signatoryGroupsUserNames).map((signatoryGroup: string, key: number) => {
                            return (
                              <div className={`modal-container__box__content__table__values__content__row__label`} key={key}>
                                <Text value={signatoryGroup} />
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  }
                  {
                    record && record.membersNames && JSON.parse(record.membersNames).length > 0 && (
                      <div className={`container-full modal-container__box__content__table__values__content__row --reviewers`}>
                        {
                          JSON.parse(record.membersNames).map((memberName: string, key: number) => {
                            return (
                              <div className={`modal-container__box__content__table__values__content__row__label`} key={key}>
                                <Text value={memberName} />
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  }
                  {
                    record && record.authorizerNames && JSON.parse(record.authorizerNames).length > 0 && (
                      <div className={`container-full modal-container__box__content__table__values__content__row --reviewers`}>
                        {
                          JSON.parse(record.authorizerNames).map((authorizerName: string, key: number) => {
                            return (
                              <div className={`modal-container__box__content__table__values__content__row__label`} key={key}>
                                <Text value={authorizerName} />
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  }
                  {
                    record && record.terminalId && (
                      <div className={`container-full modal-container__box__content__table__values__content__row --reviewers`}>
                        <Text value={JSON.parse(record.terminalId)} />
                      </div>
                    )
                  }
                  {
                    record && record.encryptionKey && (
                      <div className={`container-full modal-container__box__content__table__values__content__row --reviewers`}>
                        <Text value={JSON.parse(record.encryptionKey)} />
                      </div>
                    )
                  }


                </div>
              </div>
            </div>
            <div className={`container-full modal-container__box__content__action`}>
              <div className={`modal-container__box__content__action__input`}>
                <Input className='input' placeholder='Enter Comment here' value={comment} onChange={(e) => inputChange(e.target.value)} />
              </div>
              <div className={`modal-container__box__content__action__button`}>
                <Button className='button --reject' value={
                  <><Image image={cancel} /> <p> Reject</p></>
                } isLoading={state.submitReducer.fetching} onClick={() => rejectRequest()} />
                <Button className='button --accept' value={
                  <><Image image={accept} /> <p> Accept</p></>
                } isLoading={state.submitReducer.fetching} onClick={() => acceptRequest()} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ApprovalModalUpdateStyle>
  );
}

export default ApprovalModalUpdate;