import React, { useState, useEffect } from 'react';
import OngoingPaymentsListTemplateStyle from './OngoingPaymentsListTemplateStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import exportIcon from '../../../assets/images/download-icon.svg';
import OngoingPaymentsListTable from '../../organisms/OngoingPaymentsListTable/OngoingPaymentsListTable';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { DashboardActions } from '../../../redux/actions/DashboardAction';
import { exportAll, exportClear } from '../../../redux/actions/ExportAction';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import counterIcon from '../../../assets/images/counter-icon.svg';
import { alertActions } from '../../../redux/actions/AlertActions';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import Img from '../../atoms/Image/Img';
import angleLeft from '../../../assets/images/angle-small-left.svg';
import caution from '../../../assets/images/caution.png';

const OngoingPaymentsListTemplate = () => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [toFilter, setToFilter] = useState(false);
  const dispatch: TypedDispatch = useDispatch();
  const state: any = useSelector((state: RootStore) => state);
  const [statusData, setStatusData] = useState<any>({});
  let interval: any;

  useEffect(() => {
    dispatch(exportAll(`/payment/${window.location.href.split("=")[1]}/details?size=0&page=1`));
  }, [])

  useEffect(() => {
    dispatch(exportClear())
    generatePageContent();
    console.log(state);
  }, [size, page, reloadPage])

  const generatePageContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/payment/${window.location.href.split("=")[1]}/details?size=${size}&page=${page}`));
    setFetchLoading(false);
  }

  useEffect(() => {
    if (Object.keys(statusData).length > 0) {
      if (statusData.processedCount === statusData.beneficiaryCount) {
        generatePageContent();
        return;
      }
    }
  }, [statusData])

  useEffect(() => {
    fetchStatusData();
    interval = setInterval(() => {
      fetchStatusData();
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, [])

  const fetchStatusData = async () => {
    try {
      const res = await axios.get(`/payment/${window.location.href.split("=")[1]}/status`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setStatusData(res.data.data);
      if (res.data.data.processedCount === res.data.data.beneficiaryCount) {
        clearInterval(interval)
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const renderFetchLoading = () => {
    if (fetchLoading) {
      return (
        <FetchLoading />
      )
    }
  }

  const changePage = (value: number) => {
    setPage(value);
  }

  const changeSize = (value: number) => {
    setSize(value);
  }

  const exportData = () => {
    return {
      fileData: () => {
        let payments = state.exportReducer.results.data.map((payment: Record<any, any>) => {
          return (
            {
              StaffName: `${payment.firstName} ${payment.lastName}`,
              StaffId: payment.staffId,
              NetSalary: payment.netSalary,
              BankName: payment.bankName,
              TaxId: payment.payeTaxId,
              TaxCode: payment.payePartnerCode,
              TaxDeduction: payment.payeAmount,
              PFAId: payment.pensionId,
              PFACode: payment.pfaPartnerCode,
              PFADeduction: payment.pensionEmpyeeAmount,
              NHFId: payment.nhfId,
              NHFCode: payment.nhfPartnerCode,
              NHFDeduction: payment.nhfAmount,
              NHISId: payment.nhisId,
              NHISCode: payment.nhisPartnerCode,
              NHISDeduction: payment.nhisAmount,
              Deduction1: payment.ded1Amount,
              Deduction2: payment.ded2Amount,
              Deduction3: payment.ded3Amount,
              Deduction4: payment.ded4Amount,
              Deduction5: payment.ded5Amount,
              AccountNumber: payment.accountNo,
              DateCreated: dayjs(payment.createdDate).format('MM/DD/YYYY'),
            }
          )
        })
        let data = [...payments];
        return data
      },
      fileHeaders: () => {
        let headers = [
          { label: "Staff", key: "StaffName" },
          { label: "Staff ID", key: "StaffId" },
          { label: "Net Salary", key: "NetSalary" },
          { label: "Bank Name", key: "BankName" },
          { label: "Tax ID", key: "TaxId" },
          { label: "Tax Code", key: "TaxCode" },
          { label: "Tax Deduction", key: "TaxDeduction" },
          { label: "PFA ID", key: "PFAId" },
          { label: "PFA Code", key: "PFACode" },
          { label: "PFA Deduction", key: "PFADeduction" },
          { label: "NHF ID", key: "NHFId" },
          { label: "NHF Code", key: "NHFCode" },
          { label: "NHF Deduction", key: "NHFDeduction" },
          { label: "NHIS ID", key: "NHISId" },
          { label: "NHIS Code", key: "NHISCode" },
          { label: "NHIS Deduction", key: "NHISDeduction" },
          { label: "Deduction1", key: "Deduction1" },
          { label: "Deduction2", key: "Deduction2" },
          { label: "Deduction3", key: "Deduction3" },
          { label: "Deduction4", key: "Deduction4" },
          { label: "Deduction5", key: "Deduction5" },
          { label: "Account No", key: "AccountNumber" },
          { label: "Date Created", key: "DateCreated" },
        ];
        return headers;
      }
    }
  }

  const exportDataAll = () => {
    return {
      fileData: () => {
        let payments = state.exportReducer.allResults.data.map((payment: Record<any, any>) => {
          return (
            {
              StaffName: `${payment.firstName} ${payment.lastName}`,
              StaffId: payment.staffId,
              NetSalary: payment.netSalary,
              BankName: payment.bankName,
              TaxId: payment.payeTaxId,
              TaxCode: payment.payePartnerCode,
              TaxDeduction: payment.payeAmount,
              PFAId: payment.pensionId,
              PFACode: payment.pfaPartnerCode,
              PFADeduction: payment.pensionEmpyeeAmount,
              NHFId: payment.nhfId,
              NHFCode: payment.nhfPartnerCode,
              NHFDeduction: payment.nhfAmount,
              NHISId: payment.nhisId,
              NHISCode: payment.nhisPartnerCode,
              NHISDeduction: payment.nhisAmount,
              Deduction1: payment.ded1Amount,
              Deduction2: payment.ded2Amount,
              Deduction3: payment.ded3Amount,
              Deduction4: payment.ded4Amount,
              Deduction5: payment.ded5Amount,
              AccountNumber: payment.accountNo,
              DateCreated: dayjs(payment.createdDate).format('MM/DD/YYYY'),
            }
          )
        })
        let data = [...payments];
        return data
      },
      fileHeaders: () => {
        let headers = [
          { label: "Staff", key: "StaffName" },
          { label: "Staff ID", key: "StaffId" },
          { label: "Net Salary", key: "NetSalary" },
          { label: "Bank Name", key: "BankName" },
          { label: "Tax ID", key: "TaxId" },
          { label: "Tax Code", key: "TaxCode" },
          { label: "Tax Deduction", key: "TaxDeduction" },
          { label: "PFA ID", key: "PFAId" },
          { label: "PFA Code", key: "PFACode" },
          { label: "PFA Deduction", key: "PFADeduction" },
          { label: "NHF ID", key: "NHFId" },
          { label: "NHF Code", key: "NHFCode" },
          { label: "NHF Deduction", key: "NHFDeduction" },
          { label: "NHIS ID", key: "NHISId" },
          { label: "NHIS Code", key: "NHISCode" },
          { label: "NHIS Deduction", key: "NHISDeduction" },
          { label: "Deduction1", key: "Deduction1" },
          { label: "Deduction2", key: "Deduction2" },
          { label: "Deduction3", key: "Deduction3" },
          { label: "Deduction4", key: "Deduction4" },
          { label: "Deduction5", key: "Deduction5" },
          { label: "Account No", key: "AccountNumber" },
          { label: "Date Created", key: "DateCreated" },
        ];
        return headers;
      }
    }
  }

  const refreshTable = () => {
    if (reloadPage) {
      return setReloadPage(false);
    }
    setReloadPage(true);
  }

  return (
    <>
      {renderFetchLoading()}
      <OngoingPaymentsListTemplateStyle>
        <div className={`container-full payments`}>
          <div className={`container-full payments__back`}>
            <Link to="/payments/ongoing-payments">
              <Img image={angleLeft} />
              <Text value='Back to Payments' />
            </Link>
          </div>
          <div className={`container-full payments__desc`}>
            <HeaderText value="Ongoing Payment" />
          </div>
          <div className='container-full payments__action'>
            <div className={`payments__action__desc`}>
              <HeaderText value='Uploaded List Name' />
            </div>
            <div className={`payments__action__search`}>
              <div className={`payments__action__search__toggle`}>
                <p>Filter bad records</p>
                <div className={`payments__action__search__toggle__switch`}>
                  <input type="checkbox" className={`input `} onChange={() => setToFilter(!toFilter)} />
                  <div className={`payments__action__search__toggle__switch__box`}>
                    <div className={`payments__action__search__toggle__switch__box__ball`}></div>
                  </div>
                </div>
              </div>
              <div className='btn-group'>
                {
                  (state.exportReducer.results.data.length > 0) ?
                    <CSVLink data={exportData().fileData()} headers={exportData().fileHeaders()} filename={"ongoing payments list.csv"}>
                      <button className='button --export'>
                        <Image image={exportIcon} />
                      </button>
                    </CSVLink> :
                    <CSVLink data={exportDataAll().fileData()} headers={exportDataAll().fileHeaders()} filename={"ongoing payments list.csv"}>
                      <button className='button --export'>
                        <Image image={exportIcon} />
                      </button>
                    </CSVLink>
                }
              </div>
            </div>
          </div>
          <div className={`container-full payments__error`}>
            <div className={`payments__error__box`}>
              <Text value={`${Object.keys(statusData).length > 0 ? statusData.badRecords : ''} Error(s)`} className='error' />
              <Image image={counterIcon} />
              <p className='counter'> <span>{Object.keys(statusData).length > 0 && statusData.badRecords}</span>{Object.keys(statusData).length > 0 && `/${statusData.beneficiaryCount}`} </p>
            </div>
            <div className={`payments__error__processed`}>
              {
                Object.keys(statusData).length > 0 && statusData.status === 11 &&
                <div title="No. of deduction accounts provided do not match the No. of deduction entries on file">
                  <Image image={caution} title="deduction" />
                </div>
              }
              {
                Object.keys(statusData).length > 0 &&
                <p>
                  <span>
                    {`${statusData.processedCount}/${statusData.beneficiaryCount}`}
                  </span> files processed
                </p>
              }
            </div>
          </div>
          <div className='container-full payments__table'>
            {
              (state.dashboardReducer.results !== null) ?
                <OngoingPaymentsListTable data={state.dashboardReducer.results} toFilter={toFilter} statusData={statusData} pagination={size} setPagination={changeSize} changePage={changePage} page={page} reloadPage={refreshTable} /> : null
            }
          </div>
        </div>
      </OngoingPaymentsListTemplateStyle>
    </>
  );

};

export default OngoingPaymentsListTemplate;
