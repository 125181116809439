import React, { useState, useEffect, useRef } from 'react';
import MerchantProfileTemplateStyle from './MerchantProfileTemplateStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import angleLeft from '../../../assets/images/angle-small-left.svg';
import merchantProfile from '../../../assets/images/merchant-profile.svg';
import editMerchant from '../../../assets/images/edit-merchant.svg';
import deactivateMerchant from '../../../assets/images/deactivate-merchant.svg';
import MerchantNav from '../../organisms/MerchantNav/MerchantNav';
import document from '../../../assets/images/document-new.svg';
import expandIcon from '../../../assets/images/expand-icon.svg';
import { Link } from 'react-router-dom';
import rejectIcon from '../../../assets/images/reject-icon-red.svg';
import approveIcon from '../../../assets/images/approve-icon-green.svg';
import RejectMerchantModal from '../../organisms/RejectMerchantModal/RejectMerchantModal';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { DashboardActions } from '../../../redux/actions/DashboardAction';
import dayjs from 'dayjs'
import Cookies from 'js-cookie';
import { appInstance as axios } from '../../../axios';
import cogoToast from "cogo-toast";

const MerchantProfileTemplate = () => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const [url, setFileUrl] = useState<string>();
  const [name, setFileName] = useState<string>();
  const [toggleRejectMerchantModal, setToggleRejectMerchantModal] = useState(false);
  const [imageUrl, setFileimageUrl] = useState<string>();

  const showRejectMerchantModal = () => {
    setToggleRejectMerchantModal(true)
  }

  const hideRejectMerchantModal = () => {
    setToggleRejectMerchantModal(false)
  }

  const renderRejectMerchantModal = () => {
    if (toggleRejectMerchantModal) {
      return (
        <RejectMerchantModal handleClose={hideRejectMerchantModal} />
      )
    }
  }

  const [fetchLoading, setFetchLoading] = useState(true);
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  console.log(state);

  useEffect(() => {
    if (state.dashboardReducer.results && state.dashboardReducer.results.data.content[0] && state.dashboardReducer.results.data.content[0].companyLogoUri) {
      openImageFile(state.dashboardReducer.results.data.content[0].companyLogoUri);
    }
  }, [state.dashboardReducer])

  useEffect(() => {
    generatePageContent();
  }, [])

  const generatePageContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/merchant?${window.location.href.split("?")[1]}`));
    setFetchLoading(false);
  }

  const openImageFile = async (fileUri: string) => {
    const apiDefinition = () => {
      return axios.get(`${fileUri.substring(4)}`,
        {
          responseType: "blob",
          headers: {
            "Authorization": `Bearer ${Cookies.get("userToken")}`,
          }
        }
      );
    };
    try {
      setFetchLoading(true);
      const { data } = await apiDefinition();
      const url = URL.createObjectURL(new Blob([data]));
      setFileimageUrl(url);
      setFetchLoading(false);
    } catch (error) {
      cogoToast.error("Error downloading file", { position: "top-right" })
    }
  }

  const renderFetchLoading = () => {
    if (fetchLoading) {
      return (
        <FetchLoading />
      )
    }
  }

  const openFile = async (fileUri: string, fileName: string) => {
    const apiDefinition = () => {
      return axios.get(`${fileUri.substring(4)}`,
        {
          responseType: "blob",
          headers: {
            "Authorization": `Bearer ${Cookies.get("userToken")}`,
          }
        }
      );
    };
    try {
      setFetchLoading(true);
      const { data } = await apiDefinition();
      const url = URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      setFileUrl(url);
      setFileName(fileName);
      ref.current && ref.current.click();
      setFetchLoading(false);
      URL.revokeObjectURL(url);
    } catch (error) {
      cogoToast.error("Error downloading file", { position: "top-right" })
    }
  }

  return (
    <>
      {renderRejectMerchantModal()}
      {renderFetchLoading()}
      <MerchantProfileTemplateStyle>
        <div className={`container-full merchants`}>
          <div className={`container-full merchants__back`}>
            <Link to="/merchants">
              <Image image={angleLeft} />
              <Text value='Back to Merchants' />
            </Link>
          </div>
          <div className={`container-full merchants__desc`}>
            <HeaderText value='Merchants' />
          </div>
          <div className={`container-full merchants__top`}>
            <div className={`container-full merchants__top__desc`}>
              <div className={`merchants__top__desc__name`}>
                {
                  (state.dashboardReducer.results && imageUrl) ?
                    <Image image={imageUrl} /> : <Image image={merchantProfile} />
                }
                <div className={`merchants__top__desc__name__group`}>
                  <HeaderText value={(state.dashboardReducer.results && state.dashboardReducer.results.data.content[0]) ? state.dashboardReducer.results.data.content[0].businessName : ''} />
                  <Text value={(state.dashboardReducer.results && state.dashboardReducer.results.data.content[0]) ? state.dashboardReducer.results.data.content[0].businessEmail : ''} />
                </div>
              </div>
              <div className={`merchants__top__desc__action`}>
                <div className={`merchants__top__desc__action__item --edit`}>
                  {
                    (state.authReducer.results.roles.includes('Authorizer') && state.authReducer.results.roles.length === 1)
                      || (state.authReducer.results.roles.includes('Audit') && state.authReducer.results.roles.length === 1) ?
                      null :
                      <Link to={`/merchants/profile/edit?${window.location.href.split("?")[1]}`}>
                        <Image image={editMerchant} />
                      </Link>
                  }

                </div>
                {/* <div className={`merchants__top__desc__action__item`}>
                  <Image image={deactivateMerchant} />
                </div> */}
              </div>
              {/* <div className={`merchants__top__desc__action --validate`}>
                <button className='button --reject' onClick={() => showRejectMerchantModal()}>
                  <Image image={rejectIcon} />
                  Reject
                </button>
                <button className='button --approve'>
                  <Image image={approveIcon} />
                  Approve
                </button>
              </div> */}
            </div>
            <div className={`container-full merchants__top__nav`}>
              <div>
                <MerchantNav />
              </div>
              <p className='date'> Added: <span> {
                (state.dashboardReducer.results && state.dashboardReducer.results.data.content[0]) ? dayjs(state.dashboardReducer.results.data.content[0].createdDate).format('ddd, D MMMM, YYYY') : ''
              } </span> </p>
            </div>
          </div>
          <div className={`container-full merchants__info`}>
            <div className={`container-full merchants__info__desc`}>
              <HeaderText value='Business Information' />
            </div>
            <div className={`container-full merchants__info__box`}>
              <div className='container-full merchants__info__box__row'>
                <div className='div-md-6 merchants__info__box__row__item'>
                  <HeaderText value='Name' />
                  <Text value={
                    (state.dashboardReducer.results && state.dashboardReducer.results.data.content[0]) ? state.dashboardReducer.results.data.content[0].businessName : ''
                  } />
                </div>
                <div className='div-md-6 merchants__info__box__row__item'>
                  <HeaderText value='Email' />
                  <Text value={(state.dashboardReducer.results && state.dashboardReducer.results.data.content[0]) ? state.dashboardReducer.results.data.content[0].businessEmail : ''} />
                </div>
              </div>
              <div className='container-full merchants__info__box__row'>
                <div className='div-md-6 merchants__info__box__row__item'>
                  <HeaderText value='RC Number' />
                  <Text value={(state.dashboardReducer.results && state.dashboardReducer.results.data.content[0]) ? state.dashboardReducer.results.data.content[0].rcNumber : ''} />
                </div>
                <div className='div-md-6 merchants__info__box__row__item'>
                  <HeaderText value='Contact Number' />
                  <Text value={(state.dashboardReducer.results && state.dashboardReducer.results.data.content[0]) ? state.dashboardReducer.results.data.content[0].businessPhoneNumber : ''} />
                </div>
              </div>
              <div className='container-full merchants__info__box__row'>
                <div className='div-md-12 merchants__info__box__row__item'>
                  <HeaderText value='Allow Salary Processing' />
                  <div className={`merchants__info__box__row__item__switch`}>
                    <input type="checkbox" className={`input`} checked={
                      (state.dashboardReducer.results && state.dashboardReducer.results.data.content[0]) && state.dashboardReducer.results.data.content[0].processSalary
                    } />
                    <div className={`merchants__info__box__row__item__switch__box`}>
                      <div className={`merchants__info__box__row__item__switch__box__ball`}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`container-full merchants__info`}>
            <div className={`container-full merchants__info__desc`}>
              <HeaderText value='Emergency Contact' />
            </div>
            <div className={`container-full merchants__info__box --emergency`}>
              <div className='container-full merchants__info__box__row'>
                <div className='div-sm-12 merchants__info__box__row__item'>
                  <HeaderText value='Address' />
                  <Text value={(state.dashboardReducer.results && state.dashboardReducer.results.data.content[0]) ? state.dashboardReducer.results.data.content[0].address : ''} />
                </div>
              </div>
            </div>
          </div>
          <div className={`container-full merchants__info`}>
            <div className={`container-full merchants__info__desc`}>
              <HeaderText value='KYC Documents' />
            </div>
            <div className={`container-full merchants__info__box`}>
              <div className='container-full merchants__info__box__row'>
                <a href={url} download={name} className="hidden" ref={ref} />
                {
                  state.dashboardReducer.results && state.dashboardReducer.results.data.content[0] && state.dashboardReducer.results.data.content[0].kyc !== undefined &&
                  state.dashboardReducer.results.data.content[0].kyc.length > 0 &&
                  state.dashboardReducer.results.data.content[0].kyc.map((kyc: Record<any, any>) => {
                    if (kyc.fileUri) {
                      return (
                        <div className='div-md-6 merchants__info__box__row__item --documents' key={kyc.id} onClick={() => openFile(kyc.fileUri, kyc.name)}>
                          <Image image={document} className="document" />
                          <div>
                            <HeaderText value={kyc.name} />
                            <Text value='PDF' />
                          </div>
                          <Image image={expandIcon} className="expand" />
                        </div>
                      )
                    }
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </MerchantProfileTemplateStyle>
    </>
  );

};

export default MerchantProfileTemplate;
