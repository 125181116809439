import styled from "styled-components";
import Colors from '../../../helpers/Colors';

const DashboardMenuStyle = styled.div`
  background: #0A1249;  
  box-sizing: border-box;
  height: 100vh;
  padding: 0 25px 0 25px;
  position: relative;
  width: 100%;

  .dashboard-menu__logo{
    margin-top: 27px;
  }
  a{
    width: 100%;
  }
  .dashboard-menu__menus{
    margin-top: 88px;
  }
  .dashboard-menu__menus__item{
    border-left: 4px solid transparent;
    box-sizing: border-box;
    height: 40px;
    justify-content: normal;
    margin-bottom: 10px;
    padding: 0 8px 0 8px;
    position: relative;
    width: 100%;
    p{
      color: white;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 0.875em;
      line-height: 18px;
      margin-left: 8px;
      margin-top: 11px;
      pointer-events: none;
    }
    img{
      pointer-events: none;
    }
    .caret{
      margin: 18.33px 11.67px 0 0;
      position: absolute;
      right: 0;
      top: 0;
      transform: rotate(180deg);
    }
  }
  .--parent{
    margin-bottom: 0;
  }
  .dashboard-menu__menus__item__sub-menu{
    margin-bottom: 10px;
    max-height: 0;
    overflow: hidden;
    transition: all linear 0.5s;
  }
  .dashboard-menu__menus__item__sub-menu__item{
    box-sizing: border-box;
    height: 28px;
    margin-top: 5px;
    padding-left: 36px;
    position: relative;
    p{
      color: rgba(255, 255, 255, 0.5);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 0.875em;
      line-height: 18px;
      margin-top: 5px;
    }
    img{
      display: none;
      left: 0;
      margin-left: 23px;
      margin-top: 11.5px;
      position: absolute;
      top: 0;
    }
  }
  .active > .dashboard-menu__menus__item{
    background: rgba(255, 255, 255, 0.1);
    border-left: 4px solid white;
  }
  .active > .dashboard-menu__menus__item__sub-menu__item{
    img{
      display: block;
    }
    p{
      color: white;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .dashboard-menu__user{
    bottom: 0;
    justify-content: normal;
    margin-bottom: 35px;
    position: absolute;
  }
  .dashboard-menu__user__img{
    border: 1.5px solid white;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    .dp{
      border-radius: 50%;
      height: 40px;
      object-fit: cover;
      width: 40px;
    }
  }
  .dashboard-menu__user__text{
    margin-left: 9px;
    margin-top: 2px;
    h2{
      color: #FFF;
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    p{
      color: #FFF;
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      opacity: 0.6;
    }
  }
`;

export default DashboardMenuStyle;