import styled from "styled-components";

const CreateFeeCategoryModalStyle = styled.div`
    animation: show-bg 0.5s linear forwards;
    box-sizing:border-box;
    display:flex;
    height:100%;
    left:0;
    position:absolute;
    position:fixed;
    top: 0;
    width:100%;
    z-index:5000;
    @keyframes show-bg{
        0%{
            background:rgba(0, 0, 0, 0)
        }
        100%{
            background:rgba(37, 39, 51, 0.7);
        }
    }
    .modal-container{
        box-sizing:border-box;
        height:100vh;
    }
    .modal-container__box{
        -ms-flex: 0 0 645px;
        -webkit-flex: 0 0 645px;
        flex: 0 0 645px;
        max-width: 645px;
        background: #FAFAFA;
        border-radius:10px;
        box-sizing:border-box;
        padding: 20px;
        padding-bottom: 30px;
    }
    .modal-container__box__header{
        box-sizing: border-box;
        padding-left: 10px;
        h2{
           color: #252733;
           font-family: 'Mulish';
           font-style: normal;
           font-weight: 700;
           font-size: 18px;
           line-height: 30px;
        }
        img{
            cursor: pointer;
        }
    }
    .modal-container__box__content{
        box-sizing: border-box;
        margin-top: 28px;
        padding: 0 15px;
    }
    .modal-container__box__content__item{
        margin-bottom: 13px;
        &:last-child{
            margin-bottom: 0;
        }
        p{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 9px;
            span{
                color: #F12B2C;
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                margin-left: 5px;
            }
        }
        .input{
            background: rgba(55, 81, 255, 0.025);
            border: none;
            border-radius: 6px;
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            height: 40px;
            padding-left: 15px;
            position: relative;
            &::placeholder{
                color: rgba(37, 39, 51, 0.3);
            }
        }
    }
    .modal-container__box__action{
        justify-content: flex-end;
        margin-top: 79px;
        .button{
            border-radius: 8px;
            font-family: 'Mulish';
            font-weight: 500;
            font-size: 16px;
            height: 50px;
            margin-bottom: 0;
        }
        .--cancel{
            background: #F5F5F5;
            color: #252733;
            margin-right: 20px;
            width: 102px;
        }
        .--change{
            background: #3751FF;
            box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
            color: white;
            width: 117px;
        }
    }
`
export default CreateFeeCategoryModalStyle;