import React, { useState } from 'react';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import MerchantsFeesTableStyle from './MerchantsFeesTableStyle';
import nike from '../../../assets/images/nike.svg';
import elipses from '../../../assets/images/elipses.svg';
import userImage from '../../../assets/images/user-male.svg';
import Pagination from '../Pagination/Pagination';
import dayjs from 'dayjs';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {exportData} from '../../../redux/actions/ExportAction';
import { useNavigate } from "react-router-dom";

type UserTableProps = {
  data: any,
  pagination: number,
  setPagination: Function,
  changePage: Function,
  page: number,
  reloadPage: Function
}

const MerchantsFeesTable = ({data, pagination, setPagination, changePage, page, reloadPage}: UserTableProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state:any = useSelector( (state:RootStore) => state);
  let timeOutId:any = null;
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const navigate = useNavigate();

  const onClickHandler = (value: boolean, id: string) => {
    if(selectedMenu !== false){
      setSelectedMenu(false);
      return;
    }
    setSelectedMenu(value);
    setSelectedId(id);
  }

  const onBlurHandler = () => {

      timeOutId = setTimeout(() => {
        setSelectedMenu(false);
      });
  }

  const onFocusHandler = () =>{
      clearTimeout(timeOutId);
  }

  const handleAllCheck = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      await dispatch(exportData(state.dashboardReducer.results.data.content))
      console.log(state.exportReducer);
    }
    else{
      await dispatch(exportData([]));
      console.log(state.exportReducer);
    }
  };

  const handleCheckItem = (item: Record<any, any>) => {
    if(state.exportReducer.results.data.includes(item)){
      const newArr = state.exportReducer.results.data.filter( (record: Record<any,any>) => record.id !== item.id)
      console.log(newArr);
      return dispatch(exportData(newArr));
    }
    const update = [...state.exportReducer.results.data, item]
    console.log(update);
    return dispatch(exportData(update));
  };


  return (
    <>
      <MerchantsFeesTableStyle>
        <div className={`container-full`}>
          <table>
            <thead>
              <tr className={`table-header`}>
                <th>
                  <input type="checkbox" onChange={handleAllCheck}/>
                  <Image image={nike} />
                </th>
                <th>
                  <Text value="Name" className={`table-title`} />
                </th>
                <th>
                  <Text value="Description" className={`table-title`} />
                </th>
                <th>
                  <Text value="Date Created" className={`table-title`} />
                </th>
                <th>
                  <Text value="Last Updated" className={`table-title`} />
                </th>
                <th>
                  <Text value="Status" className={`table-title`} />
                </th>
                <th>
                  <Text value="More" className={`table-title`} />
                </th>
              </tr>
            </thead>
            <tbody className={`table-body`}>
              {
                (data.data.content !== undefined && data.data.content.length > 0) ?
                data.data.content.map((fee: Record<any, any>, key: string) => {
                  return (
                    <tr className={`table-row`} key={key}>
                      <td>
                        <input
                          type="checkbox"
                          checked={state.exportReducer.results.data.includes(
                            fee
                          )}
                          onChange={() => handleCheckItem(fee)}
                        />
                        <Image image={nike} />
                      </td >
                      <td> 
                        <Text value={fee.name} className='label' />
                      </td>
                      <td> 
                        <Text value={fee.description} className='label' />
                      </td>
                      <td> 
                        <Text value={dayjs(fee.createdDate).format('MM/DD/YYYY')} className='label' />
                        <Text value={dayjs(fee.createdDate).format('h:mm A')} className='date' />
                      </td>
                      <td> 
                        <Text value={dayjs(fee.lastModifiedDate).format('MM/DD/YYYY')} className='label' />
                        <Text value={dayjs(fee.lastModifiedDate).format('h:mm A')} className='date' />
                      </td>
                      <td> 
                        {
                          (fee.status === 0) ? 
                          <button className='status --inactive'>
                            Inactive
                          </button> : null
                        }
                        {
                          (fee.status === 1) ? 
                          <button className='status --active'>
                            Active
                          </button>: null
                        }
                        {
                          (fee.status > 1) ? 
                          <button className='status --pending'>
                            Pending
                          </button> : null
                        } 
                      </td>
                      <td> 
                      <div className={`container-full`} onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
                          <button className={`button --elipses`} onClick={() => onClickHandler(true, fee.id)} aria-haspopup="true" aria-expanded={selectedMenu}>
                            <Image image={elipses} />
                          </button>
                          {
                            selectedMenu !== false && selectedId === fee.id && (
                              <div className={`container-full more-info`}>
                                <div className={`container-full more-info__item`}>
                                  <button onClick={() => navigate(`/merchants/fees/summary?merchantId=${fee.merchantId}&feeCategoryId=${fee.id}`)}>
                                    <Text value='View Details' />
                                  </button>
                                </div>
                              </div>
                            )
                          }
                        </div>
                      </td>
                    </tr>
                  )
                }) : null
              }
              
            </tbody>
          </table>
        </div>
        <div className={`container-full pagination`}>
          <Pagination pagination={pagination} changePage={changePage} setPagination={setPagination} page={page}/> 
        </div>
      </MerchantsFeesTableStyle>
    </>
  );
};

export default MerchantsFeesTable;
