import React, { useState, useEffect } from 'react';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import SetFeesFormStyle from './SetFeesFormStyle';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { signIn } from '../../../redux/actions/AuthAction';
import { useNavigate } from "react-router-dom";
import caret from '../../../assets/images/caret-down-select.svg';
import addIcon from '../../../assets/images/add-blue.svg';
import { setDeductions, setNHF, setNHIS, setPAYE, setPension, setSalary } from '../../../redux/actions/FeeStepAction';

type SetFeesFormProps = {
  handleClose: Function,
  setStep: any
  feeType: string
}


const SetFeesForm = ({ handleClose, setStep, feeType }: SetFeesFormProps) => {
  const [fields, setFields] = useState({
    chargeTo: '',
    feeType: '',
    chargeType: '',
    amount: '0.00',
    addedFee: '0.00',
    minimumFee: '0.00',
    maximumFee: '0.00',
  })
  const [fieldErrors, setFieldErrors] = useState({
    maximumFee: ''
  })
  const [scaleBands, setScaleBands]:any = useState([]);
  const [scaleBandsError, setScaleBandsError]:any = useState([]);
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  // const navigate = useNavigate();

  useEffect(() => {
    if (feeType === "nhis") {
      if (state.feeStepReducer.results.nhis && Object.keys(state.feeStepReducer.results.nhis).length > 0) {
        let fieldValue = {
          chargeTo: state.feeStepReducer.results.nhis.bearer,
          feeType: state.feeStepReducer.results.nhis.feeType,
          chargeType: state.feeStepReducer.results.nhis.chargeType,
          amount: state.feeStepReducer.results.nhis.feeValue,
          addedFee: state.feeStepReducer.results.nhis.addedFee,
          minimumFee: state.feeStepReducer.results.nhis.minimumFee,
          maximumFee: state.feeStepReducer.results.nhis.maximumFee,
        }
        setFields(fieldValue);
        setScaleBands(state.feeStepReducer.results.nhis.participantFeeScale)
      }
    }
    else if (feeType === "nhf") {
      if (state.feeStepReducer.results.nhf && Object.keys(state.feeStepReducer.results.nhf).length > 0) {
        let fieldValue = {
          chargeTo: state.feeStepReducer.results.nhf.bearer,
          feeType: state.feeStepReducer.results.nhf.feeType,
          chargeType: state.feeStepReducer.results.nhf.chargeType,
          amount: state.feeStepReducer.results.nhf.feeValue,
          addedFee: state.feeStepReducer.results.nhf.addedFee,
          minimumFee: state.feeStepReducer.results.nhf.minimumFee,
          maximumFee: state.feeStepReducer.results.nhf.maximumFee,
        }
        setFields(fieldValue);
        setScaleBands(state.feeStepReducer.results.nhf.participantFeeScale)
      }
    }
    else if (feeType === "pension") {
      if (state.feeStepReducer.results.pension && Object.keys(state.feeStepReducer.results.pension).length > 0) {
        let fieldValue = {
          chargeTo: state.feeStepReducer.results.pension.bearer,
          feeType: state.feeStepReducer.results.pension.feeType,
          chargeType: state.feeStepReducer.results.pension.chargeType,
          amount: state.feeStepReducer.results.pension.feeValue,
          addedFee: state.feeStepReducer.results.pension.addedFee,
          minimumFee: state.feeStepReducer.results.pension.minimumFee,
          maximumFee: state.feeStepReducer.results.pension.maximumFee,
        }
        setFields(fieldValue);
        setScaleBands(state.feeStepReducer.results.pension.participantFeeScale)
      }
    }
    else if (feeType === "paye") {
      if (state.feeStepReducer.results.paye && Object.keys(state.feeStepReducer.results.paye).length > 0) {
        let fieldValue = {
          chargeTo: state.feeStepReducer.results.paye.bearer,
          feeType: state.feeStepReducer.results.paye.feeType,
          chargeType: state.feeStepReducer.results.paye.chargeType,
          amount: state.feeStepReducer.results.paye.feeValue,
          addedFee: state.feeStepReducer.results.paye.addedFee,
          minimumFee: state.feeStepReducer.results.paye.minimumFee,
          maximumFee: state.feeStepReducer.results.paye.maximumFee,
        }
        setFields(fieldValue);
        setScaleBands(state.feeStepReducer.results.paye.participantFeeScale)
      }
    }
    else if (feeType === "salary") {
      if (state.feeStepReducer.results.salary && Object.keys(state.feeStepReducer.results.salary).length > 0) {
        let fieldValue = {
          chargeTo: state.feeStepReducer.results.salary.bearer,
          feeType: state.feeStepReducer.results.salary.feeType,
          chargeType: state.feeStepReducer.results.salary.chargeType,
          amount: state.feeStepReducer.results.salary.feeValue,
          addedFee: state.feeStepReducer.results.salary.addedFee,
          minimumFee: state.feeStepReducer.results.salary.minimumFee,
          maximumFee: state.feeStepReducer.results.salary.maximumFee,
        }
        setFields(fieldValue);
        setScaleBands(state.feeStepReducer.results.salary.participantFeeScale)
      }
    }
    else if (feeType === "deductions") {
      if (state.feeStepReducer.results.deductions && Object.keys(state.feeStepReducer.results.deductions).length > 0) {
        let fieldValue = {
          chargeTo: state.feeStepReducer.results.deductions.bearer,
          feeType: state.feeStepReducer.results.deductions.feeType,
          chargeType: state.feeStepReducer.results.deductions.chargeType,
          amount: state.feeStepReducer.results.deductions.feeValue,
          addedFee: state.feeStepReducer.results.deductions.addedFee,
          minimumFee: state.feeStepReducer.results.deductions.minimumFee,
          maximumFee: state.feeStepReducer.results.deductions.maximumFee,
        }
        setFields(fieldValue);
        setScaleBands(state.feeStepReducer.results.deductions.participantFeeScale)
      }
    }
  }, [])

  const inputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues[name] = value;
    if(name === "feeType" && (fieldsValues.feeType === "1" || fieldsValues.feeType === "0") ){
      fieldsValues["minimumFee"] = "0.00";
      fieldsValues["maximumFee"] = "0.00";
      const fieldErrorValues: any = Object.assign({}, fieldErrors);
      fieldErrorValues["maximumFee"] = '';
      setFieldErrors(fieldErrorValues);
    }
    await setFields(fieldsValues);
    if(name === "feeType" && fieldsValues.feeType !== "0" ){
      setScaleBands([]);
      setScaleBandsError([]);
    }
    if(name === "maximumFee"){
      maximumInputValidate(name, value);
    }
    if(name === "minimumFee"){
      minimumInputValidate(name, value);
    }
  };

  const maximumInputValidate = async (name: string, value: string) => {
    if(parseFloat(value) <= parseFloat(fields.minimumFee)){
      const fieldErrorValues: any = Object.assign({}, fieldErrors);
      fieldErrorValues[name] = 'Maximum value should be greater than minimum';
      await setFieldErrors(fieldErrorValues);
      return false
    }
    else{
      const fieldErrorValues: any = Object.assign({}, fieldErrors);
      fieldErrorValues[name] = false;
      await setFieldErrors(fieldErrorValues);
      return true
    }
  }

  const minimumInputValidate = async (name: string, value: string) => {
    if(parseFloat(value) >= parseFloat(fields.maximumFee)){
      const fieldErrorValues: any = Object.assign({}, fieldErrors);
      fieldErrorValues["maximumFee"] = 'Maximum value should be greater than minimum';
      await setFieldErrors(fieldErrorValues);
      return false
    }
    else{
      const fieldErrorValues: any = Object.assign({}, fieldErrors);
      fieldErrorValues["maximumFee"] = false;
      await setFieldErrors(fieldErrorValues);
      return true
    }
  }

  const addBand = () => {
    let newBand;
    if(scaleBands.length > 0){
      newBand = {
        scaleFrom: parseFloat(scaleBands[scaleBands.length - 1].scaleTo) + 0.01,
        scaleTo: '0.00',
        scaleType: '',
        scaleValue: '0.00',
        addedFixedFee: '0.00',
        minFee: '0.00',
        maxFee: '0.00',
      }
    }
    else{
      newBand = {
        scaleFrom: '0.00',
        scaleTo: '0.00',
        scaleType: '',
        scaleValue: '0.00',
        addedFixedFee: '0.00',
        minFee: '0.00',
        maxFee: '0.00',
      }
    }
    
    const bands: any = [...scaleBands, newBand];
    setScaleBands(bands);
    const error = {
      maxFee: 'Maximum value should be greater than minimum',
      scaleTo: 'Range to should be greater than range from'
    }
    const errors:any = [...scaleBandsError, error];
    setScaleBandsError(errors);
  }

  const removeBand = (item: Record<any, any>) => {
    let filteredBand = scaleBands.filter((scale: Record<any, any>, key: number) => scale.scaleFrom !== item.scaleFrom);
    setScaleBands(filteredBand);
  }

  const bandInputChange = async (name: string, value: string, index: number) => {
    const fieldsValues: any = Object.assign({}, scaleBands[index]);
    fieldsValues[name] = value;
    const bands:any = scaleBands.slice()
    bands[index] = fieldsValues
    await setScaleBands(bands);
    if(name === "scaleType" && fieldsValues.scaleType === "0"){
      fieldsValues["minFee"] = "0.00";
      fieldsValues["maxFee"] = "0.00";
      const fieldErrorValues: any = Object.assign({}, scaleBandsError[scaleBandsError.length - 1]);
      fieldErrorValues["maxFee"] = '';
      const errors = scaleBandsError.slice();
      errors[index] = fieldErrorValues
      await setScaleBandsError(errors);
    }
    if(name === "maxFee"){
      maximumFeeValidate(name, value, index);
    }
    if(name === "minFee"){
      minimumFeeValidate(name, value, index);
    }
    if(name === "scaleTo"){
      scaleToValidate(name, value, index)
    }
  }

  const scaleToValidate = async (name: string, value: string, index: number) => {
    if(parseFloat(value) <= parseFloat(scaleBands[index].scaleFrom)){
      const fieldErrorValues: any = Object.assign({}, scaleBandsError[index]);
      fieldErrorValues[name] = 'Range to should be greater than range from';
      const errors = scaleBandsError.slice();
      errors[index] = fieldErrorValues
      await setScaleBandsError(errors);
      return false
    }
    else{
      const fieldErrorValues: any = Object.assign({}, scaleBandsError[index]);
      fieldErrorValues[name] = false;
      const errors = scaleBandsError.slice();
      errors[index] = fieldErrorValues
      await setScaleBandsError(errors);
      return true
    }
  }

  const maximumFeeValidate = async (name: string, value: string, index: number) => {
    if(parseFloat(value) <= parseFloat(scaleBands[index].minFee)){
      const fieldErrorValues: any = Object.assign({}, scaleBandsError[index]);
      fieldErrorValues[name] = 'Maximum value should be greater than minimum';
      const errors = scaleBandsError.slice();
      errors[index] = fieldErrorValues
      await setScaleBandsError(errors);
      return false
    }
    else{
      const fieldErrorValues: any = Object.assign({}, scaleBandsError[index]);
      fieldErrorValues[name] = false;
      const errors = scaleBandsError.slice();
      errors[index] = fieldErrorValues
      await setScaleBandsError(errors);
      return true
    }
  }

  const minimumFeeValidate = async (name: string, value: string, index: number) => {
    if(parseFloat(value) >= parseFloat(scaleBands[index].maxFee)){
      const fieldErrorValues: any = Object.assign({}, scaleBandsError[index]);
      fieldErrorValues["maxFee"] = 'Maximum value should be greater than minimum';
      const errors = scaleBandsError.slice();
      errors[index] = fieldErrorValues
      await setScaleBandsError(errors);
      return false
    }
    else{
      const fieldErrorValues: any = Object.assign({}, scaleBandsError[index]);
      fieldErrorValues["maxFee"] = false;
      const errors = scaleBandsError.slice();
      errors[index] = fieldErrorValues
      await setScaleBandsError(errors);
      return true
    }
  }

  const onFormSubmit = () => {
    return async (evt: React.FormEvent) => {
      evt.preventDefault();
      if (feeType === "nhis") {
        dispatch(setNHIS({
          "participantType": 4,
          "bearer": fields.chargeTo,
          "feeType": fields.feeType,
          "chargeType": fields.chargeType,
          "feeValue": fields.amount,
          "addedFee": fields.addedFee,
          "maximumFee": fields.maximumFee,
          "minimumFee": fields.minimumFee,
          "participantFeeScale": scaleBands
        }));
      }
      else if (feeType === "nhf") {
        dispatch(setNHF({
          "participantType": 5,
          "bearer": fields.chargeTo,
          "feeType": fields.feeType,
          "chargeType": fields.chargeType,
          "feeValue": fields.amount,
          "addedFee": fields.addedFee,
          "maximumFee": fields.maximumFee,
          "minimumFee": fields.minimumFee,
          "participantFeeScale": scaleBands
        }));
      }
      else if (feeType === "pension") {
        dispatch(setPension({
          "participantType": 3,
          "bearer": fields.chargeTo,
          "feeType": fields.feeType,
          "chargeType": fields.chargeType,
          "feeValue": fields.amount,
          "addedFee": fields.addedFee,
          "maximumFee": fields.maximumFee,
          "minimumFee": fields.minimumFee,
          "participantFeeScale": scaleBands
        }));
      }
      else if (feeType === "paye") {
        dispatch(setPAYE({
          "participantType": 2,
          "bearer": fields.chargeTo,
          "feeType": fields.feeType,
          "chargeType": fields.chargeType,
          "feeValue": fields.amount,
          "addedFee": fields.addedFee,
          "maximumFee": fields.maximumFee,
          "minimumFee": fields.minimumFee,
          "participantFeeScale": scaleBands
        }));
      }
      else if (feeType === "salary") {
        dispatch(setSalary({
          "participantType": 1,
          "bearer": fields.chargeTo,
          "feeType": fields.feeType,
          "chargeType": fields.chargeType,
          "feeValue": fields.amount,
          "addedFee": fields.addedFee,
          "maximumFee": fields.maximumFee,
          "minimumFee": fields.minimumFee,
          "participantFeeScale": scaleBands
        }));
      }
      else if (feeType === "deductions") {
        dispatch(setDeductions({
          "participantType": 6,
          "bearer": fields.chargeTo,
          "feeType": fields.feeType,
          "chargeType": fields.chargeType,
          "feeValue": fields.amount,
          "addedFee": fields.addedFee,
          "maximumFee": fields.maximumFee,
          "minimumFee": fields.minimumFee,
          "participantFeeScale": scaleBands
        }));
      }
      setStep();
    }
  }

  const validate = () => {         
    const person:any = fieldErrors;
    if (Object.keys(fieldErrors).filter((k) => person[k]).length){
        return true;
    }
    return false;
  } 

  const validateScaleBands = () => {   
    if(scaleBandsError.length > 0){
      const person:any = scaleBandsError[scaleBandsError.length - 1];
      if (Object.keys(scaleBandsError[scaleBandsError.length - 1]).filter((k) => person[k]).length){
          return true;
      }
    }   
    return false;   
  } 

  const clear = () => {
    const fieldValues = {
      chargeTo: '',
      feeType: '',
      chargeType: '',
      amount: '0.00',
      addedFee: '0.00',
      minimumFee: '0.00',
      maximumFee: '0.00',
    }
    if (feeType === "nhis") {
      dispatch(setNHIS({}));
      setFields(fieldValues);
    }
    if (feeType === "nhf") {
      dispatch(setNHF({}));
      setFields(fieldValues);
    }
    if (feeType === "pension") {
      dispatch(setPension({}));
      setFields(fieldValues);
    }
    if (feeType === "paye") {
      dispatch(setPAYE({}));
      setFields(fieldValues);
    }
    if (feeType === "salary") {
      dispatch(setSalary({}));
      setFields(fieldValues);
    }
    if (feeType === "deductions") {
      dispatch(setDeductions({}));
      setFields(fieldValues);
    }
  }

  const validateAddBandButton = () => {
    if(scaleBands.length > 0){
      if(scaleBands[scaleBands.length - 1].scaleType !== "0"){
        if( (scaleBands[scaleBands.length - 1].minFee === "" || scaleBands[scaleBands.length - 1].maxFee === "" || scaleBands[scaleBands.length - 1].scaleTo === "") ||
          (parseFloat(scaleBands[scaleBands.length - 1].maxFee) <= parseFloat(scaleBands[scaleBands.length - 1].minFee)) ||
          (parseFloat(scaleBands[scaleBands.length - 1].scaleTo) <= parseFloat(scaleBands[scaleBands.length - 1].scaleFrom))){
          return true
        }
      }
      else if(scaleBands[scaleBands.length - 1].scaleType === "0"){
        if(scaleBands[scaleBands.length - 1].scaleTo === "" || parseFloat(scaleBands[scaleBands.length - 1].scaleTo) <= parseFloat(scaleBands[scaleBands.length - 1].scaleFrom)){
          return true
        }
      }
      else{
        return false
      }
    }
    else{
      return false;
    }  
  }

  return (
    <SetFeesFormStyle>
      <div className={`container-full form`}>
        <form onSubmit={onFormSubmit()}>
          <div className={`container-full form__content`}>
            <div className={`container-full form__content__item`}>
              <p> Charge to <span>*</span></p>
              <div className={`container-full form__content__item__radio`}>
                <div className={`div-md-6 form__content__item__radio__item`}>
                  <input type="radio" name="chargeTo" className='radio' required onChange={(evt) => inputChange(evt.target.name, evt.target.value)} value="1" checked={fields.chargeTo === "1" ? true : false} />
                  <div className={`form__content__item__radio__item__box`}>
                    <div className={`form__content__item__radio__item__box__circle`}></div>
                    <Text value='Merchant' />
                  </div>
                </div>
                <div className={`div-md-6 form__content__item__radio__item`}>
                  <input type="radio" name="chargeTo" className='radio' required onChange={(evt) => inputChange(evt.target.name, evt.target.value)} value="2" checked={fields.chargeTo === "2" ? true : false} />
                  <div className={`form__content__item__radio__item__box`}>
                    <div className={`form__content__item__radio__item__box__circle`}></div>
                    <Text value='Customer' />
                  </div>
                </div>
              </div>
            </div>
            <div className={`container-full form__content__item`}>
              <div className='div-md-6'>
                <p> Fee Type <span>*</span></p>
                <div className='input'>
                  <select required
                    name="feeType"
                    onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                    value={fields.feeType}
                  >
                    <option value="" hidden> Select Fee Type </option>
                    <option value="1"> Fixed </option>
                    <option value="2"> Percentage </option>
                    <option value="3"> Hybrid </option>
                    <option value="0"> Scaled </option>
                  </select>
                  <Image image={caret} />
                </div>
              </div>
              <div className='div-md-6'>
                <p> Charge Type <span>*</span></p>
                <div className='input'>
                  <select required
                    name="chargeType"
                    onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                    value={fields.chargeType}
                  >
                    <option value="" hidden> Select Charge Type </option>
                    <option value="1" hidden={fields.chargeTo === "2" ? true : false}> On Aggregate Payment </option>
                    <option value="2"> Per Transaction </option>
                  </select>
                  <Image image={caret} />
                </div>
              </div>
            </div>
          </div>
          <div className={`container-full form__fee-options`}>
            <div className={`container-full form__fee-options__desc`}>
              <Text value='Fee Options' />
            </div>
            {
              (fields.feeType === "") ?
                <div className={`container-full form__fee-options__warning`}>
                  <Text value='Select fee type to display fee options' />
                </div> : null
            }
            {
              (fields.feeType === "1") ?
                <div className={`container-full form__fee-options__form`}>
                  <div className={`container-full form__fee-options__form__item`}>
                    <p> Amount <span>*</span></p>
                    <div className='container-full input-box'>
                      <Input type='number' className="input" placeholder="Set Fee" required name="amount" value={fields.amount} onChange={(e) => inputChange(e.target.name, e.target.value)} />
                      <Text value='&#8358;' />
                    </div>
                  </div>
                </div> : null
            }
            {
              (fields.feeType === "2") ?
                <div className={`container-full form__fee-options__form`}>
                  <div className={`container-full form__fee-options__form__item`}>
                    <p> Percentage <span>*</span></p>
                    <div className='container-full input-box'>
                      <Input type='number' className="input" placeholder="Set Fee" required name="amount" max="100" value={fields.amount} onChange={(e) => inputChange(e.target.name, e.target.value)} />
                      <Text value='%' />
                    </div>
                  </div>
                  <div className={`container-full form__fee-options__form__item`}>
                    <div className='div-md-6'>
                      <p> Minimum Fee <span>*</span></p>
                      <div className='container-full input-box'>
                        <Input type='number' className="input" placeholder="Set Fee" required name="minimumFee" value={fields.minimumFee} onChange={(e) => inputChange(e.target.name, e.target.value)} />
                        <Text value='&#8358;' />
                      </div>
                    </div>
                    <div className='div-md-6'>
                      <p> Maximum Fee <span>*</span></p>
                      <div className='container-full input-box'>
                        <Input type='number' className="input" placeholder="Set Fee" required name="maximumFee" value={fields.maximumFee} onChange={(e) => inputChange(e.target.name, e.target.value)} />
                        <Text value='&#8358;' />
                      </div>
                      <span> {fieldErrors.maximumFee} </span>
                    </div>
                  </div>
                </div> : null
            }
            {
              (fields.feeType === "3") ?
                <div className={`container-full form__fee-options__form`}>
                  <div className={`container-full form__fee-options__form__item`}>
                    <p> Percentage <span>*</span></p>
                    <div className='container-full input-box'>
                      <Input type='number' className="input" placeholder="Set Fee" required name="amount" max="100" value={fields.amount} onChange={(e) => inputChange(e.target.name, e.target.value)} />
                      <Text value='%' />
                    </div>
                  </div>
                  <div className={`container-full form__fee-options__form__item`}>
                    <p> Amount <span>*</span></p>
                    <div className='container-full input-box'>
                      <Input type='number' className="input" placeholder="Set Fee" required name="addedFee" value={fields.addedFee} onChange={(e) => inputChange(e.target.name, e.target.value)} />
                      <Text value='&#8358;' />
                    </div>
                  </div>
                  <div className={`container-full form__fee-options__form__item`}>
                    <div className='div-md-6'>
                      <p> Minimum Fee <span>*</span></p>
                      <div className='container-full input-box'>
                        <Input type='number' className="input" placeholder="Set Fee" required name="minimumFee" value={fields.minimumFee} onChange={(e) => inputChange(e.target.name, e.target.value)} />
                        <Text value='&#8358;' />
                      </div>
                    </div>
                    <div className='div-md-6'>
                      <p> Maximum Fee <span>*</span></p>
                      <div className='container-full input-box'>
                        <Input type='number' className="input" placeholder="Set Fee" required name="maximumFee" value={fields.maximumFee} onChange={(e) => inputChange(e.target.name, e.target.value)} />
                        <Text value='&#8358;' />
                      </div>
                      <span> {fieldErrors.maximumFee} </span>
                    </div>
                  </div>
                </div> : null
            }
            {
              (fields.feeType === "0") ?
                <div className={`container-full form__fee-options__bands`}>
                  <div className={`container-full form__fee-options__bands__desc`}>
                    <HeaderText value="Transaction Bands" />
                  </div>
                  <div className={`container-full form__fee-options__bands__group`}>
                    {
                      scaleBands.length > 0 && scaleBands.map((scale: Record<any, any>, key: number) => {
                        return (
                          <div className={`container-full form__fee-options__bands__group__item`} key={key}>
                            <div className={`container-full form__fee-options__bands__group__item__desc`}>
                              <HeaderText value={`Band ${key + 1}`} />
                              <div className={`form__fee-options__bands__group__item__desc__button`} onClick={() => removeBand(scale)}>
                                <div className='form__fee-options__bands__group__item__desc__button__hyphen'></div>
                              </div>
                            </div>
                            <div className={`container-full form__fee-options__bands__group__item__form`}>
                              <div className={`container-full form__fee-options__bands__group__item__form__item`}>
                                <div className={`container-full form__fee-options__bands__group__item__form__item__input-group`}>
                                  <p className='hyphen'> - </p>
                                  <div className={`div-md-6 form__fee-options__bands__group__item__form__item__input-group__item`}>
                                    <p> Range From <span>*</span> </p>
                                    <div className='form__fee-options__bands__group__item__form__item__input-group__item__box'>
                                      <Input type='text' className={`input disabled`} placeholder="Set Fee" required
                                        value={scale.scaleFrom} name="scaleFrom" onChange={(e) => bandInputChange(e.target.name, e.target.value, key)}
                                      />
                                      <Text value='&#8358;' />
                                    </div>
                                  </div>
                                  <div className={`div-md-6 form__fee-options__bands__group__item__form__item__input-group__item`}>
                                    <p> Range To <span>*</span> </p>
                                    <div className='form__fee-options__bands__group__item__form__item__input-group__item__box'>
                                      <Input type='text' className="input" placeholder="Set Fee" required
                                        value={scale.scaleTo} name="scaleTo" onChange={(e) => bandInputChange(e.target.name, e.target.value, key)}
                                      />
                                      <Text value='&#8358;' />
                                    </div>
                                    <span> {scaleBandsError[key] && scaleBandsError[key].scaleTo} </span>
                                  </div>
                                </div>
                              </div>
                              <div className={`container-full form__fee-options__bands__group__item__form__item`}>
                                <div className={`container-full form__fee-options__bands__group__item__form__item__input-group`}>
                                  <div className={`div-md-6 form__fee-options__bands__group__item__form__item__input-group__item`}>
                                    <p> Scale Type <span>*</span> </p>
                                    <div className='input'>
                                      <select required
                                        name="scaleType"
                                        value={scale.scaleType}
                                        onChange={(e) => bandInputChange(e.target.name, e.target.value, key)}
                                      >
                                        <option value="" hidden> Select Scale Type </option>
                                        <option value="0"> Fixed</option>
                                        <option value="1"> Percentage </option>
                                        <option value="2"> Hybrid </option>
                                      </select>
                                      <Image image={caret} />
                                    </div>
                                  </div>
                                  {
                                    (scale.scaleType === "0") ?
                                      <div className={`div-md-6 form__fee-options__bands__group__item__form__item__input-group__item`}>
                                        <p> Scale Value <span>*</span> </p>
                                        <div className='form__fee-options__bands__group__item__form__item__input-group__item__box'>
                                          <Input type='text' className="input" placeholder="Set Fee" required
                                            value={scale.scaleValue} name="scaleValue" onChange={(e) => bandInputChange(e.target.name, e.target.value, key)}
                                          />
                                          <Text value='&#8358;' />
                                        </div>
                                      </div> : null
                                  }
                                  {
                                    (scale.scaleType === "1") ?
                                      <div className={`div-md-6 form__fee-options__bands__group__item__form__item__input-group__item`}>
                                        <p> Scale Value <span>*</span> </p>
                                        <div className='form__fee-options__bands__group__item__form__item__input-group__item__box --percentage'>
                                          <Input type='number' className="input" placeholder="Set Fee" max="100" required
                                            value={scale.scaleValue} name="scaleValue" onChange={(e) => bandInputChange(e.target.name, e.target.value, key)}
                                          />
                                          <Text value='%' />
                                        </div>
                                      </div> : null
                                  }
                                  {
                                    (scale.scaleType === "2") ?
                                      <div className={`div-md-6 form__fee-options__bands__group__item__form__item__input-group__item`}>
                                        <div className='container-full'>
                                          <p> Scale Value <span>*</span> </p>
                                          <div className='container-full form__fee-options__bands__group__item__form__item__input-group__item__box --percentage'>
                                            <Input type='number' className="input" placeholder="Set Fee" max="100" required
                                              value={scale.scaleValue} name="scaleValue" onChange={(e) => bandInputChange(e.target.name, e.target.value, key)}
                                            />
                                            <Text value='%' />
                                          </div>
                                        </div>
                                        <div className='container-full --fixed-fee'>
                                          <p> Added Fixed Fee</p>
                                          <div className='form__fee-options__bands__group__item__form__item__input-group__item__box'>
                                            <Input type='text' className="input" placeholder="Set Fee" required
                                              value={scale.addedFixedFee} name="addedFixedFee" onChange={(e) => bandInputChange(e.target.name, e.target.value, key)}
                                            />
                                            <Text value='&#8358;' />
                                          </div>
                                        </div>

                                      </div> : null
                                  }
                                </div>
                              </div>
                              {
                                scale.scaleType !== "0" && scale.scaleType !== "" ?
                                  <div className={`container-full form__fee-options__bands__group__item__form__item`}>
                                    <div className={`container-full form__fee-options__bands__group__item__form__item__input-group`}>
                                      <div className={`div-md-6 form__fee-options__bands__group__item__form__item__input-group__item`}>
                                        <p> Min Fee <span>*</span> </p>
                                        <div className='form__fee-options__bands__group__item__form__item__input-group__item__box'>
                                          <Input type='text' className="input" placeholder="Set Fee" required
                                            value={scale.minFee} name="minFee" onChange={(e) => bandInputChange(e.target.name, e.target.value, key)}
                                          />
                                          <Text value='&#8358;' />
                                        </div>
                                      </div>
                                      <div className={`div-md-6 form__fee-options__bands__group__item__form__item__input-group__item`}>
                                        <p> Max Fee <span>*</span> </p>
                                        <div className='form__fee-options__bands__group__item__form__item__input-group__item__box'>
                                          <Input type='text' className="input" placeholder="Set Fee" required
                                            value={scale.maxFee} name="maxFee" onChange={(e) => bandInputChange(e.target.name, e.target.value, key)}
                                          />
                                          <Text value='&#8358;' />
                                        </div>
                                        <span> {scaleBandsError[key] && scaleBandsError[key].maxFee} </span>
                                      </div>
                                    </div>
                                  </div> : null
                              }
                            </div>
                          </div>
                        )
                      })
                    }

                    <div className={`container-full form__fee-options__bands__action`}>
                      <div className={`form__fee-options__bands__action__item ${
                        (validateAddBandButton()) ? 'disabled' : ''
                      }`} onClick={() => addBand()}>
                        <Image image={addIcon} />
                        <HeaderText value='Add Band' />
                      </div>
                    </div>
                  </div>
                </div> : null
            }
          </div>
          <div className={`container-full center form__action`}>
            <Button type="button" value="Clear" className={`button --cancel`} onClick={() => clear()} />
            <Button type="submit" value="Continue" className={`button --change 
              ${
                validate() === false && validateScaleBands() === false ? '--active-btn' : '--change'
              } 
            `} isLoading={state.submitReducer.fetching} />
          </div>
        </form>
      </div>
    </SetFeesFormStyle>
  );
};

export default SetFeesForm;
