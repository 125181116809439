import styled from "styled-components";

const AddSourceAccountModalStyle = styled.div`
    animation: show-bg 0.5s linear forwards;
    box-sizing:border-box;
    display:flex;
    height:100%;
    left:0;
    position:absolute;
    position:fixed;
    top: 0;
    width:100%;
    z-index:5000;
    @keyframes show-bg{
        0%{
            background:rgba(0, 0, 0, 0)
        }
        100%{
            background:rgba(37, 39, 51, 0.7);
        }
    }
    .modal-container{
        box-sizing:border-box;
        height:100vh;
    }
    .modal-container__box{
        -ms-flex: 0 0 600px;
        -webkit-flex: 0 0 600px;
        flex: 0 0 600px;
        max-width: 600px;
        background: #FAFAFA;
        border-radius:10px;
        box-sizing:border-box;
        padding: 20px;
        padding-bottom: 30px;
    }
    .modal-container__box__header{
        box-sizing: border-box;
        padding-left: 10px;
        h2{
           color: #252733;
           font-family: 'Mulish';
           font-style: normal;
           font-weight: 700;
           font-size: 18px;
           line-height: 30px;
        }
        img{
            cursor: pointer;
        }
    }
    .modal-container__box__content{
        box-sizing: border-box;
        margin-top: 28px;
    }
`
export default AddSourceAccountModalStyle;