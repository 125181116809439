import styled from "styled-components";

const MerchantNavStyle = styled.div`
    width:100%;
    .merchant-nav__item{
        box-sizing: border-box;
        margin-right: 20px;
        padding: 9px 10px;
        p{
            color: #6D6F79;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.01em;
        }
    }
    .active > .merchant-nav__item{
        border-bottom: 2px solid #3751FF;
        padding-bottom: 12px;
        p{
            color: #3751FF;
            font-weight: 500;
        }
    }
   
`
export default MerchantNavStyle;