import React, { useState, useEffect } from 'react';
import DeclinePaymentModalStyle from './DeclinePaymentModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import dayjs from 'dayjs';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import cancelRed from '../../../assets/images/cancel-red.svg';
import { SubmitActionsPOST } from '../../../redux/actions/SubmitAction';
import { useNavigate } from 'react-router-dom';

type DeclinePaymentModalProps = {
  handleClose: Function,
}

const DeclinePaymentModal = ({ handleClose }: DeclinePaymentModalProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  const [inputValue, setInputValue] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if(state.submitReducer.results && submitted){
      navigate('/payments/pending-approval')
    }
   }, [state.submitReducer])

  const onInputChange = (value: string) => {
    setInputValue(value)
  }

  const declinePayment = async () => {
    const payload = {
      "comment": inputValue,
      "isApproved": 0
    }
    setSubmitted(true);
    dispatch(SubmitActionsPOST(`/payment/${window.location.href.split("=")[1]}/approve`, payload));
  }

  return (
    <DeclinePaymentModalStyle className={`container-full`}>
      <div className={`container-full center modal-container`}>
        <div className={`modal-container__box`}>
          <div className={`container-full modal-container__box__header`}>
            <HeaderText value='Decline Payment' />
            <Image image={close} onClick={() => handleClose()} />
          </div>
          <div className={`container-full modal-container__box__content`}>
            <HeaderText value='What is the reason for this rejection?' />
            <Input type="text" className="input"  placeholder='Enter your reason for rejection.' 
              value={inputValue}
              onChange={(event: any) => {onInputChange(event.target.value)}}
            />
          </div>
          <div className={`container-full modal-container__box__action`}>
            <Button value="cancel" className="button --cancel" onClick={() => handleClose()}/>
            <Button className='button --decline' value={
               <>
                <p>Decline Payment</p>
                <Image image={cancelRed} /> 
              </>
            } isLoading={state.submitReducer.fetching} onClick={() => declinePayment()}/> 
          </div>
        </div>
      </div>
    </DeclinePaymentModalStyle>
  );
}

export default DeclinePaymentModal;