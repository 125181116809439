import styled from "styled-components";
import Colors from '../../../helpers/Colors';

const AddSourceAccountUsersStyle = styled.div`
  width: 100%;

  form{
    width: 100%;
  }
  .form__content{
    border-bottom: 1px solid #F0F0F0;
    box-sizing: border-box;
    padding: 0 15px;
    padding-bottom: 34.26px;
  }
  .form__content__desc{
    p{
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .form__content__group{
    margin-top: 15px;
  }
  .form__content__group__item{
    margin-bottom: 22.27px;
    &:last-child{
      margin-bottom: 0;
    }
    .div-md-6{
      margin-bottom: 0;
    }
    .div-sm-12{
      margin-bottom: 0;
    }
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 10.27px;
      &:nth-of-type(2){
        color: rgba(37, 39, 51, 0.7);
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
    }
    .--account-name{
      text-transform: uppercase;
    }
    .input{
      all: unset;
      color: rgba(37, 39, 51, 0.7);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
    .password{
      position:relative;
      img{
          cursor:pointer;
          margin-right:15.52px;
          margin-top: 5px;
          position:absolute;
          right:0;
          top:0;
      }
    } 
  }
  .form__processor{
    box-sizing: border-box;
    padding: 27px 15px 0 15px;
  }
  .form__processor__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .form__processor__group{
    margin-top: 12px;
  }
  .form__processor__group__users__item{
    background: rgba(235, 237, 245, 0.3);
    border-radius: 5px;
    box-sizing: border-box;
    justify-content: normal;
    margin-bottom: 8px;
    padding: 9px 7px 6px 7px;
  }
  .form__processor__group__users__item__number{
    background: #EBEDF5;
    border-radius: 3px;
    box-sizing: border-box;
    height: 15px;
    margin-top: 0.5px;
    padding: 2px 4.5px;
    width: 15px;
    p{
      color: rgba(37, 39, 51, 0.5);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 700;
      font-size: 9px;
      line-height: 11px;
    }
  }
  .form__processor__group__users__item__name{
    margin-left: 7px;
    width: 80%;
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
    }
  }
  .form__processor__group__users__item__close{
    cursor: pointer;
    margin-left: auto;
  }
  .--add-user{
    cursor: pointer;
    justify-content: space-between;
    margin-bottom: 12px;
    position: relative;
    p{
      color: rgba(37, 39, 51, 1);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
  }
  }
  .more-info{
    background: white;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(8, 9, 10, 0.1);
    left: 0;
    max-height: 136px;
    margin-top: 50px;
    overflow: auto;
    padding: 5px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 4000;
    .active{
      background: #F5F6FF;
      background-blend-mode: multiply;
      border-radius: 5px;
    }
  }
  .more-info__item{
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 2px;
    padding: 7px 8px 7px 15px;
    transition: all 0.5s linear;
    button{
      all:unset;
      cursor:pointer;
      width:100%;
    }
    &:hover{
      background: #F5F6FF;
      background-blend-mode: multiply;
      border-radius: 5px;
    }
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
    }
  }
  .--elipses{
    all: unset;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    width: 100%;
  }

  .info{
    color: rgba(37, 39, 51, 0.5);
    font-family: 'Mulish';
    font-feature-settings: 'salt' on;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-top: 8px;
    text-transform: uppercase;
  }
  .error{
    color: #BD0F10;
    font-family: 'Mulish';
    font-feature-settings: 'salt' on;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
  }
  .form__action{
    margin-top: 83px;
    .button{
        border-radius: 8px;
        font-family: 'Mulish';
        font-weight: 500;
        font-size: 16px;
        height: 40px;
        margin-bottom: 0;
    }
    .--cancel{
        background: #F5F5F5;
        color: #252733;
        margin-right: 20px;
        width: 102px;
    }
    .--change{
      background: linear-gradient(0deg, #ADB3DC, #ADB3DC), #3751FF;
      box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
      color: white;
      pointer-events:  none;
      width: 117px;
    }
    .--active-btn{
      background: #3751FF;
      pointer-events: unset;
    }
  }
`;

export default AddSourceAccountUsersStyle;