import styled from 'styled-components';
import bg from '../../../assets/images/bg.jpeg';

const SigninTemplateStyle = styled.div`
  background: radial-gradient(54.51% 54.51% at 50% 50%, rgba(10, 18, 73, 0) 29.17%, rgba(10, 18, 73, 0.5) 100%), url(${bg});
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  height: 100vh;
  // padding-top: 70px;
  width: 100%;

  .signin{
    height: 100%;
  }
  .signin__box{
    -ms-flex: 0 0 408px;
    -webkit-flex: 0 0 408px;
    flex: 0 0 408px;
    max-width: 408px;
  }
  .signin__box__logo{
    img{
      height: 35.4px;
      width: 108px;
    }
  }
  .signin__box__modal{
    margin-top: 60px;
  }
  .signin__box__modal__menu{
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px 8px 0px 0px;
  }
  .signin__box__modal__menu__item{
    box-sizing: border-box;
    height: 40px;
    padding: 10px 0 10px 0;
    -ms-flex: 0 0 50%;
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    p{
      color: white;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 1em;
      line-height: 20px;
      text-align: center;
      width: 100%;
    }
  }
  .active{
    background: rgba(255, 255, 255, 0.3);
    border-radius: 8px 8px 0px 0px;
    p{
      font-weight: 600;
    }
  }
  .signin__box__modal__content{
    background: #FFFFFF;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 30px 49px 30px 49px;
  }
  .signin__box__modal__content__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 700;
      font-size: 1.750em;
      line-height: 35px;
    }
  }
  .signin__box__modal__content__alert{
    margin-top: 29px;
  }
  .signin__box__modal__content__form{
    margin-top: 29px;
  }
  .signin__box__link{
    margin-top: 34px;
    a{
      width: 100%;
    }
    p{
      color: rgba(255,255,255,0.8);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      width: 100%;
    }
  }
`;

export default SigninTemplateStyle;
