import styled from "styled-components";

const SetFeesModalStyle = styled.div`
    animation: show-bg 0.5s linear forwards;
    box-sizing:border-box;
    display:flex;
    height:100%;
    left:0;
    position:absolute;
    position:fixed;
    top: 0;
    width:100%;
    z-index:5000;
    @keyframes show-bg{
        0%{
            background:rgba(0, 0, 0, 0)
        }
        100%{
            background:rgba(37, 39, 51, 0.7);
        }
    }
    .modal-container{
        box-sizing:border-box;
        height:100vh;
    }
    .modal-container__box{
        -ms-flex: 0 0 645px;
        -webkit-flex: 0 0 645px;
        flex: 0 0 645px;
        max-width: 645px;
        background: #FAFAFA;
        border-radius:10px;
        box-sizing:border-box;
        max-height: 700px;
        overflow: auto;
        padding: 20px;
        padding-bottom: 30px;
    }
    .modal-container__box__header{
        box-sizing: border-box;
        padding-left: 10px;
        h2{
           color: #252733;
           font-family: 'Mulish';
           font-style: normal;
           font-weight: 700;
           font-size: 18px;
           line-height: 30px;
        }
        img{
            cursor: pointer;
        }
    }
    .modal-container__box__desc{
        box-sizing: border-box;
        margin-top: 22px;
        padding: 0 10px;
        p{
            color: #9FA2B4;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
        }
    }
    .modal-container__box__nav{
        box-sizing: border-box;
        justify-content: normal;
        margin-top: 22px;
        padding: 0 10px;
    }
    .modal-container__box__nav__item{
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        padding: 7px 0;
        p{
            color: #B6B6BB;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.01em;
        }
    }
    .--nhis{
        padding-left: 15.83px;
        padding-right: 15.83px;
    }
    .--nhf{
        padding-left: 14.83px;
        padding-right: 14.83px;
    }
    .--pensions{
        padding-left: 9.83px;
        padding-right: 9.83px;
    }
    .--paye{
        padding-left: 11.33px;
        padding-right: 11.33px;
    }
    .--salary{
        padding-left: 12.83px;
        padding-right: 12.83px;
    }
    .--deductions{
        padding-left: 6.5px;
        padding-right: 6.5px;
    }
    .--summary{
        padding-left: 4.83px;
        padding-right: 4.83px;
    }
    .modal-container__box__nav__item__circle{
        background: #FFFFFF;
        border: 1px solid #B5BABD;
        border-radius: 50%;
        display: block;
        height: 7px;
        margin-right: 6px;
        margin-top: 4.5px;
        width: 7px;
        img{
            height: 3.15px;
            margin-left: 2px;
            margin-top: 2.55px;
            width: 3.81px;
        }
    }
    .active-nav{
        border-bottom: 2px solid #3751FF;
        padding-bottom: 8px;
        padding-top: 6px;
        p{
            color: #3751FF;
            font-weight: 600;
        }
        .modal-container__box__nav__item__circle{
            border: 1px solid #3751FF;
        }
    }
    .completed{
        border-bottom: 2px solid #3751FF;
        padding-bottom: 8px;
        padding-top: 6px;
        p{
            color: #707075;
        }
        .modal-container__box__nav__item__circle{
            background: #B6B6BB;
        }
    }
    
    
`
export default SetFeesModalStyle;