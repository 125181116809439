import React, { useState, useEffect } from 'react';
import ChangePasswordModalStyle from './ChangePasswordModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import Input from '../../atoms/Input/Input';
import eyesClose from '../../../assets/images/eyes-pwd-close.svg';
import eyes from '../../../assets/images/eyes-pwd.svg';
import check from '../../../assets/images/checkmark-green.svg';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { SubmitActionsPUT } from '../../../redux/actions/SubmitAction';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { alertActions } from '../../../redux/actions/AlertActions';

type ChangePasswordModalProps = {
  handleClose: Function
}

const ChangePasswordModal = ({ handleClose }: ChangePasswordModalProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  const navigate = useNavigate()

  const [fields, setFields] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  })
  const [fieldErrors, setFieldErrors] = useState({
    oldPassword: 'Password do not check',
    newPassword: 'Password do not check',
    confirmPassword: 'Password do not check'
  })
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (state.submitReducer.results && submitted) {
      handleClose();
      if (state.authReducer.results.force_password_change) {
        logout();
      }
    }
  }, [state.submitReducer])

  const logout = async () => {
    await dispatch(alertActions.clear());
    await Cookies.remove("userToken");
    navigate('/');
  }

  const inputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues[name] = value;
    await setFields(fieldsValues);
    if (name === "newPassword" || name === "confirmPassword") {
      onInputValidate(value, name);
    }
    else {
      checkIfInputEmpty(value, name);
    }
  };

  const onInputValidate = async (value: string, name: string) => {
    if (/[A-Z]/.test(value) && /[0-9]/.test(value) && /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)) {
      const fieldErrorValues: any = Object.assign({}, fieldErrors);
      fieldErrorValues[name] = false;
      await setFieldErrors(fieldErrorValues);
      return true
    }
    else {
      const fieldErrorValues: any = Object.assign({}, fieldErrors);
      fieldErrorValues[name] = 'Password do not check';
      await setFieldErrors(fieldErrorValues);
      return false
    }
  }

  const checkIfInputEmpty = async (value: string, name: string) => {
    if (value.length > 0) {
      const fieldErrorValues: any = Object.assign({}, fieldErrors);
      fieldErrorValues[name] = false;
      await setFieldErrors(fieldErrorValues);
      return true
    }
    else {
      const fieldErrorValues: any = Object.assign({}, fieldErrors);
      fieldErrorValues[name] = 'Password do not check';
      await setFieldErrors(fieldErrorValues);
      return false
    }
  }

  const validate = () => {
    const person: any = fieldErrors;
    if (Object.keys(fieldErrors).filter((k) => person[k]).length) {
      return true;
    }
    return false;
  }

  const toggleState = () => {
    return (evt: any) => {
      if (evt.target.parentElement.firstChild.type === 'password') {
        evt.target.src = eyes;
        return (evt.target.parentElement.firstChild.type = 'text');
      } else {
        evt.target.src = eyesClose;
        return (evt.target.parentElement.firstChild.type = 'password');
      }
    };
  }

  const changePassword = async () => {
    let fieldValues = fields
    setSubmitted(true);
    await dispatch(SubmitActionsPUT('/users/auth/change', {
      "newValue": fieldValues.newPassword,
      "oldValue": fieldValues.oldPassword
    }))
  }

  return (
    <ChangePasswordModalStyle className={`container-full`}>
      <div className={`container-full center modal-container`}>
        <div className={`modal-container__box`}>
          <div className={`container-full modal-container__box__header`}>
            <HeaderText value='Change Password' />
            {
              (!state.authReducer.results.force_password_change) ? <Image image={close} onClick={() => handleClose()} /> : null
            }
          </div>
          <div className={`container-full modal-container__box__content`}>
            <div className={`container-full modal-container__box__content__desc`}>
              <Text value={`Create a new password for ${state.authReducer.results.user_email}`}/>
            </div>
            <div className={`container-full modal-container__box__content__desc__form`}>
              <div className={`container-full modal-container__box__content__desc__form__item`}>
                <Text value='Old Password' className='label' />
                <div className='container-full password'>
                  <Input type='password' className='input' placeholder='Old Password' required={true} name="oldPassword" onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                    onPaste={(event: React.FormEvent) => { event.preventDefault() }}
                    onCopy={(event: React.FormEvent) => { event.preventDefault() }}
                    onCut={(event: React.FormEvent) => { event.preventDefault() }}
                  />
                  <img
                    src={eyesClose}
                    onClick={toggleState()}
                    className={`eyes`}
                  />
                </div>
              </div>
              <div className={`container-full modal-container__box__content__desc__form__item`}>
                <Text value='New Password' className='label' />
                <div className='container-full password'>
                  <Input type='password' className='input' placeholder='Create Password' required={true} name="newPassword" onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                    onPaste={(event: React.FormEvent) => { event.preventDefault() }}
                    onCopy={(event: React.FormEvent) => { event.preventDefault() }}
                    onCut={(event: React.FormEvent) => { event.preventDefault() }}
                  />
                  <img
                    src={eyesClose}
                    onClick={toggleState()}
                    className={`eyes`}
                  />
                  <div className={`validation`}>
                    <div className={`container-full validation__item  ${/[A-Z]/.test(fields['newPassword']) ? '--active' : ''
                      }`}>
                      <Image image={check} alt="check" />
                      <Text value='Capital letter' />
                    </div>
                    <div className={`container-full validation__item ${/[0-9]/.test(fields['newPassword']) ? '--active' : ''
                      }`}>
                      <Image image={check} alt="check" />
                      <Text value='Number' />
                    </div>
                    <div className={`container-full validation__item ${fields['newPassword'].length >= 8 ? '--active' : ''
                      }`}>
                      <Image image={check} alt="check" />
                      <Text value='Minimum of 8 Characters' />
                    </div>
                    <div className={`container-full validation__item ${/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(fields['newPassword']) ? '--active' : ''
                      }`}>
                      <Image image={check} alt="check" />
                      <Text value='Special character' />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`container-full modal-container__box__content__desc__form__item`}>
                <Text value='Confirm Password' className='label' />
                <div className='container-full password'>
                  <Input type='password' className='input' placeholder='Confirm Password' required={true} name="confirmPassword" onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                    onPaste={(event: React.FormEvent) => { event.preventDefault() }}
                    onCopy={(event: React.FormEvent) => { event.preventDefault() }}
                    onCut={(event: React.FormEvent) => { event.preventDefault() }}
                  />
                  <img
                    src={eyesClose}
                    onClick={toggleState()}
                    className={`eyes`}
                  />
                  <div className={`validation`}>
                    <div className={`container-full validation__item  ${/[A-Z]/.test(fields['confirmPassword']) ? '--active' : ''
                      }`}>
                      <Image image={check} alt="check" />
                      <Text value='Capital letter' />
                    </div>
                    <div className={`container-full validation__item ${/[0-9]/.test(fields['confirmPassword']) ? '--active' : ''
                      }`}>
                      <Image image={check} alt="check" />
                      <Text value='Number' />
                    </div>
                    <div className={`container-full validation__item ${fields['confirmPassword'].length >= 8 ? '--active' : ''
                      }`}>
                      <Image image={check} alt="check" />
                      <Text value='Minimum of 8 Characters' />
                    </div>
                    <div className={`container-full validation__item ${/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(fields['confirmPassword']) ? '--active' : ''
                      }`}>
                      <Image image={check} alt="check" />
                      <Text value='Special character' />
                    </div>
                    <div className={`container-full validation__item ${fields['newPassword'] === fields['confirmPassword'] ? '--active' : ''
                      }`}>
                      <Image image={check} alt="check" />
                      <Text value='Both password match' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`container-full center modal-container__box__action`}>
            {
              (!state.authReducer.results.force_password_change) ? <Button value="Cancel" className={`button --cancel`} onClick={() => handleClose()} /> : null
            }
            <Button value="Change Password" className={`button --change ${validate() === false && fields['newPassword'] === fields['confirmPassword'] ? '--active-btn' : ''
              }`}
              isLoading={state.submitReducer.fetching}
              onClick={() => changePassword()}
            />
          </div>
        </div>
      </div>
    </ChangePasswordModalStyle>
  );
}

export default ChangePasswordModal;