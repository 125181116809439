import styled from "styled-components";

const FileProcessingModalStyle = styled.div`
    animation: show-bg 0.5s linear forwards;
    box-sizing:border-box;
    display:flex;
    height:100%;
    left:0;
    position:absolute;
    position:fixed;
    top: 0;
    width:100%;
    z-index:5000;
    @keyframes show-bg{
        0%{
            background:rgba(0, 0, 0, 0)
        }
        100%{
            background:rgba(37, 39, 51, 0.7);
        }
    }
    .modal-container{
        box-sizing:border-box;
        height:100vh;
    }
    .modal-container__box{
        -ms-flex: 0 0 400px;
        -webkit-flex: 0 0 400px;
        flex: 0 0 400px;
        max-width: 400px;
        background:#FFFFFF;
        border-radius:10px;
        box-sizing:border-box;
        padding: 20px 20px 30px 20px;
    }
    .modal-container__box__header{
        box-sizing: border-box;
        padding: 0 10px 0 10px;
        h2{
           color: #252733;
           font-family: 'Mulish';
           font-style: normal;
           font-weight: 700;
           font-size: 1.375em;
           line-height: 30px;
        }
    }
    .modal-container__box__loading{
        margin-top: 5px;
        img{
            height: auto;
            width: 120px;
        }
    }
    .modal-container__box__text{
        margin-top: 5px;
        p{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 1.375em;
            line-height: 30px;
        }
    }
    


    
`
export default FileProcessingModalStyle;