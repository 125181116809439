import React, {useState, useEffect} from 'react';
import ActivateMerchantModalStyle from './ActivateMerchantModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import Input from '../../atoms/Input/Input';
import eyesClose from '../../../assets/images/eyes-pwd-close.svg';
import eyes from '../../../assets/images/eyes-pwd.svg';
import check from '../../../assets/images/checkmark-green.svg';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {SubmitActionsPUT} from '../../../redux/actions/SubmitAction';

type ActivateMerchantModalProps = {
    handleClose: Function,
    reloadPage: Function,
    merchantId: string,
    merchantName: string
}

const ActivateMerchantModal = ({handleClose, reloadPage, merchantId, merchantName}: ActivateMerchantModalProps) => {
    const dispatch: TypedDispatch = useDispatch();
    const state = useSelector( (state:RootStore) => state);
    const [submitSuccessfull, setSubmitSuccessfull] = useState(false)

    useEffect(() => {
      if(state.submitReducer.results !== null && submitSuccessfull){
        handleClose();
        reloadPage();
      }
    }, [state.submitReducer, submitSuccessfull])

    const deactivateMerchant = async () => {
      await dispatch(SubmitActionsPUT(`/merchant/${merchantId}`, {
        "status": "1"
      }))
      setSubmitSuccessfull(true)
    }

    return (
      <ActivateMerchantModalStyle className={`container-full`}>
        <div className={`container-full center modal-container`}>
          <div className={`modal-container__box`}>
            <div className={`container-full modal-container__box__header`}>
              <HeaderText value='Deactivate Merchant' />
              <Image image={close} onClick={() => handleClose()}/>
            </div>
            <div className={`container-full modal-container__box__content`}>
              <p> You are about to deactivate <span> {merchantName}</span>.<br/> They will no longer be able to perform transactions on the platform </p>
              <p> Would you like to continue? </p>
            </div>
            <div className={`container-full center modal-container__box__action`}>
                <Button value="Cancel" className={`button --cancel`} onClick={() => handleClose()} />
                <Button value="Activate" className={`button --activate`}
                  isLoading={state.submitReducer.fetching}
                  onClick={() => deactivateMerchant()}
                  type="submit"
                />
            </div>
          </div>  
        </div>
      </ActivateMerchantModalStyle>
    );
}

export default ActivateMerchantModal;