import React, { useState, useEffect } from 'react';
import GlobalParametersTemplateStyle from './GlobalParametersTemplateStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Input from '../../atoms/Input/Input';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {DashboardActions} from '../../../redux/actions/DashboardAction';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading'
import GlobalParametersNavBar from '../../organisms/GlobalParametersNavBar/GlobalParametersNavBar';
import GlobalParametersTable from '../../organisms/GlobalParametersTable/GlobalParametersTable';

const GlobalParametersTemplate = () => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector( (state:RootStore) => state);

  useEffect(() => {
    generatePageContent();
    console.log(state);
  }, [size, page, reloadPage])

  const generatePageContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/global/parameters?size=${size}&page=${page}&name=${search}`));
    setFetchLoading(false);
  }

  const renderFetchLoading = () => {
    if(fetchLoading){
      return(
        <FetchLoading />
      )
    }
  }

  const changePage = (value: number) => {
    setPage(value);
  }

  const changeSize = (value: number) => {
    setSize(value);
  }

  const refreshTable = () => {
    if(reloadPage){
      return setReloadPage(false);
    }
    setReloadPage(true);
  }


  return (
    <>
      {renderFetchLoading()}
      <GlobalParametersTemplateStyle>
        <div className={`container-full global`}>
            <div className={`container-full global__desc`}>
              <HeaderText value="Global Parameters" />
            </div>
            {/* <GlobalParametersNavBar /> */}
            <div className={`container-full global__content`}>
              {/* <div className={`container-full global__content__desc`}>
                <HeaderText value='Global Parameters' />
              </div> */}
              <div className={`container-full global__content__action`}>
                <Input className={`input`} type="text" value={search} placeholder="Search record" 
                  onChange={(event) => setSearch(event.target.value)}  
                  onKeyPress={event => {
                    if (event.key === 'Enter' || event.which === 13 || event.keyCode === 13) {
                        generatePageContent();
                    }
                  }}
                />
              </div>
             
            </div>
            <div className='container-full global__table'>
              {
                (state.dashboardReducer.results !== null) ?
                <GlobalParametersTable data={state.dashboardReducer.results} pagination={size} setPagination={changeSize} changePage={changePage} page={page} reloadPage={refreshTable}/> : null
              }
            </div>
        </div>
      </GlobalParametersTemplateStyle>
    </>
  );
};

export default GlobalParametersTemplate;
