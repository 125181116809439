import React, { useState } from 'react';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import UsersTableStyle from './UsersTableStyle';
import nike from '../../../assets/images/nike.svg';
import elipses from '../../../assets/images/elipses.svg';
import userImage from '../../../assets/images/user-male.svg';
import Pagination from '../Pagination/Pagination';
import dayjs from 'dayjs';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {exportData} from '../../../redux/actions/ExportAction';
import EditUserModal from '../EditUserModal/EditUserModal';
import { useNavigate } from "react-router-dom";

type UserTableProps = {
  data: any,
  pagination: number,
  setPagination: Function,
  changePage: Function,
  page: number,
  reloadPage: Function
}

const UsersTable = ({data, pagination, setPagination, changePage, page, reloadPage}: UserTableProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state:any = useSelector( (state:RootStore) => state);
  let timeOutId:any = null;
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [toggleEditUserModal, setToggleEditUserModal] = useState(false);
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  const onClickHandler = (value: boolean, id: string) => {
    if(selectedMenu !== false){
      setSelectedMenu(false);
      return;
    }
    setSelectedMenu(value);
    setSelectedId(id)
  }

  const onBlurHandler = () => {
      timeOutId = setTimeout(() => {
        setSelectedMenu(false);
      });
  }

  const onFocusHandler = () =>{
      clearTimeout(timeOutId);
  }

  const handleAllCheck = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      await dispatch(exportData(state.dashboardReducer.results.data.content))
      console.log(state.exportReducer);
    }
    else{
      await dispatch(exportData([]));
      console.log(state.exportReducer);
    }
  };

  const handleCheckItem = (item: Record<any, any>) => {
    if(state.exportReducer.results.data.includes(item)){
      const newArr = state.exportReducer.results.data.filter( (record: Record<any,any>) => record.id !== item.id)
      console.log(newArr);
      return dispatch(exportData(newArr));
    }
    const update = [...state.exportReducer.results.data, item]
    console.log(update);
    return dispatch(exportData(update));
  };

  const showEditUserModal = () => {
    let userData = data.data.content.filter((data: Record<any, any>) => data.id === selectedId);
    let value = {
      ...userData[0]
    }
    console.log(value)
    setUserData(value);
    setToggleEditUserModal(true);
  }

  const hideEditUserModal = () => {
    setToggleEditUserModal(false);
  }

  const renderEditUserModal = () => {
    if(toggleEditUserModal){
      return(
        <EditUserModal handleClose={hideEditUserModal} reloadPage={reloadPage} userData={userData} />
      )
    }
  }

  const goToProfile = (id: string) => {
    navigate(`/settings/users/profile?id=${id}`);
  }


  return (
    <>
      {renderEditUserModal()}
      <UsersTableStyle>
        <div className={`container-full`}>
          <table>
            <thead>
              <tr className={`table-header`}>
                <th>
                  <input type="checkbox" onChange={handleAllCheck}/>
                  <Image image={nike} />
                </th>
                <th>
                  <Text value="User" className={`table-title`} />
                </th>
                <th>
                  <Text value="Phone Number" className={`table-title`} />
                </th>
                <th>
                  <Text value="Date Created" className={`table-title`} />
                </th>
                <th>
                  <Text value="Last Active" className={`table-title`} />
                </th>
                <th>
                  <Text value="More" className={`table-title`} />
                </th>
              </tr>
            </thead>
            <tbody className={`table-body`}>
              {
                (data.data.content !== undefined && data.data.content.length > 0) ?
                data.data.content.map((user: Record<any, any>, key: string) => {
                  return (
                    <tr className={`table-row`} key={key}>
                      <td>
                        <input
                          type="checkbox"
                          checked={state.exportReducer.results.data.includes(
                            user
                          )}
                          onChange={() => handleCheckItem(user)}
                        />
                        <Image image={nike} />
                      </td >
                      <td className={`name`}> 
                        {/* <Image image={userImage} /> */}
                        <div>
                          <Text value={`${user.firstName} ${user.lastName}`} className='label --name' onClick={() => goToProfile(user.id)}/>
                          <Text value={user.email} className='sublabel' />
                        </div>
                      </td>
                      <td> 
                        <Text value={user.phoneNumber} className='label' />
                      </td>
                      <td> 
                        <Text value={dayjs(user.createdDate).format('MM/DD/YYYY')} className='label' />
                        <Text value={dayjs(user.createdDate).format('h:mm A')} className='date' />
                      </td>
                      <td> 
                        <Text value={dayjs(user.lastLoginDate).format('MM/DD/YYYY')} className='label' />
                        <Text value={dayjs(user.lastLoginDate).format('h:mm A')} className='date' />
                      </td>
                      <td> 
                        <div className={`container-full`} onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
                          <button className={`button --elipses`} onClick={() => onClickHandler(true, user.id)} aria-haspopup="true" aria-expanded={selectedMenu}>
                            <Image image={elipses} />
                          </button>
                          {
                              selectedMenu !== false && selectedId === user.id && (
                                <div className={`container-full more-info`}>
                                  <div className={`container-full more-info__item`}>
                                    <button onClick={() => goToProfile(user.id)}>
                                      <Text value='View Profile' />
                                    </button>
                                  </div>
                                  <div className={`container-full more-info__item ${
                                    (state.authReducer.results.roles.includes('Authorizer') && state.authReducer.results.roles.length === 1) 
                                    || (state.authReducer.results.roles.includes('Audit') && state.authReducer.results.roles.length === 1) ? 'disabled': ''
                                  }`}>
                                    <button onClick={() => showEditUserModal()}>
                                      <Text value='Edit' />
                                    </button>
                                  </div>
                                </div>
                              )
                          }
                        </div>
                      </td>
                    </tr>
                  )
                }) : null
              }
            </tbody>
          </table>
        </div>
        <div className={`container-full pagination`}>
          <Pagination pagination={pagination} changePage={changePage} setPagination={setPagination} page={page}/> 
        </div>
      </UsersTableStyle>
    </>
  );
};

export default UsersTable;
