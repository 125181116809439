import styled from "styled-components";
import calendar from '../../../assets/images/calendar.svg';

const CreateIssuerModalStyle = styled.div`
    animation: show-bg 0.5s linear forwards;
    box-sizing:border-box;
    display:flex;
    height:100%;
    left:0;
    position:absolute;
    position:fixed;
    top: 0;
    width:100%;
    z-index:5000;
    @keyframes show-bg{
        0%{
            background:rgba(0, 0, 0, 0)
        }
        100%{
            background:rgba(37, 39, 51, 0.7);
        }
    }
    .modal-container{
        box-sizing:border-box;
        height:100vh;
    }
    .modal-container__box{
        -ms-flex: 0 0 626px;
        -webkit-flex: 0 0 626px;
        flex: 0 0 626px;
        max-width: 626px;
        background: #FAFAFA;
        border-radius:10px;
        box-sizing:border-box;
        max-height: 729px;
        overflow: auto;
        padding: 20px;
        position: relative;
        form{
            width: 100%;
        }
    }
    .modal-container__box__header{
        box-sizing: border-box;
        padding-left: 10px;
        h2{
           color: #252733;
           font-family: 'Mulish';
           font-style: normal;
           font-weight: 700;
           font-size: 1.375em;
           line-height: 30px;
        }
        img{
            cursor: pointer;
        }
    }
    .modal-container__box__content{
        box-sizing: border-box;
        margin-top: 40px;
        padding: 0 15px 0 15px;
    }
    .modal-container__box__content__row{
        margin-bottom: 20px;
        &:last-child{
          margin-bottom: 0;
        }
      }
      .modal-container__box__content__row__item{
        margin-bottom: 0;
        h2{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 8px;
        }
        button{
            all: unset;
            cursor: pointer;
            width: 100%;
        }
        .--date{
            background: linear-gradient(0deg, rgba(55, 81, 255, 0.025), rgba(55, 81, 255, 0.025)), url(${calendar}) no-repeat 0px;
            background-position: 98%;
            background-size: 24px;
        }
        .input{
          background: rgba(55, 81, 255, 0.025);
          border: none;
          border-radius: 6px;
          color: #252733;
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          height: 40px;
          margin-bottom: 12px;
          padding-left: 15px;
          position: relative;
          width: 100%;
          &::placeholder{
            color: rgba(37, 39, 51, 0.3);
          }
        }
        select{
          appearance: none;
          background: transparent;
          color: #252733;
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          margin-top: 10px;
          width: 100%;
        }
        select:required:invalid {
          color: rgba(37, 39, 51, 0.3);
        }
        img{
            margin-right: 8px;
            margin-top: 11px;
            position: absolute;
            right: 0;
            top: 0;
        }
        p{
          color: rgba(37, 39, 51, 0.3);
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
        }
    }

    .modal-container__box__content__invoice{
        margin-top: 0;
      }
      .modal-container__box__content__invoice__row__item{
        margin-bottom: 0;
        h2{
          color: #252733;
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 8px;
        }
      }
      .modal-container__box__content__invoice__row__item__file-select{
        background: #F7F9FE;
        border: 1.5px dashed rgba(55, 81, 255, 0.5);
        border-radius: 8px;
        height: 70px;
        p{
          color: rgba(37, 39, 51, 0.3);
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
        }
        .input{
          appearance: none;
          height: 100%;
          left: 0;
          opacity: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
      .modal-container__box__content__invoice__row__item__assistive-text{
        margin-top: 8px;
        p{
          color: #3751FF;
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
        }
      }

    .modal-container__box__action{
        justify-content: flex-end;
        margin-top: 78px;
        .button{
            border-radius: 8px;
            font-family: 'Mulish';
            font-size: 16px;
            height: 50px;
            margin-bottom: 0;
        }
        .--cancel{
            background: #F5F5F5;
            color: #252733;
            font-weight: 600;
            margin-right: 20px;
            width: 102px;
        }
        .--change{
            background: #3751FF;
            box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
            color: white;
            font-weight: 700;
            width: 88px;
        }
    }
`
export default CreateIssuerModalStyle;