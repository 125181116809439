import React, { useState } from 'react';
import ManageRolesTemplateStyle from './ManageRolesTemplateStyle';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import plus from '../../../assets/images/plus.svg';
import RolesAndPermissions from '../../organisms/RolesAndPermission/RolesAndPermissions';
import CreateRoleModal from '../../organisms/CreateRoleModal/CreateRoleModal';
import SuccessModal from '../../organisms/SuccessModal/SuccessModal';
import HeaderText from '../../atoms/HeaderText/Text';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../config/ConfigStore';
import SettingsNavBar from '../../organisms/SettingsNavBar/SettingsNavBar';

const ManageRolesTemplate = () => {
  const [toggleCreateRoleModal, setToggleCreateRoleModal] = useState(false);
  const [toggleSuccessModal, setToggleSuccessModal] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const state = useSelector((state: RootStore) => state);

  const showCreateRoleModal = () => {
    setToggleCreateRoleModal(true)
  }

  const hideCreateRoleModal = () => {
    setToggleCreateRoleModal(false)
  }

  const renderCreateRoleModal = () => {
    if (toggleCreateRoleModal) {
      return (
        <CreateRoleModal handleClose={hideCreateRoleModal} showSuccessModal={showSuccessModal} />
      )
    }
  }

  const showSuccessModal = () => {
    setToggleSuccessModal(true)
  }

  const hideSuccessModal = () => {
    setToggleSuccessModal(false);
    if (reloadPage) {
      return setReloadPage(false);
    }
    setReloadPage(true);
  }

  const renderSuccessModal = () => {
    if (toggleSuccessModal) {
      return (
        <SuccessModal handleClose={hideSuccessModal} message="The role has been created and sent to the Admin for Approval" />
      )
    }
  }

  return (
    <>
      {renderCreateRoleModal()}
      {renderSuccessModal()}
      <ManageRolesTemplateStyle>
        <div className={`container-full settings`}>
          <div className={`container-full settings__desc`}>
            <HeaderText value="Settings" />
          </div>
          <SettingsNavBar />
          <div className={`container-full settings__top`}>
            <Text value="Manage Roles" className={`title`} />
            <button className={`button ${(state.authReducer.results.roles.includes('Authorizer') && state.authReducer.results.roles.length === 1) 
            || (state.authReducer.results.roles.includes('Audit') && state.authReducer.results.roles.length === 1) ? '--disabled' : ''
              }`} onClick={() => showCreateRoleModal()}>
              <Image image={plus} alt="plus" />
              Add New
            </button>
          </div>
          <div className={`container-full settings__body`}>
            <div className={`container-full settings__body__box`}>
              <RolesAndPermissions reloadPage={reloadPage} />
            </div>
          </div>
        </div>
      </ManageRolesTemplateStyle>
    </>
  );
};

export default ManageRolesTemplate;
