import React from 'react';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import RecentPaymentsStyle from './RecentPaymentsStyle';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../config/ConfigStore';
import Button from '../../atoms/Button/Button';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import commaNumber from 'comma-number';

const RecentPayments = () => {
  const state = useSelector((state: RootStore) => state);
  const navigate = useNavigate();

  return (
    <RecentPaymentsStyle>
      <div className={`container-full recent-payments`}>
        <div className={`container-full recent-payments__menu`}>
          <div className={`div-md-6 recent-payments__menu__item`}>
            <Button className='button' value="View Payments" onClick={() => navigate('/payments/ongoing-payments')} />
          </div>
          <div className={`div-md-6 recent-payments__menu__item`}>
            <Button className='button' value="Initiate Payment" onClick={() => navigate('/payments/ongoing-payments?initiate=true')}/>
          </div>
        </div>
        <div className={`container-full recent-payments__table`}>
          <HeaderText value="Recent Payments" />
          <div className={`container-full recent-payments__table__list`}>
            {
              state.dashboardReducer.results && state.dashboardReducer.results.data.paymentDetails &&
              state.dashboardReducer.results.data.paymentDetails.recentPayments.length > 0 && state.dashboardReducer.results.data.paymentDetails.recentPayments.map((payment: Record<any, any>, key: number) => {
                return (
                  <div className={`container-full recent-payments__table__list__row`}>
                    <div className={`div-md-7 recent-payments__table__list__row__item`}>
                      <div className={`recent-payments__table__list__row__item__dot`}></div>
                      <div>
                        <Text value={payment.paymentRef} className='value' />
                        <p className='date'> {dayjs(payment.paymentDate).format("MMM D YYYY")} <span> {dayjs(payment.paymentDate).format("hh:mm A")} </span></p>
                      </div>
                    </div>
                    <div className={`div-md-5 recent-payments__table__list__row__item`}>
                      <HeaderText value={`₦${commaNumber(payment.value)}`} />
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </RecentPaymentsStyle>
  );
};

export default RecentPayments;
