import React, { useState, useEffect } from 'react';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import ResetPasswordFormStyle from './ResetPasswordFormStyle';
import Input from '../../atoms/Input/Input';
import eyesClose from '../../../assets/images/eyes-pwd-close.svg';
import eyes from '../../../assets/images/eyes-pwd.svg';
import Button from '../../atoms/Button/Button';
import check from '../../../assets/images/checkmark-green.svg';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { resetPassword } from '../../../redux/actions/AuthAction';

type ResetPasswordFormProps = {
  changeUsername: Function,
  setSubmitted: Function
}

const ResetPasswordForm = ({ changeUsername, setSubmitted }: ResetPasswordFormProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);

  const [fields, setFields] = useState({
    newPassword: '',
    confirmPassword: ''
  })
  const [fieldErrors, setFieldErrors] = useState({
    newPassword: 'Password do not check',
    confirmPassword: 'Password do not check'
  })

  useEffect(() => {
    if (state.alertReducer.type === "alert-success") {
      changeUsername(state.authReducer.results.data.user)
    }
  }, [state.alertReducer])

  const inputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues[name] = value;
    await setFields(fieldsValues);
    onInputValidate(value, name);
  };

  const onInputValidate = async (value: string, name: string) => {
    if (/[A-Z]/.test(value) && /[0-9]/.test(value) && /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)) {
      const fieldErrorValues: any = Object.assign({}, fieldErrors);
      fieldErrorValues[name] = false;
      await setFieldErrors(fieldErrorValues);
      return true
    }
    else {
      const fieldErrorValues: any = Object.assign({}, fieldErrors);
      fieldErrorValues[name] = 'Password do not check';
      await setFieldErrors(fieldErrorValues);
      return false
    }
  }

  const validate = () => {
    const person: any = fieldErrors;
    if (Object.keys(fieldErrors).filter((k) => person[k]).length) {
      return true;
    }
    return false;
  }

  const toggleState = () => {
    return (evt: any) => {
      if (evt.target.parentElement.firstChild.type === 'password') {
        evt.target.src = eyes;
        return (evt.target.parentElement.firstChild.type = 'text');
      } else {
        evt.target.src = eyesClose;
        return (evt.target.parentElement.firstChild.type = 'password');
      }
    };
  }

  const onFormSubmit = () => {
    return async (evt: React.FormEvent) => {
      evt.preventDefault();
      let data = {
        "newValue": fields.confirmPassword,
        "passKey": window.location.href.split("=")[1],
        "username": state.authReducer.results.data.user
      }
      await dispatch(resetPassword(data));
      setSubmitted(true);
    }
  }

  return (
    <ResetPasswordFormStyle>
      <div className={`container-full form ${(state.authReducer.results && state.authReducer.results.data.user) ? null : 'disabled'
        }`}>
        <form onSubmit={onFormSubmit()}>
          <div className={`container-full form__item`}>
            <Text value='New Password' className='label' />
            <div className='container-full password'>
              <Input type='password' className='input' placeholder='Create Password' required={true} name="newPassword" onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                onPaste={(event: React.FormEvent) => { event.preventDefault() }}
                onCopy={(event: React.FormEvent) => { event.preventDefault() }}
                onCut={(event: React.FormEvent) => { event.preventDefault() }}
              />
              <img
                src={eyesClose}
                onClick={toggleState()}
                className={`eyes`}
              />
              <div className={`validation`}>
                <div className={`container-full validation__item  ${/[A-Z]/.test(fields['newPassword']) ? '--active' : ''
                  }`}>
                  <Image image={check} alt="check" />
                  <Text value='Capital letter' />
                </div>
                <div className={`container-full validation__item ${/[0-9]/.test(fields['newPassword']) ? '--active' : ''
                  }`}>
                  <Image image={check} alt="check" />
                  <Text value='Number' />
                </div>
                <div className={`container-full validation__item ${fields['newPassword'].length >= 8 ? '--active' : ''
                  }`}>
                  <Image image={check} alt="check" />
                  <Text value='Minimum of 8 Characters' />
                </div>
                <div className={`container-full validation__item ${/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(fields['newPassword']) ? '--active' : ''
                  }`}>
                  <Image image={check} alt="check" />
                  <Text value='Special character' />
                </div>
              </div>
            </div>
          </div>
          <div className={`container-full form__item`}>
            <Text value='Confirm Password' className='label' />
            <div className='container-full password'>
              <Input type='password' className='input' placeholder='Confirm Password' required={true} name="confirmPassword" onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                onPaste={(event: React.FormEvent) => { event.preventDefault() }}
                onCopy={(event: React.FormEvent) => { event.preventDefault() }}
                onCut={(event: React.FormEvent) => { event.preventDefault() }}
              />
              <img
                src={eyesClose}
                onClick={toggleState()}
                className={`eyes`}
              />
              <div className={`validation`}>
                <div className={`container-full validation__item  ${/[A-Z]/.test(fields['confirmPassword']) ? '--active' : ''
                  }`}>
                  <Image image={check} alt="check" />
                  <Text value='Capital letter' />
                </div>
                <div className={`container-full validation__item ${/[0-9]/.test(fields['confirmPassword']) ? '--active' : ''
                  }`}>
                  <Image image={check} alt="check" />
                  <Text value='Number' />
                </div>
                <div className={`container-full validation__item ${fields['confirmPassword'].length >= 8 ? '--active' : ''
                  }`}>
                  <Image image={check} alt="check" />
                  <Text value='Minimum of 8 Characters' />
                </div>
                <div className={`container-full validation__item ${/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(fields['confirmPassword']) ? '--active' : ''
                  }`}>
                  <Image image={check} alt="check" />
                  <Text value='Special character' />
                </div>
                <div className={`container-full validation__item ${fields['newPassword'] === fields['confirmPassword'] ? '--active' : ''
                  }`}>
                  <Image image={check} alt="check" />
                  <Text value='Both password match' />
                </div>
              </div>
            </div>
          </div>
          <div className='container-full form__action'>
            <Button type='submit' value="Change Password" className={`button ${validate() === false && fields['newPassword'] === fields['confirmPassword'] ? '--active-btn' : ''
              }`}
              isLoading={state.authReducer.fetching}
            />
          </div>
        </form>
      </div>
    </ResetPasswordFormStyle>
  );
};

export default ResetPasswordForm;
