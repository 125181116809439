import styled from 'styled-components';
import search from '../../../assets/images/Search.svg';

const MerchantUsersTemplateStyle = styled.div`
  background: white;
  box-sizing: border-box;
  padding: 20px 40px 30px 30px;
  width: 100%;

  .merchants__back{
    justify-content: normal;
    a{
      display: flex;
    }
    p{
      color: rgba(37, 39, 51, 0.7);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      margin-top: 2.5px;
    }
  }
  .merchants__desc{
    margin-top: 10px;
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
    }
  }
  .merchants__top{
    background: white;
    border-radius: 8px;
    box-shadow: 0px 14px 25px rgba(37, 39, 51, 0.05);
    box-sizing: border-box;
    margin-top: 16px;
    padding: 20px 25px 15px 25px;
  }
  .merchants__top__desc{
    align-items: flex-start;
    border-bottom: 1px solid #F0F0F0;
    box-sizing: border-box;
    padding-bottom: 15px;
  }
  .merchants__top__desc__name{
    align-items: flex-start;
    display: flex;
    img{
      border-radius: 50%;
      height: 62px;
      margin-right: 12px;
      width: 62px;
    }
  }
  .merchants__top__desc__name__group{
    margin-top: 11px;
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 1px;
    }
    p{
      color: rgba(37, 39, 51, 0.5);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
    }
  }
  .merchants__top__desc__action{
    display: flex;
    margin-top: 22px;
    .button{
      border-radius: 5px;
      box-sizing: border-box
      display: flex;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      height: 30px;
      padding: 0 8px;
      text-align: left;
      img{
        margin-right: 3px;
        transform: translateY(4px);
      }
    }
    .--reject{
      background: #FEF0F0;
      color: #BD0F10;
      margin-right: 15.17px;
      position: relative;
      width: 77px;
      &:after{
        background: #F0F0F0;
        content: '';
        height: 21.12px;
        margin-right: -9px;
        margin-top: 4.44px;
        position: absolute;
        right: 0;
        top: 0;
        // transform: rotate(90deg);
        width: 1px;
      }
    }
    .--approve{
      background: #E7F9F4;
      color: #1B9830;
      width: 92px;
    }
  }
  .--validate{
    margin-top: 16px;
  }
  .merchants__top__desc__action__item{
    box-sizing: border-box;
    padding: 0 15.58px;
  }
  .--edit{
    border-right: 1.08333px solid #F0F0F0;
  }
  .merchants__top__nav{
    box-sizing: border-box;
    padding-top: 15px;
    .date{
      margin-top: 10px;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      font-feature-settings: 'salt' on;
      span{
        color: rgba(37, 39, 51, 0.5);
        font-family: 'Mulish';
      }
    }
  }
  .merchants__filter{
    margin-top: 37px;
  }
  .merchants__filter__desc{
    margin-top: 5px;
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }
  }
  .merchants__filter__action{
    display: flex;
    .input{
      background: url(${search}), #FAFAFA no-repeat;
      background-size: 18px;
      background-repeat: no-repeat;
      background-position: 8px 8px;
      border: none;
      border-radius: 8px;   
      box-sizing: border-box;
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      height: 34px;
      line-height: 16px;
      margin-right: 10px;
      padding-left: 37px;
      width: 194px;
      &::placeholder{
        color: rgba(159, 162, 180, 0.8)
      }
    }
  }
  .btn-group{
    .button{
      all: unset;
      margin-bottom: 0;
    }
    .--export{
      background: #FAFAFA;
      border-radius: 8px;
      box-sizing: border-box;
      height: 34px;
      margin-right: 10px;
      padding: unset;
      padding: 4px 8px;
      width: 34px;
    }
    .--disabled{
      opacity: 0.5;
      pointer-events: none;
    }
    .--user{
      background: #3751FF;
      border-radius: 8px;
      box-sizing: border-box;
      height: 34px;
      padding: unset;
      padding: 8px 8px;
      width: 34px;
    }
  }
  .merchants__table{
    margin-top: 9px;
  }
`;

export default MerchantUsersTemplateStyle;
