import React, { useState, useEffect } from 'react';
import ScaledBandDetailsModalStyle from './ScaledBandDetailsModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import Input from '../../atoms/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { SubmitActionsPOST } from '../../../redux/actions/SubmitAction';
import { alertActions } from '../../../redux/actions/AlertActions';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';
import closeIcon from '../../../assets/images/close-icon-round.svg';
import dropdown from '../../../assets/images/dropdown.svg';


type ScaledBandDetailsModalProps = {
  data: any
  handleClose: Function,
}

const ScaledBandDetailsModal = ({ handleClose, data }: ScaledBandDetailsModalProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);

  return (
    <ScaledBandDetailsModalStyle className={`container-full`}>
      <div className={`container-full center modal-container`}>
        <div className={`modal-container__box`}>
          <div className={`container-full modal-container__box__header`}>
            <HeaderText value='Scaled Band Details' />
            <Image image={close} onClick={() => handleClose()} />
          </div>
          <div className={`container-full modal-container__box__content`}>
            <div className={`container-full modal-container__box__content__details`}>
              <div className={`container-full modal-container__box__content__details__title`}>
                <HeaderText value='Salary' />
              </div>
              <div className={`container-full modal-container__box__content__details__content`}>
                <div className={`container-full modal-container__box__content__details__content__row`}>
                  <div className='div-md-6 modal-container__box__content__details__content__row__item'>
                    <Text value='Fee Type' className='title' />
                  </div>
                  <div className='div-md-6 modal-container__box__content__details__content__row__item'>
                    <div className={`modal-container__box__content__details__content__row__item__label`}>
                      <Text value='Scaled' className='label' />
                    </div>
                  </div>
                </div>
                <div className={`container-full modal-container__box__content__details__content__row`}>
                  <div className='div-md-6 modal-container__box__content__details__content__row__item'>
                    <Text value='Charge Type' className='title' />
                  </div>
                  <div className='div-md-6 modal-container__box__content__details__content__row__item'>
                    {
                      (data.chargeType === 1) ? <Text value='On Aggregated Payment' className='title' /> : null
                    }
                    {
                      (data.chargeType === 2) ? <Text value='Per Transaction' className='title' /> : null
                    }
                  </div>
                </div>
                <div className={`container-full modal-container__box__content__details__content__row`}>
                  <div className='div-md-6 modal-container__box__content__details__content__row__item'>
                    <Text value='Charge To' className='title' />
                  </div>
                  <div className='div-md-6 modal-container__box__content__details__content__row__item'>
                    {
                      (data.bearer === 1) ? <Text value='Merchant' className='title' /> : null
                    }
                    {
                      (data.bearer === 2) ? <Text value='Customer' className='title' /> : null
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className={`container-full modal-container__box__content__bands`}>
              {
                data.participantFeeScale && data.participantFeeScale.map((scale: Record<any, any>, key: number) => {
                  return (
                    <div className={`container-full modal-container__box__content__bands__item`}>
                      <div className={`container-full modal-container__box__content__bands__item__desc`}>
                        <HeaderText value={`Band ${key + 1}`} />
                      </div>
                      <div className={`container-full modal-container__box__content__bands__item__group`}>
                        <div className={`container-full modal-container__box__content__bands__item__group__row`}>
                          <div className={`div-md-6 modal-container__box__content__bands__item__group__row__item`}>
                            <Text value='Range' />
                          </div>
                          <div className={`div-md-6 modal-container__box__content__bands__item__group__row__item`}>
                            <h2> &#8358;{scale.scaleFrom} - &#8358;{scale.scaleTo} </h2>
                          </div>
                        </div>
                        <div className={`container-full modal-container__box__content__bands__item__group__row`}>
                          <div className={`div-md-6 modal-container__box__content__bands__item__group__row__item`}>
                            <Text value='Scale Type' />
                          </div>
                          <div className={`div-md-6 modal-container__box__content__bands__item__group__row__item`}>
                            <div className={`modal-container__box__content__bands__item__group__row__item__label`}>
                              {
                                (scale.scaleType === 0) ?
                                  <Text value='Fixed' /> : null
                              }
                              {
                                (scale.scaleType === 1) ?
                                  <Text value='Percentage' /> : null
                              }
                              {
                                (scale.scaleType === 2) ?
                                  <Text value='Hybrid' /> : null
                              }
                            </div>
                          </div>
                        </div>
                        <div className={`container-full modal-container__box__content__bands__item__group__row`}>
                          <div className={`div-md-6 modal-container__box__content__bands__item__group__row__item`}>
                            <Text value='Scale Value' />
                          </div>
                          <div className={`div-md-6 modal-container__box__content__bands__item__group__row__item`}>
                            {
                              (scale.scaleType === 0) ?
                                <p> &#8358;{scale.scaleValue} </p> : null
                            }
                            {
                              (scale.scaleType === 1 || scale.scaleType === 2) ?
                                <Text value={`${scale.scaleValue}%`} /> : null
                            }
                          </div>
                        </div>
                        {
                          (scale.scaleType === 2) ?
                            <div className={`container-full modal-container__box__content__bands__item__group__row`}>
                              <div className={`div-md-6 modal-container__box__content__bands__item__group__row__item`}>
                                <Text value='Added Fix Fee' />
                              </div>
                              <div className={`div-md-6 modal-container__box__content__bands__item__group__row__item`}>
                                <p> &#8358;{scale.addedFixedFee} </p>
                              </div>
                            </div> : null
                        }
                        {
                          (scale.scaleType !== 0) ?
                            <>
                              <div className={`container-full modal-container__box__content__bands__item__group__row`}>
                                <div className={`div-md-6 modal-container__box__content__bands__item__group__row__item`}>
                                  <Text value='Min Fee' />
                                </div>
                                <div className={`div-md-6 modal-container__box__content__bands__item__group__row__item`}>
                                  <p> &#8358;{scale.minFee}</p>
                                </div>
                              </div>
                              <div className={`container-full modal-container__box__content__bands__item__group__row`}>
                                <div className={`div-md-6 modal-container__box__content__bands__item__group__row__item`}>
                                  <Text value='Max Fee' />
                                </div>
                                <div className={`div-md-6 modal-container__box__content__bands__item__group__row__item`}>
                                  <p> &#8358;{scale.maxFee} </p>
                                </div>
                              </div>
                            </> : null
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </ScaledBandDetailsModalStyle>
  );
}

export default ScaledBandDetailsModal;