import React, { useState, useEffect } from 'react';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import InitiatePaymentStepTwoStyle from './InitiatePaymentStepTwoStyle';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import plus from '../../../assets/images/plus-icon-add.svg';
import minus from '../../../assets/images/minus.svg';
import { alertActions } from '../../../redux/actions/AlertActions';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';
import caret from '../../../assets/images/caret-down-select.svg';
import { stepTwo } from '../../../redux/actions/PaymentStepAction';

type InitiatePaymentStepTwoProps = {
  handleClose: Function,
  changeStep: Function
}

const InitiatePaymentStepTwo = ({ handleClose, changeStep }: InitiatePaymentStepTwoProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  const [deductions, setDeductions]: any = useState([]);
  const [fields, setFields] = useState({
    dedName: '',
    dedDesc: '',
    issuerCode: '',
    accountNo: '',
    emailAddress: ''
  })
  const [bankList, setBankList] = useState([]);

  useEffect(() => {
    generatePageContent();
  }, [])

  useEffect(() => {
    if (state.paymentStepReducer.results.stepTwo.length > 0) {
      setDeductions(state.paymentStepReducer.results.stepTwo);
    }
  }, [])

  const generatePageContent = async () => {
    try {
      const res = await axios.get(`/payroll/configuration/issuers?size=0&page=1&status=1`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setBankList(res.data.data.content);
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const inputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues[name] = value;
    await setFields(fieldsValues);
  };

  const removeDeduction = (level: number) => {
    let newDeductions = deductions.filter((deduction: Record<any, any>) => deduction.level !== level);
    setDeductions(newDeductions);
  }

  const addDeductios = () => {
    const deduction = {
      dedName: fields.dedName,
      dedDesc: fields.dedDesc,
      issuerCode: fields.issuerCode,
      accountNo: fields.accountNo,
      emailAddress: fields.emailAddress,
      level: deductions.length + 1
    }
    const newDeductions = [...deductions, deduction];
    setDeductions(newDeductions);
    setFields({
      dedName: '',
      dedDesc: '',
      issuerCode: '',
      accountNo: '',
      emailAddress: ''
    })
  }

  const onFormSubmit = () => {
    return async (evt: React.FormEvent) => {
      evt.preventDefault();
      addDeductios();
    }
  }

  const nextStep = async () => {
    if(fields.dedName !== "" && fields.dedDesc !== "" && fields.accountNo !== "" && fields.issuerCode !== "" && fields.emailAddress !== ""){
      addDeductios();
      return;
    }
    await dispatch(stepTwo(deductions));
    changeStep(3);
  }



  return (
    <InitiatePaymentStepTwoStyle>
      <div className={`container-full form`}>
        <div className={`container-full form__desc`}>
          <HeaderText value='Step 2:' className='step' />
          <HeaderText value='Deductions' className='details' />
        </div>
        {
          deductions.length > 0 && deductions.map((deduction: Record<any, any>, key: number) => {
            return (
              <div className={`container-full form__deductions`} key={key}>
                <div className={`container-full form__deductions__desc`}>
                  <HeaderText value={`Deduction ${key + 1}`} />
                  <Image image={minus} alt="minus" onClick={() => removeDeduction(key + 1)} />
                </div>
                <div className={`container-full form__deductions__content`}>
                  <div className={`container-full form__deductions__content__row`}>
                    <div className={`div-md-6 form__deductions__content__row__item`}>
                      <HeaderText value='Name' />
                      <Text value={deduction.dedName} />
                    </div>
                    <div className={`div-md-6 form__deductions__content__row__item`}>
                      <HeaderText value='Description' />
                      <Text value={deduction.dedDesc} />
                    </div>
                  </div>
                  <div className={`container-full form__deductions__content__row`}>
                    <div className={`div-md-6 form__deductions__content__row__item`}>
                      <HeaderText value='Bank Name' />
                      <Text value={deduction.issuerCode.split("+")[1]} />
                    </div>
                    <div className={`div-md-6 form__deductions__content__row__item`}>
                      <HeaderText value='Account Number' />
                      <Text value={deduction.accountNo} />
                    </div>
                  </div>
                  <div className={`container-full form__deductions__content__row`}>
                    <div className={`div-md-12 form__deductions__content__row__item`}>
                      <HeaderText value='Email' />
                      <Text value={deduction.emailAddress} />
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
        <div className={`container-full form__add-deduction`}>
          <div className={`container-full form__add-deduction__desc`}>
            <HeaderText value='Add Deduction' />
          </div>
          <form onSubmit={onFormSubmit()}>
            <div className={`container-full form__add-deduction__content`}>
              <div className={`container-full form__add-deduction__content__row`}>
                <div className={`div-md-6 form__add-deduction__content__row__item`}>
                  <HeaderText value='Name' />
                  <Input type='text' className="input" placeholder="Enter Name" required
                    name="dedName"
                    onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                    value={fields.dedName}
                  />
                </div>
                <div className={`div-md-6 form__add-deduction__content__row__item`}>
                  <HeaderText value='Description' />
                  <Input type='text' className="input" placeholder="Enter Description" required
                    name="dedDesc"
                    onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                    value={fields.dedDesc}
                  />
                </div>
              </div>
              <div className={`container-full form__add-deduction__content__row`}>
                <div className={`div-md-6 form__add-deduction__content__row__item`}>
                  <HeaderText value='Bank Name' />
                  <div className='input'>
                    <select required
                      name="issuerCode"
                      onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                      value={fields.issuerCode}
                    >
                      <option value="" hidden> Select Bank </option>
                      {
                        (bankList.length > 0 && bankList.map((bank: Record<any, any>) => {
                          return (<option value={`${bank.issuerCode}+${bank.name}`} key={bank.id}> {bank.name} </option>)
                        }))
                      }
                      {/* <option value="123+zenith bank"> Zenith Bank</option>  */}
                    </select>
                    <Image image={caret} />
                  </div>
                </div>
                <div className={`div-md-6 form__add-deduction__content__row__item`}>
                  <HeaderText value='Account Number' />
                  <Input type='text' className="input" placeholder="Enter Account Number" required
                    name="accountNo"
                    onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                    value={fields.accountNo}
                  />
                </div>
              </div>
              <div className={`container-full form__add-deduction__content__row`}>
                
                <div className={`div-md-12 form__add-deduction__content__row__item`}>
                  <HeaderText value='Email Address' />
                  <Input type='email' className="input" placeholder="Enter Email Address" required
                    name="emailAddress"
                    onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                    value={fields.emailAddress}
                  />
                </div>
              </div>
            </div>
            <Text value={`To ensure deduction processing, kindly input the corresponding account number per deduction column.`} className="additional-text" />
            <div className={`container-full form__add-deduction__action`}>
              <button type="submit" className='button'> <Image image={plus} alt="plus" /> Add Deduction</button>
            </div>
          </form>
        </div>
        <div className={`container-full form__action`}>
          <div className={`form__action__left`}>
            <Button type="button" value="Go Back" className={`button`} onClick={() => changeStep(1)} />
          </div>
          <div className={`form__action__right`}>
            <Button type="button" value="Cancel" className={`button --cancel`} onClick={() => handleClose()} />
            <Button type="submit" value="Continue" className={`button --continue `} isLoading={state.submitReducer.fetching} onClick={() => nextStep()} />
          </div>
          
        </div>
      </div>
    </InitiatePaymentStepTwoStyle>
  );
};

export default InitiatePaymentStepTwo;
