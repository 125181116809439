import styled from "styled-components";

const CreateSignatoryAssignModalStyle = styled.div`
    animation: show-bg 0.5s linear forwards;
    box-sizing:border-box;
    display:flex;
    height:100%;
    left:0;
    position:absolute;
    position:fixed;
    top: 0;
    width:100%;
    z-index:5000;
    @keyframes show-bg{
        0%{
            background:rgba(0, 0, 0, 0)
        }
        100%{
            background:rgba(37, 39, 51, 0.7);
        }
    }
    .modal-container{
        box-sizing:border-box;
        height:100vh;
    }
    .modal-container__box{
        -ms-flex: 0 0 552px;
        -webkit-flex: 0 0 552px;
        flex: 0 0 552px;
        max-width: 552px;
        background: white;
        border-radius:10px;
        box-sizing:border-box;
        padding: 15px;
    }
    .modal-container__box__header{
        box-sizing: border-box;
        padding-left: 10px;
        h2{
           color: #252733;
           font-family: 'Mulish';
           font-style: normal;
           font-weight: 700;
           font-size: 1.375em;
           line-height: 30px;
        }
        img{
            cursor: pointer;
        }
    }
    .modal-container__box__content{
        margin-top: 8px;
    }
    .modal-container__box__content__top{
        background: #FAFAFA;
        border-radius: 10px;
        box-sizing: border-box;
        height: 142.36px;
        margin-bottom: 18px;
        padding: 20px;
    }
    .modal-container__box__content__top__img{
        img{
            height: 66.36px;
            width: 66.36px;
        }
    }
    .modal-container__box__content__top__message{
        margin-top: 17px;
        h2{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 19px;
        }
    }
    .modal-container__box__content__bottom{
        box-sizing: border-box;
        padding: 0 10px;
        p{
            color: rgba(37, 39, 51, 0.7);
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 21px;
            &:nth-of-type(2){
                margin-top: 11px;
            }
        }
    }
    .modal-container__box__action{
        justify-content: flex-end;
        margin-top: 146px;
        .button{
            border-radius: 8px;
            font-family: 'Mulish';
            font-weight: 500;
            font-size: 13px;
            height: 32px;
            margin-bottom: 0;
        }
        .--cancel{
            background: #F5F5F5;
            color: #252733;
            letter-spacing: 0.2px;
            margin-right: 20px;
            width: 93px;
        }
        .--assign{
            background: #3751FF;
            box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
            color: white;
            width: 104px;
        }
    }
`
export default CreateSignatoryAssignModalStyle;