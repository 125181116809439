import styled from "styled-components";

const ApprovalModalCreateStyle = styled.div`
    animation: show-bg 0.5s linear forwards;
    box-sizing:border-box;
    display:flex;
    height:100%;
    left:0;
    position:absolute;
    position:fixed;
    top: 0;
    width:100%;
    z-index:5000;
    @keyframes show-bg{
        0%{
            background:rgba(0, 0, 0, 0)
        }
        100%{
            background:rgba(37, 39, 51, 0.7);
        }
    }
    .modal-container{
        box-sizing:border-box;
        height:100vh;
    }
    .modal-container__box{
        -ms-flex: 0 0 925px;
        -webkit-flex: 0 0 925px;
        flex: 0 0 925px;
        max-width: 925px;
        background: #FFFFFF;
        border-radius: 10px;
        box-sizing: border-box;
        padding:20px 25px 20.42px 25px;
    }
    .modal-container__box__header{
        h2{
           color: #252733;
           font-family: 'Mulish';
           font-style: normal;
           font-weight: 600;
           font-size: 18px;
           line-height: 23px;
           margin-top: 3.5px;
        }
        img{
            cursor: pointer;
        }
    }
    .modal-container__box__content{
        margin-top: 15px;
    }
    .modal-container__box__content__desc{
        border-bottom: 1px solid #F0F0F0;
        border-top: 1px solid #F0F0F0;
        box-sizing: border-box;
        padding: 15px 0;
    }
    .modal-container__box__content__desc__action{
        margin-bottom: 0;
        h2{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 15px;
        }
        p{
            color: rgba(37, 39, 51, 0.5);
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            span{
                color: #252733;
                font-family: 'Mulish';
                margin-left: 3px;
                text-transform: capitalize;
            }
        }
    }
    .modal-container__box__content__desc__details{
        margin-bottom: 0;
    }
    .modal-container__box__content__desc__details__row{
        margin-bottom: 15px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .modal-container__box__content__desc__details__row__item{
        margin-bottom: 0;
        .label{
            color: rgba(37, 39, 51, 0.5);
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
        }
        .value{
            color: #252733;
            font-family: 'Mulish';
            font-feature-settings: 'salt' on;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-align: right;
            span{
                color: rgba(37, 39, 51, 0.5);
                font-family: 'Mulish';
                margin-left: 3px;
            }
        }
    }
    .modal-container__box__content__table{
        height: 300px;
        margin-top: 15px;
        overflow: auto;
    }
    .modal-container__box__content__table__properties{
        -ms-flex: 0 0 150px;
        -webkit-flex: 0 0 150px;
        flex: 0 0 150px;
        max-width: 150px;
    }
    .modal-container__box__content__table__properties__header{
        background: #F2F3F6;
        box-sizing: border-box;
        margin-bottom: 0;
        padding: 12px 20px;
        p{
            color: #252733;
            font-family: 'Mulish';
            font-feature-settings: 'salt' on;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
        }
    }
    .modal-container__box__content__table__properties__content{
        margin-top: 15px;
    }
    .modal-container__box__content__table__properties__content__row{
        box-sizing: border-box;
        margin-bottom: 37px;
        padding: 0 20px;
        p{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            font-feature-settings: 'salt' on;
            line-height: 16px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    .modal-container__box__content__table__values{
        -ms-flex: 0 0 725px;
        -webkit-flex: 0 0 725px;
        flex: 0 0 725px;
        max-width: 725px;
    }
    .modal-container__box__content__table__values__header{
        background: #F2F3F6;
        box-sizing: border-box;
        margin-bottom: 0;
        padding: 12px 20px;
        p{
            color: #252733;
            font-family: 'Mulish';
            font-feature-settings: 'salt' on;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
        }
    }
    .modal-container__box__content__table__values__content{
        margin-top: 15px;
    }
    .modal-container__box__content__table__values__content__row{
        box-sizing: border-box;
        margin-bottom: 37px;
        padding: 0 20px;
        p{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            font-feature-settings: 'salt' on;
            line-height: 16px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    .modal-container__box__content__table__values__content__row__label{
        background: #F0F0F0;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 2px 6px;
        p{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            font-feature-settings: 'salt' on;
            line-height: 16px;
            text-align: center;
        }
    }
    .--enabled{
        background: rgba(41, 204, 151, 0.08);
        width: 61px;
        p{
            color: #1B9830;
        }
    }
    .--pending{
        background: rgba(254, 196, 0, 0.1);
        width: 61px;
        p{
            color: #DAAB0B;
        }
    }
    .--disabled{
        background: rgba(241, 43, 44, 0.1);
        width: 65px;
        p{
            color: #BD0F10;
        }
    }
    .--reviewers{
        justify-content: normal;
        .modal-container__box__content__table__values__content__row__label{
            margin-bottom: 6px;
            margin-right: 6px;
        }
    }
    .modal-container__box__content__action{
        margin-top: 24.42px;
    }
    .modal-container__box__content__action__input{
        -ms-flex: 0 0 679.17px;
        -webkit-flex: 0 0 679.17px;
        flex: 0 0 679.17px;
        max-width: 679.17px;
        .input{
            background: #F6F6F6;
            border: none;
            border-radius: 8px;
            box-sizing: border-box;
            color: #4B506D;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            height: 44px;
            padding: 12px 15px;
            &::placeholder{
                color: rgba(75, 80, 109, 0.4);
            }
        }
    }
    .modal-container__box__content__action__button{
        display: flex;
        margin-top: 7px;
        position: relative;
        .button{
            background: transparent;
            border-radius: 5px;
            box-sizing: border-box;
            height: 30px;
            margin-bottom: 0;
            padding-left: 29px;
            position: relative;
            p{
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
            }
            img{
                left: 0;
                margin-left: 8px;
                margin-top: 6px;
                position: absolute;
                top: 0;
            }
        }
        .--reject{
            background: #FEF0F0;
            color: #BD0F10;
            margin-right: 15.17px;
            width: 77px;
            &:after{
                background: #F0F0F0;
                content: '';
                height: 21.12px;
                left: 0;
                margin-left: 83px;
                margin-top: 4.43px;
                position: absolute;
                top: 0;
                z-index: 500000;
                width: 1.08333px;
            }
        }
        .--accept{
            background: #E7F9F4;
            color: #1B9830;
            width: 82px;
        }
    }
    
`
export default ApprovalModalCreateStyle;