import React, { useState, useEffect, useRef } from 'react';
import SignatoryGroupsTemplateStyle from './SignatoryGroupsTemplateStyle';
import Text from '../../atoms/Text/Text';
import Input from '../../atoms/Input/Input';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import { Link } from 'react-router-dom';
import edit from '../../../assets/images/edit-icon.svg';
import Button from '../../atoms/Button/Button';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { DashboardActions } from '../../../redux/actions/DashboardAction';
import dayjs from 'dayjs';
import { SubmitActionsPUT } from '../../../redux/actions/SubmitAction';
import SettingsNavBar from '../../organisms/SettingsNavBar/SettingsNavBar';
import exportIcon from '../../../assets/images/download-icon.svg';
import { CSVLink } from "react-csv";
import plusIcon from '../../../assets/images/plus-icon-white.svg';
import filterIcon from '../../../assets/images/filter-icon.svg';
import caret from '../../../assets/images/caret-filter-select.svg';
import nike from '../../../assets/images/nike.svg';
import plus from '../../../assets/images/plus-icon.svg';
import SignatoryGroupsTable from '../../organisms/SignatoryGroupsTable/SignatoryGroupsTable';
import CreateSignatoryModal from '../../organisms/CreateSignatoryModal/CreateSignatoryModal';
import CreateSignatoryAssignModal from '../../organisms/CreateSignatoryAssignModal/CreateSignatoryAssignModal';
import { exportAll, exportClear } from '../../../redux/actions/ExportAction';

const SignatoryGroupsTemplate = () => {
  let timeOutId: any = null;
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [toggleCreateSignatoryModal, setToggleCreateSignatoryModal] = useState(false);
  const [toggleCreateSignatoryAssignModal, setToggleCreateSignatoryAssignModal] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const dispatch: TypedDispatch = useDispatch();
  const state: any = useSelector((state: RootStore) => state);
  const [filterValues, setFilterValues] = useState<any>({});
  const [fields, setFields] = useState({
    status: ''
  });
  console.log(state);

  useEffect(() => {
    dispatch(exportAll(`/signatory?page=1&size=0${state.authReducer.results.user_level === 2 ? `&isGlobal = 1` : ''}`));
  }, [])

  useEffect(() => {
    dispatch(exportClear())
    generatePageContent();
  }, [size, page, reloadPage])

  const generatePageContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/signatory?page=${page}&size=${size}&name=${search}${state.authReducer.results.user_level === 2 ? `&isGlobal = 1` : ''}`));
    setFetchLoading(false);
  }

  const onClickHandler = (value: boolean) => {
    if (selectedMenu !== false) {
      setSelectedMenu(false);
      return;
    }
    setSelectedMenu(value);
  }

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
      setSelectedMenu(false);
    });
  }

  const onFocusHandler = () => {
    clearTimeout(timeOutId);
  }

  const renderFetchLoading = () => {
    if (fetchLoading) {
      return (
        <FetchLoading />
      )
    }
  }

  const changePage = (value: number) => {
    setPage(value);
  }

  const changeSize = (value: number) => {
    setSize(value);
  }

  const exportData = () => {
    return {
      fileData: () => {
        let signatoryGroups = state.exportReducer.results.data.map((signatory: Record<any, any>) => {
          return (
            {
              Name: signatory.name,
              CreatedBy: signatory.createdBy,
              DateModified: dayjs(signatory.createdDate).format('MM/DD/YYYY'),
              Description: signatory.description
            }
          )
        })
        let data = [...signatoryGroups];
        return data
      },
      fileHeaders: () => {
        let headers = [
          { label: "Name", key: "Name" },
          { label: "Description", key: "Description" },
          { label: "CreatedBy", key: "CreatedBy" },
          { label: "Date Modified", key: "DateModified" },
        ];
        return headers;
      }
    }
  }

  const exportDataAll = () => {
    return {
      fileData: () => {
        let signatoryGroups = state.exportReducer.allResults.data.map((signatory: Record<any, any>) => {
          return (
            {
              Name: signatory.name,
              CreatedBy: signatory.createdBy,
              DateModified: dayjs(signatory.createdDate).format('MM/DD/YYYY'),
              Description: signatory.description
            }
          )
        })
        let data = [...signatoryGroups];
        return data
      },
      fileHeaders: () => {
        let headers = [
          { label: "Name", key: "Name" },
          { label: "Description", key: "Description" },
          { label: "CreatedBy", key: "CreatedBy" },
          { label: "Date Modified", key: "DateModified" },
        ];
        return headers;
      }
    }
  }

  const refreshTable = () => {
    if (reloadPage) {
      return setReloadPage(false);
    }
    setReloadPage(true);
  }

  const changeFilterValue = async (event: any, name: string, value: string) => {
    if (event.target.checked) {
      const filterValuesObj: any = Object.assign({}, filterValues);
      filterValuesObj[name] = value;
      await setFilterValues(filterValuesObj);
    }
    else {
      const filterValuesObj: any = Object.assign({}, filterValues);
      delete filterValuesObj[name]
      await setFilterValues(filterValuesObj);
    }
  }

  const inputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues[name] = value;
    await setFields(fieldsValues);
  };

  const fetchFilteredContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/signatory?size=${size}&page=${page}${filterValues.status && fields.status !== '' ? `&status=${fields.status}` : ''}`));
    setFetchLoading(false);
    setSelectedMenu(false);
  }

  const showCreateSignatoryModal = () => {
    setToggleCreateSignatoryModal(true);
  }

  const hideCreateSignatoryModal = () => {
    setToggleCreateSignatoryModal(false);
  }

  const renderCreateSignatoryModal = () => {
    if (toggleCreateSignatoryModal) {
      return (
        <CreateSignatoryModal handleClose={hideCreateSignatoryModal} showSuccessModal={showCreateSignatoryAssignModal} />
      )
    }
  }

  const showCreateSignatoryAssignModal = () => {
    setToggleCreateSignatoryAssignModal(true);
  }

  const hideCreateSignatoryAssignModal = () => {
    setToggleCreateSignatoryAssignModal(false);
  }

  const renderCreateSignatoryAssignModal = () => {
    if (toggleCreateSignatoryAssignModal) {
      return (
        <CreateSignatoryAssignModal handleClose={hideCreateSignatoryAssignModal} reloadPage={refreshTable} />
      )
    }
  }


  return (
    <>
      {renderFetchLoading()}
      {renderCreateSignatoryModal()}
      {renderCreateSignatoryAssignModal()}
      <SignatoryGroupsTemplateStyle>
        <div className={`container-full settings`}>
          <div className={`container-full settings__desc`}>
            <HeaderText value="Settings" />
          </div>
          <SettingsNavBar />
          <div className={`container-full settings__content`}>
            <div className={`container-full settings__content__desc`}>
              <HeaderText value='Signatory Groups' />
            </div>
            <div className={`container-full settings__content__action`}>
              <Input className={`input`} type="text" value={search} placeholder="Search signatory group name"
                onChange={(event) => setSearch(event.target.value)}
                onKeyPress={event => {
                  if (event.key === 'Enter' || event.which === 13 || event.keyCode === 13) {
                    generatePageContent();
                  }
                }}
              />
              <div className='btn-group'>
                {
                  (state.exportReducer.results.data.length > 0) ?
                    <CSVLink data={exportData().fileData()} headers={exportData().fileHeaders()} filename={"signatory-groups.csv"}>
                      <button className='button --export'>
                        <Image image={exportIcon} />
                      </button>
                    </CSVLink> :
                    <CSVLink data={exportDataAll().fileData()} headers={exportDataAll().fileHeaders()} filename={"signatory-groups.csv"}>
                      <button className='button --export'>
                        <Image image={exportIcon} />
                      </button>
                    </CSVLink>
                }
                <button className={`button --user ${(state.authReducer.results.roles.includes('Authorizer') && state.authReducer.results.roles.length === 1) || (state.authReducer.results.roles.includes('Audit') && state.authReducer.results.roles.length === 1) ? '--disabled' : ''
                  }`} onClick={() => showCreateSignatoryModal()}>
                  <Image image={plusIcon} />
                </button>
              </div>
            </div>
            <div className={`container-full settings__content__filter`}>
              <div className={`settings__content__filter__item --filter`}>
                <div className={`--filter__box`}>
                  <Image image={filterIcon} alt="filter-icon" />
                </div>
                <Text value='Filter' />
              </div>
              {/* <div className={`settings__content__filter__item --active-filter`}>
                <Text value='No active filters' />
              </div> */}
              <div className={`settings__content__filter__item --add-filter`} onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
                <button className={`button --elipses`} onClick={() => onClickHandler(true)} aria-haspopup="true" aria-expanded={selectedMenu}>
                  <Image image={plus} />
                  <Text value='Add filter' />
                </button>
                {
                  selectedMenu !== false && (
                    <button className='unset'>
                      <div className={`container-full more-info`}>
                        <div className={`container-full more-info__top`}>
                          <div className={`div-md-4 more-info__top__item`}>
                            <div className={`container-full more-info__top__item__desc`}>
                              <div className={`more-info__top__item__desc__checkbox`}>
                                <input
                                  type="checkbox"
                                  name="status"
                                  onChange={(event) => changeFilterValue(event, event.target.name, event.target.value)}
                                  defaultChecked={(filterValues.status) ? true : false}
                                />
                                <Image image={nike} />
                              </div>
                              <Text value='Signatory Status' />
                            </div>
                            <div className={`container-full more-info__top__item__input`}>
                              <div className='input'>
                                <select required
                                  className={
                                    `${(filterValues.status === undefined) ? 'disabled' : null}`
                                  }
                                  name="status"
                                  value={fields.status}
                                  onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                                >
                                  <option value="" hidden> Select Status </option>
                                  <option value="1"> Active </option>
                                  <option value="0"> Disabled </option>
                                  <option value="2"> Pending </option>
                                  <option value="3"> Pending update enabled </option>
                                  <option value="4"> Pending update disabled </option>
                                  <option value="5"> Rejected pending </option>
                                </select>
                                <Image image={caret} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={`container-full more-info__bottom`}>
                          <Button value="Cancel" className='button --cancel' onClick={() => setSelectedMenu(false)} />
                          <Button value="Apply Filters" className={`button --apply`} onClick={() => fetchFilteredContent()} />
                        </div>
                      </div>
                    </button>
                  )
                }
              </div>
            </div>
          </div>
          <div className='container-full settings__table'>
            {
              (state.dashboardReducer.results !== null) ?
                <SignatoryGroupsTable data={state.dashboardReducer.results} pagination={size} setPagination={changeSize} changePage={changePage} page={page} openAddModal={showCreateSignatoryModal} reloadPage={refreshTable} /> : null
            }
          </div>
        </div>
      </SignatoryGroupsTemplateStyle>
    </>
  );
};

export default SignatoryGroupsTemplate;
