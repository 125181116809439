import axios from 'axios';
import Cookies from 'js-cookie';

const authInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Basic ZXRhcy53ZWIuY2xpZW50OnBhc3N3b3Jk`
  }
});

export default authInstance;

export const appInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

export const uploadInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});