import React, { useState, useEffect } from 'react';
import PartnerReportTemplateStyle from './PartnerReportTemplateStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import Input from '../../atoms/Input/Input';
import exportIcon from '../../../assets/images/download-icon.svg';
import filterIcon from '../../../assets/images/filter-icon.svg';
import plus from '../../../assets/images/plus-icon.svg';
import PartnerReportTable from '../../organisms/PartnerReportTable/PartnerReportTable';
import nike from '../../../assets/images/nike.svg';
import Button from '../../atoms/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { DashboardActions } from '../../../redux/actions/DashboardAction';
import { exportAll, exportClear } from '../../../redux/actions/ExportAction';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import { NavLink, useNavigate } from 'react-router-dom';
import { addDays, format } from 'date-fns';
import { DateRange, DayPicker } from 'react-day-picker';
import caret from '../../../assets/images/caret-filter-select.svg';
import { alertActions } from '../../../redux/actions/AlertActions';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';
import ReportDownload from '../../organisms/ReportDownload/ReportDownload';

const pastMonth = new Date();

const PartnerReportTemplate = () => {
  let timeOutId: any = null;
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const dispatch: TypedDispatch = useDispatch();
  const state: any = useSelector((state: RootStore) => state);
  // const navigate = useNavigate();
  const [filterValues, setFilterValues] = useState<any>({});
  const [fields, setFields] = useState({
    dateRange: '',
    transactionReference: '',
    partnerName: '',
    partnerBank: '',
    merchantName: '',
    syncStatus: ''
  });
  const [bankList, setBankList] = useState([]);
  const [partnerList, setPartnerList] = useState([]);
  const [merchantList, setMerchantList] = useState([]);

  const onClickHandler = (value: boolean) => {
    if (selectedMenu !== false) {
      setSelectedMenu(false);
      return;
    }
    setSelectedMenu(value);
  }

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
      setSelectedMenu(false);
    });
  }

  const onFocusHandler = () => {
    clearTimeout(timeOutId);
  }

  useEffect(() => {
    dispatch(exportAll(`/report/partners?size=0&page=1`));
  }, [])

  useEffect(() => {
    dispatch(exportClear())
    generatePageContent();
    fetchBanks();
    fetchMerchants();
    fetchPartners();
  }, [size, page, search])

  const generatePageContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/report/partners?size=${size}&page=${page}${search !== '' ? `&paymentRef=${search}` : ''}${window.location.href.split("?paymentRef=")[1] !== undefined ? `&paymentRef=${window.location.href.split("?paymentRef=")[1]}` : ''}`));
    setFetchLoading(false);
  }

  const fetchBanks = async () => {
    try {
      const res = await axios.get(`/payroll/configuration/issuers?size=0&page=1`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setBankList(res.data.data.content);
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const fetchMerchants = async () => {
    try {
      const res = await axios.get(`/merchant?size=0&page=1`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setMerchantList(res.data.data.content);
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const fetchPartners = async () => {
    try {
      const res = await axios.get(`payroll/configuration/partners?size=0&page=1`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setPartnerList(res.data.data.content);
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const renderFetchLoading = () => {
    if (fetchLoading) {
      return (
        <FetchLoading />
      )
    }
  }

  const changePage = (value: number) => {
    setPage(value);
  }

  const changeSize = (value: number) => {
    setSize(value);
  }

  const exportData = () => {
    return {
      fileData: () => {
        let reports = state.exportReducer.results.data.map((report: Record<any, any>) => {
          return (
            {
              PaymentName: report.paymentName,
              PaymentReference: report.paymentRef,
              PartnerName: report.partnerName,
              PartnerBank: report.partner ? report.partner.bankName : null,
              AccountNo: report.partner ? report.partner.accountNumber : null,
              Amount: report.totalAmount,
              TransactionDate: `${dayjs(report.date).format('MM/DD/YYYY')}`,
            }
          )
        })
        let data = [...reports];
        return data
      },
      fileHeaders: () => {
        let headers = [
          { label: "Payment Name", key: "PaymentName" },
          { label: "Payment Reference", key: "PaymentReference" },
          { label: "Partner Name", key: "PartnerName" },
          { label: "Partner Bank", key: "PartnerBank" },
          { label: "Account Number", key: "AccountNo" },
          { label: "Amount", key: "Amount" },
          { label: "Transaction Date", key: "TransactionDate" },
        ];
        return headers;
      }
    }
  }

  const exportDataAll = () => {
    return {
      fileData: () => {
        let reports = state.exportReducer.allResults.data.map((report: Record<any, any>) => {
          return (
            {
              PaymentName: report.paymentName,
              PaymentReference: report.paymentRef,
              PartnerName: report.partnerName,
              PartnerBank: report.partner ? report.partner.bankName : null,
              AccountNo: report.partner ? report.partner.accountNumber : null,
              Amount: report.totalAmount,
              TransactionDate: `${dayjs(report.date).format('MM/DD/YYYY')}`,
            }
          )
        })
        let data = [...reports];
        return data
      },
      fileHeaders: () => {
        let headers = [
          { label: "Payment Name", key: "PaymentName" },
          { label: "Payment Reference", key: "PaymentReference" },
          { label: "Partner Name", key: "PartnerName" },
          { label: "Partner Bank", key: "PartnerBank" },
          { label: "Account Number", key: "AccountNo" },
          { label: "Amount", key: "Amount" },
          { label: "Transaction Date", key: "TransactionDate" },
        ];
        return headers;
      }
    }
  }

  const refreshTable = () => {
    if (reloadPage) {
      return setReloadPage(false);
    }
    setReloadPage(true);
  }

  const defaultSelected: DateRange = {
    from: pastMonth,
    to: addDays(pastMonth, 4)
  };
  const [range, setRange] = useState<DateRange | any>(defaultSelected);

  let footer = <p>Please pick the first day.</p>;
  if (range !== undefined && range.from) {
    if (!range.to) {
      footer = <p>{format(range.from, 'PPP')}</p>;
    } else if (range.to) {
      footer = (
        <p>
          {format(range.from, 'PPP')}–{format(range.to, 'PPP')}
        </p>
      );
    }
  }

  const changeFilterValue = async (event: any, name: string, value: string) => {
    if (event.target.checked) {
      const filterValuesObj: any = Object.assign({}, filterValues);
      filterValuesObj[name] = value;
      await setFilterValues(filterValuesObj);
    }
    else {
      const filterValuesObj: any = Object.assign({}, filterValues);
      delete filterValuesObj[name]
      await setFilterValues(filterValuesObj);
    }
  }

  const inputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues[name] = value;
    await setFields(fieldsValues);
  };

  const fetchFilteredContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/report/partners?size=${size}&page=${page}${filterValues.transactionReference && fields.transactionReference !== '' ? `&paymentRef=${fields.transactionReference}` : ''}${filterValues.syncStatus && fields.syncStatus !== '' ? `&sync=${fields.syncStatus}` : ''}${filterValues.partnerName && fields.partnerName !== '' ? `&institutionCode=${fields.partnerName}` : ''}${filterValues.partnerBank && fields.partnerBank !== '' ? `&partnerCode=${fields.partnerBank}` : ''}${filterValues.merchantName && fields.merchantName !== '' ? `&merchantId=${fields.merchantName}` : ''}${filterValues.dateRange && range ? `&startDate=${dayjs(range.from).format("YYYY-MM-DD HH:mm:ss")}` : ''}${filterValues.dateRange && range ? `&endDate=${dayjs(range.to).format("YYYY-MM-DD HH:mm:ss")}` : ''}`));
    setFetchLoading(false);
    setSelectedMenu(false);
  }

  return (
    <>
      {renderFetchLoading()}
      <PartnerReportTemplateStyle>
        <div className={`container-full issuers`}>
          <div className={`container-full issuers__desc`}>
            <HeaderText value="Partner Report" />
          </div>
          <div className='container-full issuers__action'>
            <div className={`issuers__action__search`}>
              <Input className={`input`} type="text" placeholder="Search record"
                onChange={(event) => setSearch(event.target.value)}
                onKeyPress={event => {
                  if (event.key === 'Enter' || event.which === 13 || event.keyCode === 13) {
                    generatePageContent();
                  }
                }}
              />
            </div>
            <div className='issuers__action__btn-group'>
              <ReportDownload route='/report/partners/dn' />
              {
                (state.exportReducer.results.data.length > 0) ?
                  <CSVLink data={exportData().fileData()} headers={exportData().fileHeaders()} filename={"partner report.csv"}>
                    <button className='button --export'>
                      <Image image={exportIcon} />
                    </button>
                  </CSVLink> :
                  <CSVLink data={exportDataAll().fileData()} headers={exportDataAll().fileHeaders()} filename={"partner report.csv"}>
                    <button className='button --export'>
                      <Image image={exportIcon} />
                    </button>
                  </CSVLink>
              }
            </div>
          </div>
          <div className={`container-full merchant__filter`}>
            <div className={`merchant__filter__item --filter`}>
              <div className={`--filter__box`}>
                <Image image={filterIcon} alt="filter-icon" />
              </div>
              <Text value='Filter' />
            </div>
            {/* <div className={`merchant__filter__item --active-filter`}>
                <Text value='No active filters' />
              </div> */}
            <div className={`merchant__filter__item --add-filter`} onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
              <button className={`button --elipses`} onClick={() => onClickHandler(true)} aria-haspopup="true" aria-expanded={selectedMenu}>
                <Image image={plus} />
                <Text value='Add filter' />
              </button>
              {
                selectedMenu !== false && (
                  <button className='unset'>
                    <div className={`container-full more-info`}>
                      <div className={`container-full more-info__top`}>
                        <div className={`container-full more-info__top__row`}>
                          <div className={`div-md-3 more-info__top__row__item`}>
                            <div className={`container-full more-info__top__row__item__desc`}>
                              <div className={`more-info__top__row__item__desc__checkbox`}>
                                <input
                                  type="checkbox"
                                  name="transactionReference"
                                  onChange={(event) => changeFilterValue(event, event.target.name, event.target.value)}
                                  defaultChecked={(filterValues.transactionReference) ? true : false}
                                />
                                <Image image={nike} />
                              </div>
                              <Text value='Transaction Reference' />
                            </div>
                            <div className={`container-full more-info__top__row__item__input`}>
                              <Input type="text"
                                className={`input ${(filterValues.transactionReference === undefined) ? 'disabled' : null
                                  }`}
                                placeholder="Enter Transaction Reference"
                                name="transactionReference"
                                value={fields.transactionReference}
                                onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                              />
                            </div>
                          </div>
                          <div className={`div-md-4 more-info__top__row__item`}>
                            <div className={`container-full more-info__top__row__item__desc`}>
                              <div className={`more-info__top__row__item__desc__checkbox`}>
                                <input
                                  type="checkbox"
                                  name="dateRange"
                                  onChange={(event) => changeFilterValue(event, event.target.name, event.target.value)}
                                  defaultChecked={(filterValues.dateRange) ? true : false}
                                />
                                <Image image={nike} />
                              </div>
                              <Text value='Date Range' />
                            </div>
                            <div className={`container-full more-info__top__row__item__input`}>
                              <Input type="text" className={`input ${(filterValues.dateRange === undefined) ? 'disabled' : null
                                }`}
                                placeholder="Select Date Range"
                                value={(range) ? `${dayjs(range.from).format("YYYY-MM-DD")} to ${dayjs(range.to).format("YYYY-MM-DD")}` : ''}
                                name="dateRange"
                                onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                                readOnly
                              />
                              {
                                (filterValues.dateRange) ?
                                  <DayPicker
                                    mode="range"
                                    defaultMonth={pastMonth}
                                    selected={range}
                                    footer={footer}
                                    modifiersStyles={{
                                      selected: {
                                        background: 'blue',
                                        color: 'white',
                                        fontWeight: 'bold',
                                      },
                                    }}
                                    onSelect={setRange}
                                    style={{ position: "absolute", left: "0", marginTop: "40px", background: "white", boxSizing: "border-box", padding: "35px", boxShadow: "0px 15px 44px rgba(10, 18, 73, 0.15)" }}
                                    styles={{
                                      day: { boxSizing: "border-box", padding: '10px', borderRadius: "8px", fontFamily: "Mulish", },
                                      caption: { fontFamily: "Mulish" },
                                    }}
                                  /> : null
                              }
                            </div>
                          </div>
                          <div className={`div-md-3 more-info__top__row__item`}>
                            <div className={`container-full more-info__top__row__item__desc`}>
                              <div className={`more-info__top__row__item__desc__checkbox`}>
                                <input
                                  type="checkbox"
                                  name="partnerName"
                                  onChange={(event) => changeFilterValue(event, event.target.name, event.target.value)}
                                  defaultChecked={(filterValues.partnerName) ? true : false}
                                />
                                <Image image={nike} />
                              </div>
                              <Text value='Partner Name' />
                            </div>
                            <div className={`container-full more-info__top__row__item__input`}>
                              <div className='input'>
                                <select required
                                  className={
                                    `${(filterValues.partnerName === undefined) ? 'disabled' : null}`
                                  }
                                  name="partnerName"
                                  value={fields.partnerName}
                                  onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                                >
                                  <option value="" hidden> Select Partner Name </option>
                                  {
                                    (partnerList.length > 0 && partnerList.map((partner: Record<any, any>) => {
                                      return (<option value={partner.code} key={partner.id}> {partner.name} </option>)
                                    }))
                                  }
                                </select>
                                <Image image={caret} />
                              </div>
                            </div>
                          </div>

                          <div className={`div-md-3 more-info__top__row__item`}>
                            <div className={`container-full more-info__top__row__item__desc`}>
                              <div className={`more-info__top__row__item__desc__checkbox`}>
                                <input
                                  type="checkbox"
                                  name="partnerBank"
                                  onChange={(event) => changeFilterValue(event, event.target.name, event.target.value)}
                                  defaultChecked={(filterValues.partnerBank) ? true : false}
                                />
                                <Image image={nike} />
                              </div>
                              <Text value='Partner Bank' />
                            </div>
                            <div className={`container-full more-info__top__row__item__input`}>
                              <div className='input'>
                                <select required
                                  className={
                                    `${(filterValues.partnerBank === undefined) ? 'disabled' : null}`
                                  }
                                  name="partnerBank"
                                  value={fields.partnerBank}
                                  onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                                >
                                  <option value="" hidden> Select Partner Bank </option>
                                  {
                                    (bankList.length > 0 && bankList.map((bank: Record<any, any>) => {
                                      return (<option value={bank.issuerCode} key={bank.id}> {bank.name} </option>)
                                    }))
                                  }
                                </select>
                                <Image image={caret} />
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className={`container-full more-info__top__row`}>
                          {
                            state.authReducer.results.user_level === 2 &&
                            <div className={`div-md-3 more-info__top__row__item`}>
                              <div className={`container-full more-info__top__row__item__desc`}>
                                <div className={`more-info__top__row__item__desc__checkbox`}>
                                  <input
                                    type="checkbox"
                                    name="merchantName"
                                    onChange={(event) => changeFilterValue(event, event.target.name, event.target.value)}
                                    defaultChecked={(filterValues.merchantName) ? true : false}
                                  />
                                  <Image image={nike} />
                                </div>
                                <Text value='Merchant Name' />
                              </div>
                              <div className={`container-full more-info__top__row__item__input`}>
                                <div className='input'>
                                  <select required
                                    className={
                                      `${(filterValues.merchantName === undefined) ? 'disabled' : null}`
                                    }
                                    name="merchantName"
                                    value={fields.merchantName}
                                    onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                                  >
                                    <option value="" hidden> Select Merchant </option>
                                    {
                                      (merchantList.length > 0 && merchantList.map((merchant: Record<any, any>) => {
                                        return (<option value={merchant.id} key={merchant.id}> {merchant.businessName} </option>)
                                      }))
                                    }

                                  </select>
                                  <Image image={caret} />
                                </div>
                              </div>
                            </div>
                          }
                          <div className={`div-md-3 more-info__top__row__item`}>
                            <div className={`container-full more-info__top__row__item__desc`}>
                              <div className={`more-info__top__row__item__desc__checkbox`}>
                                <input
                                  type="checkbox"
                                  name="syncStatus"
                                  onChange={(event) => changeFilterValue(event, event.target.name, event.target.value)}
                                  defaultChecked={(filterValues.syncStatus) ? true : false}
                                />
                                <Image image={nike} />
                              </div>
                              <Text value='Sync Status' />
                            </div>
                            <div className={`container-full more-info__top__row__item__input`}>
                              <div className='input'>
                                <select required
                                  className={
                                    `${(filterValues.syncStatus === undefined) ? 'disabled' : null}`
                                  }
                                  name="syncStatus"
                                  value={fields.syncStatus}
                                  onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                                >
                                  <option value="" hidden> Select Sync Status </option>
                                  <option value="0"> Not Synced </option>
                                  <option value="1"> Synced </option>
                                  <option value="2"> Sync Failed </option>
                                </select>
                                <Image image={caret} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`container-full more-info__bottom`}>
                        <Button value="Cancel" className='button --cancel' onClick={() => setSelectedMenu(false)} />
                        <Button value="Apply Filters" className={`button --apply`} onClick={() => fetchFilteredContent()} />
                      </div>
                    </div>
                  </button>
                )
              }
            </div>
          </div>
          <div className='container-full issuers__table'>
            {
              (state.dashboardReducer.results !== null) ?
                <PartnerReportTable data={state.dashboardReducer.results} pagination={size} setPagination={changeSize} changePage={changePage} page={page} reloadPage={refreshTable} /> : null
            }
          </div>
        </div>
      </PartnerReportTemplateStyle>
    </>
  );

};

export default PartnerReportTemplate;
