import React, { useState } from 'react';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import AuditTableStyle from './AuditTableStyle';
import nike from '../../../assets/images/nike.svg';
import elipses from '../../../assets/images/elipses.svg';
import user from '../../../assets/images/user-male.svg';
import Pagination from '../Pagination/Pagination';
import AuditDetailsModal from '../AuditDetailsModal/AuditDetailsModal';
import dayjs from 'dayjs';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {exportData} from '../../../redux/actions/ExportAction';
import { useNavigate } from "react-router-dom";


type AuditTableProps = {
  data: any,
  pagination: number,
  setPagination: Function,
  changePage: Function,
  page: number,
  reloadPage: Function
}

const AuditTable = ({data, pagination, setPagination, changePage, page, reloadPage}: AuditTableProps) => {
  const [toggleAuditDetailsModal, setToggleAuditDetailsModal] = useState(false);
  let timeOutId:any = null;
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const dispatch: TypedDispatch = useDispatch();
  const state:any = useSelector( (state:RootStore) => state);
  const [auditData, setAuditData] = useState({});

  const onClickHandler = (value: boolean, id: string) => {
    if(selectedMenu !== false){
      setSelectedMenu(false);
      return;
    }
    setSelectedMenu(value);
    setSelectedId(id)
  }

  const onBlurHandler = () => {

      timeOutId = setTimeout(() => {
        setSelectedMenu(false);
      });
  }

  const onFocusHandler = () =>{
      clearTimeout(timeOutId);
  }

  const showAuditDetailsModal = (id: string) => {
    let auditData = data.data.content.filter((data: Record<any, any>) => data.id === id);
    let value = {
      ...auditData[0]
    }
    console.log(value)
    setAuditData(value);
    console.log(auditData);
    setToggleAuditDetailsModal(true);
  }

  const hideAuditDetailsModal = () => {
    setToggleAuditDetailsModal(false);
  }

  const renderAuditDetailsModal = () => {
    if(toggleAuditDetailsModal){
      return(
        <AuditDetailsModal handleClose={hideAuditDetailsModal} reloadPage={reloadPage} data={auditData}/>
      )
    }
  }

  const handleAllCheck = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      await dispatch(exportData(state.dashboardReducer.results.data.content))
      console.log(state.exportReducer);
    }
    else{
      await dispatch(exportData([]));
      console.log(state.exportReducer);
    }
  };

  const handleCheckItem = (item: Record<any, any>) => {
    if(state.exportReducer.results.data.includes(item)){
      const newArr = state.exportReducer.results.data.filter( (record: Record<any,any>) => record.id !== item.id)
      console.log(newArr);
      return dispatch(exportData(newArr));
    }
    const update = [...state.exportReducer.results.data, item]
    console.log(update);
    return dispatch(exportData(update));
  };

  return (
    <>
      {renderAuditDetailsModal()}
      <AuditTableStyle>
        <div className={`container-full`}>
          <table>
            <thead>
              <tr className={`table-header`}>
                <th>
                  <input type="checkbox" onChange={handleAllCheck}/>
                  <Image image={nike} />
                </th>
                <th>
                  <Text value="Date" className={`table-title`} />
                </th>
                <th>
                  <Text value="User" className={`table-title`} />
                </th>
                <th>
                  <Text value="Function" className={`table-title`} />
                </th>
                <th>
                  <Text value="Action" className={`table-title`} />
                </th>
              </tr>
            </thead>
            <tbody className={`table-body`}>
            {
                (data.data.content !== undefined && data.data.content.length > 0) ?
                data.data.content.map((audit: Record<any, any>, key: string) => {
                  return (
                    <tr className={`table-row`} key={key}>
                      <td>
                        <input
                          type="checkbox"
                          checked={state.exportReducer.results.data.includes(
                            audit
                          )}
                          onChange={() => handleCheckItem(audit)}
                        />
                        <Image image={nike} />
                      </td>
                      <td onClick={() => showAuditDetailsModal(audit.id)}> 
                        <Text value={dayjs(audit.createdDate).format('MM/DD/YYYY')} className='label' />
                        <Text value={dayjs(audit.createdDate).format('h:mm A')} className='date' />
                      </td>
                      <td onClick={() => showAuditDetailsModal(audit.id)}> 
                        <Text value={audit.userDetails && audit.userDetails.name} className='label --name' />
                        <Text value={audit.userDetails && audit.userDetails.email} className='sublabel' />
                      </td>
                      <td onClick={() => showAuditDetailsModal(audit.id)}>
                        {
                          (audit.auditAction === 1) ? <Text value={`User login`} className='label' /> : null
                        }
                        {
                          (audit.auditAction === 2) ? <Text value={`Give approval`} className='label' /> : null
                        }
                        {
                          (audit.auditAction === 3) ? <Text value={`Create`} className='label' /> : null
                        }
                        {
                          (audit.auditAction === 4) ? <Text value={`Update`} className='label' /> : null
                        }
                        {
                          (audit.auditAction === 5) ? <Text value={`Initiate payment`} className='label' /> : null
                        }
                        {
                          (audit.auditAction === 6) ? <Text value={`Give final payment approval`} className='label' /> : null
                        }
                        {
                          (audit.auditAction === 7) ? <Text value={`Send for fee calculation`} className='label' /> : null
                        }
                        {
                          (audit.auditAction === 8) ? <Text value={`Logout`} className='label' /> : null
                        }
                      </td>    
                      <td title={audit.comment}>
                        <Text value={audit.comment && audit.comment.length > 30 ? `${audit.comment.substring(0,30)}...` : audit.comment} className='label' />
                      </td>               
                    </tr>
                  )
                }) : null
              }          
            </tbody>
          </table>
        </div>
        <div className={`container-full pagination`}>
          <Pagination pagination={pagination} changePage={changePage} setPagination={setPagination} page={page}/> 
        </div>
      </AuditTableStyle>
    </>
  );
};

export default AuditTable;
