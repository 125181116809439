import React, {useState, useEffect} from 'react';
import MerchantNavStyle from './MerchantNavStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import { NavLink, useLocation } from 'react-router-dom';


const MerchantNav = () => {
    const location = useLocation();
    const checkIfProfileIsActive  = location.pathname.includes('profile');
    const checkIfUsersIsActive  = location.pathname.includes('users');

    return (
        <MerchantNavStyle className={`container-full merchant-nav`}>
            <NavLink 
                to={`/merchants/profile?${window.location.href.split("?")[1].split("&")[0]}`}
                className={ checkIfProfileIsActive ? 'active' : undefined}
            >
                <div className={`merchant-nav__item`}>
                    <Text value='Profile' />
                </div>
            </NavLink>
            <NavLink 
                to={`/merchants/users?${window.location.href.split("?")[1].split("&")[0]}`}
                className={ checkIfUsersIsActive ? 'active' : undefined}
            >
                <div className={`merchant-nav__item`}>
                    <Text value='Users' />
                </div>
            </NavLink>
            <NavLink 
                to={`/merchants/source-accounts?${window.location.href.split("?")[1].split("&")[0]}`}
                className={({ isActive }) =>
                isActive ? "active" : undefined
                }
            >
                <div className={`merchant-nav__item`}>
                    <Text value='Source Account' />
                </div>
            </NavLink>
            <NavLink 
                to={`/merchants/fees?${window.location.href.split("?")[1].split("&")[0]}`}
                className={({ isActive }) =>
                isActive ? "active" : undefined
                }
            >
                <div className={`merchant-nav__item`}>
                    <Text value='Fees' />
                </div>
            </NavLink>
        </MerchantNavStyle>
    );
}

export default MerchantNav;