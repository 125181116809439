import React, { useState, useEffect } from 'react';
import AuditDetailsModalStyle from './AuditDetailsModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import dayjs from 'dayjs';

type AuditDetailsModalProps = {
  handleClose: Function,
  data: any,
  reloadPage: Function
}

const AuditDetailsModal = ({ handleClose, data }: AuditDetailsModalProps) => {

  return (
    <AuditDetailsModalStyle className={`container-full`}>
      <div className={`container-full center modal-container`}>
        <div className={`modal-container__box`}>
          <div className={`container-full modal-container__box__header`}>
            <HeaderText value='Log Record' />
            <Image image={close} onClick={() => handleClose()} />
          </div>
          <div className={`container-full modal-container__box__content`}>
            <div className={`container-full modal-container__box__content__box`}>
              <div className={`container-full modal-container__box__content__box__desc`}>
                <HeaderText value='General Details' />
              </div>
              <div className={`container-full modal-container__box__content__box__content`}>
                <div className={`container-full modal-container__box__content__box__content__row`}>
                  <div className={`div-md-4 modal-container__box__content__box__content__row__item`}>
                    <HeaderText value='Date' />
                    <p> {dayjs(data.createdDate).format("DD/MM/YYYY")} <span>{dayjs(data.createdDate).format("HH:MM A")}</span></p>
                  </div>
                  <div className={`div-md-4 modal-container__box__content__box__content__row__item`}>
                    <HeaderText value='User' />
                    <Text value={data.userDetails && data.userDetails.name} />
                  </div>
                  <div className={`div-md-4 modal-container__box__content__box__content__row__item`}>
                    <HeaderText value='Section' />
                    <Text value='Merchant' />
                  </div>
                </div>
                <div className={`container-full modal-container__box__content__box__content__row`}>
                  <div className={`div-md-4 modal-container__box__content__box__content__row__item`}>
                    <HeaderText value='Function' />
                    {
                      (data.auditAction === 1) ? <Text value={`User login`} /> : null
                    }
                    {
                      (data.auditAction === 2) ? <Text value={`Give approval`} /> : null
                    }
                    {
                      (data.auditAction === 3) ? <Text value={`Create`} /> : null
                    }
                    {
                      (data.auditAction === 4) ? <Text value={`Update`} /> : null
                    }
                    {
                      (data.auditAction === 5) ? <Text value={`Initiate payment`} /> : null
                    }
                    {
                      (data.auditAction === 6) ? <Text value={`Give final payment approval`} /> : null
                    }
                    {
                      (data.auditAction === 7) ? <Text value={`Send for fee calculation`} /> : null
                    }
                    {
                      (data.auditAction === 8) ? <Text value={`Logout`} /> : null
                    }
                  </div>
                  <div className={`div-md-4 modal-container__box__content__box__content__row__item --roles`}>
                    <HeaderText value='User Role' />
                    {
                      data.userDetails && data.userDetails.userRoles.map((role: string, key: number) => {
                        return <Text value={role} key={key} />
                      })
                    }
                  </div>
                  <div className={`div-md-4 modal-container__box__content__box__content__row__item`}>
                    <HeaderText value='Action' />
                    <Text value={data.comment} />
                  </div>
                </div>
                {
                  (data.merchant) ?
                    <div className={`container-full modal-container__box__content__box__content__row`}>
                      <div className={`div-md-4 modal-container__box__content__box__content__row__item`}>
                        <HeaderText value='Merchant Name' />
                        <Text value={data.merchant} />
                      </div>
                    </div> : null
                }
              </div>
            </div>
            <div className={`container-full modal-container__box__content__box`}>
              <div className={`container-full modal-container__box__content__box__desc`}>
                <HeaderText value='User Device' />
              </div>
              <div className={`container-full modal-container__box__content__box__content`}>
                <div className={`container-full modal-container__box__content__box__content__row`}>
                  <div className={`div-md-4 modal-container__box__content__box__content__row__item`}>
                    <HeaderText value='User Client' />
                    <Text value={data.userClient} />
                  </div>
                  <div className={`div-md-4 modal-container__box__content__box__content__row__item`}>
                    <HeaderText value='IP Address' />
                    <Text value={data.ipAddress} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuditDetailsModalStyle>
  );
}

export default AuditDetailsModal;