import React, { useEffect, useState } from 'react';
import DashboardChartStyle from './DashboardChartStyle';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

type DashboardChartProps = {
  data: any,
}

const DashboardChart = ({ data }: DashboardChartProps) => {
  const [salaryValue, setSalaryValue]:any = useState([]);
  const [statBodyValue, setStatBodyValue]:any = useState([]);
  const [transValue, settransValue]:any = useState([]);
  const [labels, setLabels] = useState([]);
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);

  useEffect(() => {
    getLabels();
    getSalaryValue();
    getStatBodyValue();
    getTransValue();
  }, [data])

  const getLabels = () => {
    let labels:any = [];
    data.map((data: Record<any, any>) => {
      labels.push(data["label"]);
    })
    setLabels(labels);
    console.log(labels);
  }

  const getSalaryValue = () => {
    let salaryValue:any = [];
    data.map((data: Record<any, any>) => {
      salaryValue.push(data["salaryValue"]);
    })
    setSalaryValue(salaryValue);
    console.log(salaryValue)
  }

  const getStatBodyValue = () => {
    let statBodyValue:any = [];
    data.map((data: Record<any, any>) => {
      statBodyValue.push(data["statBodyValue"]);
    })
    setStatBodyValue(statBodyValue);
  }

  const getTransValue = () => {
    let transValue:any = [];
    data.map((data: Record<any, any>) => {
      transValue.push(data["transValue"]);
    })
    settransValue(transValue);
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options:any = {
    responsive: true,
    
    maintainAspectRatio: false,
    legend: {
        display: false,
    },
    scales: {
      x: [{
          display: true,
          ticks: {
              beginAtZero: false,
          }
      }]
  },
  };

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Statutory Body Value',
        data: [...statBodyValue],
        backgroundColor: '#0644CC',
      },
      {
        label: 'Salary Value',
        data: [...salaryValue],
        backgroundColor: '#3751FF',
      },
      {
        label: 'Transaction Value',
        data: [...transValue],
        backgroundColor: '#8790CC',
      }
    ],
  };

  return (
    <>
      <DashboardChartStyle>
        <Bar options={options} data={chartData} />
      </DashboardChartStyle>
    </>
  );
};

export default DashboardChart;
