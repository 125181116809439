import React, { useState, useEffect } from 'react';
import AuthorizePaymentModalStyle from './AuthorizePaymentModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import { SubmitActionsPOST } from '../../../redux/actions/SubmitAction';
import { useNavigate } from 'react-router-dom';
import submitBg from '../../../assets/images/submit-bg.svg';
// import crypto from 'crypto';
// var CryptoJS = require("crypto-js");

type AuthorizePaymentModalProps = {
  handleClose: Function;
  showSuccessModal: Function
}

const AuthorizePaymentModal = ({ handleClose, showSuccessModal }: AuthorizePaymentModalProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  const [inputValue, setInputValue] = useState("");
  const [submitted, setSubmitted] = useState(false);
  // const navigate = useNavigate();

  useEffect(() => {
    if (state.submitReducer.results && submitted) {
      // navigate(`/payments/pending-approval/summary/authorize?paymentReference=${window.location.href.split("=")[1]}`)
      handleClose();
      showSuccessModal();
    }
  }, [state.submitReducer])

  const onInputChange = (value: string) => {
    setInputValue(value)
  }

  const crypto = require('crypto');
  const iv = Buffer.alloc(16, 0); // Initialization vector.
  const secretKey = crypto.pbkdf2Sync(state.authReducer.results.merchant_id.toString(), state.authReducer.results.user_name, 65536, 32, 'sha256'); // Secret key derived from password and salt.

  function encrypt(strToEncrypt: string) {
    try {
      const cipher = crypto.createCipheriv('aes-256-cbc', secretKey, iv);
      const encrypted = cipher.update(strToEncrypt, 'utf8', 'base64') + cipher.final('base64');
      return encrypted;
    } catch (e: any) {
      console.log('Error while encrypting: ' + e.toString());
    }
    return null;
  }

  const authorizePayment = async () => {
    const payload = {
      "pin": encrypt(inputValue),
      "paymentReference": window.location.href.split("=")[1]
    }
    setSubmitted(true);
    dispatch(SubmitActionsPOST(`/payment/${window.location.href.split("=")[1]}/process`, payload));
  }





  return (
    <AuthorizePaymentModalStyle className={`container-full`}>
      <div className={`container-full center modal-container`}>
        <div className={`modal-container__box`}>
          <div className={`container-full modal-container__box__header`}>
            <HeaderText value='Authorize Payment' />
            <Image image={close} onClick={() => handleClose()} />
          </div>
          <div className={`container-full modal-container__box__content`}>
            <HeaderText value='Enter Pin' />
            <Input type="password" className="input" placeholder='Enter PIN'
              value={inputValue}
              onChange={(event: any) => { onInputChange(event.target.value) }}
              // onPaste={(event: React.FormEvent) => { event.preventDefault() }}
              // onCopy={(event: React.FormEvent) => { event.preventDefault() }}
              // onCut={(event: React.FormEvent) => { event.preventDefault() }}
            />
          </div>
          <div className={`container-full modal-container__box__action`}>
            <Button value="cancel" className="button --cancel" />
            <Button className='button --submit' value={
              <>
                <p>Submit</p>
                <Image image={submitBg} />
              </>
            } isLoading={state.submitReducer.fetching} onClick={() => authorizePayment()} />
          </div>
        </div>
      </div>
    </AuthorizePaymentModalStyle>
  );
}

export default AuthorizePaymentModal;