import React, { useState, useEffect } from 'react';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import AdminSigninFormStyle from './SigninFormStyle';
import Input from '../../atoms/Input/Input';
import eyesClose from '../../../assets/images/eyes-pwd-close.svg';
import eyes from '../../../assets/images/eyes-pwd.svg';
import Button from '../../atoms/Button/Button';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {signIn} from '../../../redux/actions/AuthAction';
import { useNavigate } from "react-router-dom";

const AdminSigninForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector( (state:RootStore) => state);
  const navigate = useNavigate();

  useEffect(() => {
    if(state.alertReducer.type === "alert-success" && state.authReducer.results.force_password_change){
      navigate('/settings/profile');
    }
    else if(state.alertReducer.type === "alert-success" && !state.authReducer.results.force_password_change){
      navigate('/dashboard')
    }
  }, [state.alertReducer])

  const toggleState = () => {
    return (evt: any) => {
      if (evt.target.parentElement.firstChild.type === 'password') {
        evt.target.src = eyes;
        return (evt.target.parentElement.firstChild.type = 'text');
      } else {
        evt.target.src = eyesClose;
        return (evt.target.parentElement.firstChild.type = 'password');
      }
    };
  }

  const onFormSubmit = () => {
    return async (evt: React.FormEvent) => { 
        evt.preventDefault();
        await dispatch(signIn({
          "grant_type": "password",
          "username": email,
          "password": password
        }))
    }
  }

  return (
    <AdminSigninFormStyle>
      <div className={`container-full form`}>
          <form onSubmit={onFormSubmit()}>
              <div className={`container-full form__item`}>
                <Text value='Username' className='label' />
                <Input type='text' className='input' value={email} onChange={((event) => setEmail(event.target.value))} placeholder='Email Username' required={true}/>
              </div>
              <div className={`container-full form__item`}>
                <Text value='Password' className='label' />
                <div className='container-full password'>
                  <Input type='password' className='input' value={password} onChange={((event) => setPassword(event.target.value))} placeholder='Password' required={true}
                    onPaste={(event: React.FormEvent) => {event.preventDefault()}}
                    onCopy={(event: React.FormEvent) => {event.preventDefault()}}
                    onCut={(event: React.FormEvent) => {event.preventDefault()}}
                  />
                  <img
                    src={eyesClose}
                    onClick={toggleState()}
                  />
                </div>
              </div>
              <div className='container-full form__action'>
                <Button type='submit' value="Log In" className="button" isLoading={state.authReducer.fetching} />
              </div>
          </form>
      </div>
    </AdminSigninFormStyle>
  );
};

export default AdminSigninForm;
