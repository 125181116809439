import React, { useState, useEffect } from 'react';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import OngoingPaymentsListSummaryStyle from './OngoingPaymentsListSummaryStyle';
import nike from '../../../assets/images/nike.svg';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import submit from '../../../assets/images/submit.svg';
import commaNumber from 'comma-number';
import Button from '../../atoms/Button/Button';
import { SubmitActionsPOST } from '../../../redux/actions/SubmitAction';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {appInstance as axios} from '../../../axios';
import cogoToast from "cogo-toast"; 


type UserTableProps = {
  data: any
}

const OngoingPaymentsListSummary = ({ data }: UserTableProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  const [savePayment, setSavePayment] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    if (state.submitReducer.results && submitted) {
      navigate('/payments/pending-approval')
    }
  }, [state.submitReducer])

  const onCheckboxChange = () => {
    return (event: any) => {
      if (event.target.checked) {
        setSavePayment(true);
        return;
      }
      setSavePayment(false);
    }
  }

  const sendForApproval = async () => {
    const payload = {
      "savePayment": savePayment ? 1 : 0
    }
    setSubmitted(true);
    dispatch(SubmitActionsPOST(`/payment/${window.location.href.split("=")[1]}/sendforapproval`, payload));
  }

  const downloadFile = async (file: string) => {
    const apiDefinition = () => {
      return axios.get(`/files/${file}`,
        {
          responseType: "blob",
          headers: {
            "Authorization": `Bearer ${Cookies.get("userToken")}`,
          }
        }
      );
    };
    try {
      const { data } = await apiDefinition();
      const url = window.URL.createObjectURL(new Blob([data]));
      console.log(data);
      const link = document.createElement('a');
      link.href = url;
      if(data.type === "application/octet-stream"){
        link.setAttribute('download', `${Date.now()}.xlsx`);
      }
      else{
        link.setAttribute('download', `${Date.now()}.${data.type.split("/")[1]}`);
      }
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      cogoToast.error("Error downloading file", { position: "top-right" })
    }
  }

  return (
    <>
      <OngoingPaymentsListSummaryStyle>
        <div className={`container-full data`}>
          <div className={`data__item`}>
            <div className={`container-full data__item__desc`}>
              <Text value='Salary' />
              <HeaderText value={data && commaNumber(data.data.totalSalaryAmount)} />
            </div>
            <div className={`container-full data__item__box`}>
              <HeaderText value='Salary' />
            </div>
            <div className={`container-full data__item__record`}>
              {
                data.data.salaryAggregates && data.data.salaryAggregates.length > 0 && data.data.salaryAggregates.map((salary: Record<any, any>, key: number) => {
                  return (
                    <div className={`div-sm-12 data__item__record__row --salary`} key={key}>
                      <div className={`data__item__record__row__salary`}>
                        <Text value={salary.issuerName} />
                        <div className={`data__item__record__row__salary__label`}>
                          {
                            salary.issuerType === 1 && <Text value='COM' />
                          }
                          {
                            salary.issuerType === 2 && <Text value='MFB' />
                          }
                          {
                            salary.issuerType === 3 && <Text value='WALLET' />
                          }
                        </div>
                      </div>
                      <HeaderText value={`₦${commaNumber(salary.totalAmount)}`} />
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className={`data__item`}>
            <div className={`container-full data__item__desc`}>
              <Text value='NHIS' />
              <HeaderText value={data && commaNumber(data.data.totalNhisAmount)} />
            </div>
            <div className={`container-full data__item__box`}>
              <HeaderText value='HMO' />
            </div>
            <div className={`container-full data__item__record`}>
              {
                data.data.nhisAggregates && data.data.nhisAggregates.length > 0 && data.data.nhisAggregates.map((nhis: Record<any, any>, key: number) => {
                  return (
                    <div className={`div-sm-12 data__item__record__row`} key={key}>
                      <Text value={nhis.partnerName} />
                      <HeaderText value={`₦${commaNumber(nhis.totalAmount)}`} />
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className={`data__item`}>
            <div className={`container-full data__item__desc`}>
              <Text value='NHF' />
              <HeaderText value={data && commaNumber(data.data.totalNhfAmount)} />
            </div>
            <div className={`container-full data__item__box`}>
              <HeaderText value='Bank' />
            </div>
            <div className={`container-full data__item__record`}>
              {
                data.data.nhfAggregates && data.data.nhfAggregates.length > 0 && data.data.nhfAggregates.map((nhf: Record<any, any>, key: number) => {
                  return (
                    <div className={`div-sm-12 data__item__record__row`} key={key}>
                      <Text value={nhf.partnerName} />
                      <HeaderText value={`₦${commaNumber(nhf.totalAmount)}`} />
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className={`data__item`}>
            <div className={`container-full data__item__desc`}>
              <Text value='PAYE' />
              <HeaderText value={data && commaNumber(data.data.totalPayeAmount)} />
            </div>
            <div className={`container-full data__item__box`}>
              <HeaderText value='Tax Office' />
            </div>
            <div className={`container-full data__item__record`}>
              {
                data.data.payeAggregates && data.data.payeAggregates.length > 0 && data.data.payeAggregates.map((paye: Record<any, any>, key: number) => {
                  return (
                    <div className={`div-sm-12 data__item__record__row`} key={key}>
                      <Text value={paye.partnerName} />
                      <HeaderText value={`₦${commaNumber(paye.totalAmount)}`} />
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className={`data__item`}>
            <div className={`container-full data__item__desc`}>
              <Text value='Pensions' />
              <HeaderText value={data && commaNumber(data.data.totalPensionAmount)} />
            </div>
            <div className={`container-full data__item__box`}>
              <HeaderText value='PFA' />
            </div>
            <div className={`container-full data__item__record`}>
              {
                data.data.pfaAggregates && data.data.pfaAggregates.length > 0 && data.data.pfaAggregates.map((pension: Record<any, any>, key: number) => {
                  return (
                    <div className={`div-sm-12 data__item__record__row`} key={key}>
                      <Text value={pension.partnerName} />
                      <HeaderText value={`₦${commaNumber(pension.totalAmount)}`} />
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className={`data__item`}>
            <div className={`container-full data__item__desc`}>
              <Text value='Deductions' />
              <HeaderText value={data && commaNumber(data.data.totalThirdPartyDeductionAmount)} />
            </div>
            <div className={`container-full data__item__box`}>
              <HeaderText value='Deductions' />
            </div>
            <div className={`container-full data__item__record`}>
              {
                data.data.deductionsAggregates && data.data.deductionsAggregates.length > 0 && data.data.deductionsAggregates.map((ded: Record<any, any>, key: number) => {
                  return (
                    <div className={`div-sm-12 data__item__record__row`} key={key}>
                      <Text value={ded.dedName} />
                      <HeaderText value={`₦${commaNumber(ded.totalAmount)}`} />
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
        <div className={`container-full pagination`}>
          <div className={`div-md-6 pagination__box`}>
            <Button className='button' value={"Download File"} isLoading={state.submitReducer.fetching} onClick={() => downloadFile(state.dashboardReducer.results.data.fileId)} />
            {
              state.dashboardReducer.results.data.invoiceUri &&
              <Button className='button' value={"Download Invoice"} isLoading={state.submitReducer.fetching} onClick={() => downloadFile(state.dashboardReducer.results.data.invoiceUri)} />
            }
          </div>
          <div className={`div-md-6 pagination__action`}>
            <div className={`pagination__action__save`}>
              <div className={`pagination__action__save__checkbox`}>
                <input
                  type="checkbox"
                  onChange={onCheckboxChange()}
                  value={savePayment.toString()}
                />
                <Image image={nike} />
              </div>
              <HeaderText value='Save this payment' />
            </div>
            <button className='button --cancel' onClick={() => navigate(`/payments/ongoing-payment/list?paymentReference=${window.location.href.split("=")[1]}`)}> Cancel </button>
            <Button className='button --submit' value={
              <>
                <p>Submit List</p>
                <Image image={submit} />
              </>
            } isLoading={state.submitReducer.fetching} onClick={() => sendForApproval()} />
          </div>
        </div>
      </OngoingPaymentsListSummaryStyle>
    </>
  );
};

export default OngoingPaymentsListSummary;
