import styled from "styled-components";

const MerchantProgressBarStyle = styled.div`
    width:100%;
    .merchants__body__progress{
        box-sizing: border-box;
    }
    .merchants__body__progress__item{
        -ms-flex: 0 0 33.33%;
        -webkit-flex: 0 0 33.33%;
        flex: 0 0 33.33%;
        max-width: 33.33%;
        position: relative;
        &:after{
            background: #C4C4C4;
            content: '';
            display: inline-block;
            left: 0;
            margin-left: 8%;
            margin-top: 9px;
            position: absolute;
            top: 0;
            width: 89%;
            height: 2px;
        }
    }
    .--last-item{
        display: flex;
        justify-content: space-between;
        &:after{
            width: 84%;
        }
    }
    .--active{
        &:after{
            background: #3751FF;
        }
    }
    .merchants__body__progress__item__checkbox{
        position: relative;
        .input{
            left: 0;
            margin-left: 2px;
            margin-top: -10px;
            opacity: 0;
            position: absolute;
            top: 0;
            width: 17px;
            z-index: 500;
            &:checked + {
            .merchants__body__progress__item__checkbox__box{
                background: #3751FF;
                border: 2px solid #3751FF;
                img{
                transform: scale(1);
                }
            }
            }
        }
    }
    .merchants__body__progress__item__checkbox__box{
        border: 2px solid #C4C4C4;
        border-radius: 50%;
        height: 17px;
        transition: background 0.5s linear;
        width: 17px;
        img{
            left: 0;
            margin-left: 6px;
            margin-top: 7px;
            position: absolute;
            transform: scale(0);
            transition: all 0.5s linear;
            top: 0;
        }
    }
    .--current-step{
        border: 2px solid #3751FF;
    }
   
`
export default MerchantProgressBarStyle;