import styled from "styled-components";
import Colors from '../../../helpers/Colors';

const DashboardHeaderStyle = styled.div`
  width: 100%;

  .dashboard-header{
    background: #F2F3F6;
    box-sizing: border-box;
    height: 50px;
    justify-content: flex-end;
    padding: 13px 41px 13px 41px;
  }
  .dashboard-header__actions{
    display: flex;
  }
  .dashboard-header__actions__item{
    border-right: 1px solid #DFE0EB;
    padding: 0 13px 0 13px;
    position: relative;
    &:last-child{
      border-right: 0;
      padding-right: 0;
    }
    .--elipses{
      all: unset;
      cursor: pointer;
      margin-bottom: 0;
      position: relative;
    }
    img{
      cursor: pointer;
      margin-top: 3px;
    }
    .notif{
      height: 20px;
      width: 20px;
    }
    .logout-icon{
      width: 18px;
      height: 18px;
    }
  }
  .dashboard-header__actions__item__badge{
    background: #F12B2C;
    border-radius: 50%;
    box-sizing: border-box;
    height: 18px;
    margin: -5px -5px 0 0;
    padding-top: 4px;
    position: absolute;
    right: 0;
    top: 0;
    width: 18px;
    p{
      color: white;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 9px;
      text-align: center;
    }
  }
  .unset{
    all: unset;
  }
  .more-info{
    background: white;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0px 14px 25px rgba(0, 0, 0, 0.1);
    cursor: unset;
    margin-right: 5px;
    margin-top: 33px;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 0;
    width: 310px;
    z-index: 4000;
  }
  .more-info__desc{
    h2{
      color: #130F26;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .more-info__nav{
    border-bottom: 1px solid #EEEEEE;
    display: flex;
    margin-top: 15px;
    width: 100%;
  }
  .more-info__nav__item{
    box-sizing: border-box;
    cursor: pointer;
    margin-right: 10px;
    padding: 4px 10px 2px 10px;
    p{
      color: #B6B6BB;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: -0.01em;
    }
  }
  .--active-nav{
    border-bottom: 2px solid #3751FF;
    box-sizing: border-box;
    padding: 2px 10px 4px 10px;
    p{
      color: #3751FF;
      font-weight: 600;
    }
  }
  .more-info__notifications{
    max-height:315px;
    margin-top: 10.5px;
    overflow: auto;
  }
  .more-info__notifications__item{
    background: white;
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 5px;
    padding: 8px 12px 8px 9px;
    position: relative;
    &:last-child{
      margin-bottom: 0;
      &:after{
        display: none;
      }
    }
    &:after{
      background: #EEEEEE;
      bottom: 0;
      content: '';
      display: block;
      height: 1px;
      margin-bottom: -2.5px;
      margin-left: 8px;
      position: absolute;
      left: 0;
      width: 93%;
    }
  }
  .--active{
    border-left: 3px solid #3751FF;
    .more-info__notifications__item__desc{
      h2{
        font-weight: 600;
      }
    }
  }
  .more-info__notifications__item__desc{
    margin-bottom: 3px;
    h2{
      color: #130F26;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .more-info__notifications__item__details{
    justify-content: normal;
    p{
      color: rgba(19, 15, 38, 0.5);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
    }
  }
  .more-info__notifications__item__details__dot{
    background: #D9D9D9;
    border-radius: 50%;
    height: 2px;
    margin: 10px 3px 0 3px;
    width: 2px;
  }
  .more-info__action{
    margin-bottom: 10px;
    margin-top: 14px;
    .button{
      background: #FFFFFF;
      border-radius: 5px;
      color: #3751FF;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      height: 31px;
      margin-bottom: 0;
      width: 100%;
    }
  }
  
`;

export default DashboardHeaderStyle;