import { Dispatch } from 'redux';
import {
  SubmitDispatchTypes,
  SUBMIT_SUCCESSFUL,
  SUBMIT_FAILED,
  SUBMIT_REQUEST,
  SUBMIT_CLEAR
} from './SubmitActionTypes';
import { appInstance as axios, uploadInstance } from '../../axios';
import { alertActions } from './AlertActions';
import Cookies from 'js-cookie';
import historyObject from '../../config/History';

export const SubmitActionsPOST =
  (url: string, value: object) =>
    async (dispatch: Dispatch<SubmitDispatchTypes | any>) => {
      try {
        dispatch({ type: SUBMIT_REQUEST });
        dispatch(alertActions.clear());
        const res = await axios.post(url, value, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get("userToken")}`
          }
        });
        dispatch({
          type: SUBMIT_SUCCESSFUL,
          response: res.data,
        });
        dispatch(alertActions.success(res.data.message));
      } catch (error: any) {
        if (error.response.status === 401 && error.response.data.error !== "Access is denied") {
          await Cookies.remove("userToken");
          historyObject.push("/");
        }
        if (error.message === "Network Error") {
          dispatch({
            type: SUBMIT_FAILED,
          });
          dispatch(alertActions.error('Network Error, try again!!!'));
        } else {
          dispatch({
            type: SUBMIT_FAILED,
          });
          dispatch(alertActions.error(error.response.data.error));
        }
      }
    };

export const SubmitActionsGET =
  (url: string) => async (dispatch: Dispatch<SubmitDispatchTypes | any>) => {
    try {
      dispatch({ type: SUBMIT_REQUEST });
      dispatch(alertActions.clear());
      const res = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      dispatch({
        type: SUBMIT_SUCCESSFUL,
        response: res.data,
      });
      dispatch(alertActions.success(res.data.message));
    } catch (error: any) {
      if (error.response.status === 401 && error.response.data.error !== "Access is denied") {
        await Cookies.remove("userToken");
        historyObject.push("/");
      }
      if (error.message === "Network Error") {
        dispatch({
          type: SUBMIT_FAILED,
        });
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch({
          type: SUBMIT_FAILED,
        });
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  };

export const SubmitActionsPUT = (url: string, value: object) => async (dispatch: Dispatch<SubmitDispatchTypes | any>) => {
  try {
    dispatch({ type: SUBMIT_REQUEST });
    dispatch(alertActions.clear());
    const res = await axios.put(url, value, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get("userToken")}`
      }
    })
    dispatch({
      type: SUBMIT_SUCCESSFUL,
      response: res.data
    })
    dispatch(alertActions.success(res.data.message));
  } catch (error: any) {
    if (error.response.status === 401 && error.response.data.error !== "Access is denied") {
      await Cookies.remove("userToken");
      historyObject.push("/");
    }
    if (error.message === "Network Error") {
      dispatch({
        type: SUBMIT_FAILED
      });
      dispatch(alertActions.error("Network Error, try again!!!"));
    }
    else {
      dispatch({
        type: SUBMIT_FAILED
      });
      dispatch(alertActions.error(error.response.data.error));
    }
  }
}

export const SubmitActionsUpload =
  (url: string, value: object) =>
    async (dispatch: Dispatch<SubmitDispatchTypes | any>) => {
      try {
        dispatch({ type: SUBMIT_REQUEST });
        dispatch(alertActions.clear());
        const res = await uploadInstance.post(url, value, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${Cookies.get("userToken")}`
          }
        });
        dispatch({
          type: SUBMIT_SUCCESSFUL,
          response: res.data,
        });
        dispatch(alertActions.success(res.data.message));
      } catch (error: any) {
        if (error.response.status === 401 && error.response.data.error !== "Access is denied") {
          await Cookies.remove("userToken");
          historyObject.push("/");
        }
        if (error.message === "Network Error") {
          dispatch({
            type: SUBMIT_FAILED,
          });
          dispatch(alertActions.error('Network Error, try again!!!'));
        } else {
          dispatch({
            type: SUBMIT_FAILED,
          });
          dispatch(alertActions.error(error.response.data.error));
        }
      }
    };

export const SubmitClear =
  () => async (dispatch: Dispatch<SubmitDispatchTypes | any>) => {
    dispatch({
      type: SUBMIT_CLEAR
    });
  };

