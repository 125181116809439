import styled from "styled-components";
import Colors from "../../../helpers/Colors";
// import Colors from '../../../helpers/Colors';

const SignatoryGroupsTableStyle = styled.div`
  width: 100%;
  .table__box{
    -ms-flex: 0 0 67.2%;
    -webkit-flex: 0 0 67.2%;
    flex: 0 0 67.2%;
    max-width: 67.2%;
    margin-bottom: 0;
  }
  table {
    width: 100%;
    input[type='checkbox'] {
      -moz-appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;
      background: #FFFFFF;
      border-radius: 3px;
      height: 16px;
      outline: 0.52531px solid #B5BABD !important;
      position: relative;
      transition: all 0.2s linear;
      width: 16px;
      & + img {
        margin-left: -12px;
        margin-top: 17px;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: all 0.2s linear;
        transform: scale(0);
        transform-origin: bottom left;
      }
      &:checked {
        background: #3751FF;
        outline: 2px solid #3751FF !important;
      }
      &:checked + img {
        transform: scale(1);
      }
    }
  }
  .table-header {
    th {
      background: #F2F3F6;
      border-collapse: no-collapse;
      padding: 13px 12px 11px 14px;
      position: relative;
      text-align: left;
      .table-title {
        border: 0 !important;
        color: #252733;
        font-family: 'Mulish';
        font-feature-settings: 'salt' on;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
  .table-body{
    tr{
      cursor: pointer;
      transition: all 0.5s linear;
      &:hover{
        background: #F2F3F6;
      }
    }
    td {
      box-sizing: border-box;
      padding: 17.5px 12px 12.5px 14px;
      position: relative;
      input[type='checkbox'] {
        & + img {
          margin-left: -12px;
          margin-top: 30px;
        }
      }
      div{
        margin-top: 3px;
      }
    }
    .label{
      color: #252733;
      font-family: 'Mulish';
      font-feature-settings: 'salt' on;
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
    }
    .--name{
      cursor: pointer;
    }
    .sublabel{
      color: #737373;
      font-family: 'Mulish';
      font-feature-settings: 'salt' on;
      font-style: normal;
      font-weight: 300;
      font-size: 11px;
      line-height: 14px;
      margin-top: 2px;
    }
    .date{
      color: rgba(37, 39, 51, 0.5);
      font-family: 'Mulish';
      font-feature-settings: 'salt' on;
      font-style: normal;
      font-weight: 300;
      font-size: 11px;
      line-height: 14px;
      margin-top: 3px;
    }
    .status{
      border-radius: 5px;
      box-sizing: border-box;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      padding: 2px 6px;
      text-align: center;
    }
    .--active{
      background: rgba(41, 204, 151, 0.08);
      color: #1B9830;
    }
    .--inactive{
      background: rgba(241, 43, 44, 0.1);
      color: #BD0F10;
    }
    .--pending{
      background: rgba(254, 196, 0, 0.1);
      color: #DAAB0B;
    }
    .--elipses{
      all: unset;
      cursor: pointer;
      margin-bottom: 0;
    }
  }
}
  table,
  td,
  th {
    border-collapse: collapse;
  }
  .pagination{
    border-top: 1px solid #EEEEEE;
    box-sizing: border-box;
    margin-top: 30px;
    padding: 20px 23px 20px 20px;
  }
  .more-info {
    background: white;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(8, 9, 10, 0.1);
    margin-right: 82.5px;
    margin-top: 6px;
    padding: 5px;
    position: absolute;
    right: 0;
    top: 0;
    width: 141px;
    z-index: 4000;
  }
  .more-info__item{
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 2px;
    padding: 14.5px 8px 14.5px 15px;
    transition: all 0.5s linear;
    button{
      all:unset;
      cursor:pointer;
      width:100%;
    }
    &:hover{
      background: #F5F6FF;
      background-blend-mode: multiply;
      border-radius: 5px;
    }
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }
  }
  .table__content{
    -ms-flex: 0 0 31%;
    -webkit-flex: 0 0 31%;
    flex: 0 0 31%;
    max-width: 31%;
    margin-bottom: 0;
  }
`;

export default SignatoryGroupsTableStyle;