import { Dispatch } from 'redux';
import { DEDUCTIONS, FeeDispatchTypes, INFO, NHF, NHIS, PAYE, PENSION, SALARY, STEP_CLEAR } from './FeeStepActionTypes';

export const setInfo =
  (data: object) => async (dispatch: Dispatch<FeeDispatchTypes>) => {
    dispatch({
      type: INFO,
      data,
    });
};

export const setNHIS =
  (data: object) => async (dispatch: Dispatch<FeeDispatchTypes>) => {
    dispatch({
      type: NHIS,
      data,
    });
};

export const setNHF =
  (data: object) => async (dispatch: Dispatch<FeeDispatchTypes>) => {
    dispatch({
      type: NHF,
      data,
    });
};

export const setPension =
  (data: object) => async (dispatch: Dispatch<FeeDispatchTypes>) => {
    dispatch({
      type: PENSION,
      data,
    });
};

export const setPAYE =
  (data: object) => async (dispatch: Dispatch<FeeDispatchTypes>) => {
    dispatch({
      type: PAYE,
      data,
    });
};

export const setSalary =
  (data: object) => async (dispatch: Dispatch<FeeDispatchTypes>) => {
    dispatch({
      type: SALARY,
      data,
    });
};

export const setDeductions =
  (data: object) => async (dispatch: Dispatch<FeeDispatchTypes>) => {
    dispatch({
      type: DEDUCTIONS,
      data,
    });
};

export const stepClear =
  () => async (dispatch: Dispatch<FeeDispatchTypes>) => {
    dispatch({
      type: STEP_CLEAR
    });
};
