import React, { useState, useEffect } from 'react';
import SignatoryGroupDetailsStyle from './SignatoryGroupDetailsStyle';
import Text from '../../atoms/Text/Text';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import HeaderText from '../../atoms/HeaderText/Text';
import closeIcon from '../../../assets/images/close-icon-round.svg';
import dropdown from '../../../assets/images/dropdown.svg';
import icon from '../../../assets/images/add-icon-blue.svg';
import { alertActions } from '../../../redux/actions/AlertActions';
import {appInstance as axios} from '../../../axios';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import Cookies from 'js-cookie';
import { SubmitActionsPUT } from '../../../redux/actions/SubmitAction';

type SignatoryGroupDetailsProps = {
  data: any,
  openAddModal: Function,
  reloadPage: Function,
  setData: Function
}

const SignatoryGroupDetails = ({ data, openAddModal, reloadPage, setData }: SignatoryGroupDetailsProps) => {
  let timeOutId: any = null;
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [signatoryData, setSignatoryData]: any = useState({});
  const [usersList, setUsersList] = useState([]);
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector( (state:RootStore) => state);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    fetchUsers();
    console.log(state);
  }, [])

  useEffect(() => {
    setSignatoryData(data);
  }, [data])

  useEffect(() => {
    if(state.submitReducer.results && submitted){
     reloadPage();
    }
   }, [state.submitReducer])

  const fetchUsers = async () => {
    try{
      const res = await axios.get('/users/roleType?&type=2&status=1&size=0&page=1', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setUsersList(res.data.data.content);
    }catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const onClickHandler = (value: boolean) => {
    if (selectedMenu !== false) {
      setSelectedMenu(false);
      return;
    }
    setSelectedMenu(value);
  }

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
      setSelectedMenu(false);
    });
  }

  const onFocusHandler = () => {
    clearTimeout(timeOutId);
  }

  const setDescription = async (value: string) =>  {
    const fieldsValues: any = Object.assign({}, signatoryData);
    fieldsValues["description"] = value;
    await setSignatoryData(fieldsValues);
  }

  const statusChange = async () => {
    let signatory;
    if(signatoryData.status){
      signatory = {
        ...signatoryData,
        status: 0
      }
    }
    else{
      signatory = {
        ...signatoryData,
        status: 1
      }
    }
    await setSignatoryData(signatory); 
  }

  const toggleUsers = (item: Record<any, any>) => {
    if(signatoryData.members.some((role: Record<any, any>) => role.id === item.id)){
      const newArr = signatoryData.members.filter( (record: Record<any,any>) => record.id !== item.id)
      console.log(newArr);
      let signatory = {
        ...signatoryData, 
        users: newArr
      }
      return setSignatoryData(signatory);
    }
    const signatory = {
      ...signatoryData,
      members: [...signatoryData.members, item]
    };
    setSignatoryData(signatory);
  }

  const removeUser = (item: Record<any, any>) => {
    const newArr = signatoryData.members.filter( (record: Record<any,any>) => record.id !== item.id)
    console.log(newArr);
    let signatory = {
      ...signatoryData, 
      members: newArr
    }
    return setSignatoryData(signatory);
  }

  const saveGroup = () => {
    return (e:  React.FormEvent) => {
      e.preventDefault()
      let members:any = [];
      signatoryData.members.map((user: Record<any, any>) => {
        members.push(user.id);
      })
      const values = {
        "description": signatoryData.description,
        "name": signatoryData.name,
        "status": signatoryData.status,
        "members": members
      }
      setSubmitted(true);
      dispatch(SubmitActionsPUT(`/signatory/${signatoryData.id}`, values));
    }
    
  }

  return (
    <SignatoryGroupDetailsStyle>
      <div className={`container-full signatory`}>
        <div className={`container-full signatory__desc`}>
          <HeaderText value={
            Object.keys(data).length > 0 ? data.name : 'Signatory Group'
          } />
        </div>
        {
          Object.keys(data).length > 0 ?
            <form onSubmit={saveGroup()}>
              <div className={`container-full signatory__content`}>
                <div className={`div-sm-12 signatory__content__group --description`}>
                  <HeaderText value='Description' />
                  {
                    signatoryData.status > 1 ? 
                    <Text value={signatoryData.description} /> :
                    <Input type="text" className="input" name="description" required value={signatoryData.description} onChange={(e) => setDescription(e.target.value)} />
                  }
                </div>
                <div className={`div-sm-12 signatory__content__group --created`}>
                  <HeaderText value='Created By' />
                  <Text value={signatoryData.createdBy} />
                </div>
                <div className={`div-sm-12 signatory__content__group --status`}>
                  <div className='signatory__content__group__status'>
                    <HeaderText value='Status' />
                    {
                      (signatoryData.status === 1) ? 
                      <div className='label --enabled'>
                        <Text value='Enabled' />
                      </div>: null
                    }
                    {
                      (signatoryData.status === 0) ? 
                      <div className='label --disabled'>
                        <Text value='Disabled' />
                      </div>: null
                    }
                    {
                      (signatoryData.status > 1) ? 
                      <div className='label --pending'>
                        <Text value='Pending' />
                      </div>: null
                    }
                  </div>
                  <div className={`signatory__content__group__switch ${
                    signatoryData.defaultRole ? 'disabled' : ''
                  }`}>
                    <input type="checkbox" className={`input 
                     ${
                      signatoryData.defaultRole ? 'disabled' : ''
                     }
                    `} checked={signatoryData.status} onChange={() => statusChange()} />
                    <div className={`signatory__content__group__switch__box`}>
                      <div className={`signatory__content__group__switch__box__ball`}></div>
                    </div>
                  </div>
                </div>
                <div className={`div-sm-12 signatory__content__group --users`}>
                  <HeaderText value='Users' />
                  <Text value='You can add or remove reviewers on to this group' />
                  <div className={`container-full signatory__content__group__users`}>
                    {
                      (signatoryData.members && signatoryData.members.length > 0 && signatoryData.members.map((user: Record<any, any>, key: string) => {
                        return(
                          <div className={`container-full signatory__content__group__users__item`} key={key}>
                            <div className={`signatory__content__group__users__item__number`}>
                              <Text value={key += 1} />
                            </div>
                            <div className={`signatory__content__group__users__item__name`}>
                              <Text value={`${user.username}`} />
                            </div>
                            <div className={`signatory__content__group__users__item__close`} onClick={() => removeUser(user)}>
                              <Image image={closeIcon} />
                            </div>
                          </div>
                        )
                      }))
                    }
                    <div className={`container-full signatory__content__group__users__item --add-user`} onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
                      <button type="button" className={`button --elipses`} onClick={() => onClickHandler(true)} aria-haspopup="true" aria-expanded={selectedMenu}>
                        <Text value='Select a User' />
                        <Image image={dropdown} />
                      </button>
                      {
                        selectedMenu !== false && (
                          <div className={`container-full more-info`}>
                            {
                              usersList.length > 0 ? 
                              usersList.map((user: Record<any, any>) => {
                                return(
                                  <div className={`container-full more-info__item
                                  ${
                                    (signatoryData.members.some((userData: Record<any, any>) => userData.id === user.id)) ? 'active' : ''
                                  }
                                  `} key={user.id}>
                                    <button type="button" onClick={() => toggleUsers(user)}>
                                      <Text value={`${user.firstName} ${user.lastName} (${user.username})`} />
                                    </button>
                                  </div>
                                )
                              }) : ''
                            }
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
              {
                (signatoryData.status <= 1) ? 
                <div className={`container-full signatory__action`}>
                  <Button type="button" value="Cancel" className={`button --cancel`} onClick={() => setData({})}/>
                  <Button type="submit" value="Save Group" className={`button --change ${
                   signatoryData.members && signatoryData.members.length > 0 ? '--active-btn' : ''
                  } ${
                    (state.authReducer.results.roles.includes('Authorizer') && state.authReducer.results.roles.length === 1)
                    || (state.authReducer.results.roles.includes('Audit') && state.authReducer.results.roles.length === 1) ? 'disabled' : ''
                  }`} isLoading={state.submitReducer.fetching}/>
                </div> : null
              }
            </form> : 
            <div className={`container-full center signatory__empty`}>
              <HeaderText value='Nothing to Show' className='header'/> 
              <Text value='There is nothing selected. Select an item from the list to see more details' className='text'/>
              <Text value='OR' className='or' />
              <div className='signatory__empty__action'>
                <Image image={icon} />
                <Text value='Create New Signatory Group' onClick={() => openAddModal()} className={(state.authReducer.results.roles.includes('Authorizer') && state.authReducer.results.roles.length === 1) 
                || (state.authReducer.results.roles.includes('Audit') && state.authReducer.results.roles.length === 1)? 'disabled' : ''} />
              </div>
            </div>
        }
      </div>
    </SignatoryGroupDetailsStyle>
  );
};

export default SignatoryGroupDetails;
