import React, { useState, useEffect } from 'react';
import ApprovalModalStyle from './ApprovalModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import dayjs from 'dayjs';

type ApprovalModalProps = {
  data: any,
  handleClose: Function,
}

const ApprovalModal = ({ handleClose, data }: ApprovalModalProps) => {
  const [record, setRecord]: any = useState();

  useEffect(() => {
    let recordBefore = JSON.parse(data.recordBefore);
    let recordAfter = JSON.parse(data.recordAfter)
    setRecord({ ...recordBefore, ...recordAfter });
    
  }, [])
  console.log(record)
  return (
    <ApprovalModalStyle className={`container-full`}>
      <div className={`container-full center modal-container`}>
        <div className={`modal-container__box`}>
          <div className={`container-full modal-container__box__header`}>
            <HeaderText value='Entity Approval' />
            <Image image={close} onClick={() => handleClose()} />
          </div>
          <div className={`container-full modal-container__box__content`}>
            <div className={`container-full modal-container__box__content__desc`}>
              <div className={`div-md-8 modal-container__box__content__desc__action`}>
                <HeaderText value={data.entityName.substring(1)} />
                <p> Action: <span>View</span></p>
              </div>
              <div className={`div-md-4 modal-container__box__content__desc__details`}>
                <div className={`container-full modal-container__box__content__desc__details__row`}>
                  <div className={`div-md-4 modal-container__box__content__desc__details__row__item`}>
                    <HeaderText value='Requester' className='label' />
                  </div>
                  <div className={`div-md-8 modal-container__box__content__desc__details__row__item`}>
                    <Text value={data.requester} className='value' />
                  </div>
                </div>
                <div className={`container-full modal-container__box__content__desc__details__row`}>
                  <div className={`div-md-4 modal-container__box__content__desc__details__row__item`}>
                    <HeaderText value='Date' className='label' />
                  </div>
                  <div className={`div-md-8 modal-container__box__content__desc__details__row__item`}>
                    <p className='value'> {dayjs(data.createdDate).format("DD/MM/YYYY")} <span>{dayjs(data.createdDate).format("HH:MM A")} </span></p>
                  </div>
                </div>
              </div>
            </div>
            <div className={`container-full modal-container__box__content__table`}>
              <div className={`modal-container__box__content__table__properties`}>
                <div className={`div-sm-12 modal-container__box__content__table__properties__header`}>
                  <Text value='Property' />
                </div>
                <div className={`container-full modal-container__box__content__table__properties__content`}>
                  {
                    data.entityName === ".User" ?
                      <>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Name' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Username' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Email' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Phone Number' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Description' />
                        </div>
                      </> : null
                  }
                  {
                    data.entityName === ".Merchant" ?
                      <>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Name' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='RC Number' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Email' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Phone Number' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Description' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Address' />
                        </div>
                      </> : null
                  }
                  {
                    data.entityName === ".SignatoryGroup" || data.entityName === ".Role" ?
                      <>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Name' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Description' />
                        </div>
                      </> : null
                  }
                  {
                    data.entityName === ".Partner" ?
                      <>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Name' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Description' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Email' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Phone' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Partner Code' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Settlement Account' />
                        </div>
                      </> : null
                  }
                  {
                    data.entityName === ".Issuer" ?
                      <>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Name' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Email' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Phone' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Issuer Code' />
                        </div>
                      </> : null
                  }
                  {
                    data.entityName === ".MerchantSourceAccount" ?
                      <>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Account Number' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Account Validated' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Fee Category ID' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Issuer Code' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Merchant ID' />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                          <Text value='Validated Name' />
                        </div>
                      </> : null
                  }

                  <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                    <Text value='Created By' />
                  </div>
                  <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                    <Text value='Last Modified By' />
                  </div>
                  <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                    <Text value='Status' />
                  </div>
                  <div className={`div-sm-12 modal-container__box__content__table__properties__content__row`}>
                    <Text value='Reviewers' />
                  </div>
                </div>
              </div>
              <div className={`modal-container__box__content__table__values`}>
                <div className={`div-sm-12 modal-container__box__content__table__values__header`}>
                  <Text value='Values' />
                </div>
                <div className={`container-full modal-container__box__content__table__values__content`}>
                  {
                    data.entityName === ".User" ?
                      <>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.firstName} ${record.lastName}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.username}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.email}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.phoneNumber}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? record.description : ''} />
                        </div>
                      </> : null
                  }
                  {
                    data.entityName === ".Merchant" ?
                      <>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.businessName}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.rcNumber}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.businessEmail}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.businessPhoneNumber}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.description}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.address}` : ''} />
                        </div>
                      </> : null
                  }
                  {
                    data.entityName === ".SignatoryGroup"  || data.entityName === ".Role"?
                      <>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.name}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.description}` : ''} />
                        </div>
                      </> : null
                  }
                  {
                    data.entityName === ".Partner" ?
                      <>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.name}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.description}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.contactEmail}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.contactPhone}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.partnerCode}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.settlementAccount}` : ''} />
                        </div>
                      </> : null
                  }
                  {
                    data.entityName === ".Issuer" ?
                      <>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.name}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.issuerEmail}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.contactNumber}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.issuerCode}` : ''} />
                        </div>
                      </> : null
                  }
                  {
                    data.entityName === ".MerchantSourceAccount" ?
                      <>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.accountNumber}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.accountValidated ? 'True' : 'False'}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.feeCategoryId}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.issuerCode}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.merchantId}` : ''} />
                        </div>
                        <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                          <Text value={record ? `${record.validatedName}` : ''} />
                        </div>
                      </> : null
                  }

                  <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                    <Text value={record ? record.createdBy : ''} />
                  </div>
                  <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                    <Text value={record ? record.lastModifiedBy : ''} />
                  </div>
                  <div className={`div-sm-12 modal-container__box__content__table__values__content__row`}>
                    {
                      data.status === 1 ?
                        <div className={`modal-container__box__content__table__values__content__row__label --enabled`}>
                          <Text value='Enabled' />
                        </div> : null
                    }
                    {
                      data.status > 1 ?
                        <div className={`modal-container__box__content__table__values__content__row__label --pending`}>
                          <Text value='Pending' />
                        </div> : null
                    }
                    {
                      data.status === 0 ?
                        <div className={`modal-container__box__content__table__values__content__row__label --disabled`}>
                          <Text value='Disabled' />
                        </div> : null
                    }
                  </div>
                  <div className={`container-full modal-container__box__content__table__values__content__row --reviewers`}>
                    <div className={`modal-container__box__content__table__values__content__row__label`}>
                      <Text value={data.approver} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ApprovalModalStyle>
  );
}

export default ApprovalModal;