import styled from "styled-components";

const ActivateMerchantModalStyle = styled.div`
    animation: show-bg 0.5s linear forwards;
    box-sizing:border-box;
    display:flex;
    height:100%;
    left:0;
    position:absolute;
    position:fixed;
    top: 0;
    width:100%;
    z-index:5000;
    @keyframes show-bg{
        0%{
            background:rgba(0, 0, 0, 0)
        }
        100%{
            background:rgba(37, 39, 51, 0.7);
        }
    }
    .modal-container{
        box-sizing:border-box;
        height:100vh;
    }
    .modal-container__box{
        -ms-flex: 0 0 645px;
        -webkit-flex: 0 0 645px;
        flex: 0 0 645px;
        max-width: 645px;
        background:#FFFFFF;
        border-radius:10px;
        box-sizing:border-box;
        padding:20px 20px 30px 20px;
    }
    .modal-container__box__header{
        box-sizing: border-box;
        padding: 0 0 0 10px;
        h2{
           color: #252733;
           font-family: 'Mulish';
           font-style: normal;
           font-weight: 700;
           font-size: 1.375em;
           line-height: 30px;
        }
        img{
            cursor: pointer;
        }
    }
    .modal-container__box__content{
        box-sizing: border-box;
        margin-top: 22px;
        padding: 0 10px 0 10px;
        p{
            color: rgba(37, 39, 51, 0.7);
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            span{
                color: rgb(37, 39, 51);
                font-family: 'Mulish';
            }
            &:nth-of-type(1){
                margin-bottom: 12px;
            }
        }
    }
    .modal-container__box__action{
        justify-content: flex-end;
        margin-top: 37px;
        .button{
            border-radius: 8px;
            font-family: 'Mulish';
            font-weight: 600;
            font-size: 14px;
            height: 40px;
            margin-bottom: 0;
        }
        .--cancel{
            background: #F5F5F5;
            color: #252733;
            font-weight: 500;
            margin-right: 20px;
            width: 96px;
        }
        .--activate{
            background: #3751FF;
            box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
            color: white;
            width: 159px;
        }
        .--active-btn{
            background: #3751FF;
            pointer-events: unset;
        }
    }
    


    
`
export default ActivateMerchantModalStyle;