import styled from "styled-components";

const ScaledBandDetailsModalStyle = styled.div`
    animation: show-bg 0.5s linear forwards;
    box-sizing:border-box;
    display:flex;
    height:100%;
    left:0;
    position:absolute;
    position:fixed;
    top: 0;
    width:100%;
    z-index:5000;
    @keyframes show-bg{
        0%{
            background:rgba(0, 0, 0, 0)
        }
        100%{
            background:rgba(37, 39, 51, 0.7);
        }
    }
    .modal-container{
        box-sizing:border-box;
        height:100vh;
    }
    .modal-container__box{
        -ms-flex: 0 0 640px;
        -webkit-flex: 0 0 640px;
        flex: 0 0 640px;
        max-width: 640px;
        background: #FAFAFA;
        border-radius:10px;
        box-sizing:border-box;
        max-height: 700px;
        overflow: auto;
        padding: 15px;
    }
    .modal-container__box__header{
        box-sizing: border-box;
        padding-left: 10px;
        h2{
           color: #252733;
           font-family: 'Mulish';
           font-style: normal;
           font-weight: 700;
           font-size: 1.375em;
           line-height: 30px;
        }
        img{
            cursor: pointer;
        }
    }
    .modal-container__box__content{
        background: #F9FAFB;
        border: 1px solid #F0F1F7;
        border-radius: 5px;
        box-sizing: border-box;
        margin-top: 15px;
        padding: 10px;
    }
    .modal-container__box__content__details__title{
        margin-bottom: 17px;
        h2{
            color: #130F26;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
        }
    }
    .modal-container__box__content__details__content__row{
        margin-bottom: 12px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .modal-container__box__content__details__content__row__item{
        margin-bottom: 0;
        .title{
            color: rgba(37, 39, 51, 0.7);
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 20px;
        }
    }
    .modal-container__box__content__details__content__row__item__label{
        background: #E7E7E7;
        border-radius: 5px;
        box-sizing: border-box;
        display: inline-block;
        mix-blend-mode: multiply;
        padding: 2px 6px;
        .label{
            color: #6A6A73;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            font-feature-settings: 'salt' on;
        }
    }
    .modal-container__box__content__bands{
        background: #F9FAFB;
        border-radius: 5px;
        box-sizing: border-box;
        margin-top: 8px;
        padding: 10px;
    }
    .modal-container__box__content__bands__item{
        background: #F9FAFB;
        border: 1px solid #F0F1F7;
        border-radius: 5px;
        box-sizing: border-box;
        margin-bottom: 10px;
        mix-blend-mode: multiply;
        padding: 20px 20px 25px 20px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .modal-container__box__content__bands__item__desc{
        h2{
            color: #130F26;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
        }
    }
    .modal-container__box__content__bands__item__group{
        margin-top: 19px;
    }
    .modal-container__box__content__bands__item__group__row{
        margin-bottom: 14px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .modal-container__box__content__bands__item__group__row__item{
        margin-bottom: 0;
        p{
            color: rgba(37, 39, 51, 0.7);
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 20px;
        }
        h2{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
        }
    }
    .modal-container__box__content__bands__item__group__row__item__label{
        background: #E7E7E7;
        border-radius: 5px;
        box-sizing: border-box;
        display: inline-block;
        mix-blend-mode: multiply;
        padding: 2px 6px;
        p{
            color: #6A6A73;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            font-feature-settings: 'salt' on;
        }
    }
`
export default ScaledBandDetailsModalStyle;