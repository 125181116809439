export const INFO = 'INFO';
export const NHIS = 'NHIS';
export const NHF = 'NHF';
export const PENSION = 'PENSION';
export const PAYE = 'PAYE';
export const SALARY = 'SALARY';
export const DEDUCTIONS = 'DEDUCTIONS';
export const STEP_CLEAR = 'STEP_CLEAR';

export interface SetInfo {
    type: typeof INFO,
    data: object
}

export interface SetNHIS {
    type: typeof NHIS,
    data: object
}

export interface SetNHF {
    type: typeof NHF,
    data: object
}

export interface SetPension{
    type: typeof PENSION,
    data: object
}

export interface SetPAYE{
    type: typeof PAYE,
    data: object
}

export interface SetSalary{
    type: typeof SALARY,
    data: object
}

export interface SetDeductions{
    type: typeof DEDUCTIONS,
    data: object
}

export interface StepClear {
    type: typeof STEP_CLEAR
}

export type FeeDispatchTypes = SetInfo | SetNHIS | SetNHF | SetPension | SetPAYE | SetSalary | SetDeductions | StepClear