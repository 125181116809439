export const EXPORT = 'EXPORT';
export const EXPORT_ALL = 'EXPORT_ALL';
export const EXPORT_CLEAR = 'EXPORT_CLEAR';
export const EXPORT_CLEAR_ALL = 'EXPORT_CLEAR_ALL';

export interface Export {
    type: typeof EXPORT,
    data: object
}

export interface ExportClear {
    type: typeof EXPORT_CLEAR,
}

export interface ExportAll {
    type: typeof EXPORT_ALL,
    response: {
        data: any
    }
}

export interface ExportClearAll {
    type: typeof EXPORT_CLEAR_ALL,
}

export type ExportDispatchTypes = Export | ExportClear | ExportAll | ExportClearAll