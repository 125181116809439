import styled from "styled-components";

const ReportDownloadStyle = styled.div`
    position: relative;
    width: 100%;

    .report-download{
        .button{
            background: #3751FF;
            border-radius: 8px;
            box-shadow: 0px 4px 12px 0px rgba(55, 81, 255, 0.24);
            box-sizing: border-box;
            color: white;
            cursor: pointer;
            font-family: Mulish;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            height: 34px;
            margin-right: 20px;
            padding: 0 12px;
        }
    }

    .summary{
        background: #FFFFFF;
        box-shadow: 0px 15px 44px rgba(10, 18, 73, 0.15);
        box-sizing: border-box;
        border-radius: 10px;
        margin-right: 18px;
        margin-top: 36px;
        padding: 14.5px 12px 12px 15.33px;
        position: absolute;
        right: 0;
        top: 0;
        width: 500px;
        z-index: 4000;
    }

    .summary__form__item{
        margin-bottom: 0;
        
    }
    .summary__form__item__desc{
        justify-content: normal;
        p{
          all: unset;
          color: #6D6F79;
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          line-height: 15px;
        }
      }
      .summary__form__item__desc__checkbox{
        margin-right: 7.33px;
        input[type='checkbox'] {
          -moz-appearance: none;
          -webkit-appearance: none;
          -o-appearance: none;
          background: #FFFFFF;
          border-radius: 3px;
          height: 13.33px;
          outline: 0.52531px solid #B5BABD !important;
          position: relative;
          transition: all 0.2s linear;
          width: 13.33px;
          & + img {
            margin-left: -11px;
            margin-top: 4px;
            pointer-events: none;
            position: absolute;
            top: 0;
            transition: all 0.2s linear;
            transform: scale(0);
            transform-origin: bottom left;
          }
          &:checked {
            background: #3751FF;
            outline: 2px solid #3751FF !important;
          }
          &:checked + img {
            transform: scale(1);
          }
        }
      }
      .summary__form__item__input{
        box-sizing: border-box;
        padding: 5px 0 0 18.67px;
        .input{
          background: #FAFAFA;
          border: none;
          border-radius: 8px;
          box-sizing: border-box;
          color: rgb(109, 111, 121);
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          height: 34px;
          padding-left: 10px;
          position: relative;
          width: 100%;
          &::placeholder{
            color: rgba(109, 111, 121, 0.4);
          }
        }
        select{
          appearance: none;
          background: transparent;
          color: rgb(109, 111, 121);
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          margin-top: 9px;
          width: 100%;
        }
        select:required:invalid {
          color: rgba(109, 111, 121, 0.4);
        }
        img{
          margin-top: 15.33px;
          margin-right: 6.67px;
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      .summary__action{
        justify-content: flex-end;
        margin-top: 20px;
        button{
            background: #3751FF;
            border-radius: 8px;
            font-family: 'Mulish';
            font-weight: 600;
            font-size: 14px;
            height: 40px;
            margin: 0;
            margin-bottom: 0;
            width: 100px;
        }
      }
    
`
export default ReportDownloadStyle;