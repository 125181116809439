import React, { useState, useEffect } from 'react';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import AddSourceAccountStyle from './AddSourceAccountFormStyle';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { signIn } from '../../../redux/actions/AuthAction';
import { useNavigate } from "react-router-dom";
import caret from '../../../assets/images/caret-down-select.svg';
import addIcon from '../../../assets/images/add-blue.svg';
import eyesClose from '../../../assets/images/eyes-pwd-close.svg';
import eyes from '../../../assets/images/eyes-pwd.svg';
import { stepOne } from '../../../redux/actions/AddSourcerAccountStep';
import { alertActions } from '../../../redux/actions/AlertActions';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';

type AddSourceAccountProps = {
  handleClose: Function,
  setStep: Function,
}


const AddSourceAccount = ({ handleClose, setStep }: AddSourceAccountProps) => {
  const [fields, setFields] = useState({
    bank: '',
    accountNumber: '',
    feeCategory: '',
    processor: '',
    terminalID: '',
    encryptionKey: ''
  })
  const [checking, setChecking] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [accountError, setAccountError] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [feeCategory, setFeeCategory] = useState([]);
  const [processors, setProcessors] = useState([]);
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  // const navigate = useNavigate();

  useEffect(() => {
    if (state.addSourceAccountStepReducer.results.stepOne && Object.keys(state.addSourceAccountStepReducer.results.stepOne).length > 0) {
      let fieldValue = {
        bank: state.addSourceAccountStepReducer.results.stepOne.bank,
        accountNumber: state.addSourceAccountStepReducer.results.stepOne.accountNumber,
        feeCategory: state.addSourceAccountStepReducer.results.stepOne.feeCategory,
        processor: state.addSourceAccountStepReducer.results.stepOne.processor,
        terminalID: state.addSourceAccountStepReducer.results.stepOne.terminalID,
        encryptionKey: state.addSourceAccountStepReducer.results.stepOne.encryptionKey
      }
      setFields(fieldValue);
    }
  }, [])

  useEffect(() => {
    generatePageContent();
    fetchFeeCategory();
    fetchProcessors();
  }, [])

  const generatePageContent = async () => {
    try {
      const res = await axios.get(`/payroll/configuration/issuers?size=0&page=1`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setBankList(res.data.data.content);
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const fetchFeeCategory = async () => {
    try {
      const res = await axios.get(`/fee/category?${window.location.href.split("?")[1]}&status=1&size=0&page=1`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setFeeCategory(res.data.data.content);
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const fetchProcessors = async () => {
    try {
      const res = await axios.get(`/processors?size=0&page=1`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setProcessors(res.data.data);
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const inputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues[name] = value;
    await setFields(fieldsValues);
  };

  const toggleState = () => {
    return (evt: any) => {
      if (evt.target.parentElement.firstChild.type === 'password') {
        evt.target.src = eyes;
        return (evt.target.parentElement.firstChild.type = 'text');
      } else {
        evt.target.src = eyesClose;
        return (evt.target.parentElement.firstChild.type = 'password');
      }
    };
  }

  const onFormSubmit = () => {
    return async (evt: React.FormEvent) => {
      evt.preventDefault();
      await dispatch(stepOne({
        ...fields,
        accountName: accountName
      }));
      setStep();
    }
  }

  const clear = () => {
    setFields({
      bank: '',
      accountNumber: '',
      feeCategory: '',
      processor: '',
      terminalID: '',
      encryptionKey: ''
    })
    dispatch(stepOne({}));
  }

  const getAccountName = async () => {
    const payload = {
      "accountNumber": fields.accountNumber,
      "issuerCode": fields.bank.split("+")[0],
      "entity": 1,
    }
    try {
      setChecking(true);
      const res = await axios.post(`/account/enquiry`, payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setChecking(false);
      if (res.data.data.isValidated === 0) {
        setAccountError(true);
      }
      else {
        setAccountError(false);
      }
      setAccountName(res.data.data.validatedName);
    } catch (error: any) {
      setChecking(false);
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  return (
    <AddSourceAccountStyle>
      <div className={`container-full form`}>
        <form onSubmit={onFormSubmit()}>
          <div className={`container-full form__content`}>
            <div className={`container-full form__content__desc`}>
              <Text value='Set up new card to collect payments.' />
            </div>
            <div className={`container-full form__content__group`}>
              <div className={`container-full form__content__group__item`}>
                <div className={`div-md-6`}>
                  <p> Bank Name <span> *</span></p>
                  <div className='input'>
                    <select required
                      name="bank"
                      onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                      value={fields.bank}
                    >
                      <option value="" hidden> Select Bank </option>
                      {
                        (bankList.length > 0 && bankList.map((bank: Record<any, any>) => {
                          return (<option value={`${bank.issuerCode}+${bank.name}`} key={bank.id}> {bank.name} </option>)
                        }))
                      }
                    </select>
                    <Image image={caret} />
                  </div>
                  {
                    (fields.bank !== "") ?
                      <div className='form__content__group__item__label' onClick={() => getAccountName()}>
                        <Text value='Get Account Name' />
                      </div> : null
                  }
                </div>
                <div className={`div-md-6`}>
                  <p> Account Number <span> *</span></p>
                  <Input type='text' className="input" placeholder="Set Account Number" required
                    name="accountNumber" value={fields.accountNumber} onChange={(e) => inputChange(e.target.name, e.target.value)}
                  />
                  {
                    (fields.bank !== "") ?
                      <>
                        {
                          (!accountError && accountName !== "" && !checking) ? <h4 className="info"> {accountName} </h4> : null
                        }
                        {
                          (accountError && !checking) ? <h4 className='error'> Account Name Retreival Error </h4> : null
                        }
                      </> : null
                  }
                </div>
              </div>
              <div className={`container-full form__content__group__item`}>
                <p> Fee Category <span> *</span></p>
                <div className='input'>
                  <select required
                    name="feeCategory"
                    onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                    value={fields.feeCategory}
                  >
                    <option value="" hidden> Select Fee Category </option>
                    {
                      (feeCategory.length > 0 && feeCategory.map((feeCategory: Record<any, any>) => {
                        return (<option value={`${feeCategory.id}+${feeCategory.name}`} key={feeCategory.id}> {feeCategory.name} </option>)
                      }))
                    }
                  </select>
                  <Image image={caret} />
                </div>
              </div>
            </div>
          </div>
          <div className={`container-full form__processor`}>
            <div className={`container-full form__processor__desc`}>
              <HeaderText value='Processor Connection Details' />
            </div>
            <div className={`container-full form__processor__group`}>
              <div className={`container-full form__processor__group__item`}>
                <p> Processor <span> *</span></p>
                <div className='input'>
                  <select required
                    name="processor"
                    onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                    value={fields.processor}
                  >
                    <option value="" hidden> Select a Processor </option>
                    {
                      (processors.length > 0 && processors.map((processor: Record<any, any>) => {
                        return (<option value={processor.id} key={processor.id}> {processor.name} </option>)
                      }))
                    }
                  </select>
                  <Image image={caret} />
                </div>
              </div>
              {
                fields.processor === "1" ?
                  <div className={`container-full form__processor__group__item`}>
                    <div className={`div-md-6`}>
                      <p> Terminal ID <span> *</span></p>
                      <Input type='text' className="input" placeholder="Enter Terminal ID" required
                        name="terminalID" value={fields.terminalID} onChange={(e) => inputChange(e.target.name, e.target.value)}
                      />
                    </div>
                    <div className={`div-md-6`}>
                      <p> Encryption Key <span> *</span></p>
                      <div className='password'>
                        <Input type='password' className="input" placeholder="Enter Encryption Key" required
                          name="encryptionKey" value={fields.encryptionKey} onChange={(e) => inputChange(e.target.name, e.target.value)}
                          onCopy={(event: React.FormEvent) => { event.preventDefault() }}
                          onCut={(event: React.FormEvent) => { event.preventDefault() }}
                          onPaste={(event: React.FormEvent) => { event.preventDefault() }}
                        />
                        <img
                          src={eyes}
                          onClick={toggleState()}
                        />
                      </div>
                    </div>
                  </div> : null
              }
            </div>
          </div>
          <div className={`container-full center form__action`}>
            <Button type="button" value="Clear" className={`button --cancel`} onClick={() => clear()} />
            <Button type="submit" value="Continue" className={`button --change --active-btn`} isLoading={state.submitReducer.fetching} />
          </div>
        </form>
      </div>
    </AddSourceAccountStyle>
  );
};

export default AddSourceAccount;
