import React, { useState } from 'react';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import GlobalParametersTableStyle from './GlobalParametersTableStyle';
import nike from '../../../assets/images/nike.svg';
import elipses from '../../../assets/images/elipses.svg';
import userImage from '../../../assets/images/user-male.svg';
import Pagination from '../Pagination/Pagination';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { exportData } from '../../../redux/actions/ExportAction';
import UpdateGlobalParameterModal from '../UpdateGlobalParameterModal/UpdateGlobalParameterModal';
import { useNavigate } from "react-router-dom";

type UserTableProps = {
  data: any,
  pagination: number,
  setPagination: Function,
  changePage: Function,
  page: number,
  reloadPage: Function
}

const GlobalParametersTable = ({ data, pagination, setPagination, changePage, page, reloadPage }: UserTableProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  let timeOutId: any = null;
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [toggleUpdateGlobalParameterModal, setToggleUpdateGlobalParameterModal] = useState(false);
  const [paramData, setParamData] = useState({});

  const onClickHandler = (value: boolean, id: string) => {
    if (selectedMenu !== false) {
      setSelectedMenu(false);
      return;
    }
    setSelectedMenu(value);
    setSelectedId(id)
  }

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
      setSelectedMenu(false);
    });
  }

  const onFocusHandler = () => {
    clearTimeout(timeOutId);
  }

  const showUpdateGlobalParameterModal = () => {
    let paramData = data.data.content.filter((data: Record<any, any>) => data.id === selectedId);
    let value = {
      ...paramData[0]
    }
    console.log(value)
    setParamData(value);
    setToggleUpdateGlobalParameterModal(true);
  }

  const hideUpdateGlobalParameterModal = () => {
    setToggleUpdateGlobalParameterModal(false);
  }

  const renderUpdateGlobalParameterModal = () => {
    if (toggleUpdateGlobalParameterModal) {
      return (
        <UpdateGlobalParameterModal handleClose={hideUpdateGlobalParameterModal} reloadPage={reloadPage} data={paramData} />
      )
    }
  }

  return (
    <>
      {renderUpdateGlobalParameterModal()}
      <GlobalParametersTableStyle>
        <div className={`container-full`}>
          <table>
            <thead>
              <tr className={`table-header`}>
                <th>
                  <Text value="Name" className={`table-title`} />
                </th>
                <th>
                  <Text value="Description" className={`table-title`} />
                </th>
                <th>
                  <Text value="Value" className={`table-title`} />
                </th>
                <th>
                  <Text value="More" className={`table-title`} />
                </th>
              </tr>
            </thead>
            <tbody className={`table-body`}>
              {
                (data.data.content !== undefined && data.data.content.length > 0) ?
                  data.data.content.map((param: Record<any, any>, key: string) => {
                    return (
                      <tr className={`table-row`} key={key}>
                        <td>
                          <Text value={param.name} className='label' />
                        </td>
                        <td>
                          <Text value={param.description} className="label" />
                        </td>
                        <td>
                          {
                            param.name && param.name.toLowerCase() === "switchit pin" || param.name && param.name.toLowerCase() === "switchit encryption key" ?
                              <Text value={`.....`} className="label --pin" /> :
                              param.name && param.name.toLowerCase() === "process salary" ?
                              <Text value={param.value === "0" ? 'False' : 'True'} className="label" /> :
                              <Text value={`${param.value} ${param.name==="idle_time_out" ? 'mins' : ''}`} className="label" />
                          }
                        </td>
                        <td>
                          <div className={`container-full`} onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
                            <button className={`button --elipses`} onClick={() => onClickHandler(true, param.id)} aria-haspopup="true" aria-expanded={selectedMenu}>
                              <Image image={elipses} />
                            </button>
                            {
                              selectedMenu !== false && selectedId === param.id && (
                                <div className={`container-full more-info`}>
                                  <div className={`container-full more-info__item ${(state.authReducer.results.roles.includes('Authorizer') && state.authReducer.results.roles.length === 1)
                                      || (state.authReducer.results.roles.includes('Audit') && state.authReducer.results.roles.length === 1) ? 'disabled' : ''
                                    }`}>
                                    <button onClick={() => showUpdateGlobalParameterModal()}>
                                      <Text value='Edit' />
                                    </button>
                                  </div>
                                </div>
                              )
                            }
                          </div>
                        </td>
                      </tr>
                    )
                  }) : null
              }
            </tbody>
          </table>
        </div>
        <div className={`container-full pagination`}>
          <Pagination pagination={pagination} changePage={changePage} setPagination={setPagination} page={page} />
        </div>
      </GlobalParametersTableStyle>
    </>
  );
};

export default GlobalParametersTable;
