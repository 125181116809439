import React, {useState, useEffect} from 'react';
import FeeReportModalStyle from './FeeReportModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import Input from '../../atoms/Input/Input';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {SubmitActionsPOST} from '../../../redux/actions/SubmitAction';
import { alertActions } from '../../../redux/actions/AlertActions';
import {appInstance as axios} from '../../../axios';
import Cookies from 'js-cookie';
import closeIcon from '../../../assets/images/close-icon-round.svg';
import dropdown from '../../../assets/images/dropdown.svg';


type FeeReportModalProps = {
  data: any;
  handleClose: Function
}

const FeeReportModal = ({handleClose, data}: FeeReportModalProps) => {
    const dispatch: TypedDispatch = useDispatch();
    const state = useSelector( (state:RootStore) => state);
  
    return (
        <FeeReportModalStyle className={`container-full`}>
            <div className={`container-full center modal-container`}>
                <div className={`modal-container__box`}>
                    <div className={`container-full modal-container__box__header`}>
                     <HeaderText value='Fee Breakdown' />
                     <Image image={close} onClick={() => handleClose()}/>
                    </div>
                    <div className={`container-full modal-container__box__content`}>
                      <div className='container-full modal-container__box__content__row'>
                        <div className='div-md-7 modal-container__box__content__row__item'>
                          <HeaderText value='Total Salary Amount' />
                        </div>
                        <div className='div-md-5 modal-container__box__content__row__item'>
                          <Text value={`₦${data.totalSalaryAmount}`} />
                        </div>
                      </div>
                      <div className='container-full modal-container__box__content__row'>
                        <div className='div-md-7 modal-container__box__content__row__item'>
                          <HeaderText value='Total Salary Fee Charged' />
                        </div>
                        <div className='div-md-5 modal-container__box__content__row__item'>
                          <Text value={`₦${data.totalSalaryFee}`} />
                        </div>
                      </div>
                      <div className='container-full modal-container__box__content__row'>
                        <div className='div-md-7 modal-container__box__content__row__item'>
                          <HeaderText value='Total PAYE Amount' />
                        </div>
                        <div className='div-md-5 modal-container__box__content__row__item'>
                          <Text value={`₦${data.totalPayeAmount}`} />
                        </div>
                      </div>
                      <div className='container-full modal-container__box__content__row'>
                        <div className='div-md-7 modal-container__box__content__row__item'>
                          <HeaderText value='Total PAYE Fee Charged' />
                        </div>
                        <div className='div-md-5 modal-container__box__content__row__item'>
                          <Text value={`₦${data.totalPayeFee}`} />
                        </div>
                      </div>
                      <div className='container-full modal-container__box__content__row'>
                        <div className='div-md-7 modal-container__box__content__row__item'>
                          <HeaderText value='Total PFA Amount' />
                        </div>
                        <div className='div-md-5 modal-container__box__content__row__item'>
                          <Text value={`₦${data.totalPensionAmount}`} />
                        </div>
                      </div>
                      <div className='container-full modal-container__box__content__row'>
                        <div className='div-md-7 modal-container__box__content__row__item'>
                          <HeaderText value='Total PFA Fee Charged' />
                        </div>
                        <div className='div-md-5 modal-container__box__content__row__item'>
                          <Text value={`₦${data.totalPensionFee}`} />
                        </div>
                      </div>
                      <div className='container-full modal-container__box__content__row'>
                        <div className='div-md-7 modal-container__box__content__row__item'>
                          <HeaderText value='Total NHF Amount' />
                        </div>
                        <div className='div-md-5 modal-container__box__content__row__item'>
                          <Text value={`₦${data.totalNhfAmount}`} />
                        </div>
                      </div>
                      <div className='container-full modal-container__box__content__row'>
                        <div className='div-md-7 modal-container__box__content__row__item'>
                          <HeaderText value='Total NHF Fee Charged' />
                        </div>
                        <div className='div-md-5 modal-container__box__content__row__item'>
                          <Text value={`₦${data.totalNhfFee}`} />
                        </div>
                      </div>
                      <div className='container-full modal-container__box__content__row'>
                        <div className='div-md-7 modal-container__box__content__row__item'>
                          <HeaderText value='Total HMO Amount' />
                        </div>
                        <div className='div-md-5 modal-container__box__content__row__item'>
                          <Text value={`₦${data.totalNhisAmount}`} />
                        </div>
                      </div>
                      <div className='container-full modal-container__box__content__row'>
                        <div className='div-md-7 modal-container__box__content__row__item'>
                          <HeaderText value='Total HMO Fee Charged' />
                        </div>
                        <div className='div-md-5 modal-container__box__content__row__item'>
                          <Text value={`₦${data.totalNhisFee}`} />
                        </div>
                      </div>
                      <div className='container-full modal-container__box__content__row'>
                        <div className='div-md-7 modal-container__box__content__row__item'>
                          <HeaderText value='Total Deduction Amount' />
                        </div>
                        <div className='div-md-5 modal-container__box__content__row__item'>
                          <Text value={`₦${data.totalDedAmount}`} />
                        </div>
                      </div>
                      <div className='container-full modal-container__box__content__row'>
                        <div className='div-md-7 modal-container__box__content__row__item'>
                          <HeaderText value='Total Deduction Fee' />
                        </div>
                        <div className='div-md-5 modal-container__box__content__row__item'>
                          <Text value={`₦${data.totalDedFee}`} />
                        </div>
                      </div>

                    </div>
                </div>  
            </div>
        </FeeReportModalStyle>
    );
}

export default FeeReportModal;