import React, { useState, useEffect } from 'react';
import MerchantSourceAccountTemplateStyle from './MerchantSourceAccountTemplateStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import Input from '../../atoms/Input/Input';
import angleLeft from '../../../assets/images/angle-small-left.svg';
import merchantProfile from '../../../assets/images/merchant-profile.svg';
import MerchantNav from '../../organisms/MerchantNav/MerchantNav';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { DashboardActions } from '../../../redux/actions/DashboardAction';
import { exportAll, exportClear } from '../../../redux/actions/ExportAction';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import exportIcon from '../../../assets/images/download-icon.svg';
import { alertActions } from '../../../redux/actions/AlertActions';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';
import cogoToast from "cogo-toast";
import Button from '../../atoms/Button/Button';
import plusIcon from '../../../assets/images/plus-icon-white.svg';
import filterIcon from '../../../assets/images/filter-icon.svg';
import caret from '../../../assets/images/caret-filter-select.svg';
import nike from '../../../assets/images/nike.svg';
import plus from '../../../assets/images/plus-icon.svg';
import MerchantMappedAccounts from '../../organisms/MerchantMappedAccounts/MerchantMappedAccounts';
import AddSourceAccountModal from '../../organisms/AddSourceAccountModal/AddSourceAccountModal';

const MerchantSourceAccountTemplate = () => {
  let timeOutId: any = null;
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [merchantData, setMerchantData]: any = useState({});
  const [imageUrl, setFileimageUrl] = useState<string>();
  const [filterValues, setFilterValues] = useState<any>({});
  const [fields, setFields] = useState({
    status: ''
  });
  const [toggleAddSourceAccountModal, setToggleAddSourceAccountModal] = useState(false);
  const dispatch: TypedDispatch = useDispatch();
  const state: any = useSelector((state: RootStore) => state);

  useEffect(() => {
    dispatch(exportAll(`/account?${window.location.href.split("?")[1]}&size=0&page=1`));
  }, [])

  useEffect(() => {
    dispatch(exportClear())
    generatePageContent();
    console.log(state);
  }, [size, page, reloadPage])

  useEffect(() => {
    if (merchantData && merchantData.companyLogoUri) {
      openImageFile(merchantData.companyLogoUri);
    }
  }, [merchantData])

  const generatePageContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/account?${window.location.href.split("?")[1]}&size=${size}&page=${page}${search !== "" ? `&name=${search}` : ''}`));
    setFetchLoading(false);

    try {
      const res = await axios.get(`/merchant?${window.location.href.split("?")[1]}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setMerchantData(res.data.data.content[0]);
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const onClickHandler = (value: boolean) => {
    if (selectedMenu !== false) {
      setSelectedMenu(false);
      return;
    }
    setSelectedMenu(value);
  }

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
      setSelectedMenu(false);
    });
  }

  const onFocusHandler = () => {
    clearTimeout(timeOutId);
  }

  const openImageFile = async (fileUri: string) => {
    const apiDefinition = () => {
      return axios.get(`${fileUri.substring(4)}`,
        {
          responseType: "blob",
          headers: {
            "Authorization": `Bearer ${Cookies.get("userToken")}`,
          }
        }
      );
    };
    try {
      setFetchLoading(true);
      const { data } = await apiDefinition();
      const url = URL.createObjectURL(new Blob([data]));
      setFileimageUrl(url);
      setFetchLoading(false);
    } catch (error) {
      cogoToast.error("Error downloading file", { position: "top-right" })
    }
  }

  const renderFetchLoading = () => {
    if (fetchLoading) {
      return (
        <FetchLoading />
      )
    }
  }

  const changePage = (value: number) => {
    setPage(value);
  }

  const changeSize = (value: number) => {
    setSize(value);
  }

  const exportData = () => {
    return {
      fileData: () => {
        let accounts = state.exportReducer.results.data.map((account: Record<any, any>) => {
          return (
            {
              Name: account.issuer ? account.issuer.issName : null,
              AccountNumber: account.accountNumber,
              Issuer: account.issuer ? account.issuer.issName : null,
              FeeCategory: account.feeCategory ? account.feeCategory.catName : null,
              DefaultProcessor: account.mappedProcessors && account.mappedProcessors.length > 0 ? account.mappedProcessors[0].processorName : null,
              DateCreated: dayjs(account.createdDate).format('MM/DD/YYYY'),
            }
          )
        })
        let data = [...accounts];
        return data
      },
      fileHeaders: () => {
        let headers = [
          { label: "Name", key: "Name" },
          { label: "Account Number", key: "AccountNumber" },
          { label: "Issuer", key: "Issuer" },
          { label: "Fee Category", key: "FeeCategory" },
          { label: "Default Processor", key: "DefaultProcessor" },
          { label: "Date Created", key: "DateCreated" },
        ];
        return headers;
      }
    }
  }

  const exportDataAll = () => {
    return {
      fileData: () => {
        let accounts = state.exportReducer.allResults.data.map((account: Record<any, any>) => {
          return (
            {
              Name: account.issuer ? account.issuer.issName : null,
              AccountNumber: account.accountNumber,
              Issuer: account.issuer ? account.issuer.issName : null,
              FeeCategory: account.feeCategory ? account.feeCategory.catName : null,
              DefaultProcessor: account.mappedProcessors && account.mappedProcessors.length > 0 ? account.mappedProcessors[0].processorName : null,
              DateCreated: dayjs(account.createdDate).format('MM/DD/YYYY'),
            }
          )
        })
        let data = [...accounts];
        return data
      },
      fileHeaders: () => {
        let headers = [
          { label: "Name", key: "Name" },
          { label: "Account Number", key: "AccountNumber" },
          { label: "Issuer", key: "Issuer" },
          { label: "Fee Category", key: "FeeCategory" },
          { label: "Default Processor", key: "DefaultProcessor" },
          { label: "Date Created", key: "DateCreated" },
        ];
        return headers;
      }
    }
  }

  const refreshTable = () => {
    if (reloadPage) {
      return setReloadPage(false);
    }
    setReloadPage(true);
  }

  const changeFilterValue = async (event: any, name: string, value: string) => {
    if (event.target.checked) {
      const filterValuesObj: any = Object.assign({}, filterValues);
      filterValuesObj[name] = value;
      await setFilterValues(filterValuesObj);
    }
    else {
      const filterValuesObj: any = Object.assign({}, filterValues);
      delete filterValuesObj[name]
      await setFilterValues(filterValuesObj);
    }
  }

  const inputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues[name] = value;
    await setFields(fieldsValues);
  };

  const fetchFilteredContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/account?${window.location.href.split("?")[1]}&size=${size}&page=${page}${filterValues.status && fields.status !== '' ? `&status=${fields.status}` : ''}`));
    setFetchLoading(false);
    setSelectedMenu(false);
  }

  const showAddSourceAccountModal = () => {
    setToggleAddSourceAccountModal(true);
  }

  const hideAddSourceAccountModal = () => {
    setToggleAddSourceAccountModal(false);
  }

  const renderAddSourceAccountModal = () => {
    if (toggleAddSourceAccountModal) {
      return (
        <AddSourceAccountModal handleClose={hideAddSourceAccountModal} reloadPage={refreshTable} />
      )
    }
  }

  return (
    <>
      {renderFetchLoading()}
      {renderAddSourceAccountModal()}
      <MerchantSourceAccountTemplateStyle>
        <div className={`container-full merchants`}>
          <div className={`container-full merchants__back`}>
            <Link to="/merchants">
              <Image image={angleLeft} />
              <Text value='Back to Merchants' />
            </Link>
          </div>
          <div className={`container-full merchants__desc`}>
            <HeaderText value='Merchants' />
          </div>
          <div className={`container-full merchants__top`}>
            <div className={`container-full merchants__top__desc`}>
              <div className={`merchants__top__desc__name`}>
                {
                  (merchantData && imageUrl) ?
                    <Image image={imageUrl} /> : <Image image={merchantProfile} />
                }
                <div className={`merchants__top__desc__name__group`}>
                  <HeaderText value={
                    (merchantData.businessName) ? merchantData.businessName : ''
                  } />
                  <Text value={
                    (merchantData.businessEmail) ? merchantData.businessEmail : ''
                  } />
                </div>
              </div>
            </div>
            <div className={`container-full merchants__top__nav`}>
              <div>
                <MerchantNav />
              </div>
            </div>
          </div>
          <div className={`container-full merchants__bottom`}>
            <div className={`container-full merchants__bottom__desc`}>
              <HeaderText value='Mapped Cards' />
            </div>
            {
              (state.dashboardReducer.results !== null && state.dashboardReducer.results.data.content.length > 0) ?
                <div className={`container-full merchants__bottom__content`}>
                  <div className={`container-full merchants__bottom__content__action`}>
                    <Input className={`input`} type="text" value={search} placeholder="Search by Account"
                      onChange={(event) => setSearch(event.target.value)}
                      onKeyPress={event => {
                        if (event.key === 'Enter' || event.which === 13 || event.keyCode === 13) {
                          generatePageContent();
                        }
                      }}
                    />
                    <div className='btn-group'>
                      {
                        (state.exportReducer.results.data.length > 0) ?
                          <CSVLink data={exportData().fileData()} headers={exportData().fileHeaders()} filename={"merchants source account.csv"}>
                            <button className='button --export'>
                              <Image image={exportIcon} />
                            </button>
                          </CSVLink> :
                          <CSVLink data={exportDataAll().fileData()} headers={exportDataAll().fileHeaders()} filename={"merchants source account.csv"}>
                            <button className='button --export'>
                              <Image image={exportIcon} />
                            </button>
                          </CSVLink>
                      }
                      <button className='button --user' onClick={() => showAddSourceAccountModal()}>
                        <Image image={plusIcon} />
                      </button>
                    </div>
                  </div>
                  <div className={`container-full merchants__bottom__content__filter`}>
                    <div className={`merchants__bottom__content__filter__item --filter`}>
                      <div className={`--filter__box`}>
                        <Image image={filterIcon} alt="filter-icon" />
                      </div>
                      <Text value='Filter' />
                    </div>
                    {/* <div className={`merchants__bottom__content__filter__item --active-filter`}>
                  <Text value='No active filters' />
                </div> */}
                    <div className={`merchants__bottom__content__filter__item --add-filter`} onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
                      <button className={`button --elipses`} onClick={() => onClickHandler(true)} aria-haspopup="true" aria-expanded={selectedMenu}>
                        <Image image={plus} />
                        <Text value='Add filter' />
                      </button>
                      {
                        selectedMenu !== false && (
                          <button className='unset'>
                            <div className={`container-full more-info`}>
                              <div className={`container-full more-info__top`}>
                                <div className={`div-md-4 more-info__top__item`}>
                                  <div className={`container-full more-info__top__item__desc`}>
                                    <div className={`more-info__top__item__desc__checkbox`}>
                                      <input
                                        type="checkbox"
                                        name="status"
                                        onChange={(event) => changeFilterValue(event, event.target.name, event.target.value)}
                                        defaultChecked={(filterValues.status) ? true : false}
                                      />
                                      <Image image={nike} />
                                    </div>
                                    <Text value='Signatory Status' />
                                  </div>
                                  <div className={`container-full more-info__top__item__input`}>
                                    <div className='input'>
                                      <select required
                                        className={
                                          `${(filterValues.status === undefined) ? 'disabled' : null}`
                                        }
                                        name="status"
                                        value={fields.status}
                                        onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                                      >
                                        <option value="" hidden> Select Status </option>
                                        <option value="1"> Active </option>
                                        <option value="0"> Disabled </option>
                                        <option value="2"> Pending </option>
                                        <option value="3"> Pending update enabled </option>
                                        <option value="4"> Pending update disabled </option>
                                        <option value="5"> Rejected pending </option>
                                      </select>
                                      <Image image={caret} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={`container-full more-info__bottom`}>
                                <Button value="Cancel" className='button --cancel' onClick={() => setSelectedMenu(false)} />
                                <Button value="Apply Filters" className={`button --apply`} onClick={() => fetchFilteredContent()} />
                              </div>
                            </div>
                          </button>
                        )
                      }
                    </div>
                  </div>
                  <div className={`container-full merchants__bottom__content__table`}>
                    <MerchantMappedAccounts data={state.dashboardReducer.results} pagination={size} setPagination={changeSize} changePage={changePage} page={page} reloadPage={refreshTable} />
                  </div>
                </div> :
                <div className={`container-full center merchants__bottom__empty`}>
                  <div className={`div-md-5 merchants__bottom__empty__box`}>
                    <HeaderText value='No Cards Mapped Yet' />
                    <Text value='There are not yet any mappes cards for this merchant' />
                    <div className={`container-full center`}>
                      <Button value="Add Cards Now" className="button" onClick={() => showAddSourceAccountModal()} />
                    </div>
                  </div>
                </div>
            }
          </div>

        </div>

      </MerchantSourceAccountTemplateStyle>
    </>
  );

};

export default MerchantSourceAccountTemplate;
