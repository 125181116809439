import React, { useState, useEffect } from 'react';
import AddSourceAccountModalStyle from './AddSourceAccountModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import Input from '../../atoms/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { SubmitActionsPOST } from '../../../redux/actions/SubmitAction';
import { alertActions } from '../../../redux/actions/AlertActions';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';
import closeIcon from '../../../assets/images/close-icon-round.svg';
import dropdown from '../../../assets/images/dropdown.svg';
import caret from '../../../assets/images/caret-down-select.svg';
import AddSourceAccountForm from '../../molecules/AddSourceAccountForm/AddSourceAccountForm';
import AddSourceAccountUsers from '../../molecules/AddSourceAccountUsersForm/AddSourceAccountUsersForm';

type AddSourceAccountModalProps = {
    handleClose: Function,
    reloadPage: Function
}

const AddSourceAccountModal = ({ handleClose, reloadPage }: AddSourceAccountModalProps) => {
    const dispatch: TypedDispatch = useDispatch();
    const state = useSelector((state: RootStore) => state);
    const [step, setStep] = useState(1);

    const setStepNumber = () => {
        let nextStep: number = step;
        setStep(++nextStep);
    }

    const goBack = () => {
        setStep(1);
    }

    return (
        <AddSourceAccountModalStyle className={`container-full`}>
            <div className={`container-full center modal-container`}>
                <div className={`modal-container__box`}>
                    <div className={`container-full modal-container__box__header`}>
                        <HeaderText value='Add Source Account' />
                        <Image image={close} onClick={() => handleClose()} />
                    </div>
                    <div className={`container-full modal-container__box__content`}>
                        { step === 1 ? <AddSourceAccountForm handleClose={handleClose} setStep={setStepNumber} /> : null}
                        { step === 2 ?  <AddSourceAccountUsers handleClose={handleClose} setStep={goBack} reloadPage={reloadPage}/> : null}
                    </div>
                </div>
            </div>
        </AddSourceAccountModalStyle>
    );
}

export default AddSourceAccountModal;