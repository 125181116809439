import React, {useState, useEffect} from 'react';
import PaginationStyle from './ReportDetailsPaginationStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import caretDown from '../../../assets/images/caret-down-space.svg';
import caretUp from '../../../assets/images/caret-up-space.svg';
import doubleAngleLeft from '../../../assets/images/angle-double-left.svg';
import doubleAngleRight from '../../../assets/images/angle-double-right.svg';
import angleLeft from '../../../assets/images/Arrow-Left 2.svg';
import angleRight from '../../../assets/images/Arrow-Right 2.svg';
import {useSelector} from 'react-redux';
import { RootStore } from '../../../config/ConfigStore';

type PaginationProps = {
    pagination: number,
    changePage: Function,
    setPagination: Function,
    page: number,
    data: any
  }

const Pagination = ({pagination, changePage, setPagination, page, data}: PaginationProps) => {
    const state = useSelector( (state:RootStore) => state);
  
    return (
        <PaginationStyle className={`container-full`}>
            <div className={`pagination__left`}>
                <div className={`pagination__left__desc`}>
                    <p> Showing <span> 1- {
                    (pagination > data.totalElements) ? data.totalElements : pagination
                } of {data.totalElements} </span></p>
                </div>
                <div className={`pagination__left__count`}>
                    <Image image={caretDown} onClick={() => setPagination(--pagination)}/>
                    <Text value={pagination.toString()} className='number' />
                    <Image image={caretUp} onClick={() => setPagination(++pagination)}/>
                    <Text value='/Page' className='desc' />
                </div>
            </div>
            <div className={`pagination__right`}>
                {
                    (data.first === false) ?
                    <Image image={doubleAngleLeft} onClick={() => changePage(1)}/> :
                    <Image image={doubleAngleLeft} className={`--disabled-img`} />
                }
                {
                    (page > 1 && data.totalPages >= page) ? 
                    <Image image={angleLeft} onClick={() => changePage(--page)}/> :
                    <Image image={angleLeft} className={`--disabled-img`} />
                }
                
                <p> Page <span> {page} </span> of {data.totalPages}</p>
                {
                    (data.totalPages > page) ?  
                    <Image image={angleRight} onClick={() => changePage(++page)}/> :
                    <Image image={angleRight} className={`--disabled-img`}/>
                }
                {
                    (data.last === false) ?
                    <Image image={doubleAngleRight} onClick={() => changePage(data.totalPages)}/> :
                    <Image image={doubleAngleRight} className={`--disabled-img`} />
                }
            </div>
        </PaginationStyle>
    );
}

export default Pagination;