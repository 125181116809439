import { Dispatch } from 'redux';
import {
  AuthDispatchTypes,
  AUTH_USER,
  AUTH_FAILED,
  AUTH_REQUEST,
} from './AuthActionTypes';
import axios from '../../axios';
import { alertActions } from './AlertActions';
import Cookies from 'js-cookie';
import jwt from 'jwt-decode' 


export const signIn =
  (credentials: object) =>
  async (dispatch: Dispatch<AuthDispatchTypes | any>) => {
    try {
      dispatch({ type: AUTH_REQUEST });
      dispatch(alertActions.clear());
      const res = await axios.post('/auth/token', credentials);
      const user = jwt(res.data.access_token);
      dispatch({
        type: AUTH_USER,
        response: user,
      });
      await Cookies.set('userToken',res.data.access_token,{ expires: res.data.expires_in });
      dispatch(alertActions.success("Login Successful"));
    } catch (error: any) {
      console.log(error);
      if (error.message === "Network Error") {
        dispatch({
          type: AUTH_FAILED,
        });
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch({
          type: AUTH_FAILED,
        });
        dispatch(alertActions.error(error.response.data.message));
      }
    }
  };

export const forgotPassword = (username: string) => async ( dispatch: Dispatch<AuthDispatchTypes | any>) => {
    try{
        dispatch({ type: AUTH_REQUEST });
        dispatch(alertActions.clear());
        const res = await axios.get(`/users/auth/reset?user=${username}`)
        dispatch({
            type: AUTH_USER,
            response: res.data
        })
        dispatch(alertActions.success(res.data.message));
    } catch(error: any) {
      if (error.message === "Network Error") {
        dispatch({
          type: AUTH_FAILED,
        });
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch({
          type: AUTH_FAILED,
        });
        dispatch(alertActions.error(error.response.data.message));
      }
    }
  };

export const resetTokenValidation =
  (passKey: string) =>
  async (dispatch: Dispatch<AuthDispatchTypes | any>) => {
    try {
      dispatch({ type: AUTH_REQUEST });
      dispatch(alertActions.clear());
      const res = await axios.get(`/users/auth/reset/${passKey}`);
      dispatch({
        type: AUTH_USER,
        response: res.data,
      });
      dispatch(alertActions.success(res.data.message));
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch({
          type: AUTH_FAILED,
        });
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch({
          type: AUTH_FAILED,
        });
        dispatch(alertActions.error(error.response.data.message));
      }
    }
};
export const resetPassword =
  (values: object) =>
  async (dispatch: Dispatch<AuthDispatchTypes | any>) => {
    try {
      dispatch({ type: AUTH_REQUEST });
      dispatch(alertActions.clear());
      const res = await axios.post(`/users/auth/change`, values, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      dispatch({
        type: AUTH_USER,
        response: res.data,
      });
      dispatch(alertActions.success(res.data.message));
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch({
          type: AUTH_FAILED,
        });
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch({
          type: AUTH_FAILED,
        });
        dispatch(alertActions.error(error.response.data.message));
      }
    }
};
