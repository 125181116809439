import BusinessProfileTemplate from "../components/templates/BusinessProfile/BusinessProfileTemplate";
import CreateMerchantTemplate from "../components/templates/CreateMerchant/CreateMerchantTemplate";
import ManageRolesTemplate from "../components/templates/ManageRoles/ManageRolesTemplate";
import MerchantEditProfileTemplate from "../components/templates/MerchantEditProfile/MerchantEditProfileTemplate";
import MerchantProfileTemplate from "../components/templates/MerchantProfile/MerchantProfileTemplate";
import MerchantsTemplate from "../components/templates/Merchants/MerchantsTemplate";
import MerchantUsersTemplate from "../components/templates/MerchantUsers/MerchantUsersTemplate";
import MerchantUsersDetailTemplate from "../components/templates/MerchantUsersDetail/MerchantUsersDetailTemplate";
import ProfileTemplate from "../components/templates/Profile/ProfileTemplate";
import SignatoryGroupsTemplate from "../components/templates/SignatoryGroups/SignatoryGroupsTemplate";
import UserProfileTemplate from "../components/templates/UserProfile/UserProfileTemplate";
import UsersTemplate from "../components/templates/Users/UsersTemplate";
import GlobalParametersTemplate from "../components/templates/GlobalParameters/GlobalParametersTemplate";
import NotificationsTemplate from "../components/templates/Notifications/NotificationsTemplate";
import IssuersTemplate from "../components/templates/Issuers/IssuersTemplate";
import EntitiesTemplate from "../components/templates/Entities/EntitiesTemplate";
import StatutoryBodyTemplate from "../components/templates/StatutoryBody/StatutoryBodyTemplate";
import MerchantFeesTemplate from "../components/templates/MerchantFees/MerchantFeesTemplate";
import MerchantFeesSummaryTemplate from "../components/templates/MerchantFeesSummary/MerchantFeesSummaryTemplate";
import MerchantSourceAccountTemplate from "../components/templates/MerchantSourceAccount/MerchantSourceAccountTemplate";
import DashboardTemplate from "../components/templates/Dashboard/DashboardTemplate";
import AuditLogTemplateGB from "../components/templates/AuditLogGB/AuditLogTemplate";
import AuditLogTemplate from "../components/templates/AuditLog/AuditLogTemplate";
import OngoingPaymentsTemplate from "../components/templates/OngoingPayments/OngoingPaymentsTemplate";
import OngoingPaymentsListTemplate from "../components/templates/OngoingPaymentsList/OngoingPaymentsListTemplate";
import OngoingPaymentsListSummaryTemplate from "../components/templates/OngoingPaymentsListSummary/OngoingPaymentsListSummaryTemplate";
import PendingApprovalTemplate from "../components/templates/PendingApproval/PendingApprovalTemplate";
import PendingApprovalSummaryTemplate from "../components/templates/PendingApprovalSummary/PendingApprovalSummaryTemplate";
import PendingApprovalSummaryAuthorizeTemplate from "../components/templates/PendingApprovalSummaryAuthorize/PendingApprovalSummaryAuthorizeTemplate";
import SavedPaymentsTemplate from "../components/templates/SavedPayments/SavedPaymentsTemplate";
import PaymentReportTemplate from "../components/templates/PaymentReport/PaymentReportTemplate";
import PaymentReportDetailsTemplate from "../components/templates/PaymentReportDetails/PaymentReportDetailsTemplate";
import PartnerReportTemplate from "../components/templates/PartnerReport/PartnerReportTemplate";
import FeeReportTemplate from "../components/templates/FeeReport/FeeReportTemplate";
import DeclinedPaymentsTemplate from "../components/templates/DeclinedPayments/DeclinedPaymentsTemplate";

export default [
    {
        elements: [
            {
                path: '/settings/manage-roles',
                component: ManageRolesTemplate,
            },
        ],
    },
    {
        elements: [
            {
                path: '/settings/business-profile',
                component: BusinessProfileTemplate,
            },
        ],
    },
    {
        elements: [
            {
                path: '/settings/profile',
                component: ProfileTemplate,
            },
        ],
    },
    {
        elements: [
            {
                path: '/settings/users',
                component: UsersTemplate,
            },
        ],
    },
    {
        elements: [
            {
                path: '/settings/users/profile',
                component: UserProfileTemplate,
            },
        ],
    },
    {
        elements: [
            {
                path: '/merchants',
                component: MerchantsTemplate,
            },
        ],
    },
    {
        elements: [
            {
                path: '/merchants/profile',
                component: MerchantProfileTemplate,
            },
        ],
    },
    {
        elements: [
            {
                path: '/merchants/profile/edit',
                component: MerchantEditProfileTemplate,
            },
        ],
    },
    {
        elements: [
            {
                path: '/merchants/users',
                component: MerchantUsersTemplate,
            },
        ],
    },
    {
        elements: [
            {
                path: '/merchants/users/details',
                component: MerchantUsersDetailTemplate,
            },
        ],
    },
    {
        elements: [
            {
                path: '/merchants/create/:step',
                component: CreateMerchantTemplate,
            },
        ],
    },
    {
        elements: [
            {
                path: '/merchants/fees',
                component: MerchantFeesTemplate,
            },
        ],
    },
    {
        elements: [
            {
                path: '/merchants/fees/summary',
                component: MerchantFeesSummaryTemplate,
            },
        ],
    },
    {
        elements: [
            {
                path: '/merchants/source-accounts',
                component: MerchantSourceAccountTemplate,
            },
        ],
    },
    {
        elements: [
            {
                path: '/settings/signatory-groups',
                component: SignatoryGroupsTemplate,
            },
        ],
    },
    {
        elements: [
            {
                path: '/settings/entities',
                component: EntitiesTemplate,
            },
        ],
    },
    {
        elements: [
            {
                path: '/global-parameters',
                component: GlobalParametersTemplate,
            },
        ],
    },
    // {
    //     elements: [
    //         {
    //             path: '/global-parameters/notifications',
    //             component: NotificationsTemplate,
    //         },
    //     ],
    // },
    {
        elements: [
            {
                path: '/payroll-config/issuers',
                component: IssuersTemplate
            },
        ],
    },
    {
        elements: [
            {
                path: '/payroll-config/statutory-bodies',
                component: StatutoryBodyTemplate
            },
        ],
    },
    {
        elements: [
            {
                path: '/dashboard',
                component: DashboardTemplate
            },
        ],
    },
    {
        elements: [
            {
                path: '/reports/audit/global-users',
                component: AuditLogTemplateGB
            },
        ],
    },
    {
        elements: [
            {
                path: '/reports/audit',
                component: AuditLogTemplate
            },
        ],
    },
    {
        elements: [
            {
                path: '/reports/payment-report',
                component: PaymentReportTemplate
            },
        ],
    },
    {
        elements: [
            {
                path: '/reports/payment-report/details',
                component: PaymentReportDetailsTemplate
            },
        ],
    },
    {
        elements: [
            {
                path: '/reports/partner-report',
                component: PartnerReportTemplate
            },
        ],
    },
    {
        elements: [
            {
                path: '/reports/fee-report',
                component: FeeReportTemplate
            },
        ],
    },
    {
        elements: [
            {
                path: '/payments/ongoing-payments',
                component: OngoingPaymentsTemplate
            },
        ],
    },
    {
        elements: [
            {
                path: '/payments/ongoing-payment/list',
                component: OngoingPaymentsListTemplate
            },
        ],
    },
    {
        elements: [
            {
                path: '/payments/ongoing-payment/list/summary',
                component: OngoingPaymentsListSummaryTemplate
            },
        ],
    },
    {
        elements: [
            {
                path: '/payments/pending-approval',
                component: PendingApprovalTemplate
            },
        ],
    },
    {
        elements: [
            {
                path: '/payments/pending-approval/summary',
                component: PendingApprovalSummaryTemplate
            },
        ],
    },
    {
        elements: [
            {
                path: '/payments/pending-approval/summary/authorize',
                component: PendingApprovalSummaryAuthorizeTemplate
            },
        ],
    },
    {
        elements: [
            {
                path: '/payments/declined-payments',
                component: DeclinedPaymentsTemplate
            },
        ],
    },
    {
        elements: [
            {
                path: '/payments/saved-payments',
                component: SavedPaymentsTemplate
            },
        ],
    }

];
