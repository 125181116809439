import styled from 'styled-components';
import search from '../../../assets/images/Search.svg';

const MerchantsTemplateStyle = styled.div`
  box-sizing: border-box;
  padding: 20px 40px 0 30px;
  width: 100%;
  .merchants__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 1.5em;
      line-height: 30px;
    }
  }
  
`;

export default MerchantsTemplateStyle;
