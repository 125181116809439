import React, {useState, useEffect} from 'react';
import MerchantStepThreeStyle from './MerchantStepThreeStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import { Link, useNavigate } from 'react-router-dom';
import nike from '../../../assets/images/nike.svg';
import MerchantProgressBar from '../MerchantProgressBar/MerchantProgressBar';
import Input from '../../atoms/Input/Input';
import caret from '../../../assets/images/caret-left-new.svg';
import Button from '../../atoms/Button/Button';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {stepThree} from '../../../redux/actions/StepAction';
import {DashboardActions} from '../../../redux/actions/DashboardAction';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';



const MerchantStepThree = () => {
    const dispatch: TypedDispatch = useDispatch();
    const state = useSelector( (state:RootStore) => state);
    const [fetchLoading, setFetchLoading]: any = useState(true);
    const navigate = useNavigate()
    const [fields, setFields] = useState({
        name: '',
        email: '',
        number: '',
        role: '',
        username: ''
    });
    const [fieldErrors, setFieldErrors] = useState({
        number: '',
    })

    useEffect(() => {
        if(state.dashboardReducer.results && !fetchLoading && Object.keys(state.stepReducer.results.stepThree).length === 0){
            setFields({
                name: '',
                email: '',
                number: '',
                role: state.dashboardReducer.results.data.content[0].id,
                username: ''
            })
        }
    }, [state.dashboardReducer, fetchLoading])

    useEffect(() => {
        if(Object.keys(state.stepReducer.results.stepThree).length > 0){
            let fieldValue = {
                name: state.stepReducer.results.stepThree.name,
                email: state.stepReducer.results.stepThree.email,
                number: state.stepReducer.results.stepThree.number,
                role: state.stepReducer.results.stepThree.role,
                username: state.stepReducer.results.stepThree.username
            }
            setFields(fieldValue);
        }
        if(Object.keys(state.stepReducer.results.stepOne).length < 1){
            navigate('/merchants/create/step-one');
        }
    }, [])

    useEffect(() => {
        fetchAllRoles();
      }, [])
    
    const fetchAllRoles = async () => {
        setFetchLoading(true);
        await dispatch(DashboardActions(`/roles?defaultRole=1&roleLevel=1&roleType=3`));
        setFetchLoading(false);
    }

    const renderFetchLoading = () => {
        if(fetchLoading){
          return(
            <FetchLoading />
          )
        }
      }

    const inputChange =  async (name: string, value: string) => {
        const fieldsValues: any = Object.assign({}, fields);
        fieldsValues[name] = value;
        await setFields(fieldsValues);
        console.log(fields);
        if(name === "number"){
            onInputValidate(value, name);
        }
    };

    const onInputValidate = async (value: string, name: string) => {
        if(/^234[0-9]{10}$/.test(value)){
          const fieldErrorValues: any = Object.assign({}, fieldErrors);
          fieldErrorValues[name] = false;
          await setFieldErrors(fieldErrorValues);
          return true
        }
        else{
          const fieldErrorValues: any = Object.assign({}, fieldErrors);
          fieldErrorValues[name] = 'Invalid phone number format';
          await setFieldErrors(fieldErrorValues);
          return false
        }
    }

    const onFormSubmit = () => {
        return async (evt: React.FormEvent) => { 
          evt.preventDefault();
          if(validate()){
            return;
          }
          await dispatch(stepThree(fields));
          navigate('/merchants/create/step-four')
          console.log(state);
        }
    }

    const validate = () => {         
        const person:any = fieldErrors;
        if (Object.keys(fieldErrors).filter((k) => person[k]).length){
            return true;
        }
        return false;
    } 

    return (
        <>
            {renderFetchLoading()}
            <MerchantStepThreeStyle className={`container-full`}>
            <form onSubmit={onFormSubmit()}>
                <div className={`container-full merchants__body`}>
                    <MerchantProgressBar />
                    <div className={`container-full merchants__body__form`}>
                        <div className={`div-sm-12 merchants__body__form__desc`}>
                            <Text value='Step 3:' className='step'/>
                            <HeaderText value='Corporate Administrator' className='header'/>
                            <Text value='Enter company details to create merchant' className='desc' />
                        </div>
                        <div className={`container-full merchants__body__form__form`}>
                            <div className={`container-full merchants__body__form__form__row`}>
                                <div className='div-md-6 merchants__body__form__form__row__item'>
                                    <Text value='Full Name' />
                                    <Input type="text" className='input' placeholder='Enter legal full name' required value={fields.name} name="name" onChange={(evt) => inputChange(evt.target.name, evt.target.value) } />
                                </div>
                                <div className='div-md-6 merchants__body__form__form__row__item'>
                                    <Text value='Email' />
                                    <Input type="email" className='input' placeholder='Enter email address' required value={fields.email} name="email" onChange={(evt) => inputChange(evt.target.name, evt.target.value) }/>
                                </div>
                            </div>
                            <div className={`container-full merchants__body__form__form__row`}>
                                <div className='div-md-6 merchants__body__form__form__row__item'>
                                    <Text value='Phone Number' />
                                    <Input type="text" className='input' placeholder='Enter phone number' required value={fields.number} name="number" onChange={(evt) => inputChange(evt.target.name, evt.target.value) } />
                                    <span> {fieldErrors.number} </span>
                                </div>
                                <div className='div-md-6 merchants__body__form__form__row__item'>
                                    <Text value='Role' />
                                    <div className='input'>
                                        <select required value={fields.role} name="role" onChange={(evt) => inputChange(evt.target.name, evt.target.value) }>
                                            {
                                                (state.dashboardReducer.results && state.dashboardReducer.results.data.content.map((role: Record<any, any>) => {
                                                    return(
                                                        <option value={role.id} key={role.id}> {role.name} </option>
                                                    )
                                                }))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className={`container-full merchants__body__form__form__row`}>
                                <div className='div-md-6 merchants__body__form__form__row__item'>
                                    <Text value='Username' />
                                    <Input type="text" className='input' placeholder='Enter username' required value={fields.username} name="username" onChange={(evt) => inputChange(evt.target.name, evt.target.value) } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`container-full merchants__action`}>
                        <Link to="/merchants/create/step-two">
                            <button className='button --back'>
                                <Image image={caret} />
                                Back
                            </button> 
                        </Link>
                        <Button value="Save & Continue" className='button --save' />
                </div>
            </form>
            </MerchantStepThreeStyle>
        </>
    );
}

export default MerchantStepThree;