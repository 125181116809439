import React, { useState, useEffect } from 'react';
import InitiateSavedPaymentModalStyle from './InitiateSavedPaymentModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import Input from '../../atoms/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { SubmitActionsPOST, SubmitActionsUpload } from '../../../redux/actions/SubmitAction';
import caret from '../../../assets/images/caret-down-select.svg';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';
import { alertActions } from '../../../redux/actions/AlertActions';
import dayjs from 'dayjs';
import { addDays, format } from 'date-fns';
import { DateRange, DayPicker } from 'react-day-picker';


type InitiateSavedPaymentModalProps = {
  handleClose: Function,
  reloadPage: Function,
  data: any
}

const InitiateSavedPaymentModal = ({ handleClose, reloadPage, data }: InitiateSavedPaymentModalProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  let timeOutId: any = null;
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [fields, setFields] = useState({
    signatoryGroup: '',
    sourceAccount: '',
    paymentName: '',
    paymentDescription: ''
  })
  const [uploadedFile, setUploadedFile] = useState("");
  const [signatoryList, setSignatoryList] = useState([]);
  const [sourceAccountList, setSourceAccountList] = useState([]);
  const [file, setFile] = useState("");
  const [fileSubmitted, setFileSubmitted] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const pastMonth = new Date();

  useEffect(() => {
    fetchSignatories();
    fetchSourceAccounts();
    console.log(state);
  }, [])

  useEffect(() => {
    if (state.submitReducer.results && submitted) {
      handleClose();
      reloadPage();
    }
  }, [state.submitReducer])

  useEffect(() => {
    if (state.submitReducer.results && fileSubmitted) {
      setUploadedFile(state.submitReducer.results.data.fileUri)
    }
  }, [state.submitReducer])

  const fetchSignatories = async () => {
    try {
      const res = await axios.get('/signatory?status=1&size=0&page=1', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setSignatoryList(res.data.data.content);
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const fetchSourceAccounts = async () => {
    try {
      const res = await axios.get(`/account?merchantId=${state.authReducer.results.merchant_id}&size=0&page=1`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setSourceAccountList(res.data.data.content);
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const onClickHandler = (value: boolean) => {
    if (selectedMenu !== false) {
      setSelectedMenu(false);
      return;
    }
    setSelectedMenu(value);
  }

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
      setSelectedMenu(false);
    });
  }

  const onFocusHandler = () => {
    clearTimeout(timeOutId);
  }

  const defaultSelected: DateRange = {
    from: pastMonth,
    to: addDays(pastMonth, 4)
  };
  const [range, setRange] = useState<DateRange | any>(defaultSelected);

  let footer = <p>Please pick the first day.</p>;
  if (range !== undefined && range.from) {
    if (!range.to) {
      footer = <p style={{ fontFamily: "Mulish" }}>{format(range.from, 'PPP')}</p>;
    } else if (range.to) {
      footer = (
        <p style={{ fontFamily: "Mulish" }}>
          {format(range.from, 'PPP')}–{format(range.to, 'PPP')}
        </p>
      );
    }
  }

  const inputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues[name] = value;
    await setFields(fieldsValues);
  };

  const fileUpload = async (file: any) => {
    setFile(file.name);
    setFileSubmitted(true);
    let value = {
      file,
      fileType: 'PAYMENT'
    }
    await dispatch(SubmitActionsUpload('/files/upload/', value));
  }

  const onFormSubmit = () => {
    return async (evt: React.FormEvent) => {
      evt.preventDefault();
      setSubmitted(true);
      let payload = {
        "startDate": dayjs(range.from).format("YYYY-MM-DD HH:mm:ss"),
        "endDate": dayjs(range.to).format("YYYY-MM-DD HH:mm:ss"),
        "signatoryGrpId": fields.signatoryGroup !== "" ? fields.signatoryGroup : null,
        "sourceAccountId": fields.sourceAccount,
        "invoiceUri": uploadedFile.split("/v1/files/")[1],
        "paymentName": fields.paymentName,
        "paymentDescription": fields.paymentDescription,
        "savedPaymentId": data.id,
      }
      await dispatch(SubmitActionsPOST("/payment/initiateSaved", payload));
    }
  }

  return (
    <InitiateSavedPaymentModalStyle className={`container-full`}>
      <div className={`container-full center modal-container`}>
        <div className={`modal-container__box`}>
          <div className={`container-full modal-container__box__header`}>
            <HeaderText value='Initiate From Saved Payment' />
            <Image image={close} onClick={() => handleClose()} />
          </div>
          <form onSubmit={onFormSubmit()}>
            <div className={`container-full modal-container__box__content`}>
              <div className={`container-full modal-container__box__content__row`}>
                <div className={`div-sm-12 modal-container__box__content__row__item`}>
                  <HeaderText value='Payment Name' />
                  <Input type='text' className="input" placeholder="Enter Payment Name"
                    name="paymentName"
                    onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                    value={fields.paymentName}
                    required
                  />
                </div>
              </div>
              <div className={`container-full modal-container__box__content__row`}>
                <div className={`div-sm-12 modal-container__box__content__row__item`}>
                  <HeaderText value='Payment Description' />
                  <Input type='text' className="input" placeholder="Enter Payment Description"
                    name="paymentDescription"
                    onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                    value={fields.paymentDescription}
                    required
                  />
                </div>
              </div>
              <div className={`container-full modal-container__box__content__row`}>
                <div className={`div-sm-12 modal-container__box__content__row__item`} onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
                  <HeaderText value='Payment Date Range' />
                  <button type="button" className={`button --elipses`} onClick={() => onClickHandler(true)} aria-haspopup="true" aria-expanded={selectedMenu}>
                    <Input type="text" className={`input --date`}
                      placeholder="Select Date Range"
                      value={(range) ? `${dayjs(range.from).format("YYYY-MM-DD")} to ${dayjs(range.to).format("YYYY-MM-DD")}` : ''}
                      name="dateRange"
                      onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                      readOnly
                    />
                  </button>
                  {
                    selectedMenu !== false && (
                      <div style={{ position: "absolute", zIndex: "2000" }}>
                        <DayPicker
                          mode="range"
                          defaultMonth={pastMonth}
                          selected={range}
                          footer={footer}
                          modifiersStyles={{
                            selected: {
                              background: 'blue',
                              color: 'white',
                              fontWeight: 'bold',
                            },
                          }}
                          onSelect={setRange}
                          style={{ position: "absolute", left: "0", marginTop: "0", background: "white", boxSizing: "border-box", padding: "35px", boxShadow: "0px 15px 44px rgba(10, 18, 73, 0.15)" }}
                          styles={{
                            day: { boxSizing: "border-box", padding: '10px', borderRadius: "8px", fontFamily: "Mulish" },
                            caption: { fontFamily: "Mulish" },
                          }}
                        />
                      </div>
                    )
                  }
                </div>
              </div>
              <div className={`container-full modal-container__box__content__row`}>
                <div className={`div-md-12 modal-container__box__content__row__item`}>
                  <div className='input'>
                    <select
                      name="signatoryGroup"
                      onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                      value={fields.signatoryGroup}
                    >
                      <option value=""> Select Signatory Group </option>
                      {
                        (signatoryList.length > 0 && signatoryList.map((signatory: Record<any, any>) => {
                          return (<option value={`${signatory.id}`} key={signatory.id}> {signatory.name} </option>)
                        }))
                      }
                    </select>
                    <Image image={caret} />
                  </div>
                  <Text value='Users in this group will complete payment approval.' />
                </div>
              </div>
              <div className={`container-full modal-container__box__content__row`}>
                <div className={`div-md-12 modal-container__box__content__row__item`}>
                  <div className='input'>
                    <select
                      name="sourceAccount"
                      onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                      value={fields.sourceAccount}
                      required
                    >
                      <option value="" hidden> Select Source Account </option>
                      {
                        (sourceAccountList.length > 0 && sourceAccountList.map((sourceAccount: Record<any, any>) => {
                          return (<option value={`${sourceAccount.id}`} key={sourceAccount.id}> {sourceAccount.validatedName} </option>)
                        }))
                      }

                    </select>
                    <Image image={caret} />
                  </div>
                  <Text value='This is the source account where payment would be made from.' />
                </div>
              </div>
              <div className={`container-full modal-container__box__content__invoice`}>
                <div className={`container-full modal-container__box__content__invoice__row`}>
                  <div className={`div-md-12 modal-container__box__content__invoice__row__item`}>
                    <HeaderText value='Add Description/Invoice' />
                    <div className={`container-full center modal-container__box__content__invoice__row__item__file-select`}>
                      <Text value='Select file for upload' />
                      <Input type="file" className="input"
                        onChange={(event) => fileUpload(event.target.files[0])}
                      />
                    </div>
                    <div className={`container-full modal-container__box__content__invoice__row__item__assistive-text`}>
                      <Text value={file} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`container-full center modal-container__box__action`}>
              <Button type="button" value="Cancel" className={`button --cancel`} onClick={() => handleClose()} />
              <Button type="submit" value="Submit" className={`button --change`} isLoading={state.submitReducer.fetching} />
            </div>
          </form>
        </div>
      </div>
    </InitiateSavedPaymentModalStyle>
  );
}

export default InitiateSavedPaymentModal;