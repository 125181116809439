import styled from "styled-components";

const AddUserModalStyle = styled.div`
    animation: show-bg 0.5s linear forwards;
    box-sizing:border-box;
    display:flex;
    height:100%;
    left:0;
    position:absolute;
    position:fixed;
    top: 0;
    width:100%;
    z-index:5000;
    @keyframes show-bg{
        0%{
            background:rgba(0, 0, 0, 0)
        }
        100%{
            background:rgba(37, 39, 51, 0.7);
        }
    }
    .modal-container{
        box-sizing:border-box;
        height:100vh;
    }
    .modal-container__box{
        -ms-flex: 0 0 505px;
        -webkit-flex: 0 0 505px;
        flex: 0 0 505px;
        max-width: 505px;
        background:#FFFFFF;
        border-radius:10px;
        box-sizing:border-box;
        padding:20px 20px 30px 20px;
    }
    .modal-container__box__header{
        box-sizing: border-box;
        padding: 0 10px 0 10px;
        h2{
           color: #252733;
           font-family: 'Mulish';
           font-style: normal;
           font-weight: 700;
           font-size: 1.375em;
           line-height: 30px;
        }
        img{
            cursor: pointer;
        }
    }
    .modal-container__box__content__desc{
        box-sizing: border-box;
        margin-top: 12px;
        padding: 0 10px 0 10px;
        p{
            color: rgba(37, 39, 51, 0.7);
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 21px;
        }
    }
    .modal-container__box__content__desc__form{
        background: #FAFAFA;
        border-radius: 10px;
        box-sizing: border-box;
        margin-top: 22px;
        padding: 25px;
    }
    form{
        width: 100%;
    }
    .modal-container__box__content__desc__form__item{
        margin-bottom: 12px;
        .label{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 4px;
            width: 100%;
        }
        .input{
            background: #F6F6F6;
            border: none;
            border-radius: 8px;
            box-sizing: border-box;
            color: #4B506D;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 300;
            font-size: 1em;
            height: 50px;
            padding-left: 15px;
            transition: all 0.5s linear;
            width: 100%;
            &::placeholder{
              color: rgba(75, 80, 109, 0.4);
            }
            &:focus{
              border: 1px solid rgba(55, 81, 255, 0.5);
            }
        }
        .disabled{
            background: transparent;
        }
    }
    .modal-container__box__action{
        justify-content: flex-end;
        margin-top: 27px;
        .button{
            border-radius: 8px;
            font-family: 'Mulish';
            font-weight: 500;
            font-size: 16px;
            height: 40px; 
            margin-bottom: 0;
        }
        .--cancel{
            background: #F5F5F5;
            color: #252733;
            letter-spacing: 0.2px;
            margin-right: 20px;
            width: 102px;
        }
        .--update{
            background: #3751FF;
            box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
            color: white;
            width: 187px;
        }
    }

    .modal-container__box__content__desc__form__item__switch{
        align-self: center;
        position: relative;
        .input{
          cursor: pointer;
          height: 18px;
          position: absolute;
          opacity: 0;
          width: 30px;
          &:checked + .modal-container__box__content__desc__form__item__switch__box{
            background: #1B9830;
            .modal-container__box__content__desc__form__item__switch__box__ball{
              background: white;
              margin-left: 10px;
            }
          }
        }
      }
      .modal-container__box__content__desc__form__item__switch__box{
        background: #CCCCCC;
        border-radius: 70px;
        box-sizing: border-box;
        cursor: pointer;
        height: 18px;
        padding: 2px;
        transition: all 0.2s linear;
        width: 30px;
      }
      .modal-container__box__content__desc__form__item__switch__box__ball{
        background: white;
        border-radius: 50%;
        height: 14px;
        transition: margin 0.5s linear;
        width: 14px;
      }
    


    
`
export default AddUserModalStyle;