export const STEP_1 = 'STEP_1';
export const STEP_2 = 'STEP_2';
export const STEP_3 = 'STEP_3';
export const STEP_4 = 'STEP_4';
export const STEP_CLEAR = 'STEP_CLEAR';

export interface StepOne {
    type: typeof STEP_1,
    data: object
}

export interface StepTwo {
    type: typeof STEP_2,
    data: object
}

export interface StepThree {
    type: typeof STEP_3,
    data: object
}

export interface StepFour {
    type: typeof STEP_4,
    data: object
}

export interface StepClear {
    type: typeof STEP_CLEAR
}

export type StepDispatchTypes = StepOne | StepTwo | StepThree | StepFour | StepClear