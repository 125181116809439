import styled from "styled-components";

const ApprovePaymentsModalStyle = styled.div`
    animation: show-bg 0.5s linear forwards;
    box-sizing:border-box;
    display:flex;
    height:100%;
    left:0;
    position:absolute;
    position:fixed;
    top: 0;
    width:100%;
    z-index:5000;
    @keyframes show-bg{
        0%{
            background:rgba(0, 0, 0, 0)
        }
        100%{
            background:rgba(37, 39, 51, 0.7);
        }
    }
    .modal-container{
        box-sizing:border-box;
        height:100vh;
    }
    .modal-container__box{
        -ms-flex: 0 0 645px;
        -webkit-flex: 0 0 645px;
        flex: 0 0 645px;
        max-width: 645px;
        background: #FAFAFA;
        border-radius:10px;
        box-sizing:border-box;
        max-height: 700px;
        overflow: auto;
        padding: 20px 20px 30px 30px;
    }
    .modal-container__box__header{
        h2{
           color: #252733;
           font-family: 'Mulish';
           font-style: normal;
           font-weight: 600;
           font-size: 18px;
           line-height: 23px;
           margin-top: 3.5px;
        }
        img{
            cursor: pointer;
        }
    }  
    .modal-container__box__content{
        margin-top: 22px;
        h2{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            margin-bottom: 10px;
        }
        .input{
            background: #FCFDFE;
            border: 1px solid #F0F1F7;
            border-radius: 8px;
            box-sizing: border-box;
            color: rgba(37, 39, 51, 0.4);
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            height: 83px;
            line-height: 21px;
            padding: 0 16px 0 16px;
            width: 100%;
            &::placeholder{
                color: rgba(37, 39, 51, 0.4);
            }
        }
    }
    .modal-container__box__action{
        box-sizing: border-box;
        justify-content: flex-end;
        margin-top: 37px;
        padding-right: 20px;
        .button{
            border-radius: 5px;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            height: 38px;
            margin-bottom: 0;
            position: relative;
            img{
                position: absolute;
                left: 0;
                margin-left: 20px;
                margin-top: 11px;
                top: 0;
            }
        }
        .--cancel{
            background: #F5F5F5;
            box-shadow: 0px 4px 12px rgba(37, 39, 51, 0.08);
            color: #252733;
            margin-right: 8px;
            // padding-top: 11px;
            text-align: center;
            width: 81px;
        }
        .--approve{
            background: #EEFBF7;
            box-shadow: 0px 8px 14px rgba(37, 39, 51, 0.06);
            padding: 11px 15px 11px 39px;
            text-align: left;
            width: 161px;
            p{
                color: #136C22;
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                text-align: left;
            }
        }
    }
    
`
export default ApprovePaymentsModalStyle;