import styled from 'styled-components';

const MainLayoutTemplateStyle = styled.div`
  min-height: 100vh;
  width: 100%;

  .dashboard{
    position: relative;
  }
  .dashboard__menu{
    -ms-flex: 0 0 23%;
    -webkit-flex: 0 0 23%;
    flex: 0 0 23%;
    max-width: 23%;
    left: 0;
    position: fixed;
    top: 0;
  }
  .dashboard__content{
    -ms-flex: 0 0 77%;
    -webkit-flex: 0 0 77%;
    flex: 0 0 77%;
    max-width: 77%;
    box-sizing: border-box;
    margin-left: 23%;
  }
`;

export default MainLayoutTemplateStyle;
