import styled from "styled-components";

const SuccessModalStyle = styled.div`
    animation: show-bg 0.5s linear forwards;
    box-sizing:border-box;
    display:flex;
    height:100%;
    left:0;
    position:absolute;
    position:fixed;
    top: 0;
    width:100%;
    z-index:5000;
    @keyframes show-bg{
        0%{
            background:rgba(0, 0, 0, 0)
        }
        100%{
            background:rgba(37, 39, 51, 0.75);
        }
    }
    .modal-container{
        box-sizing:border-box;
        height:100vh;
    }
    .modal-container__box{
        -ms-flex: 0 0 378px;
        -webkit-flex: 0 0 378px;
        flex: 0 0 378px;
        max-width: 378px;
        background:#FFFFFF;
        border-radius:10px;
        box-sizing:border-box;
        height: 354px;
        padding:50px 30px 50px 30px;
    }
    .modal-container__box__header{
        h2{
           color: #252733;
           font-family: 'Mulish';
           font-style: normal;
           font-weight: 700;
           font-size: 1.375em;
           line-height: 30px;
        }
        img{
            cursor: pointer;
        }
    }
    .modal-container__box__content{
        
    }
    .modal-container__box__content__message{
        margin-top: 17px;
        h2{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 30px;
            margin-bottom: 5.5px;
            text-align: center;
        }
        p{
            color: rgba(75, 80, 109, 0.7);
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            width: 100%;
            span{
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 600;
            }
        }
    }
    .modal-container__box__action{
        margin-top: 32px;
        .button{
            background: #3751FF;
            border-radius: 6px;
            box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
            color: white;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            height: 40px;
            margin-bottom: 0;
            width: 105px;
        }
    }
    


    
`
export default SuccessModalStyle;