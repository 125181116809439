import React, { useState, useEffect } from 'react';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import DashboardHeaderStyle from './DashboardHeaderStyle';
import searchIcon from '../../../assets/images/search-icon.svg';
import notificationIcon from '../../../assets/images/notification-icon.svg';
import exportIcon from '../../../assets/images/export-icon.svg';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { alertActions } from '../../../redux/actions/AlertActions';
import Button from '../../atoms/Button/Button';
import ApprovalModalCreate from '../ApprovalModalCreate/ApprovalModalCreate';
import { appInstance as axios } from '../../../axios';
import dayjs from 'dayjs';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import ApprovalModal from '../ApprovalModal/ApprovalModal';
import ApprovalModalUpdate from '../ApprovalModalUpdate/ApprovalModalUpdate';
import relativeTime from 'dayjs/plugin/relativeTime';
import power from '../../../assets/images/power.png';

const DashboardHeader = () => {
  const state = useSelector((state: RootStore) => state);
  let timeOutId: any = null;
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [size, setSize] = useState(5);
  const [page, setPage] = useState(1);
  const [status, setStatus]: any = useState();
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [notificationPaginationData, setNotificationPaginationData]:any = useState({});
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [toggleApprovalCreateModal, setToggleApprovalCreateModal] = useState(false);
  const [toggleApprovalUpdateModal, setToggleApprovalUpdateModal] = useState(false);
  const [toggleApprovalModal, setToggleApprovalModal] = useState(false);
  const [approvalDetails, setApprovalDetails]:any = useState({});
  const dispatch: TypedDispatch = useDispatch();
  const navigate = useNavigate();
  dayjs.extend(relativeTime)

  useEffect(() => {
    if(Object.keys(approvalDetails).length > 0 && approvalDetails.entityName === ".Payment"){
      let paymentReference = JSON.parse(approvalDetails.recordAfter).paymentReference;
      fetchPayment(paymentReference)
      return;
    }
    if(Object.keys(approvalDetails).length > 0 && approvalDetails.status === 1 && approvalDetails.action === "CREATE"){
      showApprovalModalCreate();
      return;
    }
    if(Object.keys(approvalDetails).length > 0 && approvalDetails.status === 1 && approvalDetails.action === "UPDATE"){
      showApprovalModalUpdate();
      return;
    }
    if(Object.keys(approvalDetails).length > 0 && (approvalDetails.status === 0 || approvalDetails.status === 2)){
      showApprovalModal();
      return;
    }
  }, [approvalDetails])

  useEffect(() => {
    if (!selectedMenu || page > 1 || status === undefined || status === 1) {
      generatePageContent();
      fetchUnreadNotifications();
    }
  }, [size, page, selectedMenu, status])

  const generatePageContent = async () => {
    try {
      setNotificationLoading(true);
      const res = await axios.get(`/audit/notifications?size=${size}&page=${page}&userId=${state.authReducer.results.user_id}&${status !== undefined ? `status=${status}` : ''}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      let totalNotifications:any = [...notifications, ...res.data.data.content];
      setNotifications(totalNotifications);
      setNotificationPaginationData(res.data.data);
      setNotificationLoading(false);
    } catch (error: any) {
      setNotificationLoading(false);
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const fetchUnreadNotifications = async () => {
    try {
      const res = await axios.get(`/audit/notifications?size=0&page=1&userId=${state.authReducer.results.user_id}&status=1`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setUnreadNotifications(res.data.data.totalElements)
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const fetchPayment = async (reference: string) => {
    try {
      const res = await axios.get(`/payment/fetchpayments?paymentReference=${reference}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      if(res.data.data.content[0].stage === "FC"){
        navigate(`/payments/pending-approval/summary/authorize?paymentReference=${reference}`);
      }
      if(res.data.data.content[0].stage === "AP"){
        navigate(`/payments/pending-approval/summary?paymentReference=${reference}`);
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const renderFetchLoading = () => {
    if (fetchLoading) {
      return (
        <FetchLoading />
      )
    }
  }

  const logout = async () => {
    await dispatch(alertActions.clear());
    await Cookies.remove("userToken");
    navigate('/');
  }

  const onClickHandler = (value: boolean) => {
    if (selectedMenu !== false) {
      setSelectedMenu(false);
      setStatus();
      setPage(1);
      setNotifications([]);
      return;
    }
    setSelectedMenu(value);
  }

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
      setNotifications([]);
      setSelectedMenu(false);
      setStatus();
      setPage(1);
    });
  }

  const onFocusHandler = () => {
    clearTimeout(timeOutId);
  }

  const showUnread = () => {
    setStatus(1);
    setNotifications([]);
  }

  const showAll = () => {
    setStatus();
    setNotifications([]);
  }

  const showApprovalModalCreate = () => {
    setToggleApprovalCreateModal(true);
  }

  const hideApprovalModalCreate = () => {
    setToggleApprovalCreateModal(false);
  }

  const renderApprovalModalCreate = () => {
    if (toggleApprovalCreateModal) {
      return (
        <ApprovalModalCreate handleClose={hideApprovalModalCreate} data={approvalDetails} />
      )
    }
  }

  const showApprovalModalUpdate = () => {
    setToggleApprovalUpdateModal(true);
  }

  const hideApprovalModalUpdate = () => {
    setToggleApprovalUpdateModal(false);
  }

  const renderApprovalModalUpdate = () => {
    if (toggleApprovalUpdateModal) {
      return (
        <ApprovalModalUpdate handleClose={hideApprovalModalUpdate} data={approvalDetails} />
      )
    }
  }

  const showApprovalModal = () => {
    setToggleApprovalModal(true);
  }

  const hideApprovalModal = () => {
    setToggleApprovalModal(false);
  }

  const renderApprovalModal = () => {
    if (toggleApprovalModal) {
      return (
        <ApprovalModal handleClose={hideApprovalModal} data={approvalDetails} />
      )
    }
  }

  const loadMore = () => {
    let newPage = page;
    if(notificationPaginationData.last === false){
      setPage(newPage + 1);
    }
  }

  const processNotification = (notificationType: number, version: number, notificationId: number, approvalStateId: number) => {
    if (notificationType === 2 && version === 0) {
      markAsRead(notificationId);
    }
    else if(notificationType === 1){
      markAsRead(notificationId);
      fetchApprovalDetails(approvalStateId)
    }
  }

  const fetchApprovalDetails = async (approvalStateId: number) => {
    try {
      setFetchLoading(true);
      const res = await axios.get(`/audit/approval/${approvalStateId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      const data = {
        ...res.data.data,
        approvalStateId
      }
      setApprovalDetails(data);
      setFetchLoading(false);
    } catch (error: any) {
      setFetchLoading(false);
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const markAsRead = async (notificationId: number) => {
    try {
      setFetchLoading(true);
      const res = await axios.put(`/audit/notifications/${notificationId}`,
        {
          read: 0
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get("userToken")}`
          }
        }
      );
      generatePageContent();
      setFetchLoading(false);
    } catch (error: any) {
      setFetchLoading(false);
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  return (
    <>
      {renderFetchLoading()}
      {renderApprovalModalCreate()}
      {renderApprovalModal()}
      {renderApprovalModalUpdate()}
      <DashboardHeaderStyle>
        <div className={`container-full dashboard-header`}>
          <div className={`dashboard-header__actions`}>
            {/* <div className={`dashboard-header__actions__item`}>
              <Image image={searchIcon} />
            </div> */}
            <div className={`dashboard-header__actions__item`} onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
              <button className={`button --elipses`} onClick={() => onClickHandler(true)} aria-haspopup="true" aria-expanded={selectedMenu}>
                <Image image={notificationIcon} className="notif" />
                <div className={`dashboard-header__actions__item__badge`}>
                  <Text value={unreadNotifications > 10 ? `10+` : `${unreadNotifications}`} />
                </div>
              </button>
              {
                selectedMenu !== false && (
                  <button className='unset'>
                    <div className={`container-full more-info`}>
                      <div className={`container-full more-info__desc`}>
                        <HeaderText value="Notifications" />
                      </div>
                      <div className={`more-info__nav`}>
                        <div className={`more-info__nav__item ${status === undefined ? '--active-nav' : ''
                          }`} onClick={() => showAll()}>
                          <Text value={`All`} />
                        </div>
                        <div className={`more-info__nav__item ${status === 1 ? '--active-nav' : ''
                          }`} onClick={() => showUnread()}>
                          <Text value={`Unread`} />
                        </div>
                      </div>
                      <div className={`container-full more-info__notifications`}>
                        {
                          notifications.length > 0 && notifications.map((notification: Record<any, any>, key: number) => {
                            return (
                              <div className={`container-full more-info__notifications__item ${notification.status ? '--active' : ''
                                }`} onClick={() => processNotification(notification.notificationType, notification.version, notification.id, notification.approvalStateId)} key={key}>
                                <div className={`div-sm-12 more-info__notifications__item__desc`}>
                                  <HeaderText value={notification.displayData} />
                                </div>
                                <div className={`container-full more-info__notifications__item__details`}>
                                  <Text value={`${dayjs(notification.createdDate).fromNow()}`} />
                                  <div className='more-info__notifications__item__details__dot'></div>
                                  <Text value='App Section' />
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                      <div className={`container-full more-info__action`}>
                        <Button className={`button ${notificationPaginationData.last === true ? 'disabled' : ''}`} value="Load More" isLoading={notificationLoading} onClick={() => loadMore()} />
                      </div>
                    </div>
                  </button>
                )
              }
            </div>
            <div className={`dashboard-header__actions__item`} onClick={() => logout()}>
              <Image image={power} className="logout-icon" />
            </div>
          </div>
        </div>
      </DashboardHeaderStyle>
    </>
  );
};

export default DashboardHeader;
