import styled from "styled-components";

const CreateIssuerModalStyle = styled.div`
    animation: show-bg 0.5s linear forwards;
    box-sizing:border-box;
    display:flex;
    height:100%;
    left:0;
    position:absolute;
    position:fixed;
    top: 0;
    width:100%;
    z-index:5000;
    @keyframes show-bg{
        0%{
            background:rgba(0, 0, 0, 0)
        }
        100%{
            background:rgba(37, 39, 51, 0.7);
        }
    }
    .modal-container{
        box-sizing:border-box;
        height:100vh;
    }
    .modal-container__box{
        -ms-flex: 0 0 500px;
        -webkit-flex: 0 0 500px;
        flex: 0 0 500px;
        max-width: 500px;
        background: #FAFAFA;
        border-radius:10px;
        box-sizing:border-box;
        padding: 15px;
    }
    .modal-container__box__header{
        box-sizing: border-box;
        padding-left: 10px;
        h2{
           color: #252733;
           font-family: 'Mulish';
           font-style: normal;
           font-weight: 700;
           font-size: 1.375em;
           line-height: 30px;
        }
        img{
            cursor: pointer;
        }
    }
    .modal-container__box__content{
        box-sizing: border-box;
        padding: 40px 40px;
    }
    .modal-container__box__content__row{
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .modal-container__box__content__row__item{
        margin-bottom: 0;
        h2{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 600;
            font-size: 1.05em;
            line-height: normal;
        }
        p{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 1em;
            line-height: normal;
            text-align: right;
        }
    }
`
export default CreateIssuerModalStyle;