import React, {useState, useEffect} from 'react';
import RejectMerchantModalStyle from './RejectMerchantModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import Input from '../../atoms/Input/Input';
import eyesClose from '../../../assets/images/eyes-pwd-close.svg';
import eyes from '../../../assets/images/eyes-pwd.svg';
import check from '../../../assets/images/checkmark-green.svg';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {SubmitActionsPOST} from '../../../redux/actions/SubmitAction';

type RejectMerchantModalProps = {
    handleClose: Function
}

const RejectMerchantModal = ({handleClose}: RejectMerchantModalProps) => {
    const dispatch: TypedDispatch = useDispatch();
    const state = useSelector( (state:RootStore) => state);
  
    return (
      <RejectMerchantModalStyle className={`container-full`}>
        <div className={`container-full center modal-container`}>
          <div className={`modal-container__box`}>
            <div className={`container-full modal-container__box__header`}>
              <HeaderText value='Reject Merchant' />
              <Image image={close} onClick={() => handleClose()}/>
            </div>
            <div className={`container-full modal-container__box__content`}>
              <HeaderText value='What is the reason for this rejection?' />
              <Input type={`text`} className="input" placeholder='Enter your reason for rejection.' />
            </div>
            <div className={`container-full center modal-container__box__action`}>
                <Button value="Cancel" className={`button --cancel`} />
                <Button value="Reject Merchant" className={`button --deactivate`}
                  isLoading={state.submitReducer.fetching}
                  type="submit"
                />
            </div>
          </div>  
        </div>
      </RejectMerchantModalStyle>
    );
}

export default RejectMerchantModal;