import styled from 'styled-components';
import search from '../../../assets/images/Search.svg';

const OngoingPaymentsListTemplateStyle = styled.div`
  background: white;
  box-sizing: border-box;
  padding: 20px 30px;
  width: 100%;

  .payments__back{
    justify-content: normal;
    a{
      display: flex;
    }
    p{
      color: rgba(37, 39, 51, 0.7);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      margin-top: 2.5px;
    }
  }

  .payments__desc{
    margin-top: 10px;
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 1.5em;
      line-height: 30px;
    }
  }
  .payments__action{
    margin-top: 10px;
  }
  .payments__action__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }
  }
  .payments__action__search{
    display: flex;
  }
  .payments__action__search__toggle{
    display: flex;
    margin-right: 12px;
    p{
      align-self: center;
      color: #3751FF;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      margin-right: 4px;
      margin-top: 2px;
    }
  }
  .payments__action__search__toggle__switch{
    align-self: center;
    position: relative;
    .input{
      cursor: pointer;
      height: 18px;
      position: absolute;
      opacity: 0;
      width: 30px;
      &:checked + .payments__action__search__toggle__switch__box{
        background: #1B9830;
        .payments__action__search__toggle__switch__box__ball{
          background: white;
          margin-left: 10px;
        }
      }
    }
  }
  .payments__action__search__toggle__switch__box{
    background: #CCCCCC;
    border-radius: 70px;
    box-sizing: border-box;
    cursor: pointer;
    height: 18px;
    padding: 2px;
    transition: all 0.2s linear;
    width: 30px;
  }
  .payments__action__search__toggle__switch__box__ball{
    background: white;
    border-radius: 50%;
    height: 14px;
    transition: margin 0.5s linear;
    width: 14px;
  }
  .btn-group{
    .button{
      all: unset;
      margin-bottom: 0;
    }
    .--export{
      background: #FAFAFA;
      border-radius: 8px;
      box-sizing: border-box;
      height: 34px;
      margin-right: 10px;
      padding: unset;
      padding: 4px 8px;
      width: 34px;
    }
    .--disabled{
      opacity: 0.5;
      pointer-events: none;
    }
    .--user{
      background: #3751FF;
      border-radius: 8px;
      box-sizing: border-box;
      cursor: pointer;
      height: 34px;
      padding: unset;
      padding: 8px 8px;
      width: 34px;
    }
  }
  .payments__error{
    margin-top: 15.5px;
  }
  .payments__error__box{
    background: #FAFAFA;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    height: 34px;
    padding: 7px 12px 7px 12px;
    // width: 202px;
    .error{
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      margin-right: 68px;
      margin-top: 2px;
    }
    img{
      margin-right: 10px;
    }
    .counter{
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 9px;
      line-height: 12px;
      margin-top: 2px;
      span{
        color: #252733;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        margin-right: 3px;
      }
    }
  }
  .payments__error__processed{
    display: flex;
    img{
      animation: pop 2s linear infinite forwards;
      height: 20px;
      width: 20px;
    }
    @keyframes pop{
      0%{
        transform: scale(0.8, 0.8)
      }
      100%{
        transform: scale(1.1, 1.1)
      }
    }
    p{
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 9px;
      line-height: 12px;
    }
    span{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      margin-right: 3px;
    }
  }
  
  .payments__table{
    margin-top: 5px;
    // overflow: hidden;
  }
  
`;

export default OngoingPaymentsListTemplateStyle;
