import { combineReducers } from 'redux';
import alertReducer from './AlertReducer';
import authReducer from './AuthReducer';
import dashboardReducer from './DashboardReducer';
import exportReducer from './ExportReducer';
import submitReducer from './SubmitReducer';
import stepReducer from './StepReducer';
import feeStepReducer from './FeeStepReducer';
import addSourceAccountStepReducer from './AddSourceAccountStepReducer';
import paymentStepReducer from './PaymentStepReducer';

export const rootReducer = combineReducers({
  alertReducer,
  authReducer,
  dashboardReducer,
  exportReducer,
  submitReducer,
  stepReducer,
  feeStepReducer,
  addSourceAccountStepReducer,
  paymentStepReducer
});
