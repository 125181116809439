import React, {useEffect, useState} from 'react';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import RolesAndPermissionsStyle from './RolesAndPermissionsStyle';
import photo from '../../../assets/images/Photo Split.svg';
import photo1 from '../../../assets/images/Photo Split (1).svg';
import photo2 from '../../../assets/images/Photo Split (2).svg';
import info from '../../../assets/images/info.svg';
import nike from '../../../assets/images/nike.svg';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {DashboardActions} from '../../../redux/actions/DashboardAction';
import {SubmitActionsPUT} from '../../../redux/actions/SubmitAction';
import { alertActions } from '../../../redux/actions/AlertActions';
import {appInstance as axios} from '../../../axios';
import Button from '../../atoms/Button/Button';
import Cookies from 'js-cookie';

type RolesAndPermissionsProps = {
  reloadPage: boolean
}

const RolesAndPermissions = ({reloadPage}: RolesAndPermissionsProps) => {
  const [fetchLoading, setFetchLoading] = useState(true);
  const [selectedRole, setSelectedRole]: any = useState({});
  const [permissions, setPermissions] = useState([]);
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector( (state:RootStore) => state);
  
  useEffect(() => {
    fetchAllRoles();
  }, [reloadPage])

  const fetchAllRoles = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/roles`));
    console.log(state);
    setFetchLoading(false);
  }

  const renderFetchLoading = () => {
    if(fetchLoading){
      return(
        <FetchLoading />
      )
    }
  }

  const fetchSelectedRoleContent = async (id: string, roleLevel: string, roleType: string) => {
    try{
      setFetchLoading(true);
      const res = await axios.get(`/roles?roleId=${id}&roleLevel=${roleLevel}&roleType=${roleType}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setFetchLoading(false);
      setSelectedRole(res.data.data.content[0]);
    }catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const fetchAllPermissions = async (roleLevel: string, roleType: string) => {
    try{
      setFetchLoading(true);
      const res = await axios.get(`/roles/permissions?roleLevel=${roleLevel}&roleType=${roleType}&size=0&page=1`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setFetchLoading(false);
      setPermissions(res.data.data.content);
    }catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const fetchRolesAndPermissions = async (id: string, roleLevel: string, roleType: string) => {
    await fetchSelectedRoleContent(id, roleLevel, roleType);
    await fetchAllPermissions(roleLevel, roleType);
  }

  const statusChange = () => {
    let role;
    if(selectedRole.status){
      role = {
        ...selectedRole,
        status: 0
      }
    }
    else{
      role = {
        ...selectedRole,
        status: 1
      }
    }
    setSelectedRole(role); 
  }

  const togglePermissions = (permission:any) => {
    if(selectedRole.permissions && selectedRole.permissions.some((role: Record<any, any>) => role.id === permission.id)){
      let filterPermissions = selectedRole.permissions.filter((role: Record<any, any>) => role.id !== permission.id);
      let newRole = {
        ...selectedRole,
        permissions: filterPermissions
      }
      setSelectedRole(newRole);
    }
    else{
      let newRole = {
        ...selectedRole,
        permissions: [
          ...selectedRole.permissions,
          permission
        ]
      }
      setSelectedRole(newRole);
    }
  }

  const saveChanges = async () => {
    let permissions:any = [];
    await selectedRole.permissions.map((permission: Record<any, any>) => {
      permissions.push(permission.id)
    })
    let value = {
      // "description": selectedRole.description,
      // "name": selectedRole.name,
      "permissions": permissions,
      "status": selectedRole.status
    }
    await dispatch(
      SubmitActionsPUT(`/roles/${selectedRole.id}`, value)
    )
  }

  return (
    <>
      {renderFetchLoading()}
      <RolesAndPermissionsStyle>
          <div className={`container-full roles`}>
            <div className={`roles__roles-box`}>
              <div className={`roles__roles-box__group`}>
                <Text value="DEFAULT ROLES" />
                <div className={`container-full roles__roles-box__group__list`}>
                  {
                    (state.dashboardReducer.results && state.dashboardReducer.results.data.content.map((role: Record<any, any>) => {
                      if(role.defaultRole === 1){
                        return(
                          <div className={`container-full roles__roles-box__group__list__item ${
                            (selectedRole.id !== undefined && selectedRole.id === role.id) ? '--active-role' : ''
                          }`} onClick={() => fetchRolesAndPermissions(role.id, role.roleLevel, role.roleType)}
                          key={role.id}>
                            <HeaderText value={role.name} />
                          </div>
                        )
                      }
                    }))
                  }
                </div>
              </div>
              <div className={`roles__roles-box__group --user-created-roles`}>
                <Text value="USER-CREATED ROLES" />
                <div className={`container-full roles__roles-box__group__list`}>
                  {
                    (state.dashboardReducer.results && state.dashboardReducer.results.data.content.map((role: Record<any, any>) => {
                      if(role.defaultRole === 0){
                        return(
                          <div className={`container-full roles__roles-box__group__list__item ${
                            (selectedRole.id !== undefined && selectedRole.id === role.id) ? '--active-role' : ''
                          }`} onClick={() => fetchRolesAndPermissions(role.id, role.roleLevel, role.roleType)}
                          key={role.id}>
                            <HeaderText value={role.name} />
                          </div>
                        )
                      }
                    }))
                  }
                </div>
              </div>
            </div>
            <div className={`roles__permissions`}>
              <div className={`container-full roles__permissions__desc`}>
                <HeaderText value={
                  (selectedRole.id !== undefined) ? selectedRole.name : 'Select a role to see information here'
                } />
              </div>
              {
                (selectedRole.id === undefined) ? 
                <div className={`container-full center roles__permissions__empty`}>
                  <div className={`div-md-6 roles__permissions__empty__box`}>
                    <Text value='Nothing Selected' />
                    <Text value='Select a role to see information here' />
                  </div>
                </div> : 
                <>
                  <div className={`container-full roles__permissions__info`}>
                    <div className={`roles__permissions__info__text`}>
                      <Image image={info} alt="info" />
                      <div>
                        <p> {selectedRole.description} </p>
                        {
                          selectedRole.defaultRole ? 
                          <p className='--warning'> Default roles <span> can not</span> be edited</p> : null
                        }
                      </div>
                    </div>
                    <div className={`roles__permissions__info__switch`}>
                      <div className={`roles__permissions__info__switch__checkbox`}>
                        <input type="checkbox" className={`input 
                          ${
                            selectedRole.defaultRole || (state.authReducer.results.roles.includes('Authorizer') && state.authReducer.results.roles.length === 1) 
                            || (state.authReducer.results.roles.includes('Audit') && state.authReducer.results.roles.length === 1) ? 'disabled' : ''
                          }`} 
                          checked={selectedRole.status} 
                          onChange={() => statusChange()}
                        />
                        <div className={`roles__permissions__info__switch__checkbox__box`}>
                          <div className={`roles__permissions__info__switch__checkbox__box__ball`}></div>
                        </div>
                      </div>
                      {/* <Text value="Enabled" /> */}
                    </div>
                  </div>
                  <div className={`container-full roles__permissions__box`}>
                    <div className="roles__permissions__box__left">
                      <div className={`container-full roles__permissions__box__left__desc`}>
                        <HeaderText value="Permissions" />
                        <Text value="Any user assigned this role will be able to:" />
                      </div>
                      <div className={`container-full roles__permissions__box__left__info`}>
                        <div className={`container-full roles__permissions__box__left__info__desc`}>
                          <HeaderText value='Selected Permissions' />
                          <Text value='This list will change as permissions are selected' />
                        </div>
                        <div className={`container-full roles__permissions__box__left__info__list`}>
                          {
                            selectedRole.permissions && selectedRole.permissions.length > 0 && selectedRole.permissions.map((permission: Record<any,any>) => {
                              return(
                                <div className={`container-full roles__permissions__box__left__info__list__item`} key={permission.id}>
                                  <div className={`roles__permissions__box__left__info__list__item__bullet`}></div>
                                  <Text value={permission.description} />
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                    <div className={`roles__permissions__box__right`}>
                      {
                        permissions.length > 0 && permissions.map((permission: Record<any, any>) => {
                          return(
                            <div className={`container-full roles__permissions__box__right__group`} key={permission.id}>
                              {/* <div className={`container-full roles__permissions__box__right__group__desc`}>
                                <Text value="" />
                              </div> */}
                              <div className={`container-full roles__permissions__box__right__group__item`}>
                                <Text value={permission.name} />
                                <div className={`roles__permissions__box__right__group__item__checkbox`}>
                                  <input type="checkbox" className={`input 
                                  ${
                                    selectedRole.defaultRole || (state.authReducer.results.roles.includes('Authorizer') && state.authReducer.results.roles.length === 1) 
                                    || (state.authReducer.results.roles.includes('Audit') && state.authReducer.results.roles.length === 1)? 'disabled' : null
                                  }`} 
                                  checked={selectedRole.permissions.some((role: Record<any, any>) => role.id === permission.id)} 
                                  onChange = {() => togglePermissions(permission)}
                                  />
                                  <div className={`roles__permissions__box__right__group__item__checkbox__box`}>
                                    <Image image={nike} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                      
                    </div>
                  </div>
                  {
                    !selectedRole.defaultRole && !state.authReducer.results.roles.includes('Authorizer') && !state.authReducer.results.roles.includes('Audit')  ?
                    <div className='container-full roles__permissions__action'>
                      <Button value="Submit Changes" isLoading={state.submitReducer.fetching} className={`button ${
                        selectedRole.permissions.length > 0 ? '' : 'disabled'
                      }`} onClick={() => saveChanges()} />
                    </div> : null
                  }
                </>
              }
            </div>
          </div>
      </RolesAndPermissionsStyle>
    </>
  );
};

export default RolesAndPermissions;
