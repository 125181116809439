import { StepDispatchTypes, STEP_1, STEP_CLEAR } from '../actions/AddSourceAccountStepTypes';

export interface DefaultState {
  results: any;
}

const defaultState: DefaultState = {
  results: {
    stepOne: {
     
    },
  },
};

const AddSourceAccountStepReducer = (
  state: DefaultState = defaultState,
  action: StepDispatchTypes
) => {
  switch (action.type) {
    case STEP_1:
      return {
        ...state,
        results: {
          ...state.results,
          stepOne: action.data,
        },
      };
    case STEP_CLEAR:
      return {
        ...state,
        results: {
          stepOne: {
            
          },
          stepTwo: {}
        }
      };
    default:
      return state;
  }
};

export default AddSourceAccountStepReducer;
