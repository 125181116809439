import React, { useState, useEffect } from 'react';
import UserProfileTemplateStyle from './UserProfileTemplateStyle';
import Text from '../../atoms/Text/Text';
import Input from '../../atoms/Input/Input';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import { Link } from 'react-router-dom';
import edit from '../../../assets/images/edit-icon.svg';
import document from '../../../assets/images/document.svg';
import pencil from '../../../assets/images/pencil.svg';
import Button from '../../atoms/Button/Button';
import disableIcon from '../../../assets/images/user-delete.svg';
import plusIcon from '../../../assets/images/plus-icon-blue.svg';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { DashboardActions } from '../../../redux/actions/DashboardAction';
import { SubmitActionsPUT, SubmitActionsUpload } from '../../../redux/actions/SubmitAction';
import dayjs from 'dayjs';
import { alertActions } from '../../../redux/actions/AlertActions';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';
import SettingsNavBar from '../../organisms/SettingsNavBar/SettingsNavBar';
import cogoToast from "cogo-toast"; 

const UserProfileTemplate = () => {
  let timeOutId: any = null;
  const [selectedRole, setSelectedRole] = useState(false);
  const [selectedSignatory, setSelectedSignatory] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(true);
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  console.log(state);
  const [fields, setFields] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
  })
  const [roles, setRoles]: any = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [signatoryGroups, setSignatoryGroups]: any = useState([]);
  const [signatoryGroupsList, setSignatoryGroupsList] = useState([]);
  const [url, setFileUrl] = useState<string>();

  useEffect(() => {
    if(state.submitReducer.results && state.submitReducer.results.data.fileUri){
        let data = {
          profilePhotoUri: state.submitReducer.results.data.fileUri
        }
        dispatch(SubmitActionsPUT(`/users/${state.authReducer.results.user_name}`, data))
    }
    if(state.submitReducer.results && state.submitReducer.results.data.profilePhotoUri){
      generatePageContent();
    }
  }, [state.submitReducer])

  useEffect(() => {
    if(state.dashboardReducer.results && state.dashboardReducer.results.data.content && state.dashboardReducer.results.data.content[0].profilePhotoUri){
        openFile(state.dashboardReducer.results.data.content[0].profilePhotoUri);
    }
  }, [state.dashboardReducer])

  useEffect(() => {
    if (state.submitReducer.results) {
      generatePageContent()
    }
  }, [state.submitReducer])

  useEffect(() => {
    if (state.dashboardReducer.results !== null && !fetchLoading) {
      let fields = {
        firstname: state.dashboardReducer.results.data.content[0].firstName,
        lastname: state.dashboardReducer.results.data.content[0].lastName,
        email: state.dashboardReducer.results.data.content[0].email,
        phone: state.dashboardReducer.results.data.content[0].phoneNumber
      }
      setFields(fields);
      setRoles(state.dashboardReducer.results.data.content[0].userRoles);
      setSignatoryGroups(state.dashboardReducer.results.data.content[0].signatoryGroups)
    }
  }, [state.dashboardReducer, fetchLoading])

  useEffect(() => {
    generatePageContent();
    fetchRoles();
    fetchSignatories();
    console.log(state);
  }, [])

  const generatePageContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/users?${window.location.href.split("?")[1]}`));
    console.log(state);
    setFetchLoading(false);
  }

  const fetchRoles = async () => {
    try {
      const res = await axios.get('/roles?page=1&size=0', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setRolesList(res.data.data.content);
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const fetchSignatories = async () => {
    try {
      const res = await axios.get('/signatory?page=1&size=0&status=1', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setSignatoryGroupsList(res.data.data.content);
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const toggleRoles = (item: Record<any, any>) => {
    if (roles.some((role: Record<any, any>) => role.id === item.id)) {
      const newArr = roles.filter((record: Record<any, any>) => record.id !== item.id)
      console.log(newArr);
      return setRoles(newArr);
    }
    const update = [...roles, item];
    setRoles(update);
  }

  const toggleSignatories = (item: Record<any, any>) => {
    if (signatoryGroups.some((signatory: Record<any, any>) => signatory.id === item.id)) {
      const newArr = signatoryGroups.filter((record: Record<any, any>) => record.id !== item.id)
      console.log(newArr);
      return setSignatoryGroups(newArr);
    }
    const update = [...signatoryGroups, item];
    setSignatoryGroups(update);
  }

  const onRoleClickHandler = (value: boolean) => {
    if (selectedRole !== false) {
      setSelectedRole(false);
      return;
    }
    setSelectedRole(value);
  }

  const onRoleBlurHandler = () => {
    timeOutId = setTimeout(() => {
      setSelectedRole(false);
    });
  }

  const onRoleFocusHandler = () => {
    clearTimeout(timeOutId);
  }

  const onSignatoryClickHandler = (value: boolean) => {
    if (selectedRole !== false) {
      setSelectedSignatory(false);
      return;
    }
    setSelectedSignatory(value);
  }

  const onSignatoryBlurHandler = () => {
    timeOutId = setTimeout(() => {
      setSelectedSignatory(false);
    });
  }

  const onSignatoryFocusHandler = () => {
    clearTimeout(timeOutId);
  }

  const renderFetchLoading = () => {
    if (fetchLoading) {
      return (
        <FetchLoading />
      )
    }
  }

  const onFormSubmit = () => {
    return async () => {
      const fieldValue = fields;
      const defaultRolesArr: any = [];
      state.dashboardReducer.results.data.content[0].userRoles.map((role: Record<any, any>) => {
        defaultRolesArr.push(role.id);
      })
      const defaultSignatoriesArr: any = [];
      state.dashboardReducer.results.data.content[0].signatoryGroups.map((signatory: Record<any, any>) => {
        defaultSignatoriesArr.push(signatory.id);
      })
      const rolesArr: any = [];
      roles.map((role: Record<any, any>) => {
        rolesArr.push(role.id);
      })
      const signatoriesArr: any = [];
      signatoryGroups.map((signatory: Record<any, any>) => {
        signatoriesArr.push(signatory.id);
      })
      const defaultValues: {
        [key: string]: any
      } = {
        "email": state.dashboardReducer.results.data.content[0].email,
        "firstName": state.dashboardReducer.results.data.content[0].firstName,
        "lastName": state.dashboardReducer.results.data.content[0].lastName,
        "phoneNumber": state.dashboardReducer.results.data.content[0].phoneNumber,
        "signatoryGroups": defaultSignatoriesArr,
        "status": state.dashboardReducer.results.data.content[0].status,
        "userLocked": false,
        "userRoles": defaultRolesArr
      }
      const newValues: {
        [key: string]: any
      } = {
        "email": fieldValue.email,
        "firstName": fieldValue.firstname,
        "lastName": fieldValue.lastname,
        "phoneNumber": fieldValue.phone,
        "signatoryGroups": signatoriesArr,
        "status": state.dashboardReducer.results.data.content[0].status,
        "userLocked": false,
        "userRoles": rolesArr
      }
      let diff = Object.keys(newValues).reduce((diff, key) => {
        if (defaultValues[key] === newValues[key]) return diff
        return {
          ...diff,
          [key]: newValues[key]
        }
      }, {})

      await dispatch(
        SubmitActionsPUT(`/users/${state.dashboardReducer.results.data.content[0].username}`, diff)
      )
    }
  }

  const inputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues[name] = value;
    await setFields(fieldsValues);
  };

  const enableUser = async () => {
    await dispatch(
      SubmitActionsPUT(`/users/${state.dashboardReducer.results.data.content[0].username}`, {
        status: 1
      })
    )
  }

  const disableUser = async () => {
    await dispatch(
      SubmitActionsPUT(`/users/${state.dashboardReducer.results.data.content[0].username}`, {
        status: 0
      })
    )
  }

  const openFile = async (fileUri: string) => { 
    const apiDefinition = () => {
      return axios.get(`${fileUri.substring(4)}`,
        {
          responseType: "blob",
          headers: {
            "Authorization": `Bearer ${Cookies.get("userToken")}`,
          }
        }
      );
    };
    try {
      setFetchLoading(true);
      const { data } = await apiDefinition();
      const url = URL.createObjectURL(new Blob([data]));
      setFileUrl(url);
      setFetchLoading(false);
    } catch (error) {
      cogoToast.error("Error downloading file", { position: "top-right" })
    }
  }

  const fileUpload = async (file: any) => {
    setFetchLoading(true);
    let value = {
        file,
        fileType: 'OTHERS'
    }
    await dispatch(SubmitActionsUpload('/files/upload/', value));
    setFetchLoading(false);
  }

  return (
    <>
      {renderFetchLoading()}
      <UserProfileTemplateStyle>
        <div className={`container-full settings`}>
          <div className={`container-full settings__desc`}>
            <HeaderText value="Settings" />
          </div>
          <SettingsNavBar />
          <div className={`container-full settings__content`}>
            <div className={`container-full settings__content__desc`}>
              <HeaderText value='Your Profile' />
            </div>
            <div className={`container-full settings__content__body`}>
              <div className={`settings__content__body__logo`}>
                <div className={`container-full settings__content__body__logo__desc`}>
                  <HeaderText value='LOGO' />
                </div>
                <div className={`container-full settings__content__body__logo__box`}>
                  {
                    (state.dashboardReducer.results && url) ? 
                    <Image className="img img-rc" image={url}/> : null
                   }
                </div>
                <div className={`settings__content__body__logo__file`}>
                  {/* <Text value='Logo' className={`logo`} />
                  <Text value='YASSS.jpeg' className={`file-name`} /> */}
                  <Image image={edit} alt="edit" />
                  <Input type="file" className={`input`} onChange={(event) => fileUpload(event.target.files[0])} accept="image/*" />
                </div>
                <div className={`settings__content__body__logo__action`}>
                  {
                    (state.dashboardReducer.results && state.dashboardReducer.results.data.content[0].status && !state.authReducer.results.roles.includes('Authorizer') && !state.authReducer.results.roles.includes('Audit')) ?
                      <Button value={
                        <>
                          <Image image={disableIcon} />
                          <p>Disable this User</p>
                        </>
                      }
                        className='button'
                        isLoading={state.submitReducer.fetching}
                        onClick={() => disableUser()}
                      /> : null
                  }
                  {
                    (state.dashboardReducer.results && !state.dashboardReducer.results.data.content[0].status && !state.authReducer.results.roles.includes('Authorizer') && !state.authReducer.results.roles.includes('Audit')) ?
                      <Button value={
                        <>
                          <Image image={disableIcon} />
                          <p>Enable this User</p>
                        </>
                      }
                        className='button --enable'
                        isLoading={state.submitReducer.fetching}
                        onClick={() => enableUser()}
                      /> : null
                  }
                </div>
              </div>
              <div className={`settings__content__body__details`}>
                <div className={`container-full settings__content__body__details__section`}>
                  <div className={`container-full settings__content__body__details__section__desc`}>
                    <HeaderText value='User details' />
                  </div>
                  <div className={`container-full settings__content__body__details__section__box`}>
                    <div className={`container-full settings__content__body__details__section__box__row`}>
                      <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                        <HeaderText value='First Name' />
                        <Input type="text" readOnly className="input" name="firstname" value={fields.firstname} onChange={(evt) => inputChange(evt.target.name, evt.target.value)} />
                      </div>
                      <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                        <HeaderText value='Last Name' />
                        <Input type="text" readOnly className="input" name="lastname" value={fields.lastname} onChange={(evt) => inputChange(evt.target.name, evt.target.value)} />
                      </div>
                    </div>
                    <div className={`container-full settings__content__body__details__section__box__row`}>
                      <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                        <HeaderText value='Phone Number' />
                        <Input type="text" readOnly className="input" name="phone" value={fields.phone} onChange={(evt) => inputChange(evt.target.name, evt.target.value)} />
                      </div>
                      <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                        <HeaderText value='Email Address' />
                        <Input type="text" readOnly className="input" name="email" value={fields.email} onChange={(evt) => inputChange(evt.target.name, evt.target.value)} />
                      </div>
                    </div>
                    <div className={`container-full settings__content__body__details__section__box__row --remove-bottom`}>
                      <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                        <HeaderText value='Username' />
                        <Text value={
                          (state.dashboardReducer.results) ? state.dashboardReducer.results.data.content[0].username : ''
                        } className='disabled' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`container-full settings__content__body__details__section --timeline`}>
                  <div className={`container-full settings__content__body__details__section__desc`}>
                    <HeaderText value='Roles and Signatory Groups' />
                  </div>
                  <div className={`container-full settings__content__body__details__section__box`}>
                    <div className={`container-full settings__content__body__details__section__box__row --remove-bottom`}>
                      <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                        <HeaderText value='Role' />
                        <div className='container-full'>
                          <div className='settings__content__body__details__section__box__row__item__text'>
                            {
                              roles.length > 0 ?
                                roles.map((role: Record<any, any>) => {
                                  return (
                                    <p key={role.id}>
                                      {role.name}
                                    </p>
                                  )
                                }) : null
                            }
                          </div>

                          <div onBlur={() => onRoleBlurHandler()} onFocus={() => onRoleFocusHandler()}>
                            {/* <button className={`button --elipses`} onClick={() => onRoleClickHandler(true)} aria-haspopup="true" aria-expanded={selectedRole}>
                              <Image image={plusIcon} />
                            </button> */}
                            {
                              selectedRole !== false && (
                                <div className={`container-full more-info`}>
                                  {
                                    rolesList.length > 0 ?
                                      rolesList.map((roleList: Record<any, any>) => {
                                        return (
                                          <div className={`container-full more-info__item
                                          ${(roles.some((role: Record<any, any>) => role.id === roleList.id)) ? 'active' : ''
                                            }
                                          `} key={roleList.id}>
                                            <button onClick={() => toggleRoles(roleList)}>
                                              <Text value={roleList.name} />
                                            </button>
                                          </div>
                                        )
                                      }) : ''
                                  }
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </div>
                      <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                        <HeaderText value='Signatory Groups' />
                        <div className={`container-full`}>
                          <div className='settings__content__body__details__section__box__row__item__text'>
                            {
                              signatoryGroups.length > 0 ?
                                signatoryGroups.map((signatory: Record<any, any>) => {
                                  return (
                                    <p key={signatory.id}>
                                      {signatory.name}
                                    </p>
                                  )
                                }) : null
                            }
                          </div>
                          <div onBlur={() => onSignatoryBlurHandler()} onFocus={() => onSignatoryFocusHandler()}>
                            {/* <button className={`button --elipses`} onClick={() => onSignatoryClickHandler(true)} aria-haspopup="true" aria-expanded={selectedSignatory}>
                              <Image image={plusIcon} />
                            </button> */}
                            {
                              selectedSignatory !== false && (
                                <div className={`container-full more-info`}>
                                 {
                                    signatoryGroupsList.length > 0 ?
                                      signatoryGroupsList.map((signatoryGroupsList: Record<any, any>) => {
                                        return (
                                          <div className={`container-full more-info__item
                                          ${(signatoryGroups.some((signatory: Record<any, any>) => signatory.id === signatoryGroupsList.id)) ? 'active' : ''
                                            }
                                          `} key={signatoryGroupsList.id}>
                                            <button onClick={() => toggleSignatories(signatoryGroupsList)}>
                                              <Text value={signatoryGroupsList.name} />
                                            </button>
                                          </div>
                                        )
                                      }) : ''
                                  }
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`container-full settings__content__body__details__section --timeline`}>
                  <div className={`container-full settings__content__body__details__section__desc`}>
                    <HeaderText value='Timeline' />
                  </div>
                  <div className={`container-full settings__content__body__details__section__box`}>
                    <div className={`container-full settings__content__body__details__section__box__row`}>
                      <div className={`div-md-6 settings__content__body__details__section__box__row__item --date`}>
                        <HeaderText value='Date Added' />
                        <p>
                          {(state.dashboardReducer.results) ? dayjs(state.dashboardReducer.results.data.content[0].createdDate).format('D MMMM YYYY') : ''}
                          <span>
                            {(state.dashboardReducer.results) ? ` ${dayjs(state.dashboardReducer.results.data.content[0].createdDate).format('h:mm A')}` : ''}
                          </span>
                        </p>
                      </div>
                      <div className={`div-md-6 settings__content__body__details__section__box__row__item --date`}>
                        <HeaderText value='Last Login' />
                        <p>
                          {(state.dashboardReducer.results) ? dayjs(state.dashboardReducer.results.data.content[0].lastLoginDate).format('D MMMM YYYY') : ''}
                          <span>
                            {(state.dashboardReducer.results) ? ` ${dayjs(state.dashboardReducer.results.data.content[0].lastLoginDate).format('h:mm A')}` : ''}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className={`container-full settings__content__body__details__section__box__row --remove-bottom --ip`}>
                      <div className={`div-md-6 settings__content__body__details__section__box__row__item --date`}>
                        <HeaderText value='Last Login IP' />
                        <p>
                          {(state.dashboardReducer.results) ? state.dashboardReducer.results.data.content[0].loginIp : ''}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`container-full settings__content__body__details__section --timeline`}>
                  <div className={`container-full settings__content__body__details__section__desc`}>
                    <HeaderText value='Security' />
                  </div>
                  <div className={`container-full settings__content__body__details__section__box`}>
                    <div className={`container-full settings__content__body__details__section__box__row --remove-bottom`}>
                      <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                        <HeaderText value='Password' className='--password' />
                        <div className={`settings__content__body__details__section__box__row__item__password`}>
                          <Text value='**********************' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={`container-full settings__content__action`}>
              <Button value={`Save Changes`} className={`button`} onClick={onFormSubmit()} isLoading={state.submitReducer.fetching} />
            </div> */}

          </div>
        </div>
      </UserProfileTemplateStyle>
    </>
  );
};

export default UserProfileTemplate;
