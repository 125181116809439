import React, { useState, useEffect } from 'react';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import FeeSummaryFormStyle from './FeeSummaryFormStyle';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { signIn } from '../../../redux/actions/AuthAction';
import { useNavigate } from "react-router-dom";
import caret from '../../../assets/images/caret-down-select.svg';
import addIcon from '../../../assets/images/add-blue.svg';
import { SubmitActionsPOST } from '../../../redux/actions/SubmitAction';
import { stepClear } from '../../../redux/actions/FeeStepAction';

type FeeSummaryFormProps = {
  handleClose: Function,
  reloadPage: Function
}


const FeeSummaryForm = ({ handleClose, reloadPage }: FeeSummaryFormProps) => {
  const [activeMenu, setActiveMenu] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);

  useEffect(() => {
    if (state.submitReducer.results && submitted) {
      dispatch(stepClear())
      handleClose();
      reloadPage();
    }
  }, [state.submitReducer])

  const onFormSubmit = () => {
    return async (evt: React.FormEvent) => {
      evt.preventDefault();
      setSubmitted(true);
      await dispatch(SubmitActionsPOST(`/fee/category`, {
        "name": state.feeStepReducer.results.info.name,
        "description": state.feeStepReducer.results.info.description,
        "merchantId": window.location.href.split("?")[1].split("=")[1],
        "participants": [
          state.feeStepReducer.results.nhis,
          state.feeStepReducer.results.nhf,
          state.feeStepReducer.results.pension,
          state.feeStepReducer.results.paye,
          state.feeStepReducer.results.salary,
          state.feeStepReducer.results.deductions,
        ]
      }))
    }
  }

  return (
    <FeeSummaryFormStyle>
      <div className={`container-full form`}>
        <form onSubmit={onFormSubmit()}>
          <div className={`container-full form__content`}>
            <div className={`container-full form__content__desc`}>
              <HeaderText value='FEE DETAILS' />
            </div>
            <div className={`container-full form__content__group`}>
              <div className={`div-sm-12 form__content__group__item`}>
                <HeaderText value='Name' />
                <Text value={state.feeStepReducer.results.info.name} />
              </div>
              <div className={`div-sm-12 form__content__group__item`}>
                <HeaderText value='Description' />
                <Text value={state.feeStepReducer.results.info.description} />
              </div>
            </div>
          </div>
          <div className={`container-full form__details`}>
            <div className={`container-full form__details__desc`}>
              <HeaderText value='FEE CATEGORY DETAILS' />
            </div>
            <div className={`container-full form__details__nav`}>
              <div className={`form__details__nav__item --nhis ${activeMenu === 0 ? 'active-nav' : ''
                }`}
                onClick={() => setActiveMenu(0)}
              >
                <HeaderText value='NHIS' />
              </div>
              <div className={`form__details__nav__item --nhf ${activeMenu === 1 ? 'active-nav' : ''
                }`}
                onClick={() => setActiveMenu(1)}
              >
                <HeaderText value='NHF' />
              </div>
              <div className={`form__details__nav__item --pensions ${activeMenu === 2 ? 'active-nav' : ''
                }`}
                onClick={() => setActiveMenu(2)}
              >
                <HeaderText value='Pensions' />
              </div>
              <div className={`form__details__nav__item --paye ${activeMenu === 3 ? 'active-nav' : ''
                }`}
                onClick={() => setActiveMenu(3)}
              >
                <HeaderText value='PAYE' />
              </div>
              <div className={`form__details__nav__item --salary ${activeMenu === 4 ? 'active-nav' : ''
                }`}
                onClick={() => setActiveMenu(4)}
              >
                <HeaderText value='Salary' />
              </div>
              <div className={`form__details__nav__item --deductions ${activeMenu === 5 ? 'active-nav' : ''
                }`}
                onClick={() => setActiveMenu(5)}
              >
                <HeaderText value='Deductions' />
              </div>
            </div>
            {
              activeMenu === 0 ?
                <div className={`container-full form__details__content`}>
                  <div className={`container-full form__details__content__desc`}>
                    <HeaderText value='NHIS' />
                  </div>
                  <div className={`container-full form__details__content__group`}>
                    <div className={`container-full form__details__content__group__item`}>
                      <div className={`div-md-6`}>
                        <Text value='Fee Type' />
                      </div>
                      <div className={`div-md-6`}>
                        <div className='label'>
                          {
                            (state.feeStepReducer.results.nhis.feeType === "0") ?
                              <Text value='Scaled' /> : null
                          }
                          {
                            (state.feeStepReducer.results.nhis.feeType === "1") ?
                              <Text value='Fixed' /> : null
                          }
                          {
                            (state.feeStepReducer.results.nhis.feeType === "2") ?
                              <Text value='Percentage' /> : null
                          }
                          {
                            (state.feeStepReducer.results.nhis.feeType === "3") ?
                              <Text value='Hybrid' /> : null
                          }
                        </div>
                      </div>
                    </div>
                    <div className={`container-full form__details__content__group__item`}>
                      <div className={`div-md-6`}>
                        <Text value='Charge Type' />
                      </div>
                      <div className={`div-md-6`}>
                        {
                          (state.feeStepReducer.results.nhis.chargeType === "1") ?
                            <Text value='On Aggregated Payment' /> : null
                        }
                        {
                          (state.feeStepReducer.results.nhis.chargeType === "2") ?
                            <Text value='Per Transaction' /> : null
                        }
                      </div>
                    </div>
                    <div className={`container-full form__details__content__group__item`}>
                      <div className={`div-md-6`}>
                        <Text value='Charge To' />
                      </div>
                      <div className={`div-md-6`}>
                        {
                          (state.feeStepReducer.results.nhis.bearer === "1") ?
                            <Text value='Merchant' /> : null
                        }
                        {
                          (state.feeStepReducer.results.nhis.bearer === "2") ?
                            <Text value='Customer' /> : null
                        }
                      </div>
                    </div>
                    {
                      (state.feeStepReducer.results.nhis.feeType === "1") ?
                        <div className={`container-full form__details__content__group__item`}>
                          <div className={`div-md-6`}>
                            <Text value='Amount' />
                          </div>
                          <div className={`div-md-6`}>
                            <h2> &#8358;{state.feeStepReducer.results.nhis.feeValue} </h2>
                          </div>
                        </div> : null
                    }
                    {
                      (state.feeStepReducer.results.nhis.feeType === "2") ?
                        <div className={`container-full form__details__content__group__item`}>
                          <div className={`div-md-6`}>
                            <Text value='Percentage' />
                          </div>
                          <div className={`div-md-6`}>
                            <HeaderText value={`${state.feeStepReducer.results.nhis.feeValue}%`} />
                          </div>
                        </div> : null
                    }
                    {
                      (state.feeStepReducer.results.nhis.feeType === "3") ?
                        <>
                          <div className={`container-full form__details__content__group__item`}>
                            <div className={`div-md-6`}>
                              <Text value='Percentage' />
                            </div>
                            <div className={`div-md-6`}>
                              <HeaderText value={`${state.feeStepReducer.results.nhis.feeValue}%`} />
                            </div>
                          </div>
                          <div className={`container-full form__details__content__group__item`}>
                            <div className={`div-md-6`}>
                              <Text value='Amount' />
                            </div>
                            <div className={`div-md-6`}>
                              <h2> &#8358;{state.feeStepReducer.results.nhis.addedFee} </h2>
                            </div>
                          </div>
                        </> : null
                    }
                  </div>
                  {
                    (state.feeStepReducer.results.nhis.feeType === "0") ?
                      <div className={`container-full form__details__content__bands`}>
                        {
                          state.feeStepReducer.results.nhis.participantFeeScale.map((scale: Record<any, any>, key: number) => {
                            return (
                              <div className={`container-full form__details__content__bands__item`} key={key}>
                                <div className={`container-full form__details__content__bands__item__desc`}>
                                  <HeaderText value={`Band ${key + 1}`} />
                                </div>
                                <div className={`container-full form__details__content__bands__item__group`}>
                                  <div className={`container-full form__details__content__bands__item__group__row`}>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <Text value='Range' />
                                    </div>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <h2> &#8358;{scale.scaleFrom} - &#8358;{scale.scaleTo} </h2>
                                    </div>
                                  </div>
                                  <div className={`container-full form__details__content__bands__item__group__row`}>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <Text value='Scale Type' />
                                    </div>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <div className={`form__details__content__bands__item__group__row__item__label`}>
                                        {
                                          (scale.scaleType === "0") ?
                                            <Text value='Fixed' /> : null
                                        }
                                        {
                                          (scale.scaleType === "1") ?
                                            <Text value='Percentage' /> : null
                                        }
                                        {
                                          (scale.scaleType === "2") ?
                                            <Text value='Hybrid' /> : null
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`container-full form__details__content__bands__item__group__row`}>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <Text value='Scale Value' />
                                    </div>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      {
                                        (scale.scaleType === "0") ?
                                          <p> &#8358;{scale.scaleValue}</p> : null
                                      }
                                      {
                                        (scale.scaleType === "1" || scale.scaleType === "2") ?
                                          <Text value={`${scale.scaleValue}%`} /> : null
                                      }
                                    </div>
                                  </div>
                                  {
                                    (scale.scaleType === "2") ?
                                      <div className={`container-full form__details__content__bands__item__group__row`}>
                                        <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                          <Text value='Added Fix Fee' />
                                        </div>
                                        <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                          <p> &#8358;{scale.addedFixedFee} </p>
                                        </div>
                                      </div> : null
                                  }
                                  {
                                    (scale.scaleType !== "0") ?
                                      <>
                                        <div className={`container-full form__details__content__bands__item__group__row`}>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <Text value='Min Fee' />
                                          </div>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <p> &#8358;{scale.minFee}</p>
                                          </div>
                                        </div>
                                        <div className={`container-full form__details__content__bands__item__group__row`}>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <Text value='Max Fee' />
                                          </div>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <p> &#8358;{scale.maxFee} </p>
                                          </div>
                                        </div>
                                      </> : null
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                      </div> : null
                  }
                </div> : null
            }
            {
              activeMenu === 1 ?
                <div className={`container-full form__details__content`}>
                  <div className={`container-full form__details__content__desc`}>
                    <HeaderText value='NHF' />
                  </div>
                  <div className={`container-full form__details__content__group`}>
                    <div className={`container-full form__details__content__group__item`}>
                      <div className={`div-md-6`}>
                        <Text value='Fee Type' />
                      </div>
                      <div className={`div-md-6`}>
                        <div className='label'>
                          {
                            (state.feeStepReducer.results.nhf.feeType === "0") ?
                              <Text value='Scaled' /> : null
                          }
                          {
                            (state.feeStepReducer.results.nhf.feeType === "1") ?
                              <Text value='Fixed' /> : null
                          }
                          {
                            (state.feeStepReducer.results.nhf.feeType === "2") ?
                              <Text value='Percentage' /> : null
                          }
                          {
                            (state.feeStepReducer.results.nhf.feeType === "3") ?
                              <Text value='Hybrid' /> : null
                          }
                        </div>
                      </div>
                    </div>
                    <div className={`container-full form__details__content__group__item`}>
                      <div className={`div-md-6`}>
                        <Text value='Charge Type' />
                      </div>
                      <div className={`div-md-6`}>
                        {
                          (state.feeStepReducer.results.nhf.chargeType === "1") ?
                            <Text value='On Aggregated Payment' /> : null
                        }
                        {
                          (state.feeStepReducer.results.nhf.chargeType === "2") ?
                            <Text value='Per Transaction' /> : null
                        }
                      </div>
                    </div>
                    <div className={`container-full form__details__content__group__item`}>
                      <div className={`div-md-6`}>
                        <Text value='Charge To' />
                      </div>
                      <div className={`div-md-6`}>
                        {
                          (state.feeStepReducer.results.nhf.bearer === "1") ?
                            <Text value='Merchant' /> : null
                        }
                        {
                          (state.feeStepReducer.results.nhf.bearer === "2") ?
                            <Text value='Customer' /> : null
                        }
                      </div>
                    </div>
                    {
                      (state.feeStepReducer.results.nhf.feeType === "1") ?
                        <div className={`container-full form__details__content__group__item`}>
                          <div className={`div-md-6`}>
                            <Text value='Amount' />
                          </div>
                          <div className={`div-md-6`}>
                            <h2> &#8358;{state.feeStepReducer.results.nhf.feeValue} </h2>
                          </div>
                        </div> : null
                    }
                    {
                      (state.feeStepReducer.results.nhf.feeType === "2") ?
                        <div className={`container-full form__details__content__group__item`}>
                          <div className={`div-md-6`}>
                            <Text value='Percentage' />
                          </div>
                          <div className={`div-md-6`}>
                            <HeaderText value={`${state.feeStepReducer.results.nhf.feeValue}%`} />
                          </div>
                        </div> : null
                    }
                    {
                      (state.feeStepReducer.results.nhf.feeType === "3") ?
                        <>
                          <div className={`container-full form__details__content__group__item`}>
                            <div className={`div-md-6`}>
                              <Text value='Percentage' />
                            </div>
                            <div className={`div-md-6`}>
                              <HeaderText value={`${state.feeStepReducer.results.nhf.feeValue}%`} />
                            </div>
                          </div>
                          <div className={`container-full form__details__content__group__item`}>
                            <div className={`div-md-6`}>
                              <Text value='Amount' />
                            </div>
                            <div className={`div-md-6`}>
                              <h2> &#8358;{state.feeStepReducer.results.nhf.addedFee} </h2>
                            </div>
                          </div>
                        </> : null
                    }
                  </div>
                  {
                    (state.feeStepReducer.results.nhf.feeType === "0") ?
                      <div className={`container-full form__details__content__bands`}>
                        {
                          state.feeStepReducer.results.nhf.participantFeeScale.map((scale: Record<any, any>, key: number) => {
                            return (
                              <div className={`container-full form__details__content__bands__item`} key={key}>
                                <div className={`container-full form__details__content__bands__item__desc`}>
                                  <HeaderText value={`Band ${key + 1}`} />
                                </div>
                                <div className={`container-full form__details__content__bands__item__group`}>
                                  <div className={`container-full form__details__content__bands__item__group__row`}>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <Text value='Range' />
                                    </div>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <h2> &#8358;{scale.scaleFrom} - &#8358;{scale.scaleTo}</h2>
                                    </div>
                                  </div>
                                  <div className={`container-full form__details__content__bands__item__group__row`}>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <Text value='Scale Type' />
                                    </div>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <div className={`form__details__content__bands__item__group__row__item__label`}>
                                        {
                                          (scale.scaleType === "0") ?
                                            <Text value='Fixed' /> : null
                                        }
                                        {
                                          (scale.scaleType === "1") ?
                                            <Text value='Percentage' /> : null
                                        }
                                        {
                                          (scale.scaleType === "2") ?
                                            <Text value='Hybrid' /> : null
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`container-full form__details__content__bands__item__group__row`}>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <Text value='Scale Value' />
                                    </div>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      {
                                        (scale.scaleType === "0") ?
                                          <p> &#8358;{scale.scaleValue} </p> : null
                                      }
                                      {
                                        (scale.scaleType === "1") ?
                                          <Text value={`${scale.scaleValue}%`} /> : null
                                      }
                                      {
                                        (scale.scaleType === "2") ?
                                          <Text value={`${scale.scaleValue}%`} /> : null
                                      }
                                    </div>
                                  </div>
                                  {
                                    (scale.scaleType === "2") ?
                                      <div className={`container-full form__details__content__bands__item__group__row`}>
                                        <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                          <Text value='Added Fix Fee' />
                                        </div>
                                        <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                          <p> &#8358;{scale.addedFixedFee} </p>
                                        </div>
                                      </div> : null
                                  }
                                  {
                                    (scale.scaleType !== "0") ?
                                      <>
                                        <div className={`container-full form__details__content__bands__item__group__row`}>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <Text value='Min Fee' />
                                          </div>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <p> &#8358;{scale.minFee} </p>
                                          </div>
                                        </div>
                                        <div className={`container-full form__details__content__bands__item__group__row`}>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <Text value='Max Fee' />
                                          </div>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <p> &#8358;{scale.maxFee} </p>
                                          </div>
                                        </div>
                                      </> : null
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                      </div> : null
                  }
                  
                </div> : null
            }
            {
              activeMenu === 2 ?
                <div className={`container-full form__details__content`}>
                  <div className={`container-full form__details__content__desc`}>
                    <HeaderText value='PFA' />
                  </div>
                  <div className={`container-full form__details__content__group`}>
                    <div className={`container-full form__details__content__group__item`}>
                      <div className={`div-md-6`}>
                        <Text value='Fee Type' />
                      </div>
                      <div className={`div-md-6`}>
                        <div className='label'>
                          {
                            (state.feeStepReducer.results.pension.feeType === "0") ?
                              <Text value='Scaled' /> : null
                          }
                          {
                            (state.feeStepReducer.results.pension.feeType === "1") ?
                              <Text value='Fixed' /> : null
                          }
                          {
                            (state.feeStepReducer.results.pension.feeType === "2") ?
                              <Text value='Percentage' /> : null
                          }
                          {
                            (state.feeStepReducer.results.pension.feeType === "3") ?
                              <Text value='Hybrid' /> : null
                          }
                        </div>
                      </div>
                    </div>
                    <div className={`container-full form__details__content__group__item`}>
                      <div className={`div-md-6`}>
                        <Text value='Charge Type' />
                      </div>
                      <div className={`div-md-6`}>
                        {
                          (state.feeStepReducer.results.pension.chargeType === "1") ?
                            <Text value='On Aggregated Payment' /> : null
                        }
                        {
                          (state.feeStepReducer.results.pension.chargeType === "2") ?
                            <Text value='Per Transaction' /> : null
                        }
                      </div>
                    </div>
                    <div className={`container-full form__details__content__group__item`}>
                      <div className={`div-md-6`}>
                        <Text value='Charge To' />
                      </div>
                      <div className={`div-md-6`}>
                        {
                          (state.feeStepReducer.results.pension.bearer === "1") ?
                            <Text value='Merchant' /> : null
                        }
                        {
                          (state.feeStepReducer.results.pension.bearer === "2") ?
                            <Text value='Customer' /> : null
                        }
                      </div>
                    </div>
                    {
                      (state.feeStepReducer.results.pension.feeType === "1") ?
                        <div className={`container-full form__details__content__group__item`}>
                          <div className={`div-md-6`}>
                            <Text value='Amount' />
                          </div>
                          <div className={`div-md-6`}>
                            <h2> &#8358;{state.feeStepReducer.results.pension.feeValue} </h2>
                          </div>
                        </div> : null
                    }
                    {
                      (state.feeStepReducer.results.pension.feeType === "2") ?
                        <div className={`container-full form__details__content__group__item`}>
                          <div className={`div-md-6`}>
                            <Text value='Percentage' />
                          </div>
                          <div className={`div-md-6`}>
                            <HeaderText value={`${state.feeStepReducer.results.pension.feeValue}%`} />
                          </div>
                        </div> : null
                    }
                    {
                      (state.feeStepReducer.results.pension.feeType === "3") ?
                        <>
                          <div className={`container-full form__details__content__group__item`}>
                            <div className={`div-md-6`}>
                              <Text value='Percentage' />
                            </div>
                            <div className={`div-md-6`}>
                              <HeaderText value={`${state.feeStepReducer.results.pension.feeValue}%`} />
                            </div>
                          </div>
                          <div className={`container-full form__details__content__group__item`}>
                            <div className={`div-md-6`}>
                              <Text value='Amount' />
                            </div>
                            <div className={`div-md-6`}>
                              <h2> &#8358;{state.feeStepReducer.results.pension.addedFee} </h2>
                            </div>
                          </div>
                        </> : null
                    }
                  </div>
                  {
                    (state.feeStepReducer.results.pension.feeType === "0") ?
                      <div className={`container-full form__details__content__bands`}>
                        {
                          state.feeStepReducer.results.pension.participantFeeScale.map((scale: Record<any, any>, key: number) => {
                            return (
                              <div className={`container-full form__details__content__bands__item`} key={key}>
                                <div className={`container-full form__details__content__bands__item__desc`}>
                                  <HeaderText value={`Band ${key + 1}`} />
                                </div>
                                <div className={`container-full form__details__content__bands__item__group`}>
                                  <div className={`container-full form__details__content__bands__item__group__row`}>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <Text value='Range' />
                                    </div>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <h2> &#8358;{scale.scaleFrom} - &#8358;{scale.scaleTo}</h2>
                                    </div>
                                  </div>
                                  <div className={`container-full form__details__content__bands__item__group__row`}>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <Text value='Scale Type' />
                                    </div>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <div className={`form__details__content__bands__item__group__row__item__label`}>
                                        {
                                          (scale.scaleType === "0") ?
                                            <Text value='Fixed' /> : null
                                        }
                                        {
                                          (scale.scaleType === "1") ?
                                            <Text value='Percentage' /> : null
                                        }
                                        {
                                          (scale.scaleType === "2") ?
                                            <Text value='Hybrid' /> : null
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`container-full form__details__content__bands__item__group__row`}>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <Text value='Scale Value' />
                                    </div>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      {
                                        (scale.scaleType === "0") ?
                                          <p> &#8358;{scale.scaleValue} </p> : null
                                      }
                                      {
                                        (scale.scaleType === "1") ?
                                          <Text value={`${scale.scaleValue}%`} /> : null
                                      }
                                      {
                                        (scale.scaleType === "2") ?
                                          <Text value={`${scale.scaleValue}%`} /> : null
                                      }
                                    </div>
                                  </div>
                                  {
                                    (scale.scaleType === "2") ?
                                      <div className={`container-full form__details__content__bands__item__group__row`}>
                                        <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                          <Text value='Added Fix Fee' />
                                        </div>
                                        <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                          <p> &#8358;{scale.addedFixedFee} </p>
                                        </div>
                                      </div> : null
                                  }
                                  {
                                    (scale.scaleType !== "0") ?
                                      <>
                                        <div className={`container-full form__details__content__bands__item__group__row`}>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <Text value='Min Fee' />
                                          </div>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <p> &#8358;{scale.minFee} </p>
                                          </div>
                                        </div>
                                        <div className={`container-full form__details__content__bands__item__group__row`}>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <Text value='Max Fee' />
                                          </div>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <p> &#8358;{scale.maxFee} </p>
                                          </div>
                                        </div>
                                      </> : null
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                      </div> : null
                  }
                  
                </div> : null
            }
            {
              activeMenu === 3 ?
                <div className={`container-full form__details__content`}>
                  <div className={`container-full form__details__content__desc`}>
                    <HeaderText value='PAYE' />
                  </div>
                  <div className={`container-full form__details__content__group`}>
                    <div className={`container-full form__details__content__group__item`}>
                      <div className={`div-md-6`}>
                        <Text value='Fee Type' />
                      </div>
                      <div className={`div-md-6`}>
                        <div className='label'>
                          {
                            (state.feeStepReducer.results.paye.feeType === "0") ?
                              <Text value='Scaled' /> : null
                          }
                          {
                            (state.feeStepReducer.results.paye.feeType === "1") ?
                              <Text value='Fixed' /> : null
                          }
                          {
                            (state.feeStepReducer.results.paye.feeType === "2") ?
                              <Text value='Percentage' /> : null
                          }
                          {
                            (state.feeStepReducer.results.paye.feeType === "3") ?
                              <Text value='Hybrid' /> : null
                          }
                        </div>
                      </div>
                    </div>
                    <div className={`container-full form__details__content__group__item`}>
                      <div className={`div-md-6`}>
                        <Text value='Charge Type' />
                      </div>
                      <div className={`div-md-6`}>
                        {
                          (state.feeStepReducer.results.paye.chargeType === "1") ?
                            <Text value='On Aggregated Payment' /> : null
                        }
                        {
                          (state.feeStepReducer.results.paye.chargeType === "2") ?
                            <Text value='Per Transaction' /> : null
                        }
                      </div>
                    </div>
                    <div className={`container-full form__details__content__group__item`}>
                      <div className={`div-md-6`}>
                        <Text value='Charge To' />
                      </div>
                      <div className={`div-md-6`}>
                        {
                          (state.feeStepReducer.results.paye.bearer === "1") ?
                            <Text value='Merchant' /> : null
                        }
                        {
                          (state.feeStepReducer.results.paye.bearer === "2") ?
                            <Text value='Customer' /> : null
                        }
                      </div>
                    </div>
                    {
                      (state.feeStepReducer.results.paye.feeType === "1") ?
                        <div className={`container-full form__details__content__group__item`}>
                          <div className={`div-md-6`}>
                            <Text value='Amount' />
                          </div>
                          <div className={`div-md-6`}>
                            <h2> &#8358;{state.feeStepReducer.results.paye.feeValue} </h2>
                          </div>
                        </div> : null
                    }
                    {
                      (state.feeStepReducer.results.paye.feeType === "2") ?
                        <div className={`container-full form__details__content__group__item`}>
                          <div className={`div-md-6`}>
                            <Text value='Percentage' />
                          </div>
                          <div className={`div-md-6`}>
                            <HeaderText value={`${state.feeStepReducer.results.paye.feeValue}%`} />
                          </div>
                        </div> : null
                    }
                    {
                      (state.feeStepReducer.results.paye.feeType === "3") ?
                        <>
                          <div className={`container-full form__details__content__group__item`}>
                            <div className={`div-md-6`}>
                              <Text value='Percentage' />
                            </div>
                            <div className={`div-md-6`}>
                              <HeaderText value={`${state.feeStepReducer.results.paye.feeValue}%`} />
                            </div>
                          </div>
                          <div className={`container-full form__details__content__group__item`}>
                            <div className={`div-md-6`}>
                              <Text value='Amount' />
                            </div>
                            <div className={`div-md-6`}>
                              <h2> &#8358;{state.feeStepReducer.results.paye.addedFee} </h2>
                            </div>
                          </div>
                        </> : null
                    }
                  </div>
                  {
                    (state.feeStepReducer.results.paye.feeType === "0") ?
                      <div className={`container-full form__details__content__bands`}>
                        {
                          state.feeStepReducer.results.paye.participantFeeScale.map((scale: Record<any, any>, key: number) => {
                            return (
                              <div className={`container-full form__details__content__bands__item`} key={key}>
                                <div className={`container-full form__details__content__bands__item__desc`}>
                                  <HeaderText value={`Band ${key + 1}`} />
                                </div>
                                <div className={`container-full form__details__content__bands__item__group`}>
                                  <div className={`container-full form__details__content__bands__item__group__row`}>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <Text value='Range' />
                                    </div>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <h2> &#8358;{scale.scaleFrom} - &#8358;{scale.scaleTo}</h2>
                                    </div>
                                  </div>
                                  <div className={`container-full form__details__content__bands__item__group__row`}>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <Text value='Scale Type' />
                                    </div>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <div className={`form__details__content__bands__item__group__row__item__label`}>
                                        {
                                          (scale.scaleType === "0") ?
                                            <Text value='Fixed' /> : null
                                        }
                                        {
                                          (scale.scaleType === "1") ?
                                            <Text value='Percentage' /> : null
                                        }
                                        {
                                          (scale.scaleType === "2") ?
                                            <Text value='Hybrid' /> : null
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`container-full form__details__content__bands__item__group__row`}>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <Text value='Scale Value' />
                                    </div>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      {
                                        (scale.scaleType === "0") ?
                                          <p> &#8358;{scale.scaleValue}</p>: null
                                      }
                                      {
                                        (scale.scaleType === "1") ?
                                          <Text value={`${scale.scaleValue}%`} /> : null
                                      }
                                      {
                                        (scale.scaleType === "2") ?
                                          <Text value={`${scale.scaleValue}%`} /> : null
                                      }
                                    </div>
                                  </div>
                                  {
                                    (scale.scaleType === "2") ?
                                      <div className={`container-full form__details__content__bands__item__group__row`}>
                                        <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                          <Text value='Added Fix Fee' />
                                        </div>
                                        <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                          <p> &#8358;{scale.addedFixedFee} </p>
                                        </div>
                                      </div> : null
                                  }
                                  {
                                    (scale.scaleType !== "0") ?
                                      <>
                                        <div className={`container-full form__details__content__bands__item__group__row`}>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <Text value='Min Fee' />
                                          </div>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <p> &#8358;{scale.minFee}</p>
                                          </div>
                                        </div>
                                        <div className={`container-full form__details__content__bands__item__group__row`}>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <Text value='Max Fee' />
                                          </div>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <p> &#8358;{scale.maxFee} </p>
                                          </div>
                                        </div>
                                      </> : null
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                      </div> : null
                  }
                  
                </div> : null
            }
            {
              activeMenu === 4 ?
                <div className={`container-full form__details__content`}>
                  <div className={`container-full form__details__content__desc`}>
                    <HeaderText value='Salary' />
                  </div>
                  <div className={`container-full form__details__content__group`}>
                    <div className={`container-full form__details__content__group__item`}>
                      <div className={`div-md-6`}>
                        <Text value='Fee Type' />
                      </div>
                      <div className={`div-md-6`}>
                        <div className='label'>
                          {
                            (state.feeStepReducer.results.salary.feeType === "0") ?
                              <Text value='Scaled' /> : null
                          }
                          {
                            (state.feeStepReducer.results.salary.feeType === "1") ?
                              <Text value='Fixed' /> : null
                          }
                          {
                            (state.feeStepReducer.results.salary.feeType === "2") ?
                              <Text value='Percentage' /> : null
                          }
                          {
                            (state.feeStepReducer.results.salary.feeType === "3") ?
                              <Text value='Hybrid' /> : null
                          }
                        </div>
                      </div>
                    </div>
                    <div className={`container-full form__details__content__group__item`}>
                      <div className={`div-md-6`}>
                        <Text value='Charge Type' />
                      </div>
                      <div className={`div-md-6`}>
                        {
                          (state.feeStepReducer.results.salary.chargeType === "1") ?
                            <Text value='On Aggregated Payment' /> : null
                        }
                        {
                          (state.feeStepReducer.results.salary.chargeType === "2") ?
                            <Text value='Per Transaction' /> : null
                        }
                      </div>
                    </div>
                    <div className={`container-full form__details__content__group__item`}>
                      <div className={`div-md-6`}>
                        <Text value='Charge To' />
                      </div>
                      <div className={`div-md-6`}>
                        {
                          (state.feeStepReducer.results.salary.bearer === "1") ?
                            <Text value='Merchant' /> : null
                        }
                        {
                          (state.feeStepReducer.results.salary.bearer === "2") ?
                            <Text value='Customer' /> : null
                        }
                      </div>
                    </div>
                    {
                      (state.feeStepReducer.results.salary.feeType === "1") ?
                        <div className={`container-full form__details__content__group__item`}>
                          <div className={`div-md-6`}>
                            <Text value='Amount' />
                          </div>
                          <div className={`div-md-6`}>
                            <h2> &#8358;{state.feeStepReducer.results.salary.feeValue} </h2>
                          </div>
                        </div> : null
                    }
                    {
                      (state.feeStepReducer.results.salary.feeType === "2") ?
                        <div className={`container-full form__details__content__group__item`}>
                          <div className={`div-md-6`}>
                            <Text value='Percentage' />
                          </div>
                          <div className={`div-md-6`}>
                            <HeaderText value={`${state.feeStepReducer.results.salary.feeValue}%`} />
                          </div>
                        </div> : null
                    }
                    {
                      (state.feeStepReducer.results.salary.feeType === "3") ?
                        <>
                          <div className={`container-full form__details__content__group__item`}>
                            <div className={`div-md-6`}>
                              <Text value='Percentage' />
                            </div>
                            <div className={`div-md-6`}>
                              <HeaderText value={`${state.feeStepReducer.results.salary.feeValue}%`} />
                            </div>
                          </div>
                          <div className={`container-full form__details__content__group__item`}>
                            <div className={`div-md-6`}>
                              <Text value='Amount' />
                            </div>
                            <div className={`div-md-6`}>
                              <h2> &#8358;{state.feeStepReducer.results.salary.addedFee} </h2>
                            </div>
                          </div>
                        </> : null
                    }
                  </div>
                  {
                    (state.feeStepReducer.results.salary.feeType === "0") ?
                      <div className={`container-full form__details__content__bands`}>
                        {
                          state.feeStepReducer.results.salary.participantFeeScale.map((scale: Record<any, any>, key: number) => {
                            return (
                              <div className={`container-full form__details__content__bands__item`} key={key}>
                                <div className={`container-full form__details__content__bands__item__desc`}>
                                  <HeaderText value={`Band ${key + 1}`} />
                                </div>
                                <div className={`container-full form__details__content__bands__item__group`}>
                                  <div className={`container-full form__details__content__bands__item__group__row`}>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <Text value='Range' />
                                    </div>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <h2> &#8358;{scale.scaleFrom} - &#8358;{scale.scaleTo}</h2>
                                    </div>
                                  </div>
                                  <div className={`container-full form__details__content__bands__item__group__row`}>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <Text value='Scale Type' />
                                    </div>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <div className={`form__details__content__bands__item__group__row__item__label`}>
                                        {
                                          (scale.scaleType === "0") ?
                                            <Text value='Fixed' /> : null
                                        }
                                        {
                                          (scale.scaleType === "1") ?
                                            <Text value='Percentage' /> : null
                                        }
                                        {
                                          (scale.scaleType === "2") ?
                                            <Text value='Hybrid' /> : null
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`container-full form__details__content__bands__item__group__row`}>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <Text value='Scale Value' />
                                    </div>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      {
                                        (scale.scaleType === "0") ?
                                          <p> &#8358;{scale.scaleValue} </p> : null
                                      }
                                      {
                                        (scale.scaleType === "1") ?
                                          <Text value={`${scale.scaleValue}%`} /> : null
                                      }
                                      {
                                        (scale.scaleType === "2") ?
                                          <Text value={`${scale.scaleValue}%`} /> : null
                                      }
                                    </div>
                                  </div>
                                  {
                                    (scale.scaleType === "2") ?
                                      <div className={`container-full form__details__content__bands__item__group__row`}>
                                        <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                          <Text value='Added Fix Fee' />
                                        </div>
                                        <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                          <p> &#8358;{scale.addedFixedFee} </p>
                                        </div>
                                      </div> : null
                                  }
                                  {
                                    (scale.scaleType !== "0") ?
                                      <>
                                        <div className={`container-full form__details__content__bands__item__group__row`}>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <Text value='Min Fee' />
                                          </div>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <p> &#8358;{scale.minFee} </p>
                                          </div>
                                        </div>
                                        <div className={`container-full form__details__content__bands__item__group__row`}>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <Text value='Max Fee' />
                                          </div>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <p> &#8358;{scale.maxFee}</p>
                                          </div>
                                        </div>
                                      </> : null
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                      </div> : null
                  }
                  
                </div> : null
            }
            {
              activeMenu === 5 ?
                <div className={`container-full form__details__content`}>
                  <div className={`container-full form__details__content__desc`}>
                    <HeaderText value='Deductions' />
                  </div>
                  <div className={`container-full form__details__content__group`}>
                    <div className={`container-full form__details__content__group__item`}>
                      <div className={`div-md-6`}>
                        <Text value='Fee Type' />
                      </div>
                      <div className={`div-md-6`}>
                        <div className='label'>
                          {
                            (state.feeStepReducer.results.deductions.feeType === "0") ?
                              <Text value='Scaled' /> : null
                          }
                          {
                            (state.feeStepReducer.results.deductions.feeType === "1") ?
                              <Text value='Fixed' /> : null
                          }
                          {
                            (state.feeStepReducer.results.deductions.feeType === "2") ?
                              <Text value='Percentage' /> : null
                          }
                          {
                            (state.feeStepReducer.results.deductions.feeType === "3") ?
                              <Text value='Hybrid' /> : null
                          }
                        </div>
                      </div>
                    </div>
                    <div className={`container-full form__details__content__group__item`}>
                      <div className={`div-md-6`}>
                        <Text value='Charge Type' />
                      </div>
                      <div className={`div-md-6`}>
                        {
                          (state.feeStepReducer.results.deductions.chargeType === "1") ?
                            <Text value='On Aggregated Payment' /> : null
                        }
                        {
                          (state.feeStepReducer.results.deductions.chargeType === "2") ?
                            <Text value='Per Transaction' /> : null
                        }
                      </div>
                    </div>
                    <div className={`container-full form__details__content__group__item`}>
                      <div className={`div-md-6`}>
                        <Text value='Charge To' />
                      </div>
                      <div className={`div-md-6`}>
                        {
                          (state.feeStepReducer.results.deductions.bearer === "1") ?
                            <Text value='Merchant' /> : null
                        }
                        {
                          (state.feeStepReducer.results.deductions.bearer === "2") ?
                            <Text value='Customer' /> : null
                        }
                      </div>
                    </div>
                    {
                      (state.feeStepReducer.results.deductions.feeType === "1") ?
                        <div className={`container-full form__details__content__group__item`}>
                          <div className={`div-md-6`}>
                            <Text value='Amount' />
                          </div>
                          <div className={`div-md-6`}>
                            <h2> &#8358;{state.feeStepReducer.results.deductions.feeValue} </h2>
                          </div>
                        </div> : null
                    }
                    {
                      (state.feeStepReducer.results.deductions.feeType === "2") ?
                        <div className={`container-full form__details__content__group__item`}>
                          <div className={`div-md-6`}>
                            <Text value='Percentage' />
                          </div>
                          <div className={`div-md-6`}>
                            <HeaderText value={`${state.feeStepReducer.results.deductions.feeValue}%`} />
                          </div>
                        </div> : null
                    }
                    {
                      (state.feeStepReducer.results.deductions.feeType === "3") ?
                        <>
                          <div className={`container-full form__details__content__group__item`}>
                            <div className={`div-md-6`}>
                              <Text value='Percentage' />
                            </div>
                            <div className={`div-md-6`}>
                              <HeaderText value={`${state.feeStepReducer.results.deductions.feeValue}%`} />
                            </div>
                          </div>
                          <div className={`container-full form__details__content__group__item`}>
                            <div className={`div-md-6`}>
                              <Text value='Amount' />
                            </div>
                            <div className={`div-md-6`}>
                              <h2> &#8358;{state.feeStepReducer.results.deductions.addedFee} </h2>
                            </div>
                          </div>
                        </> : null
                    }
                  </div>
                  {
                    (state.feeStepReducer.results.deductions.feeType === "0") ?
                      <div className={`container-full form__details__content__bands`}>
                        {
                          state.feeStepReducer.results.deductions.participantFeeScale.map((scale: Record<any, any>, key: number) => {
                            return (
                              <div className={`container-full form__details__content__bands__item`} key={key}>
                                <div className={`container-full form__details__content__bands__item__desc`}>
                                  <HeaderText value={`Band ${key + 1}`} />
                                </div>
                                <div className={`container-full form__details__content__bands__item__group`}>
                                  <div className={`container-full form__details__content__bands__item__group__row`}>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <Text value='Range' />
                                    </div>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <h2> &#8358;{scale.scaleFrom} - &#8358;{scale.scaleTo}</h2>
                                    </div>
                                  </div>
                                  <div className={`container-full form__details__content__bands__item__group__row`}>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <Text value='Scale Type' />
                                    </div>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <div className={`form__details__content__bands__item__group__row__item__label`}>
                                        {
                                          (scale.scaleType === "0") ?
                                            <Text value='Fixed' /> : null
                                        }
                                        {
                                          (scale.scaleType === "1") ?
                                            <Text value='Percentage' /> : null
                                        }
                                        {
                                          (scale.scaleType === "2") ?
                                            <Text value='Hybrid' /> : null
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`container-full form__details__content__bands__item__group__row`}>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      <Text value='Scale Value' />
                                    </div>
                                    <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                      {
                                        (scale.scaleType === "0") ?
                                          <p> &#8358;{scale.scaleValue} </p> : null
                                      }
                                      {
                                        (scale.scaleType === "1") ?
                                          <Text value={`${scale.scaleValue}%`} /> : null
                                      }
                                      {
                                        (scale.scaleType === "2") ?
                                          <Text value={`${scale.scaleValue}%`} /> : null
                                      }
                                    </div>
                                  </div>
                                  {
                                    (scale.scaleType === "2") ?
                                      <div className={`container-full form__details__content__bands__item__group__row`}>
                                        <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                          <Text value='Added Fix Fee' />
                                        </div>
                                        <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                          <p> &#8358;{scale.addedFixedFee} </p>
                                        </div>
                                      </div> : null
                                  }
                                  {
                                    (scale.scaleType !== "0") ?
                                      <>
                                        <div className={`container-full form__details__content__bands__item__group__row`}>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <Text value='Min Fee' />
                                          </div>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <p> &#8358;{scale.minFee} </p>
                                          </div>
                                        </div>
                                        <div className={`container-full form__details__content__bands__item__group__row`}>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <Text value='Max Fee' />
                                          </div>
                                          <div className={`div-md-6 form__details__content__bands__item__group__row__item`}>
                                            <p> &#8358;{scale.maxFee}</p>
                                          </div>
                                        </div>
                                      </> : null
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                      </div> : null
                  }
                  
                </div> : null
            }
            
          </div>
          <div className={`container-full center form__action`}>
            <Button type="button" value="Cancel" className={`button --cancel`} onClick={() => handleClose()} />
            <Button type="submit" value="Continue" className={`button --change `} isLoading={state.submitReducer.fetching} />
          </div>
        </form>
      </div>
    </FeeSummaryFormStyle>
  );
};

export default FeeSummaryForm;
