import styled from "styled-components";
import Colors from "../../../helpers/Colors";
// import Colors from '../../../helpers/Colors';

const MechantsFeesTableStyle = styled.div`
  width: 100%;
  .merchants{
    margin-bottom: 25px;
  }
  .merchants__card{
    background: linear-gradient(0deg, #EDCFF8, #E6E2FD, #D6DCFF), #FFFFFF;
    border-radius: 10px;
    box-sizing: border-box;
    height: 135px;
    margin-bottom: 0;
    padding: 20px 11px 18.69px 20px;
  }
  .merchants__card__details{
    justify-content: normal;
    margin-bottom: 13px;
    &:last-child{
      margin-bottom: 0;
    }
    img{
      margin-right: 2px;
    }
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-feature-settings: 'salt' on;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      margin-top: 2.5px;
    }
  }
  .merchants__card__logo{
    justify-content: flex-end;
    margin-top: 35.5px;
  }
  .merchants__details{
    margin-bottom: 0;
    margin-top: 14.5px;
  }
  .merchants__details__group{
    margin-bottom: 21px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .merchants__details__group__item{
    margin-bottom: 0;
    .title{
      color: rgba(37, 39, 51, 0.3);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
    }
    .value{
      color: #252733;
      font-family: 'Mulish';
      font-feature-settings: 'salt' on;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      span{
        color: rgba(37, 39, 51, 0.5);
        font-family: 'Mulish';
        margin-left: 6px;
      }
    }
  }
  .merchant__action{
    margin-bottom: 0;
    margin-top: 28.5px;
  }
  .--elipses{
    all: unset;
    cursor: pointer;
    margin-bottom: 0;
  }
  .more-info {
    background: white;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(8, 9, 10, 0.1);
    margin-right: 82.5px;
    margin-top: 6px;
    padding: 5px;
    position: absolute;
    right: 0;
    top: 0;
    width: 141px;
    z-index: 4000;
  }
  .more-info__item{
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 2px;
    padding: 14.5px 8px 14.5px 15px;
    transition: all 0.5s linear;
    button{
      all: unset;
      cursor: pointer;
    }
    &:hover{
      background: #F5F6FF;
      background-blend-mode: multiply;
      border-radius: 5px;
    }
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }
  }
`;

export default MechantsFeesTableStyle;