import React, {useState} from 'react';
import Text from '../../atoms/Text/Text';
import ForgotPasswordFormStyle from './ForgotPasswordFormStyle';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {forgotPassword} from '../../../redux/actions/AuthAction';

type ForgotPasswordFormProps = {
  changeUsername: Function
}

const ForgotPasswordForm = ({changeUsername}: ForgotPasswordFormProps) => {
  const [username, setUsername] = useState("");
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector( (state:RootStore) => state);

  const onFormSubmit = () => {
    return async (evt: React.FormEvent) => { 
      evt.preventDefault();
      await dispatch(forgotPassword(username));
      changeUsername(username)
    }
  }

  return (
    <ForgotPasswordFormStyle>
      <div className={`container-full form`}>
          <form onSubmit={onFormSubmit()}>
              <div className={`container-full form__item`}>
                <Text value='Username' className='label' />
                <Input type='text' className='input' placeholder='Enter Username' required={true} value={username} onChange={((event) => setUsername(event.target.value))}/>
              </div>
              <div className='container-full form__action'>
                <Button type='submit' value="Get Password Reset Link" className="button" isLoading={state.authReducer.fetching} />
              </div>
          </form>
      </div>
    </ForgotPasswordFormStyle>
  );
};

export default ForgotPasswordForm;
