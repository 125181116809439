import React, { useState, useEffect } from 'react';
import PaymentReportDetailsTemplateStyle from './PaymentReportDetailsTemplateStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { DashboardActions } from '../../../redux/actions/DashboardAction';
import { exportClear } from '../../../redux/actions/ExportAction';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import dayjs from 'dayjs';
import commaNumber from 'comma-number';
import PaymentReportDetailsTable from '../../organisms/PaymentReportDetailsTable/PaymentReportDetailsTable';
import Image from '../../atoms/Image/Img';
import exportIcon from '../../../assets/images/download-icon.svg';
import Cookies from 'js-cookie';
import {appInstance as axios} from '../../../axios';
import cogoToast from "cogo-toast"; 

const PaymentReportDetailsTemplate = () => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);

  useEffect(() => {
    dispatch(exportClear())
    generatePageContent();
    console.log(state);
  }, [size, page, reloadPage])

  const generatePageContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/report/transactions?${window.location.href.split("?")[1]}&size=${size}&page=${page}`));
    setFetchLoading(false);
  }

  const renderFetchLoading = () => {
    if (fetchLoading) {
      return (
        <FetchLoading />
      )
    }
  }

  const changePage = (value: number) => {
    setPage(value);
  }

  const downloadFile = async (file: string) => {
    const apiDefinition = () => {
      return axios.get(`/files/${file}`,
        {
          responseType: "blob",
          headers: {
            "Authorization": `Bearer ${Cookies.get("userToken")}`,
          }
        }
      );
    };
    try {
      const { data } = await apiDefinition();
      const url = window.URL.createObjectURL(new Blob([data]));
      console.log(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      cogoToast.error("Error downloading file", { position: "top-right" })
    }
  }

  return (
    <>
      {renderFetchLoading()}
      <PaymentReportDetailsTemplateStyle>
        <div className={`container-full payments`}>
          <div className={`container-full payments__desc`}>
            <HeaderText value="Payment Details" />
            <button className='button --export' onClick={() => downloadFile(state.dashboardReducer.results.data.content[0].fileId)}>
              <Image image={exportIcon} />
            </button>
          </div>
          <div className='container-full payments__action'>
            <div className={`payments__action__desc`}>
              <HeaderText value='Payment List Name' />
            </div>
            <div className={`payments__action__search`}>
              <div className='btn-group'>
                {/* {
                  (state.exportReducer.results.data.length > 0) ?
                    <CSVLink data={exportData().fileData()} headers={exportData().fileHeaders()} filename={"payment report.csv"}>
                      <button className='button --export'>
                        <Image image={exportIcon} />
                      </button>
                    </CSVLink> :
                    <button className='button --export --disabled'>
                      <Image image={exportIcon} />
                    </button>
                } */}
              </div>
            </div>
          </div>
          <div className={`container-full payments__data`}>
            <div className={`container-full payments__data__row`}>
              <div className={`div-md-9 payments__data__row__left`}>
                <div className={`container-full`}>
                  <div className={`div-md-3 payments__data__row__left__item`}>
                    <HeaderText value='Payment Reference' />
                    <Text value={state.dashboardReducer.results && state.dashboardReducer.results.data.content[0].paymentRef} className={`--id`} />
                  </div>
                  <div className={`div-md-3 payments__data__row__left__item`}>
                    <HeaderText value='Description' />
                    <Text value={`ID26329`} className={`--description`} />
                  </div>
                  <div className={`div-md-3 payments__data__row__left__item`}>
                    <HeaderText value='Merchant Name' />
                    <Text value={state.dashboardReducer.results && state.dashboardReducer.results.data.content[0].merchant && state.dashboardReducer.results.data.content[0].merchant.businessName} className={`--description`} />
                  </div>
                  <div className={`div-md-3 payments__data__row__left__item`}>

                  </div>
                </div>
              </div>
              <div className={`div-md-3 payments__data__row__right`}>
                <HeaderText value='Date Range' />
                <div className={`container-full payments__data__row__right__range`}>
                  <p> <span>From:</span> {state.dashboardReducer.results && dayjs(state.dashboardReducer.results.data.content[0].startDate).format("DD/MM/YYYY")}</p>
                  <p> <span>To:</span> {state.dashboardReducer.results && dayjs(state.dashboardReducer.results.data.content[0].endDate).format("DD/MM/YYYY")}</p>
                </div>
              </div>
            </div>
            <div className={`container-full payments__data__row`}>
              <div className={`div-md-9 payments__data__row__left`}>
                <div className={`container-full`}>
                  <div className={`div-md-3 payments__data__row__left__item`}>
                    <HeaderText value='Source Account' />
                    <Text value={state.dashboardReducer.results && state.dashboardReducer.results.data.content[0].sourceAccount && state.dashboardReducer.results.data.content[0].sourceAccount.validatedName} className={`--description`} />
                  </div>
                  <div className={`div-md-3 payments__data__row__left__item`}>
                    <HeaderText value='Signatory Group' />
                    <Text value={state.dashboardReducer.results && state.dashboardReducer.results.data.content[0].signatoryGrp && state.dashboardReducer.results.data.content[0].signatoryGrp.name} className='--signatory' />
                  </div>
                  <div className={`div-md-3 payments__data__row__left__item`}>
                    <HeaderText value='Number of Records' />
                    <Text value={state.dashboardReducer.results && state.dashboardReducer.results.data.content[0].totalBenCount} className={`--record`} />
                  </div>
                  <div className={`div-md-3 payments__data__row__left__item`}>

                  </div>
                </div>
              </div>
              <div className={`div-md-3 payments__data__row__right`}>
                <HeaderText value='Amount' />
                <Text value={state.dashboardReducer.results && state.dashboardReducer.results.data.content[0].totalAmount && `₦${commaNumber(state.dashboardReducer.results.data.content[0].totalAmount)}`} className='--amount' />
              </div>
            </div>
          </div>
          <div className='container-full payments__table'>
            {
              (state.dashboardReducer.results && state.dashboardReducer.results.data.content.length > 0 && state.dashboardReducer.results.data.content[0].paymentDetails) ?
                <PaymentReportDetailsTable data={state.dashboardReducer.results.data.content[0].paymentDetails} page={page} changePage={changePage} /> : null
            }
          </div>
        </div>
      </PaymentReportDetailsTemplateStyle>
    </>
  );

};

export default PaymentReportDetailsTemplate;
