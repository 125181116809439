import React, { useState, useEffect } from 'react';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import InitiatePaymentStepThreeStyle from './InitiatePaymentStepThreeStyle';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import caret from '../../../assets/images/caret-down-select.svg';
import { SubmitActionsPOST, SubmitActionsUpload } from '../../../redux/actions/SubmitAction';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';
import { alertActions } from '../../../redux/actions/AlertActions';
import { stepThree, stepClear } from '../../../redux/actions/PaymentStepAction';
import dayjs from 'dayjs';

type InitiatePaymentStepThreeProps = {
  handleClose: Function,
  toggleUploadProgress: Function,
  reloadPage: Function,
  changeStep: Function
}

const InitiatePaymentStepThree = ({ handleClose, toggleUploadProgress, reloadPage, changeStep }: InitiatePaymentStepThreeProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  const [fields, setFields] = useState({
    signatoryGroup: '',
    sourceAccount: ''
  })
  const [uploadedFile, setUploadedFile] = useState("");
  const [signatoryList, setSignatoryList] = useState([]);
  const [sourceAccountList, setSourceAccountList] = useState([]);
  const [file, setFile] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [fileSubmitted, setFileSubmitted] = useState(false);

  useEffect(() => {
    fetchSignatories();
    fetchSourceAccounts();
    console.log(state);
  }, [])

  useEffect(() => {
    if (state.submitReducer.results && submitted) {
      handleClose();
      reloadPage();
      dispatch(stepClear())
    }
  }, [state.submitReducer])

  useEffect(() => {
    if (Object.keys(state.paymentStepReducer.results.stepThree).length > 0) {
      setFields({
        signatoryGroup: state.paymentStepReducer.results.stepThree.signatoryGroup,
        sourceAccount: state.paymentStepReducer.results.stepThree.sourceAccount,
      })
      setUploadedFile(state.paymentStepReducer.results.stepThree.invoiceUri)
    }
  }, [])

  useEffect(() => {
    if (state.submitReducer.results && fileSubmitted) {
      setUploadedFile(state.submitReducer.results.data.fileUri)
    }
  }, [state.submitReducer])

  const fetchSignatories = async () => {
    try {
      const res = await axios.get('/entities?id=258', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setSignatoryList(res.data.data.content[0].groups);
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const fetchSourceAccounts = async () => {
    try {
      const res = await axios.get(`/account?merchantId=${state.authReducer.results.merchant_id}&size=0&page=1`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setSourceAccountList(res.data.data.content);
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const inputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues[name] = value;
    await setFields(fieldsValues);
  };

  const onFormSubmit = () => {
    return async (evt: React.FormEvent) => {
      evt.preventDefault();
      let data = {
        signatoryGroup: fields.signatoryGroup,
        sourceAccount: fields.sourceAccount,
        invoiceUri: uploadedFile
      }
      await dispatch(stepThree(data));

      //refactor deductions

      let newDeductions: any = [];
      state.paymentStepReducer.results.stepTwo.map((deduction: Record<any, any>, key: number) => {
        let modifiedDeduction = {
          deductionName: deduction.dedName,
          deductionDescription: deduction.dedDesc,
          issuerCode: deduction.issuerCode.split("+")[0],
          accountNumber: deduction.accountNo,
          emailAddress: deduction.emailAddress,
          level: key + 1
        }
        newDeductions.push(modifiedDeduction)
      })

      setSubmitted(true);
      let payload = {
        "fileId": state.paymentStepReducer.results.stepOne.file.split("/v1/files/")[1],
        "reUpload": "FALSE",
        "paymentReference": "",
        "startDate": dayjs(state.paymentStepReducer.results.stepOne.dateRange.from).format("YYYY-MM-DD HH:mm:ss"),
        "endDate": dayjs(state.paymentStepReducer.results.stepOne.dateRange.to).format("YYYY-MM-DD HH:mm:ss"),
        "signatoryGrpId": fields.signatoryGroup !== "" ? fields.signatoryGroup : null,
        "sourceAccountId": fields.sourceAccount,
        "invoiceUri": uploadedFile.split("/v1/files/")[1],
        "paymentName": state.paymentStepReducer.results.stepOne.paymentName,
        "paymentDescription": state.paymentStepReducer.results.stepOne.paymentDescription,
        "deductions": newDeductions
      }
      await dispatch(SubmitActionsPOST("/payment/initiate", payload));
    }
  }

  const fileUpload = async (file: any) => {
    toggleUploadProgress(true);
    setFileSubmitted(true);
    setFile(file.name);
    let value = {
      file,
      fileType: 'PAYMENT'
    }
    await dispatch(SubmitActionsUpload('/files/upload/', value));
    toggleUploadProgress(false);
    setFileSubmitted(false);
  }



  return (
    <InitiatePaymentStepThreeStyle>
      <form onSubmit={onFormSubmit()}>
        <div className={`container-full form`}>
          <div className={`container-full form__desc`}>
            <HeaderText value='Step 3:' className='step' />
            <HeaderText value='Specify Signatory Group' className='details' />
          </div>
          <div className={`container-full form__content`}>
            <div className={`container-full form__content__row`}>
              <div className={`div-md-12 form__content__row__item`}>
                <div className='input'>
                  <select
                    name="signatoryGroup"
                    onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                    value={fields.signatoryGroup}
                  >
                    <option value=""> Select Signatory Group </option>
                    {
                      (signatoryList.length > 0 && signatoryList.map((signatory: Record<any, any>) => {
                        return (<option value={`${signatory.id}`} key={signatory.id}> {signatory.name} </option>)
                      }))
                    }
                  </select>
                  <Image image={caret} />
                </div>
                <Text value='Users in this group will complete payment approval.' />
              </div>
            </div>
            <div className={`container-full form__content__row`}>
              <div className={`div-md-12 form__content__row__item`}>
                <div className='input'>
                  <select
                    name="sourceAccount"
                    onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                    value={fields.sourceAccount}
                    required
                  >
                    <option value="" hidden> Select Source Account </option>
                    {
                      (sourceAccountList.length > 0 && sourceAccountList.map((sourceAccount: Record<any, any>) => {
                        return (<option value={`${sourceAccount.id}`} key={sourceAccount.id}> {sourceAccount.validatedName} </option>)
                      }))
                    }

                  </select>
                  <Image image={caret} />
                </div>
                <Text value='This is the source account where payment would be made from.' />
              </div>
            </div>
          </div>
          <div className={`container-full form__invoice`}>
            <div className={`container-full form__invoice__row`}>
              <div className={`div-md-12 form__invoice__row__item`}>
                <HeaderText value='Add Description/Invoice' />
                <div className={`container-full center form__invoice__row__item__file-select`}>
                  <Text value='Select file for upload' />
                  <Input type="file" className="input"
                    onChange={(event) => fileUpload(event.target.files[0])}
                  />
                </div>
                <div className={`container-full form__invoice__row__item__assistive-text`}>
                  <Text value={file} />
                </div>
              </div>
            </div>
          </div>
          <div className={`container-full form__action`}>
            <div className={`form__action__left`}>
              <Button type="button" value="Go Back" className={`button`} onClick={() => changeStep(2)} />
            </div>
            <div className={`form__action__right`}>
              <Button type="button" value="Cancel" className={`button --cancel`} onClick={() => handleClose()} />
              <Button type="submit" value="Initiate Payment" className={`button --continue ${uploadedFile && uploadedFile.length <= 0 ? 'disabled' : ''
                }`} isLoading={state.submitReducer.fetching} />
            </div>
          </div>
        </div>
      </form>
    </InitiatePaymentStepThreeStyle>
  );
};

export default InitiatePaymentStepThree;
