import styled from 'styled-components';
import search from '../../../assets/images/Search.svg';

const MerchantFeesSummaryTemplateStyle = styled.div`
  background: white;
  box-sizing: border-box;
  padding: 20px 40px 30px 30px;
  width: 100%;

  .merchants__back{
    justify-content: normal;
    a{
      display: flex;
    }
    p{
      color: rgba(37, 39, 51, 0.7);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      margin-top: 2.5px;
    }
  }
  .merchants__desc{
    margin-top: 10px;
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
    }
  }
  .merchants__top{
    background: white;
    border-radius: 8px;
    box-shadow: 0px 14px 25px rgba(37, 39, 51, 0.05);
    box-sizing: border-box;
    margin-top: 16px;
    padding: 20px 25px 15px 25px;
  }
  .merchants__top__desc{
    align-items: flex-start;
    border-bottom: 1px solid #F0F0F0;
    box-sizing: border-box;
    padding-bottom: 15px;
  }
  .merchants__top__desc__name{
    align-items: flex-start;
    display: flex;
    img{
      border-radius: 50%;
      height: 62px;
      margin-right: 12px;
      width: 62px;
    }
  }
  .merchants__top__desc__name__group{
    margin-top: 11px;
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 1px;
    }
    p{
      color: rgba(37, 39, 51, 0.5);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
    }
  }
  .merchants__top__desc__action{
    display: flex;
    margin-top: 22px;
    .button{
      border-radius: 5px;
      box-sizing: border-box
      display: flex;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      height: 30px;
      padding: 0 8px;
      text-align: left;
      img{
        margin-right: 3px;
        transform: translateY(4px);
      }
    }
    .--reject{
      background: #FEF0F0;
      color: #BD0F10;
      margin-right: 15.17px;
      position: relative;
      width: 77px;
      &:after{
        background: #F0F0F0;
        content: '';
        height: 21.12px;
        margin-right: -9px;
        margin-top: 4.44px;
        position: absolute;
        right: 0;
        top: 0;
        // transform: rotate(90deg);
        width: 1px;
      }
    }
    .--approve{
      background: #E7F9F4;
      color: #1B9830;
      width: 92px;
    }
  }
  .--validate{
    margin-top: 16px;
  }
  .merchants__top__desc__action__item{
    box-sizing: border-box;
    padding: 0 15.58px;
  }
  .--edit{
    border-right: 1.08333px solid #F0F0F0;
  }
  .merchants__top__nav{
    box-sizing: border-box;
    padding-top: 15px;
    .date{
      margin-top: 10px;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      font-feature-settings: 'salt' on;
      span{
        color: rgba(37, 39, 51, 0.5);
        font-family: 'Mulish';
      }
    }
  }
  .merchants__bottom{
    background: white;
    border-radius: 10px;
    box-sizing: border-box;
    margin-top: 34px;
    padding: 8px;
  }
  .merchants__bottom__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
    }
  }
  .merchants__bottom__details{
    box-sizing: border-box;
    padding: 8px 10px;
  }
  .merchants__bottom__details__left{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 30px;
      span{
        color: rgba(37, 39, 51, 0.5);
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
  .merchants__bottom__details__right{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      font-feature-settings: 'salt' on;
      span{
        color: rgba(37, 39, 51, 0.5);
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
      }
    }
  }
  .merchants__bottom__details__right__switch{
    display: flex;
    justify-content: flex-end;
    margin-top: 1px;
    p{
      color: rgba(37, 39, 51, 0.7);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      margin-right: 4px;
      margin-top: 2px;
    }
  }
  .merchants__bottom__details__right__switch__checkbox{
    margin-top: 9.61px;
    position: relative;
    .input{
      height: 14px;
      position: absolute;
      opacity: 0;
      width: 30px;
      &:checked + .merchants__bottom__details__right__switch__checkbox__box{
        background: #1B9830;
        .merchants__bottom__details__right__switch__checkbox__box__ball{
          background: white;
          margin-left: 9px;
        }
      }
    }
    .disabled{
      &:checked + .merchants__bottom__details__right__switch__checkbox__box{
        background: #1B9830;
        opacity: 0.3;
      }
    }
  }
  .merchants__bottom__details__right__switch__checkbox__box{
    background: #E3E5ED;
    border-radius: 70px;
    box-sizing: border-box;
    height: 14.79px;
    padding: 2px;
    transition: all 0.2s linear;
    width: 23px;
  }
  .merchants__bottom__details__right__switch__checkbox__box__ball{
    background: #D1D5E0;
    border-radius: 50%;
    height: 11.5px;
    transition: margin 0.5s linear;
    width: 11.5px;
  }
  .merchants__bottom__content{

  }
  .merchants__bottom__content__row{
    
  }
  .merchants__bottom__content__row__item{
    background: grey;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 152.5px;
    margin-bottom: 8px;
    padding: 16px;
    postion: relative;
    -ms-flex: 0 0 32.85%;
    -webkit-flex: 0 0 32.85%;
    flex: 0 0 32.85%;
    max-width: 32.85%;
    &:nth-of-type(odd){
      background: #F0F6FB;
    }
    &:nth-of-type(even){
      background: #F5F6FF;
    }
  }
  .merchants__bottom__content__row__item__top__left{
    h2{
      color: #130F26;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 5px;
    }
  }
  .merchants__bottom__content__row__item__top__left__label{
    background: #E7E7E7;
    border-radius: 5px;
    box-sizing: border-box;
    display: inline-block;
    mix-blend-mode: multiply;
    padding: 2px 6px;
    p{
      color: #6A6A73;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      font-feature-settings: 'salt' on;
    }
  }
  .merchants__bottom__content__row__item__top__right{
    h2{
      color: #130F26;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 5px;
      span{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 500;
      }
      &:first-child{
        margin-bottom: 2px;
      }
    }
  }
  .merchants__bottom__content__row__item__bottom{
    margin-top: auto;
  }
  .merchants__bottom__content__row__item__bottom__left{
    .header{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
    }
    .--amount{
      margin-bottom: 7px;
    }
    .subheader{
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
    p{
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
    }
    .link{
      color: #3751FF;
      cursor: pointer;
      font-family: 'Mulish';
      font-feature-settings: 'salt' on;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 6px;
      text-align: center;
    }
  } 
  .merchants__bottom__content__row__item__bottom__right{
    align-self: flex-end;
    p{
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #9FA2B4;
      span{
        color: #130F26;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
      }
    }
  }
`;

export default MerchantFeesSummaryTemplateStyle;
