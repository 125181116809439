import React, { useState } from 'react';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import FeeReportTableStyle from './FeeReportTableStyle';
import nike from '../../../assets/images/nike.svg';
import Pagination from '../Pagination/Pagination';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { exportData } from '../../../redux/actions/ExportAction';
import { useNavigate } from "react-router-dom";
import FeeReportModal from '../FeeReportModal/FeeReportModal';
import Cookies from 'js-cookie';
import {appInstance as axios} from '../../../axios';
import { alertActions } from '../../../redux/actions/AlertActions';


type FeeReportTableProps = {
  data: any,
  pagination: number,
  setPagination: Function,
  changePage: Function,
  page: number,
  reloadPage: Function
}

const FeeReportTable = ({ data, pagination, setPagination, changePage, page, reloadPage }: FeeReportTableProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state:any = useSelector((state: RootStore) => state);
  const navigate = useNavigate();
  const [toggleFeeReportModal, setToggleFeeReportModal] = useState(false);
  const [feeData, setFeeData] = useState({});

  const handleAllCheck = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      await dispatch(exportData(state.dashboardReducer.results.data.content))
      console.log(state.exportReducer);
    }
    else {
      await dispatch(exportData([]));
      console.log(state.exportReducer);
    }
  };

  const handleCheckItem = (item: Record<any, any>) => {
    if (state.exportReducer.results.data.includes(item)) {
      const newArr = state.exportReducer.results.data.filter((record: Record<any, any>) => record.id !== item.id)
      console.log(newArr);
      return dispatch(exportData(newArr));
    }
    const update = [...state.exportReducer.results.data, item]
    console.log(update);
    return dispatch(exportData(update));
  };

  const showFeeReportModal = async (ref: string) => {
    try {
      const res = await axios.get(`/report/fees?size=${0}&page=${1}&paymentRef=${ref}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      await setFeeData(res.data.data.content[0].statCatBreakdown);
      setToggleFeeReportModal(true)
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const hideFeeReportModal = () => {
    setToggleFeeReportModal(false)
  }

  const renderFeeReportModal = () => {
    if(toggleFeeReportModal){
      return(
        <FeeReportModal handleClose={hideFeeReportModal} data={feeData} />
      )
    }
  }

  return (
    <>
      {renderFeeReportModal()}
      <FeeReportTableStyle>
        <div className={`container-full`}>
          <table>
            <thead>
              <tr className={`table-header`}>
                <th>
                  <input type="checkbox" onChange={handleAllCheck} />
                  <Image image={nike} />
                </th>
                <th>
                  <Text value="Transaction Info" className={`table-title`} />
                </th>
                {
                  state.authReducer.results.user_level === 2 && (
                    <th>
                      <Text value="Merchant Name" className={`table-title`} />
                    </th>
                  )
                }
                <th>
                  <Text value="Transaction Date" className={`table-title`} />
                </th>
                <th>
                  <Text value="Total Transactions" className={`table-title`} />
                </th>
                <th>
                  <Text value="Total Transactions Amount" className={`table-title`} />
                </th>
                <th>
                  <Text value="Total Fee" className={`table-title`} />
                </th>
                <th>
                  <Text value="More" className={`table-title`} />
                </th>
              </tr>
            </thead>
            <tbody className={`table-body`}>
              {
                (data.data.content !== undefined && data.data.content.length > 0) ?
                  data.data.content.map((report: Record<any, any>, key: string) => {
                    return (
                      <tr className={`table-row`} key={key}>
                        <td>
                          <input
                            type="checkbox"
                            checked={state.exportReducer.results.data.includes(
                              report
                            )}
                            onChange={() => handleCheckItem(report)}
                          />
                          <Image image={nike} />
                        </td>
                        <td className='name'>
                          <div className='status --successful'></div>
                          <div>
                            <Text value={report.paymentName} className='label' onClick={() => navigate('/reports/payment-report/details')} />
                            <Text value={report.paymentRef} className='sublabel' />
                          </div>
                        </td>
                        {
                          state.authReducer.results.user_level === 2 && (
                            <td>
                              <Text value={report.merchantDetails && report.merchantDetails.businessName} className='label' />
                            </td>
                          )
                        }
                        <td>
                          <Text value={dayjs(report.transactionDate).format('MM/DD/YYYY')} className='label' />
                        </td>
                        <td>
                          <Text value={report.totalTransCount} className='label' />
                        </td>
                        <td>
                          <Text value={`₦${report.totalTransAmount}`} className='label' />
                        </td>
                        <td>
                          <Text value={`₦${report.totalFee}`} className='label' />
                        </td>
                        <td>
                          <Text value='Details' className='label more' onClick={() => showFeeReportModal(report.paymentRef)} />
                        </td>
                      </tr>
                    )
                  }) : null
              }
            </tbody>
          </table>
        </div>
        <div className={`container-full pagination`}>
          <Pagination pagination={pagination} changePage={changePage} setPagination={setPagination} page={page} />
        </div>
      </FeeReportTableStyle>
    </>
  );
};

export default FeeReportTable;
