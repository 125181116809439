import React, { useState, useEffect } from 'react';
import PartnerReportDetailsModalStyle from './PaymentReportDetailsModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import dayjs from 'dayjs';
import { SubmitActionsPOST } from '../../../redux/actions/SubmitAction';
import Button from '../../atoms/Button/Button';
import { useNavigate } from 'react-router-dom';

type PartnerReportDetailsModalProps = {
  handleClose: Function,
  data: any,
  showSuccessModal: Function
}

const PartnerReportDetailsModal = ({ handleClose, data, showSuccessModal }: PartnerReportDetailsModalProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state: any = useSelector((state: RootStore) => state);
  const [submitSuccessful, setSubmitSuccessful] = useState(false);

  useEffect(() => {
    if (submitSuccessful === true && state.submitReducer.results !== null) {
      handleClose();
      showSuccessModal();
    }
  }, [submitSuccessful])

  const retryPayment = async (paymentRef: string) => {
    await dispatch(SubmitActionsPOST(`/payment/${paymentRef}/reprocess`, {
      "paymentReference": paymentRef
    }))
    setSubmitSuccessful(true);
  }


  return (
    <PartnerReportDetailsModalStyle className={`container-full`}>
      <div className={`container-full center modal-container`}>
        <div className={`modal-container__box`}>
          <div className={`container-full modal-container__box__header`}>
            <HeaderText value='Payment Record' />
            <Image image={close} onClick={() => handleClose()} />
          </div>
          <div className={`container-full modal-container__box__content`}>
            <div className={`container-full modal-container__box__content__box`}>
              <div className={`container-full modal-container__box__content__box__desc`}>
                <HeaderText value='Payment Details' />
              </div>
              <div className={`container-full modal-container__box__content__box__content`}>
                <div className={`container-full modal-container__box__content__box__content__row`}>
                  <div className={`div-md-4 modal-container__box__content__box__content__row__item`}>
                    <HeaderText value='Payment Name' />
                    <Text value={data.paymentName} />
                  </div>
                  <div className={`div-md-4 modal-container__box__content__box__content__row__item`}>
                    <HeaderText value='Payment Reference' />
                    <Text value={data.paymentRef} />
                  </div>
                  <div className={`div-md-4 modal-container__box__content__box__content__row__item`}>
                    <HeaderText value='Date' />
                    <p> {dayjs(data.createdDate).format("DD/MM/YYYY")} <span>{dayjs(data.createdDate).format("HH:MM A")}</span></p>
                  </div>
                </div>
                <div className={`container-full modal-container__box__content__box__content__row`}>
                  <div className={`div-md-4 modal-container__box__content__box__content__row__item`}>
                    <HeaderText value='Start Date' />
                    <p> {dayjs(data.startDate).format("DD/MM/YYYY")} <span>{dayjs(data.startDate).format("HH:MM A")}</span></p>
                  </div>
                  <div className={`div-md-4 modal-container__box__content__box__content__row__item --roles`}>
                    <HeaderText value='End Date' />
                    <p> {dayjs(data.endDate).format("DD/MM/YYYY")} <span>{dayjs(data.endDate).format("HH:MM A")}</span></p>
                  </div>
                  <div className={`div-md-4 modal-container__box__content__box__content__row__item`}>
                    <HeaderText value='Total Amount' />
                    <Text value={`₦${data.totalAmount}`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`container-full modal-container__box__content__form`}>
            <Button value={`Retry Partner Sync`} isLoading={state.submitReducer.fetching} onClick={() => retryPayment(data.paymentRef)} />
          </div>
        </div>
      </div>
    </PartnerReportDetailsModalStyle>
  );
}

export default PartnerReportDetailsModal;