import React from 'react';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import PerformingMerchantsStyle from './PerformingMerchantsStyle';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../config/ConfigStore';
import Button from '../../atoms/Button/Button';
import { useNavigate } from 'react-router-dom';
import commaNumber from 'comma-number';


const PerformingMerchants = () => {
  const state = useSelector((state: RootStore) => state);
  const navigate = useNavigate();

  return (
    <PerformingMerchantsStyle>
      <div className={`container-full performing-merchants`}>
        <div className={`container-full performing-merchants__menu`}>
          <div className={`div-md-6 performing-merchants__menu__item`}>
            <Button className='button' value="View Merchants" onClick={() => navigate('/merchants')} />
          </div>
          <div className={`div-md-6 performing-merchants__menu__item ${
            (state.authReducer.results.roles.includes('Authorizer') && state.authReducer.results.roles.length === 1) 
            || (state.authReducer.results.roles.includes('Audit') && state.authReducer.results.roles.length === 1)  ? 'disabled' : ''
          }`}>
            <Button className='button' value="Add Merchant" onClick={() => navigate('/merchants/create/step-one')}/>
          </div>
        </div>
        <div className={`container-full performing-merchants__table`}>
          <HeaderText value="Top Performing Merchants" />
          <div className={`container-full performing-merchants__table__list`}>
            {
              state.dashboardReducer.results && state.dashboardReducer.results.data.topMerchants &&
              state.dashboardReducer.results.data.topMerchants.merchantDetails.length > 0 && state.dashboardReducer.results.data.topMerchants.merchantDetails.map((merchant: Record<any, any>, key: number) => {
                return (
                  <div className={`container-full performing-merchants__table__list__row`} key={key}>
                    <div className={`div-md-7 performing-merchants__table__list__row__item`}>
                      <div className={`performing-merchants__table__list__row__item__dot`}></div>
                      <Text value={merchant.name} className='value' />
                    </div>
                    <div className={`div-md-5 performing-merchants__table__list__row__item`}>
                      <HeaderText value={`₦${commaNumber(merchant.value)}`} />
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </PerformingMerchantsStyle>
  );
};

export default PerformingMerchants;
