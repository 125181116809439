import React, { useState, useEffect } from 'react';
import AddStatutoryBodyModalStyle from './AddStatutoryBodyModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import Input from '../../atoms/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { SubmitActionsPOST } from '../../../redux/actions/SubmitAction';
import { alertActions } from '../../../redux/actions/AlertActions';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';
import closeIcon from '../../../assets/images/close-icon-round.svg';
import dropdown from '../../../assets/images/dropdown.svg';
import caret from '../../../assets/images/caret-down-select.svg';

type AddStatutoryBodyModalProps = {
  handleClose: Function,
  reloadPage: Function
}

const AddStatutoryBodyModal = ({ handleClose, reloadPage }: AddStatutoryBodyModalProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  const [fields, setFields] = useState({
    name: '',
    description: '',
    email: '',
    phoneNumber: '',
    institutionCode: '',
    category: '',
    bank: '',
    accountNumber: ''
  })
  const [checking, setChecking] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [accountError, setAccountError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [bankList, setBankList] = useState([]);


  useEffect(() => {
    if (state.submitReducer.results && submitted) {
      handleClose();
      reloadPage();
    }
  }, [state.submitReducer])

  useEffect(() => {
    generatePageContent();
  }, [])

  const generatePageContent = async () => {
    try {
      const res = await axios.get(`/payroll/configuration/issuers?size=0&page=1`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setBankList(res.data.data.content);
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const inputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues[name] = value;
    await setFields(fieldsValues);
  };

  const onFormSubmit = () => {
    return (e: React.FormEvent) => {
      e.preventDefault();
      setSubmitted(true);
      let values = {
        "code": fields.institutionCode,
        "name": fields.name,
        "description": fields.description,
        "contactEmail": fields.email,
        "contactPhone": fields.phoneNumber,
        "category": fields.category,
        "settlementDetails": {
          "issuerCode": fields.bank,
          "settlementAccount": fields.accountNumber
        },
      }
      dispatch(SubmitActionsPOST('/payroll/configuration/partners', values))
    }
  }

  const getAccountName = async () => {
    const payload = {
      "accountNumber": fields.accountNumber,
      "issuerCode": fields.bank,
      "entity": 2,
    }
    try {
      setChecking(true);
      const res = await axios.post(`/account/enquiry`, payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setChecking(false);
      if(res.data.data.isValidated === 0){
        setAccountError(true);
      }
      else{
        setAccountError(false);
      }
      setAccountName(res.data.data.validatedName);
    } catch (error: any) {
      setChecking(false);
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  return (
    <AddStatutoryBodyModalStyle className={`container-full`}>
      <div className={`container-full center modal-container`}>
        <div className={`modal-container__box`}>
          <div className={`container-full modal-container__box__header`}>
            <HeaderText value='Add Statutory Body' />
            <Image image={close} onClick={() => handleClose()} />
          </div>
          <form onSubmit={onFormSubmit()}>
            <div className={`container-full modal-container__box__content`}>
              <div className={`container-full modal-container__box__content__desc`}>
                <HeaderText value='Provide information for the Statutory Body you would like to create' />
              </div>
              <div className={`container-full modal-container__box__content__form`}>
                <div className={`container-full modal-container__box__content__form__item`}>
                  <p> Name <span>*</span></p>
                  <Input type='text' className="input" placeholder="Eg Retail Corporate Ltd" required name="name" value={fields.name} onChange={(e) => inputChange(e.target.name, e.target.value)} />
                </div>
                <div className={`container-full modal-container__box__content__form__item`}>
                  <Text value='Description' />
                  <Input type='text' className="input" placeholder="Add a description here" name="description" value={fields.description} onChange={(e) => inputChange(e.target.name, e.target.value)} />
                </div>
                <div className={`container-full modal-container__box__content__form__item`}>
                  <div className='div-md-6'>
                    <p> Email <span>*</span></p>
                    <Input type='email' className="input" placeholder="Eg. maggiesmitthoie@gmail.com" required name="email" value={fields.email} onChange={(e) => inputChange(e.target.name, e.target.value)} />
                  </div>
                  <div className='div-md-6'>
                    <p> Phone Number <span>*</span></p>
                    <Input type='text' className="input" placeholder="Eg. 2348024044353" required name="phoneNumber" value={fields.phoneNumber} onChange={(e) => inputChange(e.target.name, e.target.value)} />
                  </div>
                </div>
                <div className={`container-full modal-container__box__content__form__item`}>
                  <div className='div-md-6'>
                    <p> Institution Code <span>*</span></p>
                    <Input type='text' className="input" placeholder="Eg. 023" required name="institutionCode" value={fields.institutionCode} onChange={(e) => inputChange(e.target.name, e.target.value)} />
                  </div>
                  <div className='div-md-6'>
                    <p> Category <span>*</span></p>
                    <div className='input'>
                      <select required
                        name="category"
                        onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                        value={fields.category}
                      >
                        <option value="" hidden> Select Category </option>
                        <option value="1"> PAYE </option>
                        <option value="2"> PFA </option>
                        <option value="3"> NHIS </option>
                        <option value="4"> NHF </option>
                      </select>
                      <Image image={caret} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`container-full modal-container__box__content__desc2`}>
                <HeaderText value='Settlement Account Details' />
              </div>
              <div className={`container-full modal-container__box__content__form`}>
                <div className={`container-full modal-container__box__content__form__item`}>
                  <div className='div-md-6'>
                    <p> Bank <span>*</span></p>
                    <div className='input'>
                      <select required
                        name="bank"
                        onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                        value={fields.bank}
                      >
                        <option value="" hidden> Select Bank </option>
                        {
                          (bankList.length > 0 && bankList.map((bank: Record<any, any>) => {
                            return (<option value={bank.issuerCode} key={bank.id}> {bank.name} </option>)
                          }))
                        }
                      </select>
                      <Image image={caret} />
                    </div>
                    {
                      (fields.bank !== "") ? 
                      <div className='modal-container__box__content__form__item__label' onClick={() => getAccountName()}>
                        <Text value='Get Account Name' />
                      </div> : null
                    }  
                  </div>
                  <div className='div-md-6' title='Click on get account name to verify account'>
                    <p> Account Number <span>*</span></p>
                    <Input type='text' className="input" placeholder="Enter Account Number" required name="accountNumber" value={fields.accountNumber} onChange={(e) => inputChange(e.target.name, e.target.value)} />
                    {
                      (fields.bank !== "") ? 
                      <>
                        {
                          (!accountError && accountName !== "" && !checking) ? <h4 className="info"> {accountName} </h4> : null
                        }
                        {
                          (accountError && !checking) ? <h4 className='error'> Account Name Retreival Error </h4> : null
                        }
                      </> : null
                    }
                   
                  </div>
                </div>
              </div>
            </div>
            <div className={`container-full center modal-container__box__action`}>
              <Button type="button" value="Cancel" className={`button --cancel`} onClick={() => handleClose()} />
              <Button type="submit" value="Save" className={`button --change --active-btn`} isLoading={state.submitReducer.fetching} />
            </div>
          </form>
        </div>
      </div>
    </AddStatutoryBodyModalStyle>
  );
}

export default AddStatutoryBodyModal;