import styled from 'styled-components';

const ProfileTemplateStyle = styled.div`
  background: white;
  box-sizing: border-box;
  padding: 20px 30px;
  width: 100%;

  .settings__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 1.5em;
      line-height: 30px;
    }
  }
  .settings__content{
    box-sizing: border-box;
    padding: 20px;
  }
  .settings__content__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 700;
      font-size: 1em;
      line-height: 20px;
    }
  }
  .settings__content__body{
    margin-top: 23px;
  }
  .settings__content__body__logo{
    -ms-flex: 0 0 23%;
    -webkit-flex: 0 0 23%;
    flex: 0 0 23%;
    max-width: 23%;
    margin-top: 21px;
  }
  .settings__content__body__logo__desc{
    h2{
      color: rgba(37, 39, 51, 0.3);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 14px;
    }
  }
  .settings__content__body__logo__box{
    background: rgba(55, 81, 255, 0.03);
    border-radius: 10px;
    box-sizing: border-box;
    min-height: 208px;
    margin-top: 8px;
    padding: 12px;
    img{
      height: auto;
      width: 100%;
    }
  }
  .settings__content__body__logo__file{
    margin-top: 8px;
    position: relative;
    .logo{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
    .file-name{
      color: rgba(37, 39, 51, 0.3);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 14px;
      margin-top: 2px;
    }
    img{
      cursor:pointer;
      margin-top: 2.2px;
    }
    .input{
      bottom: 0;
      cursor: pointer;
      left: 0;
      opacity: 0;
      position: absolute;
    }
  }
  .settings__content__body__details{
    -ms-flex: 0 0 74%;
    -webkit-flex: 0 0 74%;
    flex: 0 0 74%;
    max-width: 74%;
  }
  .settings__content__body__details__section__desc{
    h2{
      color: rgba(37, 39, 51, 0.3);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 14px;
      text-transform: uppercase;
    }
  }
  .settings__content__body__details__section__box{
    margin-top: 8px;
  }
  .settings__content__body__details__section__box__row{
    margin-bottom: 14px;
    .div-md-6{
      margin-bottom: 0;
    }
    h2{
      color: rgba(37, 39, 51, 0.4);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 9.5px;
    }
    .--password{
      margin-bottom: 8px;
    }
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
      span{
        color: rgba(37, 39, 51, 0.4);
        font-family: 'Mulish';
      }
    }
    .button{
      background: #F4F4F6;
      background-blend-mode: multiply;
      border-radius: 5px;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      letter-spacing: 0.2px;
      margin-top: 22px;
      padding: unset;
      height: 26px;
      width: 73px;
    }
  }
  .settings__content__body__details__section__box__row__item__password{
    background: rgba(55, 81, 255, 0.03);
    border-radius: 8px;
    box-sizing: border-box;
    height: 40px;
    padding: 10px 15px;
    width: 100%;
    p{
      color: rgba(37, 39, 51, 0.3);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .--remove-bottom{
    margin-bottom: 0;
  }
  .--timeline{
    margin-top: 52px;
  }
  .--ip{
    justify-content: flex-end;
  }
  .settings__content__action{
    border-top: 2px solid #EEEEEE;
    box-sizing: border-box;
    justify-content: flex-end;
    margin-top: 74px;
    padding-top: 11px;
    .button{
      background: #F5F5F5;
      border-radius: 8px;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.2px;
      height: 44px;
      margin-bottom: 0;
      padding-left: 40px;
      position: relative;
      text-align: left;
      width: 95px;
      img{
        left: 0;
        margin: 15.57px 0 0 21.57px;
        position: absolute;
        top: 0;
      }
    }
  }
`;

export default ProfileTemplateStyle;
