import styled from "styled-components";

const CreateRoleModalStyle = styled.div`
    animation: show-bg 0.5s linear forwards;
    box-sizing:border-box;
    display:flex;
    height:100%;
    left:0;
    position:absolute;
    position:fixed;
    top: 0;
    width:100%;
    z-index:5000;
    @keyframes show-bg{
        0%{
            background:rgba(0, 0, 0, 0)
        }
        100%{
            background:rgba(37, 39, 51, 0.75);
        }
    }
    form{
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .modal-container{
        box-sizing:border-box;
        height:100vh;
    }
    .modal-container__box{
        -ms-flex: 0 0 786px;
        -webkit-flex: 0 0 786px;
        flex: 0 0 786px;
        max-width: 786px;
        background:#FFFFFF;
        border-radius:10px;
        box-sizing:border-box;
        overflow:scroll;
        padding:20px 20px 30px 30px;
    }
    .modal-container__box__header{
        h2{
           color: #252733;
           font-family: 'Mulish';
           font-style: normal;
           font-weight: 700;
           font-size: 1.375em;
           line-height: 30px;
        }
        img{
            cursor: pointer;
        }
    }
    .modal-container__box__content{
        height: 482px;
        overflow: scroll;
    }
    .modal-container__box__content__details{
        -ms-flex: 0 0 285px;
        -webkit-flex: 0 0 285px;
        flex: 0 0 285px;
        max-width: 285px;
        margin-top: 27px;
    }
    .modal-container__box__content__details__form__item{
        margin-bottom: 18px;
        p{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
        }
        .input{
            background: rgba(55, 81, 255, 0.02);
            border: none;
            border-radius: 5px;
            box-sizing: border-box;
            color: rgba(37, 39, 51, 0.3);
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            height: 40px;
            margin-top: 6px;
            padding-left: 15px;
            &::placeholder{
                color: rgba(37, 39, 51, 0.3);
                font-family: 'Mulish';
            }
        }
    }
    .modal-container__box__content__details__form__item__select{
        background: rgba(55, 81, 255, 0.02);
        border-radius: 5px;
        box-sizing: border-box;
        height: 40px;
        margin-top: 6px;
        padding-left: 15px;
        position: relative;
        select{
            appearance: none;
            background: transparent;
            color: rgba(37, 39, 51, 0.3);
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            width: 100%;
        }   
        img{
            margin-right: 16px;
            margin-top: 18px;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    .modal-container__box__content__details__permissions{
        box-sizing: border-box;
        margin-top: 37px;
        padding: 0 8px 0 8px;
    }
    .modal-container__box__content__details__permissions__desc{
        h2{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
        }
    }
    .modal-container__box__content__details__permissions__info{
        margin-top: 18px;
    }
    .modal-container__box__content__details__permissions__info__desc{
        margin-bottom: 10px;
        h2{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
        }
        p{
            color: rgba(37, 39, 51, 0.5);
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 16px;
            margin-top: 7px;
        }
    }
    .modal-container__box__content__details__permissions__info__list{
        box-sizing: border-box;
        justify-content: normal;
        padding-left: 9px;
    }
    .modal-container__box__content__details__permissions__info__list__item{
        margin-bottom: 2px;
        p{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 300;
            font-size: 13px;
            line-height: 16px;
            margin-left: 7.5px;
            width: 95%;
            span{
            font-family: 'Mulish';
            font-weight: 400;
            }
        }
    }
    .modal-container__box__content__details__permissions__info__list__item__bullet{
        background: #252733;
        border-radius: 50%;
        height: 5px;
        margin-top: 6px;
        width: 5px;
    }
    .modal-container__box__content__details__permissions__info__item{
        margin-bottom: 15.5px;
        h2{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
        }
    }
    .modal-container__box__content__details__permissions__info__item__list{
        box-sizing: border-box;
        margin-top: 1px;
        padding-left: 9px;
    }
    .modal-container__box__content__details__permissions__info__item__list__item{
        justify-content: normal;
        margin-bottom: 2px;
        &:last-child{
            margin-bottom: 0;
        }
        p{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 300;
            font-size: 13px;
            line-height: 16px;
            margin-left: 7.5px;
            width: 95%;
            span{
                font-family: 'Mulish';
                font-weight: 400;
            }
        }
    }
    .modal-container__box__content__details__permissions__info__item__list__item__bullet{
        background: #252733;
        border-radius: 50%;
        height: 5px;
        margin-top: 6px;
        width: 5px;
    }
    .modal-container__box__content__privileges{
        background: #FCFCFD;
        border-radius: 10px;
        box-sizing: border-box;
        margin-top: 12px;
        padding: 10px;
        -ms-flex: 0 0 403px;
        -webkit-flex: 0 0 403px;
        flex: 0 0 403px;
        max-width: 403px;
    }
    .modal-container__box__content__privileges__desc{
        margin-bottom: 16px;
        h2{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            line-height: 16px;
        }
    }
    .modal-container__box__content__privileges__group{
        margin-bottom: 9.5px;
    }
    .modal-container__box__content__privileges__group__desc{
        background: #F5F9FE;
        box-sizing: border-box;
        margin-bottom: 9.5px;
        padding: 3.5px 20px 3.5px 20px;
        text-transform: capitalize;
        p{
          color: rgba(37, 39, 51, 0.4);
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 13px;
        }
      }
      .modal-container__box__content__privileges__group__item{
        box-sizing: border-box;
        justify-content: normal;
        margin-bottom: 19px;
        padding: 0 20px 0 20px;
        &:last-child{
          margin-bottom: 9.5px;
        }
        p{
          color: #252733;
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          width: 22%;
        }
      }
      .modal-container__box__content__privileges__group__item__checkbox{
        margin-left: 60%;
        position: relative;
        .input{
          left: 0;
          margin-top: -13px;
          opacity: 0;
          position: absolute;
          top: 0;
          width: 14.5px;
          z-index: 500;
          &:checked + {
            .modal-container__box__content__privileges__group__item__checkbox__box{
              background: #3751FF;
              img{
                transform: scale(1);
              }
            }
          }
        }
      }
      .modal-container__box__content__privileges__group__item__checkbox__box{
        background: white;
        border: 0.52531px solid #B5BABD;
        border-radius: 3px;
        height: 14.5px;
        position: relative;
        transition: background 0.5s linear;
        width: 14.5px;
        img{
          left: 0;
          margin-left: 3.5px;
          margin-top: 4.5px;
          position: absolute;
          transform: scale(0);
          transition: all 0.5s linear;
          top: 0;
        }
      }
      .modal-container__box__action{
          justify-content: flex-end;
          margin-top: 37.5px;
          .button{
            
            border-radius: 8px;
            height: 40px;
            margin-bottom: 0;
            width: 145px;
          }
          .--save{
            background: #F5F5F5;
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 0.2px;
            margin-right: 20px;
          }
          .--create{
            background: #3751FF; 
            border-radius: 8px;
            box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
            color: white;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
          }
      }

    
`
export default CreateRoleModalStyle;