import React, { useState, useEffect } from 'react';
import ForgotPasswordTemplateStyle from './ForgotPasswordTemplateStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import logo from '../../../assets/images/logo.svg';
import Alert from '../../molecules/Alerts/Alerts';
import { Link, useNavigate } from 'react-router-dom';
import ForgotPasswordForm from '../../molecules/ForgotPasswordForm/ForgotPasswordForm';
import arrowLeft from '../../../assets/images/arrow-left.svg';
import success from '../../../assets/images/success.svg';
import {useSelector, useDispatch} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {alertActions} from '../../../redux/actions/AlertActions';
import {forgotPassword} from '../../../redux/actions/AuthAction';
import Cookies from 'js-cookie';

const ForgotPasswordTemplate = () => {
  const [username, setUsername] = useState("")
  const state = useSelector( (state:RootStore) => state);
  const dispatch: TypedDispatch = useDispatch();
  const auth = Cookies.get("userToken");
  const navigate = useNavigate()

  useEffect(() => {
    if(auth !== undefined){
      navigate('/dashboard')
    }
    return () => {
      dispatch(alertActions.clear());
    }
  }, [])

  return (
    <>
      <ForgotPasswordTemplateStyle>
       <div className={`container-full center signin`}>
          <div className={`signin__box`}>
            <div className={`container-full center signin__box__logo`}>
              <Image image={logo} alt="logo" />
            </div>
            {
              state.alertReducer.type === "alert-success" ?
                 <div className={`container-full signin__box__modal --success-modal`}>
                    <div className={`container-full signin__box__modal__content --success-modal__content`}>
                      <div className={`container-full signin__box__modal__content__nav`}>
                        <Image image={arrowLeft} />
                        <Text value='Go Back' onClick={() => dispatch(alertActions.clear())}/>
                      </div>
                      <div className={`container-full center signin__box__modal__content__desc --success-modal__desc`}>
                        <HeaderText value="Forgot Password" />
                      </div>
                      <div className={`container-full center signin__box__modal__content__icon`}>
                        <Image image={success} />
                      </div>
                      <div className={`container-full center signin__box__modal__content__text`}>
                        <p> Dear {username}, A reset link has been sent to your email </p>
                      </div>
                      <div className={`container-full center signin__box__modal__content__action`}>
                        <p> Didn’t get the link? <span onClick={() => dispatch(forgotPassword(username))}> Resend it. </span> </p>
                      </div>
                    </div>
                 </div> : 
                 <>
                  <div className={`container-full signin__box__modal`}>
                    <div className={`container-full signin__box__modal__content`}>
                      <div className={`container-full center signin__box__modal__content__desc`}>
                        <HeaderText value="Forgot Password" />
                        <Text value='Enter your username to request a password reset' />
                      </div>
                      {
                        state.alertReducer.type === "alert-danger" ? 
                        <div className={`container-full signin__box__modal__content__alert`}>
                          <Alert text={state.alertReducer.message} />
                        </div> : ''
                      }
                      <div className={`container-full signin__box__modal__content__form`}>
                        <ForgotPasswordForm changeUsername={setUsername}/>
                      </div>
                    </div>
                  </div>
                  <div className={`container-full signin__box__link`}>
                    <Link to="/">
                      <Text value='Go Back' />
                    </Link>
                  </div>
                </>
            }
          </div>
       </div>
      </ForgotPasswordTemplateStyle>
    </>
  );
};

export default ForgotPasswordTemplate;
