import styled from "styled-components";

const AuditDetailsModalStyle = styled.div`
    animation: show-bg 0.5s linear forwards;
    box-sizing:border-box;
    display:flex;
    height:100%;
    left:0;
    position:absolute;
    position:fixed;
    top: 0;
    width:100%;
    z-index:5000;
    @keyframes show-bg{
        0%{
            background:rgba(0, 0, 0, 0)
        }
        100%{
            background:rgba(37, 39, 51, 0.7);
        }
    }
    .modal-container{
        box-sizing:border-box;
        height:100vh;
    }
    .modal-container__box{
        -ms-flex: 0 0 836px;
        -webkit-flex: 0 0 836px;
        flex: 0 0 836px;
        max-width: 836px;
        background: #FAFAFA;
        border-radius:10px;
        box-sizing:border-box;
        max-height: 700px;
        overflow: auto;
        padding: 20px 25px 40px 25px;
    }
    .modal-container__box__header{
        h2{
           color: #252733;
           font-family: 'Mulish';
           font-style: normal;
           font-weight: 600;
           font-size: 18px;
           line-height: 23px;
           margin-top: 3.5px;
        }
        img{
            cursor: pointer;
        }
    }  
    .modal-container__box__content{
        margin-top: 15px;
    }
    .modal-container__box__content__box{
        background: rgba(55, 81, 255, 0.03);
        border-radius: 8px;
        box-sizing: border-box;
        margin-bottom: 15px;
        padding: 11.5px 15px 20px 15px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .modal-container__box__content__box__desc{
        border-bottom: 1px solid #DEE0E6;
        box-sizing: border-box;
        padding-bottom: 16.5px;
        h2{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
        }
    }
    .modal-container__box__content__box__content{
        margin-top: 15px;
    }
    .modal-container__box__content__box__content__row{
        margin-bottom: 15px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .modal-container__box__content__box__content__row__item{
        margin-bottom: 0;
        h2{
            color: rgba(37, 39, 51, 0.5);
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            margin-bottom: 2px;
            width: 100%;
        }
        p{
            color: #252733;
            font-family: 'Mulish';
            font-feature-settings: 'salt' on;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            span{
                font-family: 'Mulish';
                color: rgba(37, 39, 51, 0.5);
                margin-right: 3px;
            }
        }
    }
    .--roles{
        p{
            display: inline;
            &:after{
                content: ', '
            }
            &:last-child{
                &:after{
                    content: ''
                }
            }
        }
    }
`
export default AuditDetailsModalStyle;