import styled from 'styled-components';
import search from '../../../assets/images/Search.svg';

const OngoingPaymentsListSummaryTemplateStyle = styled.div`
  background: white;
  box-sizing: border-box;
  padding: 20px 30px;
  width: 100%;

  .payments__back{
    justify-content: normal;
    a{
      display: flex;
    }
    p{
      color: rgba(37, 39, 51, 0.7);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      margin-top: 2.5px;
    }
  }

  .payments__desc{
    margin-top: 10px;
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 1.5em;
      line-height: 30px;
    }
  }
  .payments__action{
    margin-top: 20.5px;
  }
  .payments__action__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }
  }
  .payments__action__search{
    display: flex;
  }
  .btn-group{
    .button{
      all: unset;
      margin-bottom: 0;
    }
    .--export{
      background: #FAFAFA;
      border-radius: 8px;
      box-sizing: border-box;
      height: 34px;
      margin-right: 10px;
      padding: unset;
      padding: 4px 8px;
      width: 34px;
    }
    .--disabled{
      opacity: 0.5;
      pointer-events: none;
    }
    .--user{
      background: #3751FF;
      border-radius: 8px;
      box-sizing: border-box;
      cursor: pointer;
      height: 34px;
      padding: unset;
      padding: 8px 8px;
      width: 34px;
    }
  }
  .payments__data{
    margin-top: 5px;
  }
  .payments__data__row{
    box-sizing: border-box;
    padding-top: 5px;
    &:first-child{
      border-bottom: 1px solid #EEEEEE;
      padding-bottom: 10px;
    }
  }
  .payments__data__row__left{
    margin-bottom: 0;
  }
  .payments__data__row__left__item{
    margin-bottom: 0;
    h2{
      color: rgba(37, 39, 51, 0.5);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 2px;
    }
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
    }
    .--id{
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
    .--date{
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      span{
        color: rgba(37, 39, 51, 0.5);
        font-family: 'Mulish';
        margin-left: 3px;
      }
    }
    .--username{
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }
    .--description{
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }
    .--signatory{
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
    .--record{
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .payments__data__row__right{
    margin-bottom: 0;
    h2{
      color: rgba(37, 39, 51, 0.5);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 2px;
      text-align: right;
      width: 100%;
    }
    .--amount{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      text-align: right;
      width: 100%;
    }
  }
  .payments__data__row__right__range{
    justify-content: flex-end;
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      &:first-child{
        margin-right: 4px;
      }
      span{
        color: rgba(37, 39, 51, 0.5);
        font-family: 'Mulish';
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin-right: 7px;
      }
    }
  }
  .payments__table{
    margin-top: 16px;
  }
  
  
`;

export default OngoingPaymentsListSummaryTemplateStyle;
