import styled from "styled-components";
import Colors from '../../../helpers/Colors';

const ForgotPasswordFormStyle = styled.div`
  width: 100%;

  form{
    width: 100%;
  }
  .form__item{
    margin-bottom: 29px;
    .label{
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 8px;
      text-transform: uppercase;
    }
    .input{
      background: #FCFDFE;
      border: 1px solid #F0F1F7;
      border-radius: 8px;
      box-sizing: border-box;
      color: #4B506D;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 1em;
      height: 50px;
      padding-left: 15px;
      width: 100%;
      transition: all 0.5s linear;
      &::placeholder{
        color: rgba(75, 80, 109, 0.4);
      }
      &:focus{
        border: 1px solid rgba(55, 81, 255, 0.5);
      }
    }
  }
  .password{
    position:relative;
    img{
        cursor:pointer;
        margin-right:16.67px;
        margin-top:18.33px;
        position:absolute;
        right:0;
        top:0;
    }
  } 
  .form__action{
    .button{
      background: #3751FF;
      border-radius: 8px;
      box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
      color: white;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      height: 50px;
      margin-bottom: 0;
      width: 100%;
    }
  }
  
  
`;

export default ForgotPasswordFormStyle;