import React, { useState, useEffect } from 'react';
import AuditLogTemplateStyle from './AuditLogTemplateStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import Input from '../../atoms/Input/Input';
import exportIcon from '../../../assets/images/download-icon.svg';
import plusIcon from '../../../assets/images/plus-icon-white.svg';
import filterIcon from '../../../assets/images/filter-icon.svg';
import plus from '../../../assets/images/plus-icon.svg';
import AuditTable from '../../organisms/AuditTable/AuditTable';
import nike from '../../../assets/images/nike.svg';
import Button from '../../atoms/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { DashboardActions } from '../../../redux/actions/DashboardAction';
import { exportAll, exportClear } from '../../../redux/actions/ExportAction';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import { NavLink, useNavigate } from 'react-router-dom';
import { addDays, format } from 'date-fns';
import { DateRange, DayPicker } from 'react-day-picker';
import caret from '../../../assets/images/caret-filter-select.svg';
import CreateIssuerModal from '../../organisms/CreateIssuerModal/CreateIssuerModal';

const pastMonth = new Date();

const AuditLogTemplate = () => {
  let timeOutId: any = null;
  const [toggleCreateIssuerModal, setToggleCreateIssuerModal] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const dispatch: TypedDispatch = useDispatch();
  const state: any = useSelector((state: RootStore) => state);
  // const navigate = useNavigate();
  const [filterValues, setFilterValues] = useState<any>({});
  const [fields, setFields] = useState({
    dateRange: '',
    username: '',
    email: '',
    actionType: ''
  });

  const onClickHandler = (value: boolean) => {
    if (selectedMenu !== false) {
      setSelectedMenu(false);
      return;
    }
    setSelectedMenu(value);
  }

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
      setSelectedMenu(false);
    });
  }

  const onFocusHandler = () => {
    clearTimeout(timeOutId);
  }

  useEffect(() => {
    dispatch(exportAll(`/audit/logs?size=0&page=1`));
  }, [])

  useEffect(() => {
    dispatch(exportClear())
    generatePageContent();
    console.log(state);
  }, [size, page, search])

  const generatePageContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/audit/logs/?size=${size}&page=${page}${search !== '' ? `&userName=${search}` : ''}`));
    setFetchLoading(false);
  }

  const renderFetchLoading = () => {
    if (fetchLoading) {
      return (
        <FetchLoading />
      )
    }
  }

  const changePage = (value: number) => {
    setPage(value);
  }

  const changeSize = (value: number) => {
    setSize(value);
  }

  const exportData = () => {
    return {
      fileData: () => {
        let auditType: string;
        let audits = state.exportReducer.results.data.map((audit: Record<any, any>) => {
          if (audit.auditAction === 1) {
            auditType = "User login"
          }
          else if (audit.auditAction === 2) {
            auditType = "Give approval"
          }
          else if (audit.auditAction === 3) {
            auditType = "Create"
          }
          else if (audit.auditAction === 4) {
            auditType = "Update"
          }
          else if (audit.auditAction === 5) {
            auditType = "Initiate payment"
          }
          else if (audit.auditAction === 6) {
            auditType = "Give final payment approval"
          }
          else if (audit.auditAction === 7) {
            auditType = "Send for fee calculation"
          }
          else {
            auditType = "Logout"
          }
          return (
            {
              Name: audit.userDetails ? audit.userDetails.name : null,
              Email: audit.userDetails ? audit.userDetails.email : null,
              IpAddress: audit.ipAddress,
              UserClient: audit.userClient,
              AuditType: auditType,
              DateCreated: dayjs(audit.createdDate).format('MM/DD/YYYY'),
            }
          )
        })
        let data = [...audits];
        return data
      },
      fileHeaders: () => {
        let headers = [
          { label: "Name", key: "Name" },
          { label: "Email", key: "Email" },
          { label: "IpAddress", key: "IpAddress" },
          { label: "UserClient", key: "UserClient" },
          { label: "AuditType", key: "AuditType" },
          { label: "Date Created", key: "DateCreated" },
        ];
        return headers;
      }
    }
  }

  const exportDataAll = () => {
    return {
      fileData: () => {
        let auditType: string;
        let audits = state.exportReducer.allResults.data.map((audit: Record<any, any>) => {
          if (audit.auditAction === 1) {
            auditType = "User login"
          }
          else if (audit.auditAction === 2) {
            auditType = "Give approval"
          }
          else if (audit.auditAction === 3) {
            auditType = "Create"
          }
          else if (audit.auditAction === 4) {
            auditType = "Update"
          }
          else if (audit.auditAction === 5) {
            auditType = "Initiate payment"
          }
          else if (audit.auditAction === 6) {
            auditType = "Give final payment approval"
          }
          else if (audit.auditAction === 7) {
            auditType = "Send for fee calculation"
          }
          else {
            auditType = "Logout"
          }
          return (
            {
              Name: audit.userDetails ? audit.userDetails.name : null,
              Email: audit.userDetails ? audit.userDetails.email : null,
              IpAddress: audit.ipAddress,
              UserClient: audit.userClient,
              AuditType: auditType,
              DateCreated: dayjs(audit.createdDate).format('MM/DD/YYYY'),
            }
          )
        })
        let data = [...audits];
        return data
      },
      fileHeaders: () => {
        let headers = [
          { label: "Name", key: "Name" },
          { label: "Email", key: "Email" },
          { label: "IpAddress", key: "IpAddress" },
          { label: "UserClient", key: "UserClient" },
          { label: "AuditType", key: "AuditType" },
          { label: "Date Created", key: "DateCreated" },
        ];
        return headers;
      }
    }
  }

  const refreshTable = () => {
    if (reloadPage) {
      return setReloadPage(false);
    }
    setReloadPage(true);
  }

  const defaultSelected: DateRange = {
    from: pastMonth,
    to: addDays(pastMonth, 4)
  };
  const [range, setRange] = useState<DateRange | any>(defaultSelected);

  let footer = <p>Please pick the first day.</p>;
  if (range !== undefined && range.from) {
    if (!range.to) {
      footer = <p>{format(range.from, 'PPP')}</p>;
    } else if (range.to) {
      footer = (
        <p>
          {format(range.from, 'PPP')}–{format(range.to, 'PPP')}
        </p>
      );
    }
  }

  const changeFilterValue = async (event: any, name: string, value: string) => {
    if (event.target.checked) {
      const filterValuesObj: any = Object.assign({}, filterValues);
      filterValuesObj[name] = value;
      await setFilterValues(filterValuesObj);
    }
    else {
      const filterValuesObj: any = Object.assign({}, filterValues);
      delete filterValuesObj[name]
      await setFilterValues(filterValuesObj);
    }
  }

  const inputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues[name] = value;
    await setFields(fieldsValues);
  };

  const fetchFilteredContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/audit/logs?size=${size}&page=${page}${filterValues.username && fields.username !== '' ? `&userName=${fields.username}` : ''}${filterValues.actionType && fields.actionType !== '' ? `&action=${fields.actionType}` : ''}${filterValues.dateRange && range ? `&createdDateFrom=${dayjs(range.from).format("YYYY-MM-DD HH:mm:ss")}` : ''}${filterValues.dateRange && range ? `&createdDateTo=${dayjs(range.to).format("YYYY-MM-DD HH:mm:ss")}` : ''}`));
    setFetchLoading(false);
    setSelectedMenu(false);
  }

  return (
    <>
      {renderFetchLoading()}
      <AuditLogTemplateStyle>
        <div className={`container-full issuers`}>
          <div className={`container-full issuers__desc`}>
            <HeaderText value="Audit Log" />
          </div>
          <div className='container-full issuers__action'>
            <div className={`issuers__action__search`}>
              <Input className={`input`} type="text" placeholder="Search audits"
                onChange={(event) => setSearch(event.target.value)}
                onKeyPress={event => {
                  if (event.key === 'Enter' || event.which === 13 || event.keyCode === 13) {
                    generatePageContent();
                  }
                }}
              />
            </div>
            <div className='issuers__action__btn-group'>
              {
                (state.exportReducer.results.data.length > 0) ?
                  <CSVLink data={exportData().fileData()} headers={exportData().fileHeaders()} filename={"audits.csv"}>
                    <button className='button --export'>
                      <Image image={exportIcon} />
                    </button>
                  </CSVLink> :
                  <CSVLink data={exportDataAll().fileData()} headers={exportDataAll().fileHeaders()} filename={"audits.csv"}>
                    <button className='button --export'>
                      <Image image={exportIcon} />
                    </button>
                  </CSVLink>
              }
            </div>
          </div>
          <div className={`container-full merchant__filter`}>
            <div className={`merchant__filter__item --filter`}>
              <div className={`--filter__box`}>
                <Image image={filterIcon} alt="filter-icon" />
              </div>
              <Text value='Filter' />
            </div>
            {/* <div className={`merchant__filter__item --active-filter`}>
                <Text value='No active filters' />
              </div> */}
            <div className={`merchant__filter__item --add-filter`} onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
              <button className={`button --elipses`} onClick={() => onClickHandler(true)} aria-haspopup="true" aria-expanded={selectedMenu}>
                <Image image={plus} />
                <Text value='Add filter' />
              </button>
              {
                selectedMenu !== false && (
                  <button className='unset'>
                    <div className={`container-full more-info`}>
                      <div className={`container-full more-info__top`}>
                        <div className={`container-full more-info__top__row`}>
                          <div className={`div-md-4 more-info__top__row__item`}>
                            <div className={`container-full more-info__top__row__item__desc`}>
                              <div className={`more-info__top__row__item__desc__checkbox`}>
                                <input
                                  type="checkbox"
                                  name="dateRange"
                                  onChange={(event) => changeFilterValue(event, event.target.name, event.target.value)}
                                  defaultChecked={(filterValues.dateRange) ? true : false}
                                />
                                <Image image={nike} />
                              </div>
                              <Text value='Date Range' />
                            </div>
                            <div className={`container-full more-info__top__row__item__input`}>
                              <Input type="text" className={`input ${(filterValues.dateRange === undefined) ? 'disabled' : null
                                }`}
                                placeholder="Select Date Range"
                                value={(range) ? `${dayjs(range.from).format("YYYY-MM-DD")} to ${dayjs(range.to).format("YYYY-MM-DD")}` : ''}
                                name="dateRange"
                                onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                                readOnly
                              />
                              {
                                (filterValues.dateRange) ?
                                  <DayPicker
                                    mode="range"
                                    defaultMonth={pastMonth}
                                    selected={range}
                                    footer={footer}
                                    modifiersStyles={{
                                      selected: {
                                        background: 'blue',
                                        color: 'white',
                                        fontWeight: 'bold',
                                      },
                                    }}
                                    onSelect={setRange}
                                    style={{ position: "absolute", left: "0", marginTop: "40px", background: "white", boxSizing: "border-box", padding: "35px", boxShadow: "0px 15px 44px rgba(10, 18, 73, 0.15)" }}
                                    styles={{
                                      day: { boxSizing: "border-box", padding: '10px', borderRadius: "8px", fontFamily: "Mulish", },
                                      caption: { fontFamily: "Mulish" },
                                    }}
                                  /> : null
                              }
                            </div>
                          </div>
                          <div className={`div-md-3 more-info__top__row__item --username`}>
                            <div className={`container-full more-info__top__row__item__desc`}>
                              <div className={`more-info__top__row__item__desc__checkbox`}>
                                <input
                                  type="checkbox"
                                  name="username"
                                  onChange={(event) => changeFilterValue(event, event.target.name, event.target.value)}
                                  defaultChecked={(filterValues.username) ? true : false}
                                />
                                <Image image={nike} />
                              </div>
                              <Text value='Username' />
                            </div>
                            <div className={`container-full more-info__top__row__item__input`}>
                              <Input type="text"
                                className={`input ${(filterValues.username === undefined) ? 'disabled' : null
                                  }`}
                                placeholder="Enter Username"
                                name="username"
                                value={fields.username}
                                onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                              />
                            </div>
                          </div>
                          <div className={`div-md-3 more-info__top__row__item`}>
                            <div className={`container-full more-info__top__row__item__desc`}>
                              <div className={`more-info__top__row__item__desc__checkbox`}>
                                <input
                                  type="checkbox"
                                  name="actionType"
                                  onChange={(event) => changeFilterValue(event, event.target.name, event.target.value)}
                                  defaultChecked={(filterValues.actionType) ? true : false}
                                />
                                <Image image={nike} />
                              </div>
                              <Text value='Action Type' />
                            </div>
                            <div className={`container-full more-info__top__row__item__input`}>
                              <div className='input'>
                                <select required
                                  className={
                                    `${(filterValues.actionType === undefined) ? 'disabled' : null}`
                                  }
                                  name="actionType"
                                  value={fields.actionType}
                                  onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                                >
                                  <option value="" hidden> Select Action Type </option>
                                  <option value="1"> User Login </option>
                                  <option value="2"> Give Approval </option>
                                  <option value="3"> Create </option>
                                  <option value="4"> Update </option>
                                  <option value="5"> Initiate Payment  </option>
                                  <option value="6"> Give Final Payment Approval </option>
                                  {/* <option value="7"> Send Fee For Calculation </option> */}
                                </select>
                                <Image image={caret} />
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                      <div className={`container-full more-info__bottom`}>
                        <Button value="Cancel" className='button --cancel' onClick={() => setSelectedMenu(false)} />
                        <Button value="Apply Filters" className={`button --apply`} onClick={() => fetchFilteredContent()} />
                      </div>
                    </div>
                  </button>
                )
              }
            </div>
          </div>
          <div className='container-full issuers__table'>
            {
              (state.dashboardReducer.results !== null) ?
                <AuditTable data={state.dashboardReducer.results} pagination={size} setPagination={changeSize} changePage={changePage} page={page} reloadPage={refreshTable} /> : null
            }
          </div>
        </div>
      </AuditLogTemplateStyle>
    </>
  );

};

export default AuditLogTemplate;
