import React, {useState, useEffect} from 'react';
import AddUserModalStyle from './AddUserModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import Input from '../../atoms/Input/Input';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {SubmitActionsPOST} from '../../../redux/actions/SubmitAction';
import { alertActions } from '../../../redux/actions/AlertActions';
import {appInstance as axios} from '../../../axios';
import Cookies from 'js-cookie';

type AddUserModalProps = {
    handleClose: Function,
    reloadPage: Function
}

const AddUserModal = ({handleClose, reloadPage}: AddUserModalProps) => {
    const dispatch: TypedDispatch = useDispatch();
    const state = useSelector( (state:RootStore) => state);
    const [fields, setFields] = useState({
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      username: '',
    })
    const [fieldErrors, setFieldErrors] = useState({
      firstname: 'Field cannot be empty',
      lastname: 'Field cannot be empty',
      email: 'Field cannot be empty',
      phone: 'Field cannot be empty',
      username: 'Field cannot be empty',
    })
    const [roles, setRoles] = useState([]);
    const [rolesList, setRolesList] = useState([]);
    const [submitSuccessfull, setSubmitSuccessfull] = useState(false);

    useEffect(() => {
      generatePageContent();
    }, [])

    useEffect(() => {
      if(state.submitReducer.results !== null && submitSuccessfull){
        handleClose();
        reloadPage();
      }
    }, [state.submitReducer, submitSuccessfull])
  
    const generatePageContent = async () => {
      try{
        const res = await axios.get('/roles?status=1&size=0&page=1', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get("userToken")}`
          }
        });
        setRolesList(res.data.data.content);
      }catch (error: any) {
        if (error.message === "Network Error") {
          dispatch(alertActions.error('Network Error, try again!!!'));
        } else {
          dispatch(alertActions.error(error.response.data.error));
        }
      }
    }

    const onFormSubmit = () => {
      return async (evt: React.FormEvent) => { 
          evt.preventDefault();
          const fieldValue = fields;
          await dispatch(
            SubmitActionsPOST(`/users`, {
              "email": fieldValue.email,
              "firstName": fieldValue.firstname,
              "lastName": fieldValue.lastname,
              "phoneNumber": fieldValue.phone,
              "username": fieldValue.username,
              "userRoles": roles,
            })
          )
          setSubmitSuccessfull(true);
      }
    }
  
    const inputChange =  async (name: string, value: string) => {
      const fieldsValues: any = Object.assign({}, fields);
      fieldsValues[name] = value;
      await setFields(fieldsValues);
      onInputValidate(value, name);
    };

    const onInputValidate = async (value: string, name: string) => {
      if(value.length > 0){
        const fieldErrorValues: any = Object.assign({}, fieldErrors);
        fieldErrorValues[name] = false;
        await setFieldErrors(fieldErrorValues);
        return true
      }
      else{
        const fieldErrorValues: any = Object.assign({}, fieldErrors);
        fieldErrorValues[name] = 'Field is required';
        await setFieldErrors(fieldErrorValues);
        return false
      }
    }

    const onCheckBoxChange = (id: string) => {
      return (event: any) => {
          if(event.target.checked){
              let data = id
              let rolesArr:any = [data, ...roles];
              setRoles(rolesArr);
          }
          else{
              let rolesArr = roles;
              for( var i = 0; i < rolesArr.length; i++){ 
                  if (rolesArr[i] === id) { 
                      rolesArr.splice(i, 1); 
                  }
              }
              setRoles(rolesArr)
          }
          console.log(roles); 
      }
    } 
  
    const validate = () => {         
      const person:any = fieldErrors;
      if (Object.keys(fieldErrors).filter((k) => person[k]).length){
          return true;
      }
      return false;
    } 
  
    return (
        <AddUserModalStyle className={`container-full`}>
            <div className={`container-full center modal-container`}>
                <div className={`modal-container__box`}>
                    <div className={`container-full modal-container__box__header`}>
                     <HeaderText value='Add User' />
                     <Image image={close} onClick={() => handleClose()}/>
                    </div>
                    <form onSubmit={onFormSubmit()}>
                    <div className={`container-full modal-container__box__content`}>
                     <div className={`container-full modal-container__box__content__desc`}>
                        <Text value='Fill in the form to add a user' />
                     </div>
                     <div className={`container-full modal-container__box__content__desc__form`}>
                        <div className={`container-full modal-container__box__content__desc__form__item`}>
                          <Text value='First Name' className='label' />
                          <div className='container-full'>
                            <Input type='text' className='input' minLength={3} placeholder='Enter Firstname' required={true} name="firstname" onChange={(evt) => inputChange(evt.target.name, evt.target.value) }/>
                          </div>
                        </div>
                        <div className={`container-full modal-container__box__content__desc__form__item`}>
                            <Text value='Last Name' className='label' />
                            <div className='container-full'>
                              <Input type='text' className='input' minLength={3} placeholder='Enter Lastname' required={true} name="lastname" onChange={(evt) => inputChange(evt.target.name, evt.target.value) } />
                            </div>
                        </div>
                        <div className={`container-full modal-container__box__content__desc__form__item`}>
                            <Text value='Email' className='label' />
                            <div className='container-full'>
                              <Input type='text' className='input' placeholder='Enter Email Address' required={true} name="email" onChange={(evt) => inputChange(evt.target.name, evt.target.value) } />
                            </div>
                        </div>
                        <div className={`container-full modal-container__box__content__desc__form__item`}>
                            <Text value='Phone Number' className='label' />
                            <div className='container-full'>
                              <Input type='text' className='input' placeholder='Enter Phone Number eg 2348098976787' required={true} name="phone" onChange={(evt) => inputChange(evt.target.name, evt.target.value) } />
                            </div>
                        </div>
                        <div className={`container-full modal-container__box__content__desc__form__item`}>
                            <Text value='Username' className='label' />
                            <div className='container-full'>
                              <Input type='text' className='input' placeholder='Enter Username' required={true} name="username" onChange={(evt) => inputChange(evt.target.name, evt.target.value) } />
                            </div>
                        </div>
                        <div className={`container-full modal-container__box__content__desc__form__item`}>
                            <Text value='Roles' className='label' />
                            <div className='container-full modal-container__box__content__desc__form__item__role'>
                              {
                                rolesList.length > 0 ? rolesList.map((role: Record<any, any>) => {
                                  return (
                                    <div className='modal-container__box__content__desc__form__item__role__item' key={role.id}>
                                      <Input type='checkbox' onChange={onCheckBoxChange(role.id)}/>
                                      <div className='modal-container__box__content__desc__form__item__role__item__box'>
                                        <Text value={role.name} />
                                        {
                                          (role.name !== "Administrator" && role.name !== "Initiator" && role.name !== "Authorizer" && role.name !== "Audit") &&
                                          <span>
                                            {
                                              role.roleType === 1 && state.authReducer.results.user_level === 2 && '(Audit)'
                                            }
                                            {
                                              role.roleType === 1 && state.authReducer.results.user_level === 1 && '(Initiator)'
                                            }
                                            {
                                              role.roleType === 2 && '(Authorizer)'
                                            }
                                            {
                                              role.roleType === 3 && '(Admin)'
                                            }
                                          </span>
                                        }
                                      </div>
                                    </div>
                                  )
                                })  : ''
                              }
                            </div>
                        </div>
                     </div>
                    </div>
                    <div className={`container-full center modal-container__box__action`}>
                        <Button value="Cancel" className={`button --cancel`} onClick={() => handleClose()}/>
                        <Button type="submit" value="Create User" className={`button --change ${
                          validate() === false && roles.length > 0 ? '--active-btn' : ''
                        }`} isLoading={state.submitReducer.fetching}/>
                    </div>
                    </form>
                </div>  
            </div>
        </AddUserModalStyle>
    );
}

export default AddUserModal;