import React, { useState, useEffect } from 'react';
import MerchantEditProfileTemplateStyle from './MerchantEditProfileTemplateStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import angleLeft from '../../../assets/images/angle-small-left.svg';
import merchantProfile from '../../../assets/images/merchant-profile.svg';
import editMerchant from '../../../assets/images/edit-merchant.svg';
import deactivateMerchant from '../../../assets/images/deactivate-merchant.svg';
import MerchantNav from '../../organisms/MerchantNav/MerchantNav';
import document from '../../../assets/images/document-new.svg';
import editIcon from '../../../assets/images/close.png';
import { Link } from 'react-router-dom';
import Input from '../../atoms/Input/Input';
import revertIcon from '../../../assets/images/revert-icon.svg';
import checkIcon from '../../../assets/images/circle-check.svg';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { DashboardActions } from '../../../redux/actions/DashboardAction';
import { SubmitActionsPUT, SubmitActionsUpload } from '../../../redux/actions/SubmitAction';
import Button from '../../atoms/Button/Button';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { appInstance as axios } from '../../../axios';
import cogoToast from "cogo-toast";
import fileIconAdd from '../../../assets/images/Subtract.svg';

const MerchantEditProfileTemplate = () => {
  let timeOutId: any = null;
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(true);
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  const [imageUrl, setFileimageUrl] = useState<string>();
  const [fields, setFields] = useState({
    name: '',
    email: '',
    rcNumber: '',
    phone: '',
    address: '',
    description: '',
    kyc: []
  })
  const [processSalary, setProcessSalary] = useState(false);
  const [uploadFields, setUploadFields] = useState({
    name: '',
    description: ''
  })

  useEffect(() => {
    setDefaultValues();
  }, [state.dashboardReducer])

  const setDefaultValues = () => {
    if (state.dashboardReducer.results !== null) {
      let fields = {
        name: state.dashboardReducer.results.data.content[0].businessName,
        email: state.dashboardReducer.results.data.content[0].businessEmail,
        rcNumber: state.dashboardReducer.results.data.content[0].rcNumber,
        phone: state.dashboardReducer.results.data.content[0].businessPhoneNumber,
        address: state.dashboardReducer.results.data.content[0].address,
        description: state.dashboardReducer.results.data.content[0].description,
        kyc: state.dashboardReducer.results.data.content[0].kyc
      }
      setFields(fields);
    }
  }

  useEffect(() => {
    if (state.dashboardReducer.results && state.dashboardReducer.results.data.content[0].companyLogoUri) {
      openImageFile(state.dashboardReducer.results.data.content[0].companyLogoUri);
    }
  }, [state.dashboardReducer])

  useEffect(() => {
    if (state.submitReducer.results) {
      let data = {
        name: uploadFields.name,
        description: uploadFields.description,
        fileUri: state.submitReducer.results.data.fileUri
      }
      let newArr: any = [
        ...fields["kyc"],
        data
      ]
      console.log(newArr);
      const fieldsValues: any = Object.assign({}, fields);
      fieldsValues.kyc = newArr;
      setFields(fieldsValues);
      console.log(fields);
    }
  }, [state.submitReducer])
  console.log(fields);
  useEffect(() => {
    generatePageContent();
  }, [])

  const generatePageContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/merchant?${window.location.href.split("?")[1]}`));
    console.log(state);
    setFetchLoading(false);
  }

  const openImageFile = async (fileUri: string) => {
    const apiDefinition = () => {
      return axios.get(`${fileUri.substring(4)}`,
        {
          responseType: "blob",
          headers: {
            "Authorization": `Bearer ${Cookies.get("userToken")}`,
          }
        }
      );
    };
    try {
      setFetchLoading(true);
      const { data } = await apiDefinition();
      const url = URL.createObjectURL(new Blob([data]));
      setFileimageUrl(url);
      setFetchLoading(false);
    } catch (error) {
      cogoToast.error("Error downloading file", { position: "top-right" })
    }
  }

  const renderFetchLoading = () => {
    if (fetchLoading) {
      return (
        <FetchLoading />
      )
    }
  }

  const onClickHandler = (value: boolean) => {
    if (selectedMenu !== false) {
      setSelectedMenu(false);
      return;
    }
    setSelectedMenu(value);
  }

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
    });
  }

  const onFocusHandler = () => {
    clearTimeout(timeOutId);
  }

  const inputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues[name] = value;
    await setFields(fieldsValues);
  };

  const onCheckboxChange = () => {
    return (event: any) => {
      if (event.target.checked) {
        setProcessSalary(true);
        return
      }
      setProcessSalary(false);
    }
  }

  const uploadInputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, uploadFields);
    fieldsValues[name] = value;
    await setUploadFields(fieldsValues);
  };

  const onFormSubmit = () => {
    return async (event: React.FormEvent) => {
      event.preventDefault();
      let stateValue = state.dashboardReducer.results.data.content[0]
      const defaultValues: {
        [key: string]: any
      } = {
        "address": stateValue.address,
        "businessEmail": stateValue.businessEmail,
        "businessName": stateValue.businessName,
        "businessPhoneNumber": stateValue.businessPhoneNumber,
        "rcNumber": stateValue.rcNumber,
        "description": stateValue.description,
        "kyc": stateValue.kyc,
        "processSalary": stateValue.processSalary
      }
      const newValues: {
        [key: string]: any
      } = {
        "address": fields.address,
        "businessEmail": fields.email,
        "businessName": fields.name,
        "businessPhoneNumber": fields.phone,
        "rcNumber": fields.rcNumber,
        "description": fields.description,
        "kyc": fields.kyc,
        "processSalary": processSalary
      }
      let diff = Object.keys(newValues).reduce((diff, key) => {
        if (defaultValues[key] === newValues[key]) return diff
        return {
          ...diff,
          [key]: newValues[key]
        }
      }, {})
      if (Object.keys(diff).length > 0) {
        await dispatch(SubmitActionsPUT(`/merchant/${window.location.href.split("=")[1]}`, diff))
      }
    }
  }

  const removeItem = async (fileUri: string) => {
    let newArr = fields.kyc.filter((file: Record<any, any>) => file.fileUri !== fileUri);
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues["kyc"] = newArr;
    await setFields(fieldsValues);
  }

  const fileUpload = async (file: any) => {
    setSelectedMenu(false);
    setFetchLoading(true);
    let value = {
      file,
      fileType: 'KYC'
    }
    await dispatch(SubmitActionsUpload('/files/upload/', value));
    setUploadFields({
      name: '',
      description: ''
    })
    setFetchLoading(false);
  }

  return (
    <>
      {renderFetchLoading()}
      <MerchantEditProfileTemplateStyle>
        <div className={`container-full merchants`}>
          <div className={`container-full merchants__back`}>
            <Link to="/merchants">
              <Image image={angleLeft} />
              <Text value='Back to Merchants' />
            </Link>
          </div>
          <div className={`container-full merchants__desc`}>
            <HeaderText value='Merchants' />
          </div>
          <form onSubmit={onFormSubmit()}>
            <div className={`container-full merchants__top`}>
              <div className={`container-full merchants__top__desc`}>
                <div className={`merchants__top__desc__name`}>
                  {
                    (state.dashboardReducer.results && imageUrl) ?
                      <Image image={imageUrl} /> : <Image image={merchantProfile} />
                  }
                  <div className={`merchants__top__desc__name__group`}>
                    <HeaderText value={(state.dashboardReducer.results) ? state.dashboardReducer.results.data.content[0].businessName : ''} />
                    <Text value={(state.dashboardReducer.results) ? state.dashboardReducer.results.data.content[0].businessEmail : ''} />
                  </div>
                </div>
                <div className={`merchants__top__desc__action`}>
                  <button className='button --revert' onClick={() => setDefaultValues()}>
                    <Image image={revertIcon} />
                    Revert
                  </button>
                  <Button value={
                    <>
                      <Image image={checkIcon} />
                      Save Changes
                    </>
                  } className='button --save' type='submit' isLoading={state.submitReducer.fetching} />
                </div>
              </div>
              <div className={`container-full merchants__top__nav`}>
                <div>
                  <MerchantNav />
                </div>
                <p className='date'> Added: <span> {
                  (state.dashboardReducer.results) ? dayjs(state.dashboardReducer.results.data.content[0].createdDate).format('ddd, D MMMM, YYYY') : ''
                } </span> </p>
              </div>
            </div>
            <div className={`container-full merchants__info`}>
              <div className={`container-full merchants__info__desc`}>
                <HeaderText value='Business Information' />
              </div>
              <div className={`container-full merchants__info__box`}>
                <div className='container-full merchants__info__box__row'>
                  <div className='div-md-6 merchants__info__box__row__item'>
                    <HeaderText value='Name' />
                    <Input type="text" className={`input`} name="name" required value={fields.name} onChange={(evt) => inputChange(evt.target.name, evt.target.value)} />
                  </div>
                  <div className='div-md-6 merchants__info__box__row__item'>
                    <HeaderText value='Email' />
                    <Input type="text" className={`input`} name="email" required value={fields.email} onChange={(evt) => inputChange(evt.target.name, evt.target.value)} />
                  </div>
                </div>
                <div className='container-full merchants__info__box__row'>
                  <div className='div-md-6 merchants__info__box__row__item'>
                    <HeaderText value='RC Number' />
                    <Input type="text" className={`input`} name="rcNumber" required value={fields.rcNumber} onChange={(evt) => inputChange(evt.target.name, evt.target.value)} />
                  </div>
                  <div className='div-md-6 merchants__info__box__row__item'>
                    <HeaderText value='Contact Number' />
                    <Input type="text" className={`input`} name="phone" required value={fields.phone} onChange={(evt) => inputChange(evt.target.name, evt.target.value)} />
                  </div>
                </div>
                <div className='container-full merchants__info__box__row'>
                  <div className='div-md-12 merchants__info__box__row__item'>
                    <HeaderText value='Allow Salary Processing' />
                    <div className={`merchants__info__box__row__item__switch`}>
                      <input type="checkbox" className={`input`} defaultChecked={
                        state.dashboardReducer.results && state.dashboardReducer.results.data.content[0] && state.dashboardReducer.results.data.content[0].processSalary
                      } onChange={onCheckboxChange()} />
                      <div className={`merchants__info__box__row__item__switch__box`}>
                        <div className={`merchants__info__box__row__item__switch__box__ball`}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`container-full merchants__info`}>
              <div className={`container-full merchants__info__desc`}>
                <HeaderText value='Emergency Contact' />
              </div>
              <div className={`container-full merchants__info__box`}>
                <div className='container-full merchants__info__box__row'>
                  <div className='div-sm-12 merchants__info__box__row__item'>
                    <HeaderText value='Address' />
                    <Input type="text" className={`input`} name="address" required value={fields.address} onChange={(evt) => inputChange(evt.target.name, evt.target.value)} />
                  </div>
                </div>
              </div>
            </div>
            <div className={`container-full merchants__info`}>
              <div className={`container-full merchants__info__desc`}>
                <HeaderText value='KYC Documents' />
              </div>
              <div className={`container-full merchants__info__box`}>
                <div className='container-full merchants__info__box__row --kyc'>
                  <div className={`div-md-10`}>
                    {
                      fields.kyc !== undefined && fields.kyc.length > 0 && fields.kyc.map((kyc: Record<any, any>, key: number) => {
                        if (kyc.fileUri) {
                          return (
                            <div className='div-md-5 merchants__info__box__row__item --documents' onClick={() => removeItem(kyc.fileUri)} key={key}>
                              <Image image={document} className="document" />
                              <div>
                                <HeaderText value={kyc.name} />
                                <Text value='PDF' />
                              </div>
                              <Image image={editIcon} className="expand" />
                            </div>
                          )
                        }
                      })
                    }
                  </div>
                  <div className={`div-md-2 merchants__info__box__row__add-file`}>
                    <div className={`container-full`} onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
                      <button type="button" onClick={() => onClickHandler(true)} aria-haspopup="true" aria-expanded={selectedMenu}>
                        <Image image={fileIconAdd} />
                      </button>
                      {
                        selectedMenu !== false && (
                          <div className={`container-full more-info`}>
                            <div className={`container-full more-info__item`}>
                              <Text value='File Name' />
                              <Input type="text" className='input' placeholder='Enter file name' name="name" onChange={(evt) => uploadInputChange(evt.target.name, evt.target.value)} />
                            </div>
                            <div className={`container-full more-info__item`}>
                              <Text value='File Description' />
                              <Input type="text" className='input' placeholder='Enter file description' name="description" onChange={(evt) => uploadInputChange(evt.target.name, evt.target.value)} />
                            </div>
                            <div className={`container-full more-info__item`}>
                              <Text value='Select File' />
                              <Input type="file" id="file" className={`input ${(uploadFields.name && uploadFields.description) ? null : 'disabled'
                                }`} onChange={(event) => fileUpload(event.target.files[0])} accept="application/pdf" />
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </MerchantEditProfileTemplateStyle>
    </>
  );

};

export default MerchantEditProfileTemplate;
