import React, { useState, useEffect } from 'react';
import MerchantStepOneStyle from './MerchantStepOneStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import { Link, useNavigate } from 'react-router-dom';
import nike from '../../../assets/images/nike.svg';
import MerchantProgressBar from '../MerchantProgressBar/MerchantProgressBar';
import Input from '../../atoms/Input/Input';
import caret from '../../../assets/images/caret-left-new.svg';
import Button from '../../atoms/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { stepOne, stepClear } from '../../../redux/actions/StepAction';


const MerchantStepOne = () => {
    const dispatch: TypedDispatch = useDispatch();
    const state = useSelector((state: RootStore) => state);
    const navigate = useNavigate()
    const [fields, setFields] = useState({
        name: '',
        email: '',
        rcNumber: '',
        number: '',
        address: '',
        description: ''
    })
    const [fieldErrors, setFieldErrors] = useState({
        number: '',
    })
    const [processSalary, setProcessSalary] = useState(false);

    useEffect(() => {
        if (Object.keys(state.stepReducer.results.stepOne).length > 0) {
            let fieldValue = {
                name: state.stepReducer.results.stepOne.name,
                email: state.stepReducer.results.stepOne.email,
                rcNumber: state.stepReducer.results.stepOne.rcNumber,
                number: state.stepReducer.results.stepOne.number,
                address: state.stepReducer.results.stepOne.address,
                description: state.stepReducer.results.stepOne.description
            }
            setFields(fieldValue);
            setProcessSalary(state.stepReducer.results.stepOne.processSalary)
        }
    }, [])

    const inputChange = async (name: string, value: string) => {
        const fieldsValues: any = Object.assign({}, fields);
        fieldsValues[name] = value;
        await setFields(fieldsValues);
        if (name === "number") {
            onInputValidate(value, name);
        }
    };

    const onCheckboxChange = () => {
        return (event: any) => {
            if(event.target.checked){
                setProcessSalary(true);
                return
            }
            setProcessSalary(false);
        }
    }

    const onInputValidate = async (value: string, name: string) => {
        if (/^234[0-9]{10}$/.test(value)) {
            const fieldErrorValues: any = Object.assign({}, fieldErrors);
            fieldErrorValues[name] = false;
            await setFieldErrors(fieldErrorValues);
            return true
        }
        else {
            const fieldErrorValues: any = Object.assign({}, fieldErrors);
            fieldErrorValues[name] = 'Invalid phone number format';
            await setFieldErrors(fieldErrorValues);
            return false
        }
    }

    const onFormSubmit = () => {
        return async (evt: React.FormEvent) => {
            evt.preventDefault();
            if (validate()) {
                return;
            }
            let fieldsValue = {
                ...fields,
                processSalary: processSalary
            }
            await dispatch(stepOne(fieldsValue));
            navigate('/merchants/create/step-two')
            console.log(state);
        }
    }

    const validate = () => {
        const person: any = fieldErrors;
        if (Object.keys(fieldErrors).filter((k) => person[k]).length) {
            return true;
        }
        return false;
    }

    return (
        <MerchantStepOneStyle className={`container-full`}>
            <form onSubmit={onFormSubmit()}>
                <div className={`container-full merchants__body`}>
                    <MerchantProgressBar />
                    <div className={`container-full merchants__body__form`}>
                        <div className={`div-sm-12 merchants__body__form__desc`}>
                            <Text value='Step 1:' className='step' />
                            <HeaderText value='Business Information' className='header' />
                            <Text value='Enter company details to create merchant' className='desc' />
                        </div>
                        <div className={`container-full merchants__body__form__form`}>
                            <div className={`container-full merchants__body__form__form__row`}>
                                <div className='div-md-6 merchants__body__form__form__row__item'>
                                    <Text value='Name' />
                                    <Input type="text" className='input' placeholder='Enter registered company name' required value={fields.name} name="name" onChange={(evt) => inputChange(evt.target.name, evt.target.value)} />
                                </div>
                                <div className='div-md-6 merchants__body__form__form__row__item'>
                                    <Text value='Email' />
                                    <Input type="email" className='input' placeholder='Enter business email address' required value={fields.email} name="email" onChange={(evt) => inputChange(evt.target.name, evt.target.value)} />
                                </div>
                            </div>
                            <div className={`container-full merchants__body__form__form__row`}>
                                <div className='div-md-6 merchants__body__form__form__row__item'>
                                    <Text value='RC Number' />
                                    <Input type="number" className='input' placeholder='Enter RC number' required value={fields.rcNumber} name="rcNumber" onChange={(evt) => inputChange(evt.target.name, evt.target.value)} />
                                </div>
                                <div className='div-md-6 merchants__body__form__form__row__item'>
                                    <Text value='Contact Number' />
                                    <Input type="text" className='input' placeholder='Enter contact number e.g 2349068193245' required value={fields.number} name="number" onChange={(evt) => inputChange(evt.target.name, evt.target.value)} />
                                    <span> {fieldErrors.number} </span>
                                </div>
                            </div>
                            <div className={`container-full merchants__body__form__form__row`}>
                                <div className='div-md-12 merchants__body__form__form__row__item'>
                                    <Text value='Address' />
                                    <Input type="text" className='input' minLength={10} placeholder='Enter office address' required value={fields.address} name="address" onChange={(evt) => inputChange(evt.target.name, evt.target.value)} />
                                </div>
                            </div>
                            <div className={`container-full merchants__body__form__form__row`}>
                                <div className='div-md-12 merchants__body__form__form__row__item'>
                                    <Text value='Business Description' />
                                    <Input type="text" className='input' placeholder='Enter business description' required value={fields.description} name="description" onChange={(evt) => inputChange(evt.target.name, evt.target.value)} />
                                </div>
                            </div>
                            <div className={`container-full merchants__body__form__form__row`}>
                                <div className='div-md-12 merchants__body__form__form__row__item'>
                                    <Text value='Allow Salary Processing' />
                                    <div className={`merchants__body__form__form__row__switch`}>
                                        <input type="checkbox" className={`input`} checked={processSalary} onChange={onCheckboxChange()}/>
                                        <div className={`merchants__body__form__form__row__switch__box`}>
                                            <div className={`merchants__body__form__form__row__switch__box__ball`}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`container-full merchants__action`}>
                    <Link to="/merchants">
                        <button className='button --back'>
                            <Image image={caret} />
                            Back
                        </button>
                    </Link>
                    <Button value="Save & Continue" className='button --save' />
                </div>
            </form>
        </MerchantStepOneStyle>
    );
}

export default MerchantStepOne;