import React, { useEffect, useState } from 'react';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import PartnerReportTableStyle from './PartnerReportTableStyle';
import nike from '../../../assets/images/nike.svg';
import Pagination from '../Pagination/Pagination';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { exportData } from '../../../redux/actions/ExportAction';
import Cookies from 'js-cookie';
import { appInstance as axios } from '../../../axios';
import cogoToast from "cogo-toast";
import exportIcon from '../../../assets/images/download-icon.svg';


type PartnerReportTableProps = {
  data: any,
  pagination: number,
  setPagination: Function,
  changePage: Function,
  page: number,
  reloadPage: Function
}

const PartnerReportTable = ({ data, pagination, setPagination, changePage, page, reloadPage }: PartnerReportTableProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state: any = useSelector((state: RootStore) => state);

  const handleAllCheck = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      await dispatch(exportData(state.dashboardReducer.results.data.content))
      console.log(state.exportReducer);
    }
    else {
      await dispatch(exportData([]));
      console.log(state.exportReducer);
    }
  };

  const handleCheckItem = (item: Record<any, any>) => {
    if (state.exportReducer.results.data.includes(item)) {
      const newArr = state.exportReducer.results.data.filter((record: Record<any, any>) => record.id !== item.id)
      console.log(newArr);
      return dispatch(exportData(newArr));
    }
    const update = [...state.exportReducer.results.data, item]
    console.log(update);
    return dispatch(exportData(update));
  };

  const downloadFile = async (file: string) => {
    const apiDefinition = () => {
      return axios.get(`/files/${file.split("/files/")[1]}`,
        {
          responseType: "blob",
          headers: {
            "Authorization": `Bearer ${Cookies.get("userToken")}`,
          }
        }
      );
    };
    try {
      const { data } = await apiDefinition();
      const url = window.URL.createObjectURL(new Blob([data]));
      console.log(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      cogoToast.error("Error downloading file", { position: "top-right" })
    }
  }

  const statusArr = [
    "not synced",
    "synced",
    "sync failed"
  ]

  return (
    <>
      <PartnerReportTableStyle>
        <div className={`container-full`}>
          <table>
            <thead>
              <tr className={`table-header`}>
                <th>
                  <input type="checkbox" onChange={handleAllCheck} />
                  <Image image={nike} />
                </th>
                <th>
                  <Text value="Transaction Info" className={`table-title`} />
                </th>
                {
                  state.authReducer.results.user_level === 2 && (
                    <th>
                      <Text value="Merchant Name" className={`table-title`} />
                    </th>
                  )
                }
                <th>
                  <Text value="Transaction Date" className={`table-title`} />
                </th>
                <th>
                  <Text value="Partner Name" className={`table-title`} />
                </th>
                <th>
                  <Text value="Partner Bank" className={`table-title`} />
                </th>
                <th>
                  <Text value="Sync Status" className={`table-title`} />
                </th>
                <th>
                  <Text value="Amount" className={`table-title`} />
                </th>
                <th>
                  <Text value="More" className={`table-title`} />
                </th>
              </tr>
            </thead>
            <tbody className={`table-body`}>
              {
                (data.data.content !== undefined && data.data.content.length > 0) ?
                  data.data.content.map((report: Record<any, any>, key: string) => {
                    return (
                      <tr className={`table-row`} key={key}>
                        <td>
                          <input
                            type="checkbox"
                            checked={state.exportReducer.results.data.includes(
                              report
                            )}
                            onChange={() => handleCheckItem(report)}
                          />
                          <Image image={nike} />
                        </td>
                        <td className='name'>
                          <div className='status --successful'></div>
                          <div>
                            <Text value={report.paymentName} className='label' />
                            <Text value={report.paymentRef} className='sublabel' />
                          </div>
                        </td>
                        {
                          state.authReducer.results.user_level === 2 && (
                            <td>
                              <Text value={report.merchant && report.merchant.businessName} className='label' />
                            </td>
                          )
                        }
                        <td>
                          <Text value={dayjs(report.date).format('MM/DD/YYYY')} className='label' />
                        </td>
                        <td>
                          <Text value={report.partnerName} className='label' />
                        </td>
                        <td>
                          <Text value={report.partner && report.partner.bankName} className='label' />
                          <Text value={report.partner && report.partner.accountNumber} className='sublabel' />
                        </td>
                        <td>
                          <Text value={statusArr[report.sync]} className='label' />
                        </td>
                        <td>
                          <Text value={`₦${report.totalAmount}`} className='label' />
                        </td>
                        <td>
                          <button className={`button --export`} onClick={report.sync === 1 ? () => downloadFile(report.syncFileId) : () => { cogoToast.error("File not synced yet", { position: "top-right" }) }}>
                            <Image image={exportIcon} />
                          </button>
                        </td>

                      </tr>
                    )
                  }) : null
              }
            </tbody>
          </table>
        </div>
        <div className={`container-full pagination`}>
          <Pagination pagination={pagination} changePage={changePage} setPagination={setPagination} page={page} />
        </div>
      </PartnerReportTableStyle>
    </>
  );
};

export default PartnerReportTable;
