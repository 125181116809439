import styled from "styled-components";
import Colors from "../../../helpers/Colors";
// import Colors from '../../../helpers/Colors';

const OngoingPaymentsListTableStyle = styled.div`
  position: relative;
  width: 100%;
  .table{
    overflow-x: scroll;
  }
  table {
    width: 100%;
    input[type='checkbox'] {
      -moz-appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;
      background: #FFFFFF;
      border-radius: 3px;
      height: 16px;
      outline: 0.52531px solid #B5BABD !important;
      position: relative;
      transition: all 0.2s linear;
      width: 16px;
      & + img {
        margin-left: -12px;
        margin-top: 17px;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: all 0.2s linear;
        transform: scale(0);
        transform-origin: bottom left;
      }
      &:checked {
        background: #3751FF;
        outline: 2px solid #3751FF !important;
      }
      &:checked + img {
        transform: scale(1);
      }
  }
  .table-header {
    th{
      background: #F2F3F6;
      border-collapse: no-collapse;
      padding: 13px 12px 11px 14px;
      position: relative;
      text-align: left;
      white-space: nowrap;
      .table-title {
        border: 0 !important;
        color: #252733;
        font-family: 'Mulish';
        font-feature-settings: 'salt' on;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
  .table-body{
    .--error-row{
      background: rgba(241, 43, 44, 0.1);
    }
    .--active-row{
      background: linear-gradient(0deg, rgba(41, 204, 151, 0.08), rgba(41, 204, 151, 0.08)), #FFFFFF;
    }
    td{
      box-sizing: border-box;
      padding: 17.5px 12px 8px 14px;
      position: relative;
      vertical-align: top;
      white-space: nowrap;
      input[type='checkbox'] {
        & + img {
          margin-left: -12px;
          margin-top: 30px;
        }
      }
    }
    .label{
      color: #252733;
      font-family: 'Mulish';
      font-feature-settings: 'salt' on;
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
    }
    .sublabel{
      color: rgba(37, 39, 51, 0.5);
      font-family: 'Mulish';
      font-feature-settings: 'salt' on;
      font-style: normal;
      font-weight: 300;
      font-size: 11px;
      line-height: 14px;
    }
    .--remove-top{
      margin-top: 0;
    }
    .--name{
      cursor: pointer;
    }
    .date{
      color: rgba(37, 39, 51, 0.5);
      font-family: 'Mulish';
      font-feature-settings: 'salt' on;
      font-style: normal;
      font-weight: 300;
      font-size: 11px;
      line-height: 14px;
      margin-top: 3px;
    }
    .name{
      display: flex;
      img{
        height: 35px;
        margin-right: 8px;
        width: 35px;
      }
    }
    .status{
      border-radius: 5px;
      box-sizing: border-box;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      padding: 2px 6px;
      text-align: center;
    }
    .--active{
      background: rgba(41, 204, 151, 0.08);
      color: #1B9830;
    }
    .--inactive{
      background: rgba(241, 43, 44, 0.1);
      color: #BD0F10;
    }
    .--pending{
      background: rgba(254, 196, 0, 0.1);
      color: #DAAB0B;
    }
    .--elipses{
      all: unset;
      cursor: pointer;
      margin-bottom: 0;
    }
  }
}
  table,
  td,
  th {
    border-collapse: collapse;
  }
  .more-info {
    background: white;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(8, 9, 10, 0.1);
    margin-right: 82.5px;
    margin-top: 6px;
    padding: 5px;
    position: absolute;
    right: 0;
    top: 0;
    width: 141px;
    z-index: 4000;
  }
  .more-info__item{
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 2px;
    padding: 14.5px 8px 14.5px 15px;
    transition: all 0.5s linear;
    button{
      all: unset;
      cursor: pointer;
      width: 100%;
    }
    &:hover{
      background: #F5F6FF;
      background-blend-mode: multiply;
      border-radius: 5px;
    }
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }
  }
  .pagination{
    border-top: 1px solid #EEEEEE;
    box-sizing: border-box;
    margin-top: 30px;
    overflow: hidden;
    padding: 20px 23px 20px 20px;
    position: relative;
  }
  .pagination__box{
    margin-bottom: 0;
    margin-top: 10px;
  }
  .pagination__action{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    .button{
      border-radius: 6px;
      box-sizing: border-box;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      height: 40px;
      line-height: 20px;
      margin-right: 8px;
      padding: 10px 15px;
      position: relative;
      text-align: left;
      &:last-child{
        margin-right: 0;
      }
      img{
        position: absolute;
        right: 0;
        margin-right: 10px;
        margin-top: 10px;
        top: 0;
      }
    }
    .--download{
      background: #F5F5F5;
      color: #252733;
      width: 190px;
    }
    .--upload{
      background: #3751FF;
      box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
      color: white;
      width: 183px;
      .input{
        appearance: none;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
    .--proceed{
      background: #3751FF;
      box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
      color: white;
      width: 160px;
    }
  }
`;

export default OngoingPaymentsListTableStyle;