import styled from 'styled-components';

const AlertStyles = styled.div`
    width:100%;
    .alert {
        background: rgba(241, 43, 44, 0.1);
        border-radius: 8px;
        box-sizing:border-box;
        justify-content: normal;
        padding: 10px;
        position: relative;
        img{
            margin-right: 5px;
        }
        p{
            color: #BD0F10;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            margin-top: 3px;
        }
    }
`

export default AlertStyles;