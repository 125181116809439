import styled from 'styled-components';
import search from '../../../assets/images/Search.svg';

const MerchantSourceAccountTemplateStyle = styled.div`
  background: white;
  box-sizing: border-box;
  padding: 20px 40px 30px 30px;
  width: 100%;

  .merchants__back{
    justify-content: normal;
    a{
      display: flex;
    }
    p{
      color: rgba(37, 39, 51, 0.7);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      margin-top: 2.5px;
    }
  }
  .merchants__desc{
    margin-top: 10px;
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
    }
  }
  .merchants__top{
    background: white;
    border-radius: 8px;
    box-shadow: 0px 14px 25px rgba(37, 39, 51, 0.05);
    box-sizing: border-box;
    margin-top: 16px;
    padding: 20px 25px 15px 25px;
  }
  .merchants__top__desc{
    align-items: flex-start;
    border-bottom: 1px solid #F0F0F0;
    box-sizing: border-box;
    padding-bottom: 15px;
  }
  .merchants__top__desc__name{
    align-items: flex-start;
    display: flex;
    img{
      border-radius: 50%;
      height: 62px;
      margin-right: 12px;
      width: 62px;
    }
  }
  .merchants__top__desc__name__group{
    margin-top: 11px;
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 1px;
    }
    p{
      color: rgba(37, 39, 51, 0.5);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
    }
  }
  .merchants__top__desc__action{
    display: flex;
    margin-top: 22px;
    .button{
      border-radius: 5px;
      box-sizing: border-box
      display: flex;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      height: 30px;
      padding: 0 8px;
      text-align: left;
      img{
        margin-right: 3px;
        transform: translateY(4px);
      }
    }
    .--reject{
      background: #FEF0F0;
      color: #BD0F10;
      margin-right: 15.17px;
      position: relative;
      width: 77px;
      &:after{
        background: #F0F0F0;
        content: '';
        height: 21.12px;
        margin-right: -9px;
        margin-top: 4.44px;
        position: absolute;
        right: 0;
        top: 0;
        // transform: rotate(90deg);
        width: 1px;
      }
    }
    .--approve{
      background: #E7F9F4;
      color: #1B9830;
      width: 92px;
    }
  }
  .--validate{
    margin-top: 16px;
  }
  .merchants__top__desc__action__item{
    box-sizing: border-box;
    padding: 0 15.58px;
  }
  .--edit{
    border-right: 1.08333px solid #F0F0F0;
  }
  .merchants__top__nav{
    box-sizing: border-box;
    padding-top: 15px;
    .date{
      margin-top: 10px;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      font-feature-settings: 'salt' on;
      span{
        color: rgba(37, 39, 51, 0.5);
        font-family: 'Mulish';
      }
    }
  }
  .merchants__bottom{
    background: white;
    border-radius: 10px;
    box-sizing: border-box;
    margin-top: 34px;
    padding: 8px;
  }
  .merchants__bottom__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
    }
  }
  .merchants__bottom__empty{
    box-sizing: border-box;
    padding: 144.5px 0;
  }
  .merchants__bottom__empty__box{
    -ms-flex: 0 0 236px;
    -webkit-flex: 0 0 236px;
    flex: 0 0 236px;
    max-width: 236px;
    margin-bottom: 0;
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 5px;
      text-align: center;
    }
    p{
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 10px;
      text-align: center;
    }
    .button{
      background: #3751FF;
      border-radius: 6px;
      box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
      color: #FFFFFF;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      height: 32px;
      margin: auto;
      padding: unset;
      width: 127px;
    }
  }
  .merchants__bottom__content{
    margin-top: 8px;
  }
  .merchants__bottom__content__action{
    .input{
      background: url(${search}), #FAFAFA no-repeat;
      background-size: 18px;
      background-repeat: no-repeat;
      background-position: 8px 8px;
      border: none;
      border-radius: 8px;   
      box-sizing: border-box;
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      height: 34px;
      line-height: 16px;
      padding-left: 37px;
      padding-right: 8px;
      width: 194px;
      &::placeholder{
        color: rgba(159, 162, 180, 0.8)
      }
    }
  }
  .btn-group{
    .button{
      all: unset;
      margin-bottom: 0;
    }
    .--export{
      background: #FAFAFA;
      border-radius: 8px;
      box-sizing: border-box;
      height: 34px;
      margin-right: 10px;
      padding: unset;
      padding: 4px 8px;
      width: 34px;
    }
    .--disabled{
      opacity: 0.5;
      pointer-events: none;
    }
    .--user{
      background: #3751FF;
      border-radius: 8px;
      box-sizing: border-box;
      cursor: pointer;
      height: 34px;
      padding: unset;
      padding: 7px 9px;
      width: 34px;
    }
  }
  .merchants__bottom__content__filter{
    justify-content: normal;
    margin-top: 13px;
    position: relative;
  }
  .merchants__bottom__content__filter__item{
    box-sizing: border-box;
    border-right: 1px solid rgba(37, 39, 51, 0.1);
    padding: 0 10px;
  }
  .--active-filter{
    padding: 0 15px;
    p{
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;
      margin-top: 5.5px;
    }
  }
  .--filter{
    display: flex;
    p{
      color: rgba(109, 111, 121, 0.5);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: -0.01em;
      line-height: 18px;
      margin-left: 7px;
      margin-top: 4px;
    }
  }
  .--filter__box{
    background: #FAFAFA;
    border-radius: 6px;
    box-sizing: border-box;
    height: 26px;
    padding: 4px;
    width: 26px;
  }
  .--add-filter{
    border: none;
    display: flex;
    padding: 0 16px;
    -ms-flex: 0 0 130px;
    -webkit-flex: 0 0 130px;
    flex: 0 0 130px;
    max-width: 130px;
    p{
      color: #6D6F79;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.01em;
      margin-top: 5.5px;
      width: 100%;
    }
    .--elipses{
      all:unset;
      cursor:pointer;
      display: flex;
      width:100%;
    }
  }
  .unset{
    all: unset;
  }
  .more-info {
    background: #FFFFFF;
    box-shadow: 0px 15px 44px rgba(10, 18, 73, 0.15);
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 34px;
    padding: 14.5px 12px 12px 15.33px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 4000;
  }
  .more-info__top{
    border-bottom: 1px solid rgba(37, 39, 51, 0.1);
    box-sizing: border-box;
    padding-bottom: 20px;
  }
  .more-info__top__item{
    margin-bottom: 0;
  }
  .more-info__top__item__desc{
    justify-content: normal;
    p{
      all: unset;
      color: #6D6F79;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;
    }
  }
  .more-info__top__item__desc__checkbox{
    margin-right: 7.33px;
    input[type='checkbox'] {
      -moz-appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;
      background: #FFFFFF;
      border-radius: 3px;
      height: 13.33px;
      outline: 0.52531px solid #B5BABD !important;
      position: relative;
      transition: all 0.2s linear;
      width: 13.33px;
      & + img {
        margin-left: -11px;
        margin-top: 4px;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: all 0.2s linear;
        transform: scale(0);
        transform-origin: bottom left;
      }
      &:checked {
        background: #3751FF;
        outline: 2px solid #3751FF !important;
      }
      &:checked + img {
        transform: scale(1);
      }
    }
  }
  .more-info__top__item__input{
    box-sizing: border-box;
    padding: 5px 0 0 18.67px;
    .input{
      background: #FAFAFA;
      border: none;
      border-radius: 8px;
      box-sizing: border-box;
      color: rgb(109, 111, 121);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      height: 34px;
      padding-left: 10px;
      position: relative;
      width: 100%;
      &::placeholder{
        color: rgba(109, 111, 121, 0.4);
      }
    }
    select{
      appearance: none;
      background: transparent;
      cursor: pointer;
      color: rgb(109, 111, 121);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      margin-top: 9px;
      width: 100%;
    }
    select:required:invalid {
      color: rgba(109, 111, 121, 0.4);
    }
    img{
      margin-top: 15.33px;
      margin-right: 6.67px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .more-info__bottom{
    justify-content: flex-end;
    margin-top: 15px;
    .button{
      border-radius: 8px;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      height: 40px;
      margin-bottom: 0;
      text-align: center;
    }
    .--cancel{
      background: #F5F5F5;
      color: #252733;
      margin-right: 20px;
      width: 75px;
    }
    .--apply{
      background: #3751FF;
      box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
      color: #FFFFFF;
      width: 110px;
    }
    .disabled{
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .merchants__bottom__content__table{
    margin-top: 31px;
  }
`;

export default MerchantSourceAccountTemplateStyle;
