import React, {useState, useEffect} from 'react';
import SuccessModalStyle from './SuccessModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import Input from '../../atoms/Input/Input';
import caret from '../../../assets/images/caret-down-new.svg';
import success from '../../../assets/images/success.svg';

type SuccessModalProps = {
    handleClose: Function;
    message: string;
}

const SuccessModal = ({handleClose, message}: SuccessModalProps) => {
  
    return (
        <SuccessModalStyle className={`container-full`}>
            <div className={`container-full center modal-container`}>
                <div className={`modal-container__box`}>
                    <div className={`container-full modal-container__box__content`}>
                      <div className={`container-full center modal-container__box__content__img`}>
                          <Image image={success} />
                      </div>
                      <div className={`container-full center modal-container__box__content__message`}>
                          <HeaderText value="Success" />
                          <p> {message} </p>
                      </div>
                    </div>
                    <div className={`container-full center modal-container__box__action`}>
                        <Button value="OK" className={`button`} onClick={() => handleClose()} />
                    </div>
                </div>  
            </div>
        </SuccessModalStyle>
    );
}

export default SuccessModal;