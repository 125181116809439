import { StepDispatchTypes, STEP_1, STEP_2, STEP_3, STEP_4, STEP_CLEAR } from '../actions/StepActionTypes';

export interface DefaultState {
  results: any;
}

const defaultState: DefaultState = {
  results: {
    stepOne: {
     
    },
    stepTwo: [],
    stepThree: {

    },
    stepFour: {

    }
  },
};

const stepReducer = (
  state: DefaultState = defaultState,
  action: StepDispatchTypes
) => {
  switch (action.type) {
    case STEP_1:
      return {
        ...state,
        results: {
          ...state.results,
          stepOne: action.data,
        },
      };
    case STEP_2:
      return {
        ...state,
        results: {
          ...state.results,
          stepTwo: action.data,
        },
      };
    case STEP_3:
      return {
        ...state,
        results: {
          ...state.results,
          stepThree: action.data,
        },
      };
    case STEP_4:
      return {
        ...state,
        results: {
          ...state.results,
          stepFour: action.data,
        },
      };
    case STEP_CLEAR:
      return {
        ...state,
        results: {
          stepOne: {
            
          },
          stepTwo: [],
          stepThree: {

          },
          stepFour: {
      
          }
        }
      };
    default:
      return state;
  }
};

export default stepReducer;
