import React, { useState } from 'react';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import PaymentReportDetailsTableStyle from './PaymentReportDetailsTableStyle';
import nike from '../../../assets/images/nike.svg';
import ReportDetailsPagination from '../ReportDetailsPagination/ReportDetailsPagination';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { exportData } from '../../../redux/actions/ExportAction';
import { useNavigate } from "react-router-dom";


type PaymentReportDetailsTableProps = {
  data: any
  page: number
  changePage: Function
}

const PaymentReportDetailsTable = ({ data, page, changePage}: PaymentReportDetailsTableProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  

  const [size, setSize] = useState(10);

  const changeSize = (value: number) => {
    setSize(value);
  }

  // const handleAllCheck = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.checked) {
  //     await dispatch(exportData(state.dashboardReducer.results.data.content))
  //     console.log(state.exportReducer);
  //   }
  //   else {
  //     await dispatch(exportData([]));
  //     console.log(state.exportReducer);
  //   }
  // };

  // const handleCheckItem = (item: Record<any, any>) => {
  //   if (state.exportReducer.results.data.includes(item)) {
  //     const newArr = state.exportReducer.results.data.filter((record: Record<any, any>) => record.id !== item.id)
  //     console.log(newArr);
  //     return dispatch(exportData(newArr));
  //   }
  //   const update = [...state.exportReducer.results.data, item]
  //   console.log(update);
  //   return dispatch(exportData(update));
  // };

  return (
    <>
      <PaymentReportDetailsTableStyle>
        <div className={`container-full`}>
          <table>
            <thead>
              <tr className={`table-header`}>
                {/* <th>
                  <input type="checkbox" onChange={handleAllCheck} />
                  <Image image={nike} />
                </th> */}
                <th>
                  <Text value="Beneficiary" className={`table-title`} />
                </th>
                <th>
                  <Text value="Beneficiary Account" className={`table-title`} />
                </th>
                <th>
                  <Text value="Net Salary" className={`table-title`} />
                </th>
                <th>
                  <Text value="PAYE Details" className={`table-title`} />
                </th>
                <th>
                  <Text value="PAYE Amount" className={`table-title`} />
                </th>
                <th>
                  <Text value="PFA Details" className={`table-title`} />
                </th>
                <th>
                  <Text value="PFA Amount" className={`table-title`} />
                </th>
                <th>
                  <Text value="Employee Contribution" className={`table-title`} />
                </th>
                <th>
                  <Text value="NHF Details" className={`table-title`} />
                </th>
                <th>
                  <Text value="NHF Amount" className={`table-title`} />
                </th>
                <th>
                  <Text value="NHIS Details" className={`table-title`} />
                </th>
                <th>
                  <Text value="NHIS Amount" className={`table-title`} />
                </th>
                <th>
                  <Text value="Deduction 1" className={`table-title`} />
                </th>
                <th>
                  <Text value="Deduction 2" className={`table-title`} />
                </th>
                <th>
                  <Text value="Deduction 3" className={`table-title`} />
                </th>
                <th>
                  <Text value="Deduction 4" className={`table-title`} />
                </th>
                <th>
                  <Text value="Deduction 5" className={`table-title`} />
                </th>
              </tr>
            </thead>
            <tbody className={`table-body`}>
              {
                (data.records !== undefined && data.records.length > 0) ?
                  data.records.map((report: Record<any, any>, key: string) => {
                    return (
                      <tr className={`table-row`} key={key}>
                        {/* <td>
                          <input
                            type="checkbox"
                            checked={state.exportReducer.results.data.includes(
                              report
                            )}
                            onChange={() => handleCheckItem(report)}
                          />
                          <Image image={nike} />
                        </td> */}
                        <td>
                            <Text value={`${report.firstName} ${report.lastName}`} className='label'/>
                            <Text value={report.staffId} className='sublabel' />
                        </td>
                        <td>
                          <Text value={report.accountNumber} className='label' />
                          <Text value={report.bankName} className='sublabel' />
                        </td>
                        <td>
                          <Text value={`₦${report.salaryAmount}`} className='labelbold' />
                        </td>
                        <td>
                          <Text value={report.payeTaxId} className='labelbold' />
                          <Text value={report.payePartnerCode} className='sublabel' />
                        </td>
                        <td>
                          <Text value={`₦${report.payeAmount}`} className='labelbold' />
                        </td>
                        <td>
                          <Text value={report.pensionId} className='label' />
                          <Text value={report.pfaPartnerCode} className='sublabel' />
                        </td>
                        <td>
                          <Text value={`₦${report.penEmpyerAmount}`} className='labelbold'/>
                        </td>
                        <td>
                          <Text value={`₦${report.penEmpyeeAmount}`} className='labelbold'/>
                        </td>
                        <td>
                          <Text value={report.nhfId} className='labelbold' />
                          <Text value={report.nhfPartnerCode} className='sublabel' />
                        </td>
                        <td>
                          <Text value={`₦${report.nhfAmount}`} className='labelbold'/>
                        </td>
                        <td>
                          <Text value={report.nhisId} className='labelbold' />
                          <Text value={report.nhisPartnerCode} className='sublabel' />
                        </td>
                        <td>
                          <Text value={`₦${report.nhisAmount}`} className='labelbold'/>
                        </td>
                        <td>
                          <Text value={report.ded1Amount ? `₦${report.ded1Amount}` : ''} className='labelbold'/>
                        </td>
                        <td>
                          <Text value={report.ded2Amount ? `₦${report.ded2Amount}` : ''} className='labelbold'/>
                        </td>
                        <td>
                          <Text value={report.ded3Amount ? `₦${report.ded3Amount}` : ''} className='labelbold'/>
                        </td>
                        <td>
                          <Text value={report.ded4Amount ? `₦${report.ded4Amount}` : ''} className='labelbold'/>
                        </td>
                        <td>
                          <Text value={report.ded5Amount ? `₦${report.ded5Amount}` : ''} className='labelbold'/>
                        </td>

                      </tr>
                    )
                  }) : null
              }
            </tbody>
          </table>
        </div>
        <div className={`container-full pagination`}>
          <ReportDetailsPagination pagination={size} changePage={changePage} setPagination={changeSize} page={page} data={data}/>
        </div>
      </PaymentReportDetailsTableStyle>
    </>
  );
};

export default PaymentReportDetailsTable;
