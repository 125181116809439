import React, {useState, useEffect} from 'react';
import CreateRoleModalStyle from './CreateRoleModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import Input from '../../atoms/Input/Input';
import caret from '../../../assets/images/caret-down-new.svg';
import nike from '../../../assets/images/nike.svg';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {SubmitActionsPOST} from '../../../redux/actions/SubmitAction';
import { alertActions } from '../../../redux/actions/AlertActions';
import {appInstance as axios} from '../../../axios';
import Cookies from 'js-cookie';

type CreateRoleModalProps = {
    handleClose: Function
    showSuccessModal: Function
}

const CreateRoleModal = ({handleClose, showSuccessModal}: CreateRoleModalProps) => {
    const [fetchLoading, setFetchLoading] = useState(false);
    const [selectedRole, setSelectedRole]: any = useState({
        permissions: []
    });
    const [permissions, setPermissions] = useState([]);
    const [fields, setFields] = useState({
        name: '',
        type: '',
        description: ''
    })
    const dispatch: TypedDispatch = useDispatch();
    const state = useSelector( (state:RootStore) => state);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if(state.submitReducer.results !== null && submitted){
          handleClose();
          showSuccessModal();
        }
    }, [state.submitReducer])

    useEffect(() => {
        if(fields.type !== ''){
            fetchAllPermissions(fields.type);;
        }
    }, [fields.type])

    const renderFetchLoading = () => {
        if(fetchLoading){
            return(
                <FetchLoading />
            )
        }
    }

    const inputChange =  async (name: string, value: string) => {
        const fieldsValues: any = Object.assign({}, fields);
        fieldsValues[name] = value;
        await setFields(fieldsValues);
    };

    const onFormSubmit = () => {
        return async (evt: React.FormEvent) => { 
            evt.preventDefault();
            const fieldValue = fields;
            let permissions:any = [];
            await selectedRole.permissions.map((permission: Record<any, any>) => {
                permissions.push(permission.id)
            })
            let value = {
                "description": fieldValue.description,
                "name": fieldValue.name,
                "permissions": permissions,
                "roleType": fieldValue.type
            }
            if(permissions.length > 0){
                setSubmitted(true)
                await dispatch(
                    SubmitActionsPOST(`/roles`, value)
                )
            }
        }
    }

    const fetchAllPermissions = async (roleType: string) => {
        try{
          setFetchLoading(true);
          const res = await axios.get(`/roles/permissions?roleLevel=${state.authReducer.results.user_level}&roleType=${roleType}&size=0&page=1`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Cookies.get("userToken")}`
            }
          });
          setFetchLoading(false);
          setPermissions(res.data.data.content);
        }catch (error: any) {
          if (error.message === "Network Error") {
            dispatch(alertActions.error('Network Error, try again!!!'));
          } else {
            dispatch(alertActions.error(error.response.data.error));
          }
        }
    }

    const togglePermissions = (permission:any) => {
        if(selectedRole.permissions.some((role: Record<any, any>) => role.id === permission.id)){
          let filterPermissions = selectedRole.permissions.filter((role: Record<any, any>) => role.id !== permission.id);
          let newRole = {
            ...selectedRole,
            permissions: filterPermissions
          }
          setSelectedRole(newRole);
        }
        else{
          let newRole = {
            ...selectedRole,
            permissions: [
              ...selectedRole.permissions,
              permission
            ]
          }
          setSelectedRole(newRole);
        }
    }

    return (
        <>
            {renderFetchLoading()}
            <CreateRoleModalStyle className={`container-full`}>
                <div className={`container-full center modal-container`}>
                    <form onSubmit={onFormSubmit()}>
                    <div className={`modal-container__box`}>
                        <div className={`container-full modal-container__box__header`}>
                            <HeaderText value="Create Role" />
                            <Image image={close} onClick={() => handleClose()}/>
                        </div>
                        <div className={`container-full modal-container__box__content`}>
                            <div className={`modal-container__box__content__details`}>
                                <div className={`container-full modal-container__box__content__details__form`}>
                                    <div className={`container-full modal-container__box__content__details__form__item`}>
                                        <Text value="What will this role be called?" />
                                        <Input type="text" className={`input`} placeholder="Name your role" required={true} name="name" onChange={(evt) => inputChange(evt.target.name, evt.target.value) }/>
                                    </div>
                                    <div className={`container-full modal-container__box__content__details__form__item`}>
                                        <Text value="What type of role is this?" />
                                        <div className={`container-full modal-container__box__content__details__form__item__select`}>
                                            <select required={true} name="type" onChange={(evt) => inputChange(evt.target.name, evt.target.value) }>
                                                <option value=""> Select Type </option>
                                                <option value="1"> Iniator </option>
                                                <option value="2"> Authorizer/Reviewer </option>
                                                <option value="3"> Admin </option>
                                            </select>
                                            <Image image={caret} />
                                        </div>
                                    </div>
                                    <div className={`container-full modal-container__box__content__details__form__item`}>
                                        <Text value="Add a short description" />
                                        <Input type="text" className={`input`} placeholder="Description" required={true} name="description" onChange={(evt) => inputChange(evt.target.name, evt.target.value) }/>
                                    </div>
                                </div>
                                <div className={`container-full modal-container__box__content__details__permissions`}>
                                    <div className={`container-full modal-container__box__content__details__permissions__desc`}>
                                        <HeaderText value="Any user assigned this role will be able to:" />
                                    </div>
                                    <div className={`container-full modal-container__box__content__details__permissions__info`}>
                                        <div className={`container-full modal-container__box__content__details__permissions__info__desc`}>
                                            <HeaderText value='Selected Permissions' />
                                            <Text value='This list will change as permissions are selected' />
                                        </div>
                                        <div className={`container-full modal-container__box__content__details__permissions__info__list`}>
                                            {
                                                selectedRole.permissions && selectedRole.permissions.length > 0 && selectedRole.permissions.map((permission: Record<any,any>) => {
                                                return(
                                                    <div className={`container-full modal-container__box__content__details__permissions__info__list__item`} key={permission.id}>
                                                        <div className={`modal-container__box__content__details__permissions__info__list__item__bullet`}></div>
                                                        <Text value={permission.description} />
                                                    </div>
                                                )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`modal-container__box__content__privileges`}>
                                <div className={`modal-container__box__content__privileges__desc`}>
                                    <HeaderText value="Assign Priviledges" />
                                </div>
                                {
                                    permissions.length > 0 && permissions.map((permission: Record<any, any>) => {
                                        return(
                                            <div className={`container-full modal-container__box__content__privileges__group`}>
                                                {/* <div className={`container-full modal-container__box__content__privileges__group__desc`}>
                                                    <Text value="Home" />
                                                </div> */}
                                                <div className={`container-full modal-container__box__content__privileges__group__item`}>
                                                    <Text value={permission.name} />
                                                    <div className={`modal-container__box__content__privileges__group__item__checkbox`}>
                                                    <input type="checkbox" className={`input`} 
                                                        onChange = {() => togglePermissions(permission)}
                                                    />
                                                    <div className={`modal-container__box__content__privileges__group__item__checkbox__box`}>
                                                        <Image image={nike} />
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className={`container-full modal-container__box__action`}>
                            <Button value="Create Role" type='submit' className={`button --create ${
                                selectedRole.permissions.length > 0 ? '' : 'disabled'
                            }`} isLoading={state.submitReducer.fetching} />
                        </div>
                    </div>  
                    </form>
                    
                </div>
            </CreateRoleModalStyle>
        </>
    );
}

export default CreateRoleModal;