import styled from 'styled-components';

const ProfileTemplateStyle = styled.div`
  background: white;
  box-sizing: border-box;
  padding: 20px 30px;
  width: 100%;

  .settings__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 1.5em;
      line-height: 30px;
    }
  }
  .settings__content{
    box-sizing: border-box;
    padding: 20px;
  }
  .settings__content__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 700;
      font-size: 1em;
      line-height: 20px;
    }
  }
  .settings__content__body{
    margin-top: 23px;
  }
  .settings__content__body__logo{
    -ms-flex: 0 0 23%;
    -webkit-flex: 0 0 23%;
    flex: 0 0 23%;
    max-width: 23%;
    margin-top: 21px;
  }
  .settings__content__body__logo__desc{
    h2{
      color: rgba(37, 39, 51, 0.3);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 14px;
    }
  }
  .settings__content__body__logo__box{
    background: rgba(55, 81, 255, 0.03);
    border-radius: 10px;
    box-sizing: border-box;
    min-height: 208px;
    margin-top: 8px;
    padding: 12px;
    img{
      height: auto;
      width: 100%;
    }
  }
  .settings__content__body__logo__file{
    margin-top: 8px;
    position: relative;
    .logo{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
    .file-name{
      color: rgba(37, 39, 51, 0.3);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 14px;
      margin-top: 2px;
    }
    img{
      cursor: pointer;
      margin-top: 2.2px;
    }
    .input{
      bottom: 0;
      cursor: pointer;
      left: 0;
      opacity: 0;
      position: absolute;
    }
  }
  .settings__content__body__logo__action{
    margin-top: 13px;
    .button{
      background: rgba(241, 43, 44, 0.1);
      background-blend-mode: multiply;
      border-radius: 5px;
      height: 36px;
      padding-left: 36px;
      position: relative;
      width: 157px;
      img{
        height: 18px;
        left: 0;
        margin: 7px 0 0 15px;
        position: absolute;
        top: 0;
        width: 18px;
      }
      p{
        color: #BD0F10;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 0.2px;
        line-height: 20px;
        text-align: left;
      }
    }
    .--enable{
      background: rgba(41, 204, 151, 0.08);
      p{
        color: #1B9830;
      }
    }
  }
  .settings__content__body__details{
    -ms-flex: 0 0 74%;
    -webkit-flex: 0 0 74%;
    flex: 0 0 74%;
    max-width: 74%;
  }
  .settings__content__body__details__section__desc{
    h2{
      color: rgba(37, 39, 51, 0.3);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 14px;
      text-transform: uppercase;
    }
  }
  .settings__content__body__details__section__box{
    margin-top: 8px;
  }
  .settings__content__body__details__section__box__row{
    margin-bottom: 14px;
    .div-md-6{
      margin-bottom: 0;
    }
    h2{
      color: rgba(37, 39, 51, 0.4);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 9.5px;
    }
    .--password{
      margin-bottom: 8px;
    }
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
      span{
        color: rgba(37, 39, 51, 0.4);
        font-family: 'Mulish';
      }
    }
    .input{
      all: unset;
      appearance: none;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
      width: 100%;
    }
    .button{
      background: #F4F4F6;
      background-blend-mode: multiply;
      border-radius: 5px;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      letter-spacing: 0.2px;
      margin-top: 22px;
      padding: unset;
      height: 26px;
      width: 73px;
    }
    .--elipses{
      all: unset;
      cursor: pointer;
      margin-bottom: 0;
    }
  }
  .settings__content__body__details__section__box__row__item{
    position: relative;
  }
  .settings__content__body__details__section__box__row__item__text{
    -ms-flex: 0 0 88%;
    -webkit-flex: 0 0 88%;
    flex: 0 0 88%;
    display: flex;
    flex-wrap: wrap;
    max-width: 88%;
    p{
      margin-right: 2px;
      &:after{
        content: ",";
      }
      &:last-child{
        &:after{
          content: "";
        }
      }
    }
  }
  .more-info{
    background: white;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(8, 9, 10, 0.1);
    left: 0;
    max-height: 136px;
    margin-top: 61px;
    overflow: auto;
    padding: 5px;
    position: absolute;
    top: 0;
    width: 361px;
    z-index: 4000;
    .active{
      background: #F5F6FF;
      background-blend-mode: multiply;
      border-radius: 5px;
    }
  }
  .more-info__item{
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 2px;
    padding: 7px 8px 7px 15px;
    transition: all 0.5s linear;
    button{
      all:unset;
      cursor:pointer;
      width:100%;
    }
    &:hover{
      background: #F5F6FF;
      background-blend-mode: multiply;
      border-radius: 5px;
    }
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
    }
  }
  .settings__content__body__details__section__box__row__item__password{
    background: rgba(55, 81, 255, 0.03);
    border-radius: 8px;
    box-sizing: border-box;
    height: 40px;
    padding: 10px 15px;
    width: 100%;
    p{
      color: rgba(37, 39, 51, 0.3);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .--remove-bottom{
    margin-bottom: 0;
  }
  .--ip{
    justify-content: flex-end;
  }
  .--timeline{
    margin-top: 52px;
  }
  .--date{
    h2{
      color: rgb(37, 39, 51);
    }
    p{
      color: rgba(37, 39, 51, 0.5);
      span{
        color: rgba(37, 39, 51, 0.3);
        font-family: 'Mulish';
      }
    }
  }
  .settings__content__action{
    border-top: 2px solid #EEEEEE;
    box-sizing: border-box;
    justify-content: flex-end;
    margin-top: 74px;
    padding-top: 11px;
    .button{
      background: #F5F5F5;
      border-radius: 8px;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.2px;
      height: 44px;
      margin-bottom: 0;
      width: 160px;
    }
  }
`;

export default ProfileTemplateStyle;
