import React, { useState } from 'react';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import SavedPaymentsTableStyle from './SavedPaymentsTableStyle';
import nike from '../../../assets/images/nike.svg';
import elipses from '../../../assets/images/elipses.svg';
import user from '../../../assets/images/user-male.svg';
import Pagination from '../Pagination/Pagination';
import UpdateIssuerModal from '../UpdateIssuerModal/UpdateIssuerModal';
import dayjs from 'dayjs';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {exportData} from '../../../redux/actions/ExportAction';
import { useNavigate } from "react-router-dom";
import InitiateSavedPaymentModal from '../InitiateSavedPaymentModal/InitiateSavedPaymentModal';


type UserTableProps = {
  data: any,
  pagination: number,
  setPagination: Function,
  changePage: Function,
  page: number,
  reloadPage: Function
}

const SavedPaymentsTable = ({data, pagination, setPagination, changePage, page, reloadPage}: UserTableProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state:any = useSelector( (state:RootStore) => state);
  const navigate = useNavigate();
  const [toggleInitiatePaymentModal, setToggleInitiatePaymentModal] = useState(false);
  const [tableData, setTableData] = useState({});


  const handleAllCheck = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      await dispatch(exportData(state.dashboardReducer.results.data.content))
      console.log(state.exportReducer);
    }
    else{
      await dispatch(exportData([]));
      console.log(state.exportReducer);
    }
  };

  const handleCheckItem = (item: Record<any, any>) => {
    if(state.exportReducer.results.data.includes(item)){
      const newArr = state.exportReducer.results.data.filter( (record: Record<any,any>) => record.id !== item.id)
      console.log(newArr);
      return dispatch(exportData(newArr));
    }
    const update = [...state.exportReducer.results.data, item]
    console.log(update);
    return dispatch(exportData(update));
  };

  const showInitiatePaymentModal = (value: Object) => {
    setTableData(value)
    setToggleInitiatePaymentModal(true);
  }

  const hideInitiatePaymentModal = () => {
    setToggleInitiatePaymentModal(false);
  }

  const renderInitiatePaymentModal = () => {
    if(toggleInitiatePaymentModal){
      return(
        <InitiateSavedPaymentModal handleClose={hideInitiatePaymentModal} reloadPage={() => navigate('/payments/ongoing-payments')} data={tableData} />
      )
    }
  }

  return (
    <>
      {renderInitiatePaymentModal()}
      <SavedPaymentsTableStyle>
        <div className={`container-full`}>
          <table>
            <thead>
              <tr className={`table-header`}>
                <th>
                  <input type="checkbox" onChange={handleAllCheck}/>
                  <Image image={nike} />
                </th>
                <th>
                  <Text value="Date Added" className={`table-title`} />
                </th>
                <th>
                  <Text value="Name" className={`table-title`} />
                </th>
                <th>
                  <Text value="Description" className={`table-title`} />
                </th>
                <th>
                  <Text value="Reference" className={`table-title`} />
                </th>
                <th>
                  <Text value="Date Range" className={`table-title`} />
                </th>
              </tr>
            </thead>
            <tbody className={`table-body`}>
            {
                (data.data.content !== undefined && data.data.content.length > 0) ?
                data.data.content.map((payment: Record<any, any>, key: string) => {
                  return (
                    <tr className={`table-row`} key={key}>
                      <td>
                        <input
                          type="checkbox"
                          checked={state.exportReducer.results.data.includes(
                            payment
                          )}
                          onChange={() => handleCheckItem(payment)}
                        />
                        <Image image={nike} />
                      </td >
                      <td> 
                        <Text value={dayjs(payment.createdDate).format('MM/DD/YYYY')} className='label' />
                        <Text value={dayjs(payment.createdDate).format('h:mm A')} className='date' />
                      </td>
                      <td onClick={() => showInitiatePaymentModal(payment)}> 
                        <Text value={payment.name} className='label --name'/>
                      </td>
                      <td> 
                        <Text value={payment.description} className='label' />
                      </td>
                      <td> 
                        <Text value={payment.paymentReference} className='label' />
                      </td>
                      <td> 
                        <Text value={`${dayjs(payment.startDate).format('MM/DD/YYYY')} - ${dayjs(payment.endDate).format('MM/DD/YYYY')}`} className='label' />
                      </td>
                      
                    </tr>
                  )
                }) : null
              }          
            </tbody>
          </table>
        </div>
        <div className={`container-full pagination`}>
          <Pagination pagination={pagination} changePage={changePage} setPagination={setPagination} page={page}/> 
        </div>
      </SavedPaymentsTableStyle>
    </>
  );
};

export default SavedPaymentsTable;
