import React, { useState, useEffect } from 'react';
import CreateFeeCategoryModalStyle from './CreateFeeCategoryModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import Input from '../../atoms/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { SubmitActionsPOST } from '../../../redux/actions/SubmitAction';
import { alertActions } from '../../../redux/actions/AlertActions';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';
import closeIcon from '../../../assets/images/close-icon-round.svg';
import dropdown from '../../../assets/images/dropdown.svg';
import caret from '../../../assets/images/caret-down-select.svg';
import { setInfo } from '../../../redux/actions/FeeStepAction';

type CreateFeeCategoryModalProps = {
  handleClose: Function,
  showSetFeesModal: Function,
}

const CreateFeeCategoryModal = ({ handleClose, showSetFeesModal }: CreateFeeCategoryModalProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  const [fields, setFields] = useState({
    name: '',
    description: '',
  })

  useEffect(() => {
    if (Object.keys(state.feeStepReducer.results.info).length > 0) {
      let fieldValue = {
        name: state.feeStepReducer.results.info.name,
        description: state.feeStepReducer.results.info.description
      }
      setFields(fieldValue);
    }
  }, [])

  const inputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues[name] = value;
    await setFields(fieldsValues);
  };

  const onFormSubmit = () => {
    return (e: React.FormEvent) => {
      e.preventDefault();
      dispatch(setInfo(fields));
      showSetFeesModal();
    }
  }

  const clear = () => {
    dispatch(setInfo({}));
    setFields({
      name: '',
      description: '',
    })
  }

  return (
    <CreateFeeCategoryModalStyle className={`container-full`}>
      <div className={`container-full center modal-container`}>
        <div className={`modal-container__box`}>
          <div className={`container-full modal-container__box__header`}>
            <HeaderText value='Create New Fee Category' />
            <Image image={close} onClick={() => handleClose()} />
          </div>
          <form onSubmit={onFormSubmit()}>
            <div className={`container-full modal-container__box__content`}>
              <div className={`div-sm-12 modal-container__box__content__item`}>
                <p> Fee Category Name <span>*</span></p>
                <Input type='text' className="input" placeholder="Enter Fee Category Name" required name="name" value={fields.name} onChange={(e) => inputChange(e.target.name, e.target.value)} />
              </div>
              <div className={`div-sm-12 modal-container__box__content__item`}>
                <p> Description <span>*</span></p>
                <Input type='text' className="input" placeholder="Enter fee description" required name="description" value={fields.description} onChange={(e) => inputChange(e.target.name, e.target.value)} />
              </div>
            </div>
            <div className={`container-full center modal-container__box__action`}>
              <Button type="button" value="Clear" className={`button --cancel`} onClick={() => clear()} />
              <Button type="submit" value="Continue" className={`button --change `} isLoading={state.submitReducer.fetching} />
            </div>
          </form>
        </div>
      </div>
    </CreateFeeCategoryModalStyle>
  );
}

export default CreateFeeCategoryModal;