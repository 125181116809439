import styled from "styled-components";

const PaginationStyle = styled.div`
    width:100%;
    .pagination__left{
        display: flex;
    }
    .pagination__left__desc{
        p{
            color: rgba(37, 39, 51, 0.5);
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            span{
                color: rgba(37, 39, 51, 0.3);
                font-family: 'Mulish';
                margin-left: 3px;
            }
        }
    }
    .pagination__left__count{
        display: flex;
        margin-left: 25px;
        img{
            margin-right: 1px;
        }
        .number{
            color: #252733;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            margin-right: 1px;
            margin-top: 1px;
        }
        .desc{
            color: rgba(37, 39, 51, 0.3);
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            margin-right: 1px;
            margin-top: 2px;
        }
    }
    .pagination__right{
        display: flex;
        img{
            margin-right: 15.92px;
            &:nth-of-type(2){
                margin-right: 0;
            }
        }
        .--disabled-img{
            opacity: 0.2;
            pointer-events: none;
        }
        p{
            color: rgba(37, 39, 51, 0.25);
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            margin: 0 13px;
            span{
                color: #252733;
                font-family: 'Mulish'; 
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                margin: 0 2px;
            }
        }
    }
`
export default PaginationStyle;