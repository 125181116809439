import React, { useState, useEffect } from 'react';
import DashboardTemplateStyle from './DashboardTemplateStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import Input from '../../atoms/Input/Input';
import wrapper from '../../../assets/images/wrapper.svg';
import caret from '../../../assets/images/caret-daily.svg';
import calendar from '../../../assets/images/calendar-Icon.svg';
import dayjs from 'dayjs';
import { addDays, format } from 'date-fns';
import { DateRange, DayPicker } from 'react-day-picker';
import graph from '../../../assets/images/Graph.svg';
import { useDispatch,useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { DashboardActions } from '../../../redux/actions/DashboardAction';
import PerformingMerchants from '../../organisms/PerformingMerchants/PerformingMerchants';
import RecentPayments from '../../organisms/RecentPayments/RecentPayments';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import commaNumber from 'comma-number';
import { alertActions } from '../../../redux/actions/AlertActions';
import Cookies from 'js-cookie';
import { appInstance as axios } from '../../../axios';
import DashboardChart from '../../organisms/DashboardChart/DashboardChart';
import formatNumber from '../../../helpers/numberFormatter';

const pastMonth = new Date();

pastMonth.setDate(pastMonth.getDate() - 30);

const DashboardTemplate = () => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  const [toggleCalendar, setToggleCalendar] = useState(false);
  const defaultSelected: DateRange = {
    from: pastMonth,
    to: addDays(pastMonth, 30)
  };
  const [range, setRange] = useState<DateRange | any>(defaultSelected);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [groupBy, setGroupBy] = useState("0");
  const [breakdownData, setBreakdownData] = useState([]);
  const [salaryValue, setSalaryValue]:any = useState([]);
  const [statBodyValue, setStatBodyValue]:any = useState([]);
  const [transValue, setTransValue]:any = useState([]);
  const [salaryVolume, setSalaryVolume]:any = useState([]);
  const [statBodyVolume, setStatBodyVolume]:any = useState([]);
  const [transVolume, setTransVolume]:any = useState([]);

  useEffect(() => {
    generatePageContent();
    fetchStatisticsBreakdown();
  }, [range, groupBy])

  useEffect(() => {
    getSalaryValue();
    getStatBodyValue();
    getTransValue();
    getSalaryVolume();
    getStatBodyVolume();
    getTransVolume();
  }, [breakdownData])

  const generatePageContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/statistics/summary?dateFrom=${dayjs(range.from).format("YYYY-MM-DD HH:mm:ss")}&dateTo=${dayjs(range.to).format("YYYY-MM-DD HH:mm:ss")}&groupBy=${groupBy}`));
    setFetchLoading(false);
  }

  const fetchStatisticsBreakdown = async () => {
    try {
      setFetchLoading(true);
      const res = await axios.get(`/statistics/breakdown?dateFrom=${dayjs(range.from).format("YYYY-MM-DD HH:mm:ss")}&dateTo=${dayjs(range.to).format("YYYY-MM-DD HH:mm:ss")}&groupBy=${groupBy}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setBreakdownData(res.data.data.breakdown);
      setFetchLoading(false);
    } catch (error: any) {
      setFetchLoading(false);
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const getSalaryValue = () => {
    let salaryValue:any = [];
    breakdownData.map((data: Record<any, any>) => {
      salaryValue.push(data["salaryValue"]);
    })
    setSalaryValue(salaryValue);
  }

  const getStatBodyValue = () => {
    let statBodyValue:any = [];
    breakdownData.map((data: Record<any, any>) => {
      statBodyValue.push(data["statBodyValue"]);
    })
    setStatBodyValue(statBodyValue);
  }

  const getTransValue = () => {
    let transValue:any = [];
    breakdownData.map((data: Record<any, any>) => {
      transValue.push(data["transValue"]);
    })
    setTransValue(transValue);
  }

  const getSalaryVolume = () => {
    let salaryVolume:any = [];
    breakdownData.map((data: Record<any, any>) => {
      salaryVolume.push(data["salaryVolume"]);
    })
    setSalaryVolume(salaryVolume);
  }

  const getStatBodyVolume = () => {
    let statBodyVolume:any = [];
    breakdownData.map((data: Record<any, any>) => {
      statBodyVolume.push(data["statBodyVolume"]);
    })
    setStatBodyVolume(statBodyVolume);
  }

  const getTransVolume = () => {
    let transVolume:any = [];
    breakdownData.map((data: Record<any, any>) => {
      transVolume.push(data["transVolume"]);
    })
    setTransVolume(transVolume);
  }
 
  let footer = <p>Please pick the first day.</p>;
  if (range !== undefined && range.from) {
    if (!range.to) {
      footer = <p>{format(range.from, 'PPP')}</p>;
    } else if (range.to) {
      footer = (
        <p>
          {format(range.from, 'PPP')}–{format(range.to, 'PPP')}
        </p>
      );
    }
  }

  const showCalendar = () => {
    if (toggleCalendar) {
      setToggleCalendar(false);
      return;
    }
    setToggleCalendar(true);
  }

  const renderFetchLoading = () => {
    if (fetchLoading) {
      return (
        <FetchLoading />
      )
    }
  }

  return (
    <>
      {renderFetchLoading()}
      <DashboardTemplateStyle>
        <div className={`container-full dashboard`}>
          <div className={`container-full dashboard__desc`}>
            <HeaderText value="Dashboard" />
            <div className={`dashboard__desc__filter`}>
              <div className={`dashboard__desc__filter__select`}>
                <Image image={wrapper} alt="wrapper" className='wrapper' />
                <select onChange={(event) => setGroupBy(event.target.value)}>
                  <option value="0"> Daily </option>
                  <option value="1"> Monthly </option>
                </select>
                <Image image={caret} alt="caret" className='caret' />
              </div>
              <div className={`dashboard__desc__filter__range`}>
                <div className={`dashboard__desc__filter__range__item`} onClick={() => showCalendar()}>
                  <Text value='Range:' />
                  <Text value={(range) ? `${dayjs(range.from).format("YYYY-MM-DD")} to ${dayjs(range.to).format("YYYY-MM-DD")}` : ''} className='--date' />
                  <Image image={calendar} alt="calendar" />
                </div>
                {
                  toggleCalendar ?
                    <DayPicker
                      mode="range"
                      defaultMonth={pastMonth}
                      selected={range}
                      footer={footer}
                      modifiersStyles={{
                        selected: {
                          background: 'blue',
                          color: 'white',
                          fontWeight: 'bold',
                        },
                      }}
                      onSelect={setRange}
                      style={{ position: "absolute", right: "0", marginTop: "40px", background: "white", boxSizing: "border-box", padding: "35px", boxShadow: "0px 15px 44px rgba(10, 18, 73, 0.15)",zIndex: 10000 }}
                      styles={{
                        day: { boxSizing: "border-box", padding: '10px', borderRadius: "8px", fontFamily: "Mulish", },
                        caption: { fontFamily: "Mulish" },
                      }}
                    /> : null
                }
              </div>
            </div>
          </div>
          <div className={`container-full dashboard__body`} onClick={() => setToggleCalendar(false)}>
            <div className={`dashboard__body__left`}>
              <div className={`container-full dashboard__body__left__summary`}>
                <div className={`dashboard__body__left__summary__item --value`}>
                  <Text value='Transaction Value' />
                  <HeaderText value={`
                    ${state.dashboardReducer.results && state.dashboardReducer.results.data.summary && Object.keys(state.dashboardReducer.results.data.summary).length > 0 ? `₦${commaNumber(formatNumber(state.dashboardReducer.results.data.summary.transValue))}` : 'NIL'}
                  `}  />
                </div>
                <div className={`dashboard__body__left__summary__item --volume`}>
                  <Text value='Transaction Volume' />
                  <HeaderText value={`${state.dashboardReducer.results && state.dashboardReducer.results.data.summary && Object.keys(state.dashboardReducer.results.data.summary).length > 0 ? commaNumber(formatNumber(state.dashboardReducer.results.data.summary.transVolume, 0)) : 'NIL'}`} />
                </div>
                {
                  state.authReducer.results.user_level === 2 ?
                    <div className={`dashboard__body__left__summary__item --count`}>
                      <Text value='Merchant Count' />
                      <HeaderText value={
                        state.dashboardReducer.results && state.dashboardReducer.results.data.topMerchants ? commaNumber(formatNumber(state.dashboardReducer.results.data.topMerchants.merchantCount, 0)) : 'NIL'
                      } />
                    </div> :
                    <div className={`dashboard__body__left__summary__item --count`}>
                      <Text value='Saved Payments Count' />
                      <HeaderText value={
                        state.dashboardReducer.results && state.dashboardReducer.results.data.paymentDetails ? commaNumber(formatNumber(state.dashboardReducer.results.data.paymentDetails.paymentCount, 0)) : 'NIL'
                      } />
                    </div>
                }
              </div>
              <div className={`container-full dashboard__body__left__chart`}>
                <div className={`div-md-8 dashboard__body__left__chart__diagram`}>
                  {
                    breakdownData.length > 0 ? <DashboardChart data={breakdownData} /> : null
                  }
                </div>
                <div className={`div-md-4 dashboard__body__left__chart__labels`}>
                  <div className='div-sm-12 dashboard__body__left__chart__labels__card'>
                    <Text value='Statutory Bodies' className='title' />
                    <HeaderText value={statBodyValue.length > 0 ? `₦${commaNumber(formatNumber(statBodyValue.reduce((partialSum:any, a:any) => partialSum + a, 0)))}` : `₦0`} />
                    <Text value={statBodyVolume.length > 0 ? `${commaNumber(formatNumber(statBodyVolume.reduce((partialSum:any, a:any) => partialSum + a, 0), 0))} transactions` : `0 transactions`} className='value' />
                    <div className={`dashboard__body__left__chart__labels__card__dot --statutories-bodies`}></div>
                  </div>
                  <div className='div-sm-12 dashboard__body__left__chart__labels__card'>
                    <Text value='Salary' className='title' />
                    <HeaderText value={salaryValue.length > 0 ? `₦${commaNumber(formatNumber(salaryValue.reduce((partialSum:any, a:any) => partialSum + a, 0)))}` : `₦0`} />
                    <Text value={salaryVolume.length > 0 ? `${commaNumber(formatNumber(salaryVolume.reduce((partialSum:any, a:any) => partialSum + a, 0), 0))} transactions` : `0 transactions`} className='value' />
                    <div className={`dashboard__body__left__chart__labels__card__dot --beneficiary-bank`}></div>
                  </div>
                  <div className='div-sm-12 dashboard__body__left__chart__labels__card --total-card'>
                    <Text value='Total' className='title' />
                    <HeaderText value={transValue.length > 0 ? `₦${commaNumber(formatNumber(transValue.reduce((partialSum:any, a:any) => partialSum + a, 0)))}` : `₦0`} />
                    <Text value={transVolume.length > 0 ? `${commaNumber(formatNumber(transVolume.reduce((partialSum:any, a:any) => partialSum + a, 0), 0))} transactions` : `0 transactions`} className='value' />
                    <div className={`dashboard__body__left__chart__labels__card__dot --total`}></div>
                  </div>
                </div>
              </div>
              <div className={`container-full dashboard__body__left__break-down`}>
                <div className={`div-md-9 dashboard__body__left__break-down__sb`}>
                  <Text value='Statutory Bodies' className='title' />
                  <div className={`container-full dashboard__body__left__break-down__sb__row`}>
                    <div className={`div-md-6 dashboard__body__left__break-down__sb__row__item`}>
                      <Text value='NHIS' className='title' />
                      <HeaderText value={`
                        ${state.dashboardReducer.results && state.dashboardReducer.results.data.summary && Object.keys(state.dashboardReducer.results.data.summary).length > 0 ? `₦${commaNumber(formatNumber(state.dashboardReducer.results.data.summary.nhisValue))}` : '0'}
                      `} />
                      <Text value={`${state.dashboardReducer.results && state.dashboardReducer.results.data.summary && Object.keys(state.dashboardReducer.results.data.summary).length > 0 ? commaNumber(formatNumber(state.dashboardReducer.results.data.summary.nhisVolume, 0)) : '0'} transactions`} className='value' />
                    </div>
                    <div className={`div-md-6 dashboard__body__left__break-down__sb__row__item`}>
                      <Text value='NHF' className='title' />
                      <HeaderText value={`
                        ${state.dashboardReducer.results && state.dashboardReducer.results.data.summary && Object.keys(state.dashboardReducer.results.data.summary).length > 0 ? `₦${commaNumber(formatNumber(state.dashboardReducer.results.data.summary.nhfValue))}` : '0'}
                      `} />
                      <Text value={`${state.dashboardReducer.results && state.dashboardReducer.results.data.summary && Object.keys(state.dashboardReducer.results.data.summary).length > 0 ? commaNumber(formatNumber(state.dashboardReducer.results.data.summary.nhfVolume, 0)) : '0'} transactions`} className='value' />
                    </div>
                  </div>
                  <div className={`container-full dashboard__body__left__break-down__sb__row`}>
                    <div className={`div-md-6 dashboard__body__left__break-down__sb__row__item`}>
                      <Text value='Pension' className='title' />
                      <HeaderText value={`
                        ${state.dashboardReducer.results && state.dashboardReducer.results.data.summary && Object.keys(state.dashboardReducer.results.data.summary).length > 0 ? `₦${commaNumber(formatNumber(state.dashboardReducer.results.data.summary.pensionValue))}` : '0'}
                      `} />
                      <Text value={`${state.dashboardReducer.results && state.dashboardReducer.results.data.summary && Object.keys(state.dashboardReducer.results.data.summary).length > 0 ? commaNumber(formatNumber(state.dashboardReducer.results.data.summary.pensionVolume, 0)) : '0'} transactions`} className='value' />
                    </div>
                    <div className={`div-md-6 dashboard__body__left__break-down__sb__row__item`}>
                      <Text value='PAYE' className='title' />
                      <HeaderText value={`
                       ${state.dashboardReducer.results && state.dashboardReducer.results.data.summary && Object.keys(state.dashboardReducer.results.data.summary).length > 0 ? `₦${commaNumber(formatNumber(state.dashboardReducer.results.data.summary.payeValue))}` : '0'}
                      `} />
                      <Text value={`${state.dashboardReducer.results && state.dashboardReducer.results.data.summary && Object.keys(state.dashboardReducer.results.data.summary).length > 0 ? commaNumber(formatNumber(state.dashboardReducer.results.data.summary.payeVolume, 0)) : '0'} transactions`} className='value' />
                    </div>
                  </div>
                </div>
                <div className={`div-md-3 dashboard__body__left__break-down__bb`}>
                  <Text value='Beneficiary Banks' className='title' />
                  <div className={`div-sm-12 dashboard__body__left__break-down__bb__card`}>
                    <Text value='MFB' className='title' />
                    <HeaderText value={`
                      ${state.dashboardReducer.results && state.dashboardReducer.results.data.summary && Object.keys(state.dashboardReducer.results.data.summary).length > 0 ? `₦${commaNumber(formatNumber(state.dashboardReducer.results.data.summary.salaryMfbValue))}` : '0'}
                    `} />
                    <Text value={`${state.dashboardReducer.results && state.dashboardReducer.results.data.summary && Object.keys(state.dashboardReducer.results.data.summary).length > 0 ? commaNumber(formatNumber(state.dashboardReducer.results.data.summary.salaryMfbVolume, 0)) : '0'} transactions`} className='value' />
                  </div>
                  <div className={`div-sm-12 dashboard__body__left__break-down__bb__card`}>
                    <Text value='Commercial' className='title' />
                    <HeaderText value={`
                    ${state.dashboardReducer.results && state.dashboardReducer.results.data.summary && Object.keys(state.dashboardReducer.results.data.summary).length > 0 ? `₦${commaNumber(formatNumber(state.dashboardReducer.results.data.summary.salaryCommValue))}` : '0'}
                    `} />
                    <Text value={`${state.dashboardReducer.results && state.dashboardReducer.results.data.summary && Object.keys(state.dashboardReducer.results.data.summary).length > 0 ? commaNumber(formatNumber(state.dashboardReducer.results.data.summary.salaryCommVolume, 0)) : '0'} transactions`} className='value' />
                  </div>
                </div>
              </div>
            </div>
            <div className={`dashboard__body__right`}>
              {
                state.authReducer.results.user_level === 2 ?
                  <PerformingMerchants /> : <RecentPayments />
              }
            </div>
          </div>
        </div>
      </DashboardTemplateStyle>
    </>
  );

};

export default DashboardTemplate;
