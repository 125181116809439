import React, { useState, useEffect } from 'react';
import ApprovePaymentModalStyle from './ApprovePaymentModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import dayjs from 'dayjs';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import cancelRed from '../../../assets/images/cancel-red.svg';
import { SubmitActionsPOST } from '../../../redux/actions/SubmitAction';
import { useNavigate } from 'react-router-dom';
import nikeGreen from '../../../assets/images/nike-green.svg';

type ApprovePaymentModalProps = {
  handleClose: Function,
}

const ApprovePaymentModal = ({ handleClose }: ApprovePaymentModalProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  const [inputValue, setInputValue] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if(state.submitReducer.results && submitted && state.dashboardReducer.results.data.finalAuthorizer){
      navigate(`/payments/pending-approval/summary/authorize?paymentReference=${window.location.href.split("=")[1]}`)
    }
    else if(state.submitReducer.results && submitted && !state.dashboardReducer.results.data.finalAuthorizer){
      navigate(`/payments/pending-approval`)
    }
   }, [state.submitReducer])

  const onInputChange = (value: string) => {
    setInputValue(value)
  }

  const approvePayment = async () => {
    const payload = {
      "comment": inputValue,
      "isApproved": 1
    }
    setSubmitted(true);
    dispatch(SubmitActionsPOST(`/payment/${window.location.href.split("=")[1]}/approve`, payload));
  }

  return (
    <ApprovePaymentModalStyle className={`container-full`}>
      <div className={`container-full center modal-container`}>
        <div className={`modal-container__box`}>
          <div className={`container-full modal-container__box__header`}>
            <HeaderText value='Approve Payment' />
            <Image image={close} onClick={() => handleClose()} />
          </div>
          <div className={`container-full modal-container__box__content`}>
            <HeaderText value='What is the reason for this approval?' />
            <Input type="text" className="input"  placeholder='Enter your reason for approval.' 
              value={inputValue}
              onChange={(event: any) => {onInputChange(event.target.value)}}
            />
          </div>
          <div className={`container-full modal-container__box__action`}>
            <Button value="cancel" className="button --cancel" />
            <Button className='button --approve' value={
               <>
                <p>Approve Payment</p>
                <Image image={nikeGreen} /> 
              </>
            } isLoading={state.submitReducer.fetching} onClick={() => approvePayment()}/> 
          </div>
        </div>
      </div>
    </ApprovePaymentModalStyle>
  );
}

export default ApprovePaymentModal;