import React, { useState, useEffect } from 'react';
import RetrySuccessModalStyle from './RetrySuccessModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import Input from '../../atoms/Input/Input';
import caret from '../../../assets/images/caret-down-new.svg';
import success from '../../../assets/images/success.svg';
import { useNavigate } from 'react-router-dom';
import {  useSelector } from 'react-redux';
import {  RootStore } from '../../../config/ConfigStore';

type RetrySuccessModalProps = {
    handleClose: Function;
    reloadPage: Function;
    data: any;
}

const RetrySuccessModal = ({ handleClose, reloadPage, data }: RetrySuccessModalProps) => {
    const state: any = useSelector((state: RootStore) => state);
    const navigate = useNavigate();
    const closeModal = () => {
        handleClose(); 
        reloadPage()
    }

    const goTo = () => {
        navigate(`/reports/partner-report?paymentRef=${data.paymentRef}`)
        handleClose();
    }
    return (
        <RetrySuccessModalStyle className={`container-full`}>
            <div className={`container-full center modal-container`}>
                <div className={`modal-container__box`}>
                    <div className={`container-full modal-container__box__header`}>
                        <Image image={close} onClick={() => closeModal()} />
                    </div>
                    <div className={`container-full modal-container__box__content`}>
                        <div className={`container-full center modal-container__box__content__img`}>
                            <Image image={success} />
                        </div>
                        <div className={`container-full center modal-container__box__content__message`}>
                            <HeaderText value="Success" />
                            <p> Payment report retrial successful </p>
                        </div>
                    </div>
                    <div className={`container-full center modal-container__box__action`}>
                        <Button value="Go to partner report" className={`button`} onClick={() => goTo()} />
                    </div>
                </div>
            </div>
        </RetrySuccessModalStyle>
    );
}

export default RetrySuccessModal;