import styled from "styled-components";
import Colors from '../../../helpers/Colors';

const RecentPaymentsStyle = styled.div`
  background: white;
  box-sizing: border-box;
  padding-left: 24px;
  width: 100%;

  .recent-payments{
   
  }
  .recent-payments__menu__item{
    margin-bottom: 0;
    .button{
      background: linear-gradient(0deg, #EBEEFF, #EBEEFF), #FFFFFF;
      border-radius: 8px;
      box-sizing: border-box;
      color: #3751FF;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      height: 65px;
      line-height: 16px;
      margin-bottom: 0;
      width: 100%;
    }
  }
  .recent-payments__table{
    margin-top: 24px;
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }
  }
  .recent-payments__table__list{
    box-sizing: border-box;
    margin-top: 34px;
    padding: 0 10px 0 15px;
  }
  .recent-payments__table__list__row{
    box-sizing: border-box;
    margin-bottom: 1.5px;
    padding-bottom: 15px;
    &:last-child{
      &:after{
        display: none;
      }
    }
    &:after{
      background: #F4F5F6;
      content: '';
      display: block;
      height: 1px;
      margin-left: 5px;
      margin-top: 16px;
      width: 90%;
    }
  }
  .recent-payments__table__list__row__item{
    display: flex;
    margin-bottom: 0;
    .value{
      color: #252733;
      font-family: 'Mulish';
      font-feature-settings: 'salt' on;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      
    }
    .date{
      color: rgba(37, 39, 51, 0.5);
      font-family: 'Mulish';
      font-feature-settings: 'salt' on;
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 14px;
      span{
        font-family: 'Mulish';
        opacity: 0.5;
      }
    }
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-feature-settings: 'salt' on;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      
    }
  }
  .recent-payments__table__list__row__item__dot{
    background: #1B9830;
    border-radius: 50%;
    height: 8px;
    margin-right: 9px;
    margin-top: 5px;
    width: 8px;
  }
`;

export default RecentPaymentsStyle;