import { ExportDispatchTypes, EXPORT, EXPORT_CLEAR, EXPORT_ALL, EXPORT_CLEAR_ALL } from '../actions/ExportActionTypes';

export interface DefaultState {
  results: any;
  allResults: any;
}

const defaultState: DefaultState = {
  results: {
    data: []
  },
  allResults: {
    data: []
  }
};

const exportReducer = (
  state: DefaultState = defaultState,
  action: ExportDispatchTypes
) => {
  switch (action.type) {
    case EXPORT:
      return {
        ...state,
        results: {
          ...state.results,
          data: action.data,
        },
      };
    case EXPORT_CLEAR:
      return {
        ...state,
        results: {
          data: []
        }
      }
    case EXPORT_ALL:
      return {
        ...state,
        allResults: {
          ...state.results,
          data: action.response,
        },
      };
    case EXPORT_CLEAR_ALL:
      return {
        ...state,
        allResults: {
          data: []
        }
      }
    default:
      return state;
  }
};

export default exportReducer;
