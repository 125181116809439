import styled from "styled-components";
import Colors from '../../../helpers/Colors';

const SetFeesFormStyle = styled.div`
  width: 100%;

  form{
    width: 100%;
  }
  .form__content{
    border-bottom: 1px solid #F0F0F0;
    box-sizing: border-box;
    margin-top: 38px;
    padding: 0 15px;
    padding-bottom: 27px;
  }
  .form__content__item{
    margin-bottom: 13px;
    &:last-child{
        margin-bottom: 0;
    }
    .div-md-6{
      margin-bottom: 0;
    }
    p{
        color: #252733;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 9px;
        span{
            color: #F12B2C;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            margin-left: 5px;
        }
    }
    .input{
      background: rgba(55, 81, 255, 0.025);
      border: none;
      border-radius: 6px;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      height: 40px;
      padding-left: 15px;
      position: relative;
      &::placeholder{
        color: rgba(37, 39, 51, 0.3);
      }
    }
    select{
      appearance: none;
      background: transparent;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      margin-top: 10px;
      width: 100%;
    }
    select:required:invalid {
      color: rgba(37, 39, 51, 0.3);
    }
    img{
      margin-right: 8px;
      margin-top: 11px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .form__content__item__radio{
    background: #FAFBFF;
    border-radius: 6px;
    box-sizing: border-box;
    height: 40px;
    padding: 10px;
  }
  .form__content__item__radio__item{
    cursor: pointer;
    margin-bottom: 0;
    position: relative;
    .radio{
      cursor: pointer;
      opacity: 0;
      position: absolute;
      width: 100%;
      &:checked + .form__content__item__radio__item__box{
        p{
          color: #3751FF;
          font-weight: 600;
        }
        .form__content__item__radio__item__box__circle{
          border: 1px solid #3751FF;
        }
      }
    }
  }
  .form__content__item__radio__item__box{
    display: flex;
    p{
      color: #B6B6BB;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .form__content__item__radio__item__box__circle{
    background: #FFFFFF;
    border: 1px solid #B5BABD;
    border-radius: 50%;
    display: block;
    height: 7px;
    margin-right: 6px;
    margin-top: 4.5px;
    width: 7px;
  }
  .form__fee-options{
    box-sizing: border-box;
    margin-top: 27px;
    padding: 0 15px;
  }
  .form__fee-options__desc{
    margin-bottom: 12px;
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .form__fee-options__warning{
    p{
      color: rgba(37, 39, 51, 0.5);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .form__fee-options__form{
    margin-top: 9px;
  }
  .form__fee-options__form__item{
    margin-bottom: 21px;
    &:last-child{
        margin-bottom: 0;
    }
    .div-md-6{
      margin-bottom: 0;
    }
    p{
        color: #252733;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 9px;
        span{
            color: #F12B2C;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            margin-left: 5px;
        }
    }
    .input-box{
      position: relative;
      p{
        color: rgba(37, 39, 51, 0.3);
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin-right: 15.27px;
        margin-top: 10px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .input{
      background: rgba(55, 81, 255, 0.025);
      border: none;
      border-radius: 6px;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      height: 40px;
      padding-left: 15px;
      position: relative;
      &::placeholder{
        color: rgba(37, 39, 51, 0.3);
      }
    }
    span{
      color: red;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
  }
  .form__fee-options__bands{
    margin-top: 8px;
  }
  .form__fee-options__bands__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .form__fee-options__bands__group{
    background: #FCFDFE;
    border: 1px solid #F0F1F7;
    border-radius: 5px;
    box-sizing: border-box;
    margin-top: 8px;
    padding: 10px;
    padding-bottom: 18px;
  }
  .form__fee-options__bands__group__item{
    background: #F9FAFB;
    border: 1px solid #F0F1F7;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 36px;
    padding: 10px;
  }
  .form__fee-options__bands__group__item__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.01em;
    }
  }
  .form__fee-options__bands__group__item__form{
    margin-top: 11px;
  }
  .form__fee-options__bands__group__item__form__item{
    position: relative;
    margin-bottom: 21px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .form__fee-options__bands__group__item__form__item__input-group{
    .hyphen{
      all: unset;
      color: #A8A8A8;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      left: 0;
      line-height: 16px;
      margin-left: 262.5px;
      margin-top: 42px;
      position: absolute;
    }
  }
  .form__fee-options__bands__group__item__form__item__input-group__item{
    margin-bottom: 0;
    position: relative;
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 11px;
      span{
          color: #F12B2C;
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          margin-left: 5px;
      }
    }
    select{
      appearance: none;
      background: transparent;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      margin-top: 10px;
      width: 100%;
    }
    select:required:invalid {
        color: rgba(37, 39, 51, 0.3);
    }
    img{
        margin-right: 8px;
        margin-top: 11px;
        position: absolute;
        right: 0;
        top: 0;
    }
    .--fixed-fee{
      margin-top: 20px;
    }
    span{
      color: red;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      margin-top: 4px;
    }
  }
  .form__fee-options__bands__group__item__form__item__input-group__item__box{
    margin-bottom: 0;
    position: relative;
    p{
      color: rgba(37, 39, 51, 0.3);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      margin-left: 10px;
      margin-top: 10px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .input{
    background: rgba(55, 81, 255, 0.025);
    border: none;
    border-radius: 6px;
    color: #252733;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    height: 40px;
    padding-left: 22px;
    position: relative;
      &::placeholder{
        color: rgba(37, 39, 51, 0.3);
      }
    }
  }
  .--percentage{
    p{
      margin-left: 80%;
    }
    .input{
      padding-left: 10px;
    }
  }
  .form__fee-options__bands__action{
    box-sizing: border-box;
    justify-content: flex-end;
    margin-top: 8px;
    padding: 0 12px;
  }
  .form__fee-options__bands__action__item{
    cursor: pointer;
    display: flex;
    img{
      margin-right: 10px;
    }
    h2{
      color: #3751FF;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
    }
  }
  .form__fee-options__bands__group__item__desc__button{
    background: #D1D3DB;
    border-radius: 3px;
    cursor: pointer;
    height: 16.29px;
    opacity: 0.3;
    width: 16.29px;
  }
  .form__fee-options__bands__group__item__desc__button__hyphen{
    background: #200E32;
    border-radius: 8px;
    height: 1.36px;
    margin-left: 2.71px;
    margin-top: 7.46px;
    width: 10.86px;
  }
  .form__action{
    justify-content: flex-end;
    margin-top: 83px;
    .button{
        border-radius: 8px;
        font-family: 'Mulish';
        font-weight: 500;
        font-size: 16px;
        height: 40px;
        margin-bottom: 0;
    }
    .--cancel{
        background: #F5F5F5;
        color: #252733;
        margin-right: 20px;
        width: 102px;
    }
    .--change{
        background: linear-gradient(0deg, #ADB3DC, #ADB3DC), #3751FF;
        box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
        color: white;
        pointer-events:  none;
        width: 117px;
    }
    .--active-btn{
      background: #3751FF;
      pointer-events: unset;
    }
  }
`;

export default SetFeesFormStyle;