import React, {useState, useEffect} from 'react';
import CreateIssuerModalStyle from './CreateIssuerModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import Input from '../../atoms/Input/Input';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {SubmitActionsPOST} from '../../../redux/actions/SubmitAction';
import { alertActions } from '../../../redux/actions/AlertActions';
import {appInstance as axios} from '../../../axios';
import Cookies from 'js-cookie';
import closeIcon from '../../../assets/images/close-icon-round.svg';
import dropdown from '../../../assets/images/dropdown.svg';


type CreateIssuerModalProps = {
  handleClose: Function,
  reloadPage: Function
}

const CreateIssuerModal = ({handleClose, reloadPage}: CreateIssuerModalProps) => {
    const dispatch: TypedDispatch = useDispatch();
    const state = useSelector( (state:RootStore) => state);
    const [fields, setFields] = useState({
      name: '',
      email: '',
      phoneNumber: '',
      bankCode: '',
      bankType: ''
    })
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
     if(state.submitReducer.results && submitted){
      handleClose();
      reloadPage();
     }
    }, [state.submitReducer])

    const inputChange =  async (name: string, value: string) => {
      const fieldsValues: any = Object.assign({}, fields);
      fieldsValues[name] = value;
      await setFields(fieldsValues);
    };

    const onFormSubmit = () => {
      return(e: React.FormEvent) => {
        e.preventDefault();
        setSubmitted(true);
        let values = {
          "contactNumber": fields.phoneNumber,
          "issuerCode": fields.bankCode,
          "issuerEmail": fields.email,
          "issuerType": fields.bankType,
          "name": fields.name
        }
        dispatch(SubmitActionsPOST('/payroll/configuration/issuers', values))
      }
    }
  
    return (
        <CreateIssuerModalStyle className={`container-full`}>
            <div className={`container-full center modal-container`}>
                <div className={`modal-container__box`}>
                    <div className={`container-full modal-container__box__header`}>
                     <HeaderText value='Create Issuer' />
                     <Image image={close} onClick={() => handleClose()}/>
                    </div>
                    <form onSubmit={onFormSubmit()}>
                      <div className={`container-full modal-container__box__content`}>
                        <div className={`container-full modal-container__box__content__desc`}>
                          <HeaderText value='Provide information for the Issuer you would like to create' />
                        </div>
                        <div className={`container-full modal-container__box__content__form`}>
                          <div className={`container-full modal-container__box__content__form__item`}>
                            <Text value='Name' />
                            <Input type='text' className="input" placeholder="Eg Retail Corporate Ltd" required name="name" value={fields.name} onChange={(e) => inputChange(e.target.name, e.target.value)} />
                          </div>
                          <div className={`container-full modal-container__box__content__form__item`}>
                            <div className='div-md-6'>
                              <Text value='Email' />
                              <Input type='email' className="input"  placeholder="Eg. maggiesmitthoie@gmail.com" required name="email" value={fields.email} onChange={(e) => inputChange(e.target.name, e.target.value)}/>
                            </div>
                            <div className='div-md-6'>
                              <Text value='Phone Number' />
                              <Input type='text' className="input"  placeholder="Eg. 2348024044353" required name="phoneNumber" value={fields.phoneNumber} onChange={(e) => inputChange(e.target.name, e.target.value)}/>
                            </div>
                          </div>
                          <div className={`container-full modal-container__box__content__form__item`}>
                            <div className='div-md-6'>
                              <Text value='Bank Code' />
                              <Input type='text' className="input"  placeholder="Eg. 023" required name="bankCode" value={fields.bankCode} onChange={(e) => inputChange(e.target.name, e.target.value)}/>
                            </div>
                            <div className='div-md-6'>
                              <Text value='Bank Type' />
                              <div className='input'>
                                <select required
                                  name="bankType"
                                  onChange={(evt) => inputChange(evt.target.name, evt.target.value)} 
                                  value={fields.bankType}
                                >
                                  <option value="" hidden> Select Category </option>
                                  <option value="1"> Commercial Bank </option>
                                  <option value="2"> Microfinance Bank </option>
                                  <option value="3"> Wallet </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`container-full center modal-container__box__action`}>
                          <Button type="button" value="Cancel" className={`button --cancel`} onClick={() => handleClose()}/>
                          <Button type="submit" value="Save" className={`button --change `} isLoading={state.submitReducer.fetching}/>
                      </div>
                    </form>
                </div>  
            </div>
        </CreateIssuerModalStyle>
    );
}

export default CreateIssuerModal;