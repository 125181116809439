import styled from "styled-components";

const MerchantStepTwoStyle = styled.div`
position: relative;
.merchants__body{
    box-sizing: border-box;
    margin-top: 55px;
    padding-left: 36px;
}

.merchants__body__form{
    box-sizing: border-box;
    margin-top: 20px;
    padding-right: 36px;
}
.merchants__body__form__desc{
    margin-bottom: 0;
    .step{
        color: rgba(37, 39, 51, 0.5);
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }
    .header{
        color: #252733;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
    }
    .desc{
        color: #9FA2B4;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        margin-top: 10px;
    }
}
.merchants__body__form__form{
    box-sizing: border-box;
    margin-top: 55px;
    padding-left: 25px;
    padding-right: 20px;
}
.merchants__body__form__form__files{
    margin-bottom: 0;
    .container-full{
        flex-wrap: nowrap;
        justify-content: normal;
        overflow: scroll;
    }
}
.merchants__body__form__form__files__item{
    -ms-flex: 0 0 200px;
    -webkit-flex: 0 0 200px;
    flex: 0 0 200px;
    margin-right: 51.33px;
    max-width: 200px;
    .file-name{
        color: #252733;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 15px;
    }
    .--add-file{
        cursor: pointer;
    }
    button{
        all:unset;
        cursor:pointer;
        width:100%;
    }
}
.merchants__body__form__form__files__item__box{
    background: rgba(55, 81, 255, 0.05);
    border-radius: 10px;
    box-sizing: border-box;
    cursor: pointer;
    padding: 10px;
    position: relative;
    width: 100%;
}
.merchants__body__form__form__files__item__box__inner{
    border: 1.5px dashed rgba(55, 81, 255, 0.5);
    border-radius: 8px;
    height: 100%;
    width: 100%;
}
.merchants__body__form__form__files__item__box__inner__img{
    display: flex;
    justify-content: center;
    margin-top: 31.5px;
    position: relative;
    .add{
        margin-bottom: 5px;
    }
    .close{
        left: 0;
        margin-left: 51%;
        margin-top: -4px;
        position: absolute;
        top: 0;
    }
}
.merchants__body__form__form__files__item__box__inner__text{
    margin-bottom: 31.5px;
    width: 100%;
    .file-name{
        color: #252733;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        margin: 0;
        text-align: center;
        width: 100%;
    }
    .file-type{
        color: rgba(37, 39, 51, 0.5);
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        margin: 0;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
    }
    .upload-desc{
        color: #252733;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        span{
            color: #3751FF;
            font-family: 'Mulish';
        }
    }
}
.merchants__body__form__form__add-file{
    margin-bottom: 0;
}
.more-info {
    background: white;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(8, 9, 10, 0.1);
    margin-right: 82.5px;
    margin-top: 6px;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 0;
    width: 250px;
    z-index: 4000;
}
.more-info__item{
    margin-bottom: 20px;
    p{
        color: #252733;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 7.5px;
    }
    .input{
        background: rgba(55, 81, 255, 0.03);
        border: none;
        border-radius: 8px;
        box-sizing: border-box;
        color: rgb(37, 39, 51);
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        padding-left: 15px;
        width: 100%;
        &::placeholder{
            color: rgba(37, 39, 51, 0.3);
        }
    }
    &:last-child{
        margin-bottom: 0;
    }
}
.merchants__action{
    border-top: 2px solid #F8F9FA;
    box-sizing: border-box;
    padding: 8px 36px;
    position: absolute;
    top: 78vh;
    .button{
        border-radius: 8px;
        height: 44px;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.2px;
        margin-bottom: 41px;
    }
    .--back{
        background: #F5F5F5;
        color: #252733;
        padding-left: 44px;
        position: relative;
        text-align: left;
        width: 107px;
        img{
            left: 0;
            margin: 12px 0 0 14px;
            position: absolute;
            top: 0;
        }
    }
    .--skip{
        background: #F5F5F5;
        color: #252733;
        margin-right: 20px;
        width: 83px;
    }
    .--save{
        background: #3751FF;
        box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
        color: white;
        width: 176px;
    }
}

   
`
export default MerchantStepTwoStyle;