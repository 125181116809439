import { Dispatch } from 'redux';
import { StepDispatchTypes, STEP_1, STEP_2, STEP_3, STEP_CLEAR } from './PaymentStepActionTypes';

export const stepOne =
  (data: object) => async (dispatch: Dispatch<StepDispatchTypes>) => {
    dispatch({
      type: STEP_1,
      data,
    });
};

export const stepTwo =
  (data: object) => async (dispatch: Dispatch<StepDispatchTypes>) => {
    dispatch({
      type: STEP_2,
      data,
    });
};

export const stepThree =
  (data: object) => async (dispatch: Dispatch<StepDispatchTypes>) => {
    dispatch({
      type: STEP_3,
      data,
    });
};

export const stepClear =
  () => async (dispatch: Dispatch<StepDispatchTypes>) => {
    dispatch({
      type: STEP_CLEAR
    });
};
