import React from 'react';
import CreateSignatoryAssignModalStyle from './CreateSignatoryAssignModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import success from '../../../assets/images/success.svg';
import { useNavigate } from 'react-router-dom';


type CreateSignatoryAssignModalProps = {
  handleClose: Function,
  reloadPage: Function
}

const CreateSignatoryAssignModal = ({handleClose, reloadPage}: CreateSignatoryAssignModalProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector( (state:RootStore) => state);
  const navigate = useNavigate();

  const closeModal = () => {
    handleClose();
    reloadPage();
  }

  return (
      <CreateSignatoryAssignModalStyle className={`container-full`}>
          <div className={`container-full center modal-container`}>
              <div className={`modal-container__box`}>
                  <div className={`container-full modal-container__box__header`}>
                    <HeaderText value='Assign Signatory Group?' />
                    <Image image={close} onClick={() => handleClose()}/>
                  </div>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className={`container-full modal-container__box__content`}>
                      <div className={`container-full modal-container__box__content__top`}>
                        <div className={`container-full center modal-container__box__content__top__img`}>
                          <Image image={success} />
                        </div>
                        <div className={`container-full center modal-container__box__content__top__message`}>
                          <HeaderText value="You just created a Signatory Group!" />
                        </div>
                      </div>
                      <div className={`container-full modal-container__box__content__bottom`}>
                        <Text value='Signatory groups are to be assigned to Entities. You can do this now or later under Entities' />
                        <Text value='Would you like to assign this group now?' />
                      </div>
                    </div>
                    <div className={`container-full center modal-container__box__action`}>
                        <Button value="Do it later" className={`button --cancel`} onClick={() => closeModal()}/>
                        <Button type="submit" value="Assign Now" className={`button --assign`} isLoading={state.submitReducer.fetching} onClick={() => navigate('/settings/entities')}/>
                    </div>
                  </form>
              </div>  
          </div>
      </CreateSignatoryAssignModalStyle>
  );
}

export default CreateSignatoryAssignModal;