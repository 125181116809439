import React, {useState, useEffect} from 'react';
import EditUserModalStyle from './EditUserModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import Input from '../../atoms/Input/Input';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {SubmitActionsPUT} from '../../../redux/actions/SubmitAction';
import { alertActions } from '../../../redux/actions/AlertActions';
import {appInstance as axios} from '../../../axios';
import Cookies from 'js-cookie';

type EditUserModalProps = {
    handleClose: Function,
    reloadPage: Function,
    userData: any
}

const EditUserModal = ({handleClose, reloadPage, userData}: EditUserModalProps) => {
    const dispatch: TypedDispatch = useDispatch();
    const state = useSelector( (state:RootStore) => state);
    const [fields, setFields] = useState({
      firstname: userData.firstName,
      lastname: userData.lastName,
      email: userData.email,
      phone: userData.phoneNumber
    })
    const [fieldErrors, setFieldErrors] = useState({
      firstname: '',
      lastname: '',
      email: '',
      phone: ''
    })
    const [roles, setRoles] = useState([]);
    const [rolesList, setRolesList] = useState([]);
    const [submitSuccessfull, setSubmitSuccessfull] = useState(false);

    useEffect(() => {
      fetchRoles();
      fetchSpecificUserInfo();
    }, [])

    useEffect(() => {
      if(state.submitReducer.results !== null && submitSuccessfull){
        handleClose();
        reloadPage();
      }
    }, [state.submitReducer, submitSuccessfull])
  
    const fetchRoles = async () => {
      try{
        const res = await axios.get('/roles?status=1&size=0&page=1', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get("userToken")}`
          }
        });
        setRolesList(res.data.data.content);
      }catch (error: any) {
        if (error.message === "Network Error") {
          dispatch(alertActions.error('Network Error, try again!!!'));
        } else {
          dispatch(alertActions.error(error.response.data.error));
        }
      }
    }

    const fetchSpecificUserInfo = async () => {
      try{
        const res = await axios.get(`/users?id=${userData.id}&size=0&page=1`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get("userToken")}`
          }
        });
        let data:any = [];
        await res.data.data.content[0].userRoles.map((role: Record<any, any>) => {
          data.push(role.id)
        })
        setRoles(data);
        let signatoryData:any = [];
        await res.data.data.content[0].signatoryGroups.map((signatory: Record<any, any>) => {
          data.push(signatory.id)
        })

      }catch (error: any) {
        if (error.message === "Network Error") {
          dispatch(alertActions.error('Network Error, try again!!!'));
        } else {
          dispatch(alertActions.error(error.response.data.error));
        }
      }
    }

    const onFormSubmit = () => {
      return async (evt: React.FormEvent) => { 
          evt.preventDefault();
          const fieldValue = fields;

          const defaultValues:{
            [key: string]: any
          } = {
            "email": userData.email,
            "firstName": userData.firstName,
            "lastName": userData.lastName,
            "phoneNumber": userData.phoneNumber,
            "status": userData.status,
            "userLocked": false
          }
          const newValues:{
            [key: string]: any
          } = {
            "email": fieldValue.email,
            "firstName": fieldValue.firstname,
            "lastName": fieldValue.lastname,
            "phoneNumber": fieldValue.phone,
            "status": userData.status,
            "userLocked": false,
            "userRoles": roles
          } 
          let diff = Object.keys(newValues).reduce((diff, key) => {
            if (defaultValues[key] === newValues[key]) return diff
            return {
              ...diff,
              [key]: newValues[key]
            }
          }, {})

          await dispatch(
            SubmitActionsPUT(`/users/${userData.username}`, diff)
          )
          setSubmitSuccessfull(true)
      }
    }
  
    const inputChange =  async (name: string, value: string) => {
      const fieldsValues: any = Object.assign({}, fields);
      fieldsValues[name] = value;
      await setFields(fieldsValues);
      onInputValidate(value, name);
    };

    const onInputValidate = async (value: string, name: string) => {
      if(value.length > 0){
        const fieldErrorValues: any = Object.assign({}, fieldErrors);
        fieldErrorValues[name] = false;
        await setFieldErrors(fieldErrorValues);
        return true
      }
      else{
        const fieldErrorValues: any = Object.assign({}, fieldErrors);
        fieldErrorValues[name] = 'Field is required';
        await setFieldErrors(fieldErrorValues);
        return false
      }
    }

    const onCheckBoxChange = (id: string) => {
      return (event: any) => {
          if(event.target.checked){
              let data = id
              let rolesArr:any = [data, ...roles];
              setRoles(rolesArr);
          }
          else{
              let rolesArr = roles;
              let newRoles = rolesArr.filter((role: string) => role !== id)
              setRoles(newRoles)
          }
      }
    } 
  
    const validate = () => {         
      const person:any = fieldErrors;
      if (Object.keys(fieldErrors).filter((k) => person[k]).length){
          return true;
      }
      return false;
    } 
  
    return (
        <EditUserModalStyle className={`container-full`}>
            <div className={`container-full center modal-container`}>
                <div className={`modal-container__box`}>
                    <div className={`container-full modal-container__box__header`}>
                     <HeaderText value='Edit User' />
                     <Image image={close} onClick={() => handleClose()}/>
                    </div>
                    <form onSubmit={onFormSubmit()}>
                    <div className={`container-full modal-container__box__content`}>
                     <div className={`container-full modal-container__box__content__desc`}>
                        <Text value='Fill in the form to add a user' />
                     </div>
                     <div className={`container-full modal-container__box__content__desc__form`}>
                        <div className={`container-full modal-container__box__content__desc__form__item`}>
                          <Text value='First Name' className='label' />
                          <div className='container-full'>
                            <Input type='text' className='input' placeholder='Enter Firstname' value={fields.firstname} required={true} name="firstname" onChange={(evt) => inputChange(evt.target.name, evt.target.value) }/>
                          </div>
                        </div>
                        <div className={`container-full modal-container__box__content__desc__form__item`}>
                            <Text value='Last Name' className='label' />
                            <div className='container-full'>
                              <Input type='text' className='input' placeholder='Enter Lastname' value={fields.lastname} required={true} name="lastname" onChange={(evt) => inputChange(evt.target.name, evt.target.value) } />
                            </div>
                        </div>
                        <div className={`container-full modal-container__box__content__desc__form__item`}>
                            <Text value='Email' className='label' />
                            <div className='container-full'>
                              <Input type='text' className='input' placeholder='Enter Email Address' value={fields.email} required={true} name="email" onChange={(evt) => inputChange(evt.target.name, evt.target.value) } />
                            </div>
                        </div>
                        <div className={`container-full modal-container__box__content__desc__form__item`}>
                            <Text value='Phone Number' className='label' />
                            <div className='container-full'>
                              <Input type='text' className='input' placeholder='Enter Phone Number eg 2348098976787' value={fields.phone} required={true} name="phone" onChange={(evt) => inputChange(evt.target.name, evt.target.value) } />
                            </div>
                        </div>
                        <div className={`container-full modal-container__box__content__desc__form__item`}>
                            <Text value='Roles' className='label' />
                            <div className='container-full modal-container__box__content__desc__form__item__role'>
                              {
                                rolesList.length > 0 ? rolesList.map((role: Record<any, any>) => {
                                  return (
                                    <div className='modal-container__box__content__desc__form__item__role__item' key={role.id}>
                                      <Input type='checkbox' 
                                        value={role.id}
                                        onChange={onCheckBoxChange(role.id)} 
                                        checked={roles.some((userRoles: Record<any, any>) => userRoles === role.id)}
                                      />
                                      <div className='modal-container__box__content__desc__form__item__role__item__box'>
                                        <Text value={role.name} />
                                        {
                                          (role.name !== "Administrator" && role.name !== "Initiator" && role.name !== "Authorizer") &&
                                          <span>
                                            {
                                              role.roleType === 1 && '(Initiator)'
                                            }
                                            {
                                              role.roleType === 2 && '(Authorizer)'
                                            }
                                            {
                                              role.roleType === 3 && '(Admin)'
                                            }
                                          </span>
                                        }
                                      </div>
                                    </div>
                                  )
                                })  : ''
                              }
                            </div>
                        </div>
                     </div>
                    </div>
                    <div className={`container-full center modal-container__box__action`}>
                        <Button value="Cancel" className={`button --cancel`} onClick={() => handleClose()}/>
                        <Button type="submit" value="Edit User" className={`button --change ${
                          validate() === false && roles.length > 0 ? '--active-btn' : ''
                        }`} isLoading={state.submitReducer.fetching}/>
                    </div>
                    </form>
                </div>  
            </div>
        </EditUserModalStyle>
    );
}

export default EditUserModal;