import React, { useState } from 'react';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import PaymentReportTableStyle from './PaymentReportTableStyle';
import nike from '../../../assets/images/nike.svg';
import Pagination from '../Pagination/Pagination';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { exportData } from '../../../redux/actions/ExportAction';
import { useNavigate } from "react-router-dom";
import Button from '../../atoms/Button/Button';
import PaymentReportDetailsModal from '../PaymentReportDetailsModal/PaymentReportDetailsModal';
import SuccessModal from '../SuccessModal/SuccessModal';
import RetrySuccessModal from '../RetrySuccessModal/RetrySuccessModal';


type PaymentReportTableProps = {
  data: any,
  pagination: number,
  setPagination: Function,
  changePage: Function,
  page: number,
  reloadPage: Function
}

const PaymentReportTable = ({ data, pagination, setPagination, changePage, page, reloadPage }: PaymentReportTableProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state: any = useSelector((state: RootStore) => state);
  const navigate = useNavigate();
  const [togglePaymentReportDetailsModal, setTogglePaymentReportDetailsModal] = useState(false);
  const [toggleSuccessModal, setToggleSuccessModal] = useState(false);
  const [reportData, setReportData] = useState({});

  const handleAllCheck = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      await dispatch(exportData(state.dashboardReducer.results.data.content))
      console.log(state.exportReducer);
    }
    else {
      await dispatch(exportData([]));
      console.log(state.exportReducer);
    }
  };

  const handleCheckItem = (item: Record<any, any>) => {
    if (state.exportReducer.results.data.includes(item)) {
      const newArr = state.exportReducer.results.data.filter((record: Record<any, any>) => record.id !== item.id)
      console.log(newArr);
      return dispatch(exportData(newArr));
    }
    const update = [...state.exportReducer.results.data, item]
    console.log(update);
    return dispatch(exportData(update));
  };

  const statusArr = [
    "Bulk Payment Debit Failed",
    "Bulk Payment Debit Completed",
    "Ongoing Bulk Debit",
    "Fee calculation completed",
    "Fee calculation completed with Error",
    "Ongoing fee calculation",
    "Pending authorization",
    "Payment Aggregation completed",
    "Payment Aggregation failed",
    "Ongoing Payment Aggregation",
    "File parsing completed",
    "Incomplete or failed File parsing",
    "New payment, file parsing ongoing",
    "Bulk payment completed, Sync ongoing",
    "Bulk payment completed, sync completed with errors",
    "Bulk payment completed, sync completed"
  ]

  const showPaymentReportDetailsModal = (paymentRef: string) => {
    let reportData = data.data.content.filter((data: Record<any, any>) => data.paymentRef === paymentRef);
    let value = {
      ...reportData[0]
    }
    console.log(value)
    setReportData(value);
    console.log(reportData);
    setTogglePaymentReportDetailsModal(true);
  }

  const hidePaymentReportDetailsModal = () => {
    setTogglePaymentReportDetailsModal(false);
  }

  const renderPaymentReportDetailsModal = () => {
    if (togglePaymentReportDetailsModal) {
      return (
        <PaymentReportDetailsModal handleClose={hidePaymentReportDetailsModal} data={reportData} showSuccessModal={showSuccessModal} />
      )
    }
  }

  const showSuccessModal = (paymentRef: string) => {
    setToggleSuccessModal(true);
  }

  const hideSuccessModal = () => {
    setToggleSuccessModal(false);
  }

  const renderSuccessModal = () => {
    if (toggleSuccessModal) {
      return (
        <RetrySuccessModal handleClose={hideSuccessModal} reloadPage={reloadPage} data={reportData} />
      )
    }
  }

  return (
    <>
      {renderPaymentReportDetailsModal()}
      {renderSuccessModal()}
      <PaymentReportTableStyle>
        <div className={`container-full`}>
          <table>
            <thead>
              <tr className={`table-header`}>
                <th>
                  <input type="checkbox" onChange={handleAllCheck} />
                  <Image image={nike} />
                </th>
                <th>
                  <Text value="Payment Info" className={`table-title`} />
                </th>
                {
                  state.authReducer.results.user_level === 2 && (
                    <th>
                      <Text value="Merchant Name" className={`table-title`} />
                    </th>
                  )
                }
                <th>
                  <Text value="Data Range" className={`table-title`} />
                </th>
                <th>
                  <Text value="Txn Date" className={`table-title`} />
                </th>
                <th>
                  <Text value="Records" className={`table-title`} />
                </th>
                <th>
                  <Text value="Amount" className={`table-title`} />
                </th>
                <th>
                  <Text value="Status" className={`table-title`} />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className={`table-body`}>
              {
                (data.data.content !== undefined && data.data.content.length > 0) ?
                  data.data.content.map((report: Record<any, any>, key: string) => {
                    return (
                      <tr className={`table-row`} key={key}>
                        <td>
                          <input
                            type="checkbox"
                            checked={state.exportReducer.results.data.includes(
                              report
                            )}
                            onChange={() => handleCheckItem(report)}
                          />
                          <Image image={nike} />
                        </td>
                        <td className='name'>
                          <div className='status --successful'></div>
                          <div>
                            <Text value={report.paymentName} className='label' onClick={() => navigate(`/reports/payment-report/details?paymentReference=${report.paymentRef}`)} />
                            <Text value={report.paymentRef} className='sublabel' />
                          </div>
                        </td>
                        {
                          state.authReducer.results.user_level === 2 && (
                            <td>
                              <Text value={report.merchant && report.merchant.businessName} className='label' />
                            </td>
                          )
                        }
                        <td>
                          <Text value={`${dayjs(report.startDate).format('MM/DD/YYYY')} - ${dayjs(report.endDate).format('MM/DD/YYYY')}`} className='label' />
                        </td>
                        <td>
                          <Text value={`${dayjs(report.createdDate).format('MM/DD/YYYY')}`} className='label' />
                        </td>
                        <td>
                          <Text value={report.totalBenCount} className='label' />
                        </td>
                        <td>
                          <Text value={`₦${report.totalAmount}`} className='label' />
                        </td>
                        <td>
                          <Text value={statusArr[report.paymentStatus]} className='label' />
                        </td>
                        { state.authReducer.results.user_level === 1 ? 
                          report.paymentStatus === 1 || report.paymentStatus === 14 ?
                            <td>
                              <Button className={`button --retry`} value="Retry Sync" onClick={() => showPaymentReportDetailsModal(report.paymentRef)} />
                            </td> :
                            <td>
                              <Button className={`button --retry disabled`} value="Retry Sync" onClick={() => showPaymentReportDetailsModal(report.paymentRef)} />
                            </td> : null
                        }
                      </tr>
                    )
                  }) : null
              }
            </tbody>
          </table>
        </div>
        <div className={`container-full pagination`}>
          <Pagination pagination={pagination} changePage={changePage} setPagination={setPagination} page={page} />
        </div>
      </PaymentReportTableStyle>
    </>
  );
};

export default PaymentReportTable;
