import styled from 'styled-components';
import search from '../../../assets/images/Search.svg';

const MerchantEditProfileTemplateStyle = styled.div`
  background: white;
  box-sizing: border-box;
  padding: 20px 40px 30px 30px;
  width: 100%;

  .merchants__back{
    justify-content: normal;
    a{
      display: flex;
    }
    p{
      color: rgba(37, 39, 51, 0.7);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      margin-top: 2.5px;
    }
  }
  .merchants__desc{
    margin-top: 10px;
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
    }
  }
  form{
    width: 100%;
  }
  .merchants__top{
    background: white;
    border-radius: 8px;
    box-shadow: 0px 14px 25px rgba(37, 39, 51, 0.05);
    box-sizing: border-box;
    margin-bottom: 34px;
    margin-top: 16px;
    padding: 20px 25px 15px 25px;
  }
  .merchants__top__desc{
    align-items: flex-start;
    border-bottom: 1px solid #F0F0F0;
    box-sizing: border-box;
    padding-bottom: 15px;
  }
  .merchants__top__desc__name{
    align-items: flex-start;
    display: flex;
    img{
      border-radius: 50%;
      height: 62px;
      margin-right: 12px;
      width: 62px;
    }
  }
  .merchants__top__desc__name__group{
    margin-top: 11px;
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 1px;
    }
    p{
      color: rgba(37, 39, 51, 0.5);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
    }
  }
  .merchants__top__desc__action{
    display: flex;
    margin-top: 16px;
    .button{
      border-radius: 5px;
      box-sizing: border-box
      display: flex;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      height: 30px;
      padding: 0 8px;
      text-align: left;
      img{
        margin-right: 3px;
        transform: translateY(4px);
      }
    }
    .--revert{
      background: #F5F5F5;
      color: #252733;
      margin-right: 15.17px;
      position: relative;
      width: 80px;
      &:after{
        background: #F0F0F0;
        content: '';
        height: 21.12px;
        margin-right: -9px;
        margin-top: 4.44px;
        position: absolute;
        right: 0;
        top: 0;
        // transform: rotate(90deg);
        width: 1px;
      }
    }
    .--save{
      background: #3751FF;
      color: white;
      width: 131px;
    }
  }
  
  .merchants__top__nav{
    box-sizing: border-box;
    padding-top: 15px;
    .date{
      margin-top: 10px;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      font-feature-settings: 'salt' on;
      span{
        color: rgba(37, 39, 51, 0.5);
        font-family: 'Mulish';
      }
    }
  }
  .merchants__info{
    background: #FAFAFA;
    box-sizing: border-box;
    margin-bottom: 25px;
    padding: 15px;
  }
  .merchants__info__desc{
    h2{
      color: #08090A;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
    }
  }
  .merchants__info__box{
    background: rgba(245, 245, 245, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 16px;
    padding: 15px;
  }
  .merchants__info__box__row{
    margin-bottom: 25px;
    &:last-child{
      margin-bottom: 0;
    }
    h2{
      color: #08090A;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 8px;
    }
    .input{
      background: rgba(55, 81, 255, 0.03);
      border: 1px solid rgba(55, 81, 255, 0.2);
      border-radius: 5px;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      height: 45px;
      padding-left: 15px;
      width: 100%;
      &::placeholder{
        color: #252733;
      }
    }
  }
  .--kyc{
    justify-content: normal;
    .div-md-10{
      display: flex;
      flex-wrap: nowrap;
      justify-content: normal;
      margin-bottom: 0;
      overflow: scroll;
    }
  }
  .merchants__info__box__row__item{
    cursor: pointer;
    margin-bottom: 0;
  }
  .--documents{
    background: rgba(55, 81, 255, 0.03);
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    margin-right: 22px;
    min-height: 70px;
    padding: 15px;
    .document{
      margin-right: 10px;
    }
    h2{
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 1px;
    }
    p{
      color: rgba(37, 39, 51, 0.5);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
    }
    .expand{
      align-self: flex-start;
      height: 12px;
      margin-left: auto;
      width: 12px;
    }
  }
  .merchants__info__box__row__add-file{
    background: rgba(55, 81, 255, 0.03);
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 0;
    margin-right: 22px;
    height: 70px;
    padding: 15px;
    position: relative;
    .container-full{
      justify-content: center;
      img{
        margin-top: -6px;
      }
    }
  }
  .more-info {
    background: white;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(8, 9, 10, 0.1);
    margin-right: 82.5px;
    margin-top: -30px;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 0;
    width: 250px;
    z-index: 4000;
}
.more-info__item{
    margin-bottom: 20px;
    p{
        color: #252733;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 7.5px;
        width: 100%;
    }
    .input{
        background: rgba(55, 81, 255, 0.03);
        border: none;
        border-radius: 8px;
        box-sizing: border-box;
        color: rgb(37, 39, 51);
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        padding-left: 15px;
        width: 100%;
        &::placeholder{
            color: rgba(37, 39, 51, 0.3);
        }
    }
    &:last-child{
        margin-bottom: 0;
    }
}

.merchants__info__box__row__item__switch{
  align-self: center;
  position: relative;
  .input{
    cursor: pointer;
    height: 18px;
    position: absolute;
    opacity: 0;
    width: 30px;
    &:checked + .merchants__info__box__row__item__switch__box{
      background: #1B9830;
      .merchants__info__box__row__item__switch__box__ball{
        background: white;
        margin-left: 10px;
      }
    }
  }
}
.merchants__info__box__row__item__switch__box{
  background: #CCCCCC;
  border-radius: 70px;
  box-sizing: border-box;
  cursor: pointer;
  height: 18px;
  padding: 2px;
  transition: all 0.2s linear;
  width: 30px;
}
.merchants__info__box__row__item__switch__box__ball{
  background: white;
  border-radius: 50%;
  height: 14px;
  transition: margin 0.5s linear;
  width: 14px;
}
`;

export default MerchantEditProfileTemplateStyle;
