import React, { useState, useEffect } from 'react';
import MerchantFeesSummaryTemplateStyle from './MerchantFeesSummaryTemplateStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import angleLeft from '../../../assets/images/angle-small-left.svg';
import merchantProfile from '../../../assets/images/merchant-profile.svg';
import MerchantNav from '../../organisms/MerchantNav/MerchantNav';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { DashboardActions } from '../../../redux/actions/DashboardAction';
import { exportClear } from '../../../redux/actions/ExportAction';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import { alertActions } from '../../../redux/actions/AlertActions';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';
import cogoToast from "cogo-toast";
import ScaledBandDetailsModal from '../../organisms/ScaledBandDetailsModal/ScaledBandDetailsModal';
import { SubmitActionsPUT } from '../../../redux/actions/SubmitAction';

const MerchantFeesSummaryTemplate = () => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [toggleScaledBandDetails, setToggleScaledBandDetails] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [merchantData, setMerchantData]: any = useState({});
  const [imageUrl, setFileimageUrl] = useState<string>();
  const [scaleData, setScaleData] = useState({});
  const [status, setStatus] = useState(0);
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);

  useEffect(() => {
    dispatch(exportClear())
    generatePageContent();
    if (state.dashboardReducer.results) {
      setStatus(state.dashboardReducer.results.data.content[0].status)
    }
    console.log(state);
  }, [size, page])

  useEffect(() => {
    if (merchantData && merchantData.companyLogoUri) {
      openImageFile(merchantData.companyLogoUri);
    }
  }, [merchantData])

  const generatePageContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/fee/category?${window.location.href.split("?")[1].split("&")[0]}&size=${size}&page=${page}&${window.location.href.split("?")[1].split("&")[1]}`));
    setFetchLoading(false);

    try {
      const res = await axios.get(`/merchant?${window.location.href.split("?")[1]}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setMerchantData(res.data.data.content[0]);
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const openImageFile = async (fileUri: string) => {
    const apiDefinition = () => {
      return axios.get(`${fileUri.substring(4)}`,
        {
          responseType: "blob",
          headers: {
            "Authorization": `Bearer ${Cookies.get("userToken")}`,
          }
        }
      );
    };
    try {
      setFetchLoading(true);
      const { data } = await apiDefinition();
      const url = URL.createObjectURL(new Blob([data]));
      setFileimageUrl(url);
      setFetchLoading(false);
    } catch (error) {
      cogoToast.error("Error downloading file", { position: "top-right" })
    }
  }

  const renderFetchLoading = () => {
    if (fetchLoading) {
      return (
        <FetchLoading />
      )
    }
  }

  const showScaledBandDetailsModal = async (data: object) => {
    await setScaleData(data)
    setToggleScaledBandDetails(true);
  }

  const hideScaledBandDetailsModal = () => {
    setToggleScaledBandDetails(false);
  }

  const renderScaledBandDetailsModal = () => {
    if (toggleScaledBandDetails) {
      return (
        <ScaledBandDetailsModal handleClose={hideScaledBandDetailsModal} data={scaleData} />
      )
    }
  }

  const statusChange = () => {
    return async (event: any) => {
      if (event.target.checked) {
        let value = {
          status: 1
        }
        setStatus(1);
        setFetchLoading(true);
        await dispatch(SubmitActionsPUT(`/fee/category/${state.dashboardReducer.results.data.content[0].id}`, value));
        setFetchLoading(false);
      }
      else {
        let value = {
          status: 0
        }
        setStatus(0);
        setFetchLoading(true);
        await dispatch(SubmitActionsPUT(`/fee/category/${state.dashboardReducer.results.data.content[0].id}`, value));
        setFetchLoading(false);
      }
    }
  }

  return (
    <>
      {renderFetchLoading()}
      {renderScaledBandDetailsModal()}
      <MerchantFeesSummaryTemplateStyle>
        <div className={`container-full merchants`}>
          <div className={`container-full merchants__back`}>
            <Link to="/merchants">
              <Image image={angleLeft} />
              <Text value='Back to Merchants' />
            </Link>
          </div>
          <div className={`container-full merchants__desc`}>
            <HeaderText value='Merchants' />
          </div>
          <div className={`container-full merchants__top`}>
            <div className={`container-full merchants__top__desc`}>
              <div className={`merchants__top__desc__name`}>
                {
                  (merchantData && imageUrl) ?
                    <Image image={imageUrl} /> : <Image image={merchantProfile} />
                }
                <div className={`merchants__top__desc__name__group`}>
                  <HeaderText value={
                    (merchantData.businessName) ? merchantData.businessName : ''
                  } />
                  <Text value={
                    (merchantData.businessEmail) ? merchantData.businessEmail : ''
                  } />
                </div>
              </div>
            </div>
            <div className={`container-full merchants__top__nav`}>
              <div>
                <MerchantNav />
              </div>
            </div>
          </div>
          <div className={`container-full merchants__bottom`}>
            <div className={`container-full merchants__bottom__desc`}>
              <HeaderText value='Fee Category Summary' />
            </div>
            <div className={`container-full merchants__bottom__details`}>
              <div className={`merchants__bottom__details__left`}>
                <h2> Category Name
                  <span>:
                    {
                      state.dashboardReducer.results && state.dashboardReducer.results.data.content.length > 0 ? state.dashboardReducer.results.data.content[0].name : null
                    }
                  </span>
                </h2>
                <h2> Description
                  <span>:
                    {
                      state.dashboardReducer.results && state.dashboardReducer.results.data.content.length > 0 ? state.dashboardReducer.results.data.content[0].description : null
                    }
                  </span>
                </h2>
              </div>
              <div className={`merchants__bottom__details__right`}>
                <h2> Last Updated<span>: Sat, Sept 20, 2008</span></h2>
                <div className={`merchants__bottom__details__right__switch`}>
                  {
                    (status === 1) ? <Text value="Enabled" /> : null
                  }
                  {
                    (status === 0) ? <Text value="Disabled" /> : null
                  }
                  <div className={`merchants__bottom__details__right__switch__checkbox`}>
                    <input type="checkbox" className={`input`} value={status} defaultChecked={
                      state.dashboardReducer.results && state.dashboardReducer.results.data.content.length > 0 && state.dashboardReducer.results.data.content[0].status === 1 ? true : false
                    }
                      onChange={statusChange()}
                    />
                    <div className={`merchants__bottom__details__right__switch__checkbox__box`}>
                      <div className={`merchants__bottom__details__right__switch__checkbox__box__ball`}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`container-full merchants__bottom__content`}>
              <div className={`container-full merchants__bottom__content__row`}>
                {
                  state.dashboardReducer.results && state.dashboardReducer.results.data.content.length > 0
                  && state.dashboardReducer.results.data.content[0].participants && state.dashboardReducer.results.data.content[0].participants.map((category: Record<any, any>, key: number) => {
                    return (
                      <div className={`div-md-4 merchants__bottom__content__row__item`} key={key}>
                        <div className={`container-full merchants__bottom__content__row__item__top`}>
                          <div className={`merchants__bottom__content__row__item__top__left`}>
                            {category.participantType === 1 ? <HeaderText value='Salary' /> : null}
                            {category.participantType === 2 ? <HeaderText value='PAYE' /> : null}
                            {category.participantType === 3 ? <HeaderText value='Pension' /> : null}
                            {category.participantType === 4 ? <HeaderText value='NHIS' /> : null}
                            {category.participantType === 5 ? <HeaderText value='NHF' /> : null}
                            {category.participantType === 6 ? <HeaderText value='Deductions' /> : null}
                            <div className={`merchants__bottom__content__row__item__top__left__label`}>
                              {
                                (category.feeType === 0) ?
                                  <Text value='Scaled' /> : null
                              }
                              {
                                (category.feeType === 1) ?
                                  <Text value='Fixed' /> : null
                              }
                              {
                                (category.feeType === 2) ?
                                  <Text value='Percentage' /> : null
                              }
                              {
                                (category.feeType === 3) ?
                                  <Text value='Hybrid' /> : null
                              }
                            </div>
                          </div>
                          {
                            category.feeType !== 0 && category.feeType !== 1 &&
                            <div className={`merchants__bottom__content__row__item__top__right`}>
                              <h2>Min Fee: <span>&#8358;{category.minimumFee}</span></h2>
                              <h2>Max Fee: <span>&#8358;{category.maximumFee}</span></h2>
                            </div>
                          }
                        </div>
                        <div className={`container-full merchants__bottom__content__row__item__bottom`}>
                          <div className={`merchants__bottom__content__row__item__bottom__left`}>
                            {
                              (category.feeType === 1) ?
                                <h2 className='header --amount'> &#8358;{category.feeValue} </h2> : null
                            }
                            {
                              (category.feeType === 2 || category.feeType === 3) ?
                                <HeaderText value={`${category.feeValue}%`} className='header' /> : null
                            }
                            {
                              (category.feeType === 3) ?
                                <h2 className='subheader'> + &#8358;{category.addedFee} </h2> : null
                            }
                            {
                              (category.feeType === 0) ?
                                <Text value='View Scaled Bands' className='link' onClick={() => showScaledBandDetailsModal(category)} /> : null
                            }
                            {
                              (category.chargeType === 1) ? <Text value='On Agreegate Payment' /> : null
                            }
                            {
                              (category.chargeType === 2) ? <Text value='Per Transaction' /> : null
                            }
                          </div>
                          <div className={`merchants__bottom__content__row__item__bottom__right`}>
                            <p>Charged to
                              <span>
                                {
                                  (category.bearer === 1) ? 'Merchant' : null
                                }
                                {
                                  (category.bearer === 2) ? 'Customer' : null
                                }
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>

        </div>
      </MerchantFeesSummaryTemplateStyle>
    </>
  );

};

export default MerchantFeesSummaryTemplate;
