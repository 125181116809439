import React, { useState, useEffect } from 'react';
import PendingApprovalSummaryAuthorizeTemplateStyle from './PendingApprovalSummaryAuthorizeTemplateStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { DashboardActions } from '../../../redux/actions/DashboardAction';
import { exportClear } from '../../../redux/actions/ExportAction';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import dayjs from 'dayjs';
import commaNumber from 'comma-number';
import PendingApprovalSummaryAuthorize from '../../organisms/PendingApprovalSummaryAuthorize/PendingApprovalSummaryAuthorize';
import { Link } from 'react-router-dom';
import Img from '../../atoms/Image/Img';
import angleLeft from '../../../assets/images/angle-small-left.svg';
import { alertActions } from '../../../redux/actions/AlertActions';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';
import FileProcessingModal from '../../organisms/FileProcessingModal/FileProcessingModal';

const PendingApprovalSummaryAuthorizeTemplate = () => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [statusData, setStatusData] = useState<any>({});
  const [toggleFileProcessingModal, setToggleFileProcessingModal] = useState(false);
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  let interval:any;

  useEffect(() => {
    dispatch(exportClear())
    generatePageContent();
    console.log(state);
  }, [size, page, reloadPage])

  useEffect(() => {
    fetchStatusData();
    interval = setInterval(() => {
      fetchStatusData();
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, [])

  useEffect(() => {
    if (Object.keys(statusData).length > 0) {
      if (statusData.processedCount < statusData.beneficiaryCount) {
        showFileProcessingModal();
      }
      else {
        hideFileProcessingModal();
        generatePageContent();
      }
    }
  }, [statusData])

  const fetchStatusData = async () => {
    try {
      const res = await axios.get(`/payment/${window.location.href.split("=")[1]}/status`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setStatusData(res.data.data);
      if (res.data.data.processedCount === res.data.data.beneficiaryCount) {
        clearInterval(interval)
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const generatePageContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/payment/${window.location.href.split("=")[1]}/aggregatedetails?size=${size}&page=${page}`));
    setFetchLoading(false);
  }

  const renderFetchLoading = () => {
    if (fetchLoading) {
      return (
        <FetchLoading />
      )
    }
  }

  const showFileProcessingModal = () => {
    setToggleFileProcessingModal(true);
  }

  const hideFileProcessingModal = () => {
    setToggleFileProcessingModal(false);
  }

  const renderFileProcessingModal = () => {
    if (toggleFileProcessingModal) {
      return (
        <FileProcessingModal data={statusData} header={`Fee Calculation Ongoing`} />
      )
    }
  }

  return (
    <>
      {renderFetchLoading()}
      {renderFileProcessingModal()}
      <PendingApprovalSummaryAuthorizeTemplateStyle>
        <div className={`container-full payments`}>
          <div className={`container-full payments__back`}>
            <Link to="/payments/ongoing-payments">
              <Img image={angleLeft} />
              <Text value='Back to Payments' />
            </Link>
          </div>
          <div className={`container-full payments__desc`}>
            <HeaderText value="Payment Summary" />
          </div>
          <div className='container-full payments__action'>
            <div className={`payments__action__desc`}>
              <HeaderText value='Payment List Name' />
            </div>
            <div className={`payments__action__search`}>
              <div className='btn-group'>
                {/* {
                  (state.exportReducer.results.data.length > 0) ?
                    <CSVLink data={exportData().fileData()} headers={exportData().fileHeaders()} filename={"pending approval.csv"}>
                      <button className='button --export'>
                        <Image image={exportIcon} />
                      </button>
                    </CSVLink> :
                    <button className='button --export --disabled'>
                      <Image image={exportIcon} />
                    </button>
                } */}
              </div>
            </div>
          </div>
          <div className={`container-full payments__data`}>
            <div className={`container-full payments__data__row`}>
              <div className={`div-md-9 payments__data__row__left`}>
                <div className={`container-full`}>
                  <div className={`div-md-3 payments__data__row__left__item`}>
                    <HeaderText value='Payment Reference' />
                    <Text value={state.dashboardReducer.results && window.location.href.split("=")[1] !== undefined && window.location.href.split("=")[1]} className={`--id`} />
                  </div>
                  <div className={`div-md-3 payments__data__row__left__item`}>
                    <HeaderText value='Date Initiated' />
                    <p className={`--date`}> {state.dashboardReducer.results && dayjs(state.dashboardReducer.results.data.createdDate).format("DD/MM/YYYY")}
                      <span> {state.dashboardReducer.results && dayjs(state.dashboardReducer.results.data.createdDate).format("HH:mm:ss")} </span></p>
                  </div>
                  <div className={`div-md-3 payments__data__row__left__item`}>
                    <HeaderText value='Initiated By' />
                    <Text value={state.dashboardReducer.results && state.dashboardReducer.results.data.uploader && `${state.dashboardReducer.results.data.uploader.firstName} ${state.dashboardReducer.results.data.uploader.lastName}`} className={`--username`} />
                  </div>
                  <div className={`div-md-3 payments__data__row__left__item`}>

                  </div>
                </div>
              </div>
              <div className={`div-md-3 payments__data__row__right`}>
                <HeaderText value='Date Range' />
                <div className={`container-full payments__data__row__right__range`}>
                  <p> <span>From:</span> {state.dashboardReducer.results && dayjs(state.dashboardReducer.results.data.startDate).format("DD/MM/YYYY")}</p>
                  <p> <span>To:</span> {state.dashboardReducer.results && dayjs(state.dashboardReducer.results.data.endDate).format("DD/MM/YYYY")}</p>
                </div>
              </div>
            </div>
            <div className={`container-full payments__data__row`}>
              <div className={`div-md-9 payments__data__row__left`}>
                <div className={`container-full`}>
                  <div className={`div-md-3 payments__data__row__left__item`}>
                    <HeaderText value='Name' />
                    <Text value={state.dashboardReducer.results && state.dashboardReducer.results.data.paymentName} className={`--description`} />
                  </div>
                  <div className={`div-md-3 payments__data__row__left__item`}>
                    <HeaderText value='Description' />
                    <Text value={state.dashboardReducer.results && state.dashboardReducer.results.data.paymentDescription} className='--signatory' />
                  </div>
                  <div className={`div-md-3 payments__data__row__left__item`}>
                    <HeaderText value='Number of Records' />
                    <Text value={state.dashboardReducer.results && state.dashboardReducer.results.data.totalBenCount} className={`--record`} />
                  </div>
                  <div className={`div-md-3 payments__data__row__left__item`}>

                  </div>
                </div>
              </div>
              <div className={`div-md-3 payments__data__row__right`}>
                <HeaderText value='Amount' />
                <Text value={state.dashboardReducer.results && `₦${commaNumber(state.dashboardReducer.results.data.totalAmount)}`} className='--amount' />
              </div>
            </div>
          </div>
          <div className='container-full payments__table'>
            {
              (state.dashboardReducer.results !== null && statusData.processedCount === statusData.beneficiaryCount) ?
                <PendingApprovalSummaryAuthorize data={state.dashboardReducer.results} /> : null
            }
          </div>
        </div>
      </PendingApprovalSummaryAuthorizeTemplateStyle>
    </>
  );

};

export default PendingApprovalSummaryAuthorizeTemplate;
