import styled from 'styled-components';
import bg from '../../../assets/images/bg.jpeg';

const ResetPasswordTemplateStyle = styled.div`
  background: radial-gradient(54.51% 54.51% at 50% 50%, rgba(10, 18, 73, 0) 29.17%, rgba(10, 18, 73, 0.5) 100%), url(${bg});
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  height: 100vh;
  // padding-top: 70px;
  width: 100%;

  .signin{
    height: 100%;
  }
  .signin__box{
    -ms-flex: 0 0 408px;
    -webkit-flex: 0 0 408px;
    flex: 0 0 408px;
    max-width: 408px;
  }
  .signin__box__logo{
    img{
      height: 35.4px;
      width: 108px;
    }
  }
  .signin__box__modal{
    margin-top: 49px;
  }
  .--success-modal{
    margin-top: 39px;
  }
  .signin__box__modal__content{
    background: #FFFFFF;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 40px 49px 40px 49px;
  }
  .--success-modal__content{
    padding: 20px 30px 30px 30px;
  }
  .signin__box__modal__content__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 700;
      font-size: 1.750em;
      line-height: 35px;
    }
    p{
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      margin-top: 10px;
      text-align: center;
      width: 100%;
    }
  }
  .signin__box__modal__content__alert{
    margin-top: 29px;
  }
  .signin__box__modal__content__form{
    margin-top: 29px;
  }
  .signin__box__modal__content__nav{
    justify-content: normal;
    p{
      color: rgba(10, 18, 73, 0.5);
      cursor: pointer
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      margin-left: 2px;
      opacity: 0.8;
    }
  }
  .--success-modal__desc{
    margin-top: 12px;
  }
  .signin__box__modal__content__icon{
    margin-top: 39px;
    img{
      height: 70px;
      width: 70px;
    }
  }
  .signin__box__modal__content__text{
    box-sizing: border-box;
    margin-top: 10px;
    padding-left: 19px;
    padding-right: 19px;
    p{
      color: rgba(75, 80, 109, 0.7);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      width: 100%;
      span{
        color: rgb(75, 80, 109);
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
      }
    }
  }
  .signin__box__modal__content__action{
    margin-top: 39px;
    .button{
      background: #3751FF;
      border-radius: 8px;
      box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
      color: white;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      height: 50px;
      margin-bottom: 0;
      width: 160px;
    }
  }
`;

export default ResetPasswordTemplateStyle;
