import React, { useState, useEffect } from 'react';
import UpdateSourceAccountModalStyle from './UpdateSourceAccountModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import Input from '../../atoms/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { SubmitActionsPOST, SubmitActionsPUT } from '../../../redux/actions/SubmitAction';
import { alertActions } from '../../../redux/actions/AlertActions';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';
import caret from '../../../assets/images/caret-down-select.svg';
import eyesClose from '../../../assets/images/eyes-pwd-close.svg';
import eyes from '../../../assets/images/eyes-pwd.svg';
import closeIcon from '../../../assets/images/close-icon-round.svg';
import dropdown from '../../../assets/images/dropdown.svg';

type UpdateSourceAccountModalProps = {
    data: any,
    handleClose: Function,
    reloadPage: Function
}

const UpdateSourceAccountModal = ({ handleClose, reloadPage, data }: UpdateSourceAccountModalProps) => {
    let timeOutId: any = null;
    const [selectedMenu, setSelectedMenu] = useState(false);
    const [fields, setFields] = useState({
        feeCategory: data.feeCategory.catId,
        processor: data.defProcessor.toString(),
        terminalID: '',
        encryptionKey: ''
    })
    const [checking, setChecking] = useState(false);
    const [accountName, setAccountName] = useState("");
    const [accountError, setAccountError] = useState(false);
    const [feeCategory, setFeeCategory] = useState([]);
    const [processors, setProcessors] = useState([]);
    const [users, setUsers]: any = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const dispatch: TypedDispatch = useDispatch();
    const state = useSelector((state: RootStore) => state);

    useEffect(() => {
        if (state.submitReducer.results && submitted) {
            handleClose();
            reloadPage();
        }
    }, [state.submitReducer])

    useEffect(() => {
        fetchFeeCategory();
        fetchProcessors();
        let assignedUsers: any = [];
        if (data.authorizers) {
            data.authorizers.map((authorizer: Record<any, any>) => {
                let user = {
                    id: authorizer.authId,
                    firstName: authorizer.authName.split(" ")[0],
                    lastName: authorizer.authName.split(" ")[1]
                }
                assignedUsers.push(user);
                return user;
            })
        }
        if (data.mappedProcessors) {
            data.mappedProcessors.map((processor: Record<any, any>) => {
                if (processor.id === data.defProcessor) {
                    const fieldsValues: any = Object.assign({}, fields);
                    processor.procParams.map((proc: Record<any, any>) => {
                        if (proc.fieldName === "terminalId") {
                            fieldsValues["terminalID"] = proc.value;
                        }
                        if (proc.fieldName === "encryptionKey") {
                            fieldsValues["encryptionKey"] = proc.value;
                        }
                    })
                    setFields(fieldsValues);
                }
            })
        }
        setUsers(assignedUsers);
    }, [])

    const fetchFeeCategory = async () => {
        try {
            const res = await axios.get(`/fee/category?${window.location.href.split("?")[1]}&status=1&size=0&page=1`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get("userToken")}`
                }
            });
            setFeeCategory(res.data.data.content);
        } catch (error: any) {
            if (error.message === "Network Error") {
                dispatch(alertActions.error('Network Error, try again!!!'));
            } else {
                dispatch(alertActions.error(error.response.data.error));
            }
        }
    }

    const fetchProcessors = async () => {
        try {
            const res = await axios.get(`/processors?size=0&page=1`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get("userToken")}`
                }
            });
            setProcessors(res.data.data);
        } catch (error: any) {
            if (error.message === "Network Error") {
                dispatch(alertActions.error('Network Error, try again!!!'));
            } else {
                dispatch(alertActions.error(error.response.data.error));
            }
        }
    }

    const inputChange = async (name: string, value: string) => {
        const fieldsValues: any = Object.assign({}, fields);
        fieldsValues[name] = value;
        await setFields(fieldsValues);
    };

    useEffect(() => {
        fetchUsers();
    }, [])

    const fetchUsers = async () => {
        try {
            const res = await axios.get(`/users/roleType?${window.location.href.split("?")[1]}&type=2&size=0&page=1`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get("userToken")}`
                }
            });
            setUsersList(res.data.data.content);
        } catch (error: any) {
            if (error.message === "Network Error") {
                dispatch(alertActions.error('Network Error, try again!!!'));
            } else {
                dispatch(alertActions.error(error.response.data.error));
            }
        }
    }

    const onClickHandler = (value: boolean) => {
        if (selectedMenu !== false) {
            setSelectedMenu(false);
            return;
        }
        setSelectedMenu(value);
    }

    const onBlurHandler = () => {
        timeOutId = setTimeout(() => {
            setSelectedMenu(false);
        });
    }

    const onFocusHandler = () => {
        clearTimeout(timeOutId);
    }

    const toggleState = () => {
        return (evt: any) => {
            if (evt.target.parentElement.firstChild.type === 'password') {
                evt.target.src = eyes;
                return (evt.target.parentElement.firstChild.type = 'text');
            } else {
                evt.target.src = eyesClose;
                return (evt.target.parentElement.firstChild.type = 'password');
            }
        };
    }

    const toggleUsers = (item: Record<any, any>) => {
        if (users.some((role: Record<any, any>) => role.id === item.id)) {
            const newArr = users.filter((record: Record<any, any>) => record.id !== item.id)
            console.log(newArr);
            return setUsers(newArr);
        }
        const newArr: any = [...users, item]
        setUsers(newArr);
    }

    const removeUser = (item: Record<any, any>) => {
        const newArr = users.filter((record: Record<any, any>) => record.id !== item.id)
        return setUsers(newArr);
    }

    const onFormSubmit = () => {
        return async (evt: React.FormEvent) => {
            evt.preventDefault();
            setSubmitted(true);
            const authorizers: any = [];
            await users.map((user: Record<any, any>) => {
                authorizers.push(user.id);
            })
            await dispatch(SubmitActionsPUT(`account/${data.id}`, {
                "feeCategoryId": fields.feeCategory,
                "defProcessorId": data.defProcessor,
                "authorizers": authorizers,
                "processorParams": {
                    "terminalId": fields.terminalID,
                    "encryptionKey": fields.encryptionKey
                },

            }));
        }
    }

    const getAccountName = async () => {
        const payload = {
            "accountNumber": data.accountNumber,
            "issuerCode": data.issuer.issCode,
            "entity": 1,
        }
        try {
            setChecking(true);
            const res = await axios.post(`/account/enquiry`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get("userToken")}`
                }
            });
            setChecking(false);
            if (res.data.data.isValidated === 0) {
                setAccountError(true);
            }
            else {
                setAccountError(false);
            }
            setAccountName(res.data.data.validatedName);
        } catch (error: any) {
            setChecking(false);
            if (error.message === "Network Error") {
                dispatch(alertActions.error('Network Error, try again!!!'));
            } else {
                dispatch(alertActions.error(error.response.data.error));
            }
        }
    }

    return (
        <UpdateSourceAccountModalStyle className={`container-full`}>
            <div className={`container-full center modal-container`}>
                <div className={`modal-container__box`}>
                    <div className={`container-full modal-container__box__header`}>
                        <HeaderText value='Update Source Account' />
                        <Image image={close} onClick={() => handleClose()} />
                    </div>
                    <form onSubmit={onFormSubmit()}>
                        <div className={`container-full modal-container__box__content`}>
                            <div className={`container-full modal-container__box__content__desc`}>
                                <Text value='Set up new card to collect payments.' />
                            </div>
                            <div className={`container-full modal-container__box__content__group`}>
                                <div className={`container-full modal-container__box__content__group__item`}>
                                    <div className={`div-md-6`}>
                                        <p> Bank Name </p>
                                        <p> {data.issuer.issName} </p>
                                        <div className='modal-container__box__content__group__item__label' onClick={() => getAccountName()}>
                                            <Text value='Get Account Name' />
                                        </div>
                                    </div>
                                    <div className={`div-md-6`}>
                                        <p> Account Number </p>
                                        <p> {data.accountNumber} </p>
                                        {
                                            (data.issuer.issName !== "") ?
                                                <>
                                                    {
                                                        (!accountError && accountName !== "" && !checking) ? <h4 className="info"> {accountName} </h4> : null
                                                    }
                                                    {
                                                        (accountError && !checking) ? <h4 className='error'> Account Name Retreival Error </h4> : null
                                                    }
                                                </> : null
                                        }
                                    </div>
                                </div>
                                <div className={`container-full modal-container__box__content__group__item`}>
                                    <div className={`div-sm-12`}>
                                        <p> Default Processor </p>
                                        {
                                            data.mappedProcessors && data.mappedProcessors.map((processor: Record<any, any>, key: number) => {
                                                if (processor.id === data.defProcessor) {
                                                    return (<p key={key}> {processor.name} </p>)
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                                <div className={`container-full modal-container__box__content__group__item`}>
                                    <div className={`div-sm-12`}>
                                        <p> Fee Category </p>
                                        <div className='input'>
                                            <select required
                                                name="feeCategory"
                                                onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                                                value={fields.feeCategory}
                                            >
                                                <option value="" hidden> Select Fee Category </option>
                                                {
                                                    (feeCategory.length > 0 && feeCategory.map((feeCategory: Record<any, any>) => {
                                                        return (<option value={`${feeCategory.id}`} key={feeCategory.id}> {feeCategory.name} </option>)
                                                    }))
                                                }
                                            </select>
                                            <Image image={caret} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`container-full modal-container__box__processor`}>
                            <div className={`container-full modal-container__box__processor__desc`}>
                                <HeaderText value='Processor Connection Details' />
                            </div>
                            <div className={`container-full modal-container__box__processor__group`}>
                                <div className={`container-full modal-container__box__processor__group__item`}>
                                    <p> Processor <span> *</span></p>
                                    <div className='input'>
                                        <select required
                                            name="processor"
                                            onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                                            value={fields.processor}
                                        >
                                            <option value="" hidden> Select a Processor </option>
                                            {
                                                (processors.length > 0 && processors.map((processor: Record<any, any>) => {
                                                    return (<option value={processor.id} key={processor.id}> {processor.name} </option>)
                                                }))
                                            }
                                        </select>
                                        <Image image={caret} />
                                    </div>
                                </div>
                                {
                                    fields.processor === "1" ?
                                        <div className={`container-full modal-container__box__processor__group__item`}>
                                            <div className={`div-md-6`}>
                                                <p> Terminal ID <span> *</span></p>
                                                <Input type='text' className="input" placeholder="Enter Terminal ID" required
                                                    name="terminalID" value={fields.terminalID} onChange={(e) => inputChange(e.target.name, e.target.value)}
                                                />
                                            </div>
                                            <div className={`div-md-6`}>
                                                <p> Encryption Key <span> *</span></p>
                                                <div className='password'>
                                                    <Input type='password' className="input" placeholder="Enter Encryption Key" required
                                                        name="encryptionKey" value={fields.encryptionKey} onChange={(e) => inputChange(e.target.name, e.target.value)}
                                                        onPaste={(event: React.FormEvent) => { event.preventDefault() }}
                                                        onCopy={(event: React.FormEvent) => { event.preventDefault() }}
                                                        onCut={(event: React.FormEvent) => { event.preventDefault() }}
                                                    />
                                                    <img
                                                        src={eyes}
                                                        onClick={toggleState()}
                                                    />
                                                </div>
                                            </div>
                                        </div> : null
                                }
                            </div>
                        </div>
                        <div className={`container-full modal-container__box__processor`}>
                            <div className={`container-full modal-container__box__processor__desc`}>
                                <HeaderText value='Update Final Authorizers' />
                            </div>
                            <div className={`container-full modal-container__box__processor__group`}>
                                <div className={`container-full modal-container__box__processor__group__users`}>
                                    <div className={`container-full modal-container__box__processor__group__users__item --add-user`} onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
                                        <button type="button" className={`button --elipses`} onClick={() => onClickHandler(true)} aria-haspopup="true" aria-expanded={selectedMenu}>
                                            <Text value='Select a User' />
                                            <Image image={dropdown} />
                                        </button>
                                        {
                                            selectedMenu !== false && (
                                                <div className={`container-full more-info`}>
                                                    {
                                                        usersList.length > 0 ?
                                                            usersList.map((user: Record<any, any>, key: number) => {
                                                                return (
                                                                    <div className={`container-full more-info__item
                                                                        ${(users.some((userData: Record<any, any>) => userData.id === user.id)) ? 'active' : ''
                                                                        }
                                                                        `} key={key}>
                                                                        <button type="button" onClick={() => toggleUsers(user)}>
                                                                            <Text value={`${user.firstName} ${user.lastName}`} />
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }) : ''
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                    {
                                        (users && users.length > 0 && users.map((user: Record<any, any>, key: string) => {
                                            return (
                                                <div className={`container-full modal-container__box__processor__group__users__item`} key={key}>
                                                    <div className={`modal-container__box__processor__group__users__item__number`}>
                                                        <Text value={key += 1} />
                                                    </div>
                                                    <div className={`modal-container__box__processor__group__users__item__name`}>
                                                        <Text value={`${user.firstName} ${user.lastName}`} />
                                                    </div>
                                                    <div className={`modal-container__box__processor__group__users__item__close`} onClick={() => removeUser(user)}>
                                                        <Image image={closeIcon} />
                                                    </div>
                                                </div>
                                            )
                                        }))
                                    }

                                </div>
                            </div>
                        </div>
                        <div className={`container-full center modal-container__box__action`}>
                            <Button type="button" value="Cancel" className={`button --cancel`} onClick={() => handleClose()} />
                            <Button type="submit" value="Update" className={`button --change ${users.length > 0 ? '--active-btn' : ''
                                }`} isLoading={state.submitReducer.fetching} />
                        </div>
                    </form>
                </div>
            </div>
        </UpdateSourceAccountModalStyle>
    );
}

export default UpdateSourceAccountModal;