export const STEP_1 = 'STEP_1';
export const STEP_CLEAR = 'STEP_CLEAR';

export interface StepOne {
    type: typeof STEP_1,
    data: object
}

export interface StepClear {
    type: typeof STEP_CLEAR
}

export type StepDispatchTypes = StepOne | StepClear