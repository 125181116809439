import React, { useEffect, useState } from 'react';
import DashboardHeader from '../../organisms/DashboardHeader/DashboardHeader';
import DashboardMenu from '../../organisms/DashboardMenu/DashboardMenu';
import MainLayoutTemplateStyle from './MainLayoutTemplateStyle';
import RoutePaths from '../../../routes';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../config/ConfigStore';
import cogoToast from "cogo-toast";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import historyObject from '../../../config/History';


const MainLayoutTemplate = () => {
  const state = useSelector((state: RootStore) => state);
  const navigate = useNavigate();

  useEffect(() => {
    if (state.alertReducer.type === "alert-success") {
      cogoToast.success(state.alertReducer.message, { position: "top-right" })
    }
    if (state.alertReducer.type === "alert-danger") {
      if (typeof state.alertReducer.message === 'string') {
        cogoToast.error(state.alertReducer.message, { position: "top-right" })
      }
      if (!Array.isArray(state.alertReducer.message) && typeof state.alertReducer.message === 'object') {
        Object.keys(state.alertReducer.message).map((message: any) => {
          window.setTimeout(() => {
            cogoToast.error(state.alertReducer.message[message], { position: "top-right" })
          })
        })
      }
      if (Array.isArray(state.alertReducer.message)) {
        state.alertReducer.message.map((error: Record<any, any>) => {
          window.setTimeout(() => {
            cogoToast.error(error.messageError, { position: "top-right" })
          })
        })
      }
    }
  }, [state.alertReducer])

  useEffect(() => {
    if (state.authReducer.results.force_password_change) {
      navigate('/settings/profile');
    }
  }, [])

  /* code for automatic logging out */
  const events = [
    "load",
    "mousemove",
    "touchmove",
    "mousedown",
    "touchstart",
    "click",
    "scroll",
    "keypress",
    "keydown"
  ];
  let timer: any;

  let logoutTime = state.authReducer.results.idle_time_out * 60000;
  console.log(logoutTime);

  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      events.forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logoutAction();
    }, logoutTime);
  };


  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const logoutAction = async () => {
    await Cookies.remove("userToken");
    historyObject.push("/");
  };

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after mins of inactivity resets.
  // However, if none of the event is triggered within mins, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    events.forEach((item) => {
      window.addEventListener(item, () => {
        console.log("kffk")
        resetTimer();
        handleLogoutTimer();
      });
    });

    return () => {
      clearTimeout(timer);
      events.forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
    }
  }, []);

  return (
    <MainLayoutTemplateStyle>
      <div className={`container-full dashboard`}>
        <div className={`dashboard__menu`}>
          <DashboardMenu />
        </div>
        <div className={`dashboard__content`}>
          <DashboardHeader />
          <RoutePaths />
        </div>
      </div>
    </MainLayoutTemplateStyle>
  );
};

export default MainLayoutTemplate;
