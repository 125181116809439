import React, { useState, useEffect, useRef } from 'react';
import MerchantStepTwoStyle from './MerchantStepTwoStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import { Link, useNavigate } from 'react-router-dom';
import nike from '../../../assets/images/nike.svg';
import MerchantProgressBar from '../MerchantProgressBar/MerchantProgressBar';
import Input from '../../atoms/Input/Input';
import caret from '../../../assets/images/caret-left-new.svg';
import Button from '../../atoms/Button/Button';
import fileIcon from '../../../assets/images/subtract-new.svg';
import fileIconAdd from '../../../assets/images/file-add.svg';
import crossCircle from '../../../assets/images/cross-circle.svg';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { stepTwo } from '../../../redux/actions/StepAction';
import { SubmitActionsUpload } from '../../../redux/actions/SubmitAction';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';


const MerchantStepTwo = () => {
    let timeOutId: any = null;
    const [selectedMenu, setSelectedMenu] = useState(false);
    const [fields, setFields] = useState({
        name: '',
        description: ''
    })
    const [uploadedFiles, setUploadedFiles]: any = useState([])
    const dispatch: TypedDispatch = useDispatch();
    const state = useSelector((state: RootStore) => state);
    const navigate = useNavigate();
    const [fetchLoading, setFetchLoading] = useState(false);

    useEffect(() => {
        if (state.submitReducer.results) {
            let data = {
                name: fields.name,
                description: fields.description,
                fileUri: state.submitReducer.results.data.fileUri
            }
            let newArr = [
                ...uploadedFiles,
                data
            ]
            console.log(newArr);
            setUploadedFiles(newArr);
        }
    }, [state.submitReducer])

    useEffect(() => {
        if (state.stepReducer.results.stepTwo.length > 0) {
            setUploadedFiles(state.stepReducer.results.stepTwo);
        }
        if (Object.keys(state.stepReducer.results.stepOne).length < 1) {
            navigate('/merchants/create/step-one');
        }
    }, [])

    console.log(state);

    const inputChange = async (name: string, value: string) => {
        const fieldsValues: any = Object.assign({}, fields);
        fieldsValues[name] = value;
        await setFields(fieldsValues);
    };


    const onClickHandler = (value: boolean) => {
        if (selectedMenu !== false) {
            setSelectedMenu(false);
            return;
        }
        setSelectedMenu(value);
    }

    const onBlurHandler = () => {
        timeOutId = setTimeout(() => {
            // setSelectedMenu(false);
        });
    }

    const onFocusHandler = () => {
        clearTimeout(timeOutId);
    }

    const fileUpload = async (file: any) => {
        setSelectedMenu(false);
        setFetchLoading(true);
        let value = {
            file,
            fileType: 'KYC'
        }
        await dispatch(SubmitActionsUpload('/files/upload/', value));
        setFields({
            name: '',
            description: ''
        })
        setFetchLoading(false);
    }

    const renderFetchLoading = () => {
        if (fetchLoading) {
            return (
                <FetchLoading />
            )
        }
    }

    const onFormSubmit = async () => {
        if (uploadedFiles.length > 0) {
            await dispatch(stepTwo(uploadedFiles));
            navigate('/merchants/create/step-three')
            console.log(state);
        }
    }

    const removeItem = (fileUri: string) => {
        let newArr = uploadedFiles.filter((file: Record<any, any>) => file.fileUri !== fileUri);
        setUploadedFiles(newArr);
    }

    return (
        <>
            {renderFetchLoading()}
            <MerchantStepTwoStyle className={`container-full`}>
                <div className={`container-full merchants__body`}>
                    <MerchantProgressBar />
                    <div className={`container-full merchants__body__form`}>
                        <div className={`div-sm-12 merchants__body__form__desc`}>
                            <Text value='Step 2:' className='step' />
                            <HeaderText value='KYC Documents' className='header' />
                            <Text value='Enter company details to create merchant' className='desc' />
                        </div>
                        <div className={`container-full merchants__body__form__form`}>
                            <div className={`div-md-9 merchants__body__form__form__files`}>
                                <div className='container-full'>
                                    {
                                        (uploadedFiles.length > 0) ? uploadedFiles.map((file: Record<any, any>, key: string) => {
                                            return (
                                                <div className='merchants__body__form__form__files__item' key={key}>
                                                    <Text value={file.name} className='file-name' />
                                                    <div className='merchants__body__form__form__files__item__box'>
                                                        <div className='merchants__body__form__form__files__item__box__inner'>
                                                            <div className='merchants__body__form__form__files__item__box__inner__img --file' >
                                                                <Image image={fileIcon} className="add" />
                                                                <Image image={crossCircle} onClick={() => removeItem(file.fileUri)} className="close" />
                                                            </div>
                                                            <div className='merchants__body__form__form__files__item__box__inner__text'>
                                                                <Text value={file.name} className='file-name' />
                                                                <Text value='PDF' className='file-type' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : null
                                    }
                                </div>
                            </div>
                            <div className={`div-md-3 merchants__body__form__form__add-file`}>
                                <div className='merchants__body__form__form__files__item'>
                                    <Text value='Select File' className='file-name ' />
                                    <div onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
                                        <button onClick={() => onClickHandler(true)} aria-haspopup="true" aria-expanded={selectedMenu}>
                                            <div className={`merchants__body__form__form__files__item__box `}>
                                                <div className='merchants__body__form__form__files__item__box__inner'>
                                                    <div className='merchants__body__form__form__files__item__box__inner__img'>
                                                        <Image image={fileIconAdd} />
                                                    </div>
                                                    <div className='merchants__body__form__form__files__item__box__inner__text'>
                                                        <p className='upload-desc'> Upload your files here... <span> browse </span> </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                        {
                                            selectedMenu !== false && (
                                                <div className={`container-full more-info`}>
                                                    <div className={`container-full more-info__item`}>
                                                        <Text value='File Name' />
                                                        <Input type="text" className='input' placeholder='Enter file name' name="name" onChange={(evt) => inputChange(evt.target.name, evt.target.value)} />
                                                    </div>
                                                    <div className={`container-full more-info__item`}>
                                                        <Text value='File Description' />
                                                        <Input type="text" className='input' placeholder='Enter file description' name="description" onChange={(evt) => inputChange(evt.target.name, evt.target.value)} />
                                                    </div>
                                                    <div className={`container-full more-info__item`}>
                                                        <Text value='Select File' />
                                                        <Input type="file" id="file" className={`input ${(fields.name !== '' && fields.description !== '') ? null : 'disabled'
                                                            }`} onChange={(event) => fileUpload(event.target.files[0])} accept="application/pdf" />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`container-full merchants__action`}>
                    <Link to="/merchants/create/step-one">
                        <button className='button --back'>
                            <Image image={caret} />
                            Back
                        </button>
                    </Link>
                    <div>
                        <Button value="Skip" className='button --skip' onClick={() => navigate('/merchants/create/step-three')} />
                        <Button value="Save &amp; Continue" className='button --save' onClick={() => onFormSubmit()} />
                    </div>
                </div>
            </MerchantStepTwoStyle>
        </>
    );
}

export default MerchantStepTwo;