import React, { useEffect, useState } from 'react';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import MerchantMappedAccountsStyle from './MerchantMappedAccountsStyle';
import elipses from '../../../assets/images/elipses.svg';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { useNavigate } from "react-router-dom";
import numberIcon from '../../../assets/images/number-icon.svg';
import houseIcon from '../../../assets/images/house-icon.svg';
import logo from '../../../assets/images/logo-blue.svg';
import UpdateSourceAccountModal from '../UpdateSourceAccountModal/UpdateSourceAccountModal';
import { alertActions } from '../../../redux/actions/AlertActions';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';

type UserTableProps = {
  data: any,
  pagination: number,
  setPagination: Function,
  changePage: Function,
  page: number,
  reloadPage: Function
}

const MerchantMappedAccounts = ({ data, reloadPage }: UserTableProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  let timeOutId: any = null;
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [toggleUpdateSourceAccountModal, setToggleUpdateSourceAccountModal] = useState(false);
  const [accountData, setAccountData] = useState({});
  const [processors, setProcessors] = useState([]);
  console.log(processors);

  useEffect(() => {
    fetchProcessors();
  }, [])

  const onClickHandler = (value: boolean, id: string) => {
    if (selectedMenu !== false) {
      setSelectedMenu(false);
      return;
    }
    setSelectedMenu(value);
    setSelectedId(id)
  }

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
      setSelectedMenu(false);
    });
  }

  const onFocusHandler = () => {
    clearTimeout(timeOutId);
  }

  const fetchProcessors = async () => {
    try {
        const res = await axios.get(`/processors?size=0&page=1`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get("userToken")}`
            }
        });
        setProcessors(res.data.data);
    } catch (error: any) {
        if (error.message === "Network Error") {
            dispatch(alertActions.error('Network Error, try again!!!'));
        } else {
            dispatch(alertActions.error(error.response.data.error));
        }
    }
  }

  const fetchAccount = async (accountNumber: string) => {
    try {
        const res = await axios.get(`/account?${window.location.href.split("?")[1]}&accountNumber=${accountNumber}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get("userToken")}`
            }
        });
        setAccountData(res.data.data.content[0]);
    } catch (error: any) {
        if (error.message === "Network Error") {
            dispatch(alertActions.error('Network Error, try again!!!'));
        } else {
            dispatch(alertActions.error(error.response.data.error));
        }
    }
  }

  const showToggleUpdateSourceAccountModal = async (accountNumber: string) => {
    await fetchAccount(accountNumber);
    setToggleUpdateSourceAccountModal(true);
  }

  const hideToggleUpdateSourceAccountModal = () => {
    setToggleUpdateSourceAccountModal(false);
  }

  const renderUpdateSourceAccountModal = () => {
    if(toggleUpdateSourceAccountModal){
      return(
        <UpdateSourceAccountModal handleClose={hideToggleUpdateSourceAccountModal} reloadPage={reloadPage} data={accountData} />
      )
    }
  }


  return (
    <>
      {renderUpdateSourceAccountModal()}
      <MerchantMappedAccountsStyle>
        <div>
          {
            (data.data.content !== undefined && data.data.content.length > 0) ?
              data.data.content.map((account: Record<any, any>, key: string) => {
                return (
                  <div className={`container-full merchants`} key={key}>
                    <div className='div-md-3 merchants__card'>
                      <div className={`container-full merchants__card__details`}>
                        <Image image={numberIcon} />
                        <HeaderText value={account.accountNumber} />
                      </div>
                      <div className={`container-full merchants__card__details`}>
                        <Image image={houseIcon} />
                        <HeaderText value={account.issuer && account.issuer.issName} />
                      </div>
                      <div className={`container-full merchants__card__logo`}>
                        <Image image={logo} />
                      </div>
                    </div>
                    <div className={`div-md-8 merchants__details`}>
                      <div className={`container-full merchants__details__group`}>
                        <div className={`div-md-4 merchants__details__group__item`}>
                          <Text value='Validated Account Name' className='title' />
                          <Text value={account.validatedName} className='value' />
                        </div>
                        <div className={`div-md-4 merchants__details__group__item`}>
                          <Text value='Created By' className='title' />
                          <Text value={account.createdBy} className='value' />
                          
                        </div>
                        <div className={`div-md-4 merchants__details__group__item`}>
                          <Text value='Default Processor' className='title' />
                          {
                            processors && processors.map((processor: Record<any, any>, key: number) => {
                                if(processor.id === account.defProcessor){
                                    return(<p className='value' key={key}> {processor.name} </p>)
                                }
                            })
                          }
                        </div>
                      </div>
                      <div className={`container-full merchants__details__group --last`}>
                        <div className={`div-md-4 merchants__details__group__item`}>
                        </div>
                        <div className={`div-md-4 merchants__details__group__item`}>
                          <Text value='Fee Category' className='title' />
                          <Text value={account.feeCategory && account.feeCategory.catName} className='value' />
                        </div>
                        <div className={`div-md-4 merchants__details__group__item`}>
                          <Text value='Date Mapped' className='title' />
                          <p className='value'> {dayjs(account.createdDate).format('MM/DD/YYYY')} <span> {dayjs(account.createdDate).format('h:mm A')} </span></p>
                        </div>
                      </div>
                    </div>
                    <div className={`div-md-1 merchant__action`}>
                      <div className={`container-full`} onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
                        <button className={`button --elipses`} onClick={() => onClickHandler(true, account.id)} aria-haspopup="true" aria-expanded={selectedMenu}>
                          <Image image={elipses} />
                        </button>
                        {
                          selectedMenu !== false && selectedId === account.id && (
                            <div className={`container-full more-info`}>
                              <div className={`container-full more-info__item`}>
                                <button onClick={() => { showToggleUpdateSourceAccountModal(account.accountNumber) }}>
                                  <Text value='Edit Card' />
                                </button>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                )
              }) : null
          }

        </div> 
      </MerchantMappedAccountsStyle>
    </>
  );
}

export default MerchantMappedAccounts;
