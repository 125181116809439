import React, { useState, useEffect } from 'react';
import MerchantsTemplateStyle from './MerchantsTemplateStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import Input from '../../atoms/Input/Input';
import exportIcon from '../../../assets/images/download-icon.svg';
import plusIcon from '../../../assets/images/plus-icon-white.svg';
import filterIcon from '../../../assets/images/filter-icon.svg';
import plus from '../../../assets/images/plus-icon.svg';
import MerchantsTable from '../../organisms/MerchantsTable/MerchantsTable';
import nike from '../../../assets/images/nike.svg';
import Button from '../../atoms/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { DashboardActions } from '../../../redux/actions/DashboardAction';
import { exportAll, exportClear } from '../../../redux/actions/ExportAction';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { addDays, format } from 'date-fns';
import { DateRange, DayPicker } from 'react-day-picker';
import caret from '../../../assets/images/caret-filter-select.svg';

const pastMonth = new Date();

const MerchantsTemplate = () => {
  let timeOutId: any = null;
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState<string | null>(null);
  const dispatch: TypedDispatch = useDispatch();
  const state: any = useSelector((state: RootStore) => state);
  console.log(state);
  const navigate = useNavigate();
  const [filterValues, setFilterValues] = useState<any>({});
  const [fields, setFields] = useState({
    dateRange: '',
    rcNumber: '',
    status: ''
  });

  const onClickHandler = (value: boolean) => {
    if (selectedMenu !== false) {
      setSelectedMenu(false);
      return;
    }
    setSelectedMenu(value);
  }

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
      setSelectedMenu(false);
    });
  }

  const onFocusHandler = () => {
    clearTimeout(timeOutId);
  }

  useEffect(() => {
    dispatch(exportAll(`/merchant?size=0&page=1${status !== null ? `&status=${status}` : ''}`));
  }, [])

  useEffect(() => {
    dispatch(exportClear());
    if (status !== "2") {
      generatePageContent();
    }
    console.log(state);
  }, [size, page, reloadPage, status])

  const generatePageContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/merchant?size=${size}&page=${page}&businessName=${search}${status !== null ? `&status=${status}` : ''}`));
    setFetchLoading(false);
  }

  const renderFetchLoading = () => {
    if (fetchLoading) {
      return (
        <FetchLoading />
      )
    }
  }

  const changePage = (value: number) => {
    setPage(value);
  }

  const changeSize = (value: number) => {
    setSize(value);
  }

  const exportData = () => {
    return {
      fileData: () => {
        let merchants = state.exportReducer.results.data.map((merchant: Record<any, any>) => {
          return (
            {
              MerchantName: merchant.businessName,
              BusinessEmail: merchant.businessEmail,
              BusinessPhone: merchant.businessPhoneNumber,
              DateCreated: dayjs(merchant.createdDate).format('MM/DD/YYYY'),
              LastActive: dayjs(merchant.lastLoginDate).format('MM/DD/YYYY'),
            }
          )
        })
        let data = [...merchants];
        return data
      },
      fileHeaders: () => {
        let headers = [
          { label: "Merchant Name", key: "MerchantName" },
          { label: "Business Email", key: "BusinessEmail" },
          { label: "Business Phone", key: "BusinessPhone" },
          { label: "Date Created", key: "DateCreated" },
          { label: "Last Active", key: "LastActive" },
        ];
        return headers;
      }
    }
  }

  const exportDataAll = () => {
    return {
      fileData: () => {
        let merchants = state.exportReducer.allResults.data.map((merchant: Record<any, any>) => {
          return (
            {
              MerchantName: merchant.businessName,
              BusinessEmail: merchant.businessEmail,
              BusinessPhone: merchant.businessPhoneNumber,
              DateCreated: dayjs(merchant.createdDate).format('MM/DD/YYYY'),
              LastActive: dayjs(merchant.lastLoginDate).format('MM/DD/YYYY'),
            }
          )
        })
        let data = [...merchants];
        return data
      },
      fileHeaders: () => {
        let headers = [
          { label: "Merchant Name", key: "MerchantName" },
          { label: "Business Email", key: "BusinessEmail" },
          { label: "Business Phone", key: "BusinessPhone" },
          { label: "Date Created", key: "DateCreated" },
          { label: "Last Active", key: "LastActive" },
        ];
        return headers;
      }
    }
  }

  const refreshTable = () => {
    if (reloadPage) {
      return setReloadPage(false);
    }
    setReloadPage(true);
  }

  const defaultSelected: DateRange = {
    from: pastMonth,
    to: addDays(pastMonth, 4)
  };
  const [range, setRange] = useState<DateRange | any>(defaultSelected);

  let footer = <p>Please pick the first day.</p>;
  if (range !== undefined && range.from) {
    if (!range.to) {
      footer = <p>{format(range.from, 'PPP')}</p>;
    } else if (range.to) {
      footer = (
        <p>
          {format(range.from, 'PPP')}–{format(range.to, 'PPP')}
        </p>
      );
    }
  }

  const changeFilterValue = async (event: any, name: string, value: string) => {
    if (event.target.checked) {
      const filterValuesObj: any = Object.assign({}, filterValues);
      filterValuesObj[name] = value;
      await setFilterValues(filterValuesObj);
    }
    else {
      const filterValuesObj: any = Object.assign({}, filterValues);
      delete filterValuesObj[name]
      await setFilterValues(filterValuesObj);
    }
  }

  const inputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues[name] = value;
    await setFields(fieldsValues);
  };

  const fetchFilteredContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/merchant?size=${size}&page=${page}${filterValues.status && fields.status !== '' ? `&status=${fields.status}` : ''}${filterValues.rcNumber && fields.rcNumber !== '' ? `&rcNumber=${fields.rcNumber}` : ''}${filterValues.dateRange && range ? `&createdDateFrom=${dayjs(range.from).format("YYYY-MM-DD HH:mm:ss")}` : ''}${filterValues.dateRange && range ? `&createdDateTo=${dayjs(range.to).format("YYYY-MM-DD HH:mm:ss")}` : ''}`));
    setFetchLoading(false);
    setSelectedMenu(false);
  }

  const filterByStatus = async (status: string | null) => {
    await setPage(1);
    setStatus(status);
  }

  const fetchPendingRecords = async () => {
    setFetchLoading(true);
    setStatus("2")
    await dispatch(DashboardActions(`/merchant?size=${size}&page=${page}&status=2&status=3&status=4&status=5`));
    setFetchLoading(false);
  }

  return (
    <>
      {renderFetchLoading()}
      <MerchantsTemplateStyle>
        <div className={`container-full merchants`}>
          <div className={`container-full merchants__desc`}>
            <HeaderText value="Merchants" />
          </div>
          <div className='container-full merchants__action'>
            <div className={`merchants__action__nav`}>
              <div className={`merchants__action__nav__item ${status === null ? '--active-nav' : null
                }`} onClick={() => filterByStatus(null)}>
                <Text value={`All ${(state.dashboardReducer.results && status === null) ? `(${state.dashboardReducer.results.data.totalElements})` : ''} `} />
              </div>
              <div className={`merchants__action__nav__item ${status === "1" ? '--active-nav' : null
                }`} onClick={() => filterByStatus("1")}>
                <Text value={`Active ${(state.dashboardReducer.results && status === "1") ? `(${state.dashboardReducer.results.data.totalElements})` : ''} `} />
              </div>
              <div className={`merchants__action__nav__item ${status === "0" ? '--active-nav' : null
                }`} onClick={() => filterByStatus("0")}>
                <Text value={`Disabled ${(state.dashboardReducer.results && status === "0") ? `(${state.dashboardReducer.results.data.totalElements})` : ''} `} />
              </div>
              <div className={`merchants__action__nav__item ${status === "2" ? '--active-nav' : null
                }`} onClick={() => fetchPendingRecords()}>
                <Text value={`Pending ${(state.dashboardReducer.results && status === "2") ? `(${state.dashboardReducer.results.data.totalElements})` : ''} `} />
              </div>
            </div>
            <div className={`merchants__action__search`}>
              <Input className={`input`} type="text" placeholder="Search by merchant name"
                onChange={(event) => setSearch(event.target.value)}
                onKeyPress={event => {
                  if (event.key === 'Enter' || event.which === 13 || event.keyCode === 13) {
                    generatePageContent();
                  }
                }}
              />
              <div className='btn-group'>
                {
                  (state.exportReducer.results.data.length > 0) ?
                    <CSVLink data={exportData().fileData()} headers={exportData().fileHeaders()} filename={"merchants.csv"}>
                      <button className='button --export'>
                        <Image image={exportIcon} />
                      </button>
                    </CSVLink> :
                    <CSVLink data={exportDataAll().fileData()} headers={exportDataAll().fileHeaders()} filename={"merchants.csv"}>
                      <button className='button --export'>
                        <Image image={exportIcon} />
                      </button>
                    </CSVLink>
                }
                <button className={`button --user ${(state.authReducer.results.roles.includes('Authorizer') && state.authReducer.results.roles.length === 1)
                  || (state.authReducer.results.roles.includes('Audit') && state.authReducer.results.roles.length === 1) ? '--disabled' : ''
                  }`} onClick={() => navigate('/merchants/create/step-one')}>
                  <Image image={plusIcon} />
                </button>
              </div>
            </div>
          </div>
          <div className={`container-full merchant__filter`}>
            <div className={`merchant__filter__item --filter`}>
              <div className={`--filter__box`}>
                <Image image={filterIcon} alt="filter-icon" />
              </div>
              <Text value='Filter' />
            </div>
            {/* <div className={`merchant__filter__item --active-filter`}>
                <Text value='No active filters' />
              </div> */}
            <div className={`merchant__filter__item --add-filter`} onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
              <button className={`button --elipses`} onClick={() => onClickHandler(true)} aria-haspopup="true" aria-expanded={selectedMenu}>
                <Image image={plus} />
                <Text value='Add filter' />
              </button>
              {
                selectedMenu !== false && (
                  <button className='unset'>
                    <div className={`container-full more-info`}>
                      <div className={`container-full more-info__top`}>
                        <div className={`div-md-4 more-info__top__item`}>
                          <div className={`container-full more-info__top__item__desc`}>
                            <div className={`more-info__top__item__desc__checkbox`}>
                              <input
                                type="checkbox"
                                name="dateRange"
                                onChange={(event) => changeFilterValue(event, event.target.name, event.target.value)}
                                defaultChecked={(filterValues.dateRange) ? true : false}
                              />
                              <Image image={nike} />
                            </div>
                            <Text value='Date Range' />
                          </div>
                          <div className={`container-full more-info__top__item__input`}>
                            <Input type="text" className={`input ${(filterValues.dateRange === undefined) ? 'disabled' : null
                              }`}
                              placeholder="Select Date Range"
                              value={(range) ? `${dayjs(range.from).format("YYYY-MM-DD")} to ${dayjs(range.to).format("YYYY-MM-DD")}` : ''}
                              name="dateRange"
                              onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                              readOnly
                            />
                            {
                              (filterValues.dateRange) ?
                                <DayPicker
                                  mode="range"
                                  defaultMonth={pastMonth}
                                  selected={range}
                                  footer={footer}
                                  modifiersStyles={{
                                    selected: {
                                      background: 'blue',
                                      color: 'white',
                                      fontWeight: 'bold',
                                    },
                                  }}
                                  onSelect={setRange}
                                  style={{ position: "absolute", left: "0", marginTop: "40px", background: "white", boxSizing: "border-box", padding: "35px", boxShadow: "0px 15px 44px rgba(10, 18, 73, 0.15)" }}
                                  styles={{
                                    day: { boxSizing: "border-box", padding: '10px', borderRadius: "8px", fontFamily: "Mulish", },
                                    caption: { fontFamily: "Mulish" },
                                  }}
                                /> : null
                            }
                          </div>
                        </div>
                        <div className={`div-md-4 more-info__top__item`}>
                          <div className={`container-full more-info__top__item__desc`}>
                            <div className={`more-info__top__item__desc__checkbox`}>
                              <input
                                type="checkbox"
                                name="rcNumber"
                                onChange={(event) => changeFilterValue(event, event.target.name, event.target.value)}
                                defaultChecked={(filterValues.rcNumber) ? true : false}
                              />
                              <Image image={nike} />
                            </div>
                            <Text value='RC Number' />
                          </div>
                          <div className={`container-full more-info__top__item__input`}>
                            <Input type="text"
                              className={`input ${(filterValues.rcNumber === undefined) ? 'disabled' : null
                                }`}
                              placeholder="Enter RC Number"
                              name="rcNumber"
                              value={fields.rcNumber}
                              onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                            />
                          </div>
                        </div>
                        <div className={`div-md-4 more-info__top__item`}>
                          <div className={`container-full more-info__top__item__desc`}>
                            <div className={`more-info__top__item__desc__checkbox`}>
                              <input
                                type="checkbox"
                                name="status"
                                onChange={(event) => changeFilterValue(event, event.target.name, event.target.value)}
                                defaultChecked={(filterValues.status) ? true : false}
                              />
                              <Image image={nike} />
                            </div>
                            <Text value='Merchant Status' />
                          </div>
                          <div className={`container-full more-info__top__item__input`}>
                            <div className='input'>
                              <select required
                                className={
                                  `${(filterValues.status === undefined) ? 'disabled' : null}`
                                }
                                name="status"
                                value={fields.status}
                                onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                              >
                                <option value="" hidden> Select Merchant Status </option>
                                <option value="1"> Active </option>
                                <option value="0"> Disabled </option>
                                <option value="2"> Pending </option>
                                <option value="3"> Pending update enabled </option>
                                <option value="4"> Pending update disabled </option>
                                <option value="5"> Rejected pending </option>
                              </select>
                              <Image image={caret} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`container-full more-info__bottom`}>
                        <Button value="Cancel" className='button --cancel' onClick={() => setSelectedMenu(false)} />
                        <Button value="Apply Filters" className={`button --apply`} onClick={() => fetchFilteredContent()} />
                      </div>
                    </div>
                  </button>
                )
              }
            </div>
          </div>
          <div className='container-full merchants__table'>
            {
              (state.dashboardReducer.results !== null) ?
                <MerchantsTable data={state.dashboardReducer.results} pagination={size} setPagination={changeSize} changePage={changePage} page={page} reloadPage={refreshTable} /> : null
            }
          </div>
        </div>
      </MerchantsTemplateStyle>
    </>
  );

};

export default MerchantsTemplate;
