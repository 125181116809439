export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_FAILED = 'AUTH_FAILED';
export const AUTH_USER = 'AUTH_USER';

export interface AuthRequest {
    type: typeof AUTH_REQUEST
}

export interface AuthFailed {
    type: typeof AUTH_FAILED
}

export interface AuthUser {
    type: typeof AUTH_USER,
    response: {
        data: any
    }
}

export type AuthDispatchTypes = AuthRequest | AuthFailed | AuthUser 