import React, { useState, useEffect } from 'react';
import InitiatePaymentModalStyle from './InitialPaymentModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import Input from '../../atoms/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { SubmitActionsPOST } from '../../../redux/actions/SubmitAction';
import { alertActions } from '../../../redux/actions/AlertActions';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';
import closeIcon from '../../../assets/images/close-icon-round.svg';
import dropdown from '../../../assets/images/dropdown.svg';
import nike from '../../../assets/images/nike.svg';
import InitiatePaymentStepOne from '../../molecules/InitiatePaymentStepOne/InitiatePaymentStepOne';
import InitiatePaymentStepTwo from '../../molecules/InitiatePaymentStepTwo/InitiatePaymentStepTwo';
import InitiatePaymentStepThree from '../../molecules/InitiatePaymentStepThree/InitiatePaymentStepThree';


type InitiatePaymentModalProps = {
  handleClose: Function,
  reloadPage: Function
}

const InitiatePaymentModal = ({ handleClose, reloadPage }: InitiatePaymentModalProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const [step, setStep] = useState(1);
  const state = useSelector((state: RootStore) => state);
  const [fields, setFields] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    bankCode: '',
    bankType: ''
  })
  const [showUploadProgress, setShowUploadProgress] = useState(false);

  const changeStep = (stepNumber: number) => {
    setStep(stepNumber);
    console.log(step)
  }

  const toggleUploadProgress = (value: boolean) => {
    setShowUploadProgress(value);
  }

  return (
    <InitiatePaymentModalStyle className={`container-full`}>
      <div className={`container-full center modal-container`}>
        <div className={`modal-container__box`}>
          {
            showUploadProgress && 
            <div className={`container-full modal-container__box__loading`}>
              <div className={`modal-container__box__loading__progress-bar`}></div>
            </div>
          }
          <div className={`container-full modal-container__box__header`}>
            <HeaderText value='Initiate Payment' />
            <Image image={close} onClick={() => handleClose()} />
          </div>
          <div className={`container-full modal-container__box__progress`}>
            <div className={`modal-container__box__progress__item ${(step !== 1) ? '--active' : null
              }`}>
              <div className={`modal-container__box__progress__item__checkbox`}>
                <input type="checkbox" className={`input disabled`}
                  checked={step !== 1 ? true : false}
                />
                <div className={`modal-container__box__progress__item__checkbox__box ${step === 1 ? '--current-step' : null
                  }`}>
                  <Image image={nike} />
                </div>
              </div>
            </div>
            <div className={`modal-container__box__progress__item --last-item ${step === 3 ? '--active' : null
              }`}>
              <div className={`modal-container__box__progress__item__checkbox`}>
                <input type="checkbox" className={`input disabled`}
                  checked={step === 3 ? true : false}
                />
                <div className={`modal-container__box__progress__item__checkbox__box ${step === 2 ? '--current-step' : null
                  }`}>
                  <Image image={nike} />
                </div>
              </div>
              <div className={`modal-container__box__progress__item__checkbox`}>
                <input type="checkbox" className={`input disabled`} />
                <div className={`modal-container__box__progress__item__checkbox__box ${step === 3 ? '--current-step' : null
                  }`}>
                  <Image image={nike} />
                </div>
              </div>
            </div>
          </div>
          <div className={`container-full modal-container__box__content`}>
            {
              (step === 1) ?
                <InitiatePaymentStepOne handleClose={handleClose} changeStep={changeStep} toggleUploadProgress={toggleUploadProgress} /> : null
            }
            {
              (step === 2) ?
                <InitiatePaymentStepTwo handleClose={handleClose} changeStep={changeStep} /> : null
            }
            {
              (step === 3) ?
                <InitiatePaymentStepThree handleClose={handleClose} toggleUploadProgress={toggleUploadProgress} reloadPage={reloadPage} changeStep={changeStep} /> : null
            }
          </div>
        </div>
      </div>
    </InitiatePaymentModalStyle>
  );
}

export default InitiatePaymentModal;