import React, {useState, useEffect} from 'react';
import ResetPasswordTemplateStyle from './ResetPasswordTemplateStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import logo from '../../../assets/images/logo.svg';
import Alert from '../../molecules/Alerts/Alerts';
import { Link, useNavigate } from 'react-router-dom';
import arrowLeft from '../../../assets/images/arrow-left.svg';
import success from '../../../assets/images/success.svg';
import ResetPasswordForm from '../../molecules/ResetPasswordForm/ResetPasswordForm';
import Button from '../../atoms/Button/Button';
import {useSelector, useDispatch} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {alertActions} from '../../../redux/actions/AlertActions';
import Cookies from 'js-cookie';
import {resetTokenValidation} from '../../../redux/actions/AuthAction';

const ResetPasswordTemplate = () => {
  const [username, setUsername] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const state = useSelector( (state:RootStore) => state);
  console.log(state);
  const dispatch: TypedDispatch = useDispatch();
  const auth = Cookies.get("userToken");
  const navigate = useNavigate()

  useEffect(() => {
    if(auth !== undefined){
      navigate('/dashboard');
    }
    return () => {
      dispatch(alertActions.clear());
    }
  }, [])

  useEffect(() => {
    dispatch(resetTokenValidation(window.location.href.split("=")[1]));
  }, [])

  const goToLoginPage = async () => {
    await dispatch(alertActions.clear());
    navigate('/')
  }

  return (
    <>
      <ResetPasswordTemplateStyle>
       <div className={`container-full center signin`}>
          <div className={`signin__box`}>
            <div className={`container-full center signin__box__logo`}>
              <Image image={logo} alt="logo" />
            </div>
            {
              state.alertReducer.type === "alert-success" && submitted ?
              <div className={`container-full signin__box__modal --success-modal`}>
                <div className={`container-full signin__box__modal__content --success-modal__content`}>
                  <div className={`container-full signin__box__modal__content__nav`}>
                    <Image image={arrowLeft} />
                    <Text value='Go Back' onClick={() => dispatch(alertActions.clear())} />
                  </div>
                  <div className={`container-full center signin__box__modal__content__desc --success-modal__desc`}>
                    <HeaderText value="Reset Successful!" />
                  </div>
                  <div className={`container-full center signin__box__modal__content__icon`}>
                    <Image image={success} />
                  </div>
                  <div className={`container-full center signin__box__modal__content__text`}>
                    <p> Your password reset for <span> {username} </span> was successful </p>
                  </div>
                  <div className={`container-full center signin__box__modal__content__action`}>
                    <Button value="Log in" className={`button`} onClick={() => goToLoginPage()}/>
                  </div>
                </div>
              </div> : 
              <div className={`container-full signin__box__modal`}>
                <div className={`container-full signin__box__modal__content`}>
                  <div className={`container-full center signin__box__modal__content__desc`}>
                    <HeaderText value="Reset Password" />
                    <Text value='Create a new password' />
                  </div>
                  {
                    state.alertReducer.type === "alert-danger" ? 
                    <div className={`container-full signin__box__modal__content__alert`}>
                      <Alert text={state.alertReducer.message} />
                    </div> : ''
                  }
                  <div className={`container-full signin__box__modal__content__form`}>
                    <ResetPasswordForm changeUsername={setUsername} setSubmitted={setSubmitted}/>
                  </div>
                </div>
              </div>
            }
            
            
          </div>
       </div>
      </ResetPasswordTemplateStyle>
    </>
  );
};

export default ResetPasswordTemplate;
