import styled from 'styled-components';
import search from '../../../assets/images/Search.svg';

const PaymentReportTemplateStyle = styled.div`
  background: white;
  box-sizing: border-box;
  padding: 20px 30px;
  width: 100%;

  .issuers__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 1.5em;
      line-height: 30px;
    }
  }
  .issuers__action{
    margin-top: 15px;
  }
  .issuers__action__nav{
    display: flex;
  }
  .issuers__action__nav__item{
    box-sizing: border-box;
    cursor: pointer;
    margin-right: 20px;
    padding: 8px 10px 8px 10px;
    p{
      color: ##6D6F79;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.01em;
    }
  }
  .--active-nav > .issuers__action__nav__item{
    border-bottom: 2px solid #3751FF;
    box-sizing: border-box;
    padding: 6px 10px 6px 10px;
    p{
      color: #3751FF;
      font-weight: 600;
    }
  }
  .issuers__action__search{
    display: flex;
    .input{
      background: url(${search}), #FAFAFA no-repeat;
      background-size: 18px;
      background-repeat: no-repeat;
      background-position: 8px 8px;
      border: none;
      border-radius: 8px;   
      box-sizing: border-box;
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      height: 34px;
      line-height: 16px;
      margin-right: 10px;
      padding-left: 37px;
      width: 194px;
      &::placeholder{
        color: rgba(159, 162, 180, 0.8)
      }
    }
  }
  .issuers__action__btn-group{
    display: flex;
    .--export{
      background: #FAFAFA;
      border-radius: 8px;
      box-sizing: border-box;
      height: 34px;
      margin-right: 10px;
      padding: unset;
      padding: 4px 8px;
      width: 34px;
    }
    .--disabled{
      opacity: 0.5;
      pointer-events: none;
    }
    .--user{
      background: #3751FF;
      border-radius: 8px;
      box-sizing: border-box;
      cursor: pointer;
      height: 34px;
      padding: unset;
      padding: 8px 8px;
      width: 34px;
    }
  }
  .issuers__action__btn-group__summary{
    position: relative;
    .button{
      background: #3751FF;
      border-radius: 8px;
      box-shadow: 0px 4px 12px 0px rgba(55, 81, 255, 0.24);
      box-sizing: border-box;
      color: white;
      cursor: pointer;
      font-family: Mulish;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      height: 34px;
      margin-right: 20px;
      padding: 0 12px;
    }
  }
  .merchant__filter{
    justify-content: flex-start;
    margin-top: 13px;
    position: relative;
  }
  .merchant__filter__item{
    box-sizing: border-box;
    border-right: 1px solid rgba(37, 39, 51, 0.1);
    padding: 0 10px;
  }
  .--active-filter{
    padding: 0 15px;
    p{
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;
      margin-top: 5.5px;
    }
  }
  .--filter{
    display: flex;
    p{
      color: rgba(109, 111, 121, 0.5);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: -0.01em;
      line-height: 18px;
      margin-left: 7px;
      margin-top: 4px;
    }
  }
  .--filter__box{
    background: #FAFAFA;
    border-radius: 6px;
    box-sizing: border-box;
    height: 26px;
    padding: 4px;
    width: 26px;
  }
  .--add-filter{
    border: none;
    display: flex;
    padding: 0 16px;
    -ms-flex: 0 0 130px;
    -webkit-flex: 0 0 130px;
    flex: 0 0 130px;
    max-width: 130px;
    p{
      color: #6D6F79;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.01em;
      margin-top: 5.5px;
      width: 100%;
    }
    .--elipses{
      all:unset;
      cursor:pointer;
      display: flex;
      width:100%;
    }
  }
  .unset{
    all: unset;
  }
  .more-info {
    background: #FFFFFF;
    box-shadow: 0px 15px 44px rgba(10, 18, 73, 0.15);
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 34px;
    padding: 14.5px 12px 12px 15.33px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 4000;
  }
  .more-info__top{
    border-bottom: 1px solid rgba(37, 39, 51, 0.1);
    box-sizing: border-box;
    padding-bottom: 21px;
  }
  .more-info__top__row{
    margin-bottom: 22.5px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .--bottom-row{
    justify-content: normal;
  }
  .more-info__top__row__item{
    margin-bottom: 0;
  }
  .--username{
    margin-right: 12.5%;
  }
  .more-info__top__row__item__desc{
    justify-content: normal;
    p{
      all: unset;
      color: #6D6F79;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;
    }
  }
  .more-info__top__row__item__desc__checkbox{
    margin-right: 7.33px;
    input[type='checkbox'] {
      -moz-appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;
      background: #FFFFFF;
      border-radius: 3px;
      height: 13.33px;
      outline: 0.52531px solid #B5BABD !important;
      position: relative;
      transition: all 0.2s linear;
      width: 13.33px;
      & + img {
        margin-left: -11px;
        margin-top: 4px;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: all 0.2s linear;
        transform: scale(0);
        transform-origin: bottom left;
      }
      &:checked {
        background: #3751FF;
        outline: 2px solid #3751FF !important;
      }
      &:checked + img {
        transform: scale(1);
      }
    }
  }
  .more-info__top__row__item__input{
    box-sizing: border-box;
    padding: 5px 0 0 18.67px;
    .input{
      background: #FAFAFA;
      border: none;
      border-radius: 8px;
      box-sizing: border-box;
      color: rgb(109, 111, 121);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      height: 34px;
      padding-left: 10px;
      position: relative;
      width: 100%;
      &::placeholder{
        color: rgba(109, 111, 121, 0.4);
      }
    }
    select{
      appearance: none;
      background: transparent;
      color: rgb(109, 111, 121);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      margin-top: 9px;
      width: 100%;
    }
    select:required:invalid {
      color: rgba(109, 111, 121, 0.4);
    }
    img{
      margin-top: 15.33px;
      margin-right: 6.67px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .more-info__bottom{
    justify-content: flex-end;
    margin-top: 15px;
    .button{
      border-radius: 8px;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      height: 40px;
      margin-bottom: 0;
      text-align: center;
    }
    .--cancel{
      background: #F5F5F5;
      color: #252733;
      margin-right: 20px;
      width: 75px;
    }
    .--apply{
      background: #3751FF;
      box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
      color: #FFFFFF;
      width: 110px;
    }
    .disabled{
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .issuers__table{
    margin-top: 9px;
  }

  .summary{
    background: #FFFFFF;
    box-shadow: 0px 15px 44px rgba(10, 18, 73, 0.15);
    box-sizing: border-box;
    border-radius: 10px;
    margin-right: 20px;
    margin-top: 36px;
    padding: 14.5px 12px 12px 15.33px;
    position: absolute;
    right: 0;
    top: 0;
    width: 200px;
    z-index: 4000;
  }
  
`;

export default PaymentReportTemplateStyle;
