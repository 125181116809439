import React, { useState, useEffect, useRef } from 'react';
import BusinessProfileTemplateStyle from './BusinessProfileTemplateStyle';
import Text from '../../atoms/Text/Text';
import Input from '../../atoms/Input/Input';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import { Link } from 'react-router-dom';
import edit from '../../../assets/images/edit-icon.svg';
import document from '../../../assets/images/document.svg';
import pencil from '../../../assets/images/pencil.svg';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { DashboardActions } from '../../../redux/actions/DashboardAction';
import { SubmitActionsPUT, SubmitActionsUpload } from '../../../redux/actions/SubmitAction';
import Cookies from 'js-cookie';
import { appInstance as axios } from '../../../axios';
import cogoToast from "cogo-toast";
import SettingsNavBar from '../../organisms/SettingsNavBar/SettingsNavBar';

const BusinessProfileTemplate = () => {
  const [fetchLoading, setFetchLoading] = useState(true);
  const ref = useRef<HTMLAnchorElement | null>(null);
  const [url, setFileUrl] = useState<string>();
  const [imageUrl, setFileimageUrl] = useState<string>();
  const [name, setFileName] = useState<string>();
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  console.log(state);

  useEffect(() => {
    if (state.submitReducer.results && state.submitReducer.results.data.fileUri) {
      let data = {
        companyLogoUri: state.submitReducer.results.data.fileUri
      }
      dispatch(SubmitActionsPUT(`/merchant/profile/${state.authReducer.results.merchant_id}`, data))
    }
    if (state.submitReducer.results && state.submitReducer.results.data.companyLogoUri) {
      generatePageContent();
    }
  }, [state.submitReducer])

  useEffect(() => {
    if (state.dashboardReducer.results && state.dashboardReducer.results.data.content && state.dashboardReducer.results.data.content.length > 0 && state.dashboardReducer.results.data.content[0].companyLogoUri) {
      openImageFile(state.dashboardReducer.results.data.content[0].companyLogoUri);
    }
  }, [state.dashboardReducer])

  useEffect(() => {
    generatePageContent();
  }, [])

  const generatePageContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/merchant?merchantId=${state.authReducer.results.merchant_id}`));
    setFetchLoading(false);
  }

  const renderFetchLoading = () => {
    if (fetchLoading) {
      return (
        <FetchLoading />
      )
    }
  }

  const openImageFile = async (fileUri: string) => {
    const apiDefinition = () => {
      return axios.get(`${fileUri.substring(4)}`,
        {
          responseType: "blob",
          headers: {
            "Authorization": `Bearer ${Cookies.get("userToken")}`,
          }
        }
      );
    };
    try {
      setFetchLoading(true);
      const { data } = await apiDefinition();
      const url = URL.createObjectURL(new Blob([data]));
      setFileimageUrl(url);
      setFetchLoading(false);
    } catch (error) {
      cogoToast.error("Error downloading file", { position: "top-right" })
    }
  }

  const openFile = async (fileUri: string, fileName: string) => {
    const apiDefinition = () => {
      return axios.get(`${fileUri.substring(4)}`,
        {
          responseType: "blob",
          headers: {
            "Authorization": `Bearer ${Cookies.get("userToken")}`,
          }
        }
      );
    };
    try {
      setFetchLoading(true);
      const { data } = await apiDefinition();
      const url = URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      setFileUrl(url);
      setFileName(fileName);
      ref.current && ref.current.click();
      setFetchLoading(false);
      URL.revokeObjectURL(url);
    } catch (error) {
      cogoToast.error("Error downloading file", { position: "top-right" })
    }
  }

  const fileUpload = async (file: any) => {
    setFetchLoading(true);
    let value = {
      file,
      fileType: 'OTHERS'
    }
    await dispatch(SubmitActionsUpload('/files/upload/', value));
    setFetchLoading(false);
  }

  return (
    <>
      {renderFetchLoading()}
      <BusinessProfileTemplateStyle>
        <div className={`container-full settings`}>
          <div className={`container-full settings__desc`}>
            <HeaderText value="Settings" />
          </div>
          <SettingsNavBar />
          <div className={`container-full settings__content`}>
            <div className={`container-full settings__content__desc`}>
              <HeaderText value='Business Profile' />
            </div>
            <div className={`container-full settings__content__body`}>
              <div className={`settings__content__body__logo`}>
                <div className={`container-full settings__content__body__logo__desc`}>
                  <HeaderText value='LOGO' />
                </div>
                <div className={`container-full settings__content__body__logo__box`}>
                  {
                    (state.dashboardReducer.results && imageUrl) ?
                      <Image className="img img-rc" image={imageUrl} /> : null
                  }
                </div>
                <div className={`settings__content__body__logo__file`}>
                  <Text value='Logo' className={`logo`} />
                  {/* <Text value='YASSS.jpeg' className={`file-name`} /> */}
                  {
                    state.authReducer.results.roles.includes('Administrator') && <Image image={edit} alt="edit" />
                  }
                  {
                    state.authReducer.results.roles.includes('Administrator') &&
                    <Input type="file" className={`input`} onChange={(event) => fileUpload(event.target.files[0])} accept="image/*" />
                  }
                </div>
              </div>
              <div className={`settings__content__body__details`}>
                <div className={`container-full settings__content__body__details__section`}>
                  <div className={`container-full settings__content__body__details__section__desc`}>
                    <HeaderText value='Business details' />
                  </div>
                  <div className={`container-full settings__content__body__details__section__box`}>
                    <div className={`container-full settings__content__body__details__section__box__row`}>
                      <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                        <HeaderText value='Name' />
                        <Text value={
                          (state.dashboardReducer.results && state.dashboardReducer.results.data.content && state.dashboardReducer.results.data.content.length > 0) ? `${state.dashboardReducer.results.data.content[0].businessName}` : ''
                        } />
                      </div>
                      <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                        <HeaderText value='Email' />
                        <Text value={
                          (state.dashboardReducer.results && state.dashboardReducer.results.data.content && state.dashboardReducer.results.data.content.length > 0) ? `${state.dashboardReducer.results.data.content[0].businessEmail}` : ''
                        } />
                      </div>
                    </div>
                    <div className={`container-full settings__content__body__details__section__box__row`}>
                      <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                        <HeaderText value='RC Number' />
                        <Text value={
                          (state.dashboardReducer.results && state.dashboardReducer.results.data.content && state.dashboardReducer.results.data.content.length > 0) ? `${state.dashboardReducer.results.data.content[0].rcNumber}` : ''
                        } />
                      </div>
                      <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                        <HeaderText value='Business Phone' />
                        <Text value={
                          (state.dashboardReducer.results && state.dashboardReducer.results.data.content && state.dashboardReducer.results.data.content.length > 0) ? `${state.dashboardReducer.results.data.content[0].businessPhoneNumber}` : ''
                        } />
                      </div>
                    </div>
                    <div className={`container-full settings__content__body__details__section__box__row`}>
                      <HeaderText value='Address' />
                      <Text value={
                        (state.dashboardReducer.results && state.dashboardReducer.results.data.content && state.dashboardReducer.results.data.content.length > 0) ? `${state.dashboardReducer.results.data.content[0].address}` : ''
                      } className='address' />
                    </div>
                    <div className={`container-full settings__content__body__details__section__box__row`}>
                      <HeaderText value='Business Description' />
                      <Text value={
                        (state.dashboardReducer.results && state.dashboardReducer.results.data.content && state.dashboardReducer.results.data.content.length > 0) ? `${state.dashboardReducer.results.data.content[0].description}` : ''
                      } className='address' />
                    </div>
                    <div className={`container-full settings__content__body__details__section__box__row --businees`}>
                      <HeaderText value='Allow Salary Processing' />
                      <div className={`settings__content__body__details__section__box__row__switch`}>
                        <input type="checkbox" className={`input`} checked={
                          (state.dashboardReducer.results && state.dashboardReducer.results.data.content && state.dashboardReducer.results.data.content[0]) && state.dashboardReducer.results.data.content[0].processSalary
                        } />
                        <div className={`settings__content__body__details__section__box__row__switch__box`}>
                          <div className={`settings__content__body__details__section__box__row__switch__box__ball`}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`container-full settings__content__body__details__section --kyc`}>
                  <div className={`container-full settings__content__body__details__section__desc`}>
                    <HeaderText value='KYC DOCumentation' />
                  </div>
                  <div className={`container-full settings__content__body__details__section__kyc`}>
                    <a href={url} download={name} className="hidden" ref={ref} />
                    {
                      state.dashboardReducer.results && state.dashboardReducer.results.data.content && state.dashboardReducer.results.data.content.length > 0 && state.dashboardReducer.results.data.content[0].kyc !== undefined &&
                      state.dashboardReducer.results.data.content[0].kyc.length > 0 &&
                      state.dashboardReducer.results.data.content[0].kyc.map((kyc: Record<any, any>, key: string) => {
                        if (kyc.fileUri) {
                          return (
                            <div className={`settings__content__body__details__section__kyc__item`} key={key} onClick={() => openFile(kyc.fileUri, kyc.name)}>
                              <Image image={document} />
                              <div className={`settings__content__body__details__section__kyc__item__text`}>
                                <HeaderText value={kyc.name} />
                                <Text value={`${kyc.name}.pdf`} />
                              </div>
                            </div>
                          )
                        }
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={`container-full settings__content__action`}>
                <Link to={
                  (state.dashboardReducer.results && state.dashboardReducer.results.data.content[0].id) ? `/settings/business-profile/edit?merchantId=${state.dashboardReducer.results.data.content[0].id}` : ''
                }>
                  <button className='button'>
                    <Image image={pencil} />
                    Edit
                  </button>
                </Link>
              </div> */}

          </div>
        </div>
      </BusinessProfileTemplateStyle>
    </>
  );
};

export default BusinessProfileTemplate;
