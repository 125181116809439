import styled from "styled-components";

const MerchantStepOneStyle = styled.div`
position: relative;
.merchants__body{
    box-sizing: border-box;
    margin-top: 55px;
    padding-left: 36px;
}

.merchants__body__form{
    box-sizing: border-box;
    margin-top: 20px;
    padding-right: 36px;
}
.merchants__body__form__desc{
    margin-bottom: 0;
    .step{
        color: rgba(37, 39, 51, 0.5);
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }
    .header{
        color: #252733;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
    }
    .desc{
        color: #9FA2B4;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        margin-top: 10px;
    }
}
.merchants__body__form__form{
    margin-top: 30px;
}
.merchants__body__form__form__row{
    margin-bottom: 20px;
    p{
        color: #252733;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 7.5px;
    }
    .input{
        background: rgba(55, 81, 255, 0.03);
        border: none;
        border-radius: 8px;
        box-sizing: border-box;
        color: rgb(37, 39, 51);
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        padding-left: 15px;
        width: 100%;
        &::placeholder{
            color: rgba(37, 39, 51, 0.3);
        }
    }
    span{
        color: red;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
    }
    &:last-child{
        margin-bottom: 0;
    }
}
.merchants__body__form__form__row__item{
    margin-bottom: 0;
}
.merchants__action{
    border-top: 2px solid #F8F9FA;
    box-sizing: border-box;
    padding: 8px 36px;
    position: absolute;
    top: 78vh;
    .button{
        border-radius: 8px;
        height: 44px;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.2px;
        margin-bottom: 41px;
    }
    .--back{
        background: #F5F5F5;
        color: #252733;
        padding-left: 44px;
        position: relative;
        text-align: left;
        width: 107px;
        img{
            left: 0;
            margin: 12px 0 0 14px;
            position: absolute;
            top: 0;
        }
    }
    .--save{
        background: #3751FF;
        box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
        color: white;
        width: 176px;
    }
}

.merchants__body__form__form__row__switch{
    align-self: center;
    position: relative;
    .input{
      cursor: pointer;
      height: 18px;
      position: absolute;
      opacity: 0;
      width: 30px;
      &:checked + .merchants__body__form__form__row__switch__box{
        background: #1B9830;
        .merchants__body__form__form__row__switch__box__ball{
          background: white;
          margin-left: 10px;
        }
      }
    }
  }
  .merchants__body__form__form__row__switch__box{
    background: #CCCCCC;
    border-radius: 70px;
    box-sizing: border-box;
    cursor: pointer;
    height: 18px;
    padding: 2px;
    transition: all 0.2s linear;
    width: 30px;
  }
  .merchants__body__form__form__row__switch__box__ball{
    background: white;
    border-radius: 50%;
    height: 14px;
    transition: margin 0.5s linear;
    width: 14px;
  }
   
`
export default MerchantStepOneStyle;