import { Dispatch } from 'redux';
import { ExportDispatchTypes, EXPORT, EXPORT_CLEAR, EXPORT_ALL, EXPORT_CLEAR_ALL } from './ExportActionTypes';
import {appInstance as axios} from '../../axios';
import { alertActions } from './AlertActions';
import Cookies from 'js-cookie';
import historyObject from '../../config/History';

export const exportData =
  (data: any) => async (dispatch: Dispatch<ExportDispatchTypes>) => {
    dispatch({
      type: EXPORT,
      data,
    });
};

export const exportClear =
  () => async (dispatch: Dispatch<ExportDispatchTypes>) => {
    dispatch({
      type: EXPORT_CLEAR
    });
};

export const exportAll =
  (url: string) => async (dispatch: Dispatch<ExportDispatchTypes | any>) => {
    try {
      dispatch(alertActions.clear());
      const res = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      dispatch({
        type: EXPORT_ALL,
        response: res.data.data.content,
      });
      dispatch(alertActions.success(res.data.message));
    } catch (error: any) {
      if(error.response.status === 401 && error.response.data.error !== "Access is denied"){
        await Cookies.remove("userToken");
        historyObject.push("/");
      }
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
};

export const exportClearAll =
  () => async (dispatch: Dispatch<ExportDispatchTypes>) => {
    dispatch({
      type: EXPORT_CLEAR_ALL
    });
};
