import React from 'react';
import AlertStyles from './AlertStyles';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import error from '../../../assets/images/error-icon.svg';

export interface AlertProps{
    text: string
}

const Alert = ({ text }: AlertProps): JSX.Element => {
    return(
        <AlertStyles> 
            <div className={`container-full alert`}> 
                <Image image={error} alt="error icon" />
                <Text value={text} />
            </div> 
        </AlertStyles>
    ) 
};

export default Alert;