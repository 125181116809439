import React, {useState, useEffect} from 'react';
import MerchantUsersDetailTemplateStyle from './MerchantUsersDetailTemplateStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import Input from '../../atoms/Input/Input';
import angleLeft from '../../../assets/images/angle-small-left.svg';
import merchantProfile from '../../../assets/images/merchant-profile.svg';
import MerchantNav from '../../organisms/MerchantNav/MerchantNav';
import { Link } from 'react-router-dom';
import angleLeftNew from '../../../assets/images/arrow-left-new.svg';
import user from '../../../assets/images/user-male-new.svg';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {DashboardActions} from '../../../redux/actions/DashboardAction';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import { alertActions } from '../../../redux/actions/AlertActions';
import {appInstance as axios} from '../../../axios';
import Cookies from 'js-cookie';
import cogoToast from "cogo-toast"; 

const MerchantUsersDetailTemplate = () => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [merchantData, setMerchantData]: any = useState({});
  const [imageUrl, setFileimageUrl] = useState<string>();
  const [profilePicture, setProfilePicture] = useState<string>();
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector( (state:RootStore) => state);

  useEffect(() => {
    generatePageContent();
    console.log(state);
  }, [])

  useEffect(() => {
    if(merchantData && merchantData.companyLogoUri){
      openFile(merchantData.companyLogoUri, "merchant");
    }
  }, [merchantData])

  useEffect(() => {
    if(state.dashboardReducer.results && state.dashboardReducer.results.data.content[0].profilePhotoUri){
        openFile(state.dashboardReducer.results.data.content[0].profilePhotoUri, "user");
    }
  }, [state.dashboardReducer])

  const generatePageContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/users?${window.location.href.split("?")[1]}&size=0&page=1`));
    setFetchLoading(false);

    try{
      let merchantId = window.location.href.split("?")[1];
      const res = await axios.get(`/merchant?${merchantId.split("&")[0]}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setMerchantData(res.data.data.content[0]);
    }catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const openFile = async (fileUri: string, type: string) => { 
    const apiDefinition = () => {
      return axios.get(`${fileUri.substring(4)}`,
        {
          responseType: "blob",
          headers: {
            "Authorization": `Bearer ${Cookies.get("userToken")}`,
          }
        }
      );
    };
    try {
      setFetchLoading(true);
      const { data } = await apiDefinition();
      const url = URL.createObjectURL(new Blob([data]));
      if(type === "merchant"){
        setFileimageUrl(url);
      }
      else{
        setProfilePicture(url);
      }
      setFetchLoading(false);
    } catch (error) {
      cogoToast.error("Error downloading file", { position: "top-right" })
    }
  }
  
  const renderFetchLoading = () => {
    if(fetchLoading){
      return(
        <FetchLoading />
      )
    }
  }

  return (
    <>
      {renderFetchLoading()}
      <MerchantUsersDetailTemplateStyle>
        <div className={`container-full merchants`}>
          <div className={`container-full merchants__back`}>
            <Link to="/merchants">
              <Image image={angleLeft} />
              <Text value='Back to Merchants' />
            </Link>
          </div>
          <div className={`container-full merchants__desc`}>
            <HeaderText value='Merchants' />
          </div>
          <div className={`container-full merchants__top`}>
            <div className={`container-full merchants__top__desc`}>
              <div className={`merchants__top__desc__name`}>
                {
                  (merchantData && imageUrl) ? 
                  <Image image={imageUrl}/> : <Image image={merchantProfile} />
                }
                <div className={`merchants__top__desc__name__group`}>
                  <HeaderText value={
                    (merchantData.businessName) ? merchantData.businessName : ''
                  } />
                  <Text value={
                    (merchantData.businessEmail) ? merchantData.businessEmail : ''
                  } />
                </div>
              </div>
            </div>
            <div className={`container-full merchants__top__nav`}>
              <div>
                <MerchantNav />
              </div>
            </div>
          </div>
          <div className={`container-full merchants__content`}>
            <div className={`container-full merchants__content__desc`}>
              <Link to={`/merchants/users?${window.location.href.split("?")[1].split("&")[0]}`}>
                <Image image={angleLeftNew} />
                <Text value='Back to Users' />
              </Link>
            </div>
            <div className={`container-full merchants__content__box`}>
              <div className={`merchants__content__box__img`}>
                {
                  (state.dashboardReducer.results && profilePicture) ? 
                  <Image className="img-rc" image={profilePicture}/> : <Image image={user} />
                }
              </div>
              <div className={`merchants__content__box__details`}>
                <div className={`container-full merchants__content__box__details__desc`}>
                  <HeaderText value='USER DETAILS' />
                </div>
                <div className={`container-full merchants__content__box__details__row`}>
                  <div className='div-md-6 merchants__content__box__details__row__item'>
                    <HeaderText value='First Name' />
                    <Text value={(state.dashboardReducer.results) ? state.dashboardReducer.results.data.content[0].firstName : ''} />
                  </div>
                  <div className='div-md-6 merchants__content__box__details__row__item'>
                    <HeaderText value='Last Name' />
                    <Text value={(state.dashboardReducer.results) ? state.dashboardReducer.results.data.content[0].lastName : ''} />
                  </div>
                </div>
                <div className={`container-full merchants__content__box__details__row`}>
                  <div className='div-md-6 merchants__content__box__details__row__item'>
                    <HeaderText value='Phone Number' />
                    <Text value={(state.dashboardReducer.results) ? state.dashboardReducer.results.data.content[0].phoneNumber : ''} />
                  </div>
                  <div className='div-md-6 merchants__content__box__details__row__item'>
                    <HeaderText value='Email Address' />
                    <Text value={(state.dashboardReducer.results) ? state.dashboardReducer.results.data.content[0].email : ''} />
                  </div>
                </div>
                <div className={`container-full merchants__content__box__details__row`}>
                  <div className='div-md-6 merchants__content__box__details__row__item'>
                    <HeaderText value='Username' />
                    <Text value={(state.dashboardReducer.results) ? state.dashboardReducer.results.data.content[0].username : ''} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MerchantUsersDetailTemplateStyle>
    </>
  );
  
};

export default MerchantUsersDetailTemplate;
