import styled from 'styled-components';

const ManageRolesTemplateStyle = styled.div`
  background: #FAFAFA;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 20px 40px 0 30px;                                                                                                                                            
  width: 100%;
  .settings__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 1.5em;
      line-height: 30px;
    }
  }
  .settings__top{
    box-sizing: border-box;
    margin-top: 15px;
    padding-left: 12px;
    position: relative;
    .title{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 1em;
      line-height: 20px;
      margin-top: 3px;
    }
    .button{
      background: #3751FF;
      border-radius: 8px;
      color: white;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 0.875em;
      height: 34px;
      margin-bottom: 0;
      padding-left: 29px ;
      position: relative;
      text-align: left;
      width: 98px;
      img{
        left: 0;
        margin-left: 10px;
        margin-top: 10px;
        position: absolute;
        top: 0;
      }
    }
    .--disabled{
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .more-info {
    background: #FFFFFF;
    box-shadow: 0px 5px 15px rgba(8, 9, 10, 0.1);
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 102px;
    padding: 12px 8px 12px 20px;
    position: absolute;
    right: 0;
    top: 0;
    width: 103px;
    z-index: 4000;
  }
  .more-info__item{
    margin-bottom: 16px;
    &:last-child{
      margin-bottom: 0;
    }
    button {
      all: unset;
      cursor: pointer;
      width: 100%;
    }
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }
  }
  .settings__body{
    box-sizing: border-box;
    margin-top: 20px;
    padding-left: 12px;
  }
  .settings__body__box{
    background: #F5F5F5;
    box-sizing: border-box;
    min-height: 100vh;
    padding: 20px;
  }
`;

export default ManageRolesTemplateStyle;
