import styled from "styled-components";
import Colors from '../../../helpers/Colors';

const SignatoryGroupsDetailsStyle = styled.div`
  align-self: flex-start;
  background: #FAFAFA;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 0;
  padding: 15px;
  width: 100%;
  
  .signatory__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .signatory__empty{
    margin-bottom: 172px;
    margin-top: 108px;
    .header{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 4px;
      text-align: center;
      width: 100%;
    }
    .text{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 7px;
      text-align: center;
      width: 80%;
    }
    .or{
      color: rgba(37, 39, 51, 0.5);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 14px;
      text-align: center;
      width: 100%;
    }
  }
  .signatory__empty__action{
    display: flex;
    margin-top: 7px;
    p{
      color: #3751FF;
      cursor: pointer;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      margin-top: 3px;
    }
  }
  .signatory__content{
    margin-top: 18px;
  }
  .signatory__content__group{
    margin-bottom: 0;
    h2{
      color: rgba(37, 39, 51, 0.5);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 8px;
    }
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
    }
    .input{
      background: rgba(235, 237, 245, 0.3);
      border: none;
      border-radius: 5px;
      box-sizing: border-box;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      height: 60px;
      padding: 7px;
      width: 100%;
    }
  }
  .--description{
    margin-bottom: 30px;
  }
  .--created{
    margin-bottom: 39px;
  }
  .--status{
    display:flex;
    justify-content: space-between;
    margin-bottom: 37px;
  }
  .--users{
    margin-bottom: 20px;
    h2{
      margin-bottom: 3px;
    }
  }
  .signatory__content__group__status{
    display: flex;
    .label{
      background: grey;
      border-radius: 5px;
      box-sizing: border-box;
      height: 20px;
      margin-left: 8px;
      padding: 2px 6px;
      // width: 200px;
      p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        font-feature-settings: 'salt' on;
      }
    }
    .--pending{
      background: rgba(254, 196, 0, 0.1);
      p{
        color: #DAAB0B;
      }
    }
    .--enabled{
      background: rgba(41, 204, 151, 0.08);
      p{
        color: #1B9830;
      }
    }
    .--disabled{
      background: rgba(241, 43, 44, 0.1);
      p{
        color: #BD0F10;
      }
    }
  }
  .signatory__content__group__switch{
    position: relative;
    .input{
      height: 18px;
      position: absolute;
      opacity: 0;
      width: 30px;
      &:checked + .signatory__content__group__switch__box{
        background: #1B9830;
        .signatory__content__group__switch__box__ball{
          background: white;
          margin-left: 10px;
        }
      }
    }
  }
  .disabled{
    .signatory__content__group__switch__box{
      background: #DDE2E5;
    }
    .signatory__content__group__switch__box__ball{
      background: #ACB5BD;
    }
  }
  .signatory__content__group__switch__box{
    background: #CCCCCC;
    border-radius: 70px;
    box-sizing: border-box;
    height: 18px;
    padding: 2px;
    transition: all 0.2s linear;
    width: 30px;
  }
  .signatory__content__group__switch__box__ball{
    background: white;
    border-radius: 50%;
    height: 14px;
    transition: margin 0.5s linear;
    width: 14px;
  }
  .signatory__content__group__users{
    margin-top: 8px;
  }
  .signatory__content__group__users__item{
    background: rgba(235, 237, 245, 0.3);
    border-radius: 5px;
    box-sizing: border-box;
    justify-content: normal;
    margin-bottom: 8px;
    padding: 9px 7px 6px 7px;
  }
  .signatory__content__group__users__item__number{
    background: #EBEDF5;
    border-radius: 3px;
    box-sizing: border-box;
    height: 15px;
    margin-top: 0.5px;
    padding: 2px 4.5px;
    width: 15px;
    p{
      color: rgba(37, 39, 51, 0.5);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 700;
      font-size: 9px;
      line-height: 11px;
    }
  }
  .signatory__content__group__users__item__name{
    margin-left: 7px;
    width: 80%;
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
    }
  }
  .signatory__content__group__users__item__close{
    cursor: pointer;
    margin-left: auto;
  }
  .--add-user{
    cursor: pointer;
    justify-content: space-between;
    margin-bottom: 0;
    margin-top: 10px;
    position: relative;
    p{
      color: rgba(37, 39, 51, 0.5);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
  }
  }
  .more-info{
    background: white;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(8, 9, 10, 0.1);
    left: 0;
    max-height: 136px;
    margin-top: 50px;
    overflow: auto;
    padding: 5px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 4000;
    .active{
      background: #F5F6FF;
      background-blend-mode: multiply;
      border-radius: 5px;
    }
  }
  .more-info__item{
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 2px;
    padding: 7px 8px 7px 15px;
    transition: all 0.5s linear;
    button{
      all:unset;
      cursor:pointer;
      width:100%;
    }
    &:hover{
      background: #F5F6FF;
      background-blend-mode: multiply;
      border-radius: 5px;
    }
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
    }
  }
  .--elipses{
    all: unset;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    width: 100%;
  }
  .signatory__action{
    justify-content: flex-end;
    .button{
      border-radius: 8px;
      font-family: 'Mulish';
      font-weight: 500;
      font-size: 13px;
      height: 32px;
      letter-spacing: 0.2px;
      margin-bottom: 0;
    }
    .--cancel{
        background: #F5F5F5;
        color: #252733;
        margin-right: 20px;
        width: 73px;
    }
    .--change{
      background: linear-gradient(0deg, #ADB3DC, #ADB3DC), #3751FF;
      box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
      color: white;
      pointer-events:  none;
      width: 101px;
    }
    .--active-btn{
      background: #3751FF;
      pointer-events: unset;
    }
  }
`;

export default SignatoryGroupsDetailsStyle;