import React, { useState, useEffect } from 'react';
import MerchantUsersTemplateStyle from './MerchantUsersTemplateStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import Input from '../../atoms/Input/Input';
import angleLeft from '../../../assets/images/angle-small-left.svg';
import merchantProfile from '../../../assets/images/merchant-profile.svg';
import editMerchant from '../../../assets/images/edit-merchant.svg';
import deactivateMerchant from '../../../assets/images/deactivate-merchant.svg';
import MerchantNav from '../../organisms/MerchantNav/MerchantNav';
import document from '../../../assets/images/document-new.svg';
import expandIcon from '../../../assets/images/expand-icon.svg';
import { Link } from 'react-router-dom';
import rejectIcon from '../../../assets/images/reject-icon-red.svg';
import approveIcon from '../../../assets/images/approve-icon-green.svg';
import plusIcon from '../../../assets/images/plus-icon-white.svg';
import MerchantsUserTable from '../../organisms/MerchantsUserTable/MerchantsUserTable';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { DashboardActions } from '../../../redux/actions/DashboardAction';
import { exportAll, exportClear } from '../../../redux/actions/ExportAction';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import exportIcon from '../../../assets/images/download-icon.svg';
import { alertActions } from '../../../redux/actions/AlertActions';
import { appInstance as axios } from '../../../axios';
import Cookies from 'js-cookie';
import cogoToast from "cogo-toast";

const MerchantUsersTemplate = () => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [merchantData, setMerchantData]: any = useState({});
  const [imageUrl, setFileimageUrl] = useState<string>();
  const dispatch: TypedDispatch = useDispatch();
  const state: any = useSelector((state: RootStore) => state);
  // const navigate = useNavigate();

  useEffect(() => {
    dispatch(exportAll(`/users?${window.location.href.split("?")[1]}&size=0&page=1`));
  }, [])

  useEffect(() => {
    dispatch(exportClear())
    generatePageContent();
    console.log(state);
  }, [size, page, reloadPage])

  useEffect(() => {
    if (merchantData && merchantData.companyLogoUri) {
      openImageFile(merchantData.companyLogoUri);
    }
  }, [merchantData])

  const generatePageContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/users?${window.location.href.split("?")[1]}&size=${size}&page=${page}&firstName=${search.split(" ")[0]}&lastName=${search.split(" ")[1]}`));
    setFetchLoading(false);

    try {
      const res = await axios.get(`/merchant?${window.location.href.split("?")[1]}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setMerchantData(res.data.data.content[0]);
    } catch (error: any) {
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const openImageFile = async (fileUri: string) => {
    const apiDefinition = () => {
      return axios.get(`${fileUri.substring(4)}`,
        {
          responseType: "blob",
          headers: {
            "Authorization": `Bearer ${Cookies.get("userToken")}`,
          }
        }
      );
    };
    try {
      setFetchLoading(true);
      const { data } = await apiDefinition();
      const url = URL.createObjectURL(new Blob([data]));
      setFileimageUrl(url);
      setFetchLoading(false);
    } catch (error) {
      cogoToast.error("Error downloading file", { position: "top-right" })
    }
  }

  const renderFetchLoading = () => {
    if (fetchLoading) {
      return (
        <FetchLoading />
      )
    }
  }

  const changePage = (value: number) => {
    setPage(value);
  }

  const changeSize = (value: number) => {
    setSize(value);
  }

  const exportData = () => {
    return {
      fileData: () => {
        let users = state.exportReducer.results.data.map((user: Record<any, any>) => {
          return (
            {
              Firstname: user.firstName,
              Lastname: user.lastName,
              Email: user.email,
              Phone: user.phoneNumber,
              DateCreated: dayjs(user.createdDate).format('MM/DD/YYYY'),
              LastActive: dayjs(user.lastLoginDate).format('MM/DD/YYYY'),
              Username: user.username
            }
          )
        })
        let data = [...users];
        return data
      },
      fileHeaders: () => {
        let headers = [
          { label: "Firstname", key: "Firstname" },
          { label: "Lastname", key: "Lastname" },
          { label: "Email", key: "Email" },
          { label: "Phone Number", key: "Phone" },
          { label: "Date Created", key: "DateCreated" },
          { label: "Last Active", key: "LastActive" },
          { label: "Username", key: "Username" },
        ];
        return headers;
      }
    }
  }

  const exportDataAll = () => {
    return {
      fileData: () => {
        let users = state.exportReducer.allResults.data.map((user: Record<any, any>) => {
          return (
            {
              Firstname: user.firstName,
              Lastname: user.lastName,
              Email: user.email,
              Phone: user.phoneNumber,
              DateCreated: dayjs(user.createdDate).format('MM/DD/YYYY'),
              LastActive: dayjs(user.lastLoginDate).format('MM/DD/YYYY'),
              Username: user.username
            }
          )
        })
        let data = [...users];
        return data
      },
      fileHeaders: () => {
        let headers = [
          { label: "Firstname", key: "Firstname" },
          { label: "Lastname", key: "Lastname" },
          { label: "Email", key: "Email" },
          { label: "Phone Number", key: "Phone" },
          { label: "Date Created", key: "DateCreated" },
          { label: "Last Active", key: "LastActive" },
          { label: "Username", key: "Username" },
        ];
        return headers;
      }
    }
  }

  const refreshTable = () => {
    if (reloadPage) {
      return setReloadPage(false);
    }
    setReloadPage(true);
  }

  return (
    <>
      {renderFetchLoading()}
      <MerchantUsersTemplateStyle>
        <div className={`container-full merchants`}>
          <div className={`container-full merchants__back`}>
            <Link to="/merchants">
              <Image image={angleLeft} />
              <Text value='Back to Merchants' />
            </Link>
          </div>
          <div className={`container-full merchants__desc`}>
            <HeaderText value='Merchants' />
          </div>
          <div className={`container-full merchants__top`}>
            <div className={`container-full merchants__top__desc`}>
              <div className={`merchants__top__desc__name`}>
                {
                  (merchantData && imageUrl) ?
                    <Image image={imageUrl} /> : <Image image={merchantProfile} />
                }
                <div className={`merchants__top__desc__name__group`}>
                  <HeaderText value={
                    (merchantData.businessName) ? merchantData.businessName : ''
                  } />
                  <Text value={
                    (merchantData.businessEmail) ? merchantData.businessEmail : ''
                  } />
                </div>
              </div>
            </div>
            <div className={`container-full merchants__top__nav`}>
              <div>
                <MerchantNav />
              </div>
            </div>
          </div>
          <div className={`container-full merchants__filter`}>
            <div className='merchants__filter__desc'>
              <HeaderText value='All Users' />
            </div>
            <div className={`merchants__filter__action`}>
              <Input className={`input`} type="text" placeholder="Search record"
                onChange={(event) => setSearch(event.target.value)}
                onKeyPress={event => {
                  if (event.key === 'Enter' || event.which === 13 || event.keyCode === 13) {
                    generatePageContent();
                  }
                }}
              />
              <div className='btn-group'>
                {
                  (state.exportReducer.results.data.length > 0) ?
                    <CSVLink data={exportData().fileData()} headers={exportData().fileHeaders()} filename={"merchant users.csv"}>
                      <button className='button --export'>
                        <Image image={exportIcon} />
                      </button>
                    </CSVLink> :
                    <CSVLink data={exportDataAll().fileData()} headers={exportDataAll().fileHeaders()} filename={"merchant users.csv"}>
                      <button className='button --export'>
                        <Image image={exportIcon} />
                      </button>
                    </CSVLink>
                }
                {/* <button className='button --user'> 
                  <Image image={plusIcon} />
                </button> */}
              </div>
            </div>
          </div>
          <div className='container-full merchants__table'>
            {
              (state.dashboardReducer.results !== null) ?
                <MerchantsUserTable data={state.dashboardReducer.results} pagination={size} setPagination={changeSize} changePage={changePage} page={page} reloadPage={refreshTable} /> : null
            }
          </div>
        </div>
      </MerchantUsersTemplateStyle>
    </>
  );

};

export default MerchantUsersTemplate;
