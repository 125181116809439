import {AuthDispatchTypes, AUTH_USER, AUTH_FAILED, AUTH_REQUEST} from '../actions/AuthActionTypes';

interface DefaultStateI {
   fetching: boolean,
   results?: any
}

const defaultState: DefaultStateI = {
    fetching: false,
    results: null
}

const signinReducer = (state: DefaultStateI = defaultState, action: AuthDispatchTypes) => {
  switch (action.type) {
    case AUTH_REQUEST:
    return Object.assign({}, state, {
      fetching: true,
      results: null 
    })
    case AUTH_FAILED:
    return Object.assign({}, state, {
      fetching: false,
      results: null
    });
    case AUTH_USER:
    return Object.assign({}, state, {
      fetching: false,
      results: action.response
    });
    default:
      return state;
  }
}

export default signinReducer;
  