import React, {useState, useEffect} from 'react';
import MerchantProgressBarStyle from './MerchantProgressBarStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import nike from '../../../assets/images/nike.svg';
import Image from '../../atoms/Image/Img';
import {useParams} from "react-router-dom";


const MerchantProgressBar = () => {
    const {step} = useParams();

    return (
        <MerchantProgressBarStyle className={`container-full merchant-nav`}>
           <div className={`container-full merchants__body__progress`}>
                <div className={`merchants__body__progress__item ${
                    (step !== "step-one") ? '--active' : null
                }`}>
                  <div className={`merchants__body__progress__item__checkbox`}>
                    <input type="checkbox" className={`input disabled`} 
                        defaultChecked={step !== "step-one" ? true : false}
                    />
                    <div className={`merchants__body__progress__item__checkbox__box ${
                        step === "step-one" ? '--current-step' : null
                    }`}>
                      <Image image={nike} />
                    </div>
                  </div>
                </div>
                <div className={`merchants__body__progress__item ${
                    step !== "step-one" && step !== "step-two" ? '--active' : null
                }`}>
                  <div className={`merchants__body__progress__item__checkbox`}>
                    <input type="checkbox" className={`input disabled`}  
                        defaultChecked={step !== "step-one" && step !== "step-two" ? true : false}
                    />
                    <div className={`merchants__body__progress__item__checkbox__box ${
                        step === "step-two" ? '--current-step' : null
                    }`}>
                      <Image image={nike} />
                    </div>
                  </div>
                </div>
                <div className={`merchants__body__progress__item --last-item ${
                    step === "step-four" ? '--active' : null
                }`}>
                  <div className={`merchants__body__progress__item__checkbox`}>
                    <input type="checkbox" className={`input disabled`} 
                        defaultChecked={step === "step-four" ? true : false}
                    />
                    <div className={`merchants__body__progress__item__checkbox__box ${
                        step === "step-three" ? '--current-step' : null
                    }`}>
                      <Image image={nike} />
                    </div>
                  </div>
                  <div className={`merchants__body__progress__item__checkbox`}>
                    <input type="checkbox" className={`input disabled`}  />
                    <div className={`merchants__body__progress__item__checkbox__box ${
                        step === "step-four" ? '--current-step' : null
                    }`}>
                      <Image image={nike} />
                    </div>
                  </div>
                </div>
            </div>
        </MerchantProgressBarStyle>
    );
}

export default MerchantProgressBar;