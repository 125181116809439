import React, { useState } from 'react';
import ReportDownloadStyle from './ReportDownloadStyle';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import nike from '../../../assets/images/nike.svg';
import Image from '../../atoms/Image/Img';
import { addDays, format } from 'date-fns';
import { DateRange, DayPicker } from 'react-day-picker';
import dayjs from 'dayjs';
import { SubmitActionsGET } from '../../../redux/actions/SubmitAction';
import Cookies from 'js-cookie';
import {appInstance as axios} from '../../../axios';
import cogoToast from "cogo-toast"; 

const pastMonth = new Date();

type ReportDownloadProps = {
  route: string
}

const ReportDownload = ({ route }: ReportDownloadProps) => {
  let timeOutId: any = null;
  const [selectedMenu, setSelectedMenu] = useState(false);
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  const [filterValues, setFilterValues] = useState<any>({});
  const [fields, setFields] = useState({
    dateRange: ''
  });

  const onClickHandler = (value: boolean) => {
    if (selectedMenu !== false) {
      setSelectedMenu(false);
      return;
    }
    setSelectedMenu(value);
  }

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
      setSelectedMenu(false);
    });
  }

  const onFocusHandler = () => {
    clearTimeout(timeOutId);
  }

  const defaultSelected: DateRange = {
    from: pastMonth,
    to: addDays(pastMonth, 4)
  };
  const [range, setRange] = useState<DateRange | any>(defaultSelected);

  let footer = <p>Please pick the first day.</p>;
  if (range !== undefined && range.from) {
    if (!range.to) {
      footer = <p>{format(range.from, 'PPP')}</p>;
    } else if (range.to) {
      footer = (
        <p>
          {format(range.from, 'PPP')}–{format(range.to, 'PPP')}
        </p>
      );
    }
  }

  const changeFilterValue = async (event: any, name: string, value: string) => {
    if (event.target.checked) {
      const filterValuesObj: any = Object.assign({}, filterValues);
      filterValuesObj[name] = value;
      await setFilterValues(filterValuesObj);
    }
    else {
      const filterValuesObj: any = Object.assign({}, filterValues);
      delete filterValuesObj[name]
      await setFilterValues(filterValuesObj);
    }
  }

  const inputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues[name] = value;
    await setFields(fieldsValues);
  };

  const downloadReport = async () => {
    const apiDefinition = () => {
      return axios.get(`${route}?size=0&page=1&dateFrom=${dayjs(range.from).format("YYYY-MM-DD HH:mm:ss")}&dateTo=${dayjs(range.to).format("YYYY-MM-DD HH:mm:ss")}`,
        {
          responseType: "blob",
          headers: {
            "Authorization": `Bearer ${Cookies.get("userToken")}`,
          }
        }
      );
    };
    try {
      const { data } = await apiDefinition();
      const url = window.URL.createObjectURL(new Blob([data]));
      console.log(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      cogoToast.error("Error downloading file", { position: "top-right" })
    }
  }


  return (
    <ReportDownloadStyle className={`container-full`}>
      <div className={`report-download`} onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
        <button className={`button`} aria-haspopup="true" aria-expanded={selectedMenu} onClick={() => onClickHandler(true)}>
          Download Summary
        </button>
        {
          selectedMenu !== false && (
            <div className='summary'>
              <div className={`container-full summary__form`}>
                <div className={`div-md-8 summary__form__item`}>
                  <div className={`container-full summary__form__item__desc`}>
                    <div className={`summary__form__item__desc__checkbox`}>
                      <input
                        type="checkbox"
                        name="dateRange"
                        onChange={(event) => changeFilterValue(event, event.target.name, event.target.value)}
                        defaultChecked={(filterValues.dateRange) ? true : false}
                      />
                      <Image image={nike} />
                    </div>
                    <Text value='Date Range' />
                  </div>
                  <div className={`container-full summary__form__item__input`}>
                    <Input type="text" className={`input ${(filterValues.dateRange === undefined) ? 'disabled' : null
                      }`}
                      placeholder="Select Date Range"
                      value={(range) ? `${dayjs(range.from).format("YYYY-MM-DD")} to ${dayjs(range.to).format("YYYY-MM-DD")}` : ''}
                      name="dateRange"
                      onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                      readOnly
                    />
                    {
                      (filterValues.dateRange) ?
                        <DayPicker
                          mode="range"
                          defaultMonth={pastMonth}
                          selected={range}
                          footer={footer}
                          modifiersStyles={{
                            selected: {
                              background: 'blue',
                              color: 'white',
                              fontWeight: 'bold',
                            },
                          }}
                          onSelect={setRange}
                          style={{ position: "absolute", left: "0", marginTop: "40px", background: "white", boxSizing: "border-box", padding: "35px", boxShadow: "0px 15px 44px rgba(10, 18, 73, 0.15)" }}
                          styles={{
                            day: { boxSizing: "border-box", padding: '10px', borderRadius: "8px", fontFamily: "Mulish", },
                            caption: { fontFamily: "Mulish" },
                          }}
                        /> : null
                    }
                  </div>
                </div>
              </div>
              <div className={`container-full summary__action`}>
                <Button value={`Download`} className='button' isLoading={state.submitReducer.fetching} onClick={() => downloadReport()} />
              </div>
            </div>
          )
        }
      </div>
    </ReportDownloadStyle>
  );
}

export default ReportDownload;