import React, { useState, useEffect } from 'react';
import SettingsNavBarStyle from './SettingsNavBarStyle';
import {useSelector} from 'react-redux';
import { RootStore } from '../../../config/ConfigStore';
import Text from '../../atoms/Text/Text';
import { NavLink, useLocation } from 'react-router-dom';

const SettingsNavBar = () => {
  const state = useSelector( (state:RootStore) => state);
  const location = useLocation();
  const checkIfUsersIsActive  = location.pathname.includes('/settings/users');

  return (
    <SettingsNavBarStyle>
      <div className={`container-full settings__nav`}>
        {
          (state.authReducer.results.user_level === 1) ?
          <NavLink to="/settings/business-profile" className={({ isActive }) =>
            isActive ? "--active-nav" : undefined
          }>
            <div className={`settings__nav__item`}>
              <Text value='Business Profile' />
            </div>
          </NavLink> : null
        }
        <NavLink to="/settings/profile" className={({ isActive }) =>
          isActive ? "--active-nav" : undefined
        }>
          <div className={`settings__nav__item`}>
            <Text value='Profile' />
          </div>
        </NavLink>
        <NavLink to="/settings/users" className={ checkIfUsersIsActive ? '--active-nav' : undefined}>
          <div className={`settings__nav__item`}>
            <Text value='Users' />
          </div>
        </NavLink>
        <NavLink to="/settings/manage-roles" className={({ isActive }) =>
            isActive ? "--active-nav" : undefined
        }>
          <div className={`settings__nav__item`}>
            <Text value='Roles &amp; Permissions' />
          </div>
        </NavLink>
        <NavLink to="/settings/signatory-groups" className={({ isActive }) =>
            isActive ? "--active-nav" : undefined
        }>
          <div className={`settings__nav__item`}>
            <Text value='Signatory Groups' />
          </div>
        </NavLink>
        <NavLink to="/settings/entities" className={({ isActive }) =>
            isActive ? "--active-nav" : undefined
        }>
          <div className={`settings__nav__item`}>
            <Text value='Entities' />
          </div>
        </NavLink>
      </div>
    </SettingsNavBarStyle>
  );
};

export default SettingsNavBar;
