import styled from "styled-components";

const CreateIssuerModalStyle = styled.div`
    animation: show-bg 0.5s linear forwards;
    box-sizing:border-box;
    display:flex;
    height:100%;
    left:0;
    position:absolute;
    position:fixed;
    top: 0;
    width:100%;
    z-index:5000;
    @keyframes show-bg{
        0%{
            background:rgba(0, 0, 0, 0)
        }
        100%{
            background:rgba(37, 39, 51, 0.7);
        }
    }
    .modal-container{
        box-sizing:border-box;
        height:100vh;
    }
    .modal-container__box{
        -ms-flex: 0 0 626px;
        -webkit-flex: 0 0 626px;
        flex: 0 0 626px;
        max-width: 626px;
        background: #FAFAFA;
        border-radius:10px;
        box-sizing:border-box;
        max-height: 729px;
        overflow: auto;
        padding: 20px;
        position: relative;
    }
    .modal-container__box::-webkit-scrollbar{
        display: none;
    }
    .modal-container__box__loading{
        background: grey;
        left:0;
        overflow: hidden;
        position:absolute;
        // position:fixed;
        right:0;
        top:0;
        z-index:7000;
    }
    .modal-container__box__loading__progress-bar{
        animation: slide-progress-bar 1.1s linear infinite;
        background: #3751FF;
        height:6px;
        width:30%;
        // position:absolute;
        z-index:3000;
    }
    @keyframes slide-progress-bar{
        0%{
            margin-left:0%;
            width:30%;
        }
        40%{
            width:60%;
        }
        100%{
            margin-left:100%;
            width:30%;
        }
    }
    .modal-container__box__header{
        box-sizing: border-box;
        padding-left: 10px;
        h2{
           color: #252733;
           font-family: 'Mulish';
           font-style: normal;
           font-weight: 700;
           font-size: 1.375em;
           line-height: 30px;
        }
        img{
            cursor: pointer;
        }
    }
    .modal-container__box__progress{
        box-sizing: border-box;
        margin-top: 25px;
        padding: 0 9.5px 0 9px;
    }
    .modal-container__box__progress__item{
        -ms-flex: 0 0 50%;
        -webkit-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        position: relative;
        &:after{
            background: #C4C4C4;
            content: '';
            display: inline-block;
            left: 0;
            margin-left: 9%;
            margin-top: 9px;
            position: absolute;
            top: 0;
            width: 89%;
            height: 2px;
        }
    }
    .--last-item{
        display: flex;
        justify-content: space-between;
        &:after{
            width: 82%;
        }
    }
    .--active{
        &:after{
            background: #3751FF;
        }
    }
    .modal-container__box__progress__item__checkbox{
        position: relative;
        .input{
            left: 0;
            margin-left: 2px;
            margin-top: -10px;
            opacity: 0;
            position: absolute;
            top: 0;
            width: 17px;
            z-index: 500;
            &:checked + {
            .modal-container__box__progress__item__checkbox__box{
                background: #3751FF;
                border: 2px solid #3751FF;
                img{
                transform: scale(1);
                }
            }
            }
        }
    }
    .modal-container__box__progress__item__checkbox__box{
        border: 2px solid #C4C4C4;
        border-radius: 50%;
        height: 17px;
        transition: background 0.5s linear;
        width: 17px;
        img{
            left: 0;
            margin-left: 6px;
            margin-top: 7px;
            position: absolute;
            transform: scale(0);
            transition: all 0.5s linear;
            top: 0;
        }
    }
    .--current-step{
        border: 2px solid #3751FF;
    }
    .modal-container__box__content{
        box-sizing: border-box;
        margin-top: 40px;
        padding: 0 15px 0 15px;
    }

    .modal-container__box__action{
        justify-content: flex-end;
        margin-top: 78px;
        .button{
            border-radius: 8px;
            font-family: 'Mulish';
            font-size: 16px;
            height: 50px;
            margin-bottom: 0;
        }
        .--cancel{
            background: #F5F5F5;
            color: #252733;
            font-weight: 600;
            margin-right: 20px;
            width: 102px;
        }
        .--change{
            background: #3751FF;
            box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
            color: white;
            font-weight: 700;
            width: 88px;
        }
    }
`
export default CreateIssuerModalStyle;