import { FeeDispatchTypes, INFO, NHIS, NHF, STEP_CLEAR, PENSION, PAYE, SALARY, DEDUCTIONS } from '../actions/FeeStepActionTypes';

export interface DefaultState {
  results: any;
}

const defaultState: DefaultState = {
  results: {
    info: {},
    nhis: {},
    nhf: {},
    pension: {},
    paye: {},
    salary: {},
    deductions: {}
  },
};

const feeStepReducer = (
  state: DefaultState = defaultState,
  action: FeeDispatchTypes
) => {
  switch (action.type) {
    case INFO:
      return {
        ...state,
        results: {
          ...state.results,
          info: action.data,
        },
      };
    case NHIS:
      return {
        ...state,
        results: {
          ...state.results,
          nhis: action.data,
        },
      };
    case NHF:
      return {
        ...state,
        results: {
          ...state.results,
          nhf: action.data,
        },
      };
    case PENSION:
      return {
        ...state,
        results: {
          ...state.results,
          pension: action.data,
        },
      };
    case PAYE:
      return {
        ...state,
        results: {
          ...state.results,
          paye: action.data,
        },
      };
    case SALARY:
      return {
        ...state,
        results: {
          ...state.results,
          salary: action.data,
        },
      };
    case DEDUCTIONS:
      return {
        ...state,
        results: {
          ...state.results,
          deductions: action.data,
        },
      };
    case STEP_CLEAR:
      return {
        ...state,
        results: {
          info: {},
          nhis: {},
          nhf: {},
          pension: {},
          paye: {},
          salary: {},
          deductions: {}
        }
      };
    default:
      return state;
  }
};

export default feeStepReducer;
