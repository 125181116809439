import React, { useState, useEffect, useRef } from 'react';
import ProfileTemplateStyle from './ProfileTemplateStyle';
import Text from '../../atoms/Text/Text';
import Input from '../../atoms/Input/Input';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import { Link } from 'react-router-dom';
import edit from '../../../assets/images/edit-icon.svg';
import pencil from '../../../assets/images/pencil.svg';
import Button from '../../atoms/Button/Button';
import ChangePasswordModal from '../../organisms/ChangePasswordModal/ChangePasswordModal';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {DashboardActions} from '../../../redux/actions/DashboardAction';
import dayjs from 'dayjs';
import { SubmitActionsPUT, SubmitActionsUpload } from '../../../redux/actions/SubmitAction';
import Cookies from 'js-cookie';
import axios from '../../../axios';
import cogoToast from "cogo-toast"; 
import SettingsNavBar from '../../organisms/SettingsNavBar/SettingsNavBar';

const ProfileTemplate = () => {
  const [toggleChangePasswordModal, setToggleChangePasswordModal] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(true);
  const [url, setFileUrl] = useState<string>();
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector( (state:RootStore) => state);
  console.log(state);

  useEffect(() => {
    if(state.submitReducer.results && state.submitReducer.results.data.fileUri){
        let data = {
          profilePhotoUri: state.submitReducer.results.data.fileUri
        }
        dispatch(SubmitActionsPUT(`/users/profile/${state.authReducer.results.user_name}`, data))
    }
    if(state.submitReducer.results && state.submitReducer.results.data.profilePhotoUri){
      generatePageContent();
    }
  }, [state.submitReducer])

  useEffect(() => {
    if(state.dashboardReducer.results && state.dashboardReducer.results.data.content && state.dashboardReducer.results.data.content[0].profilePhotoUri){
        openFile(state.dashboardReducer.results.data.content[0].profilePhotoUri);
    }
  }, [state.dashboardReducer])

  useEffect(() => {
    generatePageContent();
    if(state.authReducer.results.force_password_change){
      showChangePasswordModal();
    }
  }, [])

  const generatePageContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/users?id=${state.authReducer.results.user_id}`));
    setFetchLoading(false);
  }

  const showChangePasswordModal = () => {
    setToggleChangePasswordModal(true)
  }

  const hideChangePasswordModal = () => {
    setToggleChangePasswordModal(false)
  }

  const renderChangePasswordModal = () => {
    if(toggleChangePasswordModal){
      return(
        <ChangePasswordModal handleClose={state.authReducer.results.force_password_change ? () => {} : hideChangePasswordModal} />
      )
    }
  }

  const renderFetchLoading = () => {
    if(fetchLoading){
      return(
        <FetchLoading />
      )
    }
  }

  const fileUpload = async (file: any) => {
    setFetchLoading(true);
    let value = {
        file,
        fileType: 'OTHERS'
    }
    await dispatch(SubmitActionsUpload('/files/upload/', value));
    setFetchLoading(false);
  }

  const openFile = async (fileUri: string) => { 
    const apiDefinition = () => {
      return axios.get(`${fileUri.substring(4)}`,
        {
          responseType: "blob",
          headers: {
            "Authorization": `Bearer ${Cookies.get("userToken")}`,
          }
        }
      );
    };
    try {
      setFetchLoading(true);
      const { data } = await apiDefinition();
      const url = URL.createObjectURL(new Blob([data]));
      setFileUrl(url);
      setFetchLoading(false);
    } catch (error) {
      cogoToast.error("Error downloading file", { position: "top-right" })
    }
  }

  return (
    <>
      {renderChangePasswordModal()}
      {renderFetchLoading()}
      <ProfileTemplateStyle>
        <div className={`container-full settings`}>
            <div className={`container-full settings__desc`}>
              <HeaderText value="Settings" />
            </div>
            <SettingsNavBar />
            <div className={`container-full settings__content`}>
              <div className={`container-full settings__content__desc`}>
                <HeaderText value='Your Profile' />
              </div>
              <div className={`container-full settings__content__body`}>
                <div className={`settings__content__body__logo`}>
                  <div className={`container-full settings__content__body__logo__desc`}>
                    <HeaderText value='LOGO' />
                  </div>
                  <div className={`container-full settings__content__body__logo__box`}>
                  {
                    (state.dashboardReducer.results && url) ? 
                    <Image className="img img-rc" image={url}/> : null
                   }
                  </div>
                  <div className={`settings__content__body__logo__file`}>
                    <Text value='Logo' className={`logo`} />
                    {/* <Text value='' className={`file-name`} /> */}
                    <Image image={edit} alt="edit" />
                    <Input type="file" className={`input`} onChange={(event) => fileUpload(event.target.files[0])} accept="image/*" />
                  </div>
                </div>
                <div className={`settings__content__body__details`}>
                  <div className={`container-full settings__content__body__details__section`}>
                    <div className={`container-full settings__content__body__details__section__desc`}>
                      <HeaderText value='User details' />
                    </div>
                    <div className={`container-full settings__content__body__details__section__box`}>
                      <div className={`container-full settings__content__body__details__section__box__row`}>
                        <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                          <HeaderText value='Name' />
                          <Text value={
                             (state.dashboardReducer.results && state.dashboardReducer.results.data.content) ? `${state.dashboardReducer.results.data.content[0].firstName} ${state.dashboardReducer.results.data.content[0].lastName}` : ''
                          } />
                        </div>
                        <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                          <HeaderText value='Email' />
                          <Text value={
                            (state.dashboardReducer.results  && state.dashboardReducer.results.data.content) ? state.dashboardReducer.results.data.content[0].email : ''
                          } />
                        </div>
                      </div>
                      <div className={`container-full settings__content__body__details__section__box__row --remove-bottom`}>
                        <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                          <HeaderText value='Phone Number' />
                          <Text value={
                            (state.dashboardReducer.results  && state.dashboardReducer.results.data.content) ? state.dashboardReducer.results.data.content[0].phoneNumber : ''
                          } />
                        </div>
                        <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                          <HeaderText value='Username' />
                          <Text value={
                            (state.dashboardReducer.results  && state.dashboardReducer.results.data.content) ? state.dashboardReducer.results.data.content[0].username : ''
                          } />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`container-full settings__content__body__details__section --timeline`}>
                    <div className={`container-full settings__content__body__details__section__desc`}>
                      <HeaderText value='Roles and Signatory Groups' />
                    </div>
                    <div className={`container-full settings__content__body__details__section__box`}>
                      <div className={`container-full settings__content__body__details__section__box__row --remove-bottom`}>
                        <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                          <HeaderText value='Role' />
                          <p> 
                            {
                              state.dashboardReducer.results  && state.dashboardReducer.results.data.content && state.dashboardReducer.results.data.content[0].userRoles !== undefined && state.dashboardReducer.results.data.content[0].userRoles.length > 0 ? 
                              state.dashboardReducer.results.data.content[0].userRoles.map((role: Record<any, any>) => {
                                return(
                                  `${role.name} `
                                )
                              }) : null
                            }
                          </p>
                        </div>
                        <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                          <HeaderText value='Signatory Groups' />
                          {/* <p> Approvers, Payment_Authorisers </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`container-full settings__content__body__details__section --timeline`}>
                    <div className={`container-full settings__content__body__details__section__desc`}>
                      <HeaderText value='Timeline' />
                    </div>
                    <div className={`container-full settings__content__body__details__section__box`}>
                      <div className={`container-full settings__content__body__details__section__box__row`}>
                        <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                          <HeaderText value='Date Added' />
                          <p>
                          {(state.dashboardReducer.results  && state.dashboardReducer.results.data.content) ? `${dayjs(state.dashboardReducer.results.data.content[0].createdDate).format('MM/DD/YYYY')} ` : ''}
                            <span> 
                              {(state.dashboardReducer.results  && state.dashboardReducer.results.data.content) ? dayjs(state.dashboardReducer.results.data.content[0].createdDate).format('h:mm A') : ''}
                            </span> 
                          </p>
                        </div>
                        <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                          <HeaderText value='Last Login' />
                          <p> 
                          {(state.dashboardReducer.results  && state.dashboardReducer.results.data.content) ? `${dayjs(state.dashboardReducer.results.data.content[0].lastLoginDate).format('MM/DD/YYYY')} ` : ''}
                            <span> 
                              {(state.dashboardReducer.results  && state.dashboardReducer.results.data.content) ? dayjs(state.dashboardReducer.results.data.content[0].lastLoginDate).format('h:mm A') : ''}
                            </span> 
                          </p>
                        </div>
                      </div>
                      <div className={`container-full settings__content__body__details__section__box__row --remove-bottom --ip`}>
                        <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                          <HeaderText value='Last Login IP' />
                          <p> {(state.dashboardReducer.results  && state.dashboardReducer.results.data.content) ? state.dashboardReducer.results.data.content[0].loginIp : ''} </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`container-full settings__content__body__details__section --timeline`}>
                    <div className={`container-full settings__content__body__details__section__desc`}>
                      <HeaderText value='Security' />
                    </div>
                    <div className={`container-full settings__content__body__details__section__box`}>
                      <div className={`container-full settings__content__body__details__section__box__row --remove-bottom`}>
                        <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                          <HeaderText value='Password' className='--password'/>
                          <div className={`settings__content__body__details__section__box__row__item__password`}>
                            <Text value='**********************' />
                          </div>
                        </div>
                        <div className={`div-md-6 settings__content__body__details__section__box__row__item`}>
                          <HeaderText value='' />
                          <Button value="Change" className="button" onClick={() => showChangePasswordModal()}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className={`container-full settings__content__action`}>
                <Link to="/settings/business-profile/edit">
                  <button className='button'>
                    <Image image={pencil} />
                    Edit
                  </button>
                </Link>
              </div> */}

            </div>
        </div>
      </ProfileTemplateStyle>
    </>
  );
};

export default ProfileTemplate;
