import React, { useState, useEffect } from 'react';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import PendingApprovalSummaryStyle from './PendingApprovalSummaryStyle';
import nike from '../../../assets/images/nike.svg';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import nikeGreen from '../../../assets/images/nike-green.svg';
import cancelRed from '../../../assets/images/cancel-red.svg';
import commaNumber from 'comma-number';
import Button from '../../atoms/Button/Button';
import { SubmitActionsPOST } from '../../../redux/actions/SubmitAction';
import { useNavigate } from 'react-router-dom';
import DeclinePaymentModal from '../DeclinePaymentModal/DeclinePaymentModal';
import ApprovePaymentModal from '../ApprovePaymentModal/ApprovePaymentModal';
import Cookies from 'js-cookie';
import { appInstance as axios } from '../../../axios';
import cogoToast from "cogo-toast";


type UserTableProps = {
  data: any
}

const PendingApprovalSummary = ({ data }: UserTableProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  const [toggleDeclinePaymentModal, setToggleDeclinePaymentModal] = useState(false);
  const [toggleApprovePaymentModal, setToggleApprovePaymentModal] = useState(false);
  // const navigate = useNavigate()

  const showDeclinePaymentModal = () => {
    setToggleDeclinePaymentModal(true);
  }

  const hideDeclinePaymentModal = () => {
    setToggleDeclinePaymentModal(false);
  }

  const renderDeclinePaymentModal = () => {
    if (toggleDeclinePaymentModal) {
      return (
        <DeclinePaymentModal handleClose={hideDeclinePaymentModal} />
      )
    }
  }

  const showApprovePaymentModal = () => {
    setToggleApprovePaymentModal(true);
  }

  const hideApprovePaymentModal = () => {
    setToggleApprovePaymentModal(false);
  }

  const renderApprovePaymentModal = () => {
    if (toggleApprovePaymentModal) {
      return (
        <ApprovePaymentModal handleClose={hideApprovePaymentModal} />
      )
    }
  }

  const downloadFile = async (file: string) => {
    const apiDefinition = () => {
      return axios.get(`/files/${file}`,
        {
          responseType: "blob",
          headers: {
            "Authorization": `Bearer ${Cookies.get("userToken")}`,
          }
        }
      );
    };
    try {
      const { data } = await apiDefinition();
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      cogoToast.error("Error downloading file", { position: "top-right" })
    }
  }

  return (
    <>
      {renderDeclinePaymentModal()}
      {renderApprovePaymentModal()}
      <PendingApprovalSummaryStyle>
        <div className={`container-full data`}>
          <div className={`data__item`}>
            <div className={`container-full data__item__desc`}>
              <Text value='Salary' />
              <HeaderText value={data && commaNumber(data.data.totalSalaryAmount)} />
            </div>
            <div className={`container-full data__item__box`}>
              <HeaderText value='Salary' />
            </div>
            <div className={`container-full data__item__record`}>
              {
                data.data.salaryAggregates && data.data.salaryAggregates.length > 0 && data.data.salaryAggregates.map((salary: Record<any, any>, key: number) => {
                  return (
                    <div className={`div-sm-12 data__item__record__row --salary`} key={key}>
                      <div className={`data__item__record__row__salary`}>
                        <Text value={salary.issuerName} />
                        <div className={`data__item__record__row__salary__label`}>
                          {
                            salary.issuerType === 1 && <Text value='COM' />
                          }
                          {
                            salary.issuerType === 2 && <Text value='MFB' />
                          }
                          {
                            salary.issuerType === 3 && <Text value='WALLET' />
                          }
                        </div>
                      </div>
                      <HeaderText value={`₦${commaNumber(salary.totalAmount)}`} />
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className={`data__item`}>
            <div className={`container-full data__item__desc`}>
              <Text value='NHIS' />
              <HeaderText value={data && commaNumber(data.data.totalNhisAmount)} />
            </div>
            <div className={`container-full data__item__box`}>
              <HeaderText value='HMO' />
            </div>
            <div className={`container-full data__item__record`}>
              {
                data.data.nhisAggregates && data.data.nhisAggregates.length > 0 && data.data.nhisAggregates.map((nhis: Record<any, any>, key: number) => {
                  return (
                    <div className={`div-sm-12 data__item__record__row`} key={key}>
                      <Text value={nhis.partnerName} />
                      <HeaderText value={`₦${commaNumber(nhis.totalAmount)}`} />
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className={`data__item`}>
            <div className={`container-full data__item__desc`}>
              <Text value='NHF' />
              <HeaderText value={data && commaNumber(data.data.totalNhfAmount)} />
            </div>
            <div className={`container-full data__item__box`}>
              <HeaderText value='Bank' />
            </div>
            <div className={`container-full data__item__record`}>
              {
                data.data.nhfAggregates && data.data.nhfAggregates.length > 0 && data.data.nhfAggregates.map((nhf: Record<any, any>, key: number) => {
                  return (
                    <div className={`div-sm-12 data__item__record__row`} key={key}>
                      <Text value={nhf.partnerName} />
                      <HeaderText value={`₦${commaNumber(nhf.totalAmount)}`} />
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className={`data__item`}>
            <div className={`container-full data__item__desc`}>
              <Text value='PAYE' />
              <HeaderText value={data && commaNumber(data.data.totalPayeAmount)} />
            </div>
            <div className={`container-full data__item__box`}>
              <HeaderText value='Tax Office' />
            </div>
            <div className={`container-full data__item__record`}>
              {
                data.data.payeAggregates && data.data.payeAggregates.length > 0 && data.data.payeAggregates.map((paye: Record<any, any>, key: number) => {
                  return (
                    <div className={`div-sm-12 data__item__record__row`} key={key}>
                      <Text value={paye.partnerName} />
                      <HeaderText value={`₦${commaNumber(paye.totalAmount)}`} />
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className={`data__item`}>
            <div className={`container-full data__item__desc`}>
              <Text value='Pensions' />
              <HeaderText value={data && commaNumber(data.data.totalPensionAmount)} />
            </div>
            <div className={`container-full data__item__box`}>
              <HeaderText value='PFA' />
            </div>
            <div className={`container-full data__item__record`}>
              {
                data.data.pfaAggregates && data.data.pfaAggregates.length > 0 && data.data.pfaAggregates.map((pension: Record<any, any>, key: number) => {
                  return (
                    <div className={`div-sm-12 data__item__record__row`} key={key}>
                      <Text value={pension.partnerName} />
                      <HeaderText value={`₦${commaNumber(pension.totalAmount)}`} />
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className={`data__item`}>
            <div className={`container-full data__item__desc`}>
              <Text value='Deductions' />
              <HeaderText value={data && commaNumber(data.data.totalThirdPartyDeductionAmount)} />
            </div>
            <div className={`container-full data__item__box`}>
              <HeaderText value='Deductions' />
            </div>
            <div className={`container-full data__item__record`}>
              {
                data.data.deductionsAggregates && data.data.deductionsAggregates.length > 0 && data.data.deductionsAggregates.map((ded: Record<any, any>, key: number) => {
                  return (
                    <div className={`div-sm-12 data__item__record__row`} key={key}>
                      <Text value={ded.dedName} />
                      <HeaderText value={`₦${commaNumber(ded.totalAmount)}`} />
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
        <div className={`container-full pagination`}>
          <div className={`div-md-6 pagination__box`}>
            <Button className='button' value={"Download File"} isLoading={state.submitReducer.fetching} onClick={() => downloadFile(state.dashboardReducer.results.data.fileId)} />
            {
              state.dashboardReducer.results.data.invoiceUri &&
              <Button className='button' value={"Download Invoice"} isLoading={state.submitReducer.fetching} onClick={() => downloadFile(state.dashboardReducer.results.data.invoiceUri)} />
            }
          </div>
          <div className={`div-md-6 pagination__action`}>
            {
              state.authReducer.results.roles.includes('Authorizer') && !data.data.finalAuthorizer &&
              <>
                <Button className='button --decline' value={
                  <>
                    <p>Decline</p>
                    <Image image={cancelRed} />
                  </>
                } isLoading={state.submitReducer.fetching} onClick={() => showDeclinePaymentModal()} />
                <Button className='button --approve' value={
                  <>
                    <p>Approve</p>
                    <Image image={nikeGreen} />
                  </>
                } isLoading={state.submitReducer.fetching} onClick={() => showApprovePaymentModal()} />
              </>
            }
          </div>
        </div>
      </PendingApprovalSummaryStyle>
    </>
  );
};

export default PendingApprovalSummary;
