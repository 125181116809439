import styled from "styled-components";
import Colors from '../../../helpers/Colors';

const SettingsNavBarStyle = styled.div`
  width: 100%;
  .settings__nav{
    justify-content: normal;
    margin-top: 10px;
  }
  .settings__nav__item{
    box-sizing: border-box;
    margin-right: 20px;
    padding: 10px 10px 13px 10px;
    p{
      color: #B6B6BB;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.01em;
    }
  }
  .--active-nav{
    .settings__nav__item{
      border-bottom: 2px solid #3751FF;
      box-sizing: border-box;
      padding: 6px 10px 6px 10px;
      p{
        color: #3751FF;
        font-weight: 600;
      }
    }
  }
  
  
`;

export default SettingsNavBarStyle;