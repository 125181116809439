import React, { useState, useEffect } from 'react';
import SavedPaymentsTemplateStyle from './SavedPaymentsTemplateStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import Input from '../../atoms/Input/Input';
import exportIcon from '../../../assets/images/download-icon.svg';
import plusIcon from '../../../assets/images/plus-icon-white.svg';
import filterIcon from '../../../assets/images/filter-icon.svg';
import plus from '../../../assets/images/plus-icon.svg';
import SavedPaymentsTable from '../../organisms/SavedPaymentsTable/SavedPaymentsTable';
import nike from '../../../assets/images/nike.svg';
import Button from '../../atoms/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { DashboardActions } from '../../../redux/actions/DashboardAction';
import { exportAll, exportClear } from '../../../redux/actions/ExportAction';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import { NavLink, useNavigate } from 'react-router-dom';
import { addDays, format } from 'date-fns';
import { DateRange, DayPicker } from 'react-day-picker';
import caret from '../../../assets/images/caret-filter-select.svg';
import InitiatePaymentModal from '../../organisms/InitiatePaymentModal/InitiatePaymentModal';

const pastMonth = new Date();

const SavedPaymentsTemplate = () => {
  let timeOutId: any = null;
  const [toggleInitiatePaymentModal, setToggleInitiatePaymentModal] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const dispatch: TypedDispatch = useDispatch();
  const state: any = useSelector((state: RootStore) => state);
  // const navigate = useNavigate();
  const [filterValues, setFilterValues] = useState<any>({});
  const [fields, setFields] = useState({
    dateRange: '',
    status: ''
  });

  const onClickHandler = (value: boolean) => {
    if (selectedMenu !== false) {
      setSelectedMenu(false);
      return;
    }
    setSelectedMenu(value);
  }

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
      setSelectedMenu(false);
    });
  }

  const onFocusHandler = () => {
    clearTimeout(timeOutId);
  }

  useEffect(() => {
    dispatch(exportAll(`/payment/savedPayments?size=0&page=1&uploaderId=${state.authReducer.results.user_id}`));
  }, [])

  useEffect(() => {
    dispatch(exportClear())
    generatePageContent();
    console.log(state);
  }, [size, page, reloadPage])

  const generatePageContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/payment/savedPayments?size=${size}&page=${page}&uploaderId=${state.authReducer.results.user_id}`));
    setFetchLoading(false);
  }

  const renderFetchLoading = () => {
    if (fetchLoading) {
      return (
        <FetchLoading />
      )
    }
  }

  const changePage = (value: number) => {
    setPage(value);
  }

  const changeSize = (value: number) => {
    setSize(value);
  }

  const showInitiatePaymentModal = () => {
    setToggleInitiatePaymentModal(true);
  }

  const hideInitiatePaymentModal = () => {
    setToggleInitiatePaymentModal(false);
  }

  const renderInitiatePaymentModal = () => {
    if (toggleInitiatePaymentModal) {
      return (
        <InitiatePaymentModal handleClose={hideInitiatePaymentModal} reloadPage={refreshTable} />
      )
    }
  }

  const exportData = () => {
    return {
      fileData: () => {
        let payments = state.exportReducer.results.data.map((payment: Record<any, any>) => {
          return (
            {
              PaymentName: payment.paymentName,
              PaymentDescription: payment.paymentDescription,
              DateRange: `${dayjs(payment.startDate).format('MM/DD/YYYY')} - ${dayjs(payment.endDate).format('MM/DD/YYYY')}`,
              Stage: payment.stage === "FP" && 'File Parsing' || payment.stage === "AG" && "Aggregation"
                || payment.stage === "AP" && "Approval" || payment.stage === "FC" && "Fee Calculation" ||
                payment.stage === "PA" && "Payment Processing" || payment.stage === "FS" && "File Synchronization",
              DateCreated: dayjs(payment.createdDate).format('MM/DD/YYYY'),
            }
          )
        })
        let data = [...payments];
        return data
      },
      fileHeaders: () => {
        let headers = [
          { label: "Payment Name", key: "PaymentName" },
          { label: "Payment Description", key: "PaymentDescription" },
          { label: "Date Range", key: "DateRange" },
          { label: "Stage", key: "Stage" },
          { label: "Date Created", key: "DateCreated" },
        ];
        return headers;
      }
    }
  }

  const exportDataAll = () => {
    return {
      fileData: () => {
        let payments = state.exportReducer.allResults.data.map((payment: Record<any, any>) => {
          return (
            {
              PaymentName: payment.paymentName,
              PaymentDescription: payment.paymentDescription,
              DateRange: `${dayjs(payment.startDate).format('MM/DD/YYYY')} - ${dayjs(payment.endDate).format('MM/DD/YYYY')}`,
              Stage: payment.stage === "FP" && 'File Parsing' || payment.stage === "AG" && "Aggregation"
                || payment.stage === "AP" && "Approval" || payment.stage === "FC" && "Fee Calculation" ||
                payment.stage === "PA" && "Payment Processing" || payment.stage === "FS" && "File Synchronization",
              DateCreated: dayjs(payment.createdDate).format('MM/DD/YYYY'),
            }
          )
        })
        let data = [...payments];
        return data
      },
      fileHeaders: () => {
        let headers = [
          { label: "Payment Name", key: "PaymentName" },
          { label: "Payment Description", key: "PaymentDescription" },
          { label: "Date Range", key: "DateRange" },
          { label: "Stage", key: "Stage" },
          { label: "Date Created", key: "DateCreated" },
        ];
        return headers;
      }
    }
  }

  const refreshTable = () => {
    if (reloadPage) {
      return setReloadPage(false);
    }
    setReloadPage(true);
  }

  const defaultSelected: DateRange = {
    from: pastMonth,
    to: addDays(pastMonth, 4)
  };
  const [range, setRange] = useState<DateRange | any>(defaultSelected);

  let footer = <p>Please pick the first day.</p>;
  if (range !== undefined && range.from) {
    if (!range.to) {
      footer = <p>{format(range.from, 'PPP')}</p>;
    } else if (range.to) {
      footer = (
        <p>
          {format(range.from, 'PPP')}–{format(range.to, 'PPP')}
        </p>
      );
    }
  }

  const changeFilterValue = async (event: any, name: string, value: string) => {
    if (event.target.checked) {
      const filterValuesObj: any = Object.assign({}, filterValues);
      filterValuesObj[name] = value;
      await setFilterValues(filterValuesObj);
    }
    else {
      const filterValuesObj: any = Object.assign({}, filterValues);
      delete filterValuesObj[name]
      await setFilterValues(filterValuesObj);
    }
  }

  const inputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues[name] = value;
    await setFields(fieldsValues);
  };

  const fetchFilteredContent = async () => {
    setFetchLoading(true);
    await dispatch(DashboardActions(`/payment/fetchpayments?size=${size}&page=${page}&${filterValues.status && fields.status !== '' ? `&status=${fields.status}` : ''}`));
    setFetchLoading(false);
    setSelectedMenu(false);
  }

  return (
    <>
      {renderFetchLoading()}
      {renderInitiatePaymentModal()}
      <SavedPaymentsTemplateStyle>
        <div className={`container-full payments`}>
          <div className={`container-full payments__desc`}>
            <HeaderText value="Payments" />
          </div>
          <div className='container-full payments__action'>
            <div className={`payments__action__nav`}>
              <NavLink
                to="/payments/ongoing-payments"
                className={({ isActive }) =>
                  isActive ? "--active-nav" : undefined
                }
              >
                <div className={`payments__action__nav__item`}>
                  <Text value={`Ongoing Payments`} />
                </div>
              </NavLink>
              <NavLink
                to="/payments/pending-approval"
                className={({ isActive }) =>
                  isActive ? "--active-nav" : undefined
                }
              >
                <div className={`payments__action__nav__item`}>
                  <Text value={`Pending Approval`} />
                </div>
              </NavLink>
              <NavLink
                to="/payments/declined-payments"
                className={({ isActive }) =>
                  isActive ? "--active-nav" : undefined
                }
              >
                <div className={`payments__action__nav__item`}>
                  <Text value={`Declined Payments`} />
                </div>
              </NavLink>
              <NavLink
                to="/payments/saved-payments"
                className={({ isActive }) =>
                  isActive ? "--active-nav" : undefined
                }
              >
                <div className={`payments__action__nav__item`}>
                  <Text value={`Saved Payments`} />
                </div>
              </NavLink>
            </div>
            <div className={`payments__action__search`}>
              <Input className={`input`} type="text" placeholder="Payment Reference"
                onChange={(event) => setSearch(event.target.value)}
                onKeyPress={event => {
                  if (event.key === 'Enter' || event.which === 13 || event.keyCode === 13) {
                    generatePageContent();
                  }
                }}
              />
              <div className='btn-group'>
                {
                  (state.exportReducer.results.data.length > 0) ?
                    <CSVLink data={exportData().fileData()} headers={exportData().fileHeaders()} filename={"saved payments.csv"}>
                      <button className='button --export'>
                        <Image image={exportIcon} />
                      </button>
                    </CSVLink> :
                    <CSVLink data={exportDataAll().fileData()} headers={exportDataAll().fileHeaders()} filename={"saved payments.csv"}>
                      <button className='button --export'>
                        <Image image={exportIcon} />
                      </button>
                    </CSVLink>
                }
                <button className={`button --user ${state.authReducer.results.roles.includes('Initiator') ? '' : '--disabled'
                  }`} onClick={() => showInitiatePaymentModal()}>
                  <Image image={plusIcon} />
                </button>
              </div>
            </div>
          </div>
          <div className={`container-full payments__filter`}>
            <div className={`payments__filter__item --filter`}>
              <div className={`--filter__box`}>
                <Image image={filterIcon} alt="filter-icon" />
              </div>
              <Text value='Filter' />
            </div>
            {/* <div className={`payments__filter__item --active-filter`}>
                <Text value='No active filters' />
              </div> */}
            <div className={`payments__filter__item --add-filter`} onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
              <button className={`button --elipses`} onClick={() => onClickHandler(true)} aria-haspopup="true" aria-expanded={selectedMenu}>
                <Image image={plus} />
                <Text value='Add filter' />
              </button>
              {
                selectedMenu !== false && (
                  <button className='unset'>
                    <div className={`container-full more-info`}>
                      <div className={`container-full more-info__top`}>
                        {/* <div className={`div-md-4 more-info__top__item`}>
                          <div className={`container-full more-info__top__item__desc`}>
                            <div className={`more-info__top__item__desc__checkbox`}>
                              <input
                                type="checkbox"
                                name="dateRange"
                                onChange={(event) => changeFilterValue(event, event.target.name, event.target.value)}
                                defaultChecked={(filterValues.dateRange) ? true : false}
                              />
                              <Image image={nike} />
                            </div>
                            <Text value='Date Range' />
                          </div>
                          <div className={`container-full more-info__top__item__input`}>
                            <Input type="text" className={`input ${(filterValues.dateRange === undefined) ? 'disabled' : null
                              }`}
                              placeholder="Select Date Range"
                              value={(range) ? `${dayjs(range.from).format("YYYY-MM-DD")} to ${dayjs(range.to).format("YYYY-MM-DD")}` : ''}
                              name="dateRange"
                              onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                              readOnly
                            />
                            {
                              (filterValues.dateRange) ?
                                <DayPicker
                                  mode="range"
                                  defaultMonth={pastMonth}
                                  selected={range}
                                  footer={footer}
                                  modifiersStyles={{
                                    selected: {
                                      background: 'blue',
                                      color: 'white',
                                      fontWeight: 'bold',
                                    },
                                  }}
                                  onSelect={setRange}
                                  style={{ position: "absolute", left: "0", marginTop: "40px", background: "white", boxSizing: "border-box", padding: "35px", boxShadow: "0px 15px 44px rgba(10, 18, 73, 0.15)" }}
                                  styles={{
                                    day: { boxSizing: "border-box", padding: '10px', borderRadius: "8px", fontFamily: "Mulish", },
                                    caption: { fontFamily: "Mulish" },
                                  }}
                                /> : null
                            }
                          </div>
                        </div> */}

                        <div className={`div-md-4 more-info__top__item`}>
                          <div className={`container-full more-info__top__item__desc`}>
                            <div className={`more-info__top__item__desc__checkbox`}>
                              <input
                                type="checkbox"
                                name="status"
                                onChange={(event) => changeFilterValue(event, event.target.name, event.target.value)}
                                defaultChecked={(filterValues.status) ? true : false}
                              />
                              <Image image={nike} />
                            </div>
                            <Text value='Status' />
                          </div>
                          <div className={`container-full more-info__top__item__input`}>
                            <div className='input'>
                              <select required
                                className={
                                  `${(filterValues.status === undefined) ? 'disabled' : null}`
                                }
                                name="status"
                                value={fields.status}
                                onChange={(evt) => inputChange(evt.target.name, evt.target.value)}
                              >
                                <option value="" hidden> Select Payment Status </option>
                                <option value="0"> Bulk Payment Debit Failed </option>
                                <option value="1"> Bulk Payment Debit Completed </option>
                                <option value="2"> Ongoing Bulk Debit </option>
                                <option value="3"> Fee calculation completed </option>
                                <option value="4"> Fee calculation completed with Error </option>
                                <option value="5"> Ongoing fee calculation </option>
                                <option value="6"> Pending authorization </option>
                                <option value="7"> Payment Aggregation completed </option>
                                <option value="8"> Payment Aggregation failed </option>
                                <option value="9"> Ongoing Payment Aggregation </option>
                                <option value="10"> File parsing completed </option>
                                <option value="11"> Incomplete or failed File parsing</option>
                                <option value="12"> New payment, file parsing ongoing</option>
                              </select>
                              <Image image={caret} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`container-full more-info__bottom`}>
                        <Button value="Cancel" className='button --cancel' onClick={() => setSelectedMenu(false)} />
                        <Button value="Apply Filters" className={`button --apply`} onClick={() => fetchFilteredContent()} />
                      </div>
                    </div>
                  </button>
                )
              }
            </div>
          </div>
          <div className='container-full payments__table'>
            {
              (state.dashboardReducer.results !== null) ?
                <SavedPaymentsTable data={state.dashboardReducer.results} pagination={size} setPagination={changeSize} changePage={changePage} page={page} reloadPage={refreshTable} /> : null
            }
          </div>
        </div>
      </SavedPaymentsTemplateStyle>
    </>
  );

};

export default SavedPaymentsTemplate;
