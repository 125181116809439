import { Dispatch } from 'redux';
import {
  FetchDispatchTypes,
  FETCH_SUCCESSFUL,
  FETCH_FAILED,
  FETCH_REQUEST,
} from './DashboardActionTypes';
import {appInstance as axios} from '../../axios';
import { alertActions } from './AlertActions';
import Cookies from 'js-cookie';
import historyObject from '../../config/History';

let timeOut = window.setTimeout(async () => {
  await Cookies.remove("userToken");
  historyObject.push("/");
}, 600000)


export const DashboardActions =
  (url: string) => async (dispatch: Dispatch<FetchDispatchTypes | any>) => {
    // const navigate = createBrowserRouter();
    try {
      dispatch({ type: FETCH_REQUEST });
      dispatch(alertActions.clear());
      const res = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      dispatch({
        type: FETCH_SUCCESSFUL,
        response: res.data,
      });
      dispatch(alertActions.success(res.data.message));
    } catch (error: any) {
      if(error.response.status === 401 && error.response.data.error !== "Access is denied"){
        await Cookies.remove("userToken");
        historyObject.push("/");
      }
      if (error.message === "Network Error") {
        dispatch({
          type: FETCH_FAILED,
        });
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch({
          type: FETCH_FAILED,
        });
        dispatch(alertActions.error(error.response.data.error));
      }
    }
};

export const DashboardActionsPOST =
  (url: string, value: object) =>
  async (dispatch: Dispatch<FetchDispatchTypes | any>) => {
    try {
      dispatch({ type: FETCH_REQUEST });
      dispatch(alertActions.clear());
      const res = await axios.post(url, value, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      dispatch({
        type: FETCH_SUCCESSFUL,
        response: res.data,
      });
    } catch (error:any) {
      if(error.response.status === 401 && error.response.data.error !== "Access is denied"){
        await Cookies.remove("userToken");
        historyObject.push("/");
      }
      if (error.message === "Network Error") {
        dispatch({
          type: FETCH_FAILED,
        });
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch({
          type: FETCH_FAILED,
        });
        dispatch(alertActions.error(error.response.data.error));
      }
    }
};

export const DashboardActionsPATCH =
  (url: string, value: object) =>
  async (dispatch: Dispatch<FetchDispatchTypes | any>) => {
    try {
      dispatch({ type: FETCH_REQUEST });
      dispatch(alertActions.clear());
      const res = await axios.patch(url, value, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      dispatch({
        type: FETCH_SUCCESSFUL,
        response: res.data,
      });
    } catch (error: any) {
      if(error.response.status === 401 && error.response.data.error !== "Access is denied"){
        await Cookies.remove("userToken");
        historyObject.push("/");
      }
      if (error.message === "Network Error") {
        dispatch({
          type: FETCH_FAILED,
        });
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch({
          type: FETCH_FAILED,
        });
        dispatch(alertActions.error(error.response.data.error));
      }
    }
};
