import React, { useState, useEffect } from 'react';
import UpdateGlobalParametersModalStyle from './UpdateGlobalParameterModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/images/close.svg';
import Input from '../../atoms/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import { SubmitActionsPUT } from '../../../redux/actions/SubmitAction';
import EncryptRsa from 'encrypt-rsa';

type UpdateGlobalParametersModalProps = {
  handleClose: Function,
  reloadPage: Function,
  data: any
}

const UpdateGlobalParametersModal = ({ handleClose, reloadPage, data }: UpdateGlobalParametersModalProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state = useSelector((state: RootStore) => state);
  const [fields, setFields] = useState({
    name: data.name,
    description: data.description,
    value: data.value,
  })
  const [submitSuccessfull, setSubmitSuccessfull] = useState(false);

  useEffect(() => {
    if (state.submitReducer.results !== null && submitSuccessfull) {
      handleClose();
      reloadPage();
    }
  }, [state.submitReducer, submitSuccessfull])

  const crypto = require('crypto');
  const iv = Buffer.alloc(16, 0); // Initialization vector.
  const secretKey = crypto.pbkdf2Sync("pub242afea2bae40467bba64d61a474d2d5e", "pub1607bc1173c074d40a20c010be599ccee", 65536, 32, 'sha256'); // Secret key derived from password and salt.

  function encryptText(strToEncrypt: string) {
    try {
      const cipher = crypto.createCipheriv('aes-256-cbc', secretKey, iv);
      const encrypted = cipher.update(strToEncrypt, 'utf8', 'base64') + cipher.final('base64');
      return encrypted;
    } catch (e: any) {
      console.log('Error while encrypting: ' + e.toString());
    }
    return null;
  }

  const onFormSubmit = () => {
    return async (evt: React.FormEvent) => {
      evt.preventDefault();
      const fieldValue = fields;
      await dispatch(
        SubmitActionsPUT(`/global/parameters/${data.id}`, {
          "value": data.name.toLowerCase() === "switchit pin" || data.name.toLowerCase() === "switchit encryption key" ? encryptText(fieldValue.value) : fieldValue.value,
          "description": fieldValue.description
        })
      )
      setSubmitSuccessfull(true);
    }
  }

  const inputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues[name] = value;
    await setFields(fieldsValues);
  };

  const onCheckboxChange = () => {
    return async (event: any) => {
      if (event.target.checked) {
        const fieldsValues: any = Object.assign({}, fields);
        fieldsValues["value"] = 1;
        await setFields(fieldsValues);
        return;
      }
      const fieldsValues: any = Object.assign({}, fields);
      fieldsValues["value"] = 0;
      await setFields(fieldsValues);
    }
  }

  return (
    <UpdateGlobalParametersModalStyle className={`container-full`}>
      <div className={`container-full center modal-container`}>
        <div className={`modal-container__box`}>
          <div className={`container-full modal-container__box__header`}>
            <HeaderText value='Change Global Parameter' />
            <Image image={close} onClick={() => handleClose()} />
          </div>
          <form onSubmit={onFormSubmit()}>
            <div className={`container-full modal-container__box__content`}>
              <div className={`container-full modal-container__box__content__desc`}>
                <Text value='Update global entity to the platform' />
              </div>
              <div className={`container-full modal-container__box__content__desc__form`}>
                <div className={`container-full modal-container__box__content__desc__form__item`}>
                  <Text value='Name' className='label' />
                  <div className='container-full'>
                    <Input type='text' className='input disabled' placeholder='Enter Name' required={true} name="name" value={fields.name} onChange={(evt) => inputChange(evt.target.name, evt.target.value)} />
                  </div>
                </div>
                <div className={`container-full modal-container__box__content__desc__form__item`}>
                  <Text value='Description' className='label' />
                  <div className='container-full'>
                    <Input type='text' className='input' placeholder='Enter Description' required={true} name="description" value={fields.description} onChange={(evt) => inputChange(evt.target.name, evt.target.value)} />
                  </div>
                </div>
                <div className={`container-full modal-container__box__content__desc__form__item`}>
                  <Text value='Value' className='label' />
                  <div className='container-full'>
                    {
                      data.name.toLowerCase() === "process salary" ?
                        <div className={`modal-container__box__content__desc__form__item__switch`}>
                          <input type="checkbox" className={`input`} defaultChecked={
                            data.value === "0" ? false : true
                          } onChange={onCheckboxChange()} />
                          <div className={`modal-container__box__content__desc__form__item__switch__box`}>
                            <div className={`modal-container__box__content__desc__form__item__switch__box__ball`}></div>
                          </div>
                        </div> :
                        <Input type={
                          data.name.toLowerCase() === "switchit pin" || data.name.toLowerCase() === "switchit encryption key" ? `password` : 
                          data.name === "idle_time_out" ? 'number' : 'text'
                        }
                          className='input' placeholder='Enter Value' required={true} name="value" value={fields.value} onChange={(evt) => inputChange(evt.target.name, evt.target.value)} />
                    }

                  </div>
                </div>
              </div>
            </div>
            <div className={`container-full center modal-container__box__action`}>
              <Button value="Cancel" className={`button --cancel`} onClick={() => handleClose()} />
              <Button type="submit" value="Edit" className={`button --update`} isLoading={state.submitReducer.fetching} />
            </div>
          </form>
        </div>
      </div>
    </UpdateGlobalParametersModalStyle>
  );
}

export default UpdateGlobalParametersModal;