import styled from 'styled-components';
import search from '../../../assets/images/Search.svg';

const DashboardTemplateStyle = styled.div`
  background: white;
  box-sizing: border-box;
  padding: 22px 40px 22px 30px;
  width: 100%;

  .dashboard__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 1.5em;
      line-height: 30px;
    }
  }
  .dashboard__desc__filter{
    box-sizing: border-box;
    display: flex;
    margin-top: 2px;
    padding-right: 6px;
  }
  .dashboard__desc__filter__select{
    background: #F5F5F5;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    height: 30px;
    margin-right: 25px;
    padding: 7px 11px;
    position: relative;
    width: 92px;
    .wrapper{
      margin-bottom: 2px;
      margin-right: 9px;
      margin-top: 2px;
    }
    select{
      appearance: none;
      background: transparent;
      cursor: pointer;
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      width: 100%;
    }
    select:required:invalid {
      color: rgba(109, 111, 121, 0.4);
    }
    .caret{
      margin-top: 8px;
      margin-right: 4px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .dashboard__desc__filter__range{
    display: flex;
    position: relative;
  }
  .dashboard__desc__filter__range__item{
    background: #F5F5F5;
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 30px;
    padding: 7px 10px;
    position: relative;
    width: 260px;
    p{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      margin-right: 9px;
    }
    .--date{
      opacity: 0.6;
    }
    img{
      margin-top: 7px;
      margin-right: 10px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .dashboard__body{
    margin-top: 18px;
    width: 100%;
  }
  .dashboard__body__left{
    -ms-flex: 0 0 65%;
    -webkit-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 65%;
  }
  .dashboard__body__left__summary{
    background: #FAFAFA;
    border: 1px solid #F5F5F5;
    border-radius: 10px;
    box-shadow: 0px 14px 25px rgba(37, 39, 51, 0.05);
    box-sizing: border-box;
    height: 99px;
    justify-content: normal;
    padding: 17px 20px;
  }
  .dashboard__body__left__summary__item{
    p{
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 1px;
    }
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
    }
  }
  .--value{
    border-right: 1px solid #DFE0EB;
    box-sizing: border-box;
    padding: 9.5px 37px 9.5px 0;
  }
  .--volume{
    border-right: 1px solid #DFE0EB;
    box-sizing: border-box;
    padding: 9.5px 104px 9.5px 45px;
  }
  .--count{
    box-sizing: border-box;
    padding: 9.5px 0 9.5px 45px;
  }
  .dashboard__body__left__chart{
    background: #FAFAFA;
    box-sizing: border-box;
    margin-top: 20px;
    padding: 13px 12px 53px 12px;
  }
  .dashboard__body__left__chart__diagram{
    -ms-flex: 0 0 70%;
    -webkit-flex: 0 0 70%;
    flex: 0 0 70%;
    max-width: 70%;
    margin-bottom: 0;
  }
  .dashboard__body__left__chart__labels{
    -ms-flex: 0 0 25%;
    -webkit-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
  }
  .dashboard__body__left__chart__labels__card{
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 0;
    padding: 12.5px 12px;
    position: relative;
    .title{
      color: rgba(159, 162, 180, 0.6);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 2px;
    }
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }
    .value{
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
    }
  }
  .--total-card{
    background: rgba(37, 39, 51, 0.05);
  }
  .dashboard__body__left__chart__labels__card__dot{
    border-radius: 50%;
    height: 8px;
    margin-right: 12px;
    margin-top: 16px;
    position: absolute;
    right: 0;
    top: 0;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    width: 8px;
  }
  .--statutories-bodies{
    background: #0644CC;
  }
  .--beneficiary-bank{
    background: #3751FF;
  }
  .--total{
    background: #8790CC;
  }
  .dashboard__body__left__break-down{
    background: #FAFAFA;
    box-sizing: border-box;
    padding: 9px 12px;
  }
  .dashboard__body__left__break-down__sb{
    -ms-flex: 0 0 65%;
    -webkit-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 65%;
    margin-bottom: 0;
    .title{
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .dashboard__body__left__break-down__sb__row{
    margin-top: 8px;
  }
  .dashboard__body__left__break-down__sb__row__item{
    background: #FFFFFF;
    border-radius: 8px;
    box-sizing: border-box;
    height: 80px;
    margin-bottom: 0;
    padding: 12.5px 12px;
    -ms-flex: 0 0 49.2%;
    -webkit-flex: 0 0 49.2%;
    flex: 0 0 49.2%;
    max-width: 49.2%;
    .title{
      color: rgba(159, 162, 180, 0.6);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 2px;
    }
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }
    .value{
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
    }
  }
  .dashboard__body__left__break-down__bb{
    -ms-flex: 0 0 32.5%;
    -webkit-flex: 0 0 32.5%;
    flex: 0 0 32.5%;
    max-width: 32.5%;
    margin-bottom: 0;
    .title{
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .dashboard__body__left__break-down__bb__card{
    background: #FFFFFF;
    border-radius: 8px;
    box-sizing: border-box;
    height: 80px;
    margin-bottom: 0;
    margin-top: 8px;
    padding: 12.5px 12px;
    .title{
      color: rgba(159, 162, 180, 0.6);
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 2px;
    }
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }
    .value{
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
    }
  }
  .dashboard__body__right{
    -ms-flex: 0 0 35%;
    -webkit-flex: 0 0 35%;
    flex: 0 0 35%;
    max-width: 35%;
  }
`;

export default DashboardTemplateStyle;
