import React, {useState, useEffect} from 'react';
import CreateMerchantTemplateStyle from './CreateMerchantTemplateStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import {useParams} from "react-router-dom";
import {useSelector} from 'react-redux';
import { RootStore } from '../../../config/ConfigStore';
import MerchantStepOne from '../../organisms/MerchantStepOne/MerchantStepOne';
import MerchantStepTwo from '../../organisms/MerchantStepTwo/MerchantStepTwo';
import MerchantStepThree from '../../organisms/MerchantStepThree/MerchantStepThree';
import MerchantStepFour from '../../organisms/MerchantStepFour/MerchantStepFour';


const CreateMerchantTemplate = () => {
  const state = useSelector( (state:RootStore) => state);
  const {step} = useParams();

  return (
    <>
      <CreateMerchantTemplateStyle>
        <div className={`container-full merchants`}>
            <div className={`container-full merchants__desc`}>
              <HeaderText value="Create Merchant" />
            </div>
            {
              (step === "step-one") ? 
              <MerchantStepOne /> : null
            } 
            {
              (step === "step-two") ? 
              <MerchantStepTwo /> : null
            } 
            {
              (step === "step-three") ? 
              <MerchantStepThree /> : null
            } 
            {
              (step === "step-four") ?
              <MerchantStepFour /> : null
            }
        </div>
      </CreateMerchantTemplateStyle>
    </>
  );
  
};

export default CreateMerchantTemplate;
