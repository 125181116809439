import styled from 'styled-components';
import search from '../../../assets/images/Search.svg';

const GlobalParametersTemplateStyle = styled.div`
  background: white;
  box-sizing: border-box;
  padding: 20px 30px;
  width: 100%;

  .global__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 600;
      font-size: 1.5em;
      line-height: 30px;
    }
  }
  .global__content{
    box-sizing: border-box;
    padding: 12px;
    position: relative;
  }
  .global__content__desc{
    h2{
      color: #252733;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 700;
      font-size: 1em;
      line-height: 20px;
    }
  }
  .global__content__action{
    margin-top: 20px;
    .input{
      background: url(${search}), #FAFAFA no-repeat;
      background-size: 18px;
      background-repeat: no-repeat;
      background-position: 8px 8px;
      border: none;
      border-radius: 8px;   
      box-sizing: border-box;
      color: #9FA2B4;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      height: 34px;
      line-height: 16px;
      padding-left: 37px;
      width: 194px;
      &::placeholder{
        color: rgba(159, 162, 180, 0.8)
      }
    }
  }
  .global__table{
    margin-top: 5px;
  }
  
`;

export default GlobalParametersTemplateStyle;
