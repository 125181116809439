import React, { useState } from 'react';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import MerchantsTableStyle from './MerchantsTableStyle';
import nike from '../../../assets/images/nike.svg';
import elipses from '../../../assets/images/elipses.svg';
import user from '../../../assets/images/user-male.svg';
import Pagination from '../Pagination/Pagination';
import DeactivateMerchantModal from '../DeactivateMerchantModal/DeactivateMerchantModal';
import ActivateMerchantModal from '../ActivateMerchantModal/ActivateMerchantModal';
import dayjs from 'dayjs';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import {exportData} from '../../../redux/actions/ExportAction';
import EditUserModal from '../EditUserModal/EditUserModal';
import { useNavigate } from "react-router-dom";


type UserTableProps = {
  data: any,
  pagination: number,
  setPagination: Function,
  changePage: Function,
  page: number,
  reloadPage: Function
}

const MerchantsTable = ({data, pagination, setPagination, changePage, page, reloadPage}: UserTableProps) => {
  const [toggleDeactivateModal, setToggleDeactivateModal] = useState(false);
  const [toggleActivateModal, setToggleActivateModal] = useState(false);
  let timeOutId:any = null;
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const dispatch: TypedDispatch = useDispatch();
  const state:any = useSelector( (state:RootStore) => state);
  const navigate = useNavigate();

  const onClickHandler = (value: boolean, id: string, name: string) => {
    if(selectedMenu !== false){
      setSelectedMenu(false);
      return;
    }
    setSelectedMenu(value);
    setSelectedId(id)
    setSelectedName(name)
  }

  const onBlurHandler = () => {

      timeOutId = setTimeout(() => {
        setSelectedMenu(false);
      });
  }

  const onFocusHandler = () =>{
      clearTimeout(timeOutId);
  }

  const showDeactivateModal = () => {
    setToggleDeactivateModal(true);
  }

  const hideDeactivateModal = () => {
    setToggleDeactivateModal(false);
  }

  const renderDeactivateModal = () => {
    if(toggleDeactivateModal){
      return(
        <DeactivateMerchantModal handleClose={hideDeactivateModal} reloadPage={reloadPage} merchantId={selectedId} merchantName={selectedName}/>
      )
    }
  }

  const showActivateModal = () => {
    setToggleActivateModal(true);
  }

  const hideActivateModal = () => {
    setToggleActivateModal(false);
  }

  const renderActivateModal = () => {
    if(toggleActivateModal){
      return(
        <ActivateMerchantModal handleClose={hideActivateModal} reloadPage={reloadPage} merchantId={selectedId} merchantName={selectedName}/>
      )
    }
  }

  const handleAllCheck = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      await dispatch(exportData(state.dashboardReducer.results.data.content))
      console.log(state.exportReducer);
    }
    else{
      await dispatch(exportData([]));
      console.log(state.exportReducer);
    }
  };

  const handleCheckItem = (item: Record<any, any>) => {
    if(state.exportReducer.results.data.includes(item)){
      const newArr = state.exportReducer.results.data.filter( (record: Record<any,any>) => record.id !== item.id)
      console.log(newArr);
      return dispatch(exportData(newArr));
    }
    const update = [...state.exportReducer.results.data, item]
    console.log(update);
    return dispatch(exportData(update));
  };

  const goToProfile = (id: string) => {
    navigate(`/merchants/profile?id=${id}`);
  }


  return (
    <>
      {renderDeactivateModal()}
      {renderActivateModal()}
      <MerchantsTableStyle>
        <div className={`container-full`}>
          <table>
            <thead>
              <tr className={`table-header`}>
                <th>
                  <input type="checkbox" onChange={handleAllCheck}/>
                  <Image image={nike} />
                </th>
                <th>
                  <Text value="Merchant" className={`table-title`} />
                </th>
                <th>
                  <Text value="Business Email" className={`table-title`} />
                </th>
                <th>
                  <Text value="Date Created" className={`table-title`} />
                </th>
                <th>
                  <Text value="Status" className={`table-title`} />
                </th>
                <th>
                  <Text value="More" className={`table-title`} />
                </th>
              </tr>
            </thead>
            <tbody className={`table-body`}>
            {
                (data.data.content !== undefined && data.data.content.length > 0) ?
                data.data.content.map((merchant: Record<any, any>, key: string) => {
                  return (
                    <tr className={`table-row`} key={key}>
                      <td>
                        <input
                          type="checkbox"
                          checked={state.exportReducer.results.data.includes(
                            merchant
                          )}
                          onChange={() => handleCheckItem(merchant)}
                        />
                        <Image image={nike} />
                      </td >
                      <td className={`name`}> 
                        {/* <Image image={user} /> */}
                        <div>
                          <Text value={merchant.businessName} className='label --name' onClick={() => navigate(`/merchants/profile?merchantId=${merchant.id}`)} />
                          <Text value={merchant.address} className='sublabel' />
                        </div>
                      </td>
                      <td> 
                        <Text value={merchant.businessEmail} className='label' />
                      </td>
                      <td> 
                        <Text value={dayjs(merchant.createdDate).format('MM/DD/YYYY')} className='label' />
                        <Text value={dayjs(merchant.createdDate).format('h:mm A')} className='date' />
                      </td>
                      <td> 
                        {
                          (merchant.status === 0) ? 
                          <button className='status --inactive'>
                            Inactive
                          </button> : null
                        }
                        {
                          (merchant.status === 1) ? 
                          <button className='status --active'>
                            Active
                          </button>: null
                        }
                        {
                          (merchant.status > 1) ? 
                          <button className='status --pending'>
                            Pending
                          </button> : null
                        } 
                      </td>
                      <td> 
                        <div className={`container-full`} onBlur={() => onBlurHandler()} onFocus={() => onFocusHandler()}>
                            <button className={`button --elipses`} onClick={() => onClickHandler(true, merchant.id, merchant.businessName)} aria-haspopup="true" aria-expanded={selectedMenu}>
                              <Image image={elipses} />
                            </button>
                            {
                                selectedMenu !== false && selectedId === merchant.id &&(
                                  <div className={`container-full more-info`}>
                                    <div className={`container-full more-info__item`}>
                                      <button onClick={() => navigate(`/merchants/profile?merchantId=${merchant.id}`)}>
                                        <Text value='View Profile' />
                                      </button>
                                    </div>
                                    <div className={`container-full more-info__item`} onClick={() => navigate(`/merchants/fees?merchantId=${merchant.id}`)}>
                                      <Text value='Manage Fees' />
                                    </div>
                                    <div className={`container-full more-info__item`}>
                                      <button onClick={() => navigate(`/merchants/users?merchantId=${merchant.id}`)}>
                                        <Text value='Manage Users' />
                                      </button>
                                    </div>
                                    <div className={`container-full more-info__item`} onClick={() => navigate(`/merchants/source-accounts?merchantId=${merchant.id}`)}>
                                      <Text value='SwitchIT Cards' />
                                    </div>
                                    {
                                      merchant.status === 1 ? 
                                      <div className={`container-full more-info__item`}>
                                        <button onClick={() => showDeactivateModal()}>
                                          <Text value='Deactivate' />
                                        </button>
                                      </div> : null
                                    }
                                    {
                                      merchant.status === 0 ? 
                                      <div className={`container-full more-info__item`}>
                                        <button onClick={() => showActivateModal()}>
                                          <Text value='Activate' />
                                        </button>
                                      </div> : null
                                    }
                                    
                                  </div>
                                )
                            }
                          </div>
                      </td>
                    </tr>
                  )
                }) : null
              }          
            </tbody>
          </table>
        </div>
        <div className={`container-full pagination`}>
          <Pagination pagination={pagination} changePage={changePage} setPagination={setPagination} page={page}/> 
        </div>
      </MerchantsTableStyle>
    </>
  );
};

export default MerchantsTable;
