import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './config/ConfigStore';
import App from './App';
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import historyObject from './config/History';


ReactDOM.render(

  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HistoryRouter history={historyObject}>
        {/* <BrowserRouter> */}
        <App />
        {/* </BrowserRouter> */}
      </HistoryRouter>
    </PersistGate>
  </Provider>
  ,
  document.getElementById('root')
);

