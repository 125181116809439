import React, { useState } from 'react';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Img';
import SignatoryGroupsTableStyle from './SignatoryGroupsTableStyle';
import nike from '../../../assets/images/nike.svg';
import elipses from '../../../assets/images/elipses.svg';
import Pagination from '../Pagination/Pagination';
import dayjs from 'dayjs';
import {exportData} from '../../../redux/actions/ExportAction';
import { useNavigate } from "react-router-dom";
import SignatoryGroupDetails from '../SignatoryGroupsDetails/SignatoryGroupDetails';
import { alertActions } from '../../../redux/actions/AlertActions';
import {appInstance as axios} from '../../../axios';
import {useDispatch, useSelector} from 'react-redux';
import { TypedDispatch, RootStore } from '../../../config/ConfigStore';
import Cookies from 'js-cookie';
import FetchLoading from '../../molecules/FetchLoading/FetchLoading';

type SignatoryGroupsTableProps = {
  data: any,
  pagination: number,
  setPagination: Function,
  changePage: Function,
  page: number,
  openAddModal: Function,
  reloadPage: Function
}

const SignatoryGroupsTable = ({data, pagination, setPagination, changePage, page, openAddModal, reloadPage}: SignatoryGroupsTableProps) => {
  const dispatch: TypedDispatch = useDispatch();
  const state:any = useSelector( (state:RootStore) => state);
  let timeOutId:any = null;
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [signatoryData, setSignatoryData] = useState({})
  // const navigate = useNavigate();

  const onClickHandler = (value: boolean, id: string) => {
    if(selectedMenu !== false){
      setSelectedMenu(false);
      return;
    }
    setSelectedMenu(value);
    setSelectedId(id)
  }

  const onBlurHandler = () => {
      timeOutId = setTimeout(() => {
        setSelectedMenu(false);
      });
  }

  const onFocusHandler = () =>{
      clearTimeout(timeOutId);
  }

  const renderFetchLoading = () => {
    if(fetchLoading){
      return(
        <FetchLoading />
      )
    }
  }

  const handleAllCheck = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      await dispatch(exportData(state.dashboardReducer.results.data.content))
      console.log(state.exportReducer);
    }
    else{
      await dispatch(exportData([]));
      console.log(state.exportReducer);
    }
  };

  const handleCheckItem = (item: Record<any, any>) => {
    if(state.exportReducer.results.data.includes(item)){
      const newArr = state.exportReducer.results.data.filter( (record: Record<any,any>) => record.id !== item.id)
      console.log(newArr);
      return dispatch(exportData(newArr));
    }
    const update = [...state.exportReducer.results.data, item]
    console.log(update);
    return dispatch(exportData(update));
  };

  const fetchSignatoryData = async (id: string) => {
    try{
      setFetchLoading(true);
      const res = await axios.get(`/signatory?id=${id}${state.authReducer.results.user_level === 2 ? `&isGlobal = 1` : ''}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("userToken")}`
        }
      });
      setSignatoryData(res.data.data.content[0]);
      setFetchLoading(false);
    }catch (error: any) {
      setFetchLoading(false);
      if (error.message === "Network Error") {
        dispatch(alertActions.error('Network Error, try again!!!'));
      } else {
        dispatch(alertActions.error(error.response.data.error));
      }
    }
  }

  const setData = (value: object) => {
    setSignatoryData(value)
  }


  return (
    <>
      {renderFetchLoading()}
      <SignatoryGroupsTableStyle>
        <div className={`container-full`}>
          <div className={`div-md-9 table__box`}>
            <table>
              <thead>
                <tr className={`table-header`}>
                  <th>
                    <input type="checkbox" onChange={handleAllCheck}/>
                    <Image image={nike} />
                  </th>
                  <th>
                    <Text value="Name" className={`table-title`} />
                  </th>
                  <th>
                    <Text value="Date Modified" className={`table-title`} />
                  </th>
                  <th>
                    <Text value="Created By" className={`table-title`} />
                  </th>
                  <th>
                    <Text value="Status" className={`table-title`} />
                  </th>
                </tr>
              </thead>
              <tbody className={`table-body`}>
                {
                  (data.data.content !== undefined && data.data.content.length > 0) ?
                  data.data.content.map((signatory: Record<any, any>, key: string) => {
                    return (
                      <tr className={`table-row`} key={key} onClick={() => fetchSignatoryData(signatory.id)}>
                        <td>
                          <input
                            type="checkbox"
                            checked={state.exportReducer.results.data.includes(
                              signatory
                            )}
                            onChange={() => handleCheckItem(signatory)}
                          />
                          <Image image={nike} />
                        </td >
                        <td> 
                          <Text value={signatory.name} className='label'/>
                        </td>
                        <td> 
                          <Text value={dayjs(signatory.lastModifiedDate).format('MM/DD/YYYY')} className='label' />
                          <Text value={dayjs(signatory.lastModifiedDate).format('h:mm A')} className='date' />
                        </td>
                        <td>
                          <Text value={signatory.createdBy} className='label'/>
                        </td>
                        <td>
                          {
                            (signatory.status === 0) ? 
                            <button className='status --inactive'>
                              Inactive
                            </button> : null
                          }
                          {
                            (signatory.status === 1) ? 
                            <button className='status --active'>
                              Active
                            </button>: null
                          }
                          {
                            (signatory.status > 1) ? 
                            <button className='status --pending'>
                              Pending
                            </button> : null
                          } 
                        </td>
                      </tr>
                    )
                  }) : null
                }
              </tbody>
            </table>
          </div>
          <div className={`div-md-3 table__content`}>
            <SignatoryGroupDetails data={signatoryData} openAddModal={openAddModal} reloadPage={reloadPage} setData={setData}/>        
          </div>
        </div>
        <div className={`container-full pagination`}>
          <Pagination pagination={pagination} changePage={changePage} setPagination={setPagination} page={page}/> 
        </div>
      </SignatoryGroupsTableStyle>
    </>
  );
};

export default SignatoryGroupsTable;
